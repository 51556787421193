import { defaults } from 'chart.js';
import VitalsModal from 'components/practice/charts/ClinicalNote/Vitals/VitalsModal';
import React, { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { customizeUserBoard } from '../../../../../../api/Boards';
import { cmToFt, io, objMap } from '../../../../../../lib/helpers/utility';
import { useVitals } from '../../../../../../lib/hooks/queries/widgets/useOverview';
import WidgetHeader from '../../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from '../../../../../shared/CustomizableBoard/widgets/helpers/formatDate';
import Icon from '../../../../../shared/Icon/Icon';
import Select from '../../../../../shared/Select/Select';
import ChartRow, { ChartLabels, ChartRowData, isItemNull } from './ChartRow';

defaults.font.family = 'Plus Jakarta Sans';

const defaultDatasets = {
  heart_rate: { label: 'Heart Rate', color: '#F94144', rate: 'Bpm', visible: true },
  respirations: { label: 'Respiratory Rate', color: '#90BE6D', rate: 'Bpm', visible: true },
  blood_pressure_systolic: {
    label: 'Blood Pressure(Systolic)',
    color: '#F3722C',
    rate: 'MmHg',
    visible: true
  },
  blood_pressure_diastolic: {
    label: 'Blood Pressure(Diastolic)',
    color: '#F8961E',
    rate: 'MmHg',
    visible: true
  },
  weight: { label: 'Weight', color: '#F9C74F', rate: 'Lbs', visible: true },
  temperature: { label: 'Body Temperature', color: '#43AA8B', rate: 'Farh.', visible: true },
  height: {
    label: 'Height',
    color: '#8b5cf6',
    rate: 'Ft',
    visible: true,
    modifier: (item) => (item != null && item != '' ? cmToFt(item) : null)
  },
  bmi: { label: 'BMI', color: '#577590', rate: 'BMI', visible: true },
  oxygen_level: { label: 'Oxygen Level', color: '#F3722C', rate: 'Perct.', visible: true },
  head_circumference: { label: 'Head Circumference', color: '#F9C74F', rate: 'CM', visible: true }
};

export const options = {
  //   responsive: true,
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      ticks: {
        display: false
      },
      grid: {
        color: '#CDD5D8'
      }
    },
    y: {
      grid: {
        display: true,

        color: '#EFE7E4'
      }
    }
  },
  elements: {
    point: {
      radius: 5,
      pointStyle: 'circle',
      borderColor: '#ffffff',
      borderWidth: 2
    },
    line: {}
  },
  tooltip: {}
};

export default function Charts(props) {
  const { patientId, customizations, board } = props;
  const [show, setShow] = useState({ visible: false });
  const [lastVitals, setLastVitals] = useState([]);

  const { data } = useVitals({
    params: { patientId },
    dependencies: [patientId]
  });

  const mergeDatasets = (data) => {
    return objMap(defaultDatasets, (key, item) => ({
      ...item,
      visible: data?.[key]?.visible
    }));
  };

  const [datasets, setDatasets] = useState(
    customizations?.vitals?.datasets
      ? mergeDatasets(customizations?.vitals?.datasets)
      : defaultDatasets
  );

  const navigate = useNavigate();

  const vitals = data?.vitals?.filter(
    (v) =>
      !isItemNull(v?.heart_rate) ||
      !isItemNull(v?.respirations) ||
      !isItemNull(v?.blood_pressure_systolic) ||
      !isItemNull(v?.blood_pressure_diastolic) ||
      !isItemNull(v?.weight) ||
      !isItemNull(v?.temperature) ||
      !isItemNull(v?.height) ||
      !isItemNull(v?.bmi) ||
      !isItemNull(v?.oxygen_level) ||
      !isItemNull(v?.head_circumference)
  );

  const toggleDataset = async (dataset) => {
    const newData = {
      ...datasets,
      [dataset]: { ...datasets[dataset], visible: !datasets[dataset].visible }
    };

    const newCustoms = {
      ...customizations,
      vitals: {
        datasets: objMap(newData, (key, data) => ({ visible: data.visible }))
      }
    };

    setDatasets(newData);
    await customizeUserBoard(navigate, { board, customizations: newCustoms });
  };

  const chartData = useMemo(
    () => ({
      labels: vitals?.map((item) => formatDate(item?.created_at)),
      datasets: Object.keys(datasets).map(
        (dataset) =>
          datasets[dataset].visible && {
            label: datasets[dataset].label,
            data: vitals?.map((item, index) => item[dataset] || undefined),
            borderColor: `${datasets[dataset].color}55`,
            backgroundColor: datasets[dataset].color,
            pointBorderColor: '#ffffff',
            spanGaps: true
          }
      )
    }),
    [data, datasets]
  );

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <WidgetHeader
        hasPlus={true}
        onPlusClick={() => {
          setShow({ visible: true, type: 'create' });
        }}
        icon="chart"
        {...props}></WidgetHeader>

      {io(vitals) ? (
        <>
          <Line height={5} width={20} options={options} data={chartData} />
          <div className="!overflow-y-auto overflow-x-hidden">
            <div className="m-3 flex">
              <div className="!mr-3 flex min-w-[360px] flex-col">
                <div className="py-[6px]">
                  {/* <Select
                    disabled
                    icon={'heart'}
                    isClearable={false}
                    options={[{ value: 'vitals', label: 'Vitals' }]}
                    value={{ value: 'vitals', label: 'Vitals' }}></Select> */}
                  <div className="flex h-[44px] items-center gap-2 rounded-lg  border px-3 ">
                    <Icon icon="heart" />
                    Vitals
                  </div>
                </div>

                {Object.keys(datasets).map((item, index) => (
                  <ChartRow
                    key={index}
                    toggleDataset={() => toggleDataset(item)}
                    item={datasets[item]}
                    index={index}></ChartRow>
                ))}
              </div>
              <div className="relative flex w-full overflow-x-auto overflow-y-hidden">
                <table className="relative text-left ">
                  <thead>
                    <ChartLabels labels={vitals?.map((item) => item?.created_at)}></ChartLabels>
                  </thead>
                  <tbody>
                    {Object.keys(datasets).map((item, index) => (
                      <ChartRowData
                        key={index}
                        data={vitals?.map((data) => {
                          return { item: data[item], id: data.id };
                        })}
                        dataset={datasets[item]}
                        setShow={setShow}
                        vitals={vitals}
                        setLastVitals={setLastVitals}
                        index={index}></ChartRowData>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="p-[20px] py-3 text-center">
          <p className="font-medium text-neutral-500">No Chart data</p>
        </div>
      )}

      {show?.visible && (
        <VitalsModal
          lastVitals={show?.type === 'update' ? lastVitals : undefined}
          showVitalsModal={show?.visible}
          fromOverview={true}
          hideVitalsModal={() => setShow({ visible: false })}
        />
      )}
    </div>
  );
}
