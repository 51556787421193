import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { ia } from '../../../../lib/helpers/utility';
import practiceState from '../../practiceState';
import InsClaims from './InsClaims';
import PatientPayments from '../Payments';
import TotalReceived from './TotalReceived';
import Box from 'components/shared/Box/Box';

export default function GrandTotal() {
  const [loading, setLoading] = useState(true);
  const [accountReceivable, setAccountReceivable] = useState(0);
  const [insurancePayments, setInsurancePayments] = useState(0);
  const [adjustments, setAdjustments] = useState(0);
  const [patientPayments, setPatientPayments] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalSurchargeAmount, setTotalSurchargeAmount] = useState(0);
  const filters = useRecoilValue(practiceState.reportingFilters);
  const [view, setView] = useState();
  const [practice, setPractice] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getPractice();
  }, []);

  useEffect(() => {
    getData();
  }, [filters]);

  const getPractice = async () => {
    try {
      const res = await interimApi('/api/practice/current_practice', {}, navigate);
      const { practice } = res.data;
      if (practice) {
        setPractice(practice);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    if (filters) {
      try {
        let params = {
          startDate: filters.selectedRange[0].startDate,
          endDate: filters.selectedRange[0].endDate
        };
        if (filters?.patient?.type == 'patient') {
          params = { ...params, patientId: filters?.patient?.id };
        } else if (filters?.patient?.type == 'payer') {
          params = { ...params, payerId: filters?.patient?.id };
        }

        if (ia(filters?.patient)) {
          params = { ...params, payerPatient: filters?.patient };
        }

        if (filters?.transactionFilter) {
          params = { ...params, transactionFilter: filters?.transactionFilter };
        }

        const res = await interimApi(
          '/api/practice/billing/reporting/grand_total',
          params,
          navigate
        );
        const {
          accountReceivable,
          insurancePayments,
          adjustments,
          patientPayments,
          total,
          totalSurchargeAmount
        } = res.data;
        setAccountReceivable(accountReceivable);
        setInsurancePayments(insurancePayments);
        setAdjustments(adjustments);
        setPatientPayments(patientPayments);
        setTotal(total);
        setTotalSurchargeAmount(totalSurchargeAmount);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const breakdownTable = (e) => {
    switch (e) {
      case 'accountReceivable':
        setView(<InsClaims grandTotalView={true} />);
        break;
      case 'patientPayments':
        setView(<PatientPayments grandTotalView={true} />);
        break;
      case 'total':
        setView(<TotalReceived grandTotalView={true} />);
        break;
      default:
        setView('');
        break;
    }
  };

  return (
    <div className="flex flex-col overflow-hidden p-4" data-dd-privacy="allow">
      <div className="flex flex-wrap !gap-4">
        <Box className="cursor-pointer" onClick={() => breakdownTable('accountReceivable')}>
          <p className="heading">Accounts Receivable</p>
          <p className="title">${accountReceivable}</p>
        </Box>
        <Box className="cursor-pointer" onClick={() => breakdownTable('insurancePayments')}>
          <p className="heading">Insurance Payments</p>
          <p className="title">${insurancePayments}</p>
        </Box>
        <Box className="cursor-pointer" onClick={() => breakdownTable('adjustments')}>
          <p className="heading">Adjustments</p>
          <p className="title">${adjustments}</p>
        </Box>
        <Box className="cursor-pointer" onClick={() => breakdownTable('patientPayments')}>
          <p className="heading">Patient Payments</p>
          <p className="title">${patientPayments}</p>
        </Box>
        {practice?.surcharge && practice?.surcharge_enabled && (
          <Box className="cursor-pointer" onClick={() => breakdownTable('')}>
            <p className="heading">Total Technology Fee</p>
            <p className="title">${totalSurchargeAmount}</p>
          </Box>
        )}
        <Box className="cursor-pointer" onClick={() => breakdownTable('total')}>
          <p className="heading">Total Received</p>
          <p className="title">${total}</p>
        </Box>
      </div>
      <div className="mt-4 flex flex-col overflow-auto">{view}</div>
    </div>
  );
}
