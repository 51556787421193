import React from 'react';
import { getPatients } from 'api/Patients';
import Status from 'components/shared/Status/Status';
import { formatDate, formatDateAndTime, formatPhoneNumber, ia, mString } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import Actions from '../components/Actions';
import {
  cancelMultipleAppointments,
  creationActionAppointmentMultiple,
  remindAppointmentMultiple
} from 'api/Appointment';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import {
  appointmentStatus,
  appointmentConfirmState,
  clinicalNoteStatus,
  superbillStatus,
  claimState
} from 'constants';
import Tippy from '@tippyjs/react';
import { appointment_statuses } from './constants';

const shapeUser = (value) => {
  return `${value?.f_name} ${value?.l_name}`;
};

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

const returnAppointmentStatus = (value) => {
  let status = appointment_statuses.find((status) => status?.value === value);
  if (!status) {
    status = appointmentStatus.find((status) => status?.value === value);
  }
  return status ? status?.label : value;
};

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    label: p?.fullName,
    value: p?.id
  }));
};

export const defaultColumns = [
  {
    field: 'id',
    headerName: 'ID',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? capitalize(value) : '')
  },
  {
    field: 'starts_at',
    headerName: 'Date',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? formatDateAndTime(value) : '')
  },
  {
    field: 'patient',
    headerName: 'Patient',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? shapeUser(value) : '')
  },
  {
    field: 'practitioner',
    headerName: 'Provider',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? shapeUser(value) : '')
  },
  {
    field: 'patient.email',
    headerName: 'Patient Email',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value : 'N/A')
  },
  {
    field: 'patient.phone',
    headerName: 'Patient Phone',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (formatPhoneNumber(value) ? value : 'N/A')
  },
  {
    field: 'invoice.procedures',
    headerName: 'Services',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (ia(value) ? value?.map((v) => v?.name).join(', ') : 'N/A')
  },
  {
    field: 'invoice.balance',
    headerName: 'Balance',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (
      <Tippy
        arrow={true}
        placement="left-start"
        className="tippy-black w-fit"
        content={returnAppointmentStatus(value)}>
        <div className="flex h-full items-center">
          <Status status={truncateText(value, 8)} className="flex min-w-[80px] items-center" />
        </div>
      </Tippy>
    ),
    maxWidth: 200,
    minWidth: 50,
    resizable: false
  },
  {
    field: '',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  appointment_date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Appointment Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  resources: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Resources',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  service: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Services',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  patient: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  appointmentType: {
    type: 'search',
    multiple: true,
    values: [],
    options: [],
    title: 'Type',
    placeholder: 'Select appointment type',
    icon: false
  },
  appointmentTags: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Tags',
    placeholder: 'Select appointment tags',
    icon: false
  },
  appointmentStatus: {
    type: 'checkbox',
    values: null,
    options: appointmentStatus,
    title: 'Status'
  },
  appointmentConfirmState: {
    type: 'checkbox',
    values: null,
    options: appointmentConfirmState,
    title: 'State'
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: null,
    options: clinicalNoteStatus,
    title: 'Clinical note status'
  },
  superbillStatus: {
    type: 'checkbox',
    values: null,
    options: superbillStatus,
    title: 'Superbill'
  },
  claimState: {
    type: 'checkbox',
    values: null,
    options: claimState,
    title: 'Claim'
  }
};

export const handleRemind = async ({
  navigate,
  type = '',
  appointments = [],
  setSelectedRows,
  setMultiActionsModal
}) => {
  const params = { appointment_ids: appointments, type };
  await remindAppointmentMultiple(navigate, params);

  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentReminder: false
  }));
  showAlert({ message: 'Appointment reminder sent successfully', type: 'success' });
};

export const handleCreationAction = async ({
  navigate,
  type = '',
  appointments = [],
  setSelectedRows,
  setMultiActionsModal
}) => {
  const params = { appointment_ids: appointments, type };
  await creationActionAppointmentMultiple(navigate, params);

  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentCreation: false
  }));
  showAlert({ message: 'Appointment creation sent successfully', type: 'success' });
};

export const cancelAppointment = async ({
  setLoading,
  navigate,
  selectedRows,
  queryClient,
  setMultiActionsModal,
  setSelectedRows
}) => {
  setLoading(true);

  const params = { ids: selectedRows };
  await cancelMultipleAppointments(navigate, params);

  queryClient.invalidateQueries(['appointments']);
  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentCancel: false
  }));
  showAlert({ message: 'Appointments are canceled', color: 'success' });
  setLoading(false);
};

export const updateAppointments = async ({
  queryClient,
  navigate,
  setMultiActionsModal,
  status = '',
  appointments = [],
  setSelectedRows
}) => {
  try {
    const params = {
      update_appointment: true,
      appointments: appointments.map((appointment) => ({
        ...appointment?.data,
        status,
        resource_id: appointment?.resources,
        products: appointment?.products || [],
        procedures: appointment?.data?.invoice?.procedures
      }))
    };
    await requestApi({
      url: '/api/appointment/update_multiple',
      navigate,
      params
    });
    queryClient.invalidateQueries(['appointments']);
    showAlert({ message: 'Appointments updated successfully', color: 'success' });
  } catch (error) {
    showAlert({ message: 'Failed to update', color: 'danger' });
  }
  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentStatus: false
  }));
};
