import React, { useImperativeHandle, useRef, useState } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { permissions } from 'components/state';

const Complaints = ({ sectionRef }) => {
  const userPermissions = useRecoilValue(permissions);
  const { clinicalNote, setClinicalNote, setEnhanceNarrativeParams, isFetchingEnhanceNarrative } =
    useClinicalNoteContext();
  const complaintsRef = useRef(null);
  const otherComplaintsRef = useRef(null);
  const [complaints, setComplaints] = useState({
    present_illness: clinicalNote?.present_illness,
    other_complaints: clinicalNote?.other_complaints
  });
  const { type } = useParams();

  const handleChange = (e, key) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const updatedState = { ...complaints, [name ?? key]: value ?? e };

    setComplaints(() => updatedState);
    setClinicalNote({ ...clinicalNote, ...updatedState });
  };

  useImperativeHandle(sectionRef, () => ({
    formData: {
      present_illness: clinicalNote?.present_illness,
      other_complaints: clinicalNote?.other_complaints
    }
  }));

  const handleEnhanceNarrative = (key) => {
    setEnhanceNarrativeParams({
      kind: type,
      name: key,
      body: clinicalNote?.[key],
      customFormId: null
    });
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(280px,1fr))] gap-4">
      <Textarea
        isEditor
        label="Chief complaints"
        placeholder="Type chief complaints if you have any"
        transcribing
        value={clinicalNote?.present_illness}
        forwardedRef={complaintsRef}
        className="min-h-[200px]"
        id="present_illness"
        data-qa="present-illness"
        name="present_illness"
        onChange={(e) => handleChange(e, 'present_illness')}
        disabled={clinicalNote?.locked}
        options={
          userPermissions?.is_admin
            ? [
                {
                  title: 'Enhance with MyScribe AI',
                  icon: 'new-myscribe-stars',
                  onClick: () => handleEnhanceNarrative('present_illness'),
                  loading: isFetchingEnhanceNarrative
                }
              ]
            : []
        }
      />
      <Textarea
        isEditor
        label="Other complaints"
        placeholder="Type other complaints if you have any"
        transcribing
        className="min-h-[200px]"
        forwardedRef={otherComplaintsRef}
        value={clinicalNote?.other_complaints}
        name="other_complaints"
        id="other_complaints"
        data-qa="other-complaints"
        onChange={(e) => handleChange(e, 'other_complaints')}
        disabled={clinicalNote?.locked}
        options={
          userPermissions?.is_admin
            ? [
                {
                  title: 'Enhance with MyScribe AI',
                  icon: 'new-myscribe-stars',
                  onClick: () => handleEnhanceNarrative('other_complaints'),
                  loading: isFetchingEnhanceNarrative
                }
              ]
            : []
        }
      />
    </div>
  );
};

export default Complaints;
