import { capitalize } from 'lodash';
import ResourceTableActions from '../components/ResourceTableActions';

export default [
  {
    field: 'name',
    headerName: 'Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'description',
    headerName: 'Description',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'user.full_name',
    headerName: 'Created by',
    valueFormatter: ({ value }) => capitalize(value),
    sortable: false
  },
  {
    field: '',
    cellRenderer: ResourceTableActions,
    maxWidth: 100
  }
];
