import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Buttons/Button';
import Select from 'components/shared/Select/Select';
import Modal from 'components/shared/Modal/Modal';
import { showAlert } from 'components/shared/Alert/Alert';
import { duplicateCustomForm } from 'api/CustomForms';
import { useNavigate } from 'react-router-dom';
import { getPractices } from 'api/Practice';

const DuplicateForm = ({ showDuplicateModal, setShowDuplicateModal, formId, formVersion }) => {
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10,
    column: 'name',
    sort: 'asc'
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handlePracticeSearch = async (term) => {
    if (term) {
      const data = await getPractices(navigate, { ...filters, searchTerm: term });
      return data.practices.map((item) => {
        return { value: item.id, label: ` ${item.name}` };
      });
    }
  };

  const mutateDuplicateForm = useMutation({
    mutationFn: () =>
      duplicateCustomForm(navigate, {
        practiceId: selectedPractice.value,
        formId,
        version: formVersion
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customForm']);
      const buttons = [
        {
          text: 'View practice',
          onClick: () => navigate(`/admin/practices/${selectedPractice.value}/forms`)
        }
      ];
      showAlert({
        title: 'Form was duplicated successfully!',
        color: 'primary',
        buttons: buttons
      });
      setShowDuplicateModal(false);
    }
  });

  return (
    <Modal
      slideFromRight
      handleOpen={showDuplicateModal}
      handleClose={() => setShowDuplicateModal(false)}
      title="Duplicate form"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Cancel" outlined onClick={() => setShowDuplicateModal(false)} />
          <Button text="Duplicate" onClick={() => mutateDuplicateForm.mutate()} />
        </div>
      }>
      <div className="ModalBody">
        <Select
          label="Select the practice to duplicate the form into."
          onChange={(v) => setSelectedPractice(v)}
          isAsync
          loadOptions={handlePracticeSearch}
          placeholder="Start typing practice name"
          value={selectedPractice}
        />
      </div>
    </Modal>
  );
};

export default DuplicateForm;
