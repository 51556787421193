import React, { useMemo, useState } from 'react';
import Header from 'components/shared/Header/Header';
import usePageTitle from 'lib/hooks/usePageTitle';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';
import Filter from 'components/shared/Filters/Filter';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import moment from 'moment';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import Button from 'components/shared/Buttons/Button';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import SignAndLockProcess from './components/SignAndLockProcess';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const isFirstColumn = (params) => {
  let displayedColumns = params.api.getAllDisplayedColumns();
  let thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

const ClinicalNotes = () => {
  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns}
      name="clinical-notes">
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Clinical Notes');
  const category = 'clinical-notes';
  const [allowToSign, setAllowToSign] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const user = useRecoilValue(userState);

  const { limit, page, setPage, sort, filters, setFilters, selectedRows, setSelectedRows } =
    useTableContext();
  const { data: providers = {} } = useProviders({});

  const { data, isLoading, isFetching } = useClinicalNote({
    params: {
      forPractice: true,
      pagination: true,
      withDocumentSign: true,
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [
      limit,
      page,
      sort,
      moment(filters.appointment_date?.values?.endDate).format('YYYY-MM-DD'),
      moment(filters.appointment_date?.values?.startDate).format('YYYY-MM-DD'),
      filters.provider?.values,
      filters.cpt?.values,
      filters.icd?.values
    ]
  });

  const { data: procedures } = useServices({});

  const { data: icdList } = useICD10Codes({ params: { withMostUserSearchClicks: true } });

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.provider.options = providerOptions;

  const serviceOptions = useMemo(() => {
    if (procedures?.services?.length > 0) {
      return procedures.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [procedures]);
  filters.cpt.options = serviceOptions;

  const icdOptions = useMemo(() => {
    if (icdList?.icd10?.length > 0) {
      return icdList?.icd10?.map((i) => ({
        label: i?.name,
        value: i?.code
      }));
    }
    return [];
  }, [icdList]);

  filters.icd.options = icdOptions;

  const defaultColDef = useMemo(() => {
    return {
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const clinicalNotes = data?.clinicalNote || [];
  const count = data?.count?.count || 0;

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'actions') return;
    if (e?.column?.colId === '0' || e?.column?.colId === '1') return;
    window.open(
      `/portal/charts/${e?.data?.appointment.user_id}/clinical-notes/${e?.data?.appointment.id ?? e?.appointment?.id}`,
      '_blank'
    );
  };

  const handleSignAndLock = async () => {
    const userId = user?.user_id ?? user.id;
    let selectedClinicalNotes = selectedRows?.map((row) => row?.data);
    let filteredClinicalNotes = selectedClinicalNotes?.filter(
      (cn) =>
        cn.document_sign &&
        cn.document_sign.some((doc) => doc.user_id === userId && doc.status !== 'signed')
    );
    setAllowToSign(filteredClinicalNotes);
    setShowConfirmationModal(true);
  };

  return (
    <>
      <Header title="Clinical Notes">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <Button
              text="Sign & Lock"
              icon="new-lockv2"
              iconColor="white"
              size="small"
              onClick={handleSignAndLock}
            />
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={clinicalNotes}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              defaultColDef={defaultColDef}
              columnDef={defaultColumns}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {showConfirmationModal && (
        <SignAndLockProcess
          allowToSign={allowToSign}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
}

export default withErrorBoundary(ClinicalNotes);
