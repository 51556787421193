import React from 'react';
import Selected from './Selected/Selected';
import Rendered from './Rendered/Rendered';
import cs from 'classnames';

const PreviewNote = ({ isExport, outsideAppointmentId, patientId }) => {
  return (
    <div className="flex h-full">
      <div
        className={cs(
          'overflow-hidden transition-all duration-200',
          isExport ? 'w-[376px] min-w-[376px]' : 'w-0'
        )}>
        <Selected />
      </div>
      <Rendered
        isExport={isExport}
        outsideAppointmentId={outsideAppointmentId}
        patientId={patientId}
      />
    </div>
  );
};

export default PreviewNote;
