import React from 'react';
import ReactModal from 'react-modal';
import { Transition } from '@headlessui/react';
import Button from '../Buttons/Button';
import Icon from '../Icon/Icon';
import cs from 'classnames';
import './Modal.scss';

const Modal = ({
  isOpen = false,
  handleOpen = false,
  handleClose,
  title,
  children,
  subModal,
  slideFromRight,
  disableHeader = false,
  headButton,
  headerClassName,
  headerCloseIcon = true,
  prevButton,
  footer,
  isFooter = true,
  className,
  portalClassName,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  bodyClassName,
  footerClassName,
  customStyling,
  modalCenterAnimation = false,
  onScroll,
  customHeader,
  ...rest
}) => {
  return (
    <Transition show={isOpen || handleOpen} className="absolute">
      <ReactModal
        isOpen={isOpen || handleOpen}
        onRequestClose={handleClose}
        contentLabel={title}
        className={cs('Modal', className, slideFromRight && 'ModalRight')}
        overlayClassName={cs(
          'ModalOverlay',
          slideFromRight && 'ModalRightOverlay',
          modalCenterAnimation && 'ModalCenterAnimation',
          overlayClassName
        )}
        portalClassName={cs(portalClassName, subModal && 'SubModal')}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        style={{ content: customStyling }}
        {...rest}>
        {!disableHeader && (
          <div className={cs('Modal__head', headerClassName)}>
            {customHeader ? (
              customHeader
            ) : (
              <>
                <div className="Modal__head__left w-full">
                  {prevButton}
                  <h6 className="whitespace-nowrap">{title}</h6>
                  {headButton}
                </div>
              </>
            )}
            {headerCloseIcon && (
              <Icon
                data-qa="new-close-square"
                icon="new-close-square"
                onClick={handleClose}
                color={headerClassName?.includes('bg-') ? 'white' : null}
                className="flex h-6 w-6 items-center justify-center"
              />
            )}
          </div>
        )}
        <div onScroll={onScroll} className={cs('Modal__body', bodyClassName)}>
          {children}
        </div>
        {footer ? (
          <div className={cs('Modal__footer', footerClassName)}>{footer}</div>
        ) : isFooter ? (
          <div className="Modal__footer">
            <Button text="Close" onClick={handleClose} color="neutral" outlined />
          </div>
        ) : null}
      </ReactModal>
    </Transition>
  );
};

export default Modal;
