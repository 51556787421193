import insuranceScan from '../icons/insurance_scan.svg';
import ImagePicker from 'components/shared/Picker/ImagePicker';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import React, { useEffect, useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';
import InsuranceForms from './InsuranceForms';

const Renderer = ({
  breadcrumb,
  formik,
  handleDelete,
  handleSave,
  setZoomed,
  loading,
  processing,
  kiosk,
  payers,
  states,
  isTablet,
  getPayerAddress,
  isKeyboardOpen,
  resetCrop
}) => {
  const [showImages, setShowImages] = useState(true);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    if (isKeyboardOpen) {
      setShowImages(false);
      setShowInfo(false);
    }
  }, [isKeyboardOpen]);

  if (processing) {
    return (
      <div className="my-4 flex flex-col items-center text-center">
        <p className="text-lg font-medium text-white">Insurance scanned succesfully!</p>
        <p className="mb-6 mt-2 px-16 text-white">Your plan info is being generated...</p>
        <Icon icon="new-myscribe-ai-processing" />

        <div className="mt-6 grid w-full grid-cols-2 gap-2">
          {Array.from({ length: 8 }, (_, i) => (
            <div key={i} className="flex flex-col">
              <div className="w-1/3">
                <Skeleton baseColor="#212628" highlight="#292E30" count={1} height={15} />
              </div>
              <Skeleton baseColor="#212628" highlight="#292E30" count={1} height={45} />
            </div>
          ))}
        </div>
        <div className="mt-6 scale-150">
          <Loader color="neutral" shade="900"></Loader>
        </div>
      </div>
    );
  }

  switch (breadcrumb.currentStep) {
    case 'start':
      return (
        <div>
          <p className="text-sm text-center text-primary-900">
            Please use the camera on this device to scan both the front and back of your insurance
            card, our proprietary AI will automatically create your insurance profile or enter
            insurance manually
          </p>

          <div className="mt-4 flex flex-col items-center justify-center rounded-xl border !border-dashed !border-primary-500 bg-primary-50 px-10 pb-10">
            <img src={insuranceScan}></img>
            <Button
              color="primary"
              iconColor="white"
              className="mt-3 cursor-pointer"
              iconIsStroke={true}
              text="Scan your insurance card"
              icon="new-scan"
              onClick={() =>
                breadcrumb.push({
                  name: 'card_front',
                  title: 'Front scan'
                })
              }
            />
          </div>

          <div className="flex flex-col items-center text-center">
            <p className="text-neutral-600 text-sm py-4">Or enter insurance details manually</p>
            <Button
              onClick={() =>
                breadcrumb.push({
                  name: 'form',
                  title: 'Form'
                })
              }
              text="Enter insurance manually"
              icon="credit-card-income"
              iconColor='white'
            />
          </div>
        </div>
      );
    case 'card_front':
      return (
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {formik?.values?.card_back && !processing && !loading
              ? 'Looks good?'
              : 'Start with the front of the card'}
          </p>
          <p className="mt-2 px-16 text-white">
            {formik?.values?.card_back && !processing && !loading
              ? 'Make sure the picture is clear'
              : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
          </p>

          <ImagePicker
            label="Front of card"
            handle={formik?.values?.card_front}
            handleDelete={handleDelete}
            name="card_front"
            resetCrop={() => resetCrop('card_front')}
            kiosk={kiosk}
            processing={processing}
            resetHandle={() => formik.setFieldValue('card_front', '')}
            handleContinue={() =>
              breadcrumb.push({
                name: 'card_back',
                title: 'Back scan'
              })
            }
            onCapture={handleSave}
          />
        </div>
      );

    case 'card_back':
      return (
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {formik?.values?.card_back && !processing && !loading
              ? 'Looks good?'
              : 'Now let’s do the back'}
          </p>
          <p className="mt-2 px-16 text-white">
            {formik?.values?.card_back && !processing && !loading
              ? 'Make sure the picture is clear'
              : 'Flip your card over'}
          </p>

          <ImagePicker
            label="Back of card"
            handle={formik?.values?.card_back}
            handleDelete={handleDelete}
            resetCrop={() => resetCrop('card_back')}
            name="card_back"
            kiosk={kiosk}
            processing={processing}
            resetHandle={() => formik.setFieldValue('card_back', '')}
            handleContinue={() =>
              breadcrumb.push({
                name: 'form',
                title: 'Review'
              })
            }
            onCapture={handleSave}
          />

          {!formik?.values?.card_back && !processing && !loading && (
            <div className="mt-4 flex flex-col gap-2">
              <p className="font-medium text-neutral-500">or</p>

              <Button
                color="white"
                text="Skip back scan"
                icon="new-arrow-right"
                iconColor="white"
                iconIsStroke
                outlined
                className="w-[10rem] cursor-pointer gap-1"
                transparent
                onClick={() =>
                  breadcrumb.push({
                    name: 'form',
                    title: 'Review'
                  })
                }></Button>
            </div>
          )}
        </div>
      );

    case 'form':
      return (
        <>
          {formik.values.card_front && (
            <>
              {!isTablet || showImages ? (
                <>
                  {showInfo && (
                    <div className="-mx-[16px] -mt-[16px] mb-4 flex flex-col justify-center bg-primary-50 p-3 text-center">
                      <p className="text-lg font-medium text-primary-700">
                        We have successfully captured your insurance profile
                      </p>
                      <p className="text-primary-900">
                        Please confirm the information is correct before clicking "create profile".
                      </p>
                    </div>
                  )}

                  <div className="mb-4 grid grid-cols-2 gap-2">
                    <div className="rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                      <img
                        onClick={() => setZoomed(formik.values.card_front)}
                        className="aspect-[5/3] w-full cursor-pointer overflow-hidden rounded-xl object-cover"
                        src={`https://cdn.filestackcontent.com/${formik.values.card_front}`}></img>
                    </div>

                    <div className="rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                      {formik.values.card_back && (
                        <img
                          onClick={() => setZoomed(formik.values.card_back)}
                          className="aspect-[5/3] w-full cursor-pointer overflow-hidden rounded-xl bg-black object-cover"
                          src={`https://cdn.filestackcontent.com/${formik.values.card_back}`}></img>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex justify-center">
                  <p
                    onClick={() => setShowImages(true)}
                    className=" mb-2 cursor-pointer py-2 text-primary-500">
                    Show images
                  </p>
                </div>
              )}
            </>
          )}

          <InsuranceForms
            payers={payers}
            states={states}
            isTablet={isTablet}
            formik={formik}
            kiosk={kiosk}
            getPayerAddress={getPayerAddress}
          />
        </>
      );
  }
};

export default Renderer;
