import React, { useState } from 'react';
import cs from 'classnames';
import { Popover } from '@headlessui/react';
import NewAppointmentModal from 'components/practice/Dashboard/components/NewAppointmentModal';
import Icon from 'components/shared/Icon/Icon';
import { Capitalize, ia } from 'lib/helpers/utility';

const WidgetHeader = ({
  item,
  editMode,
  setDeleteWidgetModal,
  icon,
  iconColor,
  children,
  hasPlus = true,
  text = '',
  onPlusClick,
  showModal,
  setShowModal,
  selected = {},
  options = [],
  className
}) => {
  const { selectedOption = null, setSelectedOption = () => {} } = selected;
  const [isHover, setIsHover] = useState(false);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div
      data-dd-privacy="allow"
      className={cs(
        'relative z-10 flex items-center justify-between px-[10px] py-[6px] shadow-[0px_2px_12px_0px_rgba(0,171,232,0.06)]',
        className
      )}>
      <div className="flex items-center gap-2" data-dd-privacy="allow">
        {icon && <Icon size={20} icon={icon} color={iconColor} />}
        {item?.name && <h4 className="text-base font-500 text-primary-900">{item?.name}</h4>}
        {ia(options) && (
          <Popover className="relative">
            {({ open, close }) => (
              <div>
                <Popover.Button
                  className={cs(
                    'group flex w-[208px] cursor-pointer items-center gap-[10px] rounded-md border border-solid bg-primary-50 py-[1px] pl-[10px] pr-3 ',
                    open
                      ? '!border-primary-300 outline outline-2 outline-primary-50'
                      : '!border-transparent'
                  )}
                  data-qa="clinical-notes-menu"
                  as="div"
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}>
                  <Icon icon="chart" size={20} className="cursor-pointer" />
                  <span
                    className={cs(
                      'text-base font-500 leading-7 text-primary-900',
                      !open && 'group-hover:text-primary-700'
                    )}>
                    {Capitalize(selectedOption)}
                  </span>
                  <Icon
                    icon="chevron-down"
                    className={cs(
                      'ml-auto cursor-pointer duration-150',
                      open && 'rotate-180 transform'
                    )}
                    size={11}
                    shade={isHover && !open ? 700 : 900}
                    color="primary"
                  />
                </Popover.Button>
                <Popover.Panel className="absolute left-0 z-40 mt-[6px] grid w-[208px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
                  {options.map((option, index) => (
                    <div
                      data-dd-privacy="allow"
                      key={index}
                      className={cs(
                        'flex gap-x-2 py-[5px] !pl-4 pr-[14px] transition-all hover:bg-primary-50',
                        selectedOption === option.value && 'bg-primary-50'
                      )}
                      onClick={() => {
                        handleSelectChange(option.value);
                        close();
                      }}>
                      <Icon icon={option.icon} size={18} />
                      <span className="text-sm font-400 text-primary-900">{option.label}</span>
                    </div>
                  ))}
                </Popover.Panel>
              </div>
            )}
          </Popover>
        )}
      </div>
      <div className="flex items-center gap-2" data-dd-privacy="allow">
        {editMode && (
          <div
            onPointerDown={(e) => {
              if (e.pointerType !== 'mouse' || e.button === 0) {
                setDeleteWidgetModal(item.i);
              }
            }}
            className="flex h-6 w-6 cursor-pointer items-center justify-center !rounded-lg bg-primary-50">
            <Icon icon="trash" className="cursor-pointer text-primary-900" color="primary" />
          </div>
        )}
        {children && !editMode && children}
        <div className="flex cursor-pointer gap-1" onClick={onPlusClick} data-qa={item?.name} data-dd-privacy="allow">
          {text && <span className="text-base font-500 text-primary-900">{text}</span>}
          {hasPlus && <Icon icon="new-add-square" className="cursor-pointer" size={22} />}
        </div>
      </div>
      <NewAppointmentModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default WidgetHeader;
