import { useQuery } from '@tanstack/react-query';
import { getResourcesAsOptions } from 'api/Resource';
import { useNavigate } from 'react-router-dom';

export const useResourcesAsOptions = () => {
  const navigate = useNavigate();
  return useQuery(['resourcesOptions'], () => getResourcesAsOptions(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};
