import React, { useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../shared/Textarea/Textarea';

const GeneralNote = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const generalNoteRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { notes: clinicalNote?.notes }
  }));

  const handleChange = (e) => {
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      notes: value ?? e
    });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write general notes here"
      id="notes"
      data-qa="general-notes"
      name="notes"
      className="min-h-[200px]"
      value={clinicalNote?.notes}
      onChange={(e) => handleChange(e)}
      transcribing
      forwardedRef={generalNoteRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default GeneralNote;
