import { searchPatients } from 'components/Utility';
import numOrDefault from 'lib/helpers/numOrDefault';
import { formatDate, mString } from 'lib/helpers/utility';
import ClaimActions from './components/ClaimActions';
import ServiceCellTooltip from './components/ServiceCellTooltip';

export const getColDefs = (name, timezone) => [
  {
    headerName: 'Claim ID',
    field: 'id',
    headerCheckboxSelection: true,
    checkboxSelection: (params) => !params.node.group
  },
  { headerName: 'Patient', field: 'patient_name', hide: name === 'patient_claims' },
  { headerName: 'Claim Number', field: 'claim_md_id' },
  { headerName: 'Ins Member ID', field: 'ins_number' },
  { headerName: 'Provider', field: 'provider_name' },
  { headerName: 'Payer', field: 'payer_name' },
  {
    headerName: 'DOS',
    field: 'appointment_starts_at',
    valueGetter: (params) => {
      const date = params?.data?.appointment_starts_at;
      return formatDate(date, timezone, true);
    }
  },
  {
    headerName: 'Services',
    field: 'procedures',
    cellRenderer: ServiceCellTooltip,
    sortable: false
  },
  {
    headerName: 'Charge Amount',
    valueFormatter: ({ value }) => mString(numOrDefault(value * 100)),
    aggFunc: 'sum',
    field: 'total_charge'
  },
  {
    headerName: 'Paid Amount',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'invoice_amount_paid'
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: ClaimActions,
    maxWidth: 70,
    cellClass: 'no-border',
    pinned: 'right'
  }
];

export const DEFAULT_COLUMN_DEFS = { resizable: true, enableRowGroup: true };

export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true,
  suppressAggFuncInHeader: true,
  enableRangeSelection: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  id: {
    type: 'search',
    values: null,
    title: 'Claim ID',
    placeholder: 'Search Claim ID',
    preview: (values) => values
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  claimNumber: {
    type: 'search',
    values: null,
    title: 'Claim Number',
    placeholder: 'Search Claim Number',
    preview: (values) => values
  },
  insMemberId: {
    type: 'search',
    values: null,
    title: 'Ins. Member ID',
    placeholder: 'Search Ins. Member ID',
    preview: (values) => values
  },
  provider: {
    type: 'search',
    values: null,
    title: 'Provider',
    placeholder: 'Search Provider',
    preview: (values) => values
  },
  payer: {
    type: 'search',
    values: null,
    title: 'Payer',
    placeholder: 'Search Payer',
    preview: (values) => values
  }
};

export const getTypeDefaultFilters = (type) => {
  const newDefaultFilters = {
    ...DEFAULT_FILTERS,
    ...(type === 'all' && {
      state: {
        type: 'checkbox',
        options: [
          'ready',
          'acknowledged',
          'denied',
          'paid',
          'rejected',
          'pending',
          'archived',
          'superbills'
        ],
        values: null,
        title: 'Status',
        preview: (values) => values.map((v) => v.label).join(', ')
      }
    })
  };

  return newDefaultFilters;
};

export const getPatientDefaultFilters = () => {
  const newDefaultFilters = {
    ...DEFAULT_FILTERS,
    date: {
      values: {
        startDate: null,
        endDate: null
      },
      type: 'date-range',
      title: 'Date of Service',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    },
    state: {
      type: 'checkbox',
      options: [
        'ready',
        'acknowledged',
        'denied',
        'paid',
        'rejected',
        'pending',
        'archived',
        'superbills'
      ],
      values: null,
      title: 'Status',
      preview: (values) => values.map((v) => v.label).join(', ')
    }
  };
  delete newDefaultFilters.patients;

  return newDefaultFilters;
};
