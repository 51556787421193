import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ReactFormGenerator } from 'react-form-builder2';
import { formatSections } from '../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import RenderNarrativeValue from './RenderNarrativeValue';

const AdvancedSOAP = ({ soapForms }) => {
  const { advancedSOAP } = useClinicalNoteContext();

  const checkedAdvancedSOAP = soapForms
    ?.filter((form) => form.checked === true)
    .map((form) => {
      const matchingAdvancedSOAP = advancedSOAP?.find(
        (item) => item.form && (item.form.name === form.title || item.name === form.title)
      );

      return { ...matchingAdvancedSOAP, checkedFormTypes: form?.checkedFormTypes } || null;
    })
    .filter((item) => item !== null);

  let narrativeComponent = null;
  let templateComponent = null;

  return (
    <div className="pointer-events-none !px-4 !py-2">
      {checkedAdvancedSOAP?.map((item, idx) => {
        if (item.checkedFormTypes.isNarrativeChecked) {
          const narrative = formatSections({
            fieldData: item.form?.json?.fields,
            answerData: JSON.parse(item?.json?.fields)
          });

          narrativeComponent = (
            <React.Fragment>
              <div className="!mb-2 text-xs font-500 text-neutral-700">Narrative:</div>
              <RenderNarrativeValue narrative={narrative} />
            </React.Fragment>
          );
        }

        if (item.checkedFormTypes.isTemplateChecked) {
          templateComponent = (
            <ReactFormGenerator
              key={idx}
              answer_data={item.form && JSON.parse(item.json?.fields)}
              data={item.form ? item.form?.json?.fields : item?.json?.fields}
              hide_actions
              read_only
            />
          );
        }
        return (
          <div key={idx}>
            {narrativeComponent}
            {templateComponent}
          </div>
        );
      })}
    </div>
  );
};

export default AdvancedSOAP;
