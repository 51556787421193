import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, io } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';
import moment from 'moment-timezone';
import React from 'react';
import { getCountForType } from './lib/helpers';

const GeneralPaymentsBreakDown = ({ data }) => {
  const { dashboardData, item, layout } = data;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const { height, width, proportion } = useChartSize(container);

  const formattedData = dashboardData?.transactionGraphData
    ? Object.entries(dashboardData?.transactionGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const localDate = new Date(momentTime.valueOf());
        return {
          timestamp: localDate,
          CreditCard: parseFloat(data.generalPayment.credit_card),
          Cash: parseFloat(data.generalPayment.cash),
          Check: parseFloat(data.generalPayment.check),
          OutsideFinancing: parseFloat(data.generalPayment.outside_financing)
        };
      })
    : [];

  function getColorForGeneralPayment(type) {
    switch (type) {
      case 'CreditCard':
        return '#00ABE8';
      case 'Cash':
        return '#A52A2A';
      case 'Check':
        return '#AA336A';
      case 'OutsideFinancing':
        return '#00CED1';
      default:
        return '#000000';
    }
  }

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },
    series: ['CreditCard', 'Cash', 'Check', 'OutsideFinancing'].map((type) => ({
      type: 'line',
      xKey: 'timestamp',
      yKey: type,
      yName: `${type} (${formatLargeNumber(getCountForType(type, dashboardData))}$)`,
      stroke: getColorForGeneralPayment(type),
      marker: {
        size: 6,
        fill: getColorForGeneralPayment(type),
        stroke: getColorForGeneralPayment(type)
      }
    })),
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };

  return (
    <div className="mt-5">
      <AGChart options={options} />
    </div>
  );
};

export default GeneralPaymentsBreakDown;
