import { Transition } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import Tippy from '@tippyjs/react';
import { useChart3dContext } from 'lib/context/Chart3dContext/Chart3dContext';
import { useLabOrder } from 'lib/hooks/queries/labOrder/useLabOrder';
import { useNonERXPrescription } from 'lib/hooks/queries/nonERXPrescription/useNonERXPrescription';
import { useReferralOrder } from 'lib/hooks/queries/referralOrder/useReferralOrder';
import { assign } from 'lodash';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Axios from '../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../lib/helpers/utility';
import { showAlert as Alert, AlertContent } from '../../../../../shared/Alert/Alert';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../../shared/Icon/Icon';
import { permissions as permissions_state, userState } from '../../../../../state';
import { selectedInitialState } from '../lib/initials';
import { reorder, reorderChildren } from '../lib/renderedDragAndDropHelper';
import {
  checkIfAllFormTypesFalse,
  prepareSelection,
  processAdvancedCustomForms,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../lib/selectedHelper';
import DragDropContainer from './components/DragDropContainer';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const Selected = () => {
  const [isCheckAll, setIsCheckAll] = useState(true);
  const {
    clinicalNote,
    currentHpOverviewData,
    vitals,
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    customFormTypes,
    selected,
    setSelected
  } = useClinicalNoteContext() || {};
  const { currentMyScribe } = useAmbientListeningContext();
  const { handleGetClinicalNote, points } = useChart3dContext();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState({
    forms: true
  });

  const [showAlert, setShowAlert] = useState(true);
  const { appointmentId } = useParams();

  const apptID = appointmentId ?? clinicalNote?.appointment_id;

  const { data: referralsData, isFetching: referralOrderFetching } = useReferralOrder({
    dependencies: [apptID, clinicalNote?.id],
    params: {
      clinicalNoteIds: [apptID]
    },
    options: {
      select: ({ data }) => data?.referrals
    }
  });

  const { data: nonERXPrescription, isFetching: nonERXPrescriptionFetching } =
    useNonERXPrescription({
      dependencies: [apptID, clinicalNote?.id],
      params: {
        appointmentIds: [apptID]
      },
      options: {
        select: ({ data }) => data?.nonERXPrescription
      }
    });

  const { data: labOrder, isFetching: labOrderFetching } = useLabOrder({
    dependencies: [apptID, clinicalNote?.id],
    params: {
      appointmentIds: [apptID],
      withRelations: { provider: true, tests: true }
    },
    options: {
      select: ({ data }) => data?.labOrder
    }
  });

  const toggleExpand = (key) => {
    setSelected((prevData) => ({
      ...prevData,
      [key]: { ...prevData[key], expanded: !prevData[key].expanded || false }
    }));
  };

  const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
  const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);
  const getAdvancedFroms = Object.entries(customFormTypes || {}).map(([, object]) => {
    return processAdvancedCustomForms(object, cnDisplaySettings);
  });

  const permissions = useRecoilValue(permissions_state);

  useEffect(() => {
    getSelected();
  }, [
    clinicalNote,
    advancedHP,
    advancedSOAP,
    cnDisplaySettings,
    vitals,
    currentHpOverviewData,
    currentMyScribe,
    referralOrderFetching,
    nonERXPrescriptionFetching,
    labOrderFetching,
    permissions
  ]);

  const getSelected = async () => {
    setLoading({ ...loading, forms: true });
    try {
      let res = await Axios.post('/api/practice/medical_history/get_default_selection', {
        practitionerId: user?.user_id
      });
      const { selection } = res.data;

      const processedAdvancedForms = assign({}, ...getAdvancedFroms);

      let selectedObj = {
        ...selectedInitialState(
          clinicalNote,
          cnDisplaySettings,
          currentHpOverviewData,
          vitals,
          currentMyScribe,
          referralsData,
          nonERXPrescription,
          labOrder,
          permissions
        ),
        ...getAdvancedHP,
        ...getAdvancedSOAP,
        ...processedAdvancedForms
      };

      if (selection) {
        const result = prepareSelection({
          savedSelection: { ...selectedObj, ...selection },
          initialSelection: selectedObj
        });

        setSelected(result);
      } else {
        const formattedSelect = prepareSelection({ initialSelection: selectedObj });
        setSelected(formattedSelect);
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
    setLoading({ ...loading, forms: false });
  };

  const saveSelected = async () => {
    try {
      let res = await Axios.post('/api/practice/medical_history/save_default_selection', {
        practitionerId: user?.user_id,
        selection: selected
      });
      if (res.data) {
        Alert({
          title: 'Final Note',
          message: 'The selected sections are saved!',
          color: 'success'
        });
      }
    } catch (error) {
      toast.error('There was an error while saving!', { duration: 3000 });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const resetSelected = () => {
    const selectedObj = {
      ...selectedInitialState(clinicalNote, cnDisplaySettings, currentHpOverviewData, vitals),
      ...getAdvancedHP,
      ...getAdvancedSOAP
    };
    const formattedSelect = prepareSelection({ initialSelection: selectedObj });
    setSelected(formattedSelect);
  };

  const handleSelectAll = () => {
    setIsCheckAll((prevState) => !prevState);
    const updateSelected = { ...selected };

    Object.keys(updateSelected)
      .filter((key) => io(updateSelected[key]))
      .forEach((key) => {
        updateSelected[key] = {
          ...updateSelected[key],
          checked: !isCheckAll,
          ...(ia(updateSelected[key].formType) && {
            formType: updateSelected[key].formType.map((f) => ({
              ...f,
              checked: f.title === 'Narrative' ? !isCheckAll : false
            }))
          })
        };
      });

    setSelected(updateSelected);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updateSelected = reorder(selected, result.source.index, result.destination.index);
    setSelected(updateSelected);
  };

  const onDragEndChildren = (result) => {
    if (!result.destination) return;

    const updateSelected = reorderChildren(
      selected,
      result.source.index,
      result.destination.index,
      result.destination.droppableId
    );

    setSelected({ ...updateSelected });
  };

  const handleClick = (key) => {
    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        checked: !selected[key].checked
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'PARENT',
      key
    });

    setSelected(processedObject);
  };

  const handleClickDropdown = (key, dropdownId) => {
    const updatedFormTypes = selected[key].formType.map((type) => {
      if (type.id === dropdownId) return { ...type, checked: !type.checked };

      return type;
    });

    if (!ia(updatedFormTypes)) return;

    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        formType: updatedFormTypes
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'CHILDREN',
      key
    });

    setSelected(processedObject);
  };

  return (
    <div className="flex h-full flex-col bg-neutral-50">
      <Transition
        show={showAlert}
        enter="transition-opacity ease-all duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-all duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        {!clinicalNote?.locked && (
          <div className="px-3 pb-1 pt-3">
            <AlertContent
              width="full"
              title="If you save your current selection, it will be automatically loaded next time you visit this screen."
              handleClose={() => setShowAlert(false)}
            />
          </div>
        )}
      </Transition>
      <div className="flex items-center justify-between gap-x-2 !px-4 !pt-2">
        <p className="text-base text-neutral-800">Select Form</p>
        <div className="flex items-center gap-x-[4px]">
          <Icon
            icon="new-diskette"
            color="neutral"
            disabled={clinicalNote?.locked}
            onClick={() => saveSelected()}
            className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
          />
          <Tippy content="Reset selection" className="tippy-dark no-arrow">
            <div className="flex">
              <Icon
                icon="new-reset-neutral"
                disabled={clinicalNote?.locked}
                onClick={resetSelected}
                className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
              />
            </div>
          </Tippy>
        </div>
      </div>
      <div className="!px-5 !pb-4 !pt-2">
        <Checkbox
          label="All forms"
          onChange={handleSelectAll}
          isChecked={isCheckAll}
          disabled={clinicalNote?.locked}
          className="flex h-10 w-full items-center rounded-[4px] bg-neutral-100"
          labelClassName="cursor-pointer !py-2 !px-2 w-full"
          inputClassName="border-neutral-400"
          id="allForms"
        />
      </div>
      <div className="h-[1px] w-full bg-neutral-200"></div>
      <DragDropContainer
        onDragEnd={onDragEnd}
        onDragEndChildren={onDragEndChildren}
        loading={loading}
        toggleExpand={toggleExpand}
        handleClick={handleClick}
        handleClickDropdown={handleClickDropdown}
      />
    </div>
  );
};

export default Selected;
