import React from 'react';
import { ia, io, isEmpty } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import FamilyHistoryModal from '../Modals/FamilyHistoryModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const FamilyHistoryOverview = ({
  cnSettings,
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (cnSettings && !cnSettings?.overview?.family_history && !cnSettings?.overview?.familyHistory) {
    return null;
  }

  return (
    <SimpleAccordion
      title="Family history"
      onToggle={() => toggleOne('familyHistory')}
      open={isOpen.familyHistory}
      edit={
        <FamilyHistoryModal
          formId={currentHpOverviewData?.familyHistory?.id}
          defaultValue={currentHpOverviewData?.familyHistory?.response}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
          setCustomResponses={setOverviewData}
        />
      }>
      {ia(overviewData?.familyHistory) &&
        overviewData?.familyHistory?.map((familyHistory, index) => {
          if (familyHistory?.response?.noDetails) {
            return (
              <NoKnown
                key={index}
                form={familyHistory}
                description="No known family history"
                shouldShowDivider={index !== 0}
              />
            );
          }

          return familyHistory?.response?.row?.map((item, idx) => (
            <React.Fragment key={idx}>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
              <div key={idx} className="grid gap-y-1">
                {io(item.medicalCondition) && (
                  <div className="flex justify-between">
                    <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Medical condition:
                    </span>
                    <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                      {item.medicalCondition.value}
                    </span>
                  </div>
                )}
                {(item.fatherSide || item.motherSide) && (
                  <div className="flex justify-between">
                    <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Inheritance:
                    </span>
                    <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                      {item.fatherSide ? 'Father side' : 'Mother side'}
                    </span>
                  </div>
                )}
                {!isEmpty(item.note) && (
                  <div className="flex flex-wrap justify-between">
                    <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                      Note:
                    </span>
                    <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                      {item.note}
                    </span>
                  </div>
                )}
                <CreationInfo
                  createdAt={familyHistory?.createdAt}
                  createdBy={familyHistory?.createdBy?.fullName}
                />
              </div>
            </React.Fragment>
          ));
        })}
    </SimpleAccordion>
  );
};

export default FamilyHistoryOverview;
