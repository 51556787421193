import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { default as React, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import '../Custom.scss';
import { Allergies as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import AllergiesRow from './AllergiesRow';

const Allergies = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  fromClinicalNote = false,
  showNarrative = false,
  onChange,
  setAllergyModal = () => {}
}) => {
  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const [allergies, setAllergies] = useState(initialValue);
  const [prevAllergies, setPrevAllergies] = useState(initialValue);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.allergies?.advancedForm
      : true;
  const textAreaRef = useRef();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation() || {};
  const fromCheckin = location?.pathname.includes('/checkin');

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setAllergies(JSON.parse(defaultValue));
      } else {
        setAllergies(defaultValue);
      }
    } else {
      setAllergies(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(allergies);
  }, [allergies]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setAllergies;
    }
  }, []);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'Allergies', type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();

    const newAllergy = {
      allergy: null,
      medication: { value: 'None', label: 'None' },
      typicalReaction: null,
      note: '',
      id: self.crypto.randomUUID()
    };

    const shouldStopProcess = doEmptyFormExist({
      newForm: newAllergy,
      forms: allergies?.allergies
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Allergy',
        message: 'Empty allergy exists. Please fill in the details.',
        color: 'warning'
      });
      return;
    }

    const updatedObject = {
      noDetails: false,
      allergies: [newAllergy, ...allergies.allergies]
    };

    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const syncAllergiesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ allergiesObject: updatedObject });
    } else {
      setAllergies(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };
  const mutateDeleteAllergies = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Allergies',
          message: error,
          color: 'warning'
        });
        return;
      }
      setShowConfirmModal(false);
      setAllergies(initialValue);
      setPrevAllergies(initialValue);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'allergies'
        });
      showAlert({
        title: `Allergies deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setAllergyModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!formId && !fromCheckin) {
      showAlert({
        title: 'Allergy',
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const allergiesRow = allergies?.allergies || [];
    if (allergiesRow.length === 1) {
      setShowConfirmModal(true);
      return;
    }
    const updatedObject = {
      ...allergies,
      allergies: allergies.allergies.filter((row) => row.id !== id)
    };

    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleOnChange = (key, event, id) => {
    const updatedArray = allergies?.allergies?.map((row) => {
      if (row.id === id) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...allergies, allergies: updatedArray };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleNoDetails = () => {
    const updatedObject = { ...allergies, noDetails: !allergies.noDetails };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    allergiesObject = allergies,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...allergiesObject, narrative: event };

    setAllergies(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ allergiesObject = allergies, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: allergiesObject,
      sectionData: 'allergies',
      sectionName: 'Allergy',
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      allergiesObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <div className="Allergies CustomForms">
      <input type="hidden" value={JSON.stringify(allergies)} ref={forwardedRef} />
      {isAdvancedForm && (
        <div className={cs('flex items-center gap-x-4', allergies.noDetails && 'justify-between')}>
          <Checkbox
            data-qa="no-known-allergies"
            label="No known allergies"
            name="noDetails"
            isChecked={allergies.noDetails}
            onChange={handleNoDetails}
          />
          {allergies.noDetails && !fromCheckin && (
            <Icon
              icon="trash"
              className="cursor-pointer"
              onClick={() => {
                if (!formId) {
                  showAlert({
                    title: 'Allergies',
                    message: 'Please create the form before deleting one.',
                    color: 'warning'
                  });
                  return;
                } else {
                  setShowConfirmModal(true);
                }
              }}
            />
          )}
        </div>
      )}
      {!allergies.noDetails && isAdvancedForm && (
        <div className="dashed-top !mt-4">
          <div className="flex items-center justify-end">
            <Button
              text="Add allergy"
              iconRight="add-circle"
              transparent
              onClick={handleAddRow}
              data-qa="add-allergy-btn"
              textClassName="text-primary-500 text-sm"
            />
          </div>
          <div className="CustomForms__body">
            {allergies?.allergies?.map((item, idx) => (
              <AllergiesRow
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                key={item.id}
                index={idx}
                fromCheckin={fromCheckin}
                onChange={handleOnChange}
                onDelete={handleDeleteRow}
                length={allergies.allergies.length}
                {...item}
              />
            ))}
          </div>
        </div>
      )}

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setAllergies}
            restData={{
              className: 'w-full',
              label: 'Allergies Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-allergies',
              'data-qa': 'narrative-allergies',
              name: 'Narrative-allergies',
              value: allergies?.narrative,
              forwardedRef: textAreaRef,
              formName: 'allergies'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}
      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          submitForm({
            ...customFormsParams,
            formId,
            currentFormData: allergies,
            defaultState: prevAllergies,
            type: 'allergies'
          });
          setPrevAllergies(allergies);
        }}></button>
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Allergies"
          icon="trash"
          message="Are you sure you want to delete this form?"
          handleContinue={() => mutateDeleteAllergies.mutate()}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default Allergies;
