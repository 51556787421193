export const customStyle = ({
  width,
  height,
  disabled,
  error,
  radius,
  icon,
  isMulti,
  isCreatable,
  rightIcon,
  styles = {}
}) => ({
  container: (provided) => ({
    ...provided,
    width: width ?? '100%',
    cursor: disabled ? 'not-allowed' : 'auto',
    ...styles?.container
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 10px',
    flexWrap: isMulti || isCreatable ? 'wrap' : 'nowrap',
    ...styles?.valueContainer
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: disabled ? state.selectProps.menuColor : '#fff',
    border: '1px solid',
    borderColor:
      state.isFocused && !error
        ? '#54D2FF !important'
        : error
          ? '#FCA5A5 !important'
          : state.selectProps.menuColor,
    borderRadius: radius ?? '6px',
    width: '100%',
    minHeight: height ?? '2.5rem',
    display: 'flex',
    padding: icon ? '0 0 0 22px' : '0',
    fontSize: '0.875rem',
    color: '#DADFE2',
    caretColor: error ? '#EF4444' : '#13B9FF',
    outline:
      state.isFocused && !error
        ? '2px solid #CBF0FD'
        : state.isFocused && error
          ? '2px solid #FEE2E2'
          : '',
    cursor: 'pointer',
    '&:hover': {
      borderColor: !state.isFocused && state.selectProps.menuColor,
      color: '#DADFE2'
    },
    transition: 0,
    ...styles?.control
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#171717',
    position: 'relative',
    top: 'unset',
    transform: 'unset',
    whiteSpace: 'nowrap',
    margin: 'auto 0',
    paddingRight: !!rightIcon && '25px',
    ...styles?.singleValue
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#8FA0A7',
    position: 'relative',
    top: 'unset',
    transform: 'unset',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...styles?.placeholder
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: !!rightIcon && 'none',
    color: state.isFocused ? '#DADFE2' : state.selectProps.menuColor,
    ':hover': {
      color: state.selectProps.menuColor
    },
    padding: '4px 8px 4px 4px',
    ...styles?.dropdownIndicator
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#DADFE2' : state.selectProps.menuColor,
    ':hover': {
      color: state.selectProps.menuColor
    },
    padding: '4px 0px 4px 4px',
    ...styles?.clearIndicator
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    marginRight: rightIcon && '30px'
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    color: state.selectProps.menuColor,
    outline: '1px solid #DADFE2',
    opacity: 1,
    zIndex: 9999,
    padding: 0,
    ...styles?.menu
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999, ...styles?.menuPortal }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    lineHeight: '22px',
    color: '#1B2123',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#B0EAFF !important' : '',
    ':hover': {
      backgroundColor: '#E6F8FF'
    },
    ':active': {
      backgroundColor: '#E6F8FF'
    },
    margin: state.data.__isNew__ && '2px 4px 0 4px',
    padding: state.data.__isNew__ ? '8px' : '8px 12px',
    width: state.data.__isNew__ && 'calc(100%-8px)',
    borderRadius: state.data.__isNew__ ? '3px' : '0',
    height: 'auto',
    ...styles?.option
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E6F8FF',
    borderRadius: 5,
    cursor: 'default',
    ...styles?.multiValue
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: 'normal',
    margin: 'auto 0',
    color: '#004F6B',
    ...styles?.multiValueLabel
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#004F6B',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    cursor: 'pointer',
    '&:hover': {
      color: '#EF4444',
      backgroundColor: '#FEE2E2'
    },
    ...styles?.multiValueRemove
  })
});
