const atom = require('recoil').atom;

module.exports = {
  userState: atom({
    key: 'userState',
    default: {
      user_id: '',
      f_name: '',
      l_name: '',
      email: '',
      state: '',
      kind: '',
      authenticated: false,
      logged_in: false,
      profile_photo: null,
      created_at: null,
      display_settings: null,
      phone: null
    }
  }),
  kioskState: atom({
    key: 'kioskState',
    default: {
      isKiosk: false,
      name: '',
      id: '',
      color: ''
    }
  }),
  claimState: atom({
    key: 'claimState',
    default: {}
  }),
  claimPatientState: atom({
    key: 'claimPatientState',
    default: {}
  }),
  permissions: atom({
    key: 'permissions',
    default: { loaded: false }
  }),
  hasBeenAuthChecked: atom({
    key: 'hasBeenAuthChecked',
    default: {
      checked: false,
      isImpersonate: false
    }
  }),
  procedureCodeOptionsState: atom({ key: 'procedureCodeOptionsState', default: [] }),
  procedureCodeMapState: atom({ key: 'procedureCodeMapState', default: new Map() }),
  sidebarExpand: atom({ key: 'sidebarExpand', default: false }),
  tempSearchTerm: atom({
    key: 'tempSearchTerm',
    default: 'step1',
    get: ({ get }) => get(tempSearchTerm),
    set: ({ set }, newValue) => {
      set(tempSearchTerm, newValue);
    }
  }),
  roles: atom({ key: 'roles', default: { practiceId: 0, data: [] } }),
  roleOptions: atom({ key: 'roleOptions', default: [] }),
  resizableModalWidth: atom({ key: 'resizableModalWidth', default: 0 }),
  settings: atom({
    key: 'settings',
    default: {}
  }),
  getCodeStates: atom({
    key: 'codeStates',
    default: {
      cptCodes: [],
      modifiers: [],
      icdCodes: []
    }
  }),
  issuerState: atom({
    key: 'issuerState',
    default: {
      options: [],
      map: {}
    }
  }),
  geographicalStatesState: atom({
    key: 'geographicalStatesState',
    default: []
  }),
  socketHasConnection: atom({
    key: 'socketHasConnection',
    default: false
  })
};
