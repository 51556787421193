import Tippy from '@tippyjs/react';
import React, { useRef } from 'react';
import Button from '../../../../../../../shared/Buttons/Button';
import Icon from '../../../../../../../shared/Icon/Icon';
import Modal from '../../../../../../../shared/Modal/Modal';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import EditRecordedTranscript from './EditRecordedTranscript';
import RecordedSpeakers from './RecordedSpeakers';
import LoadingTranscript from './Transcript/LoadingTranscript';

import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useMyScribeAIContext } from '../../../../../../../../lib/context/MyScribeAI/MyScribeAIContext';
import { ia, iaRa } from '../../../../../../../../lib/helpers/utility';

const TranscriptModal = ({ showModal = false, hideModal = () => null }) => {
  const { transcriptData, isUpsertProcessLoading, actionRef, loading } =
    useAmbientListeningContext() || {};

  const audioProcessingFooter = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[120px]" />
  );
  const audioProcessingIcon = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[20px] !rounded-full" />
  );
  const audioProcessingTitle = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[320px]" />
  );

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={
        <div className="flex items-center gap-[6px] self-center">
          {loading ? audioProcessingIcon : <Icon icon="new-microphone" size={24} color="primary" />}

          {loading ? (
            audioProcessingTitle
          ) : (
            <p className="text-base font-500 text-neutral-800">
              {ia(transcriptData, 1) ? 'Edit transcript' : 'Recording ended successfully!'}
            </p>
          )}
        </div>
      }
      slideFromRight
      className="w-[1020px]"
      footer={
        <div className="flex w-full justify-between">
          <Button
            disabled={isUpsertProcessLoading || loading}
            outlined
            text={loading ? audioProcessingFooter : 'Cancel'}
            onClick={hideModal}
            color="neutral"
          />

          <Button
            disabled={isUpsertProcessLoading || loading}
            loading={isUpsertProcessLoading}
            onClick={() => actionRef?.current?.upsert()}
            text={
              loading
                ? audioProcessingFooter
                : ia(transcriptData, 1)
                  ? 'Update transcript'
                  : 'Confirm & generate transcript'
            }
          />
        </div>
      }>
      {loading ? (
        <LoadingTranscript />
      ) : (
        <MyscribeTranscript setTranscriptData={actionRef?.current?.setTranscriptData} />
      )}
    </Modal>
  );
};

export default TranscriptModal;

const MyscribeTranscript = () => {
  const { transcriptData } = useAmbientListeningContext() || {};

  return iaRa(transcriptData)?.map((data, index) => {
    const isFirstRow = index === 0;
    const actionRef = useRef();

    return (
      <div key={index}>
        {!isFirstRow && (
          <div className="!my-4 flex justify-between !border-x-0 border-y !border-dashed !border-primary-100">
            <div className="flex gap-1">
              <Icon icon="new-microphone" color="primary" />
              <p className="!py-3 text-sm font-500 text-primary-700">Additional transcription</p>
            </div>

            <div
              onClick={() => actionRef?.current?.applyOriginalSpeakers()}
              className="flex items-center">
              <Button text="Use same participants" outlined size="small" />
            </div>
          </div>
        )}

        {isFirstRow && (
          <div>
            <div className="flex flex-wrap justify-between !rounded-lg bg-primary-50 !px-6 !py-4">
              <div className="flex items-center justify-start !gap-1">
                <Icon icon="new-info" size="15px" color="primary" />
                <div className="text-sm font-medium leading-snug text-primary-500">Disclaimer</div>
              </div>
              <p className="!mt-2 text-sm italic text-primary-900">
                AI-generated content cannot guarantee perfect accuracy and should always be reviewed
                diligently before rendering as finalized clinical documentation!
              </p>
            </div>

            <p className="!my-4 text-sm font-400 text-primary-900">
              To ensure the best results, we kindly request you to label the speakers who were
              present during the conversation.
            </p>
          </div>
        )}

        <RecordedSpeakers transcriptEnhanced={data} actionRef={actionRef} />

        <div className="!mt-4 flex gap-1.5">
          <p className="text-sm font-400 text-primary-900">Transcription</p>
          <Tippy
            content="If you find any part of the transcript unsatisfactory, you have the option to edit each line to your preference. You can start editing by simply clicking on the line you wish to modify."
            className="tippy-dark">
            <div>
              <Icon icon="new-info" color="neutral" />
            </div>
          </Tippy>
        </div>

        <EditRecordedTranscript transcriptData={data} className="!mt-4" />
      </div>
    );
  });
};
