import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  hpiContextList,
  hpiDurationList,
  hpiLocationList,
  hpiQualityList,
  hpiRelievingFactorList,
  hpiTimingList
} from '../../../../../constants';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Box from '../../../../practice/charts/ClinicalNote/shared/Box';
import Textarea from '../../../../shared/Textarea/Textarea';
import { userState } from '../../../../state';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Input from '../../../Input/Input';
import RadioButton from '../../../RadioButton/RadioButton';
import Select from '../../../Select/Select';
import '../Custom.scss';
import { HPIRevisions as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { formatSections } from '../lib/generalFormatFormHelperV2';

const HPIRevisions = ({
  forwardedRef,
  defaultValue,
  isClinicalNote,
  sectionRef,
  showNarrative = false,
  onChange
}) => {
  const { clinicalNote, setClinicalNote, cnDisplaySettings } = useClinicalNoteContext() || {};
  const [hpiRevisions, setHpiRevisions] = useState(initialValue);
  const [locked, setLocked] = useState(clinicalNote?.locked || false);
  const [timer, setTimer] = useState(null);

  const otherModifyingRef = useRef(null);
  const contextNoteRef = useRef(null);
  const signsAndSymptomsRef = useRef(null);
  const relievingFactorsNote = useRef(null);

  const [syncNarrative, setSyncNarrative] = useState(true);
  const user = useRecoilValue(userState);
  const isAdvancedForm = cnDisplaySettings
    ? cnDisplaySettings?.sections?.hp?.hpi?.advancedForm
    : true;
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setHpiRevisions(JSON.parse(defaultValue));
      } else {
        setHpiRevisions(defaultValue);
      }
    } else {
      setHpiRevisions(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setLocked(clinicalNote?.locked || false);
  }, [clinicalNote]);

  useEffect(() => {
    notifyChange(hpiRevisions);
  }, [hpiRevisions]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'HPIRevisions', type: 'custom' } });
    }
  };

  useImperativeHandle(sectionRef, () => ({
    formData: { hpi: hpiRevisions }
  }));

  const handleChange = ({ event, key, parentKey, childKey, isBoolean }) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    const updatedObject = {
      ...hpiRevisions
    };

    if (parentKey && childKey) {
      updatedObject[key] = {
        ...updatedObject[key],
        [name ?? childKey]: name ? (isBoolean ? !JSON.parse(value) : value) : event
      };
    } else {
      updatedObject[name ?? (childKey || key)] = name
        ? isBoolean
          ? !JSON.parse(value)
          : value
        : event;
    }

    if (syncNarrative) {
      handleNarrative({
        rosObject: updatedObject,
        event: formatSections({
          data: updatedObject,
          isProvider: user?.kind === 'practitioner',
          sectionName: 'HPI revisions'
        })?.trim()
      });
    } else {
      setHpiRevisions(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      hpi: updatedObject
    }));
  };

  const clearOppositeElement = ({ parentKey, key }) => {
    setHpiRevisions({
      ...hpiRevisions,
      [parentKey]: {
        ...hpiRevisions[parentKey],
        [key]: ''
      }
    });
  };

  const handleNarrative = ({
    rosObject = hpiRevisions,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...rosObject, narrative: event };

    setHpiRevisions(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative</p>
        </div>
      ),
      onClick: () => {
        const narrativeOptions = {
          data: hpiRevisions,
          isProvider: user?.kind === 'practitioner',
          sectionName: 'HPI revisions'
        };
        const formattedNarrative = formatSections(narrativeOptions)?.trim();

        handleNarrative({
          event: formattedNarrative,
          scrollable: true
        });
      }
    }
  ];

  return (
    <div className="hpi grid gap-y-4">
      <input type="hidden" value={JSON.stringify(hpiRevisions)} ref={forwardedRef} />
      {isAdvancedForm && (
        <Checkbox
          data-qa="no-details-to-report"
          label="No details to report"
          name="noDetails"
          disabled={locked}
          isChecked={hpiRevisions?.noDetails}
          onChange={() =>
            handleChange({
              event: !hpiRevisions.noDetails,
              key: 'noDetails',
              parentKey: 'hpi'
            })
          }
        />
      )}

      <div className="dashed-top"></div>

      {!hpiRevisions?.noDetails && isAdvancedForm && (
        <>
          <Box className="grid grid-cols-4 sm:flex sm:flex-col md:flex md:flex-col lg:grid-cols-2">
            <Select
              radius="50px"
              label="Location"
              placeholder="Type and select location"
              id="location"
              name="location"
              isCreatable
              options={hpiLocationList}
              disabled={locked}
              value={hpiRevisions?.location}
              onChange={(event) =>
                handleChange({
                  event,
                  key: 'location',
                  parentKey: 'hpi'
                })
              }
              data-qa="text-qa"
            />
            <Select
              label="Duration"
              placeholder="Type and select duration"
              id="duration"
              name="duration"
              isCreatable
              options={hpiDurationList}
              disabled={locked}
              value={hpiRevisions?.duration}
              onChange={(event) =>
                handleChange({
                  event,
                  key: 'duration',
                  parentKey: 'hpi'
                })
              }
            />
            <Select
              label="Duration of ailment"
              placeholder="Type and select duration"
              id="timing"
              name="timing"
              isCreatable
              options={hpiTimingList}
              disabled={locked}
              value={hpiRevisions?.timing}
              onChange={(event) =>
                handleChange({
                  event,
                  key: 'timing',
                  parentKey: 'hpi'
                })
              }
            />
            <Select
              label="Quality"
              placeholder="Type and select quality"
              id="quality"
              name="quality"
              isCreatable
              options={hpiQualityList}
              disabled={locked}
              value={hpiRevisions?.quality}
              onChange={(event) =>
                handleChange({
                  event,
                  key: 'quality',
                  parentKey: 'hpi'
                })
              }
            />
            <div className="col-span-2 grid gap-4">
              <Select
                label="What makes the problem or symptoms worse (certain activities, time of day, food, etc.)?"
                placeholder="Type and select context"
                id="context"
                name="context"
                isCreatable
                options={hpiContextList}
                disabled={locked}
                value={hpiRevisions?.context}
                onChange={(event) =>
                  handleChange({
                    event,
                    key: 'context',
                    parentKey: 'hpi'
                  })
                }
              />
              <Textarea
                label="Please provide any further context on present illness, if you wish:"
                placeholder="Add note here"
                transcribing
                forwardedRef={contextNoteRef}
                disabled={locked}
                value={hpiRevisions?.contextNote}
                name="contextNote"
                id="contextNote"
                onChange={(event) =>
                  handleChange({
                    event,
                    parentKey: 'hpi'
                  })
                }
              />
            </div>
            <div className="col-span-2 grid gap-4">
              <Select
                label="What makes the problem or symptoms better (rest, medication, certain positions, etc.)?"
                placeholder="Type and select relieving factor"
                id="relievingFactor"
                name="relievingFactor"
                isCreatable
                options={hpiRelievingFactorList}
                disabled={locked}
                value={hpiRevisions?.relievingFactor}
                onChange={(event) =>
                  handleChange({
                    event,
                    key: 'relievingFactor',
                    parentKey: 'hpi'
                  })
                }
              />
              <Textarea
                label="Please provide any further context on present illness, if you wish:"
                placeholder="Add note here"
                transcribing
                forwardedRef={relievingFactorsNote}
                disabled={locked}
                value={hpiRevisions?.relievingFactorsNote}
                name="relievingFactorsNote"
                id="relievingFactorsNote"
                onChange={(event) =>
                  handleChange({
                    event,
                    parentKey: 'hpi'
                  })
                }
              />
            </div>
          </Box>
          <div className="dashed-top"></div>
          <Box className="!gap-0">
            <div className="grid !pb-4">
              <span className="!pb-3 text-base font-500 leading-6 text-neutral-900">
                Modifying factors
              </span>
              <Checkbox
                data-qa="no-modifying-factors"
                label="No modifying factors"
                name="noDetails"
                disabled={locked}
                isChecked={hpiRevisions?.modifyingFactors?.noModifyingFactors}
                onChange={() =>
                  handleChange({
                    event: !hpiRevisions.modifyingFactors?.noModifyingFactors,
                    parentKey: 'hpi',
                    key: 'modifyingFactors',
                    childKey: 'noModifyingFactors'
                  })
                }
              />
            </div>
            <div className="dashed-top"></div>
            {!hpiRevisions?.modifyingFactors?.noModifyingFactors && (
              <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm:grid-cols-1">
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">
                      Have you taken any medication?
                    </p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="takenMedicationYes"
                        disabled={locked}
                        name="takenMedication"
                        isSelected={hpiRevisions?.modifyingFactors?.takenMedication === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'takenMedication'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="takenMedicationNo"
                        name="takenMedication"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.takenMedication === 'no'}
                        onChange={(event) => {
                          clearOppositeElement({
                            parentKey: 'modifyingFactors',
                            key: 'medication'
                          });
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'takenMedication'
                          });
                        }}
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.takenMedication === 'yes' && (
                    <Input
                      className="w-[280px]"
                      label="Medication"
                      placeholder="Medication"
                      disabled={locked}
                      value={hpiRevisions?.modifyingFactors?.medication}
                      transcribing
                      onChange={(event) =>
                        handleChange({
                          event: event.target.value,
                          parentKey: 'hpi',
                          key: 'modifyingFactors',
                          childKey: 'medication'
                        })
                      }
                    />
                  )}
                  {hpiRevisions?.modifyingFactors?.takenMedication === 'yes' && (
                    <div>
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedMedicationYes"
                          name="helpedMedication"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedMedication === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedMedication'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedMedicationNo"
                          name="helpedMedication"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedMedication === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedMedication'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">Have you applied heat?</p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="appliedHeatYes"
                        name="appliedHeat"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.appliedHeat === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'appliedHeat'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="appliedHeatNo"
                        name="appliedHeat"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.appliedHeat === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'appliedHeat'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.appliedHeat === 'yes' && (
                    <div>
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedHeatYes"
                          name="helpedHeat"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedHeat === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedHeat'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedHeatNo"
                          name="helpedHeat"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedHeat === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedHeat'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">
                      Have you applied cold compress of any kind?
                    </p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="appliedColdCompressYes"
                        name="appliedColdCompress"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.appliedColdCompress === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'appliedColdCompress'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="appliedColdCompressNo"
                        name="appliedColdCompress"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.appliedColdCompress === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'appliedColdCompress'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.appliedColdCompress === 'yes' && (
                    <div>
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedColdCompressYes"
                          name="helpedColdCompress"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedColdCompress === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedColdCompress'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedColdCompressNo"
                          name="helpedColdCompress"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedColdCompress === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedColdCompress'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">
                      Have you ever had surgery for this issue?
                    </p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="hadSurgeryYes"
                        name="hadSurgery"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.hadSurgery === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'hadSurgery'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="hadSurgeryNo"
                        name="hadSurgery"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.hadSurgery === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'hadSurgery'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.hadSurgery === 'yes' && (
                    <div className="">
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedSurgeryYes"
                          name="helpedSurgery"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedSurgery === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              grandParentKey: 'hp',
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedSurgery'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedSurgeryNo"
                          name="helpedSurgery"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedSurgery === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedSurgery'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">
                      Have you tried physical therapy?
                    </p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="triedPhysicalTherapyYes"
                        name="triedPhysicalTherapy"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'triedPhysicalTherapy'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="triedPhysicalTherapyNo"
                        name="triedPhysicalTherapy"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'triedPhysicalTherapy'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'yes' && (
                    <div className="">
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedPhysicalTherapyYes"
                          name="helpedPhysicalTherapy"
                          disabled={locked}
                          isSelected={
                            hpiRevisions?.modifyingFactors?.helpedPhysicalTherapy === 'yes'
                          }
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedPhysicalTherapy'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedPhysicalTherapyNo"
                          name="helpedPhysicalTherapy"
                          disabled={locked}
                          isSelected={
                            hpiRevisions?.modifyingFactors?.helpedPhysicalTherapy === 'no'
                          }
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedPhysicalTherapy'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">
                      Have you ever been on a respirator?
                    </p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="beenRespiratorYes"
                        name="beenRespirator"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.beenRespirator === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'beenRespirator'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="beenRespiratorNo"
                        name="beenRespirator"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.beenRespirator === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'beenRespirator'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.beenRespirator === 'yes' && (
                    <div className="">
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedRespiratorYes"
                          name="helpedRespirator"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedRespirator === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedRespirator'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedRespiratorNo"
                          name="helpedRespirator"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedRespirator === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedRespirator'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4">
                  <div>
                    <p className="!pb-3 font-500 text-neutral-800">Have you tried rest?</p>
                    <div className="flex">
                      <RadioButton
                        label="yes"
                        value="yes"
                        id="triedRestYes"
                        name="triedRest"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.triedRest === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'triedRest'
                          })
                        }
                      />
                      <RadioButton
                        label="no"
                        value="no"
                        id="triedRestNo"
                        name="triedRest"
                        disabled={locked}
                        isSelected={hpiRevisions?.modifyingFactors?.triedRest === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'triedRest'
                          })
                        }
                      />
                    </div>
                  </div>
                  {hpiRevisions?.modifyingFactors?.triedRest === 'yes' && (
                    <div className="">
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped?</p>
                      <div className="flex">
                        <RadioButton
                          label="yes"
                          value="yes"
                          id="helpedRestYes"
                          name="helpedRest"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedRest === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedRest'
                            })
                          }
                        />
                        <RadioButton
                          label="no"
                          value="no"
                          id="helpedRestNo"
                          name="helpedRest"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.helpedRest === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedRest'
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-span-full grid grid-cols-2 !py-4 md:grid-cols-1">
                  <div className="dashed-bottom flex flex-col gap-6 !pb-5 !pr-12 md:!pr-0">
                    <Textarea
                      label="Associated Signs and Symptoms"
                      placeholder="Add note here"
                      transcribing
                      forwardedRef={signsAndSymptomsRef}
                      disabled={locked}
                      value={hpiRevisions?.signsAndSymptoms}
                      name="signsAndSymptoms"
                      id="signsAndSymptoms"
                      onChange={(event) =>
                        handleChange({
                          event,
                          parentKey: 'hpi'
                        })
                      }
                    />
                  </div>
                  <div className="dashed-bottom flex flex-col gap-6 !pb-5 !pr-12 md:!pr-0">
                    <Textarea
                      label="Other modifying factors"
                      placeholder="Add note here"
                      transcribing
                      forwardedRef={otherModifyingRef}
                      disabled={locked}
                      value={hpiRevisions?.modifyingFactors?.otherModifyingFactors}
                      name="otherModifyingFactors"
                      id="otherModifyingFactors"
                      onChange={(event) =>
                        handleChange({
                          event,
                          parentKey: 'hpi',
                          key: 'modifyingFactors',
                          childKey: 'otherModifyingFactors'
                        })
                      }
                    />
                    <div className="w-max">
                      <p className="!pb-3 font-500 text-neutral-800">Has this helped or hurt?</p>
                      <div className="flex">
                        <RadioButton
                          label="helped"
                          value="helped"
                          id="helpedOtherModifyingFactorsHelped"
                          name="helpedOtherModifyingFactors"
                          disabled={locked}
                          isSelected={
                            hpiRevisions?.modifyingFactors?.helpedOtherModifyingFactors === 'helped'
                          }
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedOtherModifyingFactors'
                            })
                          }
                        />
                        <RadioButton
                          label="hurt"
                          value="hurt"
                          id="helpedOtherModifyingFactorsHurt"
                          name="helpedOtherModifyingFactors"
                          disabled={locked}
                          isSelected={
                            hpiRevisions?.modifyingFactors?.helpedOtherModifyingFactors === 'hurt'
                          }
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'helpedOtherModifyingFactors'
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </>
      )}

      {cnDisplaySettings && showNarrative && (
        <NarrativeForm
          syncNarrative={syncNarrative}
          setSyncNarrative={setSyncNarrative}
          onChange={(event) => handleNarrative({ event, sync: false })}
          setCurrentForm={setHpiRevisions}
          restData={{
            className: 'w-full',
            label: 'HPI Narrative',
            placeholder: 'Add narrative here',
            id: 'Narrative-hpi',
            'data-qa': 'narrative-hpi',
            name: 'Narrative-hpi',
            value: hpiRevisions?.narrative,
            forwardedRef: textAreaRef
          }}
          narrativeOptions={isAdvancedForm ? narrativeOptions : []}
        />
      )}
    </div>
  );
};

export default HPIRevisions;
