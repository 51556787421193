import React, { useState } from 'react';
import CommsCenterSidebarList from './CommsCenterSidebarList';
import cs from 'classnames';
import Icon from '../../../../shared/Icon/Icon';

const CommsCenterSidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const handleExpanded = () => setExpanded(!expanded);

  return (
    <div
      className={cs(
        'z-10 h-auto w-6 rounded-tr-lg rounded-br-lg bg-white px-1 !py-4 shadow-md duration-200 ease-out',
        expanded && 'w-[230px] min-w-[230px] !px-3'
      )}>
      <div className="sticky top-0">
        <Icon
          icon={expanded ? 'new-double-chevron-left' : 'new-arrow-right'}
          className="flex cursor-pointer justify-end !pb-1"
          onClick={handleExpanded}
        />
        <CommsCenterSidebarList expanded={expanded} />
      </div>
    </div>
  );
};

export default CommsCenterSidebar;
