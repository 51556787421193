import React, { useState } from 'react';
import { MyTemplateContext } from './MyTemplateContext';
import { generateSOAPList } from 'components/practice/charts/ClinicalNote/lib/generateSOAPList';
import { generateHPList } from 'components/practice/charts/ClinicalNote/lib/generateHPList';
import { generateIntakeList } from 'components/practice/charts/ClinicalNote/lib/generateIntakeList';
import { generateProgressList } from 'components/practice/charts/ClinicalNote/lib/generateProgressList';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { getEnabledComponents } from '../lib/getEnabledComponents';
import AdvancedSoap from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/AdvancedSoap';
import AdvancedHP from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/AdvancedHP';

export const MyTemplateContextProvider = ({ children }) => {
  const { cnDisplaySettings, advancedHP, advancedSOAP } = useClinicalNoteContext();

  const list = {
    soap: getEnabledComponents(
      generateSOAPList,
      'soap',
      cnDisplaySettings,
      advancedSOAP,
      AdvancedSoap
    ),
    hp: getEnabledComponents(
      // generateHPList(cnDisplaySettings, advancedHP),
      generateHPList,
      'hp',
      cnDisplaySettings,
      advancedHP,
      AdvancedHP
    ),
    intake: getEnabledComponents(
      generateIntakeList,
      'hp',
      cnDisplaySettings,
      advancedHP,
      AdvancedHP
    ),
    progress: getEnabledComponents(
      generateProgressList,
      'soap',
      cnDisplaySettings,
      advancedSOAP,
      AdvancedSoap
    ),
    '3d': generateIntakeList
  };
  console.log('🚀 ~ MyTemplateContextProvider ~ list:', list);

  const [components, setComponents] = useState(list);
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);

  return (
    <MyTemplateContext.Provider
      value={{ selectedChatComponents, setSelectedChatComponents, components, setComponents }}>
      {children}
    </MyTemplateContext.Provider>
  );
};
