import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Goals from '../../ProgressNote/components/Goals';

const GoalsSection = ({ components, sectionRef, options }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Goals', parentKey: 'intake', childKey: 'goals' }}
      title={components?.goals?.title}
      id={components?.goals?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Goals section="intake" sectionRef={sectionRef} />
    </Accordion>
  );
};

export default GoalsSection;
