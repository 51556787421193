import React from 'react';
import { useRecoilValue } from 'recoil';
import state from '../../state';
import { pm } from '../../../lib/helpers/utility';
import Button from '../Button/Button';
//import { name } from 'file-loader';
//import { id } from 'date-fns/locale';

const RoleTable = ({ children, loadMoreHandler, loading, canLoadMore, addRoleHandler }) => {
  const p = useRecoilValue(state.permissions);

  return (
    <table>
      <thead>
        <tr>
          <td>Name</td>
          <td>Description</td>
          <td>Permissions</td>
          <td>Users</td>
          <td>
            {pm(p, 'role.create') && (
              <Button onClick={addRoleHandler} success id="add-role">
                Add Role +
              </Button>
            )}
          </td>
        </tr>
      </thead>
      <tbody>
        {children}
        <tr>
          <td colSpan={5}>
            {canLoadMore ? (
              <Button onClick={loadMoreHandler} loading={loading} green id="load-more-roles" >
                Load More Roles
              </Button>
            ) : (
              <span>No more roles to show.</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RoleTable;
