import React, { useRef, useState } from 'react';
import Textarea from '../../../Textarea/Textarea';
import Select from '../../../Select/Select';
import Input from '../../../Input/Input';
import Icon from '../../../Icon/Icon';

import moment from 'moment-timezone';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { medications } from '../../../../../constants';
import Checkbox from '../../../Checkbox/Checkbox';
import cs from 'classnames';
import CustomFormPopover from '../components/CustomFormPopover';

const WeightLossAdministrationRow = ({
  medication,
  doses,
  dosesUnit,
  location,
  typeOfInjection,
  date,
  weight,
  notes,
  id,
  onDelete,
  onChange,
  disabled,
  length,
  index,
  className
}) => {
  const noteRef = useRef(null);

  const unitsOptions = [
    {
      value: 'mg',
      label: 'Miligrams (mg)'
    },
    {
      value: 'ml',
      label: 'Milliliters (ml)'
    },
    {
      value: 'units',
      label: 'Units'
    }
  ];

  const [currentUnit, setCurrentUnit] = useState(dosesUnit);

  const handleCurrentUnitChange = (unit) => {
    setCurrentUnit(unit);
    onChange('dosesUnit', unit, id);
  };

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <div className="grid w-full grid-cols-1 gap-4">
        <div className="grid w-full grid-cols-6 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <Select
            label="Medication"
            options={medications}
            value={medication}
            name="medication"
            onChange={(event) => onChange('medication', event, id)}
            isCreatable
            placeholder="Type and select medication"
            // withImage
          />
          <Input
            label="Dosage"
            placeholder="Add dosage"
            currentUnit={currentUnit}
            units={unitsOptions}
            onUnitChange={handleCurrentUnitChange}
            value={doses}
            onChange={(event) => onChange('doses', event.target.value, id)}
            disabled={disabled}
          />
          <Input
            label="Location"
            placeholder="Add location"
            value={location}
            onChange={(event) => onChange('location', event.target.value, id)}
            disabled={disabled}
          />
          <div>
            <label className="!mb-3 text-sm font-500 text-neutral-800">Type of injection</label>
            <div className="flex gap-x-4">
              <Checkbox
                rounded
                label="IM"
                disabled={disabled}
                isChecked={typeOfInjection === 'IM'}
                onChange={() => onChange('typeOfInjection', 'IM', id)}
              />
              <Checkbox
                rounded
                label="SQ"
                disabled={disabled}
                isChecked={typeOfInjection === 'SQ'}
                onChange={() => onChange('typeOfInjection', 'SQ', id)}
              />
            </div>
          </div>

          <div className="grid-cols-2">
            <CustomFormPopover
              button={
                <Input
                  label="Date"
                  rightIcon="new-calendar-gray"
                  value={moment.utc(date).format('MMM D, YYYY')}
                  disabled={disabled}
                  readOnly
                />
              }
              panel={
                <Calendar
                  date={new Date(date)}
                  onChange={(event) =>
                    onChange('date', moment.utc(event).add('12', 'h').format('MMM D, YYYY'), id)
                  }
                />
              }
            />
          </div>
          <Input
            label="Weight"
            placeholder="Add weight"
            value={weight}
            onChange={(event) => onChange('weight', event.target.value, id)}
            disabled={disabled}
            rightText="lbs"
          />
        </div>
        <Textarea
          label="Notes"
          placeholder="Add notes here"
          id="notes"
          className="col-start-1 col-end-3 grid"
          name="notes"
          value={notes}
          onChange={(event) => onChange('notes', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
      </div>
      <Icon icon="trash" onClick={() => onDelete(id)} />
    </div>
  );
};

export default WeightLossAdministrationRow;
