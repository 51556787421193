import React from 'react';
import { ia } from '../../../lib/helpers/utility';
import PermissionsEntry from './PermissionsEntry';
import PermissionsTable from './PermissionsTable';

const Permissions = ({ permissions, onChange = () => {} }) => {
  return (
    <div>
      <PermissionsTable>
        {permissions?.properties &&
          ia(permissions?.properties) &&
          permissions?.properties?.map((v, i) => (
            <PermissionsEntry permissions={v} onChange={onChange} index={i} />
          ))}
      </PermissionsTable>
    </div>
  );
};

export default Permissions;
