import Honeybadger from '@honeybadger-io/js';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { requestApi } from '../../../../../api/Api';
import { getStates as getStatesAPI } from '../../../../../api/State';
import { showAlert } from '../../../../shared/Alert/Alert';
import { userState } from '../../../../state';
import PayerForm from './NewPayerForm';

// eslint-disable-next-line react/display-name
const NewPayer = forwardRef(({ onClose }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [states, setStates] = useState([]);
  const user = useRecoilValue(userState);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Payer name is required.')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: '',
      zip: '',
      phone: ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let payerObject = {
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        name: values.name,
        phone: values.phone
      };

      await requestPayer(payerObject, resetForm);
      setSubmitting(false);
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const requestPayer = async (newPayer, resetForm) => {
    const onSuccess = (data) => {
      resetForm();
      queryClient.invalidateQueries(['getPayers']);
      onClose();
      showAlert({ title: 'Success!', message: 'Payer requested successfully!', color: 'success' });
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create payer!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/payers/create',
      params: { payer: newPayer, userId: user?.user_id },
      onSuccess,
      navigate,
      onError
    });
  };

  return (
    <div>
      <PayerForm states={states} formik={formik} />
    </div>
  );
});

export default NewPayer;
