import React, { useState } from 'react';
import AssessmentSection from '../../../../CurrentNote/TypeNotes/HPNote/components/AssessmentSection';
import CPTCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import MedicationsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/MedicationsSection';
import PlanSection from '../../../../CurrentNote/TypeNotes/HPNote/components/PlanSection';
import VitalsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import GeneralNoteSection from '../../../../CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import ObjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/ObjectiveSection';
import SubjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/SubjectiveSection';
import OrdersSection from '../../../../Orders/OrdersSection';
import FormsAI from '../FormsAI';
import AdvancedSOAP from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/AdvancedSOAP';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const getFormsList = (advancedSOAP, cnDisplaySettings) => {
  const formsList = {
    subjective: SubjectiveSection,
    objective: ObjectiveSection,
    assessment: AssessmentSection,
    medicationHistory: MedicationsSection,
    plan: PlanSection,
    vitals: VitalsSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection,
    ...assignAdvTitlesToComponent(advancedSOAP, AdvancedSOAP, cnDisplaySettings)
  };

  return formsList;
};

const SOAPFormsAI = ({ inputName }) => {
  const { advancedSOAP, cnDisplaySettings } = useClinicalNoteContext();

  const [formsList] = useState(getFormsList(advancedSOAP, cnDisplaySettings));

  return <FormsAI formsList={formsList} inputName={inputName} />;
};

export default SOAPFormsAI;
