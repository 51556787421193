import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { verifyEmail } from 'lib/helpers/utility';
import Button from 'components/shared/Button/Button';
import Input from 'components/shared/Input/Input';

const AdminPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [state, setState] = useState({ email_valid: false, email_sent: false });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Myriad - Password Reset`;
  }, []);

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email':
        setEmail(value);
        if (verifyEmail(value)) {
          setState({ ...state, email_valid: true });
        } else {
          setState({ ...state, email_valid: false });
        }
        break;
    }
  };

  const requestNewPassword = async () => {
    try {
      if (!verifyEmail(email)) {
        setState({ ...state, email_valid: false });
        toast.error(`Please enter a valid email!`);
        throw new Error(`Invalid email.`);
      }
      const resData = await requestApi({
        url: '/api/admin/password/request',
        params: { email: email },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setState({ ...state, email_sent: true });
          navigate(redirect);
          toast.success(`Successfully sent a password reset email!`);
          break;
        case 1:
          Honeybadger.notify(`requestNewPassword: ${error}`);
          break;

        default:
          if (error) {
            Honeybadger.notify(`requestNewPassword: ${error}`);
          } else {
            Honeybadger.notify(`requestNewPassword: ${error}`);
          }
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`requestNewPassword: ${error}`);
    }
  };

  if (state.email_sent) {
    return (
      <>
        <h3>Email has been sent!</h3>
        <Button
          success
          className="PasswordReset__button"
          onClick={() => setState({ ...state, email_sent: false })}>
          Send Another
        </Button>
      </>
    );
  }

  return (
    <>
      <h3>Password Reset</h3>
      <Input
        label="Please enter your email to request a password reset"
        id="resetPassword"
        value={email}
        name="email"
        type="email"
        onChange={handleUpdate}
        placeholder="example@email.com"
      />
      {!state.email_valid && email.length >= 5 && (
        <label className="!pt-2 text-left text-sm text-danger-500">Please enter a valid email</label>
      )}
      {state.email_valid && (
        <Button className="PasswordReset__button" success onClick={requestNewPassword}>
          Send Request
        </Button>
      )}
    </>
  );
};

export default AdminPasswordRequest;
