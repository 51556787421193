import React, { useEffect, useState } from 'react';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import List from '../../../../shared/CustomizableBoard/widgets/components/List';
import FooterWrapper from '../../../../shared/CustomizableBoard/widgets/components/FooterWrapper';
import { useAppointments } from '../../../../../lib/hooks/queries/widgets/useOverview';
import formatDate from '../../../../shared/CustomizableBoard/widgets/helpers/formatDate';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAppointments } from '../../../../../api/Widgets';
import { useNavigate } from 'react-router-dom';
import { reShapePractitioners, reShapeProcedures } from '../../../../../lib/helpers/utility';
import { showAlert } from '../../../../shared/Alert/Alert';
import { interimApi } from '../../../../../api/InterimApi';
import PreviewAppointment from '../../../appointment/PreviewAppointment/PreviewAppointment';
import moment from 'moment';

const Appointment = ({ item, past, showAppointment, index }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        showAppointment(item);
      }}
      data-qa={`appointment-${index}`}
      className={
        past
          ? 'flex cursor-pointer flex-col gap-3 px-[20px] py-3 hover:bg-primary-25'
          : 'flex cursor-pointer flex-col gap-3 bg-primary-50 bg-opacity-50 p-[20px] pt-[12px] hover:bg-primary-50'
      }>
      <div className="flex items-center justify-between gap-2">
        <p className="font-medium text-primary-600">
          {formatDate(item?.starts_at)} at {moment(item?.starts_at).format('h:mm A')}
        </p>
        {!past && (
          <Button
            onClick={() => {
              navigate(`./clinical-notes/${item?.id}/`);
            }}
            data-qa="start-visit-btn"
            size="small"
            text="Start Visit"
          />
        )}
      </div>

      <p className="line-clamp-3 text-neutral-800">
        {item?.invoice.procedures?.length > 0 &&
          item?.invoice.procedures.map((procedure, index) => {
            return (
              <span>
                {procedure.name}
                {index !== item?.invoice.procedures?.length - 1 && (
                  <span className="px-1 text-neutral-400">•</span>
                )}
              </span>
            );
          })}
      </p>
      <div className="flex items-center gap-2">
        <p className="text-neutral-800">Practitioner</p>
        <div className="h-[10px] w-[1px] bg-neutral-400"></div>
        <p className="font-medium text-neutral-900">{item?.practitioner?.fullName}</p>
      </div>
    </div>
  );
};

export default function Appointments(props) {
  const { patientId, changedSizes, layout } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateSize = async () => {
    const size = changedSizes.find((size) => size.i === props.item.i)?.h;

    if (size) {
      const newData = await getAppointments(navigate, { limit: size, patientId });
      queryClient.setQueryData(['overview-appointments', patientId], newData);
    }
  };

  useEffect(() => {
    updateSize();
  }, [changedSizes]);

  const { data } = useAppointments({
    params: { limit: layout.find((l) => l.i === props.item.i)?.h, patientId },
    dependencies: [patientId]
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [practitioners, setPractitioners] = useState([]);

  const { data: services } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  const showAppointment = (item) => {
    setSelectedAppointment(item);
    getAppointment(item?.id);
    setShowModal(true);
  };
  const hideModal = () => {
    setShowModal(false);
    setSelectedAppointment(null);
  };

  const getPractitioners = async () => {
    try {
      const res = await interimApi('/api/practice/member/get_practitioners', {}, navigate);
      const { redirect, practitioners: loadedPractitioners } = res.data;
      if (res.data.practitioners) {
        setPractitioners(loadedPractitioners);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
      showAlert({
        title: 'An unexpected error has occurred. Please try again later.',
        color: 'danger'
      });
    }
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  const getAppointment = async (id) => {
    let params = {
      id,
      withal: {
        medicalHistory: true,
        payments: true,
        patient: patientId,
        tag: true,
        resources: true
      }
    };

    const { data } = await interimApi('/api/appointment/get_one', params, navigate);

    if (data?.appointment)
      setSelectedAppointment((prevState) => ({ ...prevState, ...data?.appointment }));
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        icon="calendar-dates"
        onPlusClick={props.showNewAppointmentModal}
        {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col overflow-y-auto">
        <FooterWrapper
          link
          onClick={() => navigate(`./appointments/`)}
          text={`Appointments (${data?.count})`}>
          <List
            items={data?.upcoming}
            dividerType="full"
            renderItemComponent={(item, index) => (
              <Appointment showAppointment={showAppointment} item={item} index={index} />
            )}></List>

          <div className="flex-col gap-2">
            {data?.past?.length > 0 ? (
              <List
                items={data?.past}
                renderItemComponent={(item, index) => (
                  <Appointment showAppointment={showAppointment} item={item} past={true} index={index} />
                )}
                headerComponent={() => (
                  <div className="p-[20px] py-3">
                    <p className="font-medium text-neutral-500">Past Appointments</p>
                  </div>
                )}></List>
            ) : (
              <div className="p-[20px] py-3 text-center">
                <p className="font-medium text-neutral-500">No Past Appointments</p>
              </div>
            )}
          </div>
        </FooterWrapper>
      </div>

      {selectedAppointment && (
        <PreviewAppointment
          appointment={selectedAppointment}
          showPreviewAppointment={showModal}
          hidePreviewAppointment={hideModal}
          setAppointment={setSelectedAppointment}
          services={reShapeProcedures(services?.services)}
          practitioners={reShapePractitioners(practitioners)}
        />
      )}
    </div>
  );
}
