import React, { useState } from 'react';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useNavigate } from 'react-router-dom';
import { creationActionAppointment, remindAppointment } from 'api/Appointment';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { requestApi } from 'api/Api';
import { useQueryClient } from '@tanstack/react-query';
import { ia } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Actions = ({ data: appointment }) => {
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);
  const [loading, setLoading] = useState({
    cancel: false,
    restore: false
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const cancelAppointmentMessage =
    'Are you sure you want to cancel this appointment?' +
    (appointment?.invoice?.amount_paid > 0
      ? " We do not recommend cancelling appointments that have finished payments. Once done the payment will be reallocated to the patient's balance"
      : '');

  let restoreAppointmentMessage = `Are you sure you want to restore this appointment? `;

  if (ia(appointment?.invoice?.products)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment will cause negative inventory levels if the stock is empty. \n Proceeding with the restoration will set these products to negative inventory. Do you wish to continue?';
  }

  if (appointment?.invoice?.procedures?.some?.((p) => p?.invoiceId)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment involves a package that has already been fully utilized. As a result, the balance for this package will not be adjusted, and no additional services or products will be available for this appointment. \n Proceeding with the restoration will not add any new balances to the package. Do you wish to continue?';
  }

  const handleRemind = async (type = '') => {
    const params = { appointment_id: appointment?.id, type };
    await remindAppointment(navigate, params);
    showAlert({ message: 'Appointment reminder sent successfully', type: 'success' });
  };

  const handleCreationAction = async (type = '') => {
    const params = {
      appointment_id: appointment?.id,
      type
    };
    await creationActionAppointment(navigate, params);
    showAlert({ message: 'Appointment creation sent successfully', type: 'success' });
  };

  const cancelAppointment = async () => {
    setLoading({ ...loading, cancel: true });

    const onSuccess = () => {
      queryClient.invalidateQueries(['appointments']);
      queryClient.resetQueries(['appointment', appointment?.id]);

      setShowConfirmCancelModal(false);
      showAlert({ message: 'Appointment was canceled', color: 'success' });
    };

    await requestApi({
      url: '/api/appointment/cancel',
      navigate,
      params: { id: appointment?.id },
      onSuccess
    });
    setLoading({ ...loading, cancel: false });
  };

  const restoreAppointment = async () => {
    setLoading({ ...loading, restore: true });

    const onSuccess = () => {
      queryClient.invalidateQueries(['appointments']);
      queryClient.resetQueries(['appointment', appointment?.id]);

      setShowConfirmRestoreModal(false);
      showAlert({ message: 'Appointment was restored', color: 'success' });
    };

    const onError = (error) => {
      setShowConfirmRestoreModal(false);
      showAlert({ message: error, color: 'danger' });
    };

    await requestApi({
      url: '/api/appointment/restore',
      navigate,
      params: { id: appointment?.id },
      onSuccess,
      onError
    });
    setLoading({ ...loading, restore: false });
  };

  const appointmentActiveOptions = [
    {
      label: 'Resend Appointment Creation',
      icon: 'calendar-tick',
      children: [
        { label: 'Email', icon: 'new-email-blue', onClick: () => handleCreationAction('email') },
        {
          label: 'Text Message',
          icon: 'text-message',
          onClick: () => handleCreationAction('text_message')
        }
      ]
    },
    {
      label: 'Resend Appointment Reminder/Forms',
      icon: 'calendar-reminder',
      children: [
        { label: 'Email', icon: 'new-email-blue', onClick: () => handleRemind('email') },
        {
          label: 'Text Message',
          icon: 'text-message',
          onClick: () => handleRemind('text_message')
        }
      ]
    },
    {
      label: 'Cancel Appointment',
      icon: 'calendar-remove',
      onClick: () => setShowConfirmCancelModal(true)
    }
  ];

  const appointmentCancelledOptions = [
    {
      label: 'Restore Appointment',
      onClick: () => setShowConfirmRestoreModal(true)
    }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={
          appointment?.status === 'cancelled'
            ? appointmentCancelledOptions
            : appointmentActiveOptions
        }
      />
      {showConfirmCancelModal && (
        <Confirm
          handleOpen={showConfirmCancelModal}
          handleClose={() => setShowConfirmCancelModal(false)}
          handleContinue={cancelAppointment}
          title="Cancel appointment"
          message={cancelAppointmentMessage}
          primaryBtnTxt="Cancel appointment"
          secondaryBtnTxt="Keep"
          icon="new-calendar-red"
          variant="danger"
          loading={loading?.cancel}
        />
      )}
      {showConfirmRestoreModal && (
        <Confirm
          variant="primary"
          secondaryBtnTxt="Cancel"
          icon="restore-appointment"
          title="Restore appointment"
          loading={loading?.restore}
          message={restoreAppointmentMessage}
          handleContinue={restoreAppointment}
          primaryBtnTxt="Restore appointment"
          handleOpen={showConfirmRestoreModal}
          handleClose={() => setShowConfirmRestoreModal(false)}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Actions);
