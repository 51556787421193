import React, { useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { useLocation } from 'react-router-dom';

const EnhanceNote = () => {
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Enhance Current Note',
      name: '/enhance-current-note',
      path: pathname
    });
  }, [breadcrumbs]);
  return (
    <div className="p-2">
      <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      <div className="flex h-screen">EnhanceNote</div>
    </div>
  );
};

export default EnhanceNote;
