import React, { useState, useEffect, useRef } from 'react';
import Button from '../Buttons/Button';
import cs from 'classnames';

const AIChatInput = ({
  leftButtons = [
    <Button
      key="1"
      text="Back"
      outlined
      transparent
      size="small"
      className="!py-2 !pl-4 !pr-4"
      onClick={onGoBack}
    />
  ],
  rightButtons = [
    <Button
      key={1}
      text="Generate"
      icon="stars-ai"
      iconSize={18}
      iconColor="#fff"
      size="small"
      className={cs('!py-2 !pl-4 !pr-4', disabled ? 'bg-primary-100' : '')}
      onClick={onSubmit}
      disabled={disabled}
    />
  ],
  placeholder = 'Type your note...',
  className,
  disabled = false,
  onSubmit = () => {},
  onGoBack = () => {},
  onTextChange = () => {}
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputHeight] = useState('auto');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to content height
    }
  }, [inputValue]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onTextChange(event);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line on Enter
      onSubmit(inputValue);
      setInputValue(''); // Clear input after submit
    }
  };

  return (
    <div
      className={cs(
        'flex w-full flex-row items-end rounded-lg border border-gray-300 bg-[#FFF] p-3 shadow-2xl',
        className
      )}>
      <div className="flex items-end">{leftButtons}</div>
      <textarea
        ref={textareaRef}
        className="max-h-[200px] flex-grow resize-none overflow-y-auto border-none bg-transparent p-2 focus:outline-none"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        style={{ height: inputHeight }}
        rows={1}
      />
      <div className="flex items-end">{rightButtons}</div>
    </div>
  );
};

export default AIChatInput;
