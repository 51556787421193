import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from 'lib/helpers/utility';
import React from 'react';

const CPTCodes = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();
  const cptCodes = clinicalNote?.cpt_codes;

  if (customClinicalNote && !ia(cptCodes)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="!pb-4 text-base font-500 text-[#003A4B] print:text-xl">CPT® codes</h3>
      <div className="grid !gap-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 first-of-type:!border-t-0">
        {iaRa(cptCodes).map((code, index) => (
          <div key={index} className="grid gap-1">
            <p className="text-sm text-neutral-700 first-letter:capitalize">
              {`${index + 1}. CPT® code:`}
            </p>
            <p className="text-sm font-500 text-[#003A4B] first-letter:capitalize">
              {code?.procedure_code || code?.cpt || code?.code?.label || 'N/A'}
            </p>
            {ia(code?.modifiers) && (
              <div className="grid gap-1 !pl-6 pt-[2px]">
                <p className="text-base text-neutral-700 first-letter:capitalize">Modifiers:</p>
                <ul className="pl-3">
                  {code?.modifiers?.map((modifier, modifierIndex) => (
                    <li
                      key={modifierIndex}
                      className="text-sm font-500 text-[#003A4B] first-letter:capitalize">
                      {modifier?.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CPTCodes;
