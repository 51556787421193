import React, { useEffect, useState, useImperativeHandle } from 'react';
import TestForm from './components/TestForm';
import Diagnosis from './components/Diagnosis';
import Input from '../../../../../../shared/Input/Input';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import Checkbox from '../../../../../../shared/Checkbox/Checkbox';
import DatePopover from '../../../../../../shared/Popovers/Date/DatePopover';
import YourSignatureMedical from '../NonERXPrescriptionCreate/components/YourSignatureMedical';

import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ia, iaRa } from '../../../../../../../lib/helpers/utility';
import { userState } from '../../../../../../state';
import { useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import { LabOrderValidationSchema, getLabOrderData } from '../../lib/handleFormik';
import { useUser } from '../../../../../../../lib/hooks/queries/user/useUser';
import { currentPractice } from '../../../../../practiceState';
import { upsertLabOrder } from '../../../../../../../api/LabOrder';
import {
  insuranceAndGuarantorMapper,
  orderMapper,
  orderPhysicianMapper,
  patientMapper,
  testDefaultObject
} from '../../lib/labOrderHelpers';
import moment from 'moment';

const LabOrderCreate = ({ actionRef, itemProps = {}, setActiveView }) => {
  const { patient } = useOutletContext();

  const [tests, setTests] = useState([{ ...testDefaultObject, id: self.crypto.randomUUID() }]);

  const practice = useRecoilValue(currentPractice);
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (ia(itemProps?.labOrderTests)) setTests(iaRa(itemProps?.labOrderTests));
  }, [itemProps?.labOrderTests]);

  const { data: currentUserInfo } = useUser({
    dependencies: [user.id],
    options: {
      enabled: !!user.id,
      select: ({ user }) => user
    }
  });

  const { appointmentId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const upsertLabOrderMutation = useMutation((data) => upsertLabOrder(navigate, data), {
    onSuccess: (data) => {
      const { labOrder, labOrderTests } = data?.data || {};

      formik.setValues(labOrder);

      queryClient.invalidateQueries(['labOrder']);

      setActiveView((prevState) => ({
        ...prevState,
        loading: false,
        component: 'preview',
        itemProps: { labOrder, labOrderTests }
      }));
    },
    onSettled: () => {
      setActiveView((prevState) => ({ ...prevState, loading: false }));
    }
  });

  const initialFormikValues = {
    provider_id: user?.id,
    appointment_id: appointmentId,
    ...patientMapper({ patient }),
    ...insuranceAndGuarantorMapper({ insuranceProfile: patient?.insuranceProfile }),
    ...orderPhysicianMapper({ currentUserInfo }),
    ...orderMapper({ currentUserInfo, practice }),
    ...itemProps?.labOrder
  };

  const processAndFinalizeTests = () => {
    const updatedArray = iaRa(tests).map((row) => {
      if (!row?.created_at || !row?.updated_at) delete row?.id;

      return row;
    });

    return updatedArray;
  };

  const formik = useFormik({
    initialValues: getLabOrderData(initialFormikValues),
    validationSchema: LabOrderValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setActiveView((prevState) => ({ ...prevState, loading: true }));

      const signatureDataValues = values?.signatureData;

      delete values?.signatureData;

      await upsertLabOrderMutation.mutateAsync({
        testsData: processAndFinalizeTests(),
        upsertData: values,
        signatureData: { ...signatureDataValues, link: pathname }
      });
    }
  });

  useImperativeHandle(actionRef, () => ({
    onSubmit: formik.submitForm
  }));

  if (!currentUserInfo?.id) return <Skeleton height="100vh" width="100vw" />;

  return (
    <div>
      <TestForm tests={tests} setTests={setTests} />

      <div className="!mt-6 bg-primary-50 !p-3 rounded-lg">
        <Input
          id="location_name"
          data-qa="location-name"
          name="location_name"
          label="Location"
          placeholder="Location Name"
          type="text"
          value={formik?.values?.location_name}
          onChange={formik && formik?.handleChange}
        />

        <div className="grid grid-cols-2 items-center !gap-3 !container mt-3">
          <DatePopover
            label="Draw Date Time"
            placementY="bottom-[110%]"
            value={formik?.values?.draw_date_time || moment().toDate()}
            onChange={(e) => formik?.setFieldValue('draw_date_time', e)}
          />

          <div>
            <label className='font-500'>Draw At Service Center</label>
            <Checkbox
              color="blue"
              checked={formik?.values?.draw_at_service_center}
              handleClick={(event) =>
                formik?.setFieldValue('draw_at_service_center', event.target.checked)
              }
            />
          </div>
        </div>
      </div>

      <div className="!mt-6 bg-primary-50 !p-3 rounded-lg">
        <Diagnosis formik={formik} />
      </div>

      <div className="!mt-6 bg-primary-50 !p-3 rounded-lg">
        <YourSignatureMedical formik={formik} actionType={'lab_order'} />
      </div>
    </div>
  );
};

export default LabOrderCreate;
