import React, { useEffect, useState } from 'react';
import Loader from 'components/shared/Loader/Loader';
import Button from '../../../../shared/Buttons/Button';
import RenderedComponents from '../PreviewNote/Rendered/components/RenderedComponents';

import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { mapRenderedInitialState, processSelectedForms } from '../PreviewNote/lib/renderedHelper';
import { selectedInitialState } from '../PreviewNote/lib/initials';
import {
  prepareSelection,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../PreviewNote/lib/selectedHelper';

const PreviewSelectedNote = ({ componentProps, cancel = () => null, loadNote = () => null }) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const { clinicalNote, overviewData, permissions, advancedHP, advancedSOAP } =
    componentProps?.customClinicalNote || {};

  useEffect(() => {
    getSelected();
  }, [advancedHP, advancedSOAP, cnDisplaySettings, overviewData, permissions]);

  const getSelected = () => {
    setLoading(true);

    const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
    const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);

    let selectedObj = {
      ...selectedInitialState(
        clinicalNote,
        cnDisplaySettings,
        overviewData,
        overviewData?.vitals,
        permissions
      ),
      ...getAdvancedHP,
      ...getAdvancedSOAP
    };

    const formattedSelect = prepareSelection({ initialSelection: selectedObj });

    const rendered = mapRenderedInitialState(formattedSelect);

    const { checkedRendered, hpForms, soapForms } = processSelectedForms({
      selected: formattedSelect,
      rendered
    });

    setSelected({ checkedRendered, hpForms, soapForms });
    setLoading(false);
  };

  if (loading) {
    return <Loader className="!text-primary-500" outlined={true} />;
  }

  return (
    <div className="!p-3 bg-primary-50 border border-solid !border-primary-500 !mt-1 !rounded-lg">
      <div>
        <RenderedComponents {...selected} {...componentProps} className="!bg-transparent" />
      </div>

      <div className="flex items-center justify-between !mt-4 !p-1 !rounded-lg bg-white border border-solid !border-primary-500 sticky bottom-1">
        <Button text="Cancel" outlined={true} size="small" onClick={cancel} data-qa="cancel-btn" />
        <Button text="Load" color="primary" size="small" onClick={loadNote} data-qa="load-btn" />
      </div>
    </div>
  );
};

export default PreviewSelectedNote;
