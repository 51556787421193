import { requestApi } from './Api';

export const getClinicalNotes = async (navigate, params) =>
  await requestApi({
    url: '/api/clinical_note/read_multiple',
    navigate,
    params
  });

export const getClinicalNote = async (navigate, params) =>
  await requestApi({
    url: '/api/clinical_note/read',
    navigate,
    params
  });
