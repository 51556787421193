import Tippy from '@tippyjs/react';
import React from 'react';
import { yesAndNoOptions } from 'constants';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import { shouldDisableDelete } from '../lib/shouldDisableDelete';
import { useRecoilValue } from 'recoil';
import { getHours } from 'components/practice/charts/SuperBillPage/lib/getHours';
import practiceState from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import { calculateTotalCharge } from 'components/practice/charts/SuperBillPage/lib';
import { generateBaseUnit } from 'components/practice/charts/SuperBillPage/lib/generateBaseUnit';
import { conditionalFormatTimeZ } from 'components/practice/charts/SuperBillPage/lib/conditionTimeFormat';
export const Procedure = ({
  procedure,
  handleCPTSearch,
  selectCPT,
  hasBaseUnit,
  index,
  claim,
  setClaim = () => {}
}) => {
  const claimProcedures = claim?.procedures || [];
  const isDeleteDisabled = shouldDisableDelete({ claim, procedure });

  const updateProcedures = (event, index, key) => {
    const field = event?.target?.name;
    let value = event?.target?.value;

    setClaim((prevClaim = { procedures: [] }) => {
      const updatedProcedures = [...prevClaim.procedures];
      updatedProcedures[index] = {
        ...updatedProcedures[index],
        [field ?? key]: value ?? (event?.label || event)
      };
      return {
        ...prevClaim,
        procedures: updatedProcedures,
        total_charge: calculateTotalCharge(updatedProcedures)
      };
    });
  };

  const handleDeletion = (index) => {
    let procedures = [...claimProcedures];
    let totalCharge = parseFloat(claim?.total_charge).toFixed(2);
    totalCharge -= parseFloat(procedures[index].charge).toFixed(2);
    procedures.splice(index, 1);
    setClaim({ ...claim, procedures, total_charge: totalCharge.toString() });
  };

  const currPractice = useRecoilValue(practiceState.currentPractice);
  const formattedStartTime = conditionalFormatTimeZ(
    procedure?.appointment_start,
    currPractice?.timezone
  );
  const formattedEndTime = conditionalFormatTimeZ(
    procedure?.appointment_end,
    currPractice?.timezone
  );

  const hours = getHours(currPractice, formattedStartTime, formattedEndTime);

  let timeOptions = hours.map((time, index) => ({
    value: time,
    label: time
  }));

  return (
    <TippyContainer isDisabled={isDeleteDisabled}>
      <tr>
        <td className="!p-1">
          <div className="grid grid-cols-2 items-end gap-[6px]">
            <DatePopover
              placeholder="From"
              rightIcon={false}
              name="from_date"
              value={procedure?.from_date}
              onChange={(event) => updateProcedures(event, index, 'from_date')}
              disabled={isDeleteDisabled}
            />
            <DatePopover
              placeholder="To"
              rightIcon={false}
              name="thru_date"
              value={procedure?.thru_date}
              onChange={(event) => updateProcedures(event, index, 'thru_date')}
              disabled={isDeleteDisabled}
            />
          </div>
        </td>
        <td className="!p-1">
          <Input
            data-qa="ndc"
            id="NDC"
            placeholder="NDC"
            value={procedure.narrative}
            name="narrative"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Input
            data-qa="place-of-service"
            id="Place"
            placeholder="Place of service"
            value={procedure.place_of_service}
            name="place_of_service"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Select
            parentClassName="min-w-[144px]"
            inputId="emg-select"
            placeholder="Emergency"
            options={yesAndNoOptions}
            value={
              procedure?.amb_emergency
                ? { value: procedure?.amb_emergency, label: procedure?.amb_emergency }
                : null
            }
            name="amb_emergency"
            onChange={(event) => updateProcedures(event, index, 'amb_emergency')}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Select
            data-qa="pro-code"
            isAsync
            inputId="cptCodes"
            cacheOptions
            loadOptions={handleCPTSearch}
            noOptionsMessage={() => 'Start typing the code or description.'}
            value={{ label: procedure.code }}
            menuPortalTarget={document.body}
            onChange={(e) => selectCPT(e, index)}
            isClearable={false}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Input
            data-qa="pro-modifiers"
            value={procedure.modifiers}
            id="Modifiers"
            placeholder="Modifiers"
            name="modifiers"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Input
            data-qa="pro-diagnosis"
            value={procedure.diagnosis}
            id="Diagnosis"
            placeholder="Diagnosis"
            name="diagnosis"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Input
            data-qa="pro-charge"
            value={procedure.charge}
            id="Charge"
            placeholder="Charge"
            name="charge"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        <td className="!p-1">
          <Input
            data-qa="pro-units"
            value={procedure.units}
            id="Units"
            placeholder="Units"
            name="units"
            onChange={(e) => updateProcedures(e, index)}
            disabled={isDeleteDisabled}
          />
        </td>
        {procedure?.base_unit && (
          <>
            <td className="!p-1">
              <Select
                placeholder="Start Time"
                isClearable={false}
                className="!-mt-[2px] !w-32"
                rightIcon="new-clock-gray"
                options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
                onChange={(val) =>
                  updateProcedures({ target: { value: val.value, name: 'start_time' } }, index)
                }
                value={
                  timeOptions.find((option) => option.value === procedure.start_time) ||
                  timeOptions.find((option) => option.value == formattedStartTime)
                }
                name="start_time"
              />
            </td>
            <td className="!p-1">
              <Select
                placeholder="End Time"
                isClearable={false}
                className="!-mt-[2px] !w-32"
                rightIcon="new-clock-gray"
                options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
                onChange={(val) =>
                  updateProcedures({ target: { value: val.value, name: 'end_time' } }, index)
                }
                value={
                  timeOptions.find((option) => option.value === procedure.end_time) ||
                  timeOptions.find((option) => option.value == formattedEndTime)
                }
                name="end_time"
              />
            </td>
            <td className="!p-1">
              <Button
                onClick={() =>
                  generateBaseUnit({
                    procedure,
                    formattedStartTime,
                    formattedEndTime,
                    index,
                    updateProcedures
                  })
                }
                text="Calculate Unit"
                className="mb-1 mt-auto max-h-[40px] w-full"
              />
            </td>
          </>
        )}
        {hasBaseUnit && !procedure.base_unit && (
          <>
            <td className="!p-1 !pr-[10px]"></td>
            <td className="!p-1 !pr-[10px]"></td>
            <td className="!p-1 !pr-[10px]"></td>
          </>
        )}
        <td className="!p-1 !pr-[10px]">
          <Icon
            icon="trash"
            onClick={() => handleDeletion(index)}
            data-qa="click-delete-icon"
            disabled={isDeleteDisabled}
          />
        </td>
      </tr>
    </TippyContainer>
  );
};

const TippyContainer = ({ isDisabled = false, children }) => {
  return (
    <Tippy
      className="tippy-dark"
      disabled={!isDisabled}
      content="Service cannot be removed because the clinical note is locked.">
      {children}
    </Tippy>
  );
};
