import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { SocialHistory as initialValue } from 'components/shared/Forms/Custom/CustomFormInitialStates';
import CustomFormPopover from 'components/shared/Forms/Custom/components/CustomFormPopover';
import {
  scrollIntoNarrativeView,
  submitForm
} from 'components/shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { permissions, userState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import './SocialHistory.scss';

const SocialHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative = false,
  fromClinicalNote = false,
  onChange,
  setItemModal = () => {}
}) => {
  const [socialHistory, setSocialHistory] = useState(initialValue);
  const [prevSocialHistory, setPrevSocialHistory] = useState(initialValue);
  const { practice_id } = useRecoilValue(permissions);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');

  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.socialHistory?.advancedForm
      : true;
  const user = useRecoilValue(userState);
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setSocialHistory(JSON.parse(defaultValue));
      } else {
        setSocialHistory(defaultValue);
      }
    } else {
      setSocialHistory(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(socialHistory);
  }, [socialHistory]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setSocialHistory;
    }
  }, []);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'SocialHistory', type: 'custom' } });
    }
  };

  const syncSocialHistoryAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ socialHistoryObject: updatedObject });
    } else {
      setSocialHistory(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleNumberOfChildren = (nr) => {
    let newArray = [];
    let obj = { age: null };
    for (let i = 0; i < nr; i++) {
      newArray.push(obj);
    }
    handleOnChange({
      children: {
        ...socialHistory.children,
        numberOfChildren: nr,
        ages: newArray
      }
    });
    setCurrentFormData &&
      setCurrentFormData((prevState) => ({
        ...prevState,
        children: {
          ...socialHistory.children,
          numberOfChildren: nr,
          ages: newArray
        }
      }));
  };

  const updateChildAge = (e, idx) => {
    const updatedArr = [...socialHistory.children.ages];
    updatedArr[idx] = e;
    handleOnChange({
      children: {
        ...socialHistory.children,
        ages: updatedArr
      }
    });
    setCurrentFormData &&
      setCurrentFormData((prevState) => ({
        ...prevState,
        children: {
          ...socialHistory.children,
          ages: updatedArr
        }
      }));
  };

  const doYou = 'Do you';
  const doesThePatient = 'Does the patient';

  const handleNoDetails = () => {
    const updatedObject = {
      ...socialHistory,
      noDetails: !socialHistory.noDetails
    };

    syncSocialHistoryAndClinicalNotes(updatedObject);
  };

  const handleOnChange = (object) => {
    const updatedObject = {
      ...socialHistory,
      ...object
    };
    syncSocialHistoryAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    socialHistoryObject = socialHistory,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...socialHistoryObject, narrative: event };

    setSocialHistory(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ socialHistoryObject = socialHistory, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: socialHistoryObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'social history'
    };
    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      socialHistoryObject,
      event: formattedNarrative,
      scrollable
    });
  };
  const mutateDeleteMedicalHistory = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Social History',
          message: error,
          color: 'warning'
        });
        return;
      }
      setSocialHistory(initialValue);
      setShowConfirmModal(false);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'socialHistory'
        });
      showAlert({
        title: `Social History deleted successfully`
      });

      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });

  return (
    <div className="flex flex-row">
      <div className="SocialHistory w-full">
        <input type="hidden" value={JSON.stringify(socialHistory)} ref={forwardedRef} />

        {isAdvancedForm && (
          <Checkbox
            label="No notable social history"
            data-qa="no-notable-social-history"
            name="noDetails"
            isChecked={socialHistory.noDetails}
            onChange={handleNoDetails}
          />
        )}

        <div className="dashed-top !mt-4"></div>

        {!socialHistory.noDetails && isAdvancedForm && (
          <div className="SocialHistory__grid">
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} smoke cigarettes?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="never"
                  label="Never"
                  rounded
                  isChecked={socialHistory?.cigarettes?.never}
                  onChange={() =>
                    handleOnChange({
                      cigarettes: {
                        ...socialHistory?.cigarettes,
                        never: !socialHistory?.cigarettes?.never,
                        yes: false,
                        packsDay: '',
                        quit: false,
                        dateQuit: null,
                        yearsSmoked: ''
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory?.cigarettes?.yes}
                  onChange={() => {
                    handleOnChange({
                      cigarettes: {
                        ...socialHistory.cigarettes,
                        yes: !socialHistory.cigarettes?.yes,
                        never: false,
                        quit: false,
                        dateQuit: null,
                        yearsSmoked: ''
                      }
                    });
                  }}
                />
                <Checkbox
                  data-qa="quit"
                  label="Quit"
                  rounded
                  isChecked={socialHistory.cigarettes?.quit}
                  onChange={() =>
                    handleOnChange({
                      cigarettes: {
                        ...socialHistory.cigarettes,
                        quit: !socialHistory.cigarettes?.quit,
                        dateQuit: new Date(),
                        never: false,
                        yes: false
                      }
                    })
                  }
                />
              </div>
              {socialHistory.cigarettes?.yes && (
                <div className="flex items-center gap-1">
                  <Input
                    data-qa="packs-per-day"
                    label="Packs per day"
                    type="number"
                    pattern="\d*"
                    min="0"
                    className="w-full text-right"
                    value={socialHistory.cigarettes.packsDay}
                    onChange={(e) =>
                      handleOnChange({
                        cigarettes: {
                          ...socialHistory.cigarettes,
                          packsDay: e.target.value
                        }
                      })
                    }
                  />
                </div>
              )}

              {socialHistory.cigarettes?.quit && (
                <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
                  <Input
                    data-qa="years-smoked"
                    label="Years smoked"
                    placeholder="Type how many years smoked"
                    transcribing
                    value={socialHistory.cigarettes.yearsSmoked}
                    onChange={(e) =>
                      handleOnChange({
                        cigarettes: {
                          ...socialHistory.cigarettes,
                          yearsSmoked: e.target.value
                        }
                      })
                    }
                  />
                  <div className="relative">
                    <span className="pb-[6px] text-sm font-500 text-neutral-800">Quit date</span>

                    <CustomFormPopover
                      popoverClassName="!w-full"
                      buttonClassName="!w-full !p-0 !mt-1"
                      button={
                        <Input
                          data-qa="new-calendar-gray"
                          rightIcon="new-calendar-gray"
                          value={moment
                            .utc(socialHistory.cigarettes.dateQuit)
                            .format('MMM Do, YYYY')}
                        />
                      }
                      panel={
                        <Calendar
                          data-qa="date-socialhistory"
                          date={new Date(socialHistory.cigarettes.dateQuit)}
                          onChange={(date) => {
                            handleOnChange({
                              cigarettes: {
                                ...socialHistory.cigarettes,
                                dateQuit: moment.utc(date).add(12, 'h').format('YYYY-MM-DD')
                              }
                            });
                            close();
                          }}
                        />
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} vape (e-cigarettes)?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory.vape?.yes}
                  onChange={() =>
                    handleOnChange({
                      vape: {
                        ...socialHistory.vape,
                        yes: !socialHistory.vape?.yes,
                        no: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="no"
                  label="No"
                  rounded
                  isChecked={socialHistory.vape?.no}
                  onChange={() =>
                    handleOnChange({
                      vape: {
                        ...socialHistory.vape,
                        no: !socialHistory.vape?.no,
                        yes: false
                      }
                    })
                  }
                />
              </div>
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} drink alcohol?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory.alcohol?.yes}
                  onChange={() =>
                    handleOnChange({
                      alcohol: {
                        ...socialHistory.alcohol,
                        yes: !socialHistory.alcohol?.yes,
                        never: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="no"
                  label="No"
                  rounded
                  isChecked={socialHistory.alcohol?.never}
                  onChange={() =>
                    handleOnChange({
                      alcohol: {
                        ...socialHistory.alcohol,
                        never: !socialHistory.alcohol?.never,
                        yes: false,
                        drinksWeek: ''
                      }
                    })
                  }
                />
              </div>

              {socialHistory.alcohol?.yes && (
                <div className="flex items-center gap-1">
                  <Input
                    data-qa="drinks"
                    className="w-full"
                    label="Drinks"
                    placeholder="Enter many drinks per week"
                    transcribing
                    value={socialHistory.alcohol.drinksWeek}
                    onChange={(e) =>
                      handleOnChange({
                        alcohol: {
                          ...socialHistory.alcohol,
                          drinksWeek: e.target.value
                        }
                      })
                    }
                  />
                  <div className="flex gap-x-6">
                    <Checkbox
                      data-qa="men"
                      label="Men"
                      isChecked={socialHistory.sexualActivity?.men}
                      onChange={() => {
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            men: !socialHistory.sexualActivity?.men
                          }
                        });
                      }}
                    />
                    <Checkbox
                      data-qa="women"
                      label="Women"
                      isChecked={socialHistory.sexualActivity?.women}
                      onChange={() =>
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            women: !socialHistory.sexualActivity?.women
                          }
                        })
                      }
                    />
                    <Checkbox
                      data-qa="both"
                      label="Both"
                      isChecked={
                        socialHistory.sexualActivity.men && socialHistory.sexualActivity?.women
                      }
                      handleClick={() =>
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            men:
                              socialHistory.sexualActivity.men &&
                              socialHistory.sexualActivity?.women
                                ? false
                                : true,
                            women:
                              socialHistory.sexualActivity.men &&
                              socialHistory.sexualActivity?.women
                                ? false
                                : true
                          }
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} use recreational drugs?
              </p>
              <div className="SocialHistory__row__content grid-cols-[repeat(auto-fit,minmax(96px,1fr))]">
                <Checkbox
                  data-qa="never"
                  label="Never"
                  isChecked={socialHistory.drugs?.never}
                  onChange={() =>
                    handleOnChange({
                      drugs: {
                        ...socialHistory.drugs,
                        never: !socialHistory.drugs?.never,
                        rarely: false,
                        timesMonth: '',
                        marijuana: false,
                        cocaine: false,
                        opioid: false,
                        other: false,
                        otherText: ''
                      }
                    })
                  }
                />
                <div className="grid gap-y-4">
                  <Checkbox
                    data-qa="rarely"
                    label="Rarely"
                    isChecked={socialHistory.drugs?.rarely}
                    onChange={() =>
                      handleOnChange({
                        drugs: {
                          ...socialHistory.drugs,
                          rarely: !socialHistory.drugs?.rarely,
                          never: false
                        }
                      })
                    }
                  />
                  {socialHistory.drugs?.rarely && (
                    <Input
                      data-qa="amount"
                      label="Amount"
                      transcribing
                      placeholder="Enter how many times per month"
                      className="max-w-[280px] lg:max-w-none"
                      value={socialHistory.drugs.timesMonth}
                      onChange={(e) =>
                        handleOnChange({
                          drugs: {
                            ...socialHistory.drugs,
                            timesMonth: e.target.value,
                            never: false
                          }
                        })
                      }
                    />
                  )}
                </div>

                <Checkbox
                  data-qa="marijuana"
                  label="Marijuana"
                  isChecked={socialHistory.drugs?.marijuana}
                  onChange={() =>
                    handleOnChange({
                      drugs: {
                        ...socialHistory.drugs,
                        marijuana: !socialHistory.drugs?.marijuana,
                        never: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="cocaine"
                  label="Cocaine"
                  isChecked={socialHistory.drugs?.cocaine}
                  onChange={() =>
                    handleOnChange({
                      drugs: {
                        ...socialHistory.drugs,
                        cocaine: !socialHistory.drugs?.cocaine,
                        never: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="opioids"
                  label="Opioids"
                  isChecked={socialHistory.drugs?.opioid}
                  onChange={() =>
                    handleOnChange({
                      drugs: {
                        ...socialHistory.drugs,
                        opioid: !socialHistory.drugs?.opioid,
                        never: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="other"
                  label="Other"
                  isChecked={socialHistory.drugs?.other}
                  onChange={() =>
                    handleOnChange({
                      drugs: {
                        ...socialHistory.drugs,
                        other: !socialHistory.drugs?.other,
                        never: false
                      }
                    })
                  }
                />
                {socialHistory.drugs?.other && (
                  <Input
                    className="col-span-full w-full"
                    data-qa="enter-other-drugs"
                    label="Other"
                    placeholder="Enter other drugs"
                    value={socialHistory.drugs?.otherText}
                    onChange={(e) =>
                      handleOnChange({
                        drugs: {
                          ...socialHistory.drugs,
                          otherText: e.target.value
                        }
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? "What's the patient's" : "What's your"} highest level of education
                completed?
              </p>
              <div className="SocialHistory__row__content grid-cols-[repeat(auto-fit,minmax(118px,1fr))]">
                <Checkbox
                  rounded
                  label="High school"
                  isChecked={socialHistory.education?.highSchool}
                  onChange={() =>
                    handleOnChange({
                      education: {
                        ...socialHistory.education,
                        highSchool: !socialHistory.education?.highSchool,
                        tradeSchool: false,
                        college: false,
                        postGraduateDegree: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="trade-school"
                  label="Trade school"
                  rounded
                  isChecked={socialHistory.education?.tradeSchool}
                  onChange={() =>
                    handleOnChange({
                      education: {
                        ...socialHistory.education,
                        tradeSchool: !socialHistory.education?.tradeSchool,
                        highSchool: false,
                        college: false,
                        postGraduateDegree: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="college"
                  label="College"
                  rounded
                  isChecked={socialHistory.education?.college}
                  onChange={() =>
                    handleOnChange({
                      education: {
                        ...socialHistory.education,
                        college: !socialHistory.education?.college,
                        highSchool: false,
                        tradeSchool: false,
                        postGraduateDegree: false
                      }
                    })
                  }
                />
                <Checkbox
                  label="Post-graduate degree(s)"
                  className="col-span-full"
                  rounded
                  isChecked={socialHistory.education?.postGraduateDegree}
                  onChange={() =>
                    handleOnChange({
                      education: {
                        ...socialHistory.education,
                        postGraduateDegree: !socialHistory.education?.postGraduateDegree,
                        highSchool: false,
                        tradeSchool: false,
                        college: false
                      }
                    })
                  }
                />
              </div>
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? 'Is the patient' : 'Are you'} employed?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory.employed?.yes}
                  onChange={() =>
                    handleOnChange({
                      employed: {
                        ...socialHistory.employed,
                        yes: !socialHistory.employed?.yes,
                        no: false,
                        retired: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="no"
                  label="No"
                  rounded
                  isChecked={socialHistory.employed?.no}
                  onChange={() =>
                    handleOnChange({
                      employed: {
                        ...socialHistory.employed,
                        no: !socialHistory.employed?.no,
                        retired: false,
                        yes: false,
                        typeOfWork: ''
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="retired"
                  label="Retired"
                  rounded
                  isChecked={socialHistory.employed?.retired}
                  onChange={() =>
                    handleOnChange({
                      employed: {
                        ...socialHistory.employed,
                        retired: !socialHistory.employed?.retired,
                        no: false,
                        yes: false,
                        typeOfWork: ''
                      }
                    })
                  }
                />
              </div>
              {socialHistory.employed?.yes && (
                <Input
                  data-qa="type-of-work"
                  className="max-w-[280px] lg:max-w-none"
                  label="Type of work"
                  placeholder="Enter type of work"
                  transcribing
                  value={socialHistory.employed.typeOfWork}
                  onChange={(e) =>
                    handleOnChange({
                      employed: {
                        ...socialHistory.employed,
                        typeOfWork: e.target.value
                      }
                    })
                  }
                />
              )}
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} exercise?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory.exercise?.yes}
                  onChange={() =>
                    handleOnChange({
                      exercise: {
                        ...socialHistory.exercise,
                        yes: !socialHistory.exercise?.yes,
                        no: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="no"
                  label="No"
                  rounded
                  isChecked={socialHistory.exercise?.no}
                  onChange={() =>
                    handleOnChange({
                      exercise: {
                        ...socialHistory.exercise,
                        no: !socialHistory.exercise?.no,
                        yes: false,
                        type: '',
                        howOften: '',
                        howLongActivity: ''
                      }
                    })
                  }
                />
              </div>
              {socialHistory.exercise?.yes && (
                <div className="flex flex-col gap-4">
                  <Input
                    data-qa="type-of-exercise"
                    label="Type of exercise"
                    placeholer="Enter type of exercise"
                    transcribing
                    value={socialHistory.exercise.type}
                    onChange={(e) =>
                      handleOnChange({
                        exercise: {
                          ...socialHistory.exercise,
                          type: e.target.value
                        }
                      })
                    }
                  />
                  <Input
                    data-qa="how-often"
                    label="How often"
                    placeholer="Enter how often patient exercises"
                    transcribing
                    value={socialHistory.exercise.howOften}
                    onChange={(e) =>
                      handleOnChange({
                        exercise: {
                          ...socialHistory.exercise,
                          howOften: e.target.value
                        }
                      })
                    }
                  />
                  <Input
                    data-qa="activity"
                    label="Activity"
                    placeholer="Enter how long per activity"
                    transcribing
                    value={socialHistory.exercise.howLongActivity}
                    onChange={(e) =>
                      handleOnChange({
                        exercise: {
                          ...socialHistory.exercise,
                          howLongActivity: e.target.value
                        }
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? "What's the patient's" : "What's your"} marital status?
              </p>
              <div className="SocialHistory__row__content grid-cols-[repeat(auto-fit,minmax(110px,1fr))]">
                <Checkbox
                  data-qa="married"
                  label="Married"
                  rounded
                  isChecked={socialHistory.martialStatus?.married}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        married: !socialHistory.martialStatus?.married,
                        partnership: false,
                        divorced: false,
                        separated: false,
                        single: false,
                        widow: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="partnership"
                  label="Partnership"
                  rounded
                  isChecked={socialHistory.martialStatus?.partnership}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        partnership: !socialHistory.martialStatus?.partnership,
                        married: false,
                        divorced: false,
                        separated: false,
                        single: false,
                        widow: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="divorced"
                  label="Divorced"
                  rounded
                  isChecked={socialHistory.martialStatus?.divorced}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        divorced: !socialHistory.martialStatus?.divorced,
                        married: false,
                        partnership: false,
                        separated: false,
                        single: false,
                        widow: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="separated"
                  label="Separated"
                  rounded
                  isChecked={socialHistory.martialStatus?.separated}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        separated: !socialHistory.martialStatus?.separated,
                        married: false,
                        partnership: false,
                        divorced: false,
                        single: false,
                        widow: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="single"
                  label="Single"
                  rounded
                  isChecked={socialHistory.martialStatus?.single}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        single: !socialHistory.martialStatus?.single,
                        married: false,
                        partnership: false,
                        separated: false,
                        divorced: false,
                        widow: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="widow-er"
                  label="Widow/er"
                  rounded
                  isChecked={socialHistory.martialStatus?.widow}
                  onChange={() =>
                    handleOnChange({
                      martialStatus: {
                        ...socialHistory.martialStatus,
                        widow: !socialHistory.martialStatus?.widow,
                        married: false,
                        partnership: false,
                        divorced: false,
                        separated: false,
                        single: false
                      }
                    })
                  }
                />
              </div>
            </div>
            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? 'Is the patient' : 'Are you'} sexually active?
              </p>
              <div className="grid gap-y-6">
                <div className="SocialHistory__row__content flex">
                  <Checkbox
                    data-qa="yes"
                    label="Yes"
                    rounded
                    isChecked={socialHistory.sexualActivity?.yes}
                    onChange={() =>
                      handleOnChange({
                        sexualActivity: {
                          ...socialHistory.sexualActivity,
                          yes: !socialHistory.sexualActivity?.yes,
                          no: false
                        }
                      })
                    }
                  />
                  <Checkbox
                    data-qa="no"
                    label="No"
                    rounded
                    isChecked={socialHistory.sexualActivity?.no}
                    onChange={() =>
                      handleOnChange({
                        sexualActivity: {
                          ...socialHistory.sexualActivity,
                          no: !socialHistory.sexualActivity?.no,
                          yes: false,
                          numberOfSexualPartners: '',
                          men: false,
                          women: false,
                          both: false
                        }
                      })
                    }
                  />
                </div>

                {socialHistory.sexualActivity?.yes && (
                  <div className="grid gap-y-6">
                    <Input
                      data-qa="partners"
                      className="max-w-[280px] lg:max-w-none"
                      label="Partners"
                      placeholder="Enter number of sexual partners"
                      transcribing
                      value={socialHistory.sexualActivity?.numberOfSexualPartners}
                      onChange={(e) =>
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            numberOfSexualPartners: e.target.value
                          }
                        })
                      }
                    />
                    <div className="flex gap-x-6">
                      <Checkbox
                        data-qa="men"
                        label="Men"
                        isChecked={socialHistory.sexualActivity?.men}
                        onChange={() => {
                          handleOnChange({
                            sexualActivity: {
                              ...socialHistory.sexualActivity,
                              men: !socialHistory.sexualActivity?.men
                            }
                          });
                        }}
                      />
                      <Checkbox
                        data-qa="women"
                        label="Women"
                        isChecked={socialHistory.sexualActivity?.women}
                        onChange={() =>
                          handleOnChange({
                            sexualActivity: {
                              ...socialHistory.sexualActivity,
                              women: !socialHistory.sexualActivity?.women
                            }
                          })
                        }
                      />
                      <Checkbox
                        data-qa="both"
                        label="Both"
                        isChecked={
                          socialHistory.sexualActivity.men && socialHistory.sexualActivity?.women
                        }
                        handleClick={() =>
                          handleOnChange({
                            sexualActivity: {
                              ...socialHistory.sexualActivity,
                              men:
                                socialHistory.sexualActivity.men &&
                                socialHistory.sexualActivity?.women
                                  ? false
                                  : true,
                              women:
                                socialHistory.sexualActivity.men &&
                                socialHistory.sexualActivity?.women
                                  ? false
                                  : true
                            }
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="flex flex-col gap-4">
                  <span className="font-500 text-neutral-800">Contraception?</span>
                  <div className="flex gap-x-6">
                    <Checkbox
                      data-qa="yes"
                      label="Yes"
                      rounded
                      isChecked={socialHistory.sexualActivity?.contracteption}
                      onChange={() =>
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            contracteption: !socialHistory.sexualActivity?.contracteption,
                            noContracteption: false
                          }
                        })
                      }
                    />
                    <Checkbox
                      data-qa="no"
                      label="No"
                      rounded
                      isChecked={socialHistory.sexualActivity?.noContracteption}
                      onChange={() =>
                        handleOnChange({
                          sexualActivity: {
                            ...socialHistory.sexualActivity,
                            noContracteption: !socialHistory.sexualActivity?.noContracteption,
                            contracteption: false,
                            methodContraception: ''
                          }
                        })
                      }
                    />
                  </div>
                </div>
                {socialHistory.sexualActivity?.contracteption && (
                  <Input
                    data-qa="contraception-method"
                    className="max-w-[280px] lg:max-w-none"
                    label="Contraception method"
                    placeholder="Enter contraception method"
                    transcribing
                    value={socialHistory.sexualActivity?.methodContraception}
                    onChange={(e) =>
                      handleOnChange({
                        sexualActivity: {
                          ...socialHistory.sexualActivity,
                          methodContraception: e.target.value
                        }
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className="SocialHistory__row">
              <p className="SocialHistory__row__title">
                {practice_id ? doesThePatient : doYou} have children?
              </p>
              <div className="SocialHistory__row__content flex">
                <Checkbox
                  data-qa="yes"
                  label="Yes"
                  rounded
                  isChecked={socialHistory.children?.yes}
                  onChange={() =>
                    handleOnChange({
                      children: {
                        ...socialHistory.children,
                        yes: !socialHistory.children?.yes,
                        no: false
                      }
                    })
                  }
                />
                <Checkbox
                  data-qa="no"
                  label="No"
                  rounded
                  isChecked={socialHistory.children?.no}
                  onChange={() =>
                    handleOnChange({
                      children: {
                        ...socialHistory.children,
                        no: !socialHistory.children?.no,
                        yes: false,
                        numberOfChildren: '',
                        ages: []
                      }
                    })
                  }
                />
              </div>

              {socialHistory.children?.yes && (
                <>
                  <Input
                    data-qa="enter-number"
                    className="max-w-[280px] lg:max-w-none"
                    label="Number of children"
                    placeholder="Enter number of children"
                    min="1"
                    type="number"
                    pattern="\d*"
                    value={socialHistory.children.numberOfChildren}
                    onChange={(e) => handleNumberOfChildren(e.target.value)}
                  />
                  <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
                    {socialHistory.children.ages?.map((item, idx) => {
                      return (
                        <Input
                          data-qa={`children-${idx}`}
                          key={idx}
                          id={idx}
                          label={`Child ${idx + 1}`}
                          placeholder={`Enter age of child ${idx + 1}`}
                          min="0"
                          type="number"
                          pattern="\d*"
                          value={socialHistory.children.ages[idx]}
                          onChange={(e) => updateChildAge(e.target.value, idx)}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {cnDisplaySettings && showNarrative && (
          <div className="!mt-3">
            <NarrativeForm
              syncNarrative={syncNarrative}
              setSyncNarrative={setSyncNarrative}
              onChange={(event) => handleNarrative({ event, sync: false })}
              setCurrentForm={setSocialHistory}
              restData={{
                className: 'w-full',
                label: 'Social History Narrative',
                placeholder: 'Add narrative here',
                id: 'Narrative-social-history',
                'data-qa': 'narrative-social-history',
                name: 'Narrative-social-history',
                value: socialHistory?.narrative,
                forwardedRef: textAreaRef,
                formName: 'socialHistory'
              }}
              narrativeOptions={isAdvancedForm ? narrativeOptions : []}
            />
          </div>
        )}

        <button
          className="hidden"
          ref={hpNoteOverviewRef}
          onClick={() => {
            submitForm({
              ...customFormsParams,
              formId,
              currentFormData: socialHistory,
              defaultState: prevSocialHistory,
              type: 'socialHistory'
            });
            setPrevSocialHistory(socialHistory);
          }}
        />
      </div>

      <div className="my-auto ml-3">
        {!fromCheckin && (
          <Icon
            icon="trash"
            onClick={() => {
              if (!formId) {
                showAlert({
                  title: 'Social History',
                  message: 'Please create the form before deleting one.',
                  color: 'warning'
                });
                return;
              } else {
                setShowConfirmModal(true);
              }
            }}
          />
        )}
      </div>
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Social History"
          icon="trash"
          message="Are you sure you want to delete this form?"
          handleContinue={() => mutateDeleteMedicalHistory.mutate()}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default SocialHistory;
