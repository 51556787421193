import React, { useState, useRef, useEffect } from 'react';
import ActionButton from '../shared/Button/ActionButton/ActionButton';
import cs from 'classnames';
import ActivityModal from './components/ActivityModal';
import Skeleton from 'react-loading-skeleton';
import { useUserActivity } from 'lib/hooks/queries/activity/useUserActivity';
import ActivityGroup from './components/ActivityGroup';
import { withErrorBoundary } from 'react-error-boundary';
import Loading from 'components/shared/Loading/Loading';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { ia } from 'lib/helpers/utility';

const UserActivity = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const [limit, setLimit] = useState(30);
  const {
    data = {},
    isLoading,
    isFetching
  } = useUserActivity({
    params: {
      limit
    },
    dependencies: [limit, isOpen]
  });
  const activityLogs = data?.logs;

  const total = data?.total;
  const returned = data?.returned;

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight === scrollHeight) {
      setLimit(limit + 15);
    }
  };

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div ref={popoverRef} className={cs('flex items-center', className)}>
      <div className="relative !ml-2">
        <ActionButton
          className="relative p-0"
          icon="new-activity-log"
          isOpen={isOpen}
          onClick={toggleIsOpen}
        />
      </div>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <ActivityModal onScroll={handleScroll} isOpen={isOpen} handleClose={toggleIsOpen}>
          {isLoading ? (
            <Skeleton width="100%" height="100%" />
          ) : (
            <div className="space-y-4">
              {ia(activityLogs) &&
                activityLogs.map((group, index) => (
                  <ActivityGroup
                    key={index}
                    date={group.date}
                    limit={limit}
                    setLimit={setLimit}
                    activities={group.logActivity}
                    toggleIsOpen={toggleIsOpen}
                  />
                ))}
              {total !== returned && isFetching && <Loading />}
            </div>
          )}
        </ActivityModal>
      </ErrorBoundary>
    </div>
  );
};

export default UserActivity;
