import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { interimApi } from 'api/InterimApi';
import Payments from 'components/Payments/Payments';
import AppointmentPayments from 'components/practice/Calendar/RBCalendar/AppointmentPayments';
import EligibilityPreview from 'components/practice/Calendar/RBCalendar/BenefitPreview/EligibilityPreview';
import Eligibility from 'components/practice/Calendar/RBCalendar/Eligibility';
import ProceduresProducts from 'components/practice/appointment/components/ProceduresProduct/ProceduresProducts';
import practiceState from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import CheckinSteps from 'components/shared/CheckinSteps/CheckinSteps';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import TagSelect from 'components/shared/Tags/Select';
import Textarea from 'components/shared/Textarea/Textarea';
import { useFormik } from 'formik';
import { ia } from 'lib/helpers/utility';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { isEqual, pick } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AppointmentPreviewInputs,
  Financial,
  Footer,
  Location,
  ModalTitle,
  NoPaymentBoxInfo,
  Patient,
  PatientStatus,
  Practitioner
} from './components';
import Resources from './components/Resources';
import UpdateAppointmentValidationSchema from './lib/UpdateAppointmentValidationSchema';
import { fields, getInitialData } from './lib/initals';
import { handleDateTimeChange, runEligibility, submitAppointment } from './lib/updateStateHelpers';

const PreviewAppointment = ({
  appointment,
  setAppointment,
  showPreviewAppointment,
  hidePreviewAppointment,
  practitioners,
  services
}) => {
  const notesRef = useRef(null);

  const diffMin = moment(appointment?.ends_at, 'DD-MM-YYYY hh:mm').diff(
    moment(appointment?.starts_at, 'DD-MM-YYYY hh:mm'),
    'minutes'
  );
  const queryClient = useQueryClient();
  const [hasInsurance, setHasInsurance] = useState(false);
  const [runningEligibility, setRunningEligibility] = useState(false);
  const [showEligibilityResponse, setShowEligibilityResponse] = useState(false);
  const [insuranceProfilesList, setInsuranceProfilesList] = useState([]);
  const [showEligibilityPopover, setShowEligibilityPopover] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] = useRecoilState(practiceState.selectedPatient);
  const [dateAndTime, setDateAndTime] = useState(
    moment.tz(appointment?.starts_at, practiceTimezone)
  );
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const practiceTimezone = currPractice?.timezone;
  const [eligibilityLimitReached, setEligibilityLimitReached] = useState(false);
  const [insuranceBenefits, setInsuranceBenefits] = useState({});
  const [isReschedule, setIsReschedule] = useState(false);
  const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);
  const [oldAppointment, setOldAppointment] = useState(appointment);
  const [loading, setLoading] = useState({
    update: false,
    confirm: false,
    cancel: false,
    restore: false
  });
  const [refetchCustomForms, setRefetchCustomForms] = useState(false);
  const [appointmentPayments, setAppointmentPayments] = useState(appointment?.payments);
  const { data: resourcesData } = useResourcesAsOptions();
  const resources = resourcesData?.resources || [];
  const [procedures, setProcedures] = useState([]);
  const [packages, setPackages] = useState([]);
  const [products, setProducts] = useState([]);
  const [restoreConfirmationModal, setRestoreConfirmationModal] = useState(false);
  const formik = useFormik({
    initialValues: getInitialData(appointment, diffMin),
    enableReinitialize: true,
    validationSchema: UpdateAppointmentValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleSubmitAppointment(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    setSelectedPatient({ ...selectedPatient, id: appointment?.patient?.id });
    checkInsurance();
    getProducts();
    getPackages();
  }, []);

  // Product;

  const getProducts = async () => {
    const onSuccess = (data) => {
      setProducts(
        data.products.map((p) => ({
          ...p,
          total_amount_cents: p?.amount_cents,
          sales_count: 1,
          ...(p?.image && {
            image: `https://cdn.filestackcontent.com/${JSON.parse(p?.image || `{}`)?.jpg}`
          })
        }))
      );
    };

    requestApi({ url: '/api/product/get', params: { forUsers: true }, onSuccess, navigate });
  };

  const getPackages = async () => {
    const onSuccess = (data) => {
      const { invoicePackages } = data;

      if (ia(invoicePackages, -1)) setPackages(invoicePackages);
    };

    appointment?.patient?.id &&
      requestApi({
        url: '/api/package/get_by_user',
        params: {
          patientId: appointment?.patient?.id,
          includeIds:
            appointment?.invoice?.procedures
              ?.filter?.((p) => p?.packageId || p?.invoiceId)
              .map?.(({ id, packageId, invoiceId }) => {
                return { id, packageId, invoiceId };
              }) || []
        },
        onSuccess,
        navigate
      });
  };

  useEffect(() => {
    formik.setFieldValue('payments', appointmentPayments);
  }, [appointmentPayments]);

  useEffect(() => {
    setEligibilityLimitReached(appointment?.eligibilityRequestReached);
    setInsuranceBenefits(appointment?.patient?.insurance_benefits?.benefits);
    setOldAppointment(appointment);
    setAppointmentPayments(appointment?.payments);
  }, [appointment]);

  const setRefetchAppointments = () => {
    queryClient.invalidateQueries({ queryKey: ['appointment-events'] });
    queryClient.invalidateQueries({ queryKey: ['overview-appointments'] });
  };

  const changeDateTime = ({ newTime, type }) => {
    try {
      handleDateTimeChange({
        newTime,
        type,
        dateAndTime,
        practiceTimezone,
        setDateAndTime,
        formik,
        diffMin,
        setIsReschedule,
        appointment
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPatientInsuranceProfiles = async (userId) => {
    try {
      const res = await interimApi('/api/insurance_profiles/get', { userId }, navigate);
      const { insuranceProfile = [] } = res.data;
      setInsuranceProfilesList(insuranceProfile);
      setHasInsurance(insuranceProfile?.length ? true : false);
    } catch (error) {
      Honeybadger.notify(`Insurance profile get userID: ${userId}, error: ${error}`);
    }
  };

  const checkInsurance = async () => {
    await getPatientInsuranceProfiles(appointment?.patient?.id);
  };

  const handleSubmitAppointment = async (updatedAppointment) => {
    await submitAppointment({
      updatedAppointment,
      appointment,
      formik,
      isReschedule,
      loading,
      navigate,
      procedures,
      refetchCustomForms,
      setAppointment,
      setIsReschedule,
      setLoading,
      setOldAppointment,
      setRefetchAppointments,
      setRefetchCustomForms,
      queryClient
    });
  };

  const cancelAppointment = async () => {
    setLoading({ ...loading, cancel: true });

    const onSuccess = () => {
      setRefetchAppointments(); // ? Is this necessary?
      queryClient.resetQueries(['appointment', appointment?.id]);

      setShowConfirmationModal(false);
      showAlert({ message: 'Appointment was canceled', color: 'success' });
      setOldAppointment((oldState) => ({ ...oldState, status: 'cancelled' }));

      hidePreviewAppointment();
    };

    await requestApi({
      url: '/api/appointment/cancel',
      navigate,
      params: { id: appointment?.id },
      onSuccess
    });
    setLoading({ ...loading, cancel: false });
  };

  const restoreAppointment = async () => {
    setLoading({ ...loading, restore: true });

    const onSuccess = () => {
      setRefetchAppointments();
      queryClient.resetQueries(['appointment', appointment?.id]);

      setRestoreConfirmationModal(false);
      showAlert({ message: 'Appointment was restored', color: 'success' });
      setOldAppointment((oldState) => ({ ...oldState, status: 'approved' }));
      hidePreviewAppointment();
    };

    const onError = (error) => {
      setRestoreConfirmationModal(false);
      hidePreviewAppointment();
      showAlert({ message: error, color: 'danger' });
    };

    await requestApi({
      url: '/api/appointment/restore',
      navigate,
      params: { id: appointment?.id },
      onSuccess,
      onError
    });
    setLoading({ ...loading, restore: false });
  };

  const handleRunEligibility = (insuranceId) => {
    runEligibility({
      setInsuranceBenefits,
      setRunningEligibility,
      insuranceId,
      appointmentId: appointment?.id,
      formik,
      setAppointment
    });
    setShowEligibilityPopover(false);
  };

  const handleUnsavedChanges = () => {
    if (oldAppointment?.status === 'cancelled') {
      hidePreviewAppointment();
      return;
    }

    const otherFields = {
      products: oldAppointment?.invoice?.products || [],
      procedures: oldAppointment?.invoice?.procedures || [],
      modifiedCopayment: null,
      modifiedDeductible: null,
      modifiedCoinsurance: null,
      tag_ids: oldAppointment?.tag_ids
    };

    const oldAppt = { ...pick(oldAppointment, fields), ...otherFields };
    const newApptValues = { ...oldAppt, ...pick(formik.values, fields) };

    const equalObj = isEqual(oldAppt, newApptValues);
    const duration = diffMin === formik.values.appointmentLength;

    if (equalObj && isReschedule === false && duration) hidePreviewAppointment();
    else setUnsavedConfirmation(true);
  };

  const eligibilityPresent = formik.values?.patient?.insurance_benefits?.retrieved || false;

  const closeEligibilityModal = () => setShowEligibilityResponse(false);

  const originalProceduresProducts = {
    products: oldAppointment?.invoice?.products || [],
    procedures: oldAppointment?.invoice?.procedures || []
  };

  const cancelAppointmentMessage =
    'Are you sure you want to cancel this appointment?' +
    (appointment?.invoice?.amount_paid > 0
      ? " We do not recommend cancelling appointments that have finished payments. Once done the payment will be reallocated to the patient's balance"
      : '');

  let restoreAppointmentMessage = `Are you sure you want to restore this appointment? `;

  if (ia(appointment?.invoice?.products)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment will cause negative inventory levels if the stock is empty. \n Proceeding with the restoration will set these products to negative inventory. Do you wish to continue?';
  }

  if (oldAppointment?.invoice?.procedures?.some?.((p) => p?.invoiceId)) {
    restoreAppointmentMessage +=
      '\n \nRestoring this appointment involves a package that has already been fully utilized. As a result, the balance for this package will not be adjusted, and no additional services or products will be available for this appointment. \n Proceeding with the restoration will not add any new balances to the package. Do you wish to continue?';
  }

  return (
    <Modal
      handleOpen={showPreviewAppointment}
      className="w-[1040px] !p-0 md:w-[510px]"
      handleClose={handleUnsavedChanges}
      title={
        <ModalTitle
          {...{
            appointment,
            setOldAppointment,
            setRefetchAppointments,
            oldAppointment,
            status: formik.values.status
          }}
        />
      }
      id="AppointmentPreviewModal"
      slideFromRight
      footer={
        <Footer
          {...{
            formik,
            handleUnsavedChanges,
            isReschedule,
            loading,
            oldAppointment,
            setShowConfirmationModal,
            setRestoreConfirmationModal
          }}
        />
      }
      bodyClassName="p-0">
      {appointment && (
        <div className="grid grid-cols-2 pt-[2px] md:!grid-cols-1">
          <div className="flex flex-col !gap-4 bg-white !p-4">
            <Patient appointment={appointment} />
            <div className="grid grid-cols-2 gap-3">
              <Practitioner {...{ formik, practitioners }} />
              <Resources {...{ formik, resources }} />
            </div>
            <AppointmentPreviewInputs
              {...{
                formik,
                currPractice,
                dateAndTime,
                practiceTimezone,
                changeDateTime
              }}
            />
            <Location formik={formik} practitioners={practitioners} />
            <TagSelect
              kind="appointment"
              currTags={appointment?.tags}
              menuPortalTarget={document.body}
              setTagIds={(ids) => formik.setFieldValue('tag_ids', ids)}
            />
            <Textarea
              label="Notes"
              data-qa="notes"
              name="practice_note"
              className="!shadow-sm"
              forwardedRef={notesRef}
              onChange={formik.handleChange}
              value={formik.values.practice_note}
              placeholder="Add any notes, problems or symptoms"
            />

            <ProceduresProducts
              formik={formik}
              services={services}
              products={products}
              packages={packages}
              originalProceduresProducts={originalProceduresProducts}
            />
          </div>
          <div className="flex flex-col !gap-4 !p-4">
            <PatientStatus
              appointment={appointment}
              label="Appointment Progress"
              listClassName="!gap-1 !pt-2"
            />
            <CheckinSteps
              formik={formik}
              appointment={appointment}
              hasInsurance={hasInsurance}
              refetchCustomForms={refetchCustomForms}
              insuranceProfiles={insuranceProfilesList}
              setAppointment={setAppointment}
            />
            <Allowed requiredPermissions={['payments.read']}>
              <Financial
                formik={formik}
                timezone={practiceTimezone}
                eligibilityPresent={eligibilityPresent}
                setShowEligibilityResponse={setShowEligibilityResponse}
                insuranceProfiles={insuranceProfilesList}
                eligibility={
                  hasInsurance ? (
                    <Eligibility
                      eligibilityLimitReached={eligibilityLimitReached}
                      setShow={setShowEligibilityPopover}
                      show={showEligibilityPopover}
                      insuranceProfilesList={insuranceProfilesList}
                      callback={handleRunEligibility}
                      loading={runningEligibility}
                      existEligibility={eligibilityPresent}
                      eligibilityCounter={currPractice.eligibility_counter}
                    />
                  ) : (
                    false
                  )
                }>
                <Payments
                  smallButton
                  apptInvoice={true}
                  appointment={appointment}
                  appointmentId={appointment?.id}
                  setAppointment={setAppointment}
                  patientId={appointment?.user_id}
                  practiceId={appointment?.practice_id}
                  amount={formik?.values?.invoice?.balance || 0}
                  surcharge={appointment?.surcharge_amount || 0}
                  setAppointmentPayments={setAppointmentPayments}
                />
              </Financial>
            </Allowed>

            {appointment?.no_payment_selected && !ia(appointmentPayments) && <NoPaymentBoxInfo />}

            {ia(appointmentPayments) && (
              <AppointmentPayments
                appointment={appointment}
                timezone={practiceTimezone}
                payments={appointmentPayments}
              />
            )}
          </div>

          <Modal
            handleOpen={showEligibilityResponse}
            handleClose={closeEligibilityModal}
            title="Eligibility response"
            customStyling={{ width: '1170px' }}
            slideFromRight
            footer={
              <div className="flex  w-full justify-between">
                <Button
                  outlined
                  text="Cancel"
                  color="neutral"
                  onClick={closeEligibilityModal}
                  id="cancelEligibilityPreviewModal"
                />
              </div>
            }>
            <EligibilityPreview
              eligibility={insuranceBenefits}
              closeModal={closeEligibilityModal}
              insuranceProfilesList={insuranceProfilesList}
            />
          </Modal>

          <Confirm
            handleOpen={showConfirmationModal}
            handleClose={() => setShowConfirmationModal(false)}
            handleContinue={cancelAppointment}
            title="Cancel appointment"
            message={cancelAppointmentMessage}
            primaryBtnTxt="Cancel appointment"
            secondaryBtnTxt="Keep"
            icon="new-calendar-red"
            variant="danger"
            loading={loading?.cancel}
          />

          <Confirm
            handleOpen={unsavedConfirmation}
            handleClose={() => setUnsavedConfirmation(false)}
            handleContinue={hidePreviewAppointment}
            title="Unsaved changes"
            message="You have unsaved changes. Do you want to close this modal?"
            primaryBtnTxt="Yes"
            secondaryBtnTxt="No"
            icon="new-calendar-red"
            variant="danger"
          />

          <Confirm
            variant="primary"
            secondaryBtnTxt="Keep"
            icon="restore-appointment"
            title="Restore appointment"
            loading={loading?.restore}
            message={restoreAppointmentMessage}
            handleContinue={restoreAppointment}
            primaryBtnTxt="Restore appointment"
            handleOpen={restoreConfirmationModal}
            handleClose={() => setRestoreConfirmationModal(false)}
          />
        </div>
      )}
    </Modal>
  );
};

export default PreviewAppointment;
