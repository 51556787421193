import React, { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../../../api/Api';
import { createInstantPacket } from '../../../../api/InstantPacket';
import { ia, io } from '../../../../lib/helpers/utility';
import { useAllCustomForms } from '../../../../lib/hooks/queries/customForms/useAllCustomForms';
import { useCustomForm } from '../../../../lib/hooks/queries/customForms/useCustomForm';
import { showAlert } from '../../../shared/Alert/Alert';
import Button from '../../../shared/Buttons/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import Icon from '../../../shared/Icon/Icon';
import Modal from '../../../shared/Modal/Modal';

export default function InstantPacket({ setShowInstantPacket }) {
  const { id } = useParams();
  const { data, isLoading } = useAllCustomForms();

  const otherForms = [
    { name: 'HIPAA Agreement', id: 'hipaa', hardcoded: true },
    { name: 'Pre-Authorized Healthcare Form (CC on file)', id: 'pre-auth', hardcoded: true },
    { name: 'Insurance', id: 'insurance', hardcoded: true },
    { name: 'Personal ID', id: 'personal-id', hardcoded: true },
    { name: 'Demographics', id: 'personal-info', hardcoded: true }
  ];

  const [selectedFormIds, setSelectedFormIds] = useState([]);
  const [selectedForm, setSelectedForm] = useState(false);
  const [previewFormId, setPreviewFormId] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState({ email: false, text: false });

  const navigate = useNavigate();

  const { data: previewFormData } = useCustomForm({
    params: { form_id: previewFormId },
    dependencies: [previewFormId]
  });

  useEffect(() => {
    if (
      previewFormId &&
      previewFormData &&
      !ia(previewFormData?.forms) &&
      io(previewFormData?.forms, 1)
    ) {
      setSelectedForm(previewFormData?.forms);
    }
  }, [previewFormData, previewFormId]);

  const isFormSelected = (id) => selectedFormIds.includes(id);

  const handleClick = (formId) => {
    if (isFormSelected(formId)) {
      setSelectedFormIds((prevIds) => prevIds.filter((id) => id !== formId));
    } else {
      setSelectedFormIds((prevIds) => [...prevIds, formId]);
    }
  };

  const previewForm = (id) => {
    if (isNaN(parseInt(id))) return;

    setPreviewFormId(id);
  };

  const handleCreate = async () => {
    const params = { formIds: selectedFormIds, patientId: id };
    const { token } = await createInstantPacket(navigate, params);
    if (token) {
      showAlert({ title: 'Instant packet created successfully!' });
      setLink(token);
    }
  };

  const sendLink = async (type) => {
    setLoading({ ...loading, [type]: true });
    const params = { link, patientId: id };
    await requestApi({
      navigate,
      url: `/api/form/instant_packet/${type}`,
      params,
      onError: () => {
        showAlert({ title: `Failed to send ${type}`, color: 'danger' });
      },
      onSuccess: () => {
        showAlert({ title: `Successfully sent ${type}` });
      }
    });
    setLoading({ ...loading, [type]: false });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      showAlert({ title: 'Copied!' });
    } catch (err) {
      showAlert({ title: 'Failed to copy!' });
    }
  };

  return (
    <Modal
      isOpen={true}
      slideFromRight
      bodyClassName="p-0"
      handleClose={() => setShowInstantPacket(false)}
      title={
        <div className="flex flex-col">
          <div>Send Patient Forms</div>
          <div className="sticky top-0 flex items-center space-x-4 bg-white">
            {selectedForm && (
              <Icon
                icon="back-large"
                onClick={() => {
                  setPreviewFormId(null);
                  setSelectedForm(null);
                }}
              />
            )}
            <h3 className="mb-4 text-base font-500 leading-6 text-neutral-800">
              {selectedForm ? `Form: ${selectedForm?.name}` : 'Forms'}
            </h3>
          </div>
        </div>
      }
      className="w-[720px]"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            outlined
            transparent
            text="Close"
            color="neutral"
            data-qa="patient-form-close-btn"
            onClick={() => setShowInstantPacket(false)}
          />

          {!link && (
            <Button
              text="Create"
              onClick={() => handleCreate()}
              data-qa="patient-form-create-btn"
            />
          )}
        </div>
      }>
      <Icon icon="new-pattern-two-rect" className="absolute bottom-[69px] left-0 -z-10" />
      <>
        {link ? (
          <div className="mt-[20%] inline-flex !h-52 w-[100%] flex-col items-center justify-start !gap-5">
            <div className="self-stretch text-center text-2xl font-medium text-primary-500">
              Packet created successfully!
            </div>
            <div className="flex h-24 flex-col items-center justify-start gap-4 self-stretch">
              <div className="self-stretch text-center text-base font-medium leading-normal text-primary-900">
                Send this link to the patient:
              </div>
              <div className="inline-flex items-center justify-start gap-4 rounded-lg border border-gray-100 bg-white !p-4 shadow">
                <div className="text-sm font-medium leading-snug text-neutral-700">{link}</div>
                <Icon
                  data-qa="copy-link"
                  icon="copy"
                  onClick={() => copyToClipboard()}
                  className="flex gap-2.5 p-1.5"
                />
              </div>
            </div>
            <div className="inline-flex items-start justify-center !gap-2.5">
              <Button
                data-qa="patient-form-send-email"
                id="email"
                text="Email patient"
                icon="new-email"
                onClick={() => sendLink('email')}
                loading={loading.email}
              />
              <Button
                data-qa="patient-form-text"
                id="text"
                text="Text patient"
                icon="new-text-message"
                onClick={() => sendLink('text')}
                loading={loading.text}
              />
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                {selectedForm ? (
                  <div className="rounded-lg bg-white bg-opacity-100 p-[0.5rem] opacity-100 !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
                    <ReactFormGenerator data={selectedForm?.json?.fields} read_only />
                  </div>
                ) : (
                  <table className="primary-table responsive-table selectable-children h-full rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
                    <thead>
                      <tr>
                        <th className="!pl-16">Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="h-[calc(100vh-233px)]">
                      {ia(data) &&
                        ia([...otherForms, ...data]) &&
                        [...otherForms, ...data]?.map((form, idx) => (
                          <tr
                            key={idx}
                            className="cursor-pointer hover:!bg-primary-50"
                            onClick={() => handleClick(form.id)}>
                            <td className="flex h-full items-center gap-x-4">
                              <Checkbox
                                radio
                                className="scale-75"
                                isChecked={isFormSelected(form.id)}
                                data-qa={`load-form-checkbox-${idx}`}
                              />
                              <span className="font-500 first-letter:capitalize ">{form.name}</span>
                            </td>

                            <td className="text-right">
                              {!form.hardcoded && (
                                <Icon
                                  icon="new-eye"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    previewForm(form.id);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </>
        )}
      </>
    </Modal>
  );
}
