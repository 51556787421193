import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Regular from './Regular';
import EPCS from './EPCS';
import Tabs from 'components/shared/Tabs/NewTabs';

const Veradigm = ({ dashboardData }) => {
  const item = { name: 'Veradigm' };
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Regular',
      content: <Regular data={dashboardData} />
    },
    {
      label: 'EPCS',
      content: <EPCS data={dashboardData} />
    }
  ];

  return (
    <div>
      <WidgetHeader icon="new-notev2" item={item} hasPlus={false} />
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default Veradigm;
