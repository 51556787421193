import React from 'react';
import Input from '../../../shared/Input/Input';
import '../Charts.scss';
import Select from '../../../shared/Select/Select';

export const customSelectStyle = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 16,
    backgroundColor: '#ffffff',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};

const PharmacyForm = ({ states, formik }) => {
  const findStateName = (value) => {
    const stateName = states?.find((state) => {
      if (state?.value == value) {
        return state;
      }
    });

    return stateName && stateName?.label;
  };

  return (
    <div className="grid gap-y-3">
      <Input
        label="Name"
        placeholder="Name"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        id="name"
        data-qa="new-pharmacy-name"
        error={formik.errors.name}
      />
      <Input
        label="Phone number"
        placeholder="+1(123)4567890"
        name="phone"
        value={formik.values.phone}
        onChange={formik.handleChange}
        id="phoneNumber"
        data-qa="new-pharmacy-phone-number"
        error={formik.errors.phone}
      />
      <Input
        label="Fax number"
        placeholder="+1(123)4567890"
        name="fax_number"
        value={formik.values.fax_number}
        onChange={formik.handleChange}
        id="faxNumber"
        data-qa="new-pharmacy-fax-number"
        error={formik.errors.fax_number}
      />
      <Input
        label="Address 1"
        placeholder="Address"
        name="address_ln_1"
        value={formik.values.address_ln_1}
        onChange={formik.handleChange}
        id="address_ln_1"
        data-qa="new-pharmacy-address-ln-1"
        error={formik.errors.address_ln_1}
      />
      <Input
        label="Address 2"
        type="text"
        placeholder="Address 2"
        name="address_ln_2"
        value={formik.values.address_ln_2}
        onChange={formik.handleChange}
        id="address_ln_2"
        data-qa="new-pharmacy-address-ln-2"
      />
      <Input
        label="City"
        type="text"
        placeholder="City"
        name="city"
        value={formik.values.city}
        onChange={formik.handleChange}
        id="city"
        data-qa="new-pharmacy-city"
      />
      <Input
        label="Zip"
        placeholder="Zip"
        name="zip"
        value={formik.values.zip}
        onChange={formik.handleChange}
        id="zip"
        data-qa="new-pharmacy-zip"
        error={formik.errors.zip}
      />
      <Select
        label="State"
        className="select-full-width w-full"
        placeholder="Type and select a state"
        value={{ label: findStateName(formik?.values?.state) }}
        options={states}
        onChange={(v) => {
          formik.setFieldValue('state', v.value);
        }}
        styles={customSelectStyle}
        inputId="countryAddress"
        error={formik.errors.state || null}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default PharmacyForm;
