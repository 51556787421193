import { Popover } from '@headlessui/react';
import React from 'react';
import Input from '../../../../shared/Input/Input';
import { formatDateAndTime } from '../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice as currPractice } from '../../../practiceState';
import { Calendar } from 'react-date-range';
import { getHours, handleChangeStartDate, handleDateTimeChange } from '../lib';
import moment from 'moment';
import Select from 'components/shared/Select/Select';
import { timeFilterOption } from '../../appointmentHelpers';

const DateTimeEventForm = ({ formik, editing = false }) => {
  const currentPractice = useRecoilValue(currPractice);
  const hours = getHours(currentPractice);

  const { values, setFieldValue, touched, errors, setFieldError } = formik;

  return (
    <>
      <div className="flex items-end !gap-4">
        <Popover
          className="relative z-40 flex-1
          ">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  data-qa="event-start-date"
                  rightIcon="new-calendar-gray"
                  value={formatDateAndTime(
                    values.start_date,
                    currentPractice?.timezone,
                    '',
                    'M/D/YYYY, dddd'
                  )}
                  error={touched.start_date && errors.start_date}
                />
              </Popover.Button>

              <Popover.Panel className="absolute z-10 flex w-full shadow-md">
                <Calendar
                  showDateDisplay
                  className="w-full"
                  onChange={(date) => {
                    handleChangeStartDate(values, setFieldValue, date, editing);
                    close();
                  }}
                  date={new Date(formatDateAndTime(values.start_date, currentPractice?.timezone))}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
        {!values.all_day && (
          <Select
            isClearable={false}
            rightIcon="new-clock-gray"
            filterOption={timeFilterOption}
            parentClassName="relative w-4/12"
            options={hours}
            onChange={(val) => {
              const value = { newTime: val.value, type: 'time' };
              handleDateTimeChange(values, setFieldValue, value, 'start_date');
            }}
            value={{
              label: formatDateAndTime(values.start_date, currentPractice?.timezone, '', 'h:mm A'),
              value: formatDateAndTime(values.start_date, currentPractice?.timezone, '', 'h:mm A')
            }}
          />
        )}
      </div>
      <div className="flex items-start !gap-4">
        <Popover
          className="relative z-30 flex-1
          ">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  data-qa="event-end-date"
                  rightIcon="new-calendar-gray"
                  value={formatDateAndTime(
                    values.until,
                    currentPractice?.timezone,
                    '',
                    'M/D/YYYY, dddd'
                  )}
                  error={
                    !moment(values.until).isSame(moment(values.startDate), 'day') &&
                    touched.until &&
                    errors.until
                  }
                />
              </Popover.Button>

              <Popover.Panel className="absolute z-10 flex w-full shadow-md">
                <Calendar
                  showDateDisplay
                  className="w-full"
                  onChange={(date) => {
                    date.setHours(values.until.getHours());
                    date.setMinutes(values.until.getMinutes());
                    setFieldValue('until', new Date(date));

                    if (moment(date).isAfter(moment(values.start_date))) {
                      setFieldError('until', '');
                    }
                  }}
                  date={new Date(formatDateAndTime(values.until, currentPractice?.timezone))}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
        {!values.all_day && (
          <Select
            isClearable={false}
            rightIcon="new-clock-gray"
            filterOption={timeFilterOption}
            parentClassName="relative w-4/12"
            options={hours}
            onChange={(val) => {
              const value = { newTime: val.value, type: 'time' };
              handleDateTimeChange(values, setFieldValue, value, 'until');
            }}
            value={{
              label: formatDateAndTime(values.until, currentPractice?.timezone, '', 'h:mm A'),
              value: formatDateAndTime(values.until, currentPractice?.timezone, '', 'h:mm A')
            }}
          />
        )}
      </div>
    </>
  );
};

export default DateTimeEventForm;
