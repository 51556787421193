import React, { useState } from 'react';
import Tabs from 'components/shared/Tabs/NewTabs';
import AllTransactions from './AllTransactions';
import GeneralPaymentsBreakDown from './GeneralPaymentsBreakDown';
const Transactions = (props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'General Payment Breakdown',
      content: <GeneralPaymentsBreakDown data={props} />
    },
    {
      label: 'Payment Type Breakdown',
      content: <AllTransactions data={props} />
    }
  ];

  return (
    <div className="!px-5 !py-[10px]">
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default Transactions;
