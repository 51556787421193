import React, { useState } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import Select from 'components/shared/Select/Select';
import Input from 'components/shared/Input/Input';
import Button from 'components/shared/Buttons/Button';
import Tabs from 'components/shared/Tabs/NewTabs';
import ExistingPractice from './Existing';
import NewPractice from './NewPractice';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';

export default function NewPractitioner({
  onSubmit,
  onChange,
  formik,
  formRef,
  practiceTypeList,
  genderList,
  stateList,
  practiceList,
  tax_id_types,
  specialtyTypes
}) {
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Add to existing practice',
      content: (
        <ExistingPractice
          practiceList={practiceList}
          onChange={onChange}
          formik={formik}
          stateList={stateList}
        />
      )
    },
    {
      label: 'Add new practice',
      content: (
        <NewPractice
          practiceTypeList={practiceTypeList}
          stateList={stateList}
          specialtyTypes={specialtyTypes}
          onChange={onChange}
          formik={formik}
        />
      )
    }
  ];

  return (
    <div className="bg-white !p-4">
      <form ref={formRef} className="flex flex-col gap-6">
        <div className="grid gap-3">
          <p className="text-xl font-600">Personal Information</p>
          <div className="grid grid-cols-4 gap-3">
            <Input
              label="First Name"
              name="f_name"
              value={formik.values.f_name}
              onChange={onChange}
              data-qa="f-name"
              error={formik.errors?.f_name}
            />
            <Input
              label="Middle Name"
              name="m_name"
              data-qa="m-name"
              value={formik.values.m_name}
              onChange={onChange}
              error={formik.errors?.m_name}
            />
            <Input
              label="Last Name"
              name="l_name"
              data-qa="l-name"
              value={formik.values.l_name}
              onChange={onChange}
              error={formik.errors?.l_name}
            />
            <Select
              isClearable={false}
              className="!w-full"
              inputId="gender"
              data-qa="gender"
              options={genderList}
              label="Gender"
              placeholder="Type and select Gender"
              value={{ value: formik.values?.gender, label: capitalize(formik.values?.gender) }}
              onChange={({ value }) => formik.setFieldValue('gender', value)}
              error={formik.errors?.gender}
            />
            <DatePopover
              label="Date of Birth"
              name="dob"
              data-qa="date-of-birth"
              value={
                formik.values.dob ? new Date(moment(new Date(formik.values.dob)) || null) : null
              }
              onChange={(v) => formik.setFieldValue('dob', v)}
              type="date"
              error={formik.errors?.dob}
            />
            <Input
              label="Phone Number"
              name="phone"
              data-qa="phone-number"
              value={formik.values?.phone}
              onChange={onChange}
              error={formik.errors?.phone}
            />
            <Input
              label="Email"
              name="email"
              data-qa="email"
              value={formik.values?.email}
              onChange={onChange}
              error={formik.errors?.email}
            />
          </div>
        </div>
        <div className="grid gap-3">
          <p className="text-xl font-600">Business Info</p>
          <div className="grid grid-cols-4 gap-4 md:grid-cols-1">
            <Input
              label="NPI"
              name="npi"
              data-qa="npi"
              value={formik.values?.npi}
              onChange={onChange}
              error={formik.errors?.npi}
            />
            <Input
              label="Tax ID"
              name="tax_id"
              data-qa="tax-id"
              value={formik.values?.tax_id}
              onChange={onChange}
            />
            <Select
              isClearable={false}
              label="Tax ID Type"
              name="tax_id_type"
              data-qa="tax-id-type"
              options={tax_id_types}
              placeholder="Tax ID Type"
              value={{
                label: tax_id_types.find((tax) => tax.value === formik.values?.tax_id_type)?.label
              }}
              onChange={({ value }) => formik.setFieldValue('tax_id_type', value)}
            />
            <Input
              label="Taxonomy"
              name="taxonomy"
              data-qa="taxonomy"
              value={formik.values?.taxonomy}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="grid gap-3">
          <p className="text-xl font-600">Claim MD</p>
          <div className='md:grid-cols-1" grid grid-cols-4 gap-4'>
            <Input
              className="min-w-[150px]"
              label="User ID"
              name="claim_md_user_id"
              data-qa="claim-md-user-id"
              value={formik.values?.claim_md_user_id}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="grid gap-3">
          <p className="text-xl font-600">Practice Information</p>
          <Tabs
            tabsData={tabsData}
            theme="secondary"
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            hasContent
            className="!pl-0 !pr-0"
          />
        </div>
        <Button
          className="mx-auto w-[150px]"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          type="primary"
          text="Register"
          data-qa="register-btn"
          onClick={onSubmit}
        />
      </form>
    </div>
  );
}
