import cs from 'classnames';
import Loader from 'components/shared/Loader/Loader';
import React from 'react';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import PreviewSelectedNote from './PreviewSelectedNote';

import { currentPractice } from 'components/practice/practiceState';
import { useRecoilValue } from 'recoil';
import { formatDateAndTimeZ, ia } from '../../../../../lib/helpers/utility';

const LoadPreviousNoteTable = ({
  allClinicalNotes = [],
  showPreviewProps,
  selectedNoteIndex = null,
  isDataLoaded = null,
  handleNoteClick = () => null,
  onCancelPreviewNote = () => null,
  loadNote = () => null
}) => {
  const practice = useRecoilValue(currentPractice);

  return (
    <table className="primary-table responsive-table selectable-children rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
      <thead>
        <tr>
          <th className="!pl-4">Appointment date</th>
        </tr>
      </thead>
      <tbody className="max-h-[calc(100vh-244px)]">
        {ia(allClinicalNotes) ? (
          allClinicalNotes?.map((note, idx) => {
            const shouldShowPreview =
              selectedNoteIndex === idx &&
              note?.id === showPreviewProps?.clinicalNoteId &&
              isDataLoaded;
            return (
              <div key={idx}>
                <tr
                  className={cs(
                    'cursor-pointer hover:!bg-primary-50',
                    selectedNoteIndex === idx && 'selected'
                  )}
                  onClick={() => handleNoteClick(idx)}>
                  <td className="flex h-full items-center gap-x-4">
                    <Checkbox
                      radio
                      className="scale-75"
                      isChecked={selectedNoteIndex === idx}
                      data-qa={`previous-clinical-notes-checkbox-${idx}`}
                    />

                    <span className="font-500 first-letter:capitalize ">
                      {formatDateAndTimeZ(note?.appointment?.starts_at, practice.timezone)}
                    </span>

                    {!isDataLoaded && selectedNoteIndex === idx && (
                      <Loader className="!text-primary-500" shade="50" />
                    )}
                  </td>
                </tr>

                {shouldShowPreview && (
                  <PreviewSelectedNote
                    componentProps={showPreviewProps}
                    cancel={() => onCancelPreviewNote()}
                    loadNote={() => loadNote()}
                  />
                )}
              </div>
            );
          })
        ) : (
          <span className="block !px-3 !py-6 text-center text-sm text-primary-900">
            No previous notes found!
          </span>
        )}
      </tbody>
    </table>
  );
};

export default LoadPreviousNoteTable;
