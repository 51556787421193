import React from 'react';
import Header from './components/Header';
import Board from 'components/shared/CustomizableBoard/Board';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import DateRangePicker from 'components/shared/DateRange/DateRangePicker';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';
import StickyButton from 'components/shared/CustomizableBoard/StickyButton';
import UpcomingAppointments from 'components/practice/Dashboard/widgets/UpcomingAppointments/UpcomingAppointments';
import CommunicationsUsage from 'components/practice/Dashboard/widgets/CommunicationsUsage/CommunicationsUsage';
import HealthcareProgramEfficiencySaving from 'components/practice/Dashboard/widgets/HealthcareProgramEfficiencySaving';
import Income from 'components/practice/Dashboard/widgets/Income';
import EncounterReports from 'components/practice/Dashboard/widgets/EncounterReports/EncounterReports';
import NewPatients from 'components/practice/Dashboard/widgets/NewPatients';
import usePageTitle from 'lib/hooks/usePageTitle';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { useAdminReports } from 'lib/hooks/queries/widgets/useAdminReports';
import PatientDashboardPanel from './widgets/AdminDashboardPanel/Panels/PatientDashboardPanel';
import PracticeDashboardPanel from './widgets/AdminDashboardPanel/Panels/PracticeDashboardPanel';
import StaffMembers from './widgets/AdminDashboardPanel/StaffMember';
const widgets = {
  practice_dashboard_panel: PracticeDashboardPanel,
  patient_dashboard_panel: PatientDashboardPanel,
  upcoming_appointments: UpcomingAppointments,
  communications_usage: CommunicationsUsage,
  healthcare_program_efficiency_saving: HealthcareProgramEfficiencySaving,
/*   income: Income, */
  encounter_reports: EncounterReports,
  staff_members: StaffMembers
};

export default function Dashboard() {
  return (
    <DashboardContextProvider>
      <BoardContextProvider
        layouts={{
          lg: [],
          xmd: [],
          md: [],
          sm: []
        }}
        cols={{ lg: 4, xmd: 12, md: 12, sm: 1 }}
        board="admin_reports">
        <DashboardBoard />
      </BoardContextProvider>
    </DashboardContextProvider>
  );
}

function DashboardBoard() {
  const { ranges, setRanges } = useDashboardContext();
  const params = {
    startAt: ranges.startDate,
    endAt: ranges.endDate
  };
  const { data } = useAdminReports(params);
  const { editMode } = useBoardContext();

  usePageTitle('Dashboard');

  return (
    <div className="!pb-8 bg-neutral-50 overflow-x-hidden overflow-y-auto h-full">
      <Header />
      <div className="-mt-14">
        <Board
          dashboardData={data}
          breakpoints={{ lg: 1200, xmd: 800, md: 710, sm: 0 }}
          rowHeight={145}
          widgets={widgets}
          margin={[12, 12]}>
          <div className="flex items-center gap-2 absolute right-[14px] z-20 -top-[74px]">
            {!editMode && (
              <DateRangePicker
                iconColor="white"
                buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)] sm:!bg-[#55C6EF] !py-0 !px-2 flex min-w-[30px] min-h-[30px]"
                buttonTextColor="text-white"
                customStyling={window.innerWidth < 677 ? { right: '-54px' } : {}}
                ranges={[
                  {
                    startDate: ranges?.startDate,
                    endDate: ranges?.endDate,
                    key: 'selection'
                  }
                ]}
                onChange={(e) => {
                  setRanges((prevState) => ({
                    ...prevState,
                    startDate: e.selection.startDate,
                    endDate: e.selection.endDate
                  }));
                }}
                startDate={ranges?.startDate}
                endDate={ranges?.endDate}
                minDate={-3650}
                maxDate={3650}
              />
            )}
            <StickyButton
              iconColor="white"
              className="!relative !top-auto !h-auto !m-0"
              buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)]"
            />
          </div>
        </Board>
      </div>
    </div>
  );
}
