import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import { ia, mString } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import TH from 'components/shared/Receipt/components/table/TH';
import CheckinStepsStep from 'components/shared/CheckinSteps/Step';

const CheckinStepsInsurance = ({ step, appointment, insuranceProfiles }) => {
  const [show, setShow] = useState({ expanded: false });
  const [loadingIndex, setLoadingIndex] = useState(null);

  const queryClient = useQueryClient();

  const applyInsurance = async (insuranceProfile, idx) => {
    setLoadingIndex(idx);

    const onSuccess = (data) => {
      setLoadingIndex(null);

      queryClient.setQueryData(['appointment', appointment?.id], (prev) => {
        return { appointment: { ...prev, ...appointment, invoice: data.invoice } };
      });

      showAlert({ message: 'Insurance applied successfully.', color: 'success' });
    };

    await requestApi({
      onSuccess,
      onError: () => setLoadingIndex(null),
      url: '/api/appointment/apply_insurance',
      params: { appointmentId: appointment?.id, insuranceProfile }
    });
  };

  return (
    <>
      <CheckinStepsStep
        step={step}
        onClick={() => setShow((ps) => ({ ...ps, expanded: !ps.expanded }))}
      />

      {show.expanded &&
        (ia(insuranceProfiles) ? (
          <div className="flex w-full flex-wrap gap-1 !pl-6">
            <table className="w-full">
              <thead>
                <tr className="border-[0] border-b-[0.6px] border-solid border-neutral-200">
                  <TH label="Payer Name" align="start" />
                  <TH label="Deductible" />
                  <TH label="Copay" />
                  <TH label="Co-Insurance" />
                  <TH align="right" />
                </tr>
              </thead>
              <tbody>
                {insuranceProfiles.map((ip, idx) => (
                  <tr
                    key={ip?.id}
                    className="border-[0] border-b-[0.6px] border-solid border-neutral-200 last:border-0 hover:!bg-transparent">
                    <td
                      className="max-w-[100px] truncate !px-0 !py-2 text-left text-xs text-neutral-700"
                      title={ip?.payer_name}>
                      {ip?.payer_name}
                    </td>
                    <td className="!px-0 !py-2 text-center text-xs text-neutral-700">
                      {mString(ip?.deductible)}
                    </td>
                    <td className="!px-0 !py-2 text-center text-xs text-neutral-700">
                      {mString(ip?.copay)}
                    </td>
                    <td className="!px-0 !py-2 text-center text-xs text-neutral-700">
                      {parseFloat(ip?.co_insurance / 100).toFixed(2)}%
                    </td>
                    <td className="grid place-content-end !px-0 text-right text-xs text-neutral-700">
                      <Tippy content="Apply to this visit" className="tippy-dark">
                        <div className="relative">
                          <Button
                            size="small"
                            text="Apply"
                            loading={loadingIndex === idx}
                            onClick={() => applyInsurance(ip, idx)}
                            className="!px-[8px] !py-[2px] !text-xs"
                          />
                        </div>
                      </Tippy>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="!pl-7">No insurance profile added.</p>
        ))}
    </>
  );
};

export default CheckinStepsInsurance;
