import React, { useEffect, useState } from 'react';
import Input from '../../shared/Input/Input';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../practice/practiceState';
import { useFormik } from 'formik';
import moment from 'moment';
import Button from '../../shared/Buttons/Button';
import DatePopover from '../../shared/Popovers/Date/DatePopover';
import IdleWrapper from '../components/IdleWrapper';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import RequestAssistance from '../components/RequestAssistance';
import Icon from 'components/shared/Icon/Icon';
import { useKioskContext } from 'lib/context/KioskContext/KioskContext';

const KioskIdle = () => {
  const practice = useRecoilValue(currentPractice);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { mobile, setUserIds } = useKioskContext();

  useEffect(() => {
    requestApi({
      url: '/api/kiosk/went_idle',
      navigate
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      date: null
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      date: Yup.date().required('Date of birth is required')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      const onSuccess = async (data, res) => {
        if (data?.userIds === 1) {
          !mobile &&
            (await requestApi({
              url: '/api/kiosk/command',
              params: {
                url: res?.redirectTo,
                command: 'url-change',
                page: 'checkin',
                kioskId: res?.kioskId,
                patientId: res?.patientId
              },
              navigate
            }));
        } else {
          setUserIds(data?.userIds);
        }
        navigate(res?.redirectTo);
        setLoading(false);
      };
      const onError = (error, code) => {
        const errors = {
          2: "Couldn't find appointment",
          3: "Couldn't find user"
        };
        showAlert({ title: errors[code], color: 'danger' });
        setLoading(false);
      };
      setSubmitting(true);
      await requestApi({
        url: mobile ? '/api/kiosk/mobile/checkin' : '/api/kiosk/checkin',
        onSuccess,
        onError,
        params: values,
        navigate,
        filePath: `${__dirname}/${__filename}`
      });
      setSubmitting(false);
    }
  });

  return (
    <IdleWrapper>
      <div className="rounded-t-2xl bg-primary-50 !px-8 !py-3 xs:!px-4">
        <p className="text-lg font-600 leading-7 text-primary-900">Patient Sign-in</p>
        <p className="font-regular mt-0 text-base text-neutral-600">
          We request all patients sign-in upon arrival
        </p>
      </div>
      <div className="!px-8 !py-4 xs:!px-4 xs:!pb-3 xs:!pt-2">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(140px,1fr))] gap-4 xs:flex xs:flex-col xs:gap-3">
          <Input
            label="First name"
            name="firstName"
            inputWrapperClassName="!h-[46px] sm:!h-[40px]"
            labelClassName="text-primary-900"
            placeholder="Enter your first name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.errors.firstName}
            formik={formik}
            focusNextFieldOnEnter
            noSpecialCharacters
          />
          <Input
            label="Last name"
            name="lastName"
            placeholder="Enter your last name"
            inputWrapperClassName="!h-[46px] sm:!h-[40px]"
            labelClassName="text-primary-900"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.errors.lastName}
            formik={formik}
            focusNextFieldOnEnter
            noSpecialCharacters
          />
          <DatePopover
            label="Date of birth"
            placeholder="mm/dd/yyyy"
            name="date"
            inputClassName="!h-[46px] sm:!h-[40px]"
            labelClassName="text-primary-900"
            placementX="right-0"
            value={
              formik.values.date
                ? new Date(moment.tz(new Date(formik.values.date), practice?.timezone) || null)
                : null
            }
            onChange={(date) => {
              formik.setFieldValue('date', date);
            }}
            formik={formik}
            focusNextFieldOnEnter
          />
        </div>
        <Button
          size="large"
          text="Sign-in"
          className="!mt-4 w-full"
          onClick={() => formik.submitForm()}
        />
        <div className="!mt-3 flex select-none flex-wrap items-center justify-between gap-x-6 gap-y-2">
          <RequestAssistance />
          <div className="ml-auto flex items-center gap-x-[6px]">
            <p className="text-xs font-400 text-primary-500">Powered by</p>
            <Icon icon="logo-without-symbol" />
          </div>
        </div>
      </div>
    </IdleWrapper>
  );
};

export default KioskIdle;
