import React, { useMemo, useState } from 'react';
import usePageTitle from 'lib/hooks/usePageTitle';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import Header from 'components/shared/Header/Header';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import Skeleton from 'react-loading-skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import { ia, mapValues, reShapePractitioners, reShapeProcedures } from 'lib/helpers/utility';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useServices } from 'lib/hooks/queries/services/useServices';
import PreviewAppointment from 'components/practice/appointment/PreviewAppointment/PreviewAppointment';
import { useAppointments } from 'lib/hooks/queries/appointment/useAppointments';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import HeaderActions from './components/HeaderActions';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';

const isFirstColumn = (params) => {
  let displayedColumns = params.api.getAllDisplayedColumns();
  let thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

const AppointmentsQueue = () => {
  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns}
      name="appointments-queue">
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Appointments');
  const category = 'appointments';
  const [showPreviewAppointmentModal, setShowPreviewAppointmentModal] = useState(false);
  const [appointment, setAppointment] = useState({});

  const currPractice = useRecoilValue(currentPractice);
  const { limit, page, setPage, sort, filters, setFilters, selectedRows, setSelectedRows } =
    useTableContext();
  const { data, isLoading, isFetching } = useAppointments({
    params: {
      defaultDate: null,
      withEvents: false,
      practitionerPractice: false,
      rangeDate: {
        startDate: filters?.appointment_date?.values?.startDate,
        endDate: filters?.appointment_date?.values?.endDate
      },
      limit,
      page,
      pagination: true,
      sort: 'DESC',
      filters: mapValues({
        ...filters,
        practitioners: filters?.provider?.values?.map((item) => item.value),
        serviceType: filters?.service?.values?.map((item) => item.value),
        patient: filters?.patient?.values?.map((item) => item),
        appointmentTags: filters?.appointmentTags?.values?.map((item) => item?.value),
        appointmentType: filters?.appointmentType?.values?.map((item) => item?.value),
        resources: filters?.resources?.values?.map((item) => item.value)
      })
    },
    dependencies: [limit, page, sort, filters]
  });

  const { data: providersList = {} } = useProviders({});

  const { data: proceduresList } = useServices({});

  const { data: tagsList } = useTags({ params: { kind: 'appointment' } });

  const { data: resourceList } = useResourcesAsOptions({});

  const providerOptions = useMemo(() => {
    if (providersList?.practitioners?.length > 0) {
      return providersList.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providersList]);

  filters.provider.options = providerOptions;

  const serviceOptions = useMemo(() => {
    if (proceduresList?.services?.length > 0) {
      return proceduresList.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [proceduresList]);
  filters.service.options = serviceOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags?.map((t) => ({
        label: t?.label,
        value: t?.id
      }));
    }
  }, [tagsList]);
  filters.appointmentTags.options = tagOptions;

  const typeOptions = useMemo(() => {
    if (currPractice?.appointment_types?.length > 0) {
      return currPractice?.appointment_types?.map((type) => ({
        label: type?.label,
        value: type?.value
      }));
    }
  }, [currPractice?.appointment_types]);
  filters.appointmentType.options = typeOptions;

  const resourcesOptions = useMemo(() => {
    if (resourceList?.resources?.length > 0) {
      return resourceList?.resources?.map((r) => ({
        label: r?.label,
        value: r?.value
      }));
    }
  }, [resourceList]);
  filters.resources.options = resourcesOptions;

  const appointments = data?.appointment || [];
  const count = data?.count?.count || 0;

  const defaultColDef = useMemo(() => {
    return {
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'actions') return;
    if (e?.column?.colId === '0') return;
    setAppointment(e?.data);
    setShowPreviewAppointmentModal(true);
  };

  const hidePreviewAppointment = () => {
    setShowPreviewAppointmentModal(false);
    setAppointment({});
  };

  return (
    <>
      <Header title="Appointments">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <>
              <HeaderActions selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
            </>
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} height="51px" />
          ) : (
            <AGTable
              data={appointments}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              columnDef={defaultColumns}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {showPreviewAppointmentModal && (
        <PreviewAppointment
          appointment={appointment}
          setAppointment={setAppointment}
          showPreviewAppointment={showPreviewAppointmentModal}
          hidePreviewAppointment={hidePreviewAppointment}
          practitioners={reShapePractitioners(providersList?.practitioners)}
          services={reShapeProcedures(proceduresList?.services)}
        />
      )}
    </>
  );
}

export default withErrorBoundary(AppointmentsQueue);
