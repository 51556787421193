import React, { useRef } from 'react';
import Input from 'components/shared/Input/Input';
import Textarea from 'components/shared/Textarea/Textarea';
import Select from 'components/shared/Select/Select';
import { categories, priorities, statuses } from '../lib/constants';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { ia, formatDate } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const TaskForm = ({ formik, members, selectedRequester, setSelectedRequester, practice }) => {
  const formRef = useRef();
  const findSelectedUsers = (selectedValue) => {
    const result = members?.map((member) => {
      if (selectedValue?.includes(member.value)) {
        return member && member;
      }
    });

    return result;
  };
  const selectedUsers = (members, field, formik) => {
    const result = members?.map((member) => member?.value) || [];
    formik.setFieldValue(field, ia(result) ? result : []);
  };

  const priorityOptions = priorities.map((priority) => ({
    value: priority.value,
    label: priority.label
  }));

  const statusOptions = statuses.map((status) => ({
    value: status.value,
    label: status.label
  }));

  const categoryOptions = categories.map((category) => ({
    value: category.value,
    label: category.label
  }));

  const handleChangeDueDate = (date, close) => {
    date.setHours(new Date(formik.values.task.due_date).getHours());
    date.setMinutes(new Date(formik.values.task.due_date).getMinutes());
    formik.setFieldValue('task.due_date', date);
    close();
  };

  const handleRequester = (user) => {
    if (!user) {
      setSelectedRequester('');
      formik.setFieldValue('users.requester', '');
      return;
    }
    setSelectedRequester(user);
    formik.setFieldValue('users.requester', user.value);
  };
  return (
    <form ref={formRef}>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 gap-3 py-2">
          <Input
            label="Title"
            placeholder="Title"
            name="task.title"
            value={formik?.values?.task?.title}
            onChange={formik.handleChange}
            id="title"
            data-qa="tittle"
            error={formik.touched.task?.title && formik.errors.task?.title}
          />

          <Select
            label="Category"
            placeholder="Category"
            name="task.category"
            value={categoryOptions.find((option) => option.value === formik.values.task.category)}
            onChange={(category) => {
              formik.setFieldValue('task.category', category.value);
            }}
            options={categories}
            id="Category"
            error={formik.touched.task?.category && formik.errors.task?.category}
            menuPortalTarget={document.body}
            isClearable={false}
          />
        </div>

        <div className="grid grid-cols-2 gap-3 py-2">
          <Select
            label="Requester"
            placeholder="Type and select requester"
            value={selectedRequester}
            name="users.requester"
            onChange={handleRequester}
            options={members}
            id="requester"
            error={formik.touched.users?.requester && formik.errors.users?.requester}
            menuPortalTarget={document.body}
          />
          <Popover
            className="relative z-40 flex-1
      ">
            {({ close }) => (
              <>
                <Popover.Button className="w-full p-0">
                  <Input
                    label="Due Date"
                    placeholder="Type and select due date"
                    rightIcon="new-calendar-gray"
                    value={formatDate(formik.values.task.due_date, practice?.timezone)}
                    error={formik.touched.task?.due_date_date && formik.errors.task?.due_date}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute z-10 flex w-full shadow-md">
                  <Calendar
                    showDateDisplay
                    className="w-full"
                    onChange={(date) => {
                      handleChangeDueDate(date, close);
                    }}
                    date={new Date(formatDate(formik.values.task?.due_date, practice?.timezone))}
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>

        <div className="grid grid-cols-1 gap-3 py-2">
          <Textarea
            label="Description"
            placeholder="Description"
            name="task.description"
            value={formik.values.task.description}
            onChange={(value) => {
              formik.setFieldValue('task.description', value);
            }}
            id="Description"
            error={formik.touched.task?.description && formik.errors.task?.description}
            isEditor
            className="min-h-[250px]"
          />
        </div>

        <div className="grid grid-cols-2 gap-3 py-2">
          <Select
            label="Priority"
            placeholder="Type and select Priority"
            value={priorityOptions.find((option) => option.value === formik.values.task.priority)}
            name="task.priority"
            onChange={(priority) => {
              formik.setFieldValue('task.priority', priority.value);
            }}
            options={priorities}
            id="priority"
            error={formik.touched.task?.priority && formik.errors.task?.priority}
            isClearable={false}
            menuPortalTarget={document.body}
          />
          <Select
            label="Status"
            placeholder="Type and select Status"
            value={statusOptions.find((option) => option.value === formik.values.task.status)}
            name="task.status"
            onChange={(status) => {
              formik.setFieldValue('task.status', status.value);
            }}
            options={statuses}
            id="status"
            error={formik.touched.task?.status && formik.errors.task?.status}
            isClearable={false}
            menuPortalTarget={document.body}
          />
        </div>

        <div className="grid grid-cols-2 gap-3 py-2">
          <Select
            label="Owners"
            placeholder="Type and select Owners"
            value={findSelectedUsers(formik.values.users.owners) || []}
            name="users.owners"
            isMulti
            onChange={(value) => {
              selectedUsers(value, 'users.owners', formik);
            }}
            options={members}
            id="owners"
            error={formik.touched.users?.owners && formik.errors.users?.owners}
            menuPortalTarget={document.body}
          />
          <Select
            label="Followers"
            placeholder="Type and select Followers"
            value={findSelectedUsers(formik.values.users.followers) || []}
            name="users.followers"
            isMulti
            onChange={(value) => {
              selectedUsers(value, 'users.followers', formik);
            }}
            options={members}
            id="followers"
            error={formik.touched.users?.followers && formik.errors.users?.followers}
            isClearable={false}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </form>
  );
};

export default withErrorBoundary(TaskForm);
