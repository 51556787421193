import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { permissions } from 'components/state';
import { useRecoilValue } from 'recoil';
import Welcome from './components/Welcome';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import Icon from 'components/shared/Icon/Icon';

const MyScribeAI = () => {
  const { is_admin } = useRecoilValue(permissions);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { pushBreadcrumb } = breadcrumb;

  useEffect(() => {
    pushBreadcrumb({
      label: 'MyScribe AI',
      icon: <Icon icon="stars-ai" />,
      name: 'myscribe-ai',
      path: pathname
    });
  }, []);

  if (!is_admin) {
    navigate(-1);
  }

  return (
    <div>
      <div>
        <Outlet
          context={{
            open
          }}
        />
      </div>
      <Welcome />;
    </div>
  );
};

export default withErrorBoundary(MyScribeAI);
