import { Honeybadger } from '@honeybadger-io/react';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { optionify } from '../../../lib/helpers/utility';
import Switch from '../../shared/Switch/Switch';
import state from '../../state';
import Button from '../Buttons/Button';
import ColorPicker from '../ColorPicker/ColorPicker';
import Modal from '../Modal/Modal';
import Select from '../Select/Select';
import { showAlert } from '../Alert/Alert';

const StaffMember = ({
  member: loadedMember,
  onClose,
  setSelectedStaff,
  handleOpen,
  practiceId
}) => {
  const [show, setShow] = useState({ loading: false });
  const [member, setMember] = useState({
    f_name: '',
    l_name: '',
    email: '',
    id: 0,
    m_name: '',
    phone: '',
    color: '#ffffff',
    role_id: 0,
    active: false
  });
  const [selectedRole, _setSelectedRole] = useState(null);
  const [roles, setRoles] = useRecoilState(state.roles);
  const [roleOptions, setRoleOptions] = useRecoilState(state.roleOptions);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setMember(loadedMember);
    if (roles.practiceId !== practiceId) {
      loadRoles();
    } else {
      const roleIndex = roleOptions.findIndex((v) => v.value === loadedMember.role);
      _setSelectedRole(roleOptions[roleIndex]);
    }
  }, []);

  const handleSave = async () => {
    setLoading(true);
    let newShow = Object.assign({}, show);
    try {
      const changes = pick(member, ['role_id', 'status', 'color']);
      const res = await interimApi(
        'api/practice/member/update',
        {
          newMemberSettings: changes,
          userId: member.user_id,
          practiceId
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setSelectedStaff = { ...member, ...changes };
          onClose();
          queryClient.invalidateQueries(['staffPracticeAdmin']);
          showAlert({ title: 'Staff member updated successfully', color: 'success' });
          break;
        default:
          if (error) {
            showAlert({ title: error, color: 'danger' });
            Honeybadger.notify(`handleSave staffMember: ${error}`);
          } else Honeybadger.notify(`handleSave staffMember: unexpected error`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`handleSave staffMember: unexpected error ${error}`);
    }
    setShow(newShow);
    setLoading(false);
  };

  const loadRoles = async () => {
    let newShow = Object.assign({}, show);
    try {
      const res = await interimApi(
        '/api/practice/role/get',
        {
          practiceId,
          limit: 100,
          offset: 0
        },
        navigate
      );
      const { code, redirect, error, role_list: loadedRoles } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          const newRoleOptions = optionify(loadedRoles);
          setRoles({ practiceId, data: loadedRoles });
          setRoleOptions(newRoleOptions);
          const roleIndex = newRoleOptions.findIndex((v) => v.value === loadedMember.role_id);
          _setSelectedRole(newRoleOptions[roleIndex]);
          break;
        default:
          if (error) {
            showAlert({ title: error, color: 'danger' });
            Honeybadger.notify(`loadRoles: ${error}`);
          } else Honeybadger.notify(`loadRoles: unexpected error`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`loadRoles: unexpected error ${error}`);
    }
    setShow(newShow);
  };

  const setSelectedRole = (e) => {
    setMember({ ...member, role_id: e?.value });
    _setSelectedRole(e);
  };

  return (
    <Modal
      slideFromRight={true}
      handleOpen={handleOpen}
      handleClose={onClose}
      title={`Staff Member: ${member.f_name} ${member.m_name ? `${member.m_name} ` : ''}${
        member.l_name
      }`}
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={onClose} />

          <Button
            text="Save"
            onClick={handleSave}
            loading={loading}
            green
            data-qa="staff-save-btn"
          />
        </div>
      }>
      <div style={{ minHeight: '50vh', maxHeight: '90vh', height: '30rem' }}>
        <Select
          inputId="select-role"
          label="Select Role"
          menuPlacement="auto"
          menuContainerStyle={{ zIndex: 99999 }}
          options={roleOptions}
          value={selectedRole}
          onChange={setSelectedRole}
          style={{ width: '15rem' }}
        />
        <div className="flex gap-x-3 py-3 pl-1">
          <Switch
            checked={member.status === 'active'}
            onChange={(e) => setMember({ ...member, status: e ? 'active' : 'inactive' })}
          />
          <span>Member Active</span>
        </div>
        <div>
          <ColorPicker
            label="Color"
            color={member.color}
            handleSelectColor={(e) => setMember({ ...member, color: e })}
          />
        </div>
      </div>
    </Modal>
  );
};

export default StaffMember;
