import React, { useState, Suspense } from 'react';
import Input from 'components/shared/Input/Input';
import Button from 'components/shared/Buttons/Button';
import Select from 'components/shared/Select/Select';

import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { ACESFilmicToneMapping } from 'three';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { requestApi } from 'api/Api';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { showAlert } from 'components/shared/Alert/Alert';

function euclideanDistance(a, b) {
  return Math.sqrt(Math.pow(a?.x - b?.x, 2) + Math.pow(a?.y - b?.y, 2) + Math.pow(a?.z - b?.z, 2));
}

const options = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Muscle',
    value: 'muscle'
  }
];

const Model = ({
  setMark,
  hovered = null,
  points,
  setPoints,
  mark,
  setHovered,
  model,
  setSaved
}) => {
  const male = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_male.glb`);
  const female = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_female.glb`);
  const muscle = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_muscles.glb`);

  const models = {
    male,
    female,
    muscle
  };

  const { scene } = models[model];

  const { camera } = useThree();

  const [startRotation, setStartRotation] = useState();

  const handleClickDown = (event) => {
    event.stopPropagation();
    setStartRotation({ x: camera?.rotation?.x, y: camera?.rotation?.y, z: camera?.rotation?.z });
  };

  const handleClickUp = (event) => {
    if (mark || mark === 0) {
      event.stopPropagation();
      const distance = euclideanDistance(startRotation, {
        x: camera?.rotation?.x,
        y: camera?.rotation?.y,
        z: camera?.rotation?.z
      });

      if (distance < 0.02) {
        if (hovered || hovered === 0) {
          if (points[hovered]?.mark === mark) {
            setPoints((points) => {
              let newPoints = [...points];
              newPoints.splice(hovered, 1);
              return newPoints;
            });
            setHovered(null);
          } else {
            setMark(points[hovered]?.mark);
          }
        } else {
          setPoints((points) => [
            ...points,
            {
              position: [event.point.x, event.point.y, event.point.z],
              mark: mark
            }
          ]);
        }
        setSaved(false);
      }
    }
  };

  return (
    <primitive
      scale={0.7}
      onPointerDown={handleClickDown}
      onPointerUp={handleClickUp}
      object={scene}
    />
  );
};

const Point = ({ point, index, mark, setHovered }) => {
  const isSelected = point.mark === mark;
  return (
    <mesh
      onPointerEnter={() => setHovered(index)}
      onPointerLeave={() => setHovered(null)}
      scale={isSelected ? 0.1 : 0.04}
      position={point.position}>
      <sphereGeometry args={[0.1, 32, 32]} />
      <meshBasicMaterial color="cyan" transparent opacity={isSelected ? 1 : 0.4} />
    </mesh>
  );
};

const Landmarks = () => {
  const [marks, setMarks] = useState([]);
  const [mark, setMark] = useState();
  const [input, setInput] = useState();

  const [points, setPoints] = useState([]);
  const [hovered, setHovered] = useState();

  const [editModal, setEditModal] = useState(null);
  const [editInput, setEditInput] = useState();

  const [deleteModal, setDeleteModal] = useState(null);

  const [model, setModel] = useState('male');

  const [saved, setSaved] = useState(true);

  const [importModal, setImportModal] = useState(null);

  const addMark = () => {
    if (input) {
      setMark(marks.length);
      setMarks([...marks, input]);
      setInput();
      setSaved(false);
    }
  };

  const navigate = useNavigate();

  const handleSave = () => {
    const response = requestApi({
      url: 'api/landmarks/update',
      navigate,
      onSuccess: () => {
        setSaved(true);
      },
      method: 'post',
      params: {
        model,
        marks: {
          marks,
          points
        }
      }
    });
  };

  const getLandmarks = async () => {
    const onSuccess = (data) => {
      setMarks(data?.landmarks?.marks || []);
      setPoints(data?.landmarks?.points || []);
    };

    const response = requestApi({
      url: 'api/landmarks/get',
      navigate,
      onSuccess,
      params: {
        model
      }
    });

    return response;
  };

  const { data } =
    useQuery({
      queryKey: ['getLandmarks', model],
      queryFn: getLandmarks,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }) || {};

  return (
    <div className="flex h-full gap-4 h-[80vh] pr-5 mt-12">
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex flex-col !w-72 h-[80vh] overflow-auto gap-2">
        <div className="flex gap-2 mb-3">
          <a
            className="flex-1 justify-center text-sm flex gap-1 text-center bg-primary-600 text-white p-[10px] rounded"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify({ marks: marks, points: points })
            )}`}
            download={`${model}.json`}>
            Export
          </a>

          <button
            className="flex-1 justify-center text-sm flex gap-1 text-center bg-primary-600 text-white p-[10px] rounded"
            onClick={() =>
              setImportModal({
                points: true,
                file: null
              })
            }>
            Import
          </button>
        </div>

        <p className="text-lg mb-2">Model</p>
        <Select
          isClearable={false}
          icon={'scanning'}
          options={options}
          value={{ label: model }}
          onChange={({ value }) => {
            setMark();
            setSaved(true);
            handleSave();
            setModel(value);
          }}></Select>
        <p className="text-lg mb-2 mt-3">Marks</p>

        <div className="flex gap-2">
          <Input
            placeholder={'Landmark...'}
            value={input}
            onChange={(e) => setInput(e.target.value)}></Input>
          <Button onClick={addMark} text="Add Mark"></Button>
        </div>
        {marks.map((m, index) => (
          <div
            onClick={() => setMark(index)}
            className={cs(
              'rounded cursor-pointer !p-3 bg-white w-full flex justify-between',
              index == mark && '!bg-primary-500 text-white'
            )}>
            {m}
            <div className="flex gap-2">
              <Icon
                color={index == mark ? 'white' : 'info'}
                icon={'new-edit'}
                onClick={() => {
                  setEditModal(index);
                  setEditInput(m);
                }}
                className={'cursor-pointer'}></Icon>

              <Icon
                color={index == mark ? 'white' : 'danger'}
                icon={'trash'}
                onClick={() => {
                  setDeleteModal(index);
                }}
                className={'cursor-pointer'}></Icon>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-1 h-[80vh] relative bg-white rounded-lg">
        <Canvas
          style={{ width: '100%', height: '100%' }}
          gl={{ antialias: true, toneMappingExposure: 3, toneMapping: ACESFilmicToneMapping }}
          linear
          camera={{ position: [0, 15, 30], fov: 25 }}>
          <Suspense fallback={null}>
            <Model
              model={model}
              setHovered={setHovered}
              points={points}
              hovered={hovered}
              setPoints={setPoints}
              mark={mark}
              marks={marks}
              setMark={setMark}
              setSaved={setSaved}
            />
          </Suspense>

          {points.map((point, index) => (
            <Point point={point} setHovered={setHovered} mark={mark} index={index} key={index} />
          ))}

          <OrbitControls
            makeDefault
            maxPolarAngle={Math.PI / 1.7}
            maxDistance={8}
            minDistance={1}
          />
        </Canvas>

        <div className="absolute top-0 left-0 p-3">
          <Button
            disabled={saved}
            className={cs(
              'w-full mb-3',
              !saved ? '!bg-warning-600 text-white animate-bounce' : '!bg-neutral-400'
            )}
            text={saved ? 'Saved' : 'Save'}
            onClick={handleSave}
          />
        </div>
      </div>
      <Confirm
        handleOpen={editModal !== null}
        slideFromRight
        handleClose={() => setEditModal(null)}
        icon="new-edit"
        handleContinue={() => {
          setMarks((marks) => {
            let newMarks = [...marks];
            newMarks[editModal] = editInput;
            return newMarks;
          });
          setSaved(false);
          setEditModal(null);
        }}>
        <Input
          label={'Edit Mark'}
          onChange={(e) => setEditInput(e.target.value)}
          value={editInput}></Input>
      </Confirm>
      <Confirm
        variant="danger"
        primaryBtnTxt="Delete"
        title={`Delete ${marks[deleteModal]}`}
        icon="trash"
        message="Are you sure you want to delete Mark and all its points?"
        handleContinue={() => {
          setMark(null);
          setMarks((marks) => {
            let newMarks = [...marks];
            newMarks.splice(deleteModal, 1);
            setPoints((points) => points.filter((point) => point.mark !== deleteModal));
            return newMarks;
          });
          setSaved(false);
          setDeleteModal(null);
        }}
        handleOpen={deleteModal !== null}
        handleClose={() => setDeleteModal(null)}
      />

      <Confirm
        handleOpen={importModal !== null}
        handleClose={() => setImportModal(null)}
        title={'Import landmarks'}
        handleContinue={async () => {
          try {
            const data = JSON.parse(await importModal?.file?.text());
            setMarks(data?.marks || marks);
            setPoints(importModal.points ? data?.points || points : []);
            setImportModal(null);
            setSaved(false);
          }
          catch (e) {
            console.error(e);
            showAlert({title:'Invalid json format',color:'danger'})
          } 
        }}
        icon="new-file-upload">
        <div className="flex gap-2 mt-4">
          <Checkbox label={'Marks'} disabled={true} checked={true}></Checkbox>
          <Checkbox
            label={'Points'}
            checked={importModal?.points}
            onChange={(e) =>
              setImportModal({ ...importModal, points: e.target.checked })
            }></Checkbox>
          <input
            type="file"
            name="file"
            onChange={(e) => setImportModal({ ...importModal, file: e.target.files[0] })}
            filename={'file'}></input>
        </div>
      </Confirm>
      </ErrorBoundary>
    </div>
  );
};
export default withErrorBoundary(Landmarks);
