export const practiceSidebarLinks = [
  {
    path: '',
    name: 'Dashboard',
    icon: 'new-dark-dashboard',
    end: true
  },
  {
    path: 'appointments',
    name: 'Calendar',
    icon: 'new-dark-calendar',
    end: false
  },
  {
    path: 'charts',
    name: 'Charts',
    icon: 'new-dark-patients',
    end: false
  },
  {
    path: 'beyond-billing',
    name: 'Beyond Billing',
    icon: 'new-billing',
    end: false,
    children: [
      {
        path: 'beyond-billing/overview',
        name: 'Overview',
        icon: 'new-graph'
      },
      {
        name: 'Payments',
        path: 'beyond-billing/payments',
        icon: 'new-billing'
      },
      {
        path: 'beyond-billing/patient-ledger',
        name: 'Patient Ledger',
        icon: 'new-macro-type'
      },
      // {
      //   path: 'beyond-billing/financial-reporting',
      //   name: 'Financial Reporting',
      //   icon: 'receipt-text'
      // },
      {
        path: 'beyond-billing/dynamic-ar',
        name: 'Dynamic AR',
        icon: 'new-notev3'
      },
      {
        path: 'beyond-billing/era-eob/all',
        name: 'ERA/EOB',
        icon: 'new-calendar-note'
      },
      {
        path: 'beyond-billing/claims',
        name: 'Claims',
        icon: 'wallet'
      },
      {
        path: 'beyond-billing/superbills',
        name: 'Superbills',
        icon: 'new-bill'
      },
      {
        path: 'beyond-billing/electronic-invoices',
        name: 'Electronic Invoicing',
        icon: 'new-file-dollar'
      }
    ]
  },
  {
    path: 'comms-center/fax/main',
    name: 'Comms Center',
    icon: 'new-light-features',
    end: false
  },
  {
    path: 'practice-i-queue',
    name: 'PracticeIQueue',
    icon: 'new-practice-i-queue',
    end: false,
    children: [
      {
        name: 'Overview',
        path: 'practice-i-queue/overview',
        icon: 'new-graph'
      },
      {
        name: 'Tasks',
        path: 'practice-i-queue/tasks',
        icon: 'new-task'
      },
      {
        name: 'Clinical Notes',
        path: 'practice-i-queue/clinical-notes',
        icon: 'new-notev2'
      },
      {
        name: 'Notifications',
        path: 'practice-i-queue/notifications',
        icon: 'new-notifications'
      },
      {
        name: 'Appointments',
        path: 'practice-i-queue/appointments',
        icon: 'new-calendar'
      },
      {
        name: 'Forms',
        path: 'practice-i-queue/forms',
        icon: 'new-calendar-note'
      }
    ]
  },
  {
    path: 'settings',
    name: 'Settings',
    icon: 'new-settings',
    end: false,
    children: [
      {
        path: 'settings',
        name: 'Services',
        icon: 'new-star',
        end: true
      },
      {
        path: 'settings/products',
        name: 'Products',
        icon: 'new-pill'
      },
      {
        path: 'settings/packages',
        name: 'Packages',
        icon: 'new-packages'
      },
      {
        path: 'settings/calendar',
        name: 'Calendar',
        icon: 'new-calendarv2'
      },
      {
        path: 'settings/forms',
        name: 'Forms',
        icon: 'new-calendar-note'
      },
      {
        path: 'settings/payers',
        name: 'Payers',
        icon: 'new-payers'
      },
      {
        path: 'settings/account',
        name: 'Account',
        icon: 'user-square'
      },
      {
        path: 'settings/roles',
        name: 'Roles',
        icon: 'new-patients'
      },
      {
        path: 'settings/staff',
        name: 'Staff',
        icon: 'new-three-people'
      },
      {
        path: 'settings/eprescribe',
        name: 'ePrescribe',
        icon: 'new-eprescribe-v2'
      },
      {
        path: 'settings/billing',
        name: 'Billing',
        icon: 'wallet'
      },
      {
        path: 'settings/kiosk',
        name: 'Kiosk',
        icon: 'new-packages'
      },
      {
        path: 'settings/resources',
        name: 'Resources',
        icon: 'new-menu-board'
      }
    ]
  }
];
