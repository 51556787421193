import React from 'react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

const PromptsSidebar = ({ className, isOpen, setIsOpen }) => {
  const headerButtons = [
    { id: 1, component: <Button icon="new-notev1" text="New Note" outlined size="small" /> }
  ];

  const prompts = [
    {
      id: 1,
      title: 'Wellness Checkup',
      icon: 'new-messages',
      description: 'Write a note for patient Adrian Samuel undergoing a routine wellness checkup.',
      active: true
    },
    {
      id: 2,
      title: 'Manage Diabetes Mellitus',
      icon: 'new-messages',
      description: 'Write a detailed note for Adrian Samuel managing diabetes mellitus.',
      active: false
    },
    {
      id: 3,
      title: 'Unexplained Weight Loss',
      icon: 'new-messages',
      description: 'Write a note for Adrian Samuel reporting unexplained weight loss.',
      active: false
    },
    {
      id: 4,
      title: 'Upper Respiratory Infection',
      icon: 'new-messages',
      description:
        'Write a note for Adrian Samuel with symptoms of an upper respiratory infection.',
      active: false
    }
  ];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={cs(
        'fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        {
          'w-[264px]': isOpen, // Sidebar width when open
          'w-0': !isOpen // Sidebar width when closed, but leaving space for the hamburger icon
        },
        className
      )}
      style={{
        transition: 'width 0.2s ease'
      }}>
      <div className="flex items-center justify-between bg-primary-50 !py-[6px] px-3">
        {isOpen && (
          <Icon
            icon="new-hamburger"
            className="!ml-1 cursor-pointer"
            onClick={toggleSidebar} // Toggle sidebar on click
          />
        )}
        {isOpen && (
          <>
            <div className="p-2 pl-0">Prompts</div>
            <div className="flex items-center">
              {headerButtons.map((button) => (
                <div className="p-1" key={button.id}>
                  {button.component}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {isOpen && (
        <div className="h-full overflow-y-auto p-3">
          {prompts.map((prompt) => (
            <div key={prompt.id} className="mb-4 cursor-pointer rounded-lg p-2 hover:bg-primary-50">
              <div className="mb-2 flex items-center">
                <Icon icon={prompt.icon} className="mr-2 text-blue-500" />
                <span className="text-sm text-neutral-600">{prompt.title}</span>
              </div>
              <p className="text-sm font-thin text-neutral-800">{prompt.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PromptsSidebar;
