import { Honeybadger } from '@honeybadger-io/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import toast from 'react-hot-toast';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { ia, reShapePractitioners, reShapeProcedures } from '../../../../lib/helpers/utility';
import Modal from '../../../shared/Modal/Modal';
import Pagination from '../../../shared/Pagination/Pagination';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import Tabs from '../../../shared/Tabs/NewTabs';
import PreviewAppointment from '../../appointment/PreviewAppointment/PreviewAppointment';
import { currentPractice } from '../../practiceState';
import Button from 'components/shared/Buttons/Button';

export default function Appointments({ props }) {
  const [appointments, setAppointments] = useState([]);
  const [upcomingCounter, setUpcomingCounter] = useState(0);
  const [pastCounter, setPastCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [advancedForms, setAdvancedForms] = useState([]);
  const [showAdvancedForm, setShowAdvancedForm] = useState(false);
  const [selectedAdvancedForm, setSelectedAdvancedForm] = useState(null);
  const [allForms, setAllForms] = useState([]);
  const submitAdvancedSubjective = useRef();
  const [loading, setLoading] = useState({ general: false, table: false });
  const practice = useRecoilValue(currentPractice);
  const [page, setPage] = useState({ upcoming: 1, past: 1 });
  const { patient } = props?.patient ? props : useOutletContext();
  const { type } = useParams();
  const params = useParams();
  const navigate = useNavigate();
  const [practitioners, setPractitioners] = useState([]);
  const [tabsData, setTabsData] = useState([
    {
      label: `Upcoming (${upcomingCounter})`,
      path: ``,
      end: true
    },
    {
      label: `Past (${pastCounter})`,
      path: 'past'
    }
  ]);
  moment.tz.setDefault(practice?.timezone);

  useEffect(() => {
    getAppointments();
  }, [type]);

  const { data } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  useEffect(() => {
    getPractitioners();
  }, []);

  useEffect(() => {
    const copyArr = [...tabsData];
    copyArr[0] = { ...copyArr[0], label: `Upcoming (${upcomingCounter})` };
    copyArr[1] = { ...copyArr[1], label: `Past (${pastCounter})` };
    setTabsData(copyArr);
  }, [upcomingCounter, pastCounter]);

  const getAppointments = async (props) => {
    const { pageChanged, page: pageProp, type: typeProp } = props || {};

    if (pageChanged) setLoading({ ...loading, table: true });
    else setLoading({ ...loading, general: true });

    try {
      let params = {
        patientId: patient?.id,
        withCount: pageChanged ? undefined : true,
        page: pageProp || page[type],
        type: typeProp || type
      };

      let { data } = await interimApi('/api/practice/charts/appointments', params, navigate);

      const { appointments, upcomingCount, pastCount } = data || {};

      if (appointments) setAppointments(appointments);
      if (upcomingCount) setUpcomingCounter(upcomingCount);
      if (pastCount) setPastCounter(pastCount);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }

    setLoading({ general: false, table: false });
  };

  const mapAdvancedSOAP = (data) => {
    const allData = [];
    ia(data.subjective)
      ? data.subjective.map((subjective) => allData.push(subjective))
      : allData.push(data.subjective);
    ia(data.objective)
      ? data.objective.map((objective) => allData.push(objective))
      : allData.push(data.objective);
    ia(data.assessment)
      ? data.assessment.map((assessment) => allData.push(assessment))
      : allData.push(data.assessment);
    ia(data.plan) ? data.plan.map((plan) => allData.push(plan)) : allData.push(data.plan);
    return allData;
  };

  const getAdvancedSOAP = async (appointmentId, patientId) => {
    try {
      const params = {
        patientId,
        appointmentId,
        onlyResponses: true
      };
      let res = await interimApi('/api/form/advanced_soap/get', params, navigate);
      if (res.data) {
        let allData = [...res.data.history_exam];
        allData = [...allData, ...mapAdvancedSOAP(res.data)];
        setAdvancedForms(allData);
        setAllForms(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async (e, responseId, formId, formVersion) => {
    submitAdvancedSubjective.current.classList.add('is-loading');
    try {
      let params = {
        responseId,
        formId,
        json: { fields: JSON.stringify(e) },
        appointmentId: selectedAppointment.id,
        patientId: selectedAppointment.patient.id,
        formVersion
      };
      let res = await interimApi('/api/form/upsert_response', params, navigate);
      if (res.data.upsert) {
        toast.success('Changes have been saved!', { duration: 3000 });
        submitAdvancedSubjective.current.classList.remove('is-loading');
        submitAdvancedSubjective.current.innerHTML = 'Saved';
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const hideModal = () => {
    setShowModal(false);
    setSelectedAppointment(null);
    setAdvancedForms([]);
  };

  const getPractitioners = async () => {
    try {
      const res = await interimApi('/api/practice/member/get_practitioners', {}, navigate);
      const { redirect, practitioners: loadedPractitioners } = res.data;
      if (res.data.practitioners) {
        setPractitioners(loadedPractitioners);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  // todo: convert this with ReactQuery
  const getAppointment = async (id) => {
    try {
      let params = {
        id,
        withal: { medicalHistory: true, payments: true, patient, tag: true, resources: true }
      };

      const { data } = await interimApi('/api/appointment/get_one', params, navigate);

      if (data?.appointment)
        setSelectedAppointment((prevState) => ({ ...prevState, ...data?.appointment }));
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const changePage = ({ selected }) => {
    getAppointments({ pageChanged: true, page: selected + 1 });
    setPage({ ...page, [type]: selected + 1 });
  };

  return (
    <>
      <Tabs tabsData={tabsData} theme="secondary" className="w-full" />
      <div className="overflow-auto">
        <table className="primary-table">
          <thead>
            <tr className="relative h-[50px] !p-4 shadow-[0_0_16px_0_#25313c0a]">
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Practitioner</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Services</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Balance</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Status</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Date</th>
            </tr>
          </thead>
          <tbody>
            {loading.general || loading.table ? (
              <tr>
                <td colSpan={5} className="bg-white">
                  <Skeleton count={13} height={48} />
                </td>
              </tr>
            ) : (
              <Outlet
                context={{
                  practice,
                  appointments,
                  setShowModal,
                  getAppointment,
                  getAdvancedSOAP,
                  setSelectedAppointment
                }}
              />
            )}
          </tbody>
        </table>

        {showAdvancedForm && (
          <Modal
            open={showAdvancedForm}
            onClose={() => {
              setShowAdvancedForm(false);
              setSelectedAdvancedForm(null);
            }}
            styling={{ width: '80vw', height: '80vh' }}>
            <ReactFormGenerator
              answer_data={
                selectedAdvancedForm.form && JSON.parse(selectedAdvancedForm.json.fields)
              }
              data={
                selectedAdvancedForm.form
                  ? selectedAdvancedForm.form.json.fields
                  : selectedAdvancedForm.json.fields
              }
              submitButton={
                <div className="block shrink grow basis-0 !p-3 text-center">
                  <Button text="Save" color="success" forwardedRef={submitAdvancedSubjective} />
                </div>
              }
              onSubmit={(e) =>
                handleFormSubmit(
                  e,
                  selectedAdvancedForm.form ? selectedAdvancedForm.id : null,
                  selectedAdvancedForm.form_id || selectedAdvancedForm.id,
                  selectedAdvancedForm.form_version || selectedAdvancedForm.version
                )
              }
            />
          </Modal>
        )}

        {selectedAppointment && (
          <PreviewAppointment
            appointment={selectedAppointment}
            showPreviewAppointment={showModal}
            hidePreviewAppointment={hideModal}
            setAppointment={setSelectedAppointment}
            services={reShapeProcedures(data?.services)}
            setRefetchAppointments={() => getAppointments()}
            practitioners={reShapePractitioners(practitioners)}
          />
        )}
      </div>

      <Pagination
        onPageChange={changePage}
        page={type == 'past' ? page?.past : page.upcoming}
        totalItems={type == 'past' ? pastCounter : upcomingCounter}
      />
    </>
  );
}
