import React, { useEffect } from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import cs from 'classnames';
import { question } from '../../../lib/questionnaire';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import Answers from './Answers';
import Card from './Card';

const Questionnaire = ({ handleFormView }) => {
  const { packet, setBreadCrumbs, formik, isEdit, setEnableSubmit } = usePacketBuilderContext();

  const handleClick = (value) => {
    if (value === 'patient_creation') {
      formik.setFieldValue('patient_creation', !formik?.values?.patient_creation);
    }
    const currentValue = formik.values.automation[value];
    formik.setFieldValue(`automation.${value}`, !currentValue);

    if (value !== 'forms' && value !== 'patient_creation') {
      setBreadCrumbs((prevBreadcrumbs) =>
        prevBreadcrumbs.map((item) => {
          if (item.view === value) {
            return { ...item, hidden: !item.hidden };
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    if (isEdit) {
      setEnableSubmit(true);
    }
  }, [isEdit, setEnableSubmit]);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <div className={cs(isEdit ? '!h-full' : '!pb-9')}>
          {!isEdit && (
            <>
              <div className="flex justify-between">
                <p className={cs('pb-2 text-sm font-600 text-primary-500')}>{question?.title}</p>
              </div>
              <p className="pb-[21px] text-[12px] font-400 text-primary-900">
                {question?.description}
              </p>
            </>
          )}

          <div className="grid grid-cols-2 gap-4">
            {isEdit ? (
              <>
                <div className="grid !gap-3">
                  <Card
                    title="Providers"
                    keyField="provider_ids"
                    description="Will send before each appointment for this provider."
                    label="Providers Picked"
                    view="providers"
                    values={packet?.provider_ids}
                    hasEdit
                  />
                  <Card
                    title="Services Rendered"
                    description="Will send before each encounter for this service. Intake forms should be assigned to new patient service types."
                    keyField="service_ids"
                    label="Services Picked"
                    view="services"
                    values={packet?.service_ids}
                    hasEdit
                  />
                  <Card
                    title="Appointment Tags"
                    keyField="tag_ids"
                    description="Customizable tags are ideal for broader automations. IE: New Patient, Sick Visit, No Insurance."
                    label="Tags Picked"
                    view="tags"
                    values={packet?.tag_ids}
                    hasEdit
                  />
                  <Card
                    title="Patient Creation"
                    keyField="patient_creation"
                    description="Will send to every newly created patient. Will not send during mass imports."
                    values={packet?.patient_creation}
                  />
                </div>
                <div>
                  <Card
                    title="Forms"
                    keyField="form_ids"
                    description="Below are the forms that will be sent with this form packet."
                    label="Forms Picked"
                    view="forms"
                    values={packet?.form_ids}
                    hasEdit
                    handleFormView={handleFormView}
                  />
                </div>
              </>
            ) : (
              question?.answers?.map((answer, index) => (
                <Answers
                  key={index}
                  index={index}
                  answer={answer}
                  isChecked={formik.values?.automation[answer?.value]}
                  handleClick={handleClick}
                />
              ))
            )}
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default withErrorBoundary(Questionnaire);
