import React from 'react';

import TH from './table/TH';
import { Capitalize, formatDateAndTime, mString } from '../../../../lib/helpers/utility';

const ReceiptTransactions = ({ transactions, timezone }) => {
  return (
    <div className="flex flex-col rounded-[12px] !border-[0.6px] border-solid border-neutral-200 overflow-hidden !px-5 shadow-[0px_1px_3px_0px_rgba(25,33,61,0.05),0px_2px_8px_0px_rgba(25,33,61,0.04)]">
      <p className="font-700 text-primary-900 text-sm pt-[14px] !pb-1">Payments history</p>

      <table>
        <thead>
          <tr className="border-[0] border-b-[0.6px] border-solid border-neutral-200 hover:!bg-transparent">
            <TH label="Date" align="start" />
            <TH label="Type" align="center" />
            <TH label="Status" align="center" />
            <TH label="Amount" align="center" />
            <TH label="Technology fee" align="center" />
            <TH label="Total" align="end" />
          </tr>
        </thead>

        <tbody>
          {transactions.map?.((v, i) => {
            return (
              <tr
                className="border-[0] border-b-[0.6px] border-solid border-neutral-200 hover:!bg-transparent last:!border-0"
                key={i}>
                <td className="text-xs text-neutral-900 !py-2 leading-[14px] text-left font-600 px-0">
                  {formatDateAndTime(v?.created_at, timezone)}
                </td>
                <td className="text-xs text-neutral-700 !py-2 leading-[14px] text-center">
                  {Capitalize(String(v?.type).replaceAll('_', ' '))}
                </td>
                <td className="text-xs text-neutral-700 !py-2 leading-[14px] text-center">
                  {Capitalize(String(v?.status).replaceAll('_', ' '))}
                </td>
                <td className="text-xs text-neutral-700 !py-2 leading-[14px] text-center">
                  {mString(v?.amount_paid_self - (v?.surcharge_amount || 0))}
                </td>
                <td className="text-xs text-neutral-700 !py-2 leading-[14px] text-center">
                  {mString(v?.surcharge_amount || 0)}
                </td>
                <td className="text-xs text-neutral-900 !py-2 leading-[14px] text-right font-600 px-0">
                  {mString(v?.amount_paid_self)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReceiptTransactions;
