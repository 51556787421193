import React from 'react';
import Button from '../Buttons/Button';

export default function AIChatInput(
  onGoBack = () => {},
  onGenerate = () => {},
  onTextChange = () => {}
) {
  return (
    <div className="flex h-[62px] w-full flex-row items-center rounded-lg border border-gray-300 bg-[#FFF] p-2 pl-3 pr-3 shadow-2xl">
      <Button
        text="Back"
        outlined
        transparent
        size="small"
        className="!py-2 !pl-4 !pr-4"
        onClick={onGoBack}
      />
      <input
        type="text"
        className="flex-grow border-none bg-transparent p-2 focus:outline-none"
        placeholder="Type your note..."
        onChange={onTextChange}
      />
      <Button
        text="Generate"
        icon="stars-ai"
        iconSize={18}
        iconColor="#fff"
        size="small"
        className="!py-2 !pl-4 !pr-4"
        onClick={onGenerate}
      />
    </div>
  );
}
