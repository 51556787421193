import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from 'api/User';
import { showAlert } from 'components/shared/Alert/Alert';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { genders, patientInitialValues, UpdatePatientValidationSchema } from './lib/helpers';
import PersonalInfo from './personal_info';
import { ia, io } from '/lib/helpers/utility';
const PersonalInfoContainer = forwardRef(({ patient, setShowModal }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formRef = useRef();

  const { mutateAsync: mutateUpdatePatient, isLoading: loadingUpdate } = useMutation({
    mutationFn: (data) => updateUser(navigate, data)
  });

  useImperativeHandle(ref, () => ({
    onSubmit: () => handleSubmit(),
    isLoading: loadingUpdate
  }));
  const formik = useFormik({
    initialValues: patientInitialValues(patient),
    enableReinitialize: true,
    validationSchema: UpdatePatientValidationSchema,
    onSubmit: async (values) => {
      const patientToBeUpdated = {
        patient: {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          dob: values?.dob,
          gender: values?.gender,
          email: values?.email,
          phone: values?.phone
        }
      };

      const changes = {};

      const valuesUpdated = patientToBeUpdated?.patient;
      const initialValues = formik.initialValues;

      for (const key in valuesUpdated) {
        if (valuesUpdated[key] !== initialValues[key]) changes[key] = valuesUpdated[key];
      }

      if (!io(changes)) return;

      await mutateUpdatePatient(
        { changes, user_id: patient.id },
        {
          onSuccess: async ({ code, holds }) => {
            if (code === 0 && !ia(holds)) {
              showAlert({ title: 'Patient updated successfully!', color: 'primary' });
              queryClient.invalidateQueries(['patient']);
              queryClient.invalidateQueries(['patients']);
              setShowModal(false);
            } else if (code === 6) {
              formik.setFieldError('phone', 'Invalid phone number entered.');
            } else {
              holds.forEach((hold) => {
                showAlert({
                  title: 'Patient update failed!',
                  message:
                    `${capitalize(hold?.field)}: ${hold?.message}` || 'Failed to update patient',
                  color: 'danger'
                });
              });
            }
          }
        }
      );
    }
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };

  return (
    <div>
      <PersonalInfo
        patient={patient}
        genderList={genders}
        formRef={formRef}
        formik={formik}
        formikHandle={formik.handleChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
});

export default PersonalInfoContainer;
