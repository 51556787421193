import React from 'react';
import Modal from 'components/shared/Modal/Modal';

const ActivityModal = ({ isOpen, onAfterOpen, onScroll, handleClose, children }) => {
  return (
    <Modal
      slideFromRight
      bodyClassName="!p-4 bg-white"
      id="ActivityModal"
      className="w-[450px]"
      title="User Activity"
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      handleClose={handleClose}
      portalClassName="bg-primary-50"
      isFooter={false}
      onScroll={onScroll}>
      {children}
    </Modal>
  );
};

export default ActivityModal;
