import React from 'react';

import { useOutletContext } from 'react-router-dom';
import {
  Capitalize,
  calculateAge,
  formatDate,
  formatPhoneNumber,
  isEmpty
} from '../../../../../../../../lib/helpers/utility';

const DemographicsPreview = ({ itemProps }) => {
  const { patient: outletPatient } = useOutletContext() || {};

  const patient = outletPatient || itemProps.patient;
  const fullName = `${patient?.f_name} ${patient?.l_name}`;
  const patientAddress = patient?.userAddress;
  const fields = ['address_ln_1', 'city', 'state', 'zip'];

  const fullAddress = fields
    .map((key) => {
      return !isEmpty(patientAddress?.[key]) && patientAddress?.[key];
    })
    .filter(Boolean)
    .join(', ');

  return (
    <div>
      <div className="text-base font-500 text-neutral-800">Demographics</div>

      <div className="!pl-5">
        <p className="!mt-1.5">{fullName}</p>
        <p className="!mt-1.5">
          {formatDate(patient?.dob, 'UTC', true)} ({calculateAge({ dateOfBirth: patient?.dob })})
        </p>
        <p className="!mt-1.5">{formatPhoneNumber(patient?.phone)}</p>
        <p className="!mt-1.5">{Capitalize(patient?.gender)}</p>
        <p className="!mt-1.5">{fullAddress}</p>
      </div>
    </div>
  );
};

export default DemographicsPreview;
