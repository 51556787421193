import React from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import Button from 'components/shared/Buttons/Button';

const FooterButtons = ({ isLastStep, showNavigationButtons, nextTo, backTo }) => {
  const { enableNextTo, formik, setBreadCrumbs, handleClose, enableSubmit, isEdit } =
    usePacketBuilderContext();

  const navigateView = (direction) => {
    setBreadCrumbs((prevBreadCrumbs) => {
      const currentIndex = prevBreadCrumbs.findIndex((c) => c.active);
      let newBreadCrumbs = prevBreadCrumbs.map((crumb, index) => {
        if (index === currentIndex) {
          return { ...crumb, active: false, isFinished: true };
        }
        return crumb;
      });

      let nextIndex = currentIndex;

      if (direction === 'next') {
        nextIndex++;
        while (nextIndex < newBreadCrumbs.length && newBreadCrumbs[nextIndex].hidden) {
          nextIndex++;
        }
      } else if (direction === 'back') {
        nextIndex--;
        while (nextIndex >= 0 && newBreadCrumbs[nextIndex].hidden) {
          nextIndex--;
        }
      }

      if (nextIndex >= 0 && nextIndex < newBreadCrumbs.length) {
        newBreadCrumbs[nextIndex] = { ...newBreadCrumbs[nextIndex], active: true, hidden: false };
      }

      return newBreadCrumbs;
    });
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex  w-full items-center justify-between">
        {showNavigationButtons && !isEdit ? (
          <Button
            disabled={!backTo}
            outlined
            color="neutral"
            text="Back"
            data-qa="back-btn"
            onClick={() => navigateView('back')}
          />
        ) : (
          <Button color="neutral" text="Close" outlined onClick={handleClose} data-qa="close-btn" />
        )}
        <div className="flex-end flex gap-4">
          {showNavigationButtons && nextTo && !isLastStep && !isEdit && (
            <Button
              disabled={!enableNextTo}
              text="Continue"
              onClick={() => navigateView('next')}
              data-qa="continue-btn"
            />
          )}
          {isLastStep && !isEdit && (
            <Button
              text="Submit"
              disabled={!enableSubmit}
              onClick={() => handleSubmit()}
              data-qa="submit-btn"
            />
          )}
          {isEdit && (
            <Button
              text="Save changes"
              disabled={!enableSubmit}
              onClick={handleSubmit}
              data-qa="save-changes-btn"
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(FooterButtons);
