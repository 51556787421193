import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import Button from 'components/shared/Buttons/Button';
import ExportCalendar from '../Export/Export';
import PendingAppointments from '../PendingAppointments';
import './RBCToolbar.scss';
import Filters from '../../../Dashboard/widgets/UpcomingAppointments/Calendar/components/Navigation/components/Filters';

const RBCToolbar = (toolbar) => {
  const {
    label,
    date,
    onNavigate,
    onView,
    showCalendarViewButton,
    showViews,
    view,
    views,
    handleNewAppointment,
    setCurrentView,
    setDefaultDate,
    services,
    practitioners,
    practice,
    appointmentTypes,
    pendingCount
  } = toolbar;
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [showPendingAppointment, setShowPendingAppointment] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [loadingPending, setLoadingPending] = useState(false);

  const [exportRange, setExportRange] = useState([
    {
      startDate: new Date(moment.utc().format()),
      endDate: new Date(moment.utc().format()),
      key: 'selection'
    }
  ]);

  const navigate = useNavigate();

  //new pending appointment modal
  const handlePendingAppointment = () => {
    blockScroll();
    getPendingAppointments();
    setShowPendingAppointment(true);
  };

  const hidePendingAppointment = () => {
    allowScroll();
    setShowPendingAppointment(false);
  };

  const getDates = (view) => {
    let start, end;

    if (view === 'day' || view === 'provider' || view === 'resource' || view === 'agenda') {
      start = moment.utc(date).startOf('day');
      end = moment.utc(date).endOf('day');
    } else if (view === 'week') {
      start = moment.utc(date).startOf('isoWeek');
      end = moment.utc(date).endOf('isoWeek');
    } else if (view === 'month') {
      start = moment.utc(date).startOf('month');
      end = moment.utc(date).endOf('month');
    }

    setExportRange([
      {
        startDate: new Date(moment.utc(start).format()),
        endDate: new Date(moment.utc(end).format()),
        key: 'selection'
      }
    ]);
  };

  useEffect(() => {
    setCurrentView(view);
    getDates(view);
  }, [view]);

  useEffect(() => {
    setDefaultDate(date);
  }, [date]);

  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToCurrent = () => {
    onNavigate('TODAY');
  };

  const getPendingAppointments = async () => {
    try {
      setLoadingPending(true);

      const params = {
        appointmentStatus: ['pending'],
        withal: { patient: true, practice: true, practitioner: true }
      };

      const { data } = await interimApi('/api/appointment/get_multiple', params, navigate);

      if (data?.appointment) setPendingAppointments(data?.appointment);

      setLoadingPending(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <div className="RBCToolbar">
      <Button
        onClick={handleNewAppointment}
        text="New appointment"
        type="secondary"
        data-qa="new-appointment-btn"
      />
      <div className="flex items-center gap-x-5">
        <div className="flex gap-x-2">
          <Button
            color="neutral"
            outlined
            onClick={goToBack}
            icon="back"
            size="small"
            data-qa="calendar-back-btn"
          />
          <Button
            color="neutral"
            outlined
            onClick={goToCurrent}
            text="Today"
            size="small"
            data-qa="calendar-current-btn"
          />
          <Button
            color="neutral"
            outlined
            onClick={goToNext}
            iconRight="next"
            size="small"
            data-qa="calendar-next-btn"
          />
        </div>
        <div className="text-sm font-500">{label}</div>
      </div>

      <div className="flex gap-x-2">
        <Button
          data-qa="pending-btn"
          color="warning"
          type="secondary"
          onClick={handlePendingAppointment}
          text={`${pendingAppointments.length || pendingCount} Pending`}
          size="small"
        />
        <Filters buttonClassNames="outline outline-1 outline-neutral-100" />
        <ExportCalendar exportRange={exportRange} setExportRange={setExportRange} />
      </div>

      {showCalendarViewButton && (
        <Button
          color="neutral"
          outlined
          onClick={() => navigate('/portal/appointments')}
          text="Calendar view"
          size="small"
          data-qa="calendar-view-btn"
        />
      )}
      {showViews && (
        <div className="flex gap-x-2">
          {views.map((name) => (
            <Button
              key={name}
              data-qa={`calendar-view-${name}`}
              color="neutral"
              outlined
              className={cs(view === name && '!bg-neutral-100')}
              onClick={() => onView(name)}
              text={name}
              size="small"
            />
          ))}
        </div>
      )}
      <PendingAppointments
        view={view}
        practice={practice}
        loadingPending={loadingPending}
        pendingAppointments={pendingAppointments}
        setPendingAppointments={setPendingAppointments}
        showPendingAppointments={showPendingAppointment}
        hidePendingAppointments={hidePendingAppointment}
      />
    </div>
  );
};

export default RBCToolbar;
