import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Subjective from './Subjective';

const SubjectiveSection = ({ components, sectionRef, options }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const subjectiveRef = useRef(null);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.subjective?.title}
      id={components?.subjective?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Subjective sectionRef={sectionRef} subjectiveRef={subjectiveRef} />
    </Accordion>
  );
};

export default SubjectiveSection;
