import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import NewEOB from './NewEOB';
import AllocateEOB from './AllocateEOB';
import EraRowDetails from './EraRowDetails';
import '../../BeyondBilling.scss';
import Filters from './Filters';
import { useEraContext } from '../../../../../lib/context/EraContext/EraContext';
import Table from './Table';
import { EraContextProvider } from '../../../../../lib/context/EraContext/EraContextProvider';
import { finalizeEra, refreshERA } from '../../../../../api/ERA';
import { showAlert } from '../../../../shared/Alert/Alert';
import { ia } from '../../../../../lib/helpers/utility';
import Header from '../../../../shared/Header/Header';

const EraAndEob = () => {
  const { eras, type, eraCount, codes } = useEraContext();
  const [newEOB, setNewEOB] = useState(false);
  const [selectedEOB, setSelectedEOB] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutateFinalizeERA = useMutation({
    mutationFn: ({ eraId, finalized }) =>
      finalizeEra(navigate, {
        eraId,
        fields: {
          finalized: finalized ? false : true
        }
      }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: `ERA finalized successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to finalize ERA!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to finalize ERA!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const refresh = useMutation({
    mutationFn: () => refreshERA(navigate),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: `ERA's refreshed successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to refresh ERA's!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to refresh ERA's!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  if (id) return <Outlet />;

  return (
    <>
      <Header title="ERA/EOB" subtitle={type}>
        <Filters setNewEOB={setNewEOB} refresh={refresh} />
      </Header>
      <Table>
        {ia(eras) &&
          eras?.map((era, index) => {
            return (
              <EraRowDetails
                era={era}
                type={type}
                key={index}
                index={index}
                setSelectedEOB={setSelectedEOB}
                handleFinalized={() =>
                  mutateFinalizeERA.mutate({ eraId: era.id, finalized: era.finalized })
                }
              />
            );
          })}
      </Table>

      {newEOB && <NewEOB newEOB={newEOB} setNewEOB={setNewEOB} />}

      {selectedEOB && (
        <AllocateEOB
          codes={codes}
          loadedEOB={selectedEOB}
          handleClose={() => setSelectedEOB(null)}
        />
      )}
    </>
  );
};

const EraWrapper = () => {
  return (
    <EraContextProvider>
      <EraAndEob />
    </EraContextProvider>
  );
};

export default EraWrapper;
