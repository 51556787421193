import { formatDate, mString } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React from 'react';
import SendInvoiceMessagePopUp from '../../components/SendInvoiceMessagePopUp';
import { searchPatients } from '../searchPatients';

const MemoComponent = ({ value }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: value
      }}
    />
  );
};

export const getColDefs = (timezone) => [
  {
    field: 'id',
    headerName: 'Invoice ID',
    cellClass: 'no-border'
  },
  {
    field: 'patient_name',
    headerName: 'Patient',
    cellClass: 'no-border'
  },
  {
    field: 'created_at',
    headerName: 'Invoice Date',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    cellClass: 'no-border'
  },
  {
    field: 'delivery_method',
    headerName: 'Delivery Method',
    valueGetter: (params) => {
      const deliveryMethod = params?.data?.delivery_method;
      if (!deliveryMethod) return '-';
      const methodUsed = Object.keys(deliveryMethod).filter(
        (key) => deliveryMethod[key]?.total > 0
      );

      return methodUsed.join(', ').toUpperCase();
    },
    cellClass: 'no-border'
  },
  {
    field: 'total_amount_cents',
    headerName: 'Amount',
    aggFunc: (params) => {
      let sum = 0;
      params.values.forEach((value) => (sum += value));
      return sum;
    },
    valueFormatter: ({ value }) => mString(value || 0),
    cellClass: 'no-border'
  },
  {
    field: 'paidStatus',
    headerName: 'Status',
    cellClass: 'no-border'
  },
  { field: 'memo', cellRenderer: MemoComponent, cellClass: 'no-border', headerName: 'Memo' },
  { field: 'internal_invoice_ids', headerName: 'Internal Invoices', cellClass: 'no-border' },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: SendInvoiceMessagePopUp,
    maxWidth: 70,
    cellClass: 'no-border'
  }
];

export const DEFAULT_COLUMN_DEFS = { resizable: true, enableRowGroup: true };

export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: false,
  groupSelectsChildren: true,
  enableRangeSelection: true,
  suppressAggFuncInHeader: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Invoice',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  status: {
    type: 'checkbox',
    values: null,
    options: ['Paid', 'Partially Paid', 'Not Paid'],
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  }
};
