import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Vitals from '../../../../Vitals/Vitals';

const VitalsSection = ({ components, hpNoteOverviewRef, customFormsParams, sectionRef }) => {
  const {
    clinicalNote,
    vitalsRef,
    currentHpOverviewData = () => {},
    setCurrentHpOverviewData = () => {},
    setVitals = () => {}
  } = useClinicalNoteContext();

  const onReset = () => {
    vitalsRef.current.resetVitals();
  };

  return (
    <Accordion
      updateFormType={{ name: 'Vitals', parentKey: 'hp', childKey: 'vitals' }}
      title={components?.vitals?.title}
      id={components?.vitals?.id}
      disabled={clinicalNote?.locked}
      sectionRef={sectionRef}
      advanced={false}
      options={[{ title: 'Reset Vitals', icon: 'new-reset', onClick: () => onReset() }]}>
      <Vitals
        ref={vitalsRef}
        lastVitals={currentHpOverviewData?.vitals}
        setLastVitals={setCurrentHpOverviewData}
        setOverviewVitals={setVitals}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
      />
    </Accordion>
  );
};

export default VitalsSection;
