import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import Icon from '../Icon/Icon';
import Popup from './Popup';

export default function DateRangeFilter({ data, index, setFilter }) {
  const startDate = data?.values?.startDate;
  const endDate = data?.values?.endDate;

  const handleChangeDate = (e) => {
    let startDate = moment.utc(e.selection.startDate).format('YYYY-MM-DD');
    let endDate = moment.utc(e.selection.endDate).format('YYYY-MM-DD');
    setFilter(index, {
      ...data,
      values: {
        startDate,
        endDate
      }
    });
  };

  return (
    <Popup
      width={540}
      position={['left top', 'bottom center', 'top center']}
      trigger={
        <div className="flex  cursor-pointer flex-col !py-2">
          <p className="!px-4 text-sm font-medium text-primary-700">
            {data?.title || 'Date Range'}
          </p>
          <div className="mt-4 flex justify-between !px-4">
            {startDate && endDate ? (
              <p className="font-medium text-primary-900">
                {moment(startDate).format('MMM DD, YYYY')} -{' '}
                {moment(endDate).format('MMM DD, YYYY')}
              </p>
            ) : (
              <p className="text-sm text-neutral-500">No interval selected</p>
            )}
            <Icon color="primary" shade={900} icon="calendar" />
          </div>
          <div className="!mx-4 mt-1 border !border-t-0 border-solid border-neutral-200"></div>
        </div>
      }>
      <DateRangePicker
        className="!mt-2 w-[540px] overflow-hidden rounded-[10px] bg-white "
        onChange={handleChangeDate}
        ranges={[
          {
            startDate: startDate
              ? startOfDay(moment(data?.values?.startDate).toDate())
              : moment().toDate(),
            endDate: endDate ? endOfDay(moment(data?.values?.endDate).toDate()) : moment().toDate(),
            key: 'selection'
          }
        ]}
      />
    </Popup>
  );
}
