import React from 'react';
import { useRecoilValue } from 'recoil';
import state from '../../state';
import { pm } from '../../../lib/helpers/utility';
import Button from '../Button/Button';

const RoleEntry = ({ role, index, handleRoleEditClick }) => {
  const p = useRecoilValue(state.permissions);
  return (
    <tr key={`roles-table-index-${index}`}>
      <td>{role.name}</td>
      <td>{role.description}</td>
      <td>{role.numOfPermissions}</td>
      <td>{role.numOfUsers}</td>
      <td>
        {pm(p, 'role.update') && (
          <Button warning onClick={() => handleRoleEditClick(role.id, index)} id={`edit-${role.name}`}>
            Edit
          </Button>
        )}
      </td>
    </tr>
  );
};

export default RoleEntry;
