import { iaRa, io } from 'lib/helpers/utility';

export const shouldDisableDelete = ({ claim = {}, procedure = {} }) => {
  if (!io(claim)) return;

  const isClinicalNoteLocked =
    claim?.superbill?.encounter?.appointment?.medicalHistory?.state === 'finalized' &&
    claim?.superbill?.encounter?.appointment?.medicalHistory?.locked;

  const originalProcedures = iaRa(claim?.superbill?.encounter?.cpt_codes);

  const shouldDeleteBeDisabled =
    isClinicalNoteLocked && originalProcedures?.some((row) => row?.code?.value == procedure?.code);

  return shouldDeleteBeDisabled;
};
