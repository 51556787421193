import Tippy from '@tippyjs/react';
import React from 'react';

const FormsTippy = ({ data, formCallBack }) => {
  const forms = formCallBack(data);
  const maxLength = 1;
  const truncatedForms = forms.slice(0, maxLength);
  const remainingForms = forms.slice(maxLength);

  return (
    <>
      {remainingForms.length > 0 ? (
        <Tippy
          arrow={true}
          placement="left-start"
          className="tippy-dark"
          content={forms.map((form, idx) => (
            <p key={idx} className="relative px-1 text-[12px] font-500 text-white">
              {form}
            </p>
          ))}>
          <p>{truncatedForms}...</p>
        </Tippy>
      ) : (
        <p>{truncatedForms}</p>
      )}
    </>
  );
};

export default FormsTippy;
