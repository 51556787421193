import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { interimApi } from 'api/InterimApi';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Permissions from './Permissions';
import { VERADIGM_USER_TYPES } from './constants';
import Input from '../Input/Input';
import Textarea from '../Textarea/Textarea';

const NewRole = ({ handleClose, handleOpen, practiceId, role: loadedRole, createRoleHandler }) => {
  const [show, setShow] = useState({ loading: false, edit: false });
  const [role, setRole] = useState({ id: 0, permisisons: {}, name: '', veradigm_user_type: null });
  const [orgRole, setOrgRole] = useState({ id: 0, permissions: {} });
  const [selectedVUT, setSelectedVUT] = useState(null);
  const navigate = useNavigate();
  const descriptionRef = useRef();

  useEffect(() => {
    setRole(loadedRole);
    setOrgRole(loadedRole);
  }, []);

  const handleRoleChange = ({ path, newValue, recursePath }) => {
    let newRole = _.set(Object.assign({}, role), path, newValue);
    if (recursePath) {
      newRole = _.set(newRole, ['arrayPermissions', ...recursePath, 'value'], newValue);
    }
    setRole(newRole);
  };

  const updateRole = async () => {
    let newShow = Object.assign({}, show);
    try {
      if (!role.veradigm_user_type) {
        toast.error(`You need to select a Veradigm User Type!`);
        return;
      }
      const res = await interimApi(
        '/api/practice/role/create',
        {
          practiceId: practiceId,
          role: role
        },
        navigate
      );
      const { code, redirect, error, newRoleId } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          createRoleHandler({ ...role, id: newRoleId });
          break;
        default:
          toast.error(error || `An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
    setShow(newShow);
  };

  return (
    <Modal
      slideFromRight
      title={`Role: ${role?.name}`}
      handleClose={handleClose}
      handleOpen={handleOpen}
      footer={
        <>
          <Button onClick={handleClose} warning>
            Cancel
          </Button>
          <Button onClick={updateRole} green>
            Save Changes
          </Button>
        </>
      }>
      <Input
        label="Name"
        style={{ width: '50%', minWidth: '10rem' }}
        placeholder="Role Name"
        value={role.name}
        onChange={(e) => handleRoleChange({ path: 'name', newValue: e?.target?.value })}
      />
      <Textarea
        label="Description"
        forwardedRef={descriptionRef}
        value={role.description}
        onChange={(e) => handleRoleChange({ path: 'description', newValue: e.target.value })}
      />
      <div>Veradigm User Type:</div>
      <ReactSelect
        options={VERADIGM_USER_TYPES}
        value={selectedVUT}
        onChange={(e) => {
          setSelectedVUT(e);
          setRole({ ...role, veradigm_user_type: e.value });
        }}
        placeholder="Veradigm User Type"
      />
      <Permissions onChange={handleRoleChange} permissions={role?.arrayPermissions} />
    </Modal>
  );
};

export default NewRole;
