import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import RiskFactors from './RiskFactors';

const RiskFactorsSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Risk Factors', parentKey: 'progress', childKey: 'riskFactors' }}
      title={components?.riskFactors?.title}
      id={components?.riskFactors?.id}
      disabled={clinicalNote?.locked}>
      <RiskFactors sectionRef={sectionRef} />
    </Accordion>
  );
};

export default RiskFactorsSection;
