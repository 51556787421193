import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getColDefs, DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS } from './configs';
import Transactions from 'components/shared/Transactions/transactions';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const TransactionsTable = () => {
  const { id: patientId } = useParams();
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  return (
    <TableContextProvider
      name="billing_transactions"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone)}
      pagination>
      <Transactions
        category="billing_transactions"
        patientId={patientId}
        colDefs={getColDefs(practiceTimezone)}
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
      />
    </TableContextProvider>
  );
};

export default TransactionsTable;
