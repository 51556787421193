import React from 'react';
import Tippy from '@tippyjs/react';

import Icon from '../../../../shared/Icon/Icon';
import { ia, mString } from '../../../../../lib/helpers/utility';
import { concat, map } from 'lodash';

export const handleAdjustmentCodes = (adjustmentCodes, codes) => {
  return adjustmentCodes?.map((adj, i) => {
    const { group, code, amount } = adj || {};

    const content = (
      <>
        <p className="!mb-1 text-xs">{`${group} - ${codes[group] || ''}`}</p>

        <p className="text-xs">{`${code} - ${codes[code] || ''}`}</p>
      </>
    );

    return group && code && amount ? (
      <div className="flex items-center !gap-1 whitespace-nowrap" key={i}>
        <span>{`${mString(amount * 100)} · ${group}${code}`}</span>

        <Tippy content={content} className="tippy-dark">
          <div className="relative">
            <Icon icon="info" />
          </div>
        </Tippy>
      </div>
    ) : (
      mString(0)
    );
  });
};

export const sumAdjustment = (procedures) => {
  const total = procedures?.reduce((acc, procedure) => {
    const procedureTotal = procedure?.adjustment?.reduce(
      (sum, { amount }) => sum + Number(amount || 0) * 100,
      0
    );

    return acc + (procedureTotal || 0);
  }, 0);

  return total;
};

export const displayDiagnosis = (claim) => {
  let diagnosisArr = [];

  for (let i = 1; i <= 12; i++) {
    if (claim[`diag_${i}`]) diagnosisArr.push(claim[`diag_${i}`]);
  }

  return String(diagnosisArr);
};

export const calcAdjustment = (adjustments) => {
  let amount = 0;

  ia(adjustments) &&
    adjustments?.map?.((adjustment) => {
      if (adjustment) amount += Number(adjustment?.amount) * 100;
    });

  return amount;
};

export const calcTotalAdjustment = (claims, unmatchedClaims) => {
  let total = 0;

  ia(claims) &&
    claims.map(({ procedures }) => {
      total += calcAdjustment(concat(...map(procedures, 'adjustment')));
    });

  if (ia(unmatchedClaims)) {
    ia(unmatchedClaims) &&
      unmatchedClaims.map(
        ({ charge }) => (total += calcAdjustment(concat(...map(charge, 'adjustment'))))
      );
  }

  return mString(total);
};
