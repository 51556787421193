import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { showAlert } from '../../../shared/Alert/Alert';
import Button from '../../../shared/Buttons/Button';
import Modal from '../../../shared/Modal/Modal';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import HeaderTable from './components/HeaderTable';
import NewPayer from './components/NewPayer';
import PayerTableRow from './components/PayerTableRow';
import PayersTable from './components/PayersTable';
import UpdateAddress from './components/UpdateAddress';

export default function Payers() {
  const navigate = useNavigate();
  const [newPayerModalVisible, setNewPayerModalVisible] = useState(false);
  const [updateAddressModal, setUpdateAddressModal] = useState();

  const formPayerRef = useRef();
  const formAddressRef = useRef();

  const { data, isLoading } =
    useQuery({
      queryKey: ['getPayers'],
      queryFn: getPayers,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getPayers() {
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to read payers!",
          color: 'danger'
        });
      }
    };
    const response = await requestApi({
      url: '/api/practice/settings/payers/get',
      params: {
        withCount: true
      },
      navigate,
      onError
    });

    return response;
  }

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        let res = await interimApi('/api/payers/read', { searchTerm }, navigate);
        if (res.data) {
          return res.data.payers.map((payer) => {
            return { value: payer.id, label: payer.name };
          });
        }
      } catch (error) {
        console.error(error);
        showAlert({
          title: 'Error!',
          message: 'There was an error getting all payers.',
          color: 'danger'
        });
      }
    }
  };

  return (
    <div>
      <HeaderTable setNewPayerModalVisible={setNewPayerModalVisible} searchPayers={searchPayers} />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="!p-3">
          <PayersTable>
            {data?.payers?.length ? (
              data?.payers.map((payer, index) => (
                <PayerTableRow
                  setUpdateAddressModal={setUpdateAddressModal}
                  lastIndex={index === data?.payers.length - 1}
                  key={payer?.id}
                  index={index}
                  payer={payer}
                />
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="!py-4">No Payers found</div>
                </td>
              </tr>
            )}

            <Modal
              customStyling={{ width: '620px' }}
              bodyClassName="!p-4 !max-h-[unset] !h-full"
              handleOpen={newPayerModalVisible}
              handleClose={() => setNewPayerModalVisible(false)}
              title="Request new payer!"
              slideFromRight
              key={newPayerModalVisible}
              footer={
                <div className="flex w-full justify-between">
                  <Button
                    outlined
                    text="Cancel"
                    color="neutral"
                    onClick={() => setNewPayerModalVisible(false)}
                    id="cancelPayerModalBtn"
                    data-qa="cancel-payer-btn"
                  />
                  <Button
                    onClick={() => formPayerRef.current?.submitForm()}
                    text="Request payer"
                    id="savePayerModalBtn"
                    data-qa="create-payer-btn"
                  />
                </div>
              }>
              <NewPayer ref={formPayerRef} onClose={() => setNewPayerModalVisible(false)} />
            </Modal>

            <Modal
              customStyling={{ width: '620px' }}
              bodyClassName="!p-4 !max-h-[unset] !h-full"
              handleOpen={updateAddressModal != null}
              handleClose={() => setUpdateAddressModal()}
              title="Request new payer!"
              slideFromRight
              footer={
                <div className="flex w-full justify-between">
                  <Button
                    outlined
                    text="Cancel"
                    color="neutral"
                    onClick={() => setUpdateAddressModal()}
                    id="cancelAddressModalBtn"
                    data-qa="cancel-address-btn"
                  />
                  <Button
                    onClick={() => formAddressRef.current?.submitForm()}
                    text="Update address"
                    id="saveAddressModalBtn"
                    data-qa="update-address-btn"
                  />
                </div>
              }>
              <UpdateAddress
                ref={formAddressRef}
                payer={updateAddressModal}
                onClose={() => setUpdateAddressModal()}
              />
            </Modal>
          </PayersTable>
        </div>
      )}
    </div>
  );
}
