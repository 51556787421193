import { useQuery } from '@tanstack/react-query';
import { getBalance, readBalance } from 'api/Transaction';
import { useNavigate } from 'react-router-dom';

export const usePatientBalance = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['patientBalance', ...dependencies], () => getBalance(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useReadPatientBalance = ({ params = {}, options = {}, dependencies = [], name }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => readBalance(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
