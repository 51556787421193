import { Popover } from '@headlessui/react';
import axios from 'axios';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ia } from '../../../../../../lib/helpers/utility';
import Button from '../../../../Buttons/Button';
import Icon from '../../../../Icon/Icon';
import Input from '../../../../Input/Input';
import Skeleton from '../../../../Skeleton/Skeleton';

const SelectMedicationTable = ({ handleSelectMedication = () => {} }) => {
  const [medications, setMedications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    limit: 6
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.searchTerm]);

  useEffect(() => {
    if (filters.search) {
      handleGetMedications();
    } else {
      setError(null);
      setMedications([]);
    }
  }, [filters.search, filters.limit]);

  const handleGetMedications = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://api.fda.gov/drug/ndc.json?search=generic_name:*${filters.search}*&limit=${filters.limit}`
      );

      setMedications(data.results);
      setIsLoading(false);
    } catch (error) {
      setMedications([]);
      setIsLoading(false);
      setError(error.response.data.error.message);
    }
  };

  const showTable = ia(medications) ? true : false;

  const selectMed = (selectedMedication) => {
    handleSelectMedication(selectedMedication);
    setMedications([]);
    setFilters({ ...filters, searchTerm: '', search: '' });
  };

  return (
    <div className=" flex flex-col gap-6">
      <label>Search medications from database</label>
      <div className="flex w-full items-center gap-2 ">
        {ia(medications) && (
          <Popover className="relative">
            {({ close, open }) => (
              <>
                <Popover.Button className="relative flex h-[40px] w-[82px] cursor-pointer items-center justify-center gap-[6px] rounded-md bg-white shadow-md">
                  <label htmlFor="" className="!m-0 cursor-pointer text-neutral-500 ">
                    {open ? 'Hide' : 'Show'}
                  </label>

                  <label htmlFor="" className="!m-0 cursor-pointer font-500 text-primary-900">
                    {filters.limit}
                  </label>
                  <Icon
                    icon="new-chevron-down-sm"
                    className={`cursor-pointer ${open && 'rotate-180'}`}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-10 mt-1 flex shadow-md">
                  <div className="rounded-md bg-white py-[14px] shadow-md">
                    {[6, 12, 24, 32, 48, 72].map((item) => (
                      <div
                        key={item}
                        className="flex h-[30px] w-[50px] cursor-pointer items-center justify-center text-primary-900 hover:bg-primary-50"
                        onClick={() => {
                          setFilters({ ...filters, limit: item });
                          setIsLoading(true);
                          close();
                        }}>
                        {item}
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        )}
        <Input
          data-qa="searchMedications"
          type="string"
          placeholder="Search medications..."
          value={filters.searchTerm}
          icon="new-search"
          className={` w-[50%] !rounded-2xl !border-secondary-400 `}
          onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          rightText={
            filters.searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setFilters({ ...filters, searchTerm: '', search: '' });
                }}
              />
            )
          }
        />
      </div>

      {isLoading ? (
        <Skeleton count={1} height={200} />
      ) : showTable ? (
        <div className="flex max-h-[207px]  flex-col overflow-y-auto">
          <table className="primary-table">
            <thead>
              <tr>
                <th className="w-[130px]"></th>
                <th className="w-[50%]">Drug Name</th>
                <th>Dosage Form</th>
                <th>Route</th>
              </tr>
            </thead>
            <tbody>
              {medications?.map((medication, index) => {
                return (
                  <tr
                    index={index}
                    key={medication?.product_id}
                    className="w-full cursor-pointer"
                    onClick={() => selectMed(medication)}>
                    <td className="w-[100px] !font-500">
                      <Button
                        data-qa="select-btn"
                        text="Select"
                        type="secondary"
                        onClick={() => selectMed(medication)}
                      />
                    </td>
                    <td>{capitalize(medication.generic_name || medication.brand_name)}</td>
                    <td>{capitalize(medication.dosage_form)}</td>
                    <td>
                      {medication.route?.map((r, index) => {
                        r = capitalize(r);
                        return index === medication.route.length - 1 ? r : r + '/';
                      })}
                    </td>
                  </tr>
                );
              })}
              {error && (
                <tr>
                  <td colSpan={6} className="text-center">
                    {error}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : null}
      <p>or enter medication below</p>
    </div>
  );
};

export default SelectMedicationTable;
