import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Calendar from './Calendar/Calendar';

const UpcomingAppointments = () => {
  const item = { name: 'Appointments & Events' };
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="overflow-hidden flex flex-col h-full">
      <WidgetHeader
        icon="new-add-event"
        item={item}
        text="New"
        showModal={showModal}
        setShowModal={setShowModal}
        onPlusClick={() => setShowModal(true)}
      />
      <Calendar />
    </div>
  );
};

export default UpcomingAppointments;
