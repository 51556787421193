import React from 'react';
import DatePicker from 'react-datepicker';
import './Profile.scss';
import Select from '../../shared/Select/Select';
import Input from '../../shared/Input/Input';
import Icon from '../../shared/Icon/Icon';
import { genders, marital_status, races } from '../../../constants';
import ProfilePicture from '../../Profile/ProfilePicture';
import ReactSignatureCanvas from 'react-signature-canvas';
import Button from '../../shared/Buttons/Button';
import { Capitalize, findStateName, isEmpty, snakeToTitleCase } from '../../../lib/helpers/utility';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const Profile = ({
  formRef,
  openFilestackPicker,
  formik,
  handleSubmit,
  states,
  sigPad,
  clearSignature
}) => {
  return (
    <form ref={formRef} className="bg-white !p-6" data-dd-privacy="allow">
      <div>
        <h3 className="!mb-6 text-xl text-black">Profile picture</h3>
        <ProfilePicture
          firstName={formik.values.f_name}
          lastName={formik.values.l_name}
          image={formik.values.profile_photo}
          id={formik.values.id}
          size={160}
          onClick={() => openFilestackPicker()}
          className="cursor-pointer transition-all hover:!opacity-50"
        />
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 text-xl text-black">Account information</h3>
        <div className="flex flex-wrap gap-3">
          <Input
            className="grow"
            label="First name"
            placeholder="First name"
            name="f_name"
            value={formik.values.f_name}
            onChange={formik.handleChange}
            id="firstName"
            data-qa="first-name"
            error={formik.errors.f_name}
          />
          <Input
            className="grow"
            label="Middle name"
            placeholder="Middle name"
            name="m_name"
            value={formik.values.m_name}
            onChange={formik.handleChange}
            id="middleName"
            data-qa="middle-name"
            error={formik.errors.m_name}
          />
          <Input
            className="grow"
            label="Last name"
            placeholder="Last name"
            name="l_name"
            value={formik.values.l_name}
            onChange={formik.handleChange}
            id="lastName"
            data-qa="last-name"
            error={formik.errors.l_name}
          />
          <Input
            className="grow"
            label="Email"
            placeholder="email@example.com"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            id="email"
            data-qa="email"
            error={formik.errors.email}
          />
        </div>
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 text-xl text-black">Personal information</h3>
        <div className="flex flex-wrap gap-3">
          <div className="grow" data-qa="date-of-birth-field">
            <label className="m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="firstName">
              Date of birth
            </label>
            {formik.values.dob && (
              <div className="relative">
                <DatePicker
                  id="dob"
                  placeholderText="Select date"
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(formik.values.dob)}
                  onChange={(value) => {
                    if (!value) return;

                    formik.setFieldValue('dob', value);
                  }}
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  showYearDropdown
                  data-qa="date-picker-input"
                />
                <Icon icon="calendar" className="op absolute right-4 top-[10px]" />
              </div>
            )}
            {formik.errors.dob && <span className="text-xs text-red-600">{formik.errors.dob}</span>}
          </div>
          <Input
            className="grow"
            label="Phone number"
            placeholder="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            id="phoneNumber"
            data-qa="phone-number"
            error={formik.errors.phone}
          />
          <div data-qa="select-gender">
            <label className="m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="gender">
              Gender
            </label>
            <Select
              placeholder="Type and select gender"
              backgroundColor="bg-white"
              width="256px"
              value={{ label: snakeToTitleCase(formik.values.gender) }}
              onChange={({ value }) => formik.setFieldValue('gender', value)}
              options={genders}
              inputId="gender"
            />
            {formik.errors.gender && (
              <span className="text-xs text-red-600">{formik.errors.gender}</span>
            )}
          </div>
          <div data-qa="select-race">
            <label className="m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="race">
              Race
            </label>
            <Select
              placeholder="Type and select race"
              backgroundColor="bg-white"
              width="256px"
              value={{ label: Capitalize(formik.values.race) }}
              onChange={({ value }) => formik.setFieldValue('race', value)}
              options={races}
              inputId="race"
            />
            {formik.errors.race && (
              <span className="text-xs text-red-600">{formik.errors.race}</span>
            )}
          </div>
          <div data-qa="select-marital-status">
            <label
              className="m-0 pb-[6px] text-sm font-500 text-neutral-800"
              htmlFor="maritalStatus">
              Marital Status
            </label>
            <Select
              placeholder="Type and select status"
              backgroundColor="bg-white"
              width="256px"
              value={{ label: Capitalize(formik.values.marital_status) }}
              onChange={({ value }) => formik.setFieldValue('marital_status', value)}
              options={marital_status}
              inputId="maritalStatus"
            />
            {formik.errors.marital_status && (
              <span className="text-xs text-red-600">{formik.errors.marital_status}</span>
            )}
          </div>
        </div>
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 text-xl text-black">Physical Address</h3>
        <div className="flex flex-wrap gap-3">
          <Input
            className="grow"
            label="Address"
            placeholder="Address 1"
            name="address_ln_1"
            value={formik.values.address_ln_1}
            onChange={formik.handleChange}
            id="address_ln_1"
            data-qa="address-ln-1"
            error={formik.errors.address_ln_1}
          />
          <Input
            className="grow"
            label="Address 2"
            placeholder="Address 2"
            name="address_ln_2"
            value={formik.values.address_ln_2}
            onChange={formik.handleChange}
            id="address_ln_2"
            data-qa="address-ln-2"
            error={formik.errors.address_ln_2}
          />
          <Input
            className="grow"
            label="City"
            placeholder="City"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            id="city"
            data-qa="city"
            error={formik.errors.city}
          />
          <div data-qa="select-state">
            <label className="m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="state">
              State
            </label>
            <Select
              placeholder="Type and select state"
              value={{ label: findStateName({ states, stateId: formik.values.state }) }}
              backgroundColor="bg-white"
              width="256px"
              onChange={({ value }) => formik.setFieldValue('state', value)}
              options={states}
              inputId="state"
            />
            {formik.errors.state && (
              <span className="text-xs text-red-600">{formik.errors.state}</span>
            )}
          </div>

          <Input
            className="grow"
            label="Zip code"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            id="zip"
            data-qa="zip-code"
            error={formik.errors.zip}
          />
        </div>
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 text-xl text-black">Virtual Link</h3>
        <div className="flex flex-wrap gap-3">
          <Input
            className="grow"
            label="Virtual Link"
            placeholder="Virtual Link"
            name="virtual_link"
            value={formik.values.virtual_link}
            onChange={formik.handleChange}
            id="virtual_link"
            data-qa="address-virtual_link-1"
            error={formik.errors.virtual_link}
          />
        </div>
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 self-center text-xl text-black">Signature on file</h3>
        <div className="flex flex-wrap gap-3">
          {isEmpty(formik.values.signature) ? (
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: 'SignatureCanvas' }}
              ref={sigPad}
            />
          ) : (
            <img
              src={formik.values.signature}
              className="h-[200px] w-[500px] rounded-[10px] border-2 border-dashed border-neutral-100 bg-[#f8fdff] object-contain"
              data-qa="set-signature"
            />
          )}
          <Button outlined text="Clear" onClick={clearSignature} data-qa="clear-btn" />
        </div>
        <div className="">
          <Checkbox
            name="share_signature"
            className="mt-2 "
            isChecked={formik.values.share_signature}
            handleClick={({ target }) => formik.setFieldValue('share_signature', target.checked)}
            label="Share my signature with other users"
          />
        </div>
      </div>
      <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
        <h3 className="!my-4 self-center text-xl text-black">Save Information</h3>
        <Button
          size="large"
          text="Save"
          data-qa="save-btn"
          onClick={handleSubmit}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        />
      </div>
    </form>
  );
};

export default Profile;
