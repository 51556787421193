import React from 'react';
import ClinicalNoteStatus from 'components/practice/charts/ClinicalNote/AllClinicalNotes/components/ClinicalNoteStatus';
import { formatDate, ia } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import Actions from '../Actions';
import { getIcd10Codes } from 'api/Icd10';
import { getCptCodes } from 'api/CptCodes';

const shapeProvider = (value) => {
  return `${value?.f_name} ${value?.l_name}`;
};

const searchICD10Codes = async (searchTerm, navigate) => {
  const codes = await getIcd10Codes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.icd10.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

const searchCPTCodes = async (searchTerm, navigate) => {
  const codes = await getCptCodes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.cpt.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

export const defaultColumns = [
  {
    field: 'appointment.id',
    headerName: 'ID',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? capitalize(value) : '')
  },
  {
    field: 'appointment.starts_at',
    headerName: 'Appointment Date',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? formatDate(value) : '')
  },
  {
    field: 'practitioner',
    headerName: 'Provider',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? shapeProvider(value) : '')
  },
  {
    field: 'cpt_codes',
    headerName: 'Services(CPT)',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) =>
      ia(value) ? value?.map((v) => (v?.code?.value ? v?.code?.value : 'N/A')) : 'N/A'
  },
  {
    field: 'icd_10_codes',
    headerName: 'Diagnosis(ICD)',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value : 'N/A')
  },
  {
    field: '',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: (params) => (
      <div className="flex h-full items-center justify-center">
        <ClinicalNoteStatus
          data={params?.data}
          classNames="flex min-w-[80px] justify-center items-center"
        />
      </div>
    ),
    resizable: false,
    sortable: false
  },
  {
    field: '',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  appointment_date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Appointment Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  cpt: {
    type: 'search',
    multiple: true,
    loadOptions: searchCPTCodes,
    values: [],
    title: 'Services',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  icd: {
    type: 'search',
    multiple: true,
    loadOptions: searchICD10Codes,
    values: [],
    title: 'Diagnosis',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
