import Tippy from '@tippyjs/react';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Button from 'components/shared/Buttons/Button';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import Icon from 'components/shared/Icon/Icon';
import { formatPhoneNumber, ia } from 'lib/helpers/utility';
import React from 'react';
import Highlight from '../AdminSearch/HighlightData';

const SearchResults = ({
  data,
  handleOnClick,
  inputValue,
  setInputValue,
  setSelectedPractitioner,
  setShow,
  activeTab,
  show,
  setShowSearchModal,
  divRef,
  debouncedHandleScroll
}) => {
  const truncateText = (text, maxLength) => {
    if (typeof text !== 'string') {
      return '';
    }
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const maxLength = 60;

  const FormatOptionLabel = ({ option, index }) => {
    let value = option?.value; 
    let label = truncateText(option?.label, maxLength);
    let category = truncateText(option?.category, maxLength);
    let helperOne = truncateText(option?.phone, maxLength)
      ? formatPhoneNumber(option?.phone)
      : null;
    let helperTwo = truncateText(option?.email, maxLength);
    let isRecent = truncateText(option?.isRecent, maxLength);
    let url = `/admin/${category}/${value}`;
    if (option?.category === 'forms') {
      category = 'practices';
      value = truncateText(option?.practiceId, maxLength);
      helperOne = truncateText(option?.practiceName, maxLength);
      helperTwo = `Last updated: ${formatDate(option?.created_at)}`;
      url = `/admin/${category}/${value}/forms`;
    }

    const separated = label?.split(' ');
    const firstName = ia(separated) ? separated[0] : '';
    const lastName = separated[separated?.length - 1];

    return (
      <div
        className="flex cursor-pointer items-start items-center gap-[20px] rounded-xl bg-neutral-50 !p-5 !pb-2 !pt-2 hover:bg-primary-50"
        data-qa={`search-${index}`}
        onClick={() => handleOnClick({ url, id: value })}>
        <ProfilePicture size={36} id={option.value} lastName={lastName} firstName={firstName} />
        <div className="flex w-full items-center justify-between">
          <div className="grid items-center gap-[2px]">
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
              <Highlight text={label} highlight={inputValue} />
            </p>
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
              <Highlight text={helperOne} highlight={inputValue} />
            </p>
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
              <Highlight text={helperTwo} highlight={inputValue} />
            </p>
          </div>
          <div className="flex gap-2">
            {category === 'practitioners' && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPractitioner(option);
                  setShow({ ...show, confirmImpersonate: true });
                  setShowSearchModal(false);
                  setInputValue('');
                }}
                text="Impersonate"
                type="secondary"
                size="small"
                className="transition duration-300 hover:bg-primary-100"
              />
            )}
            {isRecent && (
              <Tippy
                className="tippy-dark"
                content={`This ${activeTab.slice(0, -1)} appears at the top because it is frequently selected by you.`}>
                <div className="flex">
                  <Icon icon="new-history" />
                </div>
              </Tippy>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={divRef}
      onScroll={debouncedHandleScroll}
      className="max-h-[calc(100vh-114px)] overflow-y-auto">
      {ia(data) ? (
        data.map((option, index) => <FormatOptionLabel key={index} option={option} index={index} />)
      ) : show?.loading ? (
        <div className="flex h-16 items-center justify-center bg-primary-50 p-2 text-xs font-500 text-primary-500">
          <div className="mr-2 h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary-100 border-t-primary-500"></div>
          <div className="ml-2">Loading...</div>
        </div>
      ) : (
        <div className="flex h-[25vh] flex-col items-center justify-center bg-primary-50 p-2 text-xs font-500 text-primary-500">
          <Icon icon="no-files-found" />
          <span className="mt-2 rounded px-2 py-1 text-sm text-primary-600">
            {inputValue ? `No ${activeTab} to show!` : `No ${activeTab} to show!`}
          </span>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
