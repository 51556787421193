import React, { useEffect, useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../Modal';
import Icon from '../../Icon/Icon';
import { requestApi } from '../../../../api/Api';
import Loading from '../../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { AlertContent } from '../../Alert/Alert';
import HipaaForm from '../../Forms/HipaaForm/HipaaForm';

const HipaaFormModal = ({ showHipaaModal, hideHipaaModal, hipaaForm }) => {
  return (
    <Modal
      handleOpen={showHipaaModal}
      handleClose={hideHipaaModal}
      title="HIPAA form"
      parentSelector={() => document.getElementById('AppointmentPreviewModal') || document}
      slideFromRight
      subModal
      prevButton={
        <Button close onClick={hideHipaaModal}>
          <Icon icon="back-large" />
        </Button>
      }
      footer={<Button onClick={hideHipaaModal}>Close</Button>}>
      {!hipaaForm && <Loading />}
      {hipaaForm !== 'noHIPAA' && <div dangerouslySetInnerHTML={{ __html: hipaaForm }}></div>}
      {hipaaForm === 'noHIPAA' && (
        <div>
          <AlertContent
            className="mb-[1rem] w-full"
            color="warning"
            title="Warning"
            message="HIPAA form was not signed by the patient yet."
          />
          <HipaaForm disabled={true} />
        </div>
      )}
      {/*<HipaaForm*/}
      {/*  practice={appointment.practice}*/}
      {/*  patient={appointment.patient}*/}
      {/*  callback={() => {*/}
      {/*    setCheckinSteps({ ...checkinSteps, hipaa: true, hipaaDate: new Date() });*/}
      {/*    hideHippaModal();*/}
      {/*  }}*/}
      {/*/>*/}
    </Modal>
  );
};

export default HipaaFormModal;
