import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import SessionQuotesAndContents from './SessionQuotesAndContents';

const SessionQuotesAndContentsSection = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.sessionQuotesAndContents?.title}
      id={components?.sessionQuotesAndContents?.id}
      disabled={clinicalNote?.locked}>
      <SessionQuotesAndContents sectionRef={sectionRef} />
    </Accordion>
  );
};

export default SessionQuotesAndContentsSection;
