import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { logFilters, messageFilters } from 'constants';
import Button from 'components/shared/Buttons/Button';
import Tabs from 'components/shared/Tabs/NewTabs';
import { currentPractice } from 'components/practice/practiceState';
import SortByFilter from './Filters/SortByFilter';
import TypeFilter from './Filters/TypeFilter';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
export default function CommunicationToolbar() {
  const { patient } = useOutletContext();
  const { logs } = useParams();
  const [loading, setLoading] = useState(false);
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  const tabsData = [
    {
      label: 'Message',
      path: '',
      end: true
    },
    {
      label: 'Logs',
      path: 'logs'
    }
  ];

  const sendWelcomeEmail = async () => {
    try {
      setLoading(true);

      const { success } = await requestApi({
        url: '/api/practice/charts/communications/welcome_email',
        params: {
          practiceId: practice.id,
          patientId: patient.id
        },
        navigate
      });

      setLoading(false);

      if (!success) {
        Honeybadger.notify(
          `method: sendWelcomeEmail, patientId: ${patient.id}, practiceId: ${practice.id}, error: success is not true.`
        );
        showAlert({ message: 'Something went wrong, please try again later.', color: 'danger' });
        return;
      }

      showAlert({ message: 'Welcome email sent', color: 'success' });
      return;
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `method: sendWelcomeEmail, patientId: ${patient.id}, practiceId: ${practice.id}, error: ${error}.`
      );
    }
  };

  return (
    <div className="flex w-full items-center justify-between gap-4 border-0 !border-b !border-solid !border-neutral-100 bg-white !pr-6">
      <Tabs tabsData={tabsData} theme="secondary" className="w-full items-center border-0" />
      <div className="flex items-center gap-2">
        <div className="flex w-max flex-wrap justify-end">
          {logs ? (
            <TypeFilter name="Filter" type={'logType'} filterOptions={logFilters} />
          ) : (
            <TypeFilter name="Filter" type={'messageType'} filterOptions={messageFilters} />
          )}
          <SortByFilter />
        </div>
        <Button
          data-qa="send-welcome-email-btn"
          outlined
          icon="new-mail"
          size="medium"
          text="Send welcome email"
          loadingIcon={loading}
          onClick={() => sendWelcomeEmail()}
        />
      </div>
    </div>
  );
}
