import React, { useRef } from 'react';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from '../../../../shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../../api/Api';

import { useRecoilValue } from 'recoil';
import { permissions as userPermissions } from '../../../../state';
import Header from 'components/shared/Header/Header';

const HeaderTable = ({ searchPayers, setNewPayerModalVisible }) => {
  const selectInputRef = useRef();

  const permissions = useRecoilValue(userPermissions);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {selectInputRef.current?.inputRef?.value ? (
          <div className="flex items-center justify-center gap-4 py-4">
            <span className="text-neutral-700">No payer found!</span>

            <Button
              type="primary"
              text="Request a new payer"
              id="newPayerBtn"
              data-qa="new-payer-btn"
              onClick={() => setNewPayerModalVisible(true)}
            />
          </div>
        ) : (
          <span className="text-neutral-500">Start typing the payer name.</span>
        )}
      </components.NoOptionsMessage>
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <div className="mx-1 rounded-full bg-primary-50">
          <Icon icon="new-plus" color="primary" />
        </div>
        {children}
      </components.Control>
    );
  };

  const addPayer = async (payerId) => {
    const onSuccess = () => {
      showAlert({ title: 'Success!', message: 'Payer added successfully!', color: 'success' });
      queryClient.invalidateQueries(['getPayers']);
    };

    const onError = () => {
      showAlert({
        title: 'Something went wrong!',
        message: 'Could not add payer!',
        color: 'danger'
      });
    };

    requestApi({
      url: '/api/practice/settings/payers/create',
      params: { payerId },
      onSuccess,
      onError,
      navigate
    });
  };

  return (
    <Header title="Payers">
      <div className="mr-3 flex w-96">
        <AsyncSelect
          loadOptions={searchPayers}
          placeholder="Add Payer..."
          onChange={(e) => addPayer(e.value)}
          ref={selectInputRef}
          value=""
          className="basic-single flex-1"
          // noOptionsMessage={() => 'Start typing the payer name.'}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#54D2FF' : '#dee2e6',
              padding: '3px 5px',
              borderRadius: '100px',
              boxShadow: state.isFocused ? '0px 0px 0px 2px #CBF0FD' : '0px 2px 4px 0px #1018280A'
            })
          }}
          components={{ Control, NoOptionsMessage }}
        />
      </div>
    </Header>
  );
};

export default HeaderTable;
