import React, { useEffect, useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import { mString } from 'lib/helpers/utility';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Address from 'components/shared/address/Address';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';

const PreAuth = () => {
  const [balance, setBalance] = useState(0);
  const [show, setShow] = useState({
    startNewTransaction: false,
    saveCard: false,
    consent: false,
    transactionStartText: 'Pre Authorize'
  });
  const [address, setAddress] = useState({ address: '', zip: '' });
  const navigate = useNavigate();

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    try {
      const res = await interimApi('/api/patient/transaction/balance', {}, navigate);
      const { code, redirect, error, patientBalance: loadedPatientBalance } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setBalance(loadedPatientBalance[0].amount);
          break;

        default:
          if (error) showAlert({ message: error, color: 'danger' });
          Honeybadger.notify(
            `file: checkin/payment/preAuth, method: getBalance - try, error: ${error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/payment/preAuth, method: getBalance - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  const preAuthorize = () => {
    setShow({ ...show, startNewTransaction: true });
  };

  const handleApproved = async () => {
    try {
      const res = await interimApi('/api/patient/checkin/payment/pre_auth', {}, navigate);
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          showAlert({ message: 'Thank you!\nYour pre-authorization has been accepted.' });
          navigate('/myportal');
          break;
        case 1:
          showAlert({ message: error, color: 'danger' });
          break;

        default:
          if (error) showAlert({ message: error, color: 'danger' });
          Honeybadger.notify(
            `file: checkin/payment/preAuth, method: handleApproved - try, error: ${error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: checkin/payment/preAuth, method: handleApproved - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  const updateAddress = (e) => {
    setAddress({
      address: `${e.address_ln_1} ${e.address_ln_2}`,
      address_ln_1: e.address_ln_1,
      address_ln_2: e.address_ln_2,
      zip: e.zip
    });
  };

  return (
    <Box className="m-auto flex h-max w-[500px] flex-col gap-1">
      <h3>Patient Portion</h3>
      <h3>{`Amount Due: ${mString(balance)}`}</h3>
      <div>
        <h4>Billing Address</h4>
        <Address detailedInfo={false} updateAddress={updateAddress} />
      </div>
      <div>
        <div className="grid gap-4 !pb-4">
          <Checkbox
            label="I want to save this card in the secure vault"
            value={show.saveCard}
            onChange={(v) => setShow({ ...show, saveCard: v.target.checked })}
          />
          <Checkbox
            label="I pre-authorize payment for this appointment"
            value={show.consent}
            onChange={(v) => setShow({ ...show, consent: v.target.checked })}
          />
        </div>
        <Button onClick={preAuthorize} text={show.transactionStartText} className="mx-auto" />
        {show.startNewTransaction && typeof balance === 'number' && (
          // {show.startNewTransaction && typeof balance === 'number' && balance !== 0 && (
          <LightboxWrapper
            amount={balance}
            saveCard={show.saveCard}
            onApproval={handleApproved}
            onCancel={() => setShow({ ...show, startNewTransaction: false })}
            onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
          />
        )}
      </div>
    </Box>
  );
};

export default PreAuth;
