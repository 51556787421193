import React from 'react';
import { ia, iaRa, isEmpty } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import AllergiesModal from '../Modals/AllergiesModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const AllergiesOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview.allergies) return null;

  return (
    <SimpleAccordion
      title="Allergies"
      open={isOpen.allergies}
      onToggle={() => toggleOne('allergies')}
      edit={
        <AllergiesModal
          formId={currentHpOverviewData?.allergies?.id}
          defaultValue={currentHpOverviewData?.allergies?.response}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
          setCustomResponses={setOverviewData}
        />
      }>
      {ia(overviewData?.allergies) &&
        overviewData?.allergies?.map((allergy, index) => {
          if (allergy?.response?.noDetails) {
            return (
              <NoKnown
                key={index}
                form={allergy}
                description="No known allergies"
                shouldShowDivider={index !== 0}
              />
            );
          }

          return iaRa(allergy?.response?.allergies).map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                <div className="grid gap-y-1">
                  {!isEmpty(item?.allergy?.value) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Allergy:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                        {item.allergy.value}
                      </span>
                    </div>
                  )}
                  {!isEmpty(item?.medication?.value) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Medication provided:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                        {item.medication.value}
                      </span>
                    </div>
                  )}
                  {!isEmpty(item?.typicalReaction?.value) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Typical reaction
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                        {item.typicalReaction.value}
                      </span>
                    </div>
                  )}
                  {!isEmpty(item.note) && (
                    <div className="flex justify-between">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Note:
                      </span>
                      <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                        {item.note}
                      </span>
                    </div>
                  )}
                </div>
                <CreationInfo
                  createdAt={allergy?.createdAt}
                  createdBy={allergy?.createdBy?.fullName}
                />
              </React.Fragment>
            );
          });
        })}
    </SimpleAccordion>
  );
};

export default AllergiesOverview;
