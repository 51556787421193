import React from 'react';
import QuickActions from './QuickActions';
import TogglePractice from './components/TogglePractice';

const Navbar = () => {
  return (
    <nav
      className="absolute z-30 flex w-full items-center justify-between border-0 !border-l-[1px] !border-solid !border-neutral-50 bg-white !py-1 !pl-2 !pr-6 shadow-small"
      data-dd-privacy="allow">
      <div className="flex items-center">
        <TogglePractice />
      </div>
      <div className="ml-auto flex items-center gap-6">
        <QuickActions />
      </div>
    </nav>
  );
};

export default Navbar;
