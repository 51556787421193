import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';

const MyScribeTemplate = () => {
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb } = breadcrumb;

  return (
    <div>
      <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      <div className="flex h-screen">MyScribeTemplate</div>
    </div>
  );
};

export default MyScribeTemplate;
