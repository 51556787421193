import React, { useRef, useState } from 'react';
import { otherProblemsList } from '../../../../../constants';
import Textarea from '../../../Textarea/Textarea';
import Select from '../../../Select/Select';
import Icon from '../../../Icon/Icon';

const OtherProblemsRow = ({ note, problems, id, onChange, onDelete, length }) => {
  const [disabled, setDisabled] = useState(false);

  const noteRef = useRef(null);

  return (
    <div className="flex items-center gap-x-[10px]">
      <div className="grid grid-cols-2 gap-4 w-full">
        <Textarea
          label="Name"
          placeholder="Add note here"
          id="note"
          name="note"
          value={note}
          onChange={(event) => onChange('note', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
        <Select
          label="Severity"
          isCreatable
          options={otherProblemsList}
          value={problems}
          disabled={disabled}
          onChange={(event) => onChange('problems', event, id)}
        />
      </div>
      {length > 1 && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default OtherProblemsRow;
