import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import cs from 'classnames';
import FadeInText from './AnimatedText';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

const initialPromptTemplates = [
  { id: 1, text: 'Add Vitals', onClick: () => {}, icon: 'new-calendar-note', selected: false },
  {
    id: 2,
    text: 'Add CPT® Codes',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  },
  {
    id: 3,
    text: 'Add Diagnosis Codes',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  },
  { id: 4, text: 'Add Macros', onClick: () => {}, icon: 'new-calendar-note', selected: false },
  {
    id: 5,
    text: 'Add Treatment Plan',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  },
  {
    id: 6,
    text: 'Add Past Notes',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  },
  {
    id: 7,
    text: 'Add Patient Forms',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  },
  {
    id: 8,
    text: 'Add Patient History',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false
  }
];

const texts = ['Preserve Your Time', 'Protect Your Data', 'Perpetuate Your Practice'];

const popoverOptions = [{ label: '3D Chart', onClick: () => {}, icon: 'new-calendar-note' }];

const Chat = () => {
  const { typeOfNotes } = useMyScribeAIContext();
  const [selectedNoteType, setSelectedNoteType] = useState(typeOfNotes['soap']);

  const [disableGenerate, setDisableGenerate] = useState(true);
  const [promptTemplates, setPromptTemplates] = useState(initialPromptTemplates);

  const onTextChange = (e) => {
    setDisableGenerate(!e.target.value?.length);
  };

  const onGenerate = (text) => {
    console.log('🚀 ~ onGenerate ~ text:', text);
  };

  const onTemplateClick = (id) => {
    console.log('🚀 ~ onTemplateClick ~ id:', id);
  };

  const onNoteTypeClick = (noteType) => {
    setSelectedNoteType(noteType);
  };

  return (
    <div className="relative flex h-full flex-col pb-24">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 z-10" />
      <div className="flex justify-center">
        {Object.values(typeOfNotes)?.map((noteType) => (
          <Button
            key={noteType?.id}
            onClick={() => onNoteTypeClick(noteType)}
            // icon={noteType.icon}
            className={cs(
              'z-30 mx-2 my-2 h-[46px] w-[100px] !rounded-xl bg-white !px-[15px] !py-3 text-primary-500',
              noteType?.id === selectedNoteType?.id ? '!bg-primary-50 outline-2' : ''
            )}
            text={noteType.label}
            iconColor={noteType?.id === selectedNoteType?.id ? 'primary' : 'white'}
            outlined={noteType?.id === selectedNoteType?.id}
            transparent
            color={!noteType?.id === selectedNoteType?.id ? 'neutral' : 'primary'}
            textClassName={
              !noteType?.id === selectedNoteType?.id ? 'text-neutral-400' : 'text-primary-500'
            }
          />
        ))}
      </div>

      <div className="mb-[150px] flex flex-grow flex-col items-center justify-center overflow-y-auto">
        <Icon icon="ai-brain" className="mb-5" />
        <div className="mb-1 w-2/3 text-center text-xl text-neutral-400">
          <div className="mb-4">
            Use <span className="text-primary-500">MyScribe AI</span> to
          </div>
          <div>
            <FadeInText texts={texts} />
          </div>
        </div>
        <h4 className="mb-4 w-1/2 text-center text-base font-thin text-neutral-700">
          MyScribe AI is a HIPAA-Compliant, self-contained system. It utilizes data, at your
          direction, internally and keeps it securely within its environment. Allowing you to
          preserve your time and peace of mind.
        </h4>
      </div>

      <div className="absolute bottom-20 z-50 flex w-2/3 flex-col self-center bg-transparent">
        <div className="mb-2 flex flex-wrap justify-center">
          {Object.values(selectedNoteType?.components || {})?.map((component) => {
            return (
              <Button
                key={component.id}
                onClick={() => onTemplateClick(component.id)}
                icon={component.icon}
                className="z-30 m-1 !bg-white !py-1 text-neutral-600"
                text={component.title}
                textClassName="text-neutral-700 font-thin"
                color="neutral"
                outlined
                size="small"
              />
            );
          })}
          <Popover
            options={popoverOptions}
            isFixed
            buttonClassName="text-neutral-600 !outline !outline-1 !outline-neutral-200 border-neutral-200 !min-h-[28px] !min-w-[28px]"
            className="self-center"
            optionIconColor="neutral"
          />
        </div>
        <AIChatInput
          className="mb-3 border-2 !border-neutral-200 shadow-none outline outline-1 outline-neutral-200"
          leftButtons={[]}
          rightButtons={[
            <Button
              key={1}
              onClick={() => {}}
              icon="new-microphone"
              iconSize={18}
              className="z-30 mx-2 !bg-white !px-[15px]"
              color="neutral"
            />,
            <Button
              key={2}
              icon="sent"
              iconSize={18}
              iconColor="white"
              size="small"
              className={cs('!bg-neutral-100 outline-0', disableGenerate ? 'bg-neutral-100' : '')}
              onClick={onGenerate}
              disabled={disableGenerate}
              type="secondary"
              color="neutral"
            />
          ]}
          onTextChange={onTextChange}
          onSubmit={onGenerate}
          placeholder="Type anything.."
        />
        <p className="text-center text-sm text-neutral-700">
          AI can make mistakes. Please review & understand providers are responsible for final
          clinical note.
        </p>
      </div>
    </div>
  );
};

export default Chat;
