import React from 'react';

import Pharmacies from '../Pharmacy/Pharmacies';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Buttons/Button';
import PersonalInfo from './components/PersonalInfo';
import PhysicalAddress from './components/PhysicalAddress';
import EmergencyContact from './components/EmergencyContact';
import InsuranceProfiles from '../InsuranceProfile/InsuranceProfiles';
import ReferringProviders from './ReferringProvider/ReferringProviders';
import PermissionsAllowed from '../../../shared/Permissions/Allowed';

export default function EditDemographics({
  handleSubmit,
  onChange,
  stateList,
  getSSN,
  setDecodedSSN,
  decodedSSN,
  practice_state,
  formRef,
  formik,
  insuranceProfiles,
  pharmacies,
  currPractice,
  practicePatientData,
  occupationList,
  editable,
  loading
}) {
  const patientId = formik?.values?.id;
  const practiceId = currPractice?.id;

  return (
    <div className="!px-4">
      <form ref={formRef} onSubmit={handleSubmit} className="relative grid !gap-y-5">
        <PersonalInfo
          formik={formik}
          onChange={onChange}
          occupationList={occupationList}
          getSSN={getSSN}
          decodedSSN={decodedSSN}
          setDecodedSSN={setDecodedSSN}
          practice_state={practice_state}
          disabled={!editable}
        />

        <PhysicalAddress
          formik={formik}
          onChange={onChange}
          stateList={stateList}
          disabled={!editable}
        />

        <InsuranceProfiles
          patientId={patientId}
          practiceId={practiceId}
          insuranceProfiles={insuranceProfiles}
        />

        <Pharmacies pharmacies={pharmacies} patientId={patientId} practiceId={practiceId} />

        <EmergencyContact formik={formik} onChange={onChange} disabled={!editable} />

        <div>
          <label className="my-2 text-lg font-600">Custom Chart Id</label>

          <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
            <Input
              data-qa="custom-id"
              name="custom_id"
              label="Custom Id"
              onChange={onChange}
              placeholder="Custom Chart Id"
              value={formik?.values?.custom_id}
              error={formik?.errors?.custom_id}
              disabled={!editable}
            />
          </div>
        </div>

        {practicePatientData && (
          <ReferringProviders patientId={patientId} practicePatient={practicePatientData} />
        )}

        <PermissionsAllowed requiredPermissions={'patients.update'}>
          <div className="fixed bottom-10 right-[70px] md:right-[48px]">
            <Button
              text="Update"
              buttonType="submit"
              data-qa="update-btn"
              loading={loading}
              disabled={loading}
            />
          </div>
        </PermissionsAllowed>
      </form>
    </div>
  );
}
