import React from 'react';
import cs from 'classnames';
import useRandomGradient from '../../lib/hooks/useRandomGradient';
import { initials, io } from '../../lib/helpers/utility';

const ProfilePicture = ({
  firstName,
  lastName,
  id,
  image,
  fontSize = 'text-xl',
  size = 40,
  className,
  onClick,
  background = 'linear-gradient(98deg, #00ABE8 0%, #33C9FF 100%)',
  autoBackground = true,
  ...rest
}) => {
  if (image && io(JSON.parse(image))) {
    return (
      <div
        className={cs(
          'rounded-smooth flex min-w-[40px] items-center justify-center overflow-hidden',
          className
        )}
        style={{ width: size, height: size, minWidth: size, minHeight: size }}
        onClick={onClick}
        data-private
        {...rest}>
        <img
          className="w-full object-contain"
          src={`https://cdn.filestackcontent.com/${JSON.parse(image).jpg}`}
        />
      </div>
    );
  } else {
    return (
      <div
        className={cs(
          'rounded-smooth flex items-center  justify-center overflow-hidden ',
          className
        )}
        style={{
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          background: autoBackground ? useRandomGradient(id) : background
        }}
        onClick={onClick}
        {...rest}>
        <span className={cs('font-600 uppercase text-white', fontSize)}>
          {initials({ fName: firstName, lName: lastName })}
        </span>
      </div>
    );
  }
};

export default ProfilePicture;
