import React from 'react';
import cs from 'classnames';
import { Popover } from '@headlessui/react';

import Icon from '../Icon/Icon';

const InputOptions = ({ options = [], currentOption = null, onClickOption, position }) => {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={cs(
              'flex h-[30px] cursor-pointer items-center justify-center rounded-[4px] bg-neutral-100 px-[6px] text-sm text-neutral-600 hover:bg-neutral-200',
              open && '!bg-primary-700 hover:!bg-primary-700'
            )}>
            <span className={cs('whitespace-nowrap', open && 'text-white')}>{currentOption}</span>

            <Icon
              icon="new-chevron-down"
              className={cs('cursor-pointer', open && 'rotate-180')}
              color={open && 'white'}
            />
          </Popover.Button>

          <Popover.Panel
            className={cs(
              'absolute right-0  z-10 mt-[6px] w-max rounded-[4px] bg-white !py-2 shadow-medium',
              position == 'top' && 'bottom-9'
            )}>
            <div className="grid gap-y-1">
              {options.map((option, idx) => (
                <div
                  className="flex cursor-pointer items-center gap-x-2 rounded-[4px] py-[6px] !px-4 hover:bg-primary-50"
                  key={idx}
                  id={idx}
                  data-qa={`unit-${idx}`}
                  onClick={() => {
                    close();
                    onClickOption(option?.value);
                  }}>
                  <div
                    className={cs(
                      'flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary-600',
                      currentOption === option?.label || currentOption === option?.value
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}>
                    <Icon icon="new-check" className="scale-75" />
                  </div>

                  <span className="text-sm text-primary-900">{option.label}</span>
                </div>
              ))}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default InputOptions;
