import Button from 'components/shared/Buttons/Button';
import { ia, iaRa, spaceToKebabCase } from 'lib/helpers/utility';
import { useClinicalNoteRead } from 'lib/hooks/queries/clinicalNotes/useClinicalNote';
import React, { useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Modal from '../../../../../shared/Modal/Modal';
import { currentPractice } from '../../../../practiceState';
import { getOverview } from '../../lib/getOverview';
import { processCustomFormTypes } from '../../lib/processCustomNoteTypes';
import SignLockActionComponent from '../../shared/SignLockActionComponent';
import DisplayContent from './DisplayClinicalNoteContent';

const OutsideClinicalNoteExport = ({
  showExportModal,
  setShowExportModal,
  selectedClinicalNote,
  setSelectedClinicalNote = () => null,
  children = null,
  isPatient = false
}) => {
  const {
    setClinicalNote,
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    setCurrentHpOverviewData,
    clinicalNote,
    advancedHP,
    advancedSOAP,
    customFormTypes
  } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);
  const { patient } = useOutletContext() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const printButtonRef = useRef();
  const sendEmailToPatientButtonRef = useRef();

  const { isFetching } = useClinicalNoteRead({
    dependencies: [selectedClinicalNote?.appointment?.id],
    params: {
      patientId: patient?.id || selectedClinicalNote?.appointment?.user_id,
      appointmentId: selectedClinicalNote?.appointment?.id,
      loadedPracticeId: practice?.id,
      exporting: true
    },
    options: {
      enabled: !!selectedClinicalNote?.appointment?.id,
      refetchOnMount: true,
      onSettled: async (data) => {
        setLoading(true);
        let { clinicalNote, advancedHP, advancedSOAP, customFormTypes, serviceCPT } = data || {};

        if (!clinicalNote?.id) {
          setClinicalNote(null);
          setAdvancedHP(null);
          setAdvancedSOAP(null);
          setCustomFormTypes(null);
        }

        setClinicalNote(
          structuredClone({
            ...clinicalNote,
            cpt_codes: iaRa(serviceCPT)
          })
        );

        if (advancedHP) {
          setAdvancedHP(
            advancedHP.map((item) => ({
              ...item,
              custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
                item?.form_id || item?.id
              }`
            }))
          );
        }

        if (advancedSOAP) {
          setAdvancedSOAP(
            advancedSOAP.map((item) => ({
              ...item,
              custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
                item?.form_id || item?.id
              }`
            }))
          );
        }

        if (customFormTypes) {
          const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });

          setCustomFormTypes(processedCustomFormTypes);
        }

        const currentHpOverviewData = await getOverview({
          patientId: clinicalNote?.user_id,
          appointmentId: clinicalNote?.appointment_id,
          navigate,
          isKebab: false
        });

        setCurrentHpOverviewData(currentHpOverviewData);

        setLoading(false);
      }
    }
  });

  const closeModal = () => {
    setClinicalNote(null);
    setAdvancedHP(null);
    setAdvancedSOAP(null);
    setCustomFormTypes(null);
    setShowExportModal({ open: false });
    setSelectedClinicalNote(null);
  };

  if (showExportModal?.props?.mode === 'signAndLock') {
    const documentSignatures = ia(clinicalNote?.document_sign)
      ? clinicalNote?.document_sign
      : selectedClinicalNote?.document_sign;

    return (
      <SignLockActionComponent
        fromOutsideClinicalNote={true}
        confirmSignLock={!!showExportModal?.props}
        showConfirmSignLock={() => setShowExportModal({ open: false })}
        {...showExportModal?.props}
        documentSignatures={documentSignatures}
        isFetching={isFetching || loading}
      />
    );
  }

  const reviewClinicalNote = () => {
    window.open(
      `/portal/charts/${clinicalNote?.user_id}/clinical-notes/${clinicalNote?.appointment_id}`,
      '_blank'
    );
    closeModal();
  };

  return (
    <Modal
      handleOpen={showExportModal?.open}
      handleClose={closeModal}
      title={showExportModal?.props?.title ? showExportModal.props.title : 'Clinical Note'}
      headButton={
        <div className="mr-3 flex w-full justify-end gap-2">
          {showExportModal?.props?.mode === 'review' && (
            <Button
              icon="new-notev2"
              type="secondary"
              size="small"
              onClick={reviewClinicalNote}
              text="Edit Note"
            />
          )}

          {showExportModal?.showOnlyExport && (
            <div className="mr-3 flex items-center gap-x-1 self-center">
              <Button
                type="secondary"
                color="neutral"
                icon="new-printer"
                text="Print"
                size="small"
                onClick={() => printButtonRef?.current?.handlePrint()}
                data-qa="print-btn"
              />

              {!isPatient && (
                <Button
                  data-qa="send-email-btn"
                  type="secondary"
                  color="neutral"
                  icon="new-mail"
                  text="Email"
                  size="small"
                  onClick={() =>
                    sendEmailToPatientButtonRef?.current?.setShowConfirmationModal?.(true)
                  }
                />
              )}
            </div>
          )}
        </div>
      }
      className="w-[1020px]"
      bodyClassName="p-0"
      slideFromRight>
      <DisplayContent
        clinicalNote={clinicalNote}
        showExportModal={showExportModal}
        printButtonRef={printButtonRef}
        sendEmailToPatientButtonRef={sendEmailToPatientButtonRef}
        setShowExportModal={setShowExportModal}
        selectedClinicalNote={selectedClinicalNote}
        isFetching={isFetching || loading}
        advancedHP={advancedHP}
        advancedSOAP={advancedSOAP}
        customFormTypes={customFormTypes}>
        {children}
      </DisplayContent>
    </Modal>
  );
};

export default OutsideClinicalNoteExport;
