import React from 'react';
import cs from 'classnames';

const Breadcrumbs = ({ breadcrumbs, onClickBreadcrumb }) => {
  return (
    <nav className="flex items-center p-1">
      {breadcrumbs.map((breadcrumb, index) => (
        <div key={breadcrumb.path} className="flex cursor-pointer">
          {breadcrumb?.icon}
          <div
            onClick={() => onClickBreadcrumb(index)}
            className={cs(
              index === breadcrumbs.length - 1 ? 'text-primary-700' : 'text-primary-900',
              'cursor-pointer font-medium hover:text-primary-700'
            )}>
            {breadcrumb.label}
          </div>
          {index < breadcrumbs.length - 1 && <span className="px-1 text-neutral-400">{'>'}</span>}
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
