import { io } from 'lib/helpers/utility';

export const calcInsPaid = ({ eraId, insurancePayment }) => {
  let amount = 0;

  if (io(insurancePayment?.eob)) {
    amount += insurancePayment?.eob?.reduce((acc, item) => acc + Number(item?.amount_paid || 0), 0);
  }

  if (io(insurancePayment?.era)) {
    amount += insurancePayment?.era?.find((claim) => claim?.era_id == eraId)?.amount_paid;
  }

  return amount;
};
