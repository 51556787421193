import React from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const TableCheckbox = (params) => {
  const { node, rowIndex } = params;

  const handleChange = (e) => {
    const checked = e.target.checked;
    node.setSelected(checked);
  };
  return (
    <div className="flex h-full items-center">
      <Checkbox isChecked={node.isSelected()} onChange={handleChange} id={rowIndex} />
    </div>
  );
};

export default TableCheckbox;
