import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Vaccines from '../../../../../../../shared/Forms/Custom/Vaccines/Vaccines';

const VaccinesSection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData
}) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      updateFormType={{ name: 'Vaccines', parentKey: 'hp', childKey: 'vaccines' }}
      title={components?.vaccines?.title}
      id={components?.vaccines?.id}
      disabled={clinicalNote?.locked}>
      <Vaccines
        formId={formId}
        defaultValue={defaultValue}
        fromClinicalNote={true}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
        showNarrative
        setCurrentFormData={setCurrentFormData}
      />
    </Accordion>
  );
};

export default VaccinesSection;
