import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatDateAndTimeZ, ia } from '../../../../../../../../lib/helpers/utility';
import { useClinicalNote } from '../../../../../../../../lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { currentPractice } from '../../../../../../practiceState';

import cs from 'classnames';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';

const IncludeClinicalNotes = ({ formik }) => {
  const { id, appointmentId } = useParams();
  const [selectedNotes, setSelectedNotes] = useState(
    ia(formik?.values?.clinical_notes) ? formik?.values?.clinical_notes : []
  );
  const practice = useRecoilValue(currentPractice);

  useEffect(() => {
    formik?.setFieldValue('clinical_notes', selectedNotes);
  }, [selectedNotes]);

  const [filters, setFilters] = useState({
    appointmentIds: ia(selectedNotes) ? selectedNotes : appointmentId ? [appointmentId] : null
  });

  const { data: fetchedClinicalNotes, isFetching } = useClinicalNote({
    params: { ...filters, patientId: id },
    dependencies: [id, filters],
    options: {
      select: ({ clinicalNote }) => {
        return clinicalNote.filter((note) => note?.state);
      }
    }
  });

  const modifySelectedNotes = ({ shouldInsert = true, item = null }) => {
    if (!item) return;

    setSelectedNotes((prevState) => {
      if (shouldInsert) {
        return [...new Set([...prevState, item])];
      }

      return prevState?.filter((row) => row !== item);
    });
  };

  const selectAllNotes = ({ shouldInsert = false }) =>
    setSelectedNotes(shouldInsert ? fetchedClinicalNotes.map((item) => item?.appointment_id) : []);

  return (
    <div
      className={cs(
        'flex flex-col rounded-xl bg-white px-4 py-3 shadow-sm',
        formik?.errors?.clinical_notes && 'border border-solid !border-danger-500'
      )}>
      <div className="flex items-center gap-1.5">
        <p className="text-lg font-500 text-primary-900">Include Clinical Notes</p>
        {!!formik?.errors?.clinical_notes && (
          <span className="text-sm font-normal text-danger-500">
            ({formik?.errors?.clinical_notes})
          </span>
        )}
      </div>

      {isFetching ? (
        <Skeleton count={5} />
      ) : (
        <table className="primary-table responsive-table selectable-children !mt-4 rounded-t-lg shadow-none">
          <thead>
            <tr className="h-full">
              <th className="flex !gap-3 !pl-4">
                <Checkbox
                  onChange={(event) => selectAllNotes({ shouldInsert: event.target.checked })}
                  isChecked={fetchedClinicalNotes?.every((row) =>
                    (selectedNotes || [])?.includes(row?.appointment_id)
                  )}
                />
                <p>Appointment date</p>
              </th>
              <th>Provider</th>
            </tr>
          </thead>
          <tbody className="max-h-[calc(100vh-244px)]">
            {ia(fetchedClinicalNotes) ? (
              fetchedClinicalNotes?.map((note, idx) => {
                const isChecked = (selectedNotes || [])?.includes(note?.appointment_id);
                const providerFullName =
                  note?.practitioner?.fullName ??
                  `${note?.practitioner?.f_name} ${note?.practitioner?.l_name}`;

                return (
                  <tr
                    key={idx}
                    className={cs('cursor-pointer hover:!bg-primary-50', isChecked && 'selected')}>
                    <td className="flex h-full items-center !gap-3">
                      <Checkbox
                        isChecked={isChecked}
                        data-qa={`order-referral-note-row-${idx}`}
                        onChange={(event) =>
                          modifySelectedNotes({
                            shouldInsert: event.target.checked,
                            item: note.appointment_id
                          })
                        }
                      />
                      <span className="flex !gap-1.5 font-500 first-letter:capitalize">
                        {formatDateAndTimeZ(note?.appointment?.starts_at, practice.timezone)}{' '}
                        {note?.appointment_id == appointmentId && (
                          <p className="text-neutral-500">(Current)</p>
                        )}
                      </span>
                    </td>
                    <td>
                      <p className="font-500 text-primary-900 ">{providerFullName}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <span className="block !px-3 !py-6 text-center text-sm text-primary-900">
                No clinical note found or started. A referral order must be linked to at least one
                clinical note.
              </span>
            )}
          </tbody>
        </table>
      )}

      {!!filters.appointmentIds && (
        <p
          onClick={() =>
            setFilters({
              pagination: false
            })
          }
          className="w-fit cursor-pointer !px-4 !py-3 text-sm font-500 text-primary-500">
          show other clinical notes
        </p>
      )}
    </div>
  );
};

export default IncludeClinicalNotes;
