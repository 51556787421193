import React, { useState } from 'react';
import { handleExportModal, handleSignAndLock, handleUnlock } from '../lib/helper';
import Button from 'components/shared/Buttons/Button';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import SignAndLockProcess from './SignAndLockProcess';

const HeaderActions = ({
  setDocumentIds,
  setExportModal,
  setButtonLoading,
  setDownloadPDF,
  buttonLoading
}) => {
  const [allowToSign, setAllowToSign] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [signMessage, setSignMessage] = useState('');
  const [areFinalized, setAreFinalized] = useState(false);
  const user = useRecoilValue(userState);

  const { selectedRows, setSelectedRows } = useTableContext();

  return (
    <>
      {selectedRows?.every((doc) => doc?.data?.id) && (
        <>
          {selectedRows?.every((doc) => doc?.data?.state === 'finalized') ? (
            <Button
              text="Unlock"
              icon="new-unlockv2"
              iconColor="white"
              size="small"
              onClick={() =>
                handleUnlock({
                  user,
                  setAreFinalized,
                  setSignMessage,
                  setShowConfirmationModal,
                  setAllowToSign,
                  selectedRows
                })
              }
            />
          ) : (
            <Button
              text="Sign & Lock"
              icon="new-lockv2"
              iconColor="white"
              size="small"
              onClick={() =>
                handleSignAndLock({
                  user,
                  selectedRows,
                  setSignMessage,
                  setShowConfirmationModal,
                  setSelectedRows,
                  setAreFinalized,
                  setAllowToSign
                })
              }
            />
          )}

          <Button
            text="Export"
            icon="new-file-download"
            loading={buttonLoading}
            disabled={buttonLoading}
            iconColor="white"
            size="small"
            onClick={() =>
              handleExportModal({
                selectedRows,
                setDocumentIds,
                setExportModal,
                setButtonLoading,
                setDownloadPDF
              })
            }
          />
        </>
      )}
      {showConfirmationModal && (
        <SignAndLockProcess
          allowToSign={allowToSign}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          signMessage={signMessage}
          areFinalized={areFinalized}
        />
      )}
    </>
  );
};

export default HeaderActions;
