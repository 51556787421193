import Loader from 'components/shared/Loader/Loader';
import React, { useEffect, useState } from 'react';
import Button from '../../../../shared/Buttons/Button';
import RenderedComponents from '../PreviewNote/Rendered/components/RenderedComponents';

import { assign } from 'lodash';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

import { selectedInitialState } from '../PreviewNote/lib/initials';
import { mapRenderedInitialState, processSelectedForms } from '../PreviewNote/lib/renderedHelper';
import {
  prepareSelection,
  processAdvancedCustomForms,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../PreviewNote/lib/selectedHelper';

const PreviewSelectedNote = ({ componentProps, cancel = () => null, loadNote = () => null }) => {
  const { cnDisplaySettings, loading: cnLoading } = useClinicalNoteContext() || {};
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    clinicalNote,
    currentHpOverviewData,
    permissions,
    advancedHP,
    advancedSOAP,
    customFormTypes
  } = componentProps?.customClinicalNote || {};

  useEffect(() => {
    getSelected();
  }, [advancedHP, advancedSOAP, cnDisplaySettings, currentHpOverviewData, permissions]);

  const getSelected = () => {
    setLoading(true);

    const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
    const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);
    const getAdvancedFroms = Object.entries(customFormTypes || {}).map(([, object]) => {
      return processAdvancedCustomForms(object, cnDisplaySettings);
    });

    const processedAdvancedForms = assign({}, ...getAdvancedFroms);

    let selectedObj = {
      ...selectedInitialState(
        clinicalNote,
        cnDisplaySettings,
        currentHpOverviewData,
        currentHpOverviewData?.vitals
      ),
      ...getAdvancedHP,
      ...getAdvancedSOAP,
      ...processedAdvancedForms
    };

    const formattedSelect = prepareSelection({ initialSelection: selectedObj });

    const rendered = mapRenderedInitialState(formattedSelect);

    const { checkedRendered, hpForms, soapForms, advancedForms } = processSelectedForms({
      selected: formattedSelect,
      rendered
    });

    setSelected({ checkedRendered, hpForms, soapForms, advancedForms });
    setLoading(false);
  };

  if (loading) {
    return <Loader className="!text-primary-500" outlined={true} />;
  }

  return (
    <div className="!mt-1 !rounded-lg border border-solid !border-primary-500 bg-primary-50 !p-3">
      <div>
        <RenderedComponents {...selected} {...componentProps} className="!bg-transparent" />
      </div>

      <div className="sticky bottom-1 !mt-4 flex items-center justify-between !rounded-lg border border-solid !border-primary-500 bg-white !p-1">
        <Button
          text="Cancel"
          color="neutral"
          outlined={true}
          size="small"
          onClick={cancel}
          data-qa="cancel-btn"
          disabled={cnLoading}
        />

        <Button
          text="Load"
          color="primary"
          size="small"
          onClick={loadNote}
          data-qa="load-btn"
          disabled={cnLoading}
          loadingIcon={cnLoading}
        />
      </div>
    </div>
  );
};

export default PreviewSelectedNote;
