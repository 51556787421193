import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import ActiveSymptoms from './ActiveSymptoms';

const ActiveSymptomsSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{
        name: 'Active Symptoms',
        parentKey: 'progress',
        childKey: 'activeSymptoms'
      }}
      title={components?.activeSymptoms?.title}
      id={components?.activeSymptoms?.id}
      disabled={clinicalNote?.locked}>
      <ActiveSymptoms sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ActiveSymptomsSection;
