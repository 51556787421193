import SuperbillActions from 'components/practice/charts/billing/superbill/components/SuperbillActions';
import { formatDate, iaRa } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import { filterSuperbillClaimProcedure } from '../../lib/procedureUtils';

export default function (timezone) {
  return [
    { field: 'id', headerName: 'Superbill ID' },
    {
      field: 'f_name',
      headerName: 'First Name',
      valueFormatter: ({ value }) => value.substring(0, 10)
    },
    {
      field: 'l_name',
      headerName: 'Last Name',
      valueFormatter: ({ value }) => value.substring(0, 10)
    },
    {
      field: 'rendProv',
      headerName: 'Rend. Prov.',
      valueGetter(params) {
        const firstName = params.data.prov_name_f || '';
        const lastName = params.data.prov_name_l || '';
        return `${firstName} ${lastName}`.trim();
      },
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'bill_name',
      headerName: 'Bill. Prov.',
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'payer_name',
      headerName: 'Payer',
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'starts_at',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, timezone, true),
      sortable: false
    },
    {
      field: 'services',
      headerName: 'Services',
      valueGetter(params) {
        const services = iaRa(params.data?.invoice_procedures)
          .filter(filterSuperbillClaimProcedure)
          .map((item) => item.procedure_code)
          ?.join(', ');

        return services || null;
      },
      sortable: false
    },
    {
      field: 'claim_id',
      headerName: 'Claim',
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'state',
      headerName: 'Status',
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: SuperbillActions,
      maxWidth: 100
    }
  ];
}
