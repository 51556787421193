import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import InvoicePrintView from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoicePrintView';
import InvoicePayPrompt from 'components/public/components/InvoicePayPrompt';
import { AlertContent } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Loading from 'components/shared/Loading/Loading';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import numOrDefault from 'lib/helpers/numOrDefault';
import { usePractice } from 'lib/hooks/queries/practice/usePractice';
import { useInvoice } from 'lib/hooks/queries/public/useInvoice';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { interimApi } from '../../api/InterimApi';
import CashPayment from '../Payments/cash/cash';
import Checkbox from '../shared/Checkbox/Checkbox';
import './Invoice.scss';
import DeletedInvoiceError from './components/DeletedInvoiceError';
import InvoiceDetails from './components/InvoiceDetails';
import PatientInvoice from './components/PatientInvoice';
import { getInvoicesDetails, getProceduresTotal } from './lib/utils';
import { mString } from 'lib/helpers/utility';

export default function NewInvoiceView() {
  const [showDetails, setShowDetails] = useState(false);
  const address = { address: '', zip: '' };
  const [transactionId, setTransactionId] = useState();
  const [show, setShow] = useState({
    startNewTransaction: false,
    saveCard: false,
    transactionStartText: 'Pay Now',
    receipt: false
  });
  const [receipt, setReceipt] = useState([]);
  const navigate = useNavigate();
  const { token } = useParams();

  const { data, isFetching, isLoading } = useInvoice({
    params: { navigate, token },
    dependencies: []
  });

  const invoice = data?.invoice;
  const aeob = invoice?.aeob;
  const type = invoice?.type;
  const invoices = invoice?.internal_invoices || [];
  const totalAmount = invoice?.total_amount_cents || 0;

  let patientInvoice = {
    discount: invoice?.discount_amount_cents,
    tax: invoice?.tax_percentage,
    tax_amount_cents: invoice?.tax_amount_cents,
    surcharge_amount: numOrDefault(invoice?.surcharge_amount)
  };
  let invoiceDetailsList = [];
  if (invoices?.length > 0) {
    const procedureTotalData = getProceduresTotal(invoices);
    patientInvoice = { ...patientInvoice, ...procedureTotalData };

    invoiceDetailsList = getInvoicesDetails(invoices);
  }

  const {
    tax_amount_cents = 0,
    discount = 0,
    prevDiscount = 0,
    charge = 0,
    amount_paid = 0,
    ins_payment = 0,
    ins_adjustment = 0,
    surcharge_amount = 0
  } = patientInvoice || {};

  let total =
    charge -
    amount_paid -
    ins_payment -
    ins_adjustment -
    prevDiscount +
    tax_amount_cents +
    surcharge_amount;

  if (type === 'kiosk') total = invoice?.total_amount_cents;

  const amountDue = total - discount;

  const practiceId = invoice?.practice_id;

  const { data: practiceData } = usePractice({
    enabled: !!practiceId,
    practice_id: practiceId
  });

  const practice = practiceData?.practices;
  const claims = practice?.display_settings?.claims;

  const startTransaction = () => {
    setShow({ ...show, startNewTransaction: true });
  };

  const sendEmailReceipt = async () => {
    try {
      const res = await interimApi(
        '/api/transactions/receipt/email',
        {
          transactionId: receipt?.transaction?.id
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          toast.success('Receipt email sent!');
          break;
        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: /Invoice, method: sendEmailReceipt - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: /Invoice, method: sendEmailReceipt - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const logout = async () => {
    await requestApi({
      url: '/api/user/logout',
      navigate,
      params: {},
      onError: (error, code, redirect) => {
        navigate(redirect);
      },
      onSuccess: (error, code, redirect) => {
        navigate(redirect);
      }
    });
  };

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }
  if (data?.code === 1) {
    return <DeletedInvoiceError error={data.error} />;
  }

  return (
    <>
      <div>
        {practice && (
          <InvoicePrintView
            patient={invoice?.patient}
            practice={practice}
            createdAt={invoice?.dateofservice}
            content={
              <>
                <hr className="mx-3 my-8 h-px border-0 bg-gray-300" />

                <PatientInvoice
                  invoice={patientInvoice}
                  hasClaims={claims}
                  externalInvoice={invoice}
                />

                <div className="m-2">
                  <div className="mt-3 flex flex-col items-center gap-2 [&_span]:font-bold">
                    <span>Today's payment amount</span>
                    <span className="text-[30px]">{mString(amountDue)}</span>
                  </div>
                </div>

                <div className="mx-auto">
                  <div className="flex flex-col items-center justify-center gap-3">
                    <Button
                      color="success"
                      className="w-full p-3"
                      icon={<Icon size={24} icon="money-send" color="white" />}
                      disabled={invoice?.paid}
                      onClick={startTransaction}
                      text={<div className="text-lg">{show.transactionStartText}</div>}
                    />
                    {invoice?.paid && <AlertContent color="success" title="Already paid!" />}
                  </div>
                  <div className="mt-3">
                    <Checkbox
                      checked={show.saveCard}
                      label="I want to save this card in the secure vault."
                      onChange={(v) => setShow({ ...show, saveCard: v.target.checked })}
                    />
                  </div>
                </div>

                {showDetails && (
                  <div className="pt-5">
                    <h3 className="mx-3 mb-2 font-semibold">Detailed Invoice Information</h3>
                    <div>
                      {invoiceDetailsList.map((invoiceDetails) => (
                        <InvoiceDetails
                          key={invoiceDetails.id}
                          invoiceDetails={invoiceDetails}
                          hasClaims={claims}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            }
          />
        )}

        {show.startNewTransaction && totalAmount > 0 ? (
          process.env.ENV === 'local' ? (
            <CashPayment
              fromInvoice={true}
              amount={amountDue}
              surcharge={invoice?.surcharge_amount || 0}
              number={show.checkNumber}
              invoiceToken={token}
              billingAddress={address}
              setReceipt={(r) => setTransactionId(r?.transaction?.id)}
              onSuccess={() => {
                setShow({ ...show, receipt: true, startNewTransaction: false });
              }}
            />
          ) : (
            <InvoicePayPrompt
              amount={amountDue}
              invoiceToken={token}
              billingAddress={address}
              saveCard={show.saveCard}
              setReceipt={setReceipt}
              onApproval={(txnId) => {
                setTransactionId(txnId);
              }}
              continueText="Exit"
              onCancel={() => setShow({ ...show, startNewTransaction: false })}
              onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
            />
          )
        ) : null}
      </div>

      <ReceiptModal
        transactionId={transactionId}
        isOpen={show.receipt}
        onClose={() => {
          setShow({ ...show, receipt: false });
        }}
        viewDefaultReceipt={false}
      />

      {aeob ? (
        <div className="flex w-full flex-col items-center justify-center gap-3">
          <Button text={showDetails ? 'Hide Details' : 'View Details'} onClick={toggleDetails} />
          <Button text="Exit" onClick={logout} color="neutral" outlined />
        </div>
      ) : null}
    </>
  );
}
