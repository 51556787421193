import React, { useEffect, useState } from 'react';
import Honeybadger from '@honeybadger-io/js';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import UpdateAddressForm from './UpdateAddressForm';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { PayerInitialValues, PayerValidationSchema } from '../lib/helper';
import { useQueryClient } from '@tanstack/react-query';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { getPayerAddress, updatePayerAddress } from 'api/Payer';

const UpdateAddress = ({ payer, setUpdateAddressModal, updateAddressModal }) => {
  const [address, setAddress] = useState({});
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    getStates();
    getAddress();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getAddress = async () => {
    const response = await getPayerAddress(navigate, {
      withCount: true,
      payerId: payer?.id
    });

    if (response.code === 0) {
      setAddress(response.address);
    } else {
      showAlert({
        title: 'Error!',
        message: response.error ?? 'There was an error getting all payers.',
        color: 'danger'
      });
    }

    return response;
  };

  const formik = useFormik({
    initialValues: PayerInitialValues(payer, address),
    validationSchema: PayerValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let addressObject = {
        address_ln_1: values.address_ln_1,
        address_ln_2: values.address_ln_2,
        city: values.city,
        state: values.state,
        zip: values.zip
      };

      await updateAddress(addressObject, resetForm);
      setSubmitting(false);
    }
  });

  const updateAddress = async (address, resetForm) => {
    try {
      let res = await updatePayerAddress(navigate, { payerId: payer.id, address });
      if (res.code === 0) {
        resetForm();
        setUpdateAddressModal(false);
        showAlert({
          title: 'Success!',
          message: 'Payer updated successfully!',
          color: 'success'
        });
        queryClient.invalidateQueries(['payers']);
      }
      if (res.code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to update payer!",
          color: 'danger'
        });
      }
    } catch (error) {
      showAlert({
        title: 'Error!',
        message: error ?? 'There was an error getting all payers.',
        color: 'danger'
      });
    }
  };

  return (
    <Modal
      customStyling={{ width: '620px' }}
      bodyClassName="!p-4 !max-h-[unset] !h-full"
      handleOpen={updateAddressModal}
      handleClose={() => setUpdateAddressModal(false)}
      title="Update Payer Address"
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setUpdateAddressModal()}
            id="cancelAddressModalBtn"
            data-qa="cancel-address-btn"
          />
          <Button
            onClick={() => formik.handleSubmit()}
            text="Update address"
            id="saveAddressModalBtn"
            data-qa="update-address-btn"
          />
        </div>
      }>
      <UpdateAddressForm states={states} formik={formik} />
    </Modal>
  );
};

export default withErrorBoundary(UpdateAddress);
