import React from 'react';
import { Registry } from 'react-form-builder2';
// Custom Components
import FamilyHistory from './FamilyHistory/FamilyHistory';
import SocialHistory from './SocialHistory/SocialHistory';
import SurgicalHistory from './SurgicalHistory/SurgicalHistory';
import Allergies from './Allergies/Allergies';
import ReviewSystems from './ReviewSystems/ReviewSystems';
import Vaccines from './Vaccines/Vaccines';
import CurrentMedications from './CurrentMedications/CurrentMedications';
import OtherProblems from './OtherProblems/OtherProblems';
import HPIRevisions from './HPIRevisions/HPIRevisions';
import OrderAndResult from './OrderAndResult/OrderAndResult';
import PastMedicalHistory from './PastMedicalHistory/PastMedicalHistory';

const GetFamilyHistory = React.forwardRef((props, ref) => {
  return <FamilyHistory forwardedRef={ref} {...props} />;
});
const GetSocialHistory = React.forwardRef((props, ref) => {
  return <SocialHistory forwardedRef={ref} {...props} />;
});
const GetSurgicalHistory = React.forwardRef((props, ref) => {
  return <SurgicalHistory forwardedRef={ref} {...props} />;
});
const GetReviewSystems = React.forwardRef((props, ref) => {
  return <ReviewSystems forwardedRef={ref} {...props} />;
});
const GetAllergies = React.forwardRef((props, ref) => {
  return <Allergies forwardedRef={ref} {...props} />;
});
const GetVaccines = React.forwardRef((props, ref) => {
  return <Vaccines forwardedRef={ref} {...props} />;
});
const GetOtherProblems = React.forwardRef((props, ref) => {
  return <OtherProblems forwardedRef={ref} {...props} />;
});
const GetCurrentMedications = React.forwardRef((props, ref) => {
  return <CurrentMedications forwardedRef={ref} {...props} />;
});
const GetHPIRevisions = React.forwardRef((props, ref) => {
  return <HPIRevisions forwardedRef={ref} {...props} />;
});
const GetOrderAndResult = React.forwardRef((props, ref) => {
  return <OrderAndResult forwardedRef={ref} {...props} />;
});
const GetPastMedicalHistory = React.forwardRef((props, ref) => {
  return <PastMedicalHistory forwardedRef={ref} {...props} />;
});

Registry.register('FamilyHistory', GetFamilyHistory);
Registry.register('SocialHistory', GetSocialHistory);
Registry.register('SurgicalHistory', GetSurgicalHistory);
Registry.register('ReviewSystems', GetReviewSystems);
Registry.register('Allergies', GetAllergies);
Registry.register('Vaccines', GetVaccines);
Registry.register('OtherProblems', GetOtherProblems);
Registry.register('CurrentMedications', GetCurrentMedications);
Registry.register('HPIRevisions', GetHPIRevisions);
Registry.register('OrderAndResult', GetOrderAndResult);
Registry.register('PastMedicalHistory', GetPastMedicalHistory);

export const FormBuilderToolbar = [
  {
    key: 'FamilyHistory',
    element: 'CustomElement', // element: always should be'CustomElement'
    component: GetFamilyHistory,
    type: 'custom',
    forwardRef: true,
    field_name: 'family_history_',
    name: 'Family History',
    icon: 'people'
  },
  {
    key: 'SocialHistory',
    element: 'CustomElement',
    component: GetSocialHistory,
    type: 'custom',
    forwardRef: true,
    field_name: 'social_history_',
    name: 'Social History',
    icon: 'clipboard-text'
  },
  {
    key: 'SurgicalHistory',
    element: 'CustomElement',
    component: GetSurgicalHistory,
    type: 'custom',
    forwardRef: true,
    field_name: 'surgical_history_',
    name: 'Surgical History',
    icon: 'health'
  },
  {
    key: 'ReviewSystems',
    element: 'CustomElement',
    component: GetReviewSystems,
    type: 'custom',
    forwardRef: true,
    field_name: 'review_systems_',
    name: 'Review of Systems',
    icon: 'face-id'
  },
  {
    key: 'Allergies',
    element: 'CustomElement',
    component: GetAllergies,
    type: 'custom',
    forwardRef: true,
    field_name: 'allergies_',
    name: 'Allergies',
    icon: 'thermometer'
  },
  {
    key: 'Vaccines',
    element: 'CustomElement',
    component: GetVaccines,
    type: 'custom',
    forwardRef: true,
    field_name: 'vaccines_',
    name: 'Vaccines',
    icon: 'syringe'
  },
  {
    key: 'OtherProblems',
    element: 'CustomElement',
    component: GetOtherProblems,
    type: 'custom',
    forwardRef: true,
    field_name: 'otherProblems_',
    name: 'Other Problems',
    icon: 'flag-3'
  },
  {
    key: 'CurrentMedications',
    element: 'CustomElement',
    component: GetCurrentMedications,
    type: 'custom',
    forwardRef: true,
    field_name: 'currentMedications_',
    name: 'Current Medications',
    icon: 'pill'
  },
  {
    key: 'HPIRevisions',
    element: 'CustomElement',
    component: GetHPIRevisions,
    type: 'custom',
    forwardRef: true,
    field_name: 'hpi_',
    name: 'History of present illness',
    icon: 'clipboard-text-history'
  },
  {
    key: 'OrderAndResult',
    element: 'CustomElement',
    component: GetOrderAndResult,
    type: 'custom',
    forwardRef: true,
    field_name: 'orderAndResult_',
    name: 'Order and Result',
    icon: 'document-text'
  },
  {
    key: 'PastMedicalHistory',
    element: 'CustomElement',
    component: GetPastMedicalHistory,
    type: 'custom',
    forwardRef: true,
    field_name: 'pastMedicalHistory_',
    name: 'Past medical history',
    icon: 'chart'
  },
  // Additional standard components, you don't need full definition if no modification is required.
  {
    key: 'Header'
  },
  {
    key: 'LineBreak'
  },
  {
    key: 'TextInput'
  },
  {
    key: 'TextArea'
  },
  {
    key: 'RadioButtons'
  },
  {
    key: 'Checkboxes'
  },
  {
    key: 'Image'
  },
  {
    key: 'Label'
  },
  {
    key: 'Paragraph'
  },
  {
    key: 'Dropdown'
  },
  {
    key: 'Tags'
  },
  {
    key: 'TwoColumnRow'
  },
  {
    key: 'ThreeColumnRow'
  },
  {
    key: 'FourColumnRow'
  },
  {
    key: 'Rating'
  },
  {
    key: 'Signature'
  },
  {
    key: 'Range'
  },
  {
    key: 'Camera'
  },
  // {
  //   key: 'FileUpload'
  // },
  {
    key: 'HyperLink'
  },
  {
    key: 'DatePicker'
  }
];
