import toast from 'react-hot-toast';
import { requestApi } from '../../../../../api/Api';
import { Honeybadger } from '@honeybadger-io/react';
import { getUpdatedAppointmentShape } from './previewAptHelpers';
import moment from 'moment';
import { showAlert } from '../../../../shared/Alert/Alert';

export const submitAppointment = async ({
  updatedAppointment,
  formik,
  setLoading,
  loading,
  appointment,
  procedures,
  isReschedule,
  setRefetchAppointments,
  setAppointment,
  setIsReschedule,
  setRefetchCustomForms,
  setOldAppointment,
  refetchCustomForms,
  navigate
}) => {
  setLoading({ ...loading, update: true });
  const params = getUpdatedAppointmentShape({ updatedAppointment, appointment, procedures });

  if (isReschedule && appointment?.medicalHistory?.state === 'finalized') {
    toast.error('Clinical note has already been finalized and cannot be rescheduled at this time');
    setLoading({ ...loading, update: false });
    return;
  }

  const onSuccess = (data) => {
    if (data.affectedRows > 0) {
      setRefetchAppointments();

      if (data?.updatedAppointment) {
        setIsReschedule(false);
        setRefetchCustomForms(!refetchCustomForms);

        setOldAppointment(data?.updatedAppointment);
        setAppointment({ ...appointment, ...data?.updatedAppointment });
        formik.setFieldValue('balance', data?.updatedAppointment?.balance);
        formik.setFieldValue('resource_id', data?.updatedAppointment?.resource_id);
        if (appointment?.id !== data?.updatedAppointment?.id) {
          setAppointment({ ...appointment, ...data?.updatedAppointment });
          formik.setValues({ ...formik.initialValues, ...data?.updatedAppointment });
        }
      }

      showAlert({ message: 'Appointment was updated successfully', color: 'success' });
    } else {
      showAlert({ message: 'There was an error updating this appointment', color: 'danger' });
      Honeybadger.notify(`Appointment update ID: ${updatedAppointment.id}`);
    }
  };

  await requestApi({ url: '/api/appointment/update', navigate, onSuccess, params });

  setLoading({ ...loading, update: false });
};

export const runEligibility = async ({
  insuranceId,
  setRunningEligibility,
  formik,
  setInsuranceBenefits,
  appointmentId,
  navigate,
  setAppointment
}) => {
  setRunningEligibility(true);

  const onSuccess = (data) => {
    const { updatedAppointment } = data;
    if (updatedAppointment) {
      // formik.setFieldValue('amount', updatedAppointment.amount);
      // formik.setFieldValue('charge', updatedAppointment.charge);
      // formik.setFieldValue('eligibility_run', updatedAppointment.eligibility_run);
      // formik.setFieldValue('deductible', updatedAppointment.deductible);
      // // formik.setFieldValue('modifiedDeductible', updatedAppointment.deductible);
      // formik.setFieldValue('co_payment', updatedAppointment.co_payment);
      // // formik.setFieldValue('modifiedCopayment', updatedAppointment.co_payment);
      // formik.setFieldValue('co_insurance', updatedAppointment.co_insurance);
      // // formik.setFieldValue('modifiedCoinsurance', updatedAppointment.co_insurance);

      // formik.setFieldValue(
      //   'patient.insurance_benefits',
      //   updatedAppointment?.patient?.insurance_benefits
      // );

      formik.setValues({ ...formik.values, ...updatedAppointment });
      setAppointment((prev) => ({ ...prev, ...updatedAppointment }));

      setInsuranceBenefits(updatedAppointment?.patient?.insurance_benefits?.benefits);
    }
  };
  await requestApi({
    url: '/api/patient/insurance/check_benefits',
    params: {
      appointmentId,
      insuranceProfileId: insuranceId
    },
    onSuccess,
    navigate
  });

  setRunningEligibility(false);
};

export const handleDateTimeChange = ({
  newTime,
  type,
  dateAndTime,
  practiceTimezone,
  setDateAndTime,
  formik,
  diffMin,
  setIsReschedule,
  appointment
}) => {
  if (type == 'date') newTime = moment(newTime).add(12, 'h');
  if (dateAndTime && type == 'date') {
    const date = moment.tz(newTime, practiceTimezone).format('L');
    const time = moment.tz(dateAndTime, practiceTimezone).format('h:mm A') || '';
    newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', practiceTimezone);
  } else if (dateAndTime && type == 'time') {
    const date =
      moment.tz(dateAndTime, practiceTimezone).format('L') || moment().add(12, 'h').format('L');
    const time = newTime;
    newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', practiceTimezone);
  }
  if (!dateAndTime && type == 'time') {
    newTime = moment.tz(
      moment().add(12, 'h').format('L') + ' ' + newTime,
      'M/D/YYYY h:mm A z',
      practiceTimezone
    );
  }

  setDateAndTime(moment.tz(newTime, practiceTimezone).format());
  formik.setFieldValue('starts_at', moment.tz(newTime, practiceTimezone).format());
  formik.setFieldValue('ends_at', moment.tz(newTime, practiceTimezone).add(diffMin, 'm').format());

  const oldTime = moment.tz(appointment?.starts_at, practiceTimezone).seconds(0).format();

  moment(oldTime).isSame(moment(newTime).format()) ? setIsReschedule(false) : setIsReschedule(true);
};
