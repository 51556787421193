import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../shared/Modal/Modal';
import Button from '../../shared/Buttons/Button';
import SellPackagePatient from '../sell/SellPackagePatient';
import { deleteKeys, ia, io, reShapeProcedures, sumBy } from '../../../lib/helpers/utility';
import { useLocation, useNavigate } from 'react-router-dom';

import ProceduresProducts from '../sell/ProceduresProducts';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { requestApi } from '../../../api/Api';
import Configurations from '../../Payments/Configurations';
import { useQuery } from '@tanstack/react-query';
import Input from '../../shared/Input/Input';
import InvoiceReadOnly from '../../Payments/components/InvoiceReadOnly';
import { showAlert } from '../../shared/Alert/Alert';
import ReactToPrint from 'react-to-print';

import patient from '../patientState';
import { currentPractice } from '../../practice/practiceState';

import PrintView from './components/PrintView';
import { useRecoilValue } from 'recoil';
import Tippy from '@tippyjs/react';

const QuoteValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  patient: Yup.object().required('Patient is required.')
});

// need to check
const getUniqueProdIds = (data) => {
  const idSums = {};

  data.forEach((item) => {
    const id = item.id;
    const quantity = item.sales_count;

    if (idSums[id]) {
      idSums[id] += quantity;
    } else {
      idSums[id] = quantity;
    }
  });

  return idSums;
};

const QuoteModal = ({ showQuoteModal, setShowQuoteModal }) => {
  const ref = useRef();

  const navigate = useNavigate();

  const location = useLocation();

  const practice = useRecoilValue(currentPractice);

  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstMount, setFirstMount] = useState(true);
  const [mode, setMode] = useState({ readonly: false, created: false, converted: false });

  const formik = useFormik({
    initialValues: { patient: null, name: '', services: [], packages: [], products: [] },
    validationSchema: QuoteValidationSchema,
    onSubmit: () => {}
  });

  useEffect(() => {
    getPackages();
    getProducts();
    setFirstMount(false);
  }, []);

  useEffect(() => {
    if (
      !firstMount &&
      (location.pathname === '/portal/settings/packages' ||
        location.pathname === '/portal/settings/products')
    ) {
      setShowQuoteModal(false);
    }
  }, [location.pathname]);

  const hideQuoteModal = () => setShowQuoteModal(false);

  const checkQuote = () => {
    const onSuccess = (data) => {
      if (!!data?.invoice) {
        const newInvoice = { ...data?.invoice, selected: true, expanded: true };

        setLoading(false);

        setInvoice(newInvoice);

        setMode((prev) => ({ ...prev, readonly: true }));
      }
    };

    const onError = (error, code) => {
      if (code == 4) {
        error.map((errorMessage) =>
          showAlert({ title: 'Error', message: errorMessage, color: 'danger' })
        );
      }

      setLoading(false);
    };

    setLoading(true);

    formik.submitForm();

    if (io(formik?.errors)) {
      setLoading(false);
      return;
    }

    const val = formik.values;

    const productsFromPackToBeChecked = val.packages
      .map((pack) => pack.products.map((prod) => ({ id: prod.id, sales_count: prod.quantity })))
      .flat();

    const productsToBeChecked = val.products.map(({ id, sales_count }) => ({
      id,
      sales_count
    }));

    const productsId = [...productsToBeChecked, ...productsFromPackToBeChecked];

    const proceduresToBeChecked = val.services.map(({ id }) => id);

    const proceduresFromPack = val.packages
      .map((pack) => pack.procedures.map(({ id }) => id))
      .flat();

    const servicesId = [...proceduresToBeChecked, ...proceduresFromPack];

    const invoice = {
      discount: {},
      expanded: true,
      selected: true,
      name: formik?.values?.name,
      services: formik?.values?.services,
      packages: formik?.values?.packages,
      products: formik?.values?.products,
      patient_id: formik?.values?.patient?.value
    };

    requestApi({
      url: '/api/patient/quote/check',
      params: {
        invoice,
        productsId,
        servicesId
      },
      onSuccess,
      navigate,
      onError
    });
  };

  const createQuote = () => {
    const onSuccess = (data) => {
      if (!!data?.invoice) {
        setLoading(false);

        setMode((prev) => ({ ...prev, created: true }));

        const newInvoice = {
          ...data?.invoice,
          selected: true,
          expanded: true,
          procedures: JSON.parse(data?.invoice?.procedures),
          packages: JSON.parse(data?.invoice?.packages),
          products: JSON.parse(data?.invoice?.products),
          discount: JSON.parse(data?.invoice?.discount)
        };

        setInvoice(newInvoice);

        showAlert({ title: 'Quote', message: 'Quote created successfully.' });
      }
    };

    const onError = (error, code) => {
      if (code == 4) {
        error.map((errorMessage) =>
          showAlert({ title: 'Error', message: errorMessage, color: 'danger' })
        );
      }

      setLoading(false);
    };

    setLoading(true);

    delete invoice.selected;
    delete invoice.expanded;

    requestApi({
      onError,
      navigate,
      onSuccess,
      params: { invoice },
      url: '/api/patient/quote/create'
    });
  };

  const convertQuote = () => {
    const onSuccess = (data) => {
      if (!!data?.invoice) {
        setLoading(false);

        setMode((prev) => ({ ...prev, converted: true }));

        showAlert({ title: 'Quote', message: 'Quote converted to invoice.' });
      }
    };

    const onError = (error, code) => {
      if (code == 4) {
        error.map((errorMessage) =>
          showAlert({ title: 'Error', message: errorMessage, color: 'danger' })
        );
      }

      setLoading(false);
    };

    setLoading(true);

    requestApi({
      onError,
      navigate,
      onSuccess,
      params: { invoice },
      url: '/api/patient/quote/convert'
    });
  };

  const getPackages = async () => {
    const onSuccess = (data) => {
      const { packages: loaded_packages } = data;
      if (loaded_packages) {
        setPackages(
          loaded_packages.map((item) => ({
            ...item,
            sales_count: 1,
            total_amount_cents: item.amount_cents
          }))
        );
      }
    };

    requestApi({
      url: '/api/package/get',
      params: { forUsers: true },
      onSuccess,
      navigate
    });
  };

  const getProducts = async () => {
    const onSuccess = (data) => {
      if (ia(data.products)) {
        setProducts(
          data.products.map((prod) => ({
            ...prod,
            sales_count: 1,
            total_amount_cents: prod.amount_cents_with_tax
          }))
        );
      }
    };

    requestApi({ onSuccess, url: '/api/product/get', navigate, params: { forUsers: true } });
  };

  const { data } =
    useQuery({
      queryKey: ['getServices'],
      queryFn: getServices,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  const services = reShapeProcedures(data?.services);

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  return (
    <Modal
      slideFromRight
      className="!w-[800px] bg-white"
      isOpen={showQuoteModal}
      title="Create a new quote"
      handleClose={hideQuoteModal}
      headButton={
        mode.created && (
          <div className="flex justify-end items-center w-full">
            <ReactToPrint
              trigger={() => (
                <Button
                  color=""
                  size="small"
                  type="link"
                  text="Print"
                  icon="new-printer-bulk"
                  className="text-primary-900"
                />
              )}
              content={() => ref.current}
            />

            {!mode?.converted && (
              <Tippy content="Convert to invoice" className="tippy-dark">
                <div className="relative">
                  <Button
                    color=""
                    type="link"
                    size="small"
                    text="Convert"
                    loadingIcon={loading}
                    icon="new-import-bulk"
                    onClick={convertQuote}
                    data-qa="convert-to-invoice"
                    className="text-primary-900"
                  />
                </div>
              </Tippy>
            )}

            {/* <Button
              color=""
              type="link"
              size="small"
              text="Email"
              icon="new-sms-bulk"
              onClick={createQuote}
              // loadingIcon={loading}
              data-qa="email-to-patient"
              className="text-primary-900"
            /> */}
          </div>
        )
      }
      bodyClassName={`!max-h-[unset] !h-full ${mode?.created ? '!p-0 ' : '!p-4'}`}
      footer={
        <div className="flex w-full items-center justify-between">
          {mode.readonly && !mode.created ? (
            <Button
              outlined
              transparent
              text="Back"
              color="neutral"
              onClick={() => setMode((prev) => ({ ...prev, readonly: false }))}
            />
          ) : (
            <Button
              data-qa="close-btn"
              outlined
              transparent
              color="neutral"
              onClick={hideQuoteModal}
              text={mode.created ? 'Close' : 'Cancel'}
            />
          )}

          {!mode.readonly && !mode.created && (
            <Button
              text="Continue"
              loadingIcon={loading}
              onClick={checkQuote}
              data-qa="continue-from-list-to-quote"
              disabled={
                !ia(formik?.values?.services) &&
                !ia(formik?.values?.packages) &&
                !ia(formik?.values?.products) &&
                true
              }
            />
          )}

          {mode.readonly && !mode.created && (
            <Button
              text="Create Quote"
              loadingIcon={loading}
              onClick={createQuote}
              data-qa="create-quote"
            />
          )}
        </div>
      }>
      <div className="flex  flex-col !gap-5 bg-white" ref={ref}>
        {mode.readonly && io(invoice) ? (
          <div>
            {mode.readonly && !mode.created && (
              <InvoiceReadOnly invoices={[invoice]} from="quote" setInvoice={setInvoice} />
            )}

            {mode.created && (
              <PrintView
                practice={practice}
                createdAt={invoice?.created_at}
                patient={formik?.values?.patient}
                content={<InvoiceReadOnly invoices={[invoice]} />}
              />
            )}
          </div>
        ) : (
          <>
            <Input
              data-qa="enter-quote-name"
              required
              id="name"
              name="name"
              type="text"
              label="Quote name"
              value={formik?.values?.name}
              placeholder="Enter quote name"
              onChange={formik && formik?.handleChange}
              error={formik?.touched?.name && formik?.errors?.name}
            />

            <SellPackagePatient formik={formik} />

            {services && (
              <ProceduresProducts
                isQuote={true}
                formik={formik}
                products={products}
                services={services}
                packages={packages}
                key={products.length + packages.length}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default QuoteModal;
