import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import checkinBackgroundPNG from '../../../assets/Images/checkin-background.png';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';
import { ia } from '../../../lib/helpers/utility';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import state from './state';
import './style.scss';
import Wizard from './Wizard';
import { checkinV2Map } from './constants';
import { nextIncompleteStep } from './util';
import { showAlert } from 'components/shared/Alert/Alert';

const isDev = process.env.ENV !== 'production';

const CheckinSteps = () => {
  const [appointmentId, setAppointmentId] = useState(null);
  const [steps, setSteps] = useState([]);
  const [stepObject, setStepObject] = useState({});
  const [currentStepName, setCurrentStepName] = useState('');
  const [currentStepSubtitle, setCurrentStepSubtitle] = useState('');
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [checkinInsuranceEnabled, setCheckinInsuranceEnabled] = useState(false);
  const { pathname } = useLocation();
  const checkinButton = useRef();
  const [practiceLogo, setPracticeLogo] = useState('');
  const [practice, setPractice] = useState('');
  const [user, setUser] = useRecoilState(state?.userState);
  const [loginRequest, setLoginRequest] = useState(false);
  const [selections, setSelections] = useRecoilState(state?.selectionState);
  const [numCompleted, setNumCompleted] = useState(0);
  const { device } = useUIContext();
  const _navigate = useNavigate();

  const navigate = (p) => {
    process.env.ENV === 'local' &&
      showAlert({
        color: 'success',
        message: `Navigate to url: ${p}`,
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    _navigate(p);
  };

  useEffect(() => {
    getCheckinStatus();
  }, []);

  const handleExternalSubmit = async () => {
    let naviToNext = false;
    if (checkinButton?.current?.click) {
      naviToNext = checkinButton?.current?.click();
      // navigateToIncomplete({ steps: steps });
    } else if (checkinButton?.current?.continue) {
      naviToNext = await checkinButton?.current?.continue();
      if (naviToNext) {
        isDev && console.log('handle external submit continue case', naviToNext);
        // navigateToIncomplete({ steps: steps });
      }
    }
    isDev && console.log('handle external submit', naviToNext);
  };

  useEffect(() => {
    if (pathname == '/checkin/login') {
      setLoginRequest(true);
    } else {
      setLoginRequest(false);
    }
    ia(steps) && currentStep();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  const currentStep = () => {
    setSteps((prevStep) =>
      prevStep.map((step) => {
        if (step.path === pathname || pathname.includes(step.path)) {
          setCurrentStepName(step.name);
          setCurrentStepNumber(
            step.position === 0 ? 1 : step.position > 999 ? steps.length : step.position
          );
          if (step?.subTitle) setCurrentStepSubtitle(step?.subTitle);
          else setCurrentStepSubtitle('');
          return { ...step, status: 'current' };
        } else {
          return { ...step, status: step.completed ? 'complete' : 'incomplete' };
        }
      })
    );
  };

  const updateCompleteStep = () => {
    let newNumCompleted = 0;
    setSteps((prev) => {
      const isAllCompleted = isEverythingCompleted(prev);
      const newSteps = prev.map((step) => {
        newNumCompleted += !!step?.completed;
        if (isAllCompleted && step.name === 'Complete')
          return { ...step, status: 'complete', completed: true, isStepDone: true };

        if (!isAllCompleted && !step.completed) return { ...step, isStepDone: false };

        return step;
      });
      return newSteps;
    });
    setNumCompleted(newNumCompleted);
  };

  const updateStep = useCallback(
    async ({ stepId, formId, newStatus, meta }) => {
      let foundStepId = null;

      let newNumCompleted = 0;

      let newSteps = steps.map((v) => {
        if ((stepId && v.step_id === stepId) || pathname.includes(v.path)) {
          foundStepId = v.step_id;
          const newCompletedStatus = ['partial', 'completed'].includes(newStatus);
          newNumCompleted += !!newCompletedStatus;
          return { ...v, completed: newCompletedStatus };
        } else {
          newNumCompleted += !!v?.completed;
          return v;
        }
      });

      setSteps(newSteps);
      setNumCompleted(newNumCompleted);

      if (!formId) {
        await requestApi({
          url: '/api/patient/checkin/continue',
          params: {
            stepId: stepId || foundStepId,
            newStatus,
            meta
          },
          navigate
        });
      }

      navigateToIncomplete({ steps: newSteps });
    },
    [steps]
  );

  const updateSteps = async ({
    version,
    stepId,
    formId,
    newStatus,
    formName = null,
    formsCompleted,
    formsToEdit,
    isCompleted = true,
    meta
  }) => {
    if (version === 2) {
      await updateStep({ stepId, newStatus, formId, meta });
      return;
    }
    let stepNameComparator = (stepName) => {
      return stepName === formName;
    };
    if (ia(formsCompleted)) {
      stepNameComparator = (stepName) => {
        return formsCompleted.includes(stepName);
      };
    }
    let formsToEditComparator = (stepName) => {
      return stepName === formsToEdit;
    };
    if (ia(formsToEdit)) {
      formsToEditComparator = (stepName) => {
        return formsToEdit.includes(stepName);
      };
    }
    const updatedSteps = steps.map((step) => {
      if (stepNameComparator(step?.name)) {
        return {
          ...step,
          status: !!isCompleted ? 'complete' : 'incomplete',
          completed: !!isCompleted,
          isStepDone: true
        };
      } else if (formsToEdit && formsToEditComparator(step?.name)) {
        return {
          ...step,
          status: 'incomplete',
          completed: false,
          isStepDone: false
        };
      } else {
        return step;
      }
    });
    setSteps(updatedSteps);
    navigateToIncomplete({ steps: updatedSteps });
  };

  const updateChoices = useCallback(
    (stepsToCompleted, stepsToEdit) => {
      // current step is probably one that needs to be checked for an update
      let advance = false;

      let stepsToCompleteSet = new Set();
      let stepsToEditSet = new Set();

      if (ia(stepsToCompleted)) {
        stepsToCompleted.forEach((v) => stepsToCompleteSet.add(v));
      }

      if (ia(stepsToEdit)) {
        stepsToEdit.forEach((v) => stepsToEditSet.add(v));
      }

      const newSteps = steps.map((v) => {
        let r = v;
        if (stepsToCompleteSet.has(v?.name)) {
          // this is one of the steps to update
          r.completed = true;
          if (v?.path === pathname) {
            // current step
            advance = true;
          }
        } else if (stepsToEditSet.has(v?.name)) {
          r.completed = false;
        }
        return r;
      });

      setSteps(newSteps);
      // setSelections(ps => ({...ps, ...stepsToEdit}))

      if (advance) {
        navigateToIncomplete({ steps: newSteps });
      }
    },
    [steps]
  );

  const navigateToIncomplete = useCallback(
    ({ steps: givenSteps = false }) => {
      let lSteps = givenSteps;
      if (!lSteps) {
        lSteps = steps;
      }

      const incomplete = nextIncompleteStep(pathname, lSteps);

      let prefix = '';
      if (pathname.includes('kiosk')) {
        prefix = '/kiosk/';
      }
      navigate(incomplete?.path ? `${prefix}${incomplete?.path}` : `${prefix}/checkin/complete`);
      // navigate(incomplete?.path ? incomplete?.path : '/checkin/complete');
    },
    [steps]
  );

  const getLastStep = () => {
    if (pathname.endsWith('/pay')) {
      for (let i = 0; i < steps.length; i++) {
        if (checkinV2Map.payment?.name?.() === steps[i]?.name) {
          return steps[i]?.path;
        }
      }
      // return checkinV2Map.payment.path();
    }

    for (let i = 0; i < steps.length; i++) {
      if (i > 0 && steps[i]?.path === pathname) {
        return steps[i - 1]?.path;
      }
    }

    return steps[0]?.path;
  };

  const handleBack = () => {
    let prefix = '';
    if (pathname.includes('kiosk')) {
      prefix = '/kiosk/';
    }

    let lastStep = getLastStep();

    navigate(lastStep ? `${prefix}${lastStep}` : `${prefix}/checkin/hipaa`);
  };

  const isEverythingCompleted = (stepsArray) => {
    return (
      ia(stepsArray) &&
      stepsArray.filter((step) => step.name !== 'Complete').every((step) => step.completed === true)
    );
  };

  const getCheckinStatus = async () => {
    const onSuccess = (data) => {
      // on success
      let newNumCompleted = 0;
      if (data?.practiceLogo) {
        setPracticeLogo(data?.practiceLogo);
      }
      if (data?.practice) {
        setPractice(data?.practice);
      }
      if (data?.appointmentId) {
        setAppointmentId(data?.appointmentId);
      }
      setUser(data?.user); // TODO: set user like this in instant packets
      const dataSteps = data?.steps || {};
      let newSteps = [];
      // let newSteps = Object.assign([], dataSteps);
      setSelections(
        data?.checkinSelections || {
          personal_iden: true,
          personal_info: true,
          insurance: true
        }
      );

      newSteps = dataSteps.map((v) => {
        newNumCompleted += v?.completed;
        return {
          ...v,
          name: checkinV2Map[v?.name]?.name(v) || v?.name,
          subTitle: checkinV2Map[v?.name]?.subTitle?.(v) || ''
        };
      });

      const isFullyCompleted = isEverythingCompleted(newSteps);

      newSteps.push({
        name: 'Complete',
        path: '/checkin/complete',
        // status: initialCurrentStep({ path: '/checkin/complete', status: isFullyCompleted }),
        completed: isFullyCompleted,
        position: 9999,
        step_id: -1
      });

      // newSteps = newSteps.sort((a, b) => a.position - b.position);

      if (ia(newSteps)) {
        newSteps.forEach((v) => {
          if (v.path === pathname) {
            setCurrentStepName(v.name);
          }
        });
      }

      setSteps(newSteps);
      setStepObject({ ...dataSteps, numCompleted: newNumCompleted });
      setNumCompleted(newNumCompleted);
    };
    await requestApi({
      url: '/api/patient/checkin/status',
      onSuccess,
      navigate,
      filePath: __filename
    });
  };

  const showButton = () => {
    const excludedPaths = ['/complete', '/return-check', '/pay'];
    return !excludedPaths.some((path) => pathname.includes(path));
  };

  return (
    <Wizard
      checkinBackgroundPNG={checkinBackgroundPNG}
      loginRequest={loginRequest}
      setLoginRequest={setLoginRequest}
      device={device}
      practiceLogo={practiceLogo}
      practice={practice}
      steps={steps}
      currentStepName={currentStepName}
      currentStepSubtitle={currentStepSubtitle}
      currentStepNumber={currentStepNumber}
      updateCompleteStep={updateCompleteStep}
      updateSteps={updateSteps}
      isEverythingCompleted={isEverythingCompleted}
      navigateToIncompleted={navigateToIncomplete}
      stepObject={stepObject}
      numCompleted={numCompleted}
      setSteps={setSteps}
      checkinButton={checkinButton}
      checkinInsuranceEnabled={checkinInsuranceEnabled}
      showButton={showButton}
      handleExternalSubmit={handleExternalSubmit}
      appointmentId={appointmentId}
      handleBack={handleBack}
      updateChoices={updateChoices}
    />
  );
};

export default withErrorBoundary(CheckinSteps);
