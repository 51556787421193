import { Popover } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { toLower } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { mergePatients, updatePracticePatientLink } from '../../../../../api/Patients';
import { useUIContext } from '../../../../../lib/context/UIContext/UIContext';
import { Capitalize, isEmpty } from '../../../../../lib/helpers/utility';
import StickyNotes from '../../../StickyNotes/StickyNotes';
import { currentPractice } from '../../../practiceState';
import OptionPopover from './OptionPopover';

export default function OverviewPopover({
  patient,
  showNewAppointmentModal,
  setSellModalVisible,
  setShowPaymentModal
}) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const { timezone } = useRecoilValue(currentPractice);
  const [reason, setReason] = useState();
  const [deceaseDate, setDeceaseDate] = useState(moment());
  const { device } = useUIContext();
  const [createPopover, setCreatePopover] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedDupe, setSelectedDupe] = useState(null);

  const patientStatus = patient?.practicePatient?.status === 'active' ? 'inactive' : 'active';
  const patientStatusPopoverIcon =
    patient.status === 'active' ? 'new-patient-inactive' : 'new-patient-active';
  const patientStatusModalIcon =
    patient.status === 'active' ? 'new-patient-inactive' : 'new-patient-active';
  const queryClient = useQueryClient();

  const mutatePracticePatientLink = useMutation({
    mutationFn: () => {
      setLoading(true);
      return modalInfo.popoverType === 'merge'
        ? mergePatients(navigate, {
            basePatientId: patient.id,
            dupPatientId: selectedDupe
          })
        : updatePracticePatientLink(navigate, {
            patientId: patient.id,
            changes: {
              status: modalInfo.popoverType === 'status' ? patientStatus : modalInfo.popoverType,
              ...(isEmpty(reason) ? null : { reason }),
              ...(modalInfo.popoverType === 'deceased' && deceaseDate
                ? { decease_date: deceaseDate }
                : null)
            }
          });
    },
    onSuccess: (response) => {
      setLoading(false);
      const { message } = response;
      queryClient.invalidateQueries(['patient']);
      queryClient.invalidateQueries(['patients']);
      showAlert({ color: 'success', message: message || 'Profile was updated successfully!' });
      onCloseModal();
    }
  });

  const initialOptions = [
    {
      label: `Mark ${Capitalize(patientStatus)}`,
      type: `status`,
      icon: `${patientStatusPopoverIcon}`,
      iconColor: 'primary',
      modalProps: {
        title: `Mark Patient ${Capitalize(patientStatus)}`,
        message: `Are you sure you wish to mark this patient ${patientStatus}?`,
        primaryBtnTxt: `Mark ${Capitalize(patientStatus)}`,
        icon: `${patientStatusModalIcon}`
      }
    },
    {
      label: 'Mark Deceased',
      type: 'deceased',
      icon: 'new-deceased-patient',
      modalProps: {
        title: 'Deceased Patient',
        message: 'Are you sure you wish to mark this patient deceased?',
        primaryBtnTxt: 'Mark Deceased',
        icon: 'new-modal-patient-deceased'
      }
    },
    {
      label: 'Delete patient',
      type: 'deleted',
      icon: 'new-trash-patient',
      modalProps: {
        title: 'Delete Patient',
        message: 'Are you sure you wish to permanently delete this patient?',
        primaryBtnTxt: 'Delete Patient',
        icon: 'new-delete-patient'
      }
    },
    {
      label: 'Merge duplicate',
      type: 'merge',
      icon: 'new-merge',
      iconColor: 'primary',
      modalProps: {
        title: 'Merge Duplicate Patient Records',
        message: 'Are you certain you wish to permanently merge these patient records?',
        primaryBtnTxt: 'Merge Charts',
        icon: 'new-merge-charts'
      }
    }
  ];
  const [chartOptions, setChartOptions] = useState(initialOptions);
  const mobileOptions = {
    label: 'Create',
    create: true,
    icon: 'add-circle',
    children: [
      {
        label: 'Appointment',
        icon: 'new-calendar',
        onClick: () => showNewAppointmentModal(true)
      },
      {
        label: 'Transaction',
        icon: 'new-receipt-textv2',
        onClick: () => setSellModalVisible(true)
      },
      { label: 'Payment', icon: 'money-send', onClick: () => setShowPaymentModal(true) }
    ]
  };

  useEffect(() => {
    if (device !== 'desktop') {
      setChartOptions([...initialOptions, mobileOptions]);
    } else {
      setChartOptions(initialOptions);
    }
  }, [device]);

  const onOptionClick = (modalProps, closeModal) => {
    if (modalProps.popoverType) {
      setShowModal(true);
      setModalInfo(modalProps);
      closeModal();
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setLoading(false);
    setDeceaseDate(null);
    setReason([]);
    setModalInfo({});
  };

  if (showModal) {
    return (
      <Confirm
        handleOpen={showModal}
        handleClose={() => onCloseModal()}
        loading={loading}
        variant="danger"
        handleContinue={() => mutatePracticePatientLink.mutate()}
        disabled={modalInfo.popoverType === 'merge' && (!isChecked || !selectedDupe)}
        {...modalInfo}>
        <OptionPopover
          type={modalInfo.popoverType}
          timezone={timezone}
          reason={reason}
          setReason={setReason}
          deceaseDate={deceaseDate}
          setDeceaseDate={setDeceaseDate}
          isChecked={isChecked}
          setIsChecked={() => setIsChecked(!isChecked)}
          selectedDupe={selectedDupe}
          setSelectedDupe={setSelectedDupe}
          patient={patient}
        />
      </Confirm>
    );
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <div>
          <Popover.Button
            data-qa="overview-popover"
            className={cs(
              'flex h-8 w-8 cursor-pointer items-center justify-center rounded-[4px] shadow-small',
              open ? 'border-primary-700 !bg-primary-700 transition-all' : 'hover:bg-neutral-100'
            )}>
            <Icon icon="three-dots" className="flex cursor-pointer" color={open && 'white'} />
          </Popover.Button>

          <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-max cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
            {chartOptions.map((option, idx) => {
              return (
                <>
                  {idx === 3 && (
                    <StickyNotes
                      patientId={patient.id}
                      customStyle={{
                        button:
                          'relative flex items-center gap-x-1 py-[6px] !px-3 !pl-[13px] transition-all hover:bg-primary-50 w-full',
                        text: 'text-primary-900 text-xs',
                        icon: {
                          size: 16,
                          color: 'primary'
                        }
                      }}
                    />
                  )}
                  <div
                    key={idx}
                    className="relative flex items-center gap-x-1 !px-3 py-[6px] transition-all hover:bg-primary-50"
                    onClick={() =>
                      onOptionClick(
                        {
                          ...option?.modalProps,
                          popoverType: option?.type,
                          create: option?.create,
                          stickyNote: option?.stickyNote
                        },
                        close
                      )
                    }
                    data-qa={`create-overview-popover-${idx}`}
                    onMouseEnter={() => {
                      if (option?.create) setCreatePopover(true);
                    }}
                    onMouseLeave={() => {
                      if (option?.create) setCreatePopover(false);
                    }}>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex select-none items-center gap-x-1">
                        <Icon
                          icon={option.icon}
                          color={option.iconColor}
                          size={option.iconSize || 18}
                          className={cs(
                            'flex items-center',
                            option.icon === 'new-note' && 'mx-[1px]'
                          )}
                        />
                        <div className="text-xs text-primary-900">{option.label}</div>
                      </div>
                      {option.create && <Icon icon="new-chevron-right" />}
                    </div>

                    {createPopover && option.create && (
                      <div className="absolute -top-3 right-[96%] z-30 mt-1 grid w-max min-w-[134px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
                        {mobileOptions.children.map((item, index) => (
                          <div
                            key={index}
                            data-qa={`create-${toLower(item.label)}-inside`}
                            className="flex items-center gap-x-[6px] !px-3 py-[6px] transition-all hover:bg-primary-50"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCreatePopover(false);
                              item.onClick();
                              close();
                            }}>
                            <Icon icon={item.icon} color="primary" size={18} />
                            <span className="text-xs font-400 text-primary-900">{item.label}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
}
