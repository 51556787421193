import cs from 'classnames';
import Modal from 'components/shared/Modal/Modal';
import Switch from 'components/shared/Switch/Switch';
import React from 'react';
import PreviewNote from './PreviewNote';

const PreviewNoteModal = ({ isOpen, handleClose, title, isExport, setIsExport }) => {
  const currentViewClassName = ({ check = true }) => {
    return cs('text-sm', check ? 'text-primary-500' : 'text-neutral-500');
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      slideFromRight
      className="w-[1020px]"
      bodyClassName="p-0 overflow-hidden"
      headButton={
        <div className="!ml-3 flex items-center gap-x-1">
          <span className={currentViewClassName({ check: !isExport })}>Preview</span>
          <Switch checked={isExport} onChange={() => setIsExport(!isExport)} />
          <span className={currentViewClassName({ check: isExport })}>Export</span>
        </div>
      }>
      <PreviewNote isExport={isExport} />
    </Modal>
  );
};

export default PreviewNoteModal;
