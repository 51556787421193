import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import ReviewSystems from '../../../../../../../shared/Forms/Custom/ReviewSystems/ReviewSystems';

const ReviewOfSystems = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Review Of Systems', parentKey: 'hp', childKey: 'ros' }}
      title={components?.ros?.title}
      id={components?.ros?.id}
      disabled={clinicalNote?.locked}>
      <ReviewSystems
        isClinicalNote
        defaultValue={clinicalNote?.ros}
        sectionRef={sectionRef}
        showNarrative
      />
    </Accordion>
  );
};

export default ReviewOfSystems;
