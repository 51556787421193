import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const PatientHistorySidebar = ({ className, isOpen, setIsOpen }) => {
  const cards = [
    {
      id: 1,
      title: 'Allergies',
      icon: 'new-messages',
      body: 'Allergies goes here...',
      active: true
    },
    {
      id: 2,
      title: 'Vaccines',
      icon: 'new-messages',
      body: 'Vaccines goes here...',
      active: false
    },
    {
      id: 3,
      title: 'Current medications',
      icon: 'new-messages',
      body: 'Current medications goes here...',
      active: false
    },
    {
      id: 4,
      title: 'Complaints',
      icon: 'new-messages',
      body: 'Complaints goes here...',
      active: false
    }
  ];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={cs(
        'fixed z-30 h-full shrink-0 !border-l-[1px] border-t-0 !border-solid !border-neutral-100 duration-200',
        {
          'w-[264px]': isOpen, // Sidebar width when open
          'w-0': !isOpen // Sidebar width when closed, but leaving space for the hamburger icon
        },
        className
      )}
      style={{
        transition: 'width 0.2s ease'
      }}>
      <div className="flex items-center justify-between bg-primary-50 !py-[7.5px] px-3">
        {isOpen && (
          <Icon
            icon="new-hamburger"
            className="!ml-1 cursor-pointer"
            onClick={toggleSidebar} // Toggle sidebar on click
          />
        )}
        {isOpen && (
          <>
            <div className="p-2 pl-0">Patient History Preview</div>
          </>
        )}
      </div>
      {isOpen && (
        <div className="h-full overflow-y-auto p-3">
          {cards.map((card) => (
            <div
              key={card.id}
              className="mb-4 cursor-pointer rounded-lg !border-[1px] !border-solid !border-neutral-100 p-3 hover:bg-primary-50">
              <div className="mb-2 flex flex-col items-start">
                <span className="text-sm text-primary-900">{card.title}</span>
                <div className="mt-1 h-0.5 w-10 bg-primary-500" />
              </div>
              <div>{card.body}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PatientHistorySidebar;
