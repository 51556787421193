import { io } from 'lib/helpers/utility';

export const shouldDisableDelete = ({ claim = {}, procedure = {} }) => {
  if (!io(claim)) return;

  const isClinicalNoteLocked =
    claim?.superbill?.encounter?.appointment?.medicalHistory?.state === 'finalized' &&
    claim?.superbill?.encounter?.appointment?.medicalHistory?.locked;

  return isClinicalNoteLocked && procedure?.uuid;
};
