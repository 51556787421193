import React from 'react';
import { useRecoilValue } from 'recoil';
import { Popover } from '@headlessui/react';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker as CalendarRangePicker } from 'react-date-range';
import { defaultStaticRanges } from '../../practice/BeyondBilling/FinancialReporting/StaticFilteringRanges';
import { formatDate } from '../../../lib/helpers/utility';
import Icon from '../Icon/Icon';
import { currentPractice } from '../../practice/practiceState';
import cs from 'classnames';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';

const DateRangePicker = ({
  ranges = [
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection'
    }
  ],
  startDate,
  endDate,
  onChange = () => null,
  months = 2,
  minDate = -300,
  maxDate = 900,
  direction = 'horizontal',
  scroll = { enabled: true },
  showSelectionPreview = true,
  moveRangeOnFirstSelection = true,
  staticRanges = defaultStaticRanges,
  buttonClassName = '',
  buttonTextColor = '',
  iconColor = 'primary',
  customStyling = {}
}) => {
  const practice = useRecoilValue(currentPractice);
  const { device } = useUIContext();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            className={cs(
              'relative flex !p-2 min-w-[30px] min-h-[30px] cursor-pointer items-center justify-center gap-1 border border-neutral-100 rounded-md bg-white shadow-sm',
              open && 'todo:AddActiveStyling',
              buttonClassName
            )}>
            <Icon icon="new-calendar" size={18} color={iconColor} />
            {device !== 'mobile' && (
              <>
                <label className={cs('cursor-pointer !m-0 text-neutral-800', buttonTextColor)}>
                  {formatDate(startDate, practice.timezone)}
                </label>
                <span className={buttonTextColor}>-</span>
                <label className={cs('cursor-pointer !m-0 text-neutral-800', buttonTextColor)}>
                  {formatDate(endDate, practice.timezone)}
                </label>
              </>
            )}
          </Popover.Button>
          <Popover.Panel
            className="absolute z-10 flex shadow-md right-0 mt-1 !rounded-lg overflow-hidden"
            style={customStyling}>
            <CalendarRangePicker
              onChange={onChange}
              showSelectionPreview={showSelectionPreview}
              moveRangeOnFirstSelection={moveRangeOnFirstSelection}
              months={
                device === 'mobile' || device === 'tablet-sm' || device === 'tablet' ? 1 : months
              }
              minDate={addDays(new Date(), minDate)}
              maxDate={addDays(new Date(), maxDate)}
              direction={direction}
              scroll={scroll}
              ranges={[
                {
                  startDate: startOfDay(ranges[0].startDate),
                  endDate: endOfDay(ranges[0].endDate),
                  key: 'selection'
                }
              ]}
              staticRanges={staticRanges}
            />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default DateRangePicker;
