import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { surgeriesList } from '../../../../../constants';
import Box from '../../../../practice/charts/ClinicalNote/shared/Box';
import SearchProviders from '../../../../Search/SearchProviders/SearchProviders';
import Icon from '../../../Icon/Icon';
import Input from '../../../Input/Input';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import CustomFormPopover from '../components/CustomFormPopover';

const SurgicalHistoryRow = ({
  surgery,
  surgeon,
  date,
  calendarPopover,
  note,
  id,
  index,
  setCurrentRowIdx,
  forwardedRef,
  onDelete,
  onChange,
  onChangePopover,
  length,
  fromCheckin,
  className
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);

  useEffect(() => {
    onChangePopover(false, id);
  }, [date]);

  const togglePopover = (e) => {
    e.preventDefault();
    onChangePopover(!calendarPopover, id);
    setCurrentRowIdx(index);
  };

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <Box className="w-full">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
          <Select
            inputId="select-surgery"
            label="Surgery"
            placeholder="Type and select surgery"
            options={surgeriesList}
            value={surgery}
            name="surgery"
            isCreatable
            onChange={(event) => onChange('surgery', event, id)}
          />

          <div className="relative" ref={forwardedRef}>
            <CustomFormPopover
              button={
                <div className="flex flex-col">
                  <label
                    className="m-0 pb-[6px] text-start text-sm text-neutral-800"
                    htmlFor="surgeriesDate">
                    Date of surgery
                  </label>
                  <Input
                    inputWrapperClassName="!flex"
                    data-qa="new-calendar-gray"
                    rightIcon="new-calendar-gray"
                    value={moment.utc(date).format('MMM Do, YYYY')}
                    readOnly
                  />
                </div>
              }
              panel={
                <Calendar
                  data-qa="new-date"
                  date={new Date(date)}
                  onChange={(event) =>
                    onChange('date', moment.utc(event).add('12', 'h').format('YYYY-MM-DD'), id)
                  }
                />
              }
            />
          </div>
        </div>

        <SearchProviders
          data-qa="type-surgeon"
          label="Surgeon"
          placeholder="Type surgeon"
          disabled={disabled}
          value={surgeon}
          onChange={(event) => onChange('surgeon', event, id)}
        />

        <Textarea
          label="Please notate here if unsure"
          placeholder="Add note here"
          id="note"
          data-qa="add-note"
          name="note"
          value={note}
          onChange={(event) => onChange('note', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
      </Box>
      {(!fromCheckin || length > 1) && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default SurgicalHistoryRow;
