import React, { useState } from 'react';
import cs from 'classnames';

import Modal from '../../shared/Modal/Modal';
import Icon from '../../shared/Icon/Icon';
import { useChartExport } from '../../../lib/hooks/queries/patients/useChartExport';
import Skeleton from '../../shared/Skeleton/Skeleton';
import moment from 'moment';

export default function ChartExport({ selectedRows }) {
  const [show, setShow] = useState(false);

  const { data, isLoading, isFetching } = useChartExport({
    params: { selectedRows },
    dependencies: [selectedRows]
  });

  const exportCSV = async () => {
    const blob = new Blob([data?.csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `EHI-Export-${moment().format('YYYY-MM-DD')}.csv`;
    a.click();
  };

  return (
    <>
      <div
        onClick={() => setShow(true)}
        className={cs(
          'flex py-[5px] px-[11px] bg-white justify-center gap-2 items-center rounded-lg',
          'shadow-[0px_0px_8px_0px_#1032540A] !border-neutral-100 border cursor-pointer border-solid'
        )}>
        <Icon size={16} color={'neutral'} className={'cursor-pointer'} icon="new-export-v2"></Icon>
      </div>
      <Modal
        icon="list-unordered"
        title={'Export EHI data'}
        handleOpen={show}
        handleClose={() => setShow(false)}>
        <div>
          {isLoading || isFetching ? (
            <Skeleton count={1} />
          ) : (
            <div
              onClick={exportCSV}
              className="border mt-3 !border-primary-100 flex justify-between !rounded-lg hover:bg-primary-100 bg-primary-50 !p-2 items-center !px-4 cursor-pointer ">
              <p className="text-base text-primary-900 font-medium">Generate CSV file</p>
              <Icon className="cursor-pointer" icon="file-csv"></Icon>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
