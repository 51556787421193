import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { interimApi } from '/api/InterimApi';
import { getStates } from '/api/State';
import { ia } from '/lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import NewReferringProviderForm from './NewReferringProviderForm';

const NewReferringProvider = ({
  mode,
  setMode,
  formatRP,
  patientId,
  setProviderList,
  autofillCMS1500,
  selectedProvider,
  setAutofillCMS1500,
  setSelectedProvider,
  showNewReferringProvider,
  hideNewReferringProvider,
  className = null
}) => {
  const [loading, setLoading] = useState({
    get: false,
    search: false,
    create: false,
    update: false
  });

  const [searchList, setSearchList] = useState([]);
  const [states, setStates] = useState([]);
  const navigate = useNavigate();

  const handleEnterPreventDefault = (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const NewReferringProviderVS = Yup.object().shape({
    first_name: Yup.string().min(2, 'First Name must be at least two characters'),
    last_name: Yup.string().min(2, 'Last Name must be at least two characters')
  });

  useEffect(() => {
    getReferringProviders();
    getStatesMethod();
  }, []);

  const formik = useFormik({
    initialValues: {
      number: '',
      first_name: '',
      last_name: ''
    },
    validationSchema: NewReferringProviderVS,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      await searchProvider(values);

      setSubmitting(false);
    }
  });

  const searchProvider = async (values) => {
    try {
      setLoading({ ...loading, search: true });

      const { data } = await interimApi(`/api/npi/get`, values, navigate);

      if (ia(data?.npi, -1)) setSearchList(data?.npi);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    setLoading({ ...loading, search: false });
  };

  const createReferringProvider = async () => {
    try {
      setLoading({ ...loading, create: true });

      const { data, status } = await interimApi(
        `/api/referring_provider/create`,
        {
          patientId,
          referringProvider: selectedProvider
        },
        navigate
      );

      if (autofillCMS1500 !== selectedProvider?.autofillCMS1500) {
        await interimApi(
          'api/practice/patient/update',
          {
            user_id: patientId,
            data: { auto_ref_prov: selectedProvider?.autofillCMS1500 }
          },
          navigate
        );
        setAutofillCMS1500(selectedProvider?.autofillCMS1500);
      }

      if (data.data && status === 200) {
        setProviderList((prevState) => [data.data, ...prevState]);

        setSearchList([]);
        setSelectedProvider(null);
        hideNewReferringProvider();
        setMode({ read: false, edit: false });
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    setLoading({ ...loading, create: false });
  };

  const updateReferringProvider = async () => {
    try {
      setLoading({ ...loading, update: true });

      const { data, status } = await interimApi(
        `/api/referring_provider/update`,
        {
          referringProviderId: selectedProvider?.id,
          columns: { cond_date: selectedProvider?.cond_date }
        },
        navigate
      );

      if (autofillCMS1500 !== selectedProvider?.autofillCMS1500) {
        await interimApi(
          'api/practice/patient/update',
          {
            user_id: patientId,
            data: { auto_ref_prov: selectedProvider?.autofillCMS1500 }
          },
          navigate
        );

        setAutofillCMS1500(selectedProvider?.autofillCMS1500);
      }

      if (data.data && status === 200) {
        setProviderList((prevState) => {
          let list = prevState;

          const index = list.findIndex((f) => f.npi === selectedProvider?.npi);

          list[index] = selectedProvider;

          return list;
        });

        setSelectedProvider(null);
        hideNewReferringProvider();
        setMode({ read: false, edit: false });
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    setLoading({ ...loading, update: false });
  };

  const getReferringProviders = async () => {
    try {
      setLoading({ ...loading, get: true });

      const { data, status } = await interimApi(
        `/api/referring_provider/get`,
        { patientId },
        navigate
      );

      if (data.data && status === 200) setProviderList(data.data);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    setLoading({ ...loading, get: false });
  };

  const getStatesMethod = async () => {
    try {
      let data = await getStates(navigate);

      setStates(data);
    } catch (err) {
      Honeybadger.notify(`getStates patientID: ${patientId}, error: ${err}`);
    }
  };

  const handleCloseModal = () => {
    hideNewReferringProvider();
    formik.resetForm();
    setSearchList([]);
  };

  return (
    <Modal
      slideFromRight
      title="Referring Provider"
      className={className}
      handleClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      handleOpen={showNewReferringProvider}
      footer={
        mode?.read && (
          <>
            <Button text="Cancel" outlined color='neutral' type="link" onClick={handleCloseModal} data-qa="cancel-btn" />

            {mode?.edit ? (
              <Button
                text="Update"
                loading={loading?.update}
                onClick={updateReferringProvider}
                data-qa="update-btn"
              />
            ) : (
              <Button
                text="Create"
                loading={loading?.create}
                onClick={createReferringProvider}
                data-qa="create-btn"
              />
            )}
          </>
        )
      }>
      {mode?.read ? (
        <>
          {!mode?.edit && (
            <Button
              icon="back"
              type="link"
              text="Back"
              onClick={() => {
                setMode({ ...mode, read: false });
                setSelectedProvider(null);
              }}
            />
          )}

          <form onSubmit={formik.handleSubmit} onKeyPress={handleEnterPreventDefault}>
            <NewReferringProviderForm
              mode={mode}
              setMode={setMode}
              formatRP={formatRP}
              autofillCMS1500={autofillCMS1500}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              stateList={states}
            />
          </form>
        </>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} onKeyPress={handleEnterPreventDefault}>
            <NewReferringProviderForm formik={formik} formatRP={formatRP} />
          </form>

          <div className="!mt-4">
            {loading?.search ? (
              <Skeleton count="10" className="mt-4" />
            ) : (
              <table className="text-left shadow-[0_6px_16px_0_#25313c0a]">
                {!(searchList.length === 0) && (
                  <thead>
                    <tr className="relative h-[50px] !p-4 shadow-[0_6px_16px_0_#25313c0a]">
                      <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">NPI</th>
                      <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                        First Name
                      </th>
                      <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                        Last Name
                      </th>
                      <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Address</th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {searchList.map((provider, i) => {
                    const { number, first_name, last_name, address } = provider || {};

                    return (
                      <tr
                        data-qa={`search-providers-${i}`}
                        key={i}
                        onClick={() => {
                          if (mode?.select) {
                            selectedProvider(provider);
                            hideNewReferringProvider();
                            return;
                          }

                          setSelectedProvider({
                            ...provider,
                            autofillCMS1500
                          });
                          setMode({ ...mode, read: true });
                        }}
                        className="cursor-pointer odd:!bg-[#F8FDFF] even:!bg-white">
                        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                          {number}
                        </td>
                        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                          {first_name}
                        </td>
                        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                          {last_name}
                        </td>
                        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                          {address &&
                            `${address?.address_1}, ${address?.city} ${address?.state} ${address?.postal_code}`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default NewReferringProvider;
