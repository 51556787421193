import React, { useState } from 'react';
import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import Tabs from '../../../../shared/Tabs/NewTabs';
import Inbound from './Inbound';
import Outbound from './Outbound';

const CommunicationsUsage = (props) => {
  const item = { name: 'Communications Usage' };
  const [tabIndex, setTabIndex] = useState(0);
  const tabsData = [
    {
      label: 'Inbound',
      content: <Inbound {...props} />
    },
    {
      label: 'Outbound',
      content: <Outbound {...props} />
    }
  ];

  return (
    <div>
      <WidgetHeader icon="new-messagesv2" item={item} hasPlus={false} />
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default CommunicationsUsage;
