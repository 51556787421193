import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from '../../api/Api';

const useDisplaySettings = (userState) => {
  const saveDisplaySettings = async (updatedSettings) => {
    try {
      let params = {
        practitioner_id: userState.id,
        fields: {
          display_settings: { ...updatedSettings }
        }
      };

      requestApi({ url: '/api/practice/settings/practice_user/update', params });
    } catch (error) {
      Honeybadger.notify(`Clinical note autosave settings - catch error - ${error}`);
    }
  };

  return { saveDisplaySettings };
};

export default useDisplaySettings;
