import React from 'react';
import useChartSize from 'lib/hooks/useChartSize';
import ListView from './ListView';
import ChartView from './ChartView';
import { statuses } from '../lib/constants';
import { returnCount } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Status = (props) => {
  const { overview } = props;
  const { item, layout } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const { height, width, proportion } = useChartSize(container);

  const chartData = [
    {
      name: 'Requested',
      value: returnCount(statuses, 'Requested', overview?.tasks?.status, 'status') || 0,
      fill: '#B0EAFF'
    },
    {
      name: 'In Progress',
      value: returnCount(statuses, 'In Progress', overview?.tasks?.status, 'status') || 0,
      fill: '#33C9FF'
    },
    {
      name: 'Completed',
      value: returnCount(statuses, 'Completed', overview?.tasks?.status, 'status') || 0,
      fill: '#004F6B'
    }
  ];
  const options = {
    data: chartData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    series: [
      {
        data: chartData,
        type: 'donut',
        angleKey: 'value',
        fills: chartData.map((item) => item.fill),
        innerRadiusRatio: 0.7,
        innerLabels: [
          { text: `${overview?.tasks?.count?.count}`, color: '#004F6B', fontSize: 19 },
          { text: ' ', fontSize: 10 },
          { text: 'Tasks', color: '#6D828B', fontSize: 12 }
        ],
        innerCircle: {
          fill: 'transparent'
        }
      }
    ]
  };

  return (
    <>
      {props?.listView ? (
        <ListView
          options={options}
          constantType={statuses}
          data={overview?.tasks?.status}
          total={overview?.tasks?.count?.count}
          keyName="status"
        />
      ) : (
        <ChartView
          options={options}
          constantType={statuses}
          data={overview?.tasks?.status}
          total={overview?.tasks?.count?.count}
          keyName="status"
        />
      )}
    </>
  );
};

export default withErrorBoundary(Status);
