import React, { useState, useEffect, useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../practiceState';
import { useOutletContext, useParams } from 'react-router-dom';
import Axios from '../../../../../../configuredAxios';
import { Honeybadger } from '@honeybadger-io/react';
import { mapRenderedInitialState, processSelectedForms } from '../lib/renderedHelper';
import RenderedContent from './RenderedContent';
import { showAlert } from 'components/shared/Alert/Alert';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';

const Rendered = ({ isExport, outsideAppointmentId = null, patientId }) => {
  const { clinicalNote, selected } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);
  const { patient } = useOutletContext() || {};
  const { appointmentId = outsideAppointmentId } = useParams();
  const [provider, setProvider] = useState();
  const rendered = mapRenderedInitialState(selected);
  const renderedRef = useRef();
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [loading, setLoading] = useState({ email: false });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { data: patientFromId } = usePatient({
    params: { id: patientId },
    dependencies: [patientId]
  });

  useEffect(() => {
    getAppointment();
  }, []);

  const getAppointment = async () => {
    try {
      let params = { id: appointmentId ?? outsideAppointmentId, withal: { practitioner: true } };
      const { data } = await Axios.post('/api/appointment/get_one', params);
      setAppointmentDate(data?.appointment.starts_at);
      setProvider(data?.appointment?.practitioner);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const { checkedRendered, hpForms, soapForms, advancedForms } = processSelectedForms({
    selected,
    rendered
  });

  const sendEmailToPatient = async () => {
    setLoading({ ...loading, email: true });
    try {
      let res = await Axios.post('/api/practice/medical_history/export', {
        medicalHistoryId: clinicalNote ? clinicalNote?.id : null,
        selection: selected,
        appointmentId
      });

      const { code, error = null } = res.data;

      switch (code) {
        case 0:
          showAlert({
            title: 'Export',
            message: 'Email sent successfully!',
            color: 'success',
            duration: 3000
          });
          setShowConfirmationModal(false);
          break;
        case 1:
          showAlert({
            title: 'Export',
            message: "There's been an error while sending the email. Please try again later.",
            color: 'danger',
            duration: 3000
          });
          break;
        default:
          showAlert({
            title: 'Export',
            message:
              error ??
              "There's been an unexpected error while sending the email. Please try again later.",
            color: 'danger',
            duration: 3000
          });
          break;
      }
    } catch (error) {
      showAlert({
        title: 'Export',
        message: 'There was an error while sending the email!',
        color: 'danger',
        duration: 3000
      });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
    setLoading({ ...loading, email: false });
  };

  return (
    <RenderedContent
      isExport={isExport}
      showConfirmationModal={showConfirmationModal}
      setShowConfirmationModal={setShowConfirmationModal}
      renderedRef={renderedRef}
      practice={practice}
      provider={provider}
      patient={patient || patientFromId?.patient}
      appointmentDate={appointmentDate}
      checkedRendered={checkedRendered}
      hpForms={hpForms}
      soapForms={soapForms}
      advancedForms={advancedForms}
      sendEmailToPatient={sendEmailToPatient}
      loading={loading}
    />
  );
};

export default Rendered;
