import React from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const TableHeaderCheckbox = (params) => {
  const { api } = params;
  const allRowsSelected = api.getSelectedNodes().length === api.getDisplayedRowCount();

  const handleChange = (e) => {
    const checked = e.target.checked;

    api.forEachNode((node) => node.setSelected(checked));
  };

  return (
    <div className="flex h-full items-center">
      <Checkbox isChecked={allRowsSelected} onChange={handleChange} id={`check-all`} />
    </div>
  );
};
export default TableHeaderCheckbox;
