import { Honeybadger } from '@honeybadger-io/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import LogRocket from 'logrocket';
import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import AutoLogout from 'components/AutoLogout/AutoLogout';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { Toaster } from 'react-hot-toast';

import { useRecoilValue } from 'recoil';
import AutoUpdate from './components/AutoUpdate/AutoUpdate';
import Utility from './components/Utility';
import Socket from './components/sockets/socket';
import state from './components/state';
import { sanitizeResponse } from './lib/helpers/utility';
import { Routes } from './routes/Routes';
import './styles/main.scss';

const queryClient = new QueryClient();

const App = () => {
  if (process.env.ENV === 'production') {
    LogRocket.init('u10eni/myriad-health', {
      dom: {
        inputSanitizer: true,
        textSanitizer: true
      },
      network: {
        responseSanitizer: (response) => {
          try {
            let status = response.status;
            if (response?.body) {
              let body = JSON.parse(response.body || '{}');
              response.body = JSON.stringify(sanitizeResponse(body));
              let code = body?.code;
              switch (code) {
                case 1:
                  status = 500;
                  break;
                case 2:
                  status = 403;
                  break;
              }
              response.status = status;
            }
          } catch (error) {
            // console.error(error);
          }
          return response;
        },
        requestSanitizer: (request) => {
          try {
            if (request?.body) {
              request.body = JSON.stringify(sanitizeResponse(JSON.parse(request.body || '{}')));
            }
          } catch (error) {
            // console.error(error);
          }
          return request;
        }
      }
    });
  }

  if (
    (process.env.ENV === 'development' || process.env.ENV === 'production') &&
    window.__APP_VERSION__
  ) {
    datadogRum.init({
      applicationId: 'cb6d45cb-947a-48d9-9bb8-d1d15a109db0',
      clientToken: 'pubbc5c7e531a26bd07f2c4b08e6a90bc77',
      site: 'datadoghq.com',
      service: 'myriad-health',
      env: process.env.ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      version: window.__APP_VERSION__,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      traceContextInjection: 'all',
      allowedTracingUrls: [(url) => url.startsWith(process.env.REACT_APP_BASE_API_URL)],
      traceSampleRate: 100,
      beforeSend: (event, context) => {
        try {
          if (event.type !== 'resource' || event.resource.type !== 'xhr') {
            return true;
          }
          // Capture response headers if available
          const xhr = context?.xhr;
          if (!xhr) {
            return true;
          }
          event.context.responseHeaders = Object.fromEntries(
            xhr
              .getAllResponseHeaders()
              .split('\r\n')
              .filter((header) => header)
              .map((header) => header.split(': ').map((item) => item.trim()))
          );
        } catch (e) {
          console.log(e);
        }
        return true;
      }
    });
  }

  console.log('window.__APP_VERSION__: ', window.__APP_VERSION__);
  console.log('process.env.ENV: ', process.env.ENV);

  LogRocket.getSessionURL((sessionURL) => {
    Honeybadger.beforeNotify((notice) => {
      notice.context.sessionURL = sessionURL;
    });
  });

  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID
  });

  const hasBeenAuthChecked = useRecoilValue(state.hasBeenAuthChecked);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Utility />
        <Socket />
        {hasBeenAuthChecked.checked && <Routes />}
        <Toaster position="bottom-right" />
        {/*{process.env.ENV !== 'local' && <AutoLogout />}*/}
        <AutoLogout />
        <AutoUpdate />
      </ErrorBoundary>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default App;
