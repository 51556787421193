import React from 'react';

const Breadcrumbs = ({ breadcrumbs, onClickBreadcrumb }) => {
  return (
    <nav className="flex items-center">
      {breadcrumbs.map((breadcrumb, index) => (
        <div key={breadcrumb.path} className="flex cursor-pointer">
          {breadcrumb?.icon}
          <div
            onClick={() => onClickBreadcrumb(index)}
            className="text-gray-500 hover:text-blue-500">
            {breadcrumb.label}
          </div>
          {index < breadcrumbs.length - 1 && <span className="px-1 text-gray-400">{'>'}</span>}
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
