import React from 'react';
import Patients from '../Patients';
const PatientDashboardPanel = (props) => {
  return (
    <div className="p-4">
      <Patients {...props} />
    </div>
  );
};

export default PatientDashboardPanel;
