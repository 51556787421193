import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import ProblemManifestationDefinition from './ProblemManifestationDefinition';

const ProblemManifestationDefinitionSection = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.problemManifestationDefinition?.title}
      id={components?.problemManifestationDefinition?.id}
      disabled={clinicalNote?.locked}>
      <ProblemManifestationDefinition sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ProblemManifestationDefinitionSection;
