import paymentPattern from 'assets/Patterns/payment_pattern.svg';
import Button from 'components/shared/Buttons/Button';
import React from 'react';
import illustration from './icons/payment_fail.svg';
import Icon from 'components/shared/Icon/Icon';
import { showAlert } from 'components/shared/Alert/Alert';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const PayFailure = ({
  payWithAlternative,
  promptPayment,
  onCancel,
  errorMessage,
  showTryAgain = true
}) => {
  const { device } = useUIContext();
  return (
    <div className="flex h-[100vh] w-full flex-col items-center !p-20">
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full">
        <img src={paymentPattern} className="h-full w-full"></img>
      </div>
      <img alt="Payment Success" className="w-64" src={illustration}></img>
      <p className="mt-3 text-center text-2xl font-medium text-red-600">Payment failed</p>
      <p className="mt-2 text-center text-red-500">
        We couldn’t process your payment, try again or enter card information manually.
      </p>
      {errorMessage && <p className="text-center text-red-500">{errorMessage}</p>}
      {showTryAgain && (
        <div className="mt-3 flex items-center gap-2">
          <Button
            text="Try again"
            icon="new-reset"
            iconColor="white"
            iconIsStroke
            onClick={() => {
              if (promptPayment) {
                promptPayment();
              } else {
                showAlert({
                  color: 'warning',
                  title: 'Please select alternative payment',
                  position:
                    device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
                });
              }
            }}
          />
          <Button
            text="Enter card manually"
            icon="credit-card-income"
            iconColor="white"
            primary
            onClick={payWithAlternative && payWithAlternative}
          />
        </div>
      )}
      <div className="flex h-full flex-1 items-end">
        <div className="mb-2 flex cursor-pointer gap-1" onClick={() => onCancel && onCancel()}>
          <Icon icon="new-close-sm" className="!cursor-pointer" color="neutral" stroke />
          <p className="ml-2 text-base text-neutral-600">Close</p>
        </div>
      </div>
    </div>
  );
};

export default PayFailure;
