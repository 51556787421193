import React from 'react';
import Select from '../../../../shared/Select/Select';
import { currentPractice } from '../../../practiceState';
import { useRecoilValue } from 'recoil';

const Practitioner = ({ formik, practitioners }) => {
  const currPractice = useRecoilValue(currentPractice);

  const patientLink = formik.values.patient?.practicePatientLink?.virtual_link;
  const practiceLink = currPractice?.display_settings?.appointment?.virtual_link;

  return (
    <div>
      <p className="text-sm font-500">
        Practitioner <span className="inline text-danger-600">*</span>
      </p>
      <Select
        placeholder="Type and select a practitioner"
        value={practitioners.find(
          (practitioner) => practitioner?.value === formik?.values?.practitioner_id
        )}
        onChange={(value) => {
          formik.setFieldValue('practitioner_id', value?.value);
          formik.setFieldValue('virtual_link', patientLink || value?.virtual_link || practiceLink);
        }}
        options={practitioners}
        inputId="practitionerSelect"
        error={formik.errors.practitioner_id}
        className="!mt-2"
        isImage
      />
    </div>
  );
};

export default Practitioner;
