import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ia, pm } from '../../../lib/helpers/utility';
import state from '../../state';
import ColorPickerRgb from '../ColorPickerRgb/ColorPickerRgb';
import { withErrorBoundary } from '../Error/Boundary';
import { Popover } from '@headlessui/react';
import Icon from '../Icon/Icon';

const StaffEntry = ({ member, onEdit, index, showSupervisorSettings }) => {
  const p = useRecoilValue(state.permissions);
  const roles = useRecoilValue(state.roles);
  const [roleName, setRoleName] = useState('rool');

  useEffect(() => {
    let newRoleName = member.role_id;
    if (ia(roles.data)) {
      const retrievedRole = roles.data.find((v) => v.id === member.role_id);
      if (retrievedRole) {
        newRoleName = retrievedRole.name;
      } else {
        newRoleName += ` ⚠️`;
      }
    }
    setRoleName(newRoleName);
  }, [roles]);

  return (
    <tr key={`staff-entry-index-${index}`}>
      <td>{`${member.f_name} ${member.m_name ? `${member.m_name} ` : ''}${member.l_name}`}</td>
      <td>{member.email}</td>
      <td>{roleName}</td>
      <td>{member.status}</td>
      <td>
        <div className="h-8 w-8 rounded-sm border border-solid border-neutral-300 !p-1">
          <div style={{ background: member.color }} className="h-full w-full" />
        </div>
      </td>
      <td>
        <Popover className="relative">
          {({ open, close }) => (
            <div>
              <Popover.Button
                data-qa={`staff-${index}`}
                className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                  !open && 'hover:!bg-white'
                } ${open && '!bg-primary-700 transition-all'}`}>
                <Icon
                  icon={'three-dots'}
                  className="flex cursor-pointer"
                  color={open ? 'white' : 'black'}
                />
              </Popover.Button>

              <Popover.Panel
                className={`absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white ${
                  index ? '!-top-[90px]' : 'top-10'
                } !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`}>
                {pm(p, 'member.update') && (
                  <div
                    data-qa="edit-staff-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    id={member.user_id}
                    onClick={(e) => {
                      onEdit(e);
                      close();
                    }}>
                    <Icon icon={'new-edit'} color={'primary'} />
                    <div className="ml-1 text-sm text-primary-900">Edit</div>
                  </div>
                )}

                <div
                  data-qa="supervisor-settings-btn"
                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                  onClick={() => {
                    showSupervisorSettings(member);
                    close();
                  }}>
                  <Icon icon={'trash'} color={'primary'} />
                  <div className="ml-1 text-sm text-primary-900">Make supervisor</div>
                </div>
              </Popover.Panel>
            </div>
          )}
        </Popover>
      </td>
      {/* <td>
        {pm(p, 'member.update') && (
          <Button id={member.user_id} warning onClick={onEdit} data-qa={`staff-edit-${index}`}>
            Edit
          </Button>
        )}
      </td> */}
    </tr>
  );
};

export default withErrorBoundary(StaffEntry);
