import { currentPractice } from 'components/practice/practiceState';
import Select from 'components/shared/Select/Select';
import { Capitalize, formatDate, formatPhoneNumber, ia, search } from 'lib/helpers/utility';
import _, { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { mapInsuranceCompanyList, selectInsuranceCompany } from '../lib';
import { insurancePatientRelation } from 'constants';
import moment from 'moment';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PatientInfo = ({ superbill, states, patient, formik }) => {
  const practice = useRecoilValue(currentPractice);
  const [insuranceCompanyList, setInsuranceCompanyList] = useState([]);
  const { values: claim, setFieldValue } = formik;
  useEffect(() => {
    if (superbill) {
      handleGetInsuranceCompanyList();
    }
  }, []);

  const handleGetInsuranceCompanyList = async () => {
    const data = await mapInsuranceCompanyList({ superbill, states, patient });
    setInsuranceCompanyList(data);
  };

  const findPatientRelation = (value) => {
    const relation = insurancePatientRelation?.find((relation) => {
      if (relation?.value == value) {
        return relation;
      }
    });

    return relation && relation?.label;
  };

  return (
    <div className="block rounded-md bg-white p-[1.25rem] shadow">
      <label className="text-xl font-400 text-neutral-800">Patient info</label>
      <div className="flex sm:flex-col md:items-center">
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>First Name</label>
          <div>{superbill.patient.f_name}</div>
        </div>
        {superbill.patient.m_name && (
          <div className="block flex-shrink flex-grow p-3">
            <label className='font-500 pb-1'>Middle Name</label>
            <div>{superbill.patient.m_name}</div>
          </div>
        )}
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Last Name</label>
          <div>{superbill.patient.l_name}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Date of birth</label>
          <div>{formatDate(superbill.patient.dob)}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Gender</label>
          <div>{Capitalize(superbill.patient.gender)}</div>
        </div>
      </div>
      <div className="flex sm:flex-col md:items-center">
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Address</label>
          <div>{superbill.patient?.userAddress?.fullAddress}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Phone</label>
          <div>{formatPhoneNumber(superbill.patient.phone)}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Email</label>
          <div>{superbill.patient.email}</div>
        </div>
      </div>
      <div className="flex sm:flex-col md:items-center">
        {ia(insuranceCompanyList) && (
          <div className="block flex-shrink flex-grow p-3 print:hidden">
            <label className='font-500 pb-1'>Insurance Company</label>
            <Select
              options={insuranceCompanyList}
              onChange={(e) => selectInsuranceCompany(e, setFieldValue, states)}
              value={{ label: claim?.payer_name, value: claim?.payerid }}
              isClearable
            />
          </div>
        )}
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Insurance name</label>
          <div>{claim?.payer_name}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Insurance ID</label>
          <div>{claim?.ins_number}</div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className='font-500 pb-1'>Relation to patient</label>
          <div>{isEmpty(claim?.pat_rel) ? 'N/A' : findPatientRelation(claim.pat_rel)}</div>
        </div>
      </div>
      {!isEmpty(claim?.pat_rel) && claim.pat_rel !== '18' && (
        <div className="flex sm:flex-col md:items-center">
          <div className="block flex-shrink flex-grow p-3">
            <div>
              <label className='font-500 pb-1'>Relation Name: </label>
              <div>{`${claim.ins_name_f} ${claim.ins_name_m} ${claim.ins_name_l}`}</div>
            </div>
          </div>
          <div className="block flex-shrink flex-grow p-3">
            <div>
              <label className='font-500 pb-1'>Relation Date: </label>
              <div>{formatDate(claim?.ins_dob, practice.timezone)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(PatientInfo);
