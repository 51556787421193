import React, { useState } from 'react';
import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import { ia } from 'lib/helpers/utility';
import Tippy from '@tippyjs/react';
import { isArrayWithoutEmptyValues } from '../../../lib/helpers';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import cs from 'classnames';

const Card = ({
  keyField,
  title,
  hasEdit,
  values,
  description,
  label,
  view,
  handleFormView,
  automation
}) => {
  const { packet, setPacket, setBreadCrumbs, formik } = usePacketBuilderContext();
  const [isChecked, setIsChecked] = useState({
    providers: ia(formik.values['provider_ids']) || false,
    services: ia(formik.values['service_ids']) || false,
    tags: ia(formik.values['tag_ids']) || false,
    forms: ia(formik.values['form_ids']) || false,
    patientCreation: formik.values['patient_creation']
  });

  const handleSwitch = (e) => {
    setIsChecked((prev) => ({
      ...prev,
      [keyField]: !isChecked[keyField]
    }));
    if (!e) {
      setPacket((prevPacket) => ({
        ...prevPacket,
        [keyField]: keyField === 'patient_creation' ? !packet[keyField] : []
      }));

      formik.setFieldValue(keyField, keyField === 'patient_creation' ? !packet[keyField] : []);
      formik.setFieldValue(`automation.${automation}`, false);
    } else {
      setPacket((prevPacket) => ({
        ...prevPacket,
        [keyField]: keyField === 'patient_creation' ? !packet[keyField] : ['']
      }));
      formik.setFieldValue(keyField, keyField === 'patient_creation' ? !packet[keyField] : []);
      formik.setFieldValue(`automation.${automation}`, true);
    }
  };

  const handleShowStep = () => {
    if (view) {
      setBreadCrumbs((prevBreadCrumbs) =>
        prevBreadCrumbs.map((breadcrumb) => ({
          ...breadcrumb,
          active: breadcrumb.view === view
        }))
      );
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div
        className={cs(
          'flex flex-col rounded-[16px] border border-solid border-neutral-100 p-3',
          isChecked[view] ? 'bg-white' : 'bg-neutral-50',
          view === 'forms' ? '!h-full gap-3' : 'min-h-[140px] justify-between'
        )}>
        <div className="flex flex-col gap-2">
          <div className="flex min-h-[34px] justify-between">
            <div className="flex items-center justify-evenly gap-2">
              <Switch
                onChange={(e) => handleSwitch(e)}
                checked={keyField === 'patient_creation' ? packet[keyField] : ia(packet[keyField])}
              />
              <p className="text-sm font-500 text-primary-900">{title}</p>
            </div>
            {hasEdit && ia(values) && (
              <div
                className="flex cursor-pointer items-center bg-primary-25 p-2 text-center"
                onClick={handleShowStep}>
                <Icon icon="new-edit-v3" className="cursor-pointer" />
              </div>
            )}
          </div>
          {description && <p className="text-[12px] font-400 text-neutral-600">{description}</p>}
        </div>
        <div className="flex flex-col gap-2">
          {keyField !== 'patient_creation' &&
            isArrayWithoutEmptyValues(values) &&
            label &&
            isChecked[view] && <p className="text-[12px] font-600 text-neutral-500">{label}</p>}
          {keyField !== 'patient_creation' &&
            isArrayWithoutEmptyValues(values) &&
            isChecked[view] &&
            view !== 'forms' && (
              <div className="flex gap-[6px]">
                {values.slice(0, 3).map((item, index) => (
                  <div key={index} className="rounded-[6px] bg-primary-25 px-[8px] py-[6px]">
                    <p className="text-[12px] font-500 text-primary-500">{item?.label}</p>
                  </div>
                ))}
                {values.length > 3 && (
                  <Tippy
                    content={values.slice(3).map((item, idx) => (
                      <p key={idx} className="font-300 relative text-[12px] text-white">
                        {item?.label}
                      </p>
                    ))}
                    className="tippy-dark"
                    placement="top">
                    <div className="flex items-center">
                      <p className="relative rounded-[6px] bg-primary-25 px-2 py-[6px] text-[12px] font-500 text-primary-500">
                        +{values.length - 3}
                      </p>
                    </div>
                  </Tippy>
                )}
              </div>
            )}
          {view === 'forms' && isArrayWithoutEmptyValues(values) && isChecked[view] && (
            <div className="flex flex-wrap gap-2">
              {values.map((item, index) => (
                <div
                  key={index}
                  className="flex cursor-pointer gap-2 rounded-[6px] bg-primary-25 p-2"
                  onClick={() => handleFormView(item)}>
                  <p className="text-[12px] font-500 text-primary-500">{item?.label}</p>
                  <Icon icon="new-eye" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(Card);
