import Button from 'components/shared/Buttons/Button';
import {
  usePatientBalanceContextApi,
  usePatientBalanceContextData
} from 'lib/context/PatientBalanceContext/PatientBalanceContext';
import React from 'react';
import { FooterButtons } from './FooterButtons';

const PatientBalanceFooter = ({
  disabled,
  setShowModal,
  handleWriteOff,
  handleUnAllocatedAmount
}) => {
  const { setFormData, setActiveTab, setActiveMode: setMode } = usePatientBalanceContextApi();
  const { activeMode: mode, isSubmitButtonDisabled } = usePatientBalanceContextData();

  const handleButtons = (m, i) => {
    setMode({ ...mode, [m]: false });
    setFormData(null);
    setActiveTab(i);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 flex w-full justify-end border-x-0 border-b-0 border-t border-solid border-neutral-100 bg-white !p-4">
      {mode?.writeoff && (
        <FooterButtons
          handleButtons={handleButtons}
          tabIndex={0}
          mode="writeoff"
          text="Write Off"
          handle={handleWriteOff}
          disabled={disabled || isSubmitButtonDisabled}
        />
      )}

      {mode?.allocate && (
        <FooterButtons
          handleButtons={handleButtons}
          tabIndex={1}
          mode="allocate"
          text="Allocate"
          handle={handleUnAllocatedAmount}
          disabled={disabled || isSubmitButtonDisabled}
        />
      )}

      {!mode?.allocate && !mode?.writeoff && (
        <Button
          outlined
          color="neutral"
          text="Close"
          onClick={() => setShowModal(false)}
          data-qa="close-btn"
        />
      )}
    </div>
  );
};

export default PatientBalanceFooter;
