import React from 'react';
import { formatDate, ia } from 'lib/helpers/utility';
import { otherForms } from './constants';
import { getPatients } from 'api/Patients';
import Actions from '../components/Actions';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import FormsTippy from '../components/FormsTippy';
import TableCheckbox from '../../TableCheckbox';
import TableHeaderCheckbox from '../../TableHeaderCheckbox';

const handleForms = (value) => {
  let forms = [];
  if (ia(value?.data?.forms)) {
    value?.data?.forms?.forEach((form) => {
      const matchedForm = otherForms.find(
        (otherForm) => otherForm?.id === form?.id || otherForm?.altId === form?.id
      );
      if (matchedForm) {
        forms.push(matchedForm?.name);
      }
    });
  }
  if (ia(value?.form_ids)) {
    value?.form_ids?.forEach((formId) => {
      forms.push(formId?.label);
    });
  }
  return forms;
};

const handleCompletedForms = (value) => {
  const completedArray = [];

  value?.data?.forms
    .filter((form) => form?.completed)
    .forEach((form) => {
      const matchedForm = otherForms.find(
        (otherForm) => otherForm?.id === form?.id || otherForm?.altId === form?.id
      );
      if (matchedForm) {
        completedArray.push(matchedForm?.name);
      }
    });
  value?.data?.forms
    .filter((form) => form?.completed)
    .forEach((form) => {
      const matchedForm = value?.form_ids.find((f) => f.value === form.id);
      if (matchedForm) {
        completedArray.push(matchedForm?.label);
      }
    });

  return completedArray;
};

const handleUnCompletedForms = (value) => {
  const unCompletedArray = [];

  value?.data?.forms
    .filter((form) => !form.completed)
    .forEach((form) => {
      const matchedForm = otherForms.find(
        (otherForm) => otherForm?.id === form?.id || otherForm?.altId === form?.id
      );
      if (matchedForm) {
        unCompletedArray.push(matchedForm?.name);
      }
    });
  value?.data?.forms
    .filter((form) => !form?.completed)
    .forEach((form) => {
      const matchedForm = value?.form_ids.find((f) => f.value === form.id);
      if (matchedForm) {
        unCompletedArray.push(matchedForm?.label);
      }
    });

  return unCompletedArray;
};

const getCountOfForms = (value) => {
  if (!value?.data?.forms || !ia(value.data.forms)) {
    return '0/0';
  }
  let completedCount = 0;

  value?.data?.forms.forEach((form) => {
    if (form.completed) {
      completedCount++;
    }
  });
  return `${completedCount}/${value?.data?.forms?.length}`;
};

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    label: p?.fullName,
    value: p?.id
  }));
};

export const defaultColumns = [
  {
    field: 'checkbox',
    headerName: '',
    cellRenderer: TableCheckbox,
    headerComponent: TableHeaderCheckbox,
    maxWidth: 60,
    cellClass: 'no-border',
    resizable: false,
    sortable: false
  },
  {
    field: 'practitioner',
    headerName: 'Provider',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (ia(value) ? value.map((v) => `${v?.f_name} ${v?.l_name}`) : '')
  },
  {
    field: 'patient',
    headerName: 'Patient',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (ia(value) ? value.map((v) => `${v?.f_name} ${v?.l_name}`) : '')
  },
  {
    field: 'appointment.starts_at',
    headerName: 'Date of Service',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value?.starts_at ? formatDate(value.starts_at) : 'N/A')
  },
  {
    field: 'forms',
    headerName: 'Forms',
    cellClass: 'no-border',
    sortable: false,
    cellRenderer: (params) => <FormsTippy data={params?.data} formCallBack={handleForms} />
  },
  {
    field: 'no-completed-forms',
    headerName: 'No. of Completed Forms',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: (params) => getCountOfForms(params?.data)
  },
  {
    field: 'completed-forms',
    headerName: 'Completed Forms',
    cellClass: 'no-border',
    sortable: false,
    cellRenderer: (params) => <FormsTippy data={params?.data} formCallBack={handleCompletedForms} />
  },
  {
    field: 'incomplete-forms',
    headerName: 'Incomplete Forms',
    cellClass: 'no-border',
    sortable: false,
    cellRenderer: (params) => (
      <FormsTippy data={params?.data} formCallBack={handleUnCompletedForms} />
    )
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'expires_at',
    headerName: 'Link Expires On',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Created At',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  forms: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Forms',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  statuses: {
    type: 'checkbox',
    multiple: true,
    values: [],
    options: [],
    title: 'Status',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};

export const sendLink = async ({ type = '', navigate, packets = [], setSelectedRows }) => {
  const params = {
    packets: packets.map((packet) => ({
      link: `${process.env.REACT_APP_BASE_URL}/ia/${packet?.data?.token}`,
      patientId: packet?.data?.target_id
    })),
    type
  };

  await requestApi({
    navigate,
    url: `/api/form/instant_packet/resend_multiple`,
    params
  });

  setSelectedRows([]);
  showAlert({ title: `Successfully sent ${type}` });
};
