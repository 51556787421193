import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { deleteTask } from 'api/Tasks';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import PreviewTask from './PreviewTask';
import Popover from 'components/shared/Popovers/Popover/NewPopover';

const Actions = ({ data }) => {
  const [previewTaskModal, setPreviewTaskModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const showPreviewTaskModal = () => setPreviewTaskModal(true);
  const hidePreviewTaskModal = () => setPreviewTaskModal(false);

  const mutateDeleteTask = useMutation({
    mutationFn: (id) => deleteTask(navigate, { taskId: id }),
    onSuccess: (data) => {
      if (data?.code === 0) {
        queryClient.invalidateQueries({ queryKey: ['tasks'] });
        queryClient.refetchQueries({ queryKey: ['tasks-widget'] });
        showAlert({ title: 'Task Deleted successfully', color: 'success' });
      } else {
        showAlert({ title: 'Task deletion failed', color: 'danger' });
      }
    }
  });

  const options = [
    { label: 'Edit', onClick: showPreviewTaskModal, icon: 'new-edit' },
    { label: 'Delete', onClick: () => setShowConfirmationModal(true), icon: 'trash' }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {previewTaskModal && (
        <PreviewTask
          previewTaskModal={previewTaskModal}
          hidePreviewTaskModal={hidePreviewTaskModal}
          task={data}
        />
      )}
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Task"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this task?"
          handleOpen={showConfirmationModal}
          handleContinue={() => mutateDeleteTask.mutate(data.id)}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};
export default Actions;
