import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFormPacket } from 'api/FormPacket';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useNavigate } from 'react-router-dom';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { ia } from 'lib/helpers/utility';

const Actions = ({ data }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { formik, setIsEdit, setOpenModal, setPacket, setBreadCrumbs } = usePacketBuilderContext();

  let editInitialBreadcrumbs = [
    { title: 'Name&Automation', view: 'name_and_automation', active: true, isFinished: false },
    { title: 'Providers', view: 'providers', active: false, isFinished: false },
    { title: 'Services', view: 'services', active: false, isFinished: false },
    { title: 'Tags', view: 'tags', active: false, isFinished: false },
    { title: 'Forms', view: 'forms', active: false, isFinished: false }
  ];

  const { mutateAsync: mutateDeleteFormPacket } = useMutation({
    mutationFn: (data) => updateFormPacket(navigate, data)
  });

  const handleEdit = () => {
    setIsEdit(true);
    setBreadCrumbs(editInitialBreadcrumbs);
    setPacket({
      ...data
    });
    formik.setValues({
      ...formik.values,
      ...data,
      tag_ids: data.tag_ids.map((tag) => tag.value),
      provider_ids: data.provider_ids.map((provider) => provider.value),
      service_ids: data.service_ids.map((service) => service.value),
      form_ids: data.form_ids.map((form) => form.value),
      automation: {
        ...formik.values.automation,
        tags: ia(data.tag_ids),
        providers: ia(data.provider_ids),
        services: ia(data.service_ids),
        forms: ia(data.form_ids),
        patient_creation: data?.patient_creation
      }
    });
    setOpenModal(true);
  };

  const handleDelete = async () => {
    const variables = { fields: { status: 'deleted' }, formPacketId: data?.id };
    await mutateDeleteFormPacket(variables, {
      onSuccess: ({ code }) => {
        if (code === 0) {
          queryClient.invalidateQueries(['form_packets']);
          showAlert({ title: 'Packet Deleted successfully', color: 'success' });
        } else {
          showAlert({ title: 'Packet deletion failed', color: 'danger' });
        }
        setShowConfirmationModal(false);
      }
    });
  };

  const options = [
    { label: 'Edit', onClick: handleEdit, icon: 'new-edit' },
    { label: 'Delete', onClick: () => setShowConfirmationModal(true), icon: 'trash' }
  ];
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Form"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this packet?"
          handleOpen={showConfirmationModal}
          handleContinue={handleDelete}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
    </ErrorBoundary>
  );
};

export default withErrorBoundary(Actions);
