import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { createRef, default as React, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import useOutsideClick from '../../../../../lib/hooks/useOutsideClick';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Textarea from '../../../Textarea/Textarea';
import '../Custom.scss';
import { Vaccines as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import VaccinesRow from './VaccinesRow';

const Vaccines = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative = false,
  fromClinicalNote = false,
  onChange,
  setItemModal = () => {}
}) => {
  const initialObject = {
    vaccine: null,
    doses: null,
    lastDose: new Date().toISOString().slice(0, 10),
    calendarPopover: false,
    lastDoseDisabled: false,
    notSure: false,
    note: '',
    id: self.crypto.randomUUID()
  };
  const [vaccines, setVaccines] = useState(initialValue);
  const [prevVaccines, setPrevVaccines] = useState(initialValue);
  const [elRefs, setElRefs] = useState([]);
  const [currentRowIdx, setCurrentRowIdx] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const generalNoteRef = useRef(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.vaccines?.advancedForm
      : true;
  const textAreaRef = useRef();
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setVaccines(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setVaccines(defaultValue);
      }
    } else {
      setVaccines(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(vaccines?.row?.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [vaccines?.row]);

  useEffect(() => {
    notifyChange(vaccines);
  }, [vaccines]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setVaccines;
    }
  }, []);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'Vaccines', type: 'custom' } });
    }
  };

  const syncVaccinesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ vaccinesObject: updatedObject });
    } else {
      setVaccines(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();

    const shouldStopProcess = doEmptyFormExist({
      newForm: initialObject,
      forms: vaccines?.row
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Vaccines',
        message: 'Empty vaccine exists. Please fill in the details.',
        color: 'warning'
      });

      return;
    }

    const updatedObject = {
      row: vaccines?.row.unshift(initialObject),
      ...vaccines
    };

    syncVaccinesAndClinicalNotes(updatedObject);
  };
  const mutateDeleteVaccine = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Vaccine',
          message: error,
          color: 'warning'
        });
        return;
      }
      setShowConfirmModal(false);
      setVaccines(initialValue);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'vaccines'
        });
      showAlert({
        title: `Vaccine deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });

  const handleDeleteRow = (id) => {
    if (!formId && !fromCheckin) {
      showAlert({
        title: 'Vaccine',
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const vaccineRows = vaccines?.row || [];
    if (vaccineRows.length === 1) {
      setShowConfirmModal(true);
      return;
    } else {
      const updatedObject = {
        ...vaccines,
        row: vaccines?.row.filter((row) => row.id !== id)
      };
      syncVaccinesAndClinicalNotes(updatedObject);
    }
  };

  const handleNotSure = (id) => {
    const updatedObject = {
      ...vaccines,
      row: vaccines?.row.map((row) => {
        if (row.id === id) {
          return { ...row, notSure: !row.notSure, lastDoseDisabled: !row.notSure };
        } else {
          return row;
        }
      })
    };
    syncVaccinesAndClinicalNotes(updatedObject);
  };

  const handleOnChange = (key, event, id) => {
    const updatedObject = {
      ...vaccines,
      row: vaccines?.row.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: event };
        } else {
          return row;
        }
      })
    };
    syncVaccinesAndClinicalNotes(updatedObject);
  };

  useOutsideClick(elRefs[currentRowIdx], () => handlePopover(false, currentRowIdx));

  const handlePopover = (value, id) => {
    setVaccines({
      ...vaccines,
      row: vaccines?.row.map((row) => {
        if (row.id === id) {
          return { ...row, calendarPopover: value };
        } else if (row.id !== id) {
          return { ...row, calendarPopover: false };
        } else {
          return row;
        }
      })
    });
  };

  const handleGeneralNote = (event) => {
    const updatedObject = { ...vaccines, generalNote: event.target.value };
    syncVaccinesAndClinicalNotes(updatedObject);
  };

  const handleNoDetails = () => {
    const updatedObject = { ...vaccines, noDetails: !vaccines.noDetails };
    syncVaccinesAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    vaccinesObject = vaccines,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...vaccinesObject, narrative: event };

    setVaccines(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ vaccinesObject = vaccines, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: vaccinesObject,
      sectionData: 'row',
      sectionName: 'Vaccine',
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      vaccinesObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <div className="Vaccines CustomForms">
      <input type="hidden" value={JSON.stringify(vaccines)} ref={forwardedRef} />
      {isAdvancedForm && (
        <div className={cs('flex items-center gap-x-4', vaccines?.noDetails && 'justify-between')}>
          <Checkbox
            data-qa="no-known-vaccines"
            label="No known vaccines"
            name="noDetails"
            isChecked={vaccines?.noDetails}
            onChange={handleNoDetails}
          />
          {vaccines.noDetails && !fromCheckin && (
            <Icon
              icon="trash"
              className="cursor-pointer"
              onClick={() => {
                if (!formId) {
                  showAlert({
                    title: 'Vaccine',
                    message: 'Please create the form before deleting one.',
                    color: 'warning'
                  });
                  return;
                } else {
                  setShowConfirmModal(true);
                }
              }}
            />
          )}
        </div>
      )}
      {!vaccines.noDetails && isAdvancedForm && (
        <div className="dashed-top !mt-4">
          <div className="CustomForms__head">
            <Button
              onClick={handleAddRow}
              text="Add vaccine"
              transparent
              iconRight="add-circle"
              data-qa="add-vaccine"
              textClassName="text-primary-500"
            />
          </div>
          <div className="CustomForms__body">
            {vaccines?.row?.map((item, idx) => (
              <VaccinesRow
                key={item.id}
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                index={idx}
                setCurrentRowIdx={setCurrentRowIdx}
                onChange={handleOnChange}
                fromCheckin={fromCheckin}
                vaccines={vaccines}
                onChangePopover={handlePopover}
                forwardedRef={elRefs[idx]}
                onCheckNotSure={handleNotSure}
                onDelete={handleDeleteRow}
                length={vaccines?.row.length}
                {...item}
              />
            ))}
            <div className="dashed-top"></div>
            <div className="CustomForms__row m-0">
              <Textarea
                className="w-full"
                label="General note"
                placeholder="Add note here"
                id="generalNote"
                data-qa="general-note"
                name="generalNote"
                value={vaccines.generalNote}
                onChange={(event) => handleGeneralNote(event)}
                disabled={disabled}
                transcribing
                forwardedRef={generalNoteRef}
              />
            </div>
          </div>
        </div>
      )}
      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setVaccines}
            restData={{
              className: 'w-full',
              label: 'Vaccines Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-vaccines',
              'data-qa': 'narrative-vaccines',
              name: 'Narrative-vaccines',
              value: vaccines?.narrative,
              forwardedRef: textAreaRef,
              formName: 'vaccines'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}
      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          submitForm({
            ...customFormsParams,
            formId,
            currentFormData: vaccines,
            defaultState: prevVaccines,
            type: 'vaccines'
          });
          setPrevVaccines(vaccines);
        }}></button>
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Vaccine"
          icon="trash"
          message="Are you sure you want to delete this form?"
          handleContinue={() => mutateDeleteVaccine.mutate()}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default Vaccines;
