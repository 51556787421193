import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import Icon from '../../shared/Icon/Icon';
import { usePatients } from '../../../lib/hooks/queries/patients/usePatients';
import ProfilePicture from '../../Profile/ProfilePicture';
import Input from '../../shared/Input/Input';
import Checkbox from '../../shared/Checkbox/Checkbox';

const KioskPatientSelect = ({ kiosk, selected, setSelected }) => {
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  var timeOutId;
  useEffect(() => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setSearchTerm(search);
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const patientParams = {
    limit: 100,
    searchTerm,
    page: 1
  };

  const { data = {} } = usePatients({
    params: { ...patientParams },
    dependencies: [patientParams]
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-base font-medium text-primary-900">Search Patient</p>
        <Input
          inputId="searchPatients"
          placeholder="Search patients"
          rounded="full"
          icon={'new-search'}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>

      <div className="flex flex-col rounded-xl mt-3 overflow-hidden shadow-[0px_6px_16px_0px_#25313C0F]">
        <div className="bg-white !p-4 !px-14 rounded-t-xl">
          <p className="text-base text-primary-900">Patient</p>
        </div>
        {data?.patients?.map((patient, idx) => (
          <div
            key={patient?.id}
            className={cs(
              'flex cursor-pointer items-center gap-3 !p-3 !px-4 hover:!bg-primary-50 ',
              idx % 2 === 0 ? 'bg-[#F8FDFF]' : 'bg-white'
            )}
            onClick={() => {
              selected == patient?.id ? setSelected(null) : setSelected(patient?.id);
            }}>
            <Checkbox
              radio
              className="scale-75"
              isChecked={selected == patient?.id}
              data-qa={`patient-checkbox-${idx}`}
            />
            <ProfilePicture
              firstName={patient?.f_name}
              lastName={patient?.l_name}
              id={patient?.id}
              size={36}
            />
            <h3 className="text-base font-500 text-neutral-900">{patient.fullName}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KioskPatientSelect;
