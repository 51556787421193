import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  calculateTotalCharge,
  getPlaceOfService,
  handleCPTSearch
} from 'components/practice/charts/SuperBillPage/lib';
import { useClaim } from 'lib/hooks/queries/claims/useClaim';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Errors from './components/Errors';
import PrintPopover from './components/PrintPopover';
import { Procedure } from './components/Procedure';
import { handleUpdateClaim } from './lib';
import { currentPractice } from 'components/practice/practiceState';
import { Capitalize, ia, iaRa } from 'lib/helpers/utility';
import {
  employmentType,
  frequencyCode,
  genders,
  insurancePatientRelation,
  marital_status,
  tax_id_types,
  yesAndNoOptions
} from 'constants';
import { getServices } from 'api/Service';
import { handleSelectCPT } from 'components/practice/charts/SuperBillPage/lib/handleSelectCPT';

const CMS1500 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const practice = useRecoilValue(currentPractice);

  const pathnameParts = location.pathname.split('/');
  const claimID = pathnameParts[pathnameParts.length - 1];

  const { data, isLoading: claimLoading } = useClaim({
    params: { id: claimID },
    dependencies: [claimID]
  });
  const initialClaim = data?.claim || {};

  const [claim, setClaim] = useState(initialClaim);
  const [showPayerAddress, setShowPayerAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    data: addressData,
    isLoading,
    isFetching
  } = usePayerAddress({
    params: { payerId: claim?.payerid, withCount: true },
    dependencies: [claim?.payerid]
  });
  const address = addressData?.address;

  useEffect(() => {
    if (data?.claim) {
      setClaim(data?.claim);
    }
  }, [data]);

  const hasBaseUnit = iaRa(claim?.procedures).some((item) => item.base_unit);

  const updateClaim = (event, key) => {
    const field = event?.target?.name;
    let value = event?.target?.value;

    const updatedClaimObj = {
      ...claim,
      [field ?? key]: value ?? event.label ?? event ?? null
    };
    setClaim(updatedClaimObj);
  };

  const handleBack = () => {
    return location.key !== 'default' ? navigate(-1) : navigate('..');
  };

  const mutateUpdateClaim = useMutation({
    mutationFn: () => handleUpdateClaim(navigate, claim, initialClaim),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
      handleBack();
    },
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          queryClient.invalidateQueries(['claim']);
          showAlert({
            title: `Claim submitted successfully!`,
            color: 'success'
          });
          break;
        case 2:
          showAlert({
            title: `Failed to update claim!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to update claim!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });
  const selectCPT = async (e, index) => {
    const data = await getServices(navigate, { cpt: e.value });
    const service = data?.services;

    const updatedProcedures = handleSelectCPT({
      procedures: claim?.procedures,
      index,
      service,
      e,
      practice,
      claim
    });

    setClaim((prevClaim) => ({
      ...prevClaim,
      procedures: updatedProcedures,
      total_charge: calculateTotalCharge(updatedProcedures)
    }));
  };

  const addCPTRow = () => {
    let procedures = ia(claim?.procedures) ? [...(claim?.procedures || [])] : [];

    procedures?.push({
      code: null,
      modifiers: null,
      charge: 0,
      diagnosis: null,
      from_date: moment.utc(claim?.superbill?.encounter.appointment.starts_at).toDate(),
      thru_date: moment.utc(claim?.superbill?.encounter.appointment.starts_at).toDate(),
      narrative: '',
      units: '1',
      place_of_service: getPlaceOfService(claim?.superbill, practice)
    });
    setClaim({ ...claim, procedures });
  };

  return (
    <Modal
      slideFromRight
      isOpen
      handleClose={handleBack}
      buttonText="Done"
      title="Claim"
      className="w-[100%]"
      headerClassName="z-10"
      headButton={
        <div className="flex w-full items-center justify-end">
          <PrintPopover claim={claim} address={address} wrapperClassName="!mr-4" />
        </div>
      }
      footer={
        <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:gap-2">
          <Button
            text="Cancel"
            onClick={handleBack}
            color="neutral"
            transparent
            outlined
            data-qa="cancel-btn"
          />
          {claim?.state !== 'accepted' && (
            <Button
              data-qa="update-btn"
              onClick={() => mutateUpdateClaim.mutate()}
              text={claim?.state === 'ready' ? 'Update' : 'Update & mark ready to submit'}
              loading={loading}
            />
          )}
        </div>
      }>
      {claimLoading || isLoading || isFetching ? (
        <Skeleton />
      ) : (
        <div className="p-[24px]">
          {claim?.state === 'rejected' && <Errors />}
          <div className="grid grid-cols-3 gap-4">
            <div className="grid gap-4">
              <Input
                data-qa="payer-name"
                label="1. Payer name"
                placeholder="Payer name"
                name="payer_name"
                value={claim?.payer_name}
                onChange={updateClaim}
              />
              <div className="grid gap-4">
                <Checkbox
                  data-qa="show-payer-address"
                  label="Show payer address"
                  handleClick={() => setShowPayerAddress(!showPayerAddress)}
                  isChecked={showPayerAddress}
                />
              </div>
            </div>
            {showPayerAddress && (
              <div className="col-start-1 col-end-4 row-start-2 row-end-2 grid grid-cols-4 gap-4">
                <Input
                  data-qa="payer-address-1"
                  label="Payer Address 1"
                  placeholder="Payer Address 1"
                  value={claim?.payer_addr_1 ?? address?.address_ln_1}
                  name="payer_addr_1"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="payer-address-2"
                  label="Payer Address 2"
                  placeholder="Payer Address 2"
                  value={claim?.payer_addr_2 ?? address?.address_ln_2}
                  name="payer_addr_2"
                  onChange={updateClaim}
                />
                <div className="grid grid-cols-3 gap-2">
                  <Input
                    data-qa="payer-city"
                    label="City"
                    placeholder="City"
                    value={claim?.payer_city ?? address?.city}
                    name="payer_city"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="payer-st"
                    label="ST"
                    placeholder="ST"
                    value={claim?.payer_state ?? address?.state_label}
                    name="payer_state"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="payer-zip"
                    label="ZIP"
                    placeholder="ZIP"
                    value={claim?.payer_zip ?? address?.zip}
                    name="payer_zip"
                    onChange={updateClaim}
                  />
                </div>
                <Input
                  data-qa="payer-office-number"
                  label="Payer Office Number"
                  placeholder="Payer Office Number"
                  value={claim?.payer_office_number}
                  name="payer_office_number"
                  onChange={updateClaim}
                />
              </div>
            )}
            <div className="grid grid-cols-2 gap-2">
              <Input
                data-qa="payer-id"
                label="Payer id"
                placeholder="Payer id"
                value={claim?.payerid}
                name="payerid"
                onChange={updateClaim}
              />
              <Select
                inputId="select-payer-insurance"
                label="Insurance"
                placeholder="Insurance"
                name="insuranceType"
                value={{ value: 'primary', label: 'Primary' }}
                isClearable={false}
              />
            </div>
            <Input
              data-qa="insured-id-number"
              label="1a. Insured I.D. Number"
              placeholder="Insured I.D. Number"
              value={claim?.ins_number}
              name="ins_number"
              onChange={updateClaim}
            />
            <div>
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                2. Patient's name (L, F, M)
              </label>
              <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                <Input
                  data-qa="pat-last-name"
                  placeholder="Last name"
                  value={claim?.pat_name_l}
                  name="pat_name_l"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="pat-first-name"
                  placeholder="First name"
                  value={claim?.pat_name_f}
                  name="pat_name_f"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="pat-middle-name"
                  placeholder="Middle name"
                  className="w-[72px]"
                  value={claim?.pat_name_m}
                  name="pat_name_m"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 items-end gap-2">
              <DatePopover
                label="3. Patient's birth day / sex"
                labelClassName="w-max"
                placeholder="Birth day"
                value={claim?.pat_dob}
                onChange={(event) => updateClaim(event, 'pat_dob')}
              />
              <Select
                inputId="select-sex"
                placeholder="Type and select sex"
                value={
                  claim?.pat_sex
                    ? { value: claim?.pat_sex, label: Capitalize(claim?.pat_sex) }
                    : null
                }
                onChange={(event) => updateClaim(event, 'pat_sex')}
                options={genders}
              />
            </div>
            <div>
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                4. Insured's name (L, F, M)
              </label>
              <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                <Input
                  data-qa="ins-last-name"
                  placeholder="Last name"
                  value={claim?.ins_name_l}
                  name="ins_name_l"
                  onChange={updateClaim}
                />

                <Input
                  data-qa="ins-first-name"
                  placeholder="First name"
                  value={claim?.ins_name_f}
                  name="ins_name_f"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="ins-middle-name"
                  placeholder="Middle"
                  value={claim?.ins_name_m}
                  name="ins_name_m"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid gap-4">
              <Input
                data-qa="patients-address-1"
                label="5. Patient's Address"
                placeholder="Patient's Address"
                value={claim?.pat_addr_1}
                name="pat_addr_1"
                onChange={updateClaim}
              />
              <Input
                data-qa="patients-address-2"
                label="Address 2"
                placeholder="Address 2"
                value={claim?.pat_addr_2}
                name="pat_addr_2"
                onChange={updateClaim}
              />
              <div className="grid grid-cols-3 gap-2">
                <Input
                  data-qa="city"
                  label="City"
                  placeholder="City"
                  value={claim?.pat_city}
                  name="pat_city"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="state"
                  label="ST"
                  placeholder="ST"
                  value={claim?.pat_state}
                  name="pat_state"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="pat-zip-code"
                  label="ZIP"
                  placeholder="ZIP"
                  value={claim?.pat_zip}
                  name="pat_zip"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid h-max gap-4">
              <Select
                inputId="patients-relationship"
                label="6. Patient's relationship"
                placeholder="Type and select relationship"
                value={
                  claim?.pat_rel
                    ? iaRa(insurancePatientRelation).find((item) => item.value === claim?.pat_rel)
                    : null
                }
                onChange={(event) => updateClaim(event, 'pat_rel')}
                options={insurancePatientRelation}
                isClearable={false}
              />
              <Select
                inputId="marital-status"
                label="8. Marital status"
                placeholder="Type and select relationship"
                value={
                  claim?.pat_marital
                    ? { value: claim?.pat_marital, label: claim?.pat_marital }
                    : null
                }
                onChange={(event) => updateClaim(event, 'pat_marital')}
                options={marital_status}
              />
              <Select
                inputId="employement-status"
                label="Employment status"
                placeholder="Type and select status"
                value={
                  claim?.pat_employment
                    ? { value: claim?.pat_employment, label: claim?.pat_employment }
                    : null
                }
                onChange={(event) => updateClaim(event, 'pat_employment')}
                options={employmentType}
              />
            </div>
            <div className="grid gap-4">
              <Input
                data-qa="insureds-address"
                label="7. Insured's address"
                placeholder="7. Insured's address"
                value={claim?.ins_addr_1}
                name="ins_addr_1"
                onChange={updateClaim}
              />
              <Input
                data-qa="address-2"
                label="Address 2"
                placeholder="Address 2"
                value={claim?.ins_addr_2}
                name="ins_addr_2"
                onChange={updateClaim}
              />
              <div className="grid grid-cols-3 gap-2">
                <Input
                  data-qa="ins-city"
                  label="City"
                  placeholder="City"
                  value={claim?.ins_city}
                  name="ins_city"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="ins-state"
                  label="ST"
                  placeholder="ST"
                  value={claim?.ins_state}
                  name="ins_state"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="ins-zip"
                  label="ZIP"
                  placeholder="ZIP"
                  value={claim?.ins_zip}
                  name="ins_zip"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid h-max gap-4">
              <div>
                <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                  9. Secondary insured name (L, F, M)
                </label>
                <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                  <Input
                    data-qa="other-ins-last-name"
                    placeholder="Last name"
                    value={claim?.other_ins_name_l}
                    name="other_ins_name_l"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="other-ins-first-name"
                    placeholder="First name"
                    value={claim?.other_ins_name_f}
                    name="other_ins_name_f"
                    className="mt-auto grow"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="other-ins-middle-name"
                    placeholder="Middle name"
                    className="mt-auto w-[72px]"
                    value={claim?.other_ins_name_m}
                    name="other_ins_name_m"
                    onChange={updateClaim}
                  />
                </div>
              </div>
              <Input
                data-qa="secondaty-insureds-policy"
                label="a. Secondary insured's policy"
                placeholder="Secondary insured's policy"
                value={claim?.other_ins_number}
                name="other_ins_number"
                onChange={updateClaim}
              />
              <div className="grid grid-cols-2 items-end gap-2">
                <DatePopover
                  label="b. Secondary insured date of birth / sex"
                  labelClassName="w-max"
                  placeholder="Date of birth"
                  name="other_ins_dob"
                  value={claim?.other_ins_dob}
                  onChange={(event) => updateClaim(event, 'other_ins_dob')}
                />
                <Select
                  inputId="other-select-sex"
                  placeholder="Type and select sex"
                  value={
                    claim?.other_ins_sex
                      ? { value: claim?.other_ins_sex, label: claim?.other_ins_sex }
                      : null
                  }
                  onChange={(event) => updateClaim(event, 'other_ins_sex')}
                  options={genders}
                  isClearable={true}
                />
              </div>
              <DatePopover
                label="c. Primary payment date"
                labelClassName="w-max"
                placeholder="Primary payment date"
                value={claim?.other_ins_payment_date}
                onChange={(event) => updateClaim(event, 'other_ins_payment_date')}
              />
              <div className="grid grid-cols-[auto,1fr] gap-4">
                <Input
                  data-qa="secondary-payer-name"
                  label="d. Secondary payer name"
                  placeholder="Secondary payer name"
                  value={claim?.other_payer_name}
                  name="other_payer_name"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="other-payer-id"
                  label="Payer id"
                  placeholder="Payer id"
                  value={claim?.other_payerid}
                  name="other_payerid"
                  onChange={updateClaim}
                />
              </div>
              <Input
                data-qa="other-secondary-payer-claim-id"
                label="Seconday payer claim id"
                placeholder="Seconday payer claim id"
                value={claim?.other_claimfilingcode}
                name="other_claimfilingcode"
                onChange={updateClaim}
              />
            </div>
            <div className="grid h-max gap-4">
              <Select
                inputId="patient-condition-related"
                label="10. Patient condition related / a. Employment"
                placeholder="a. Employment"
                value={
                  claim?.employment_related
                    ? { value: claim?.employment_related, label: claim?.employment_related }
                    : null
                }
                onChange={(event) => updateClaim(event, 'employment_related')}
                options={yesAndNoOptions}
              />
              <div className="grid grid-cols-[3fr,2fr,2fr] gap-2">
                <Select
                  inputId="auto-accident"
                  label="b. Auto accident"
                  labelClassNam="w-max"
                  placeholder="Auto accident"
                  value={
                    claim?.auto_accident
                      ? { value: claim?.auto_accident, label: claim?.auto_accident }
                      : null
                  }
                  onChange={(event) => updateClaim(event, 'auto_accident')}
                  options={yesAndNoOptions}
                />
                <Input
                  data-qa="auto-accident-state"
                  label="State"
                  placeholder="State"
                  value={claim?.auto_accident_state}
                  name="auto_accident_state"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="accident-country"
                  label="Country"
                  placeholder="Country"
                  value={claim?.accident_country}
                  name="accident_country"
                  onChange={updateClaim}
                />
              </div>
              <Select
                inputId="other-accident"
                label="c. Other accident"
                labelClassNam="w-max"
                placeholder="Other accident"
                value={
                  claim?.other_accident
                    ? { value: claim?.other_accident, label: claim?.other_accident }
                    : null
                }
                onChange={(event) => updateClaim(event, 'other_accident')}
                options={yesAndNoOptions}
              />
              <Input label="10d. Claims codes" placeholder="Claims codes" data-qa="claims-codes" />
              <Select
                inputId="secondary-patient-relationship"
                label="9e. Secondary patient relationship"
                placeholder="Secondary patient relationship"
                value={
                  claim?.other_pat_rel
                    ? { value: claim?.other_pat_rel, label: claim?.other_pat_rel }
                    : null
                }
                onChange={(event) => updateClaim(event, 'other_pat_rel')}
                options={insurancePatientRelation}
              />
              <Input
                data-qa="secondary-payer-group-name"
                label="9f. Secondary payer group name"
                placeholder="Secondary payer group name"
                value={claim?.other_payer_group_name}
                name="other_payer_group_name"
                onChange={updateClaim}
              />
              <Input
                data-qa="secondary-payer-group-number"
                label="9g. Secondary payer group number"
                placeholder="Secondary payer group number"
                value={claim?.other_payer_group_number}
                name="other_payer_group_number"
                onChange={updateClaim}
              />
            </div>
            <div className="grid h-max gap-4">
              <Input
                data-qa="insureds-policy-group"
                label="11. Insured's policy group"
                placeholder="Insured's policy group"
                name="ins_group"
                value={claim?.ins_group}
                onChange={updateClaim}
              />
              <div className="grid grid-cols-2 items-end gap-2">
                <DatePopover
                  label="a. Insured's date of birth / sex"
                  labelClassName="w-max"
                  placeholder="Date of birth"
                  placementX="right-0"
                  value={claim?.ins_dob}
                  onChange={(event) => updateClaim(event, 'ins_dob')}
                />
                <Select
                  inputId="select-sex-genders"
                  placeholder="Type and select sex"
                  value={claim?.ins_sex ? { value: claim?.ins_sex, label: claim?.ins_sex } : null}
                  onChange={(event) => updateClaim(event, 'ins_sex')}
                  options={genders}
                  isClearable={true}
                />
              </div>
              <Input
                data-qa="ins-employers-name"
                label="b. Employer's name"
                placeholder="Employer's name"
                value={claim?.ins_employer}
                name="ins_employer"
                onChange={updateClaim}
              />
              <Input
                data-qa="insurance-plan-name"
                label="c. Insurance plan name"
                placeholder="Insurance plan name"
                value={claim?.ins_plan}
                name="ins_plan"
                onChange={updateClaim}
              />
              <div>
                <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                  Supervising provider name (L, F, M)
                </label>
                <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                  <Input
                    data-qa="last-name-2"
                    placeholder="Last name"
                    value={claim?.chg_supv_prov_name_l}
                    name="chg_supv_prov_name_l"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="first-name-2"
                    placeholder="First name"
                    value={claim?.chg_supv_prov_name_f}
                    name="chg_supv_prov_name_f"
                    className="mt-auto grow"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="middle-name-2"
                    placeholder="Middle name"
                    className="mt-auto w-[72px]"
                    value={claim?.chg_supv_prov_name_m}
                    name="chg_supv_prov_name_m"
                    onChange={updateClaim}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <Input
                  data-qa="supervising-id"
                  label="Supervising ID"
                  placeholder="Supervising ID"
                  value={claim?.chg_supv_prov_id}
                  name="chg_supv_prov_id"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="supervising-npi"
                  label="Supervising NPI"
                  placeholder="Supervising NPI"
                  value={claim?.chg_supv_prov_npi}
                  name="chg_supv_prov_npi"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <DatePopover
              label="14. Date of condition"
              labelClassName="w-max"
              placeholder="Date of condition"
              value={claim?.cond_date}
              onChange={(event) => updateClaim(event, 'cond_date')}
            />
            <div>
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                17. Referring physician name (L, F, M)
              </label>
              <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                <Input
                  data-qa="ref-name-l"
                  placeholder="Last name"
                  value={claim?.ref_name_l}
                  name="ref_name_l"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="ref-name-f"
                  placeholder="First name"
                  value={claim?.ref_name_f}
                  name="ref_name_f"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="ref-name-m"
                  placeholder="Middle name"
                  value={claim?.ref_name_m}
                  name="ref_name_m"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid grid-cols-[auto,1fr] gap-4">
              <Input
                data-qa="ref-npi"
                label="17a. Referring NPI"
                placeholder="Referring NPI"
                value={claim?.ref_npi}
                name="ref_npi"
                onChange={updateClaim}
              />
              <Input
                data-qa="ref-id"
                label="Other ID"
                placeholder="Other ID"
                value={claim?.ref_id}
                name="ref_id"
                onChange={updateClaim}
              />
            </div>
            <Input
              data-qa="claim-narrative"
              className="col-start-1 col-end-3"
              label="19. Claim narrative"
              placeholder=" Claim narrative"
              value={claim?.narrative}
              name="narrative"
              onChange={updateClaim}
            />
            <div>
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                18. Hospitalization dates
              </label>
              <div className="grid grid-cols-2 gap-2">
                <DatePopover
                  placeholder="From"
                  name="hosp_from_date"
                  placementX="right-0"
                  value={claim?.hosp_from_date}
                  onChange={(event) => updateClaim(event, 'hosp_from_date')}
                />
                <DatePopover
                  placeholder="To"
                  placementX="right-0"
                  name="hosp_thru_date"
                  value={claim?.hosp_thru_date}
                  onChange={(event) => updateClaim(event, 'hosp_thru_date')}
                />
              </div>
            </div>
            <div className="col-start-1 col-end-3">
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                21. Diagnosis or nature of illness or injury (relate to 24E by line)
              </label>
              <div className="grid grid-cols-4 gap-4">
                <Input
                  data-qa="diag-1"
                  className="flex gap-2"
                  label="A."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_1}
                  name="diag_1"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-2"
                  className="flex gap-2"
                  label="B."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_2}
                  name="diag_2"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-3"
                  className="flex gap-2"
                  label="C."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_3}
                  name="diag_3"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-4"
                  className="flex gap-2"
                  label="D."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_4}
                  name="diag_4"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-5"
                  className="flex gap-2"
                  label="E."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_5}
                  name="diag_5"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-6"
                  className="flex gap-2"
                  label="F."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_6}
                  name="diag_6"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-7"
                  className="flex gap-2"
                  label="G."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_7}
                  name="diag_7"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-8"
                  className="flex gap-2"
                  label="H."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_8}
                  name="diag_8"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-9"
                  className="flex gap-2"
                  label="I."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_9}
                  name="diag_9"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-10"
                  className="flex gap-2"
                  label="J."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_10}
                  name="diag_10"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-11"
                  className="flex gap-2"
                  label="K."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_11}
                  name="diag_11"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="diag-12"
                  className="flex gap-2"
                  label="L."
                  labelClassName="pt-[6px]"
                  placeholder="Diagnose"
                  value={claim?.diag_12}
                  name="diag_12"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div>
              <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                22. Resubmission code (Payer ICN)
              </label>
              <div className="grid gap-4">
                <div className="grid grid-cols-[1fr,2fr] gap-2">
                  <Select
                    inputId="code-select"
                    placeholder="Code"
                    value={
                      claim?.frequency_code
                        ? { value: claim?.frequency_code, label: claim?.frequency_code }
                        : null
                    }
                    onChange={(event) => updateClaim(event, 'frequency_code')}
                    options={frequencyCode}
                  />
                  <Input
                    data-qa="icn-dcn-1"
                    placeholder="ICN"
                    value={claim?.icn_dcn_1}
                    name="icn_dcn_1"
                    onChange={updateClaim}
                  />
                </div>
                <Input
                  data-qa="prior-auth"
                  label="23. Prior authorization number"
                  placeholder="Prior authorization number"
                  value={claim?.prior_auth}
                  name="prior_auth"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="referral-number"
                  label="Referral number"
                  placeholder="Referral number"
                  value={claim?.referral_number}
                  name="referral_number"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="clia-number"
                  label="Clia number"
                  placeholder="Clia number"
                  value={claim?.clia_number}
                  name="clia_number"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="col-start-1 col-end-4">
              <table className="primary-table">
                <thead>
                  <tr>
                    <th className="w-[300px] !p-1">24. A. Date(s) of service</th>
                    <th className="!p-1">NDC</th>
                    <th className="!p-1">B. Pos</th>
                    <th className="!p-1">C. Emg</th>
                    <th className="!p-1">D. Procedure</th>
                    <th className="!p-1">Modifier</th>
                    <th className="!p-1">E. Diag ref</th>
                    <th className="!p-1">F. $ Charges</th>
                    <th className="!p-1">G. Units</th>
                    {hasBaseUnit && (
                      <>
                        <th className="!p-1">Start time</th>
                        <th className="!p-1">End time</th>
                        <th className="!p-1">Calc Unit</th>
                      </>
                    )}
                    <th className="!p-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {ia(claim?.procedures) ? (
                    claim?.procedures?.map((item, index) => (
                      <Procedure
                        claim={claim}
                        setClaim={setClaim}
                        key={index}
                        procedure={item}
                        selectCPT={selectCPT}
                        hasBaseUnit={hasBaseUnit}
                        handleCPTSearch={(a, b) => handleCPTSearch(a, b)}
                        index={index}
                        updateClaim={updateClaim}
                      />
                    ))
                  ) : (
                    <td className="ml-10 !p-4">
                      <span className="text-sm font-500 text-neutral-800">No cpt codes added!</span>
                    </td>
                  )}
                </tbody>
              </table>
              <Button
                data-qa="add-cpt-btn"
                onClick={() => addCPTRow()}
                className="!mt-2"
                text="Add CPT"
                color="success"
                type="secondary"
              />
            </div>
          </div>
          <div className="grid grid-cols-7 gap-4 !py-4">
            <div className="col-start-1 col-end-3 grid grid-cols-2 gap-2">
              <Input
                data-qa="bill-taxid"
                label="25. Tax id"
                placeholder="Tax id"
                value={claim?.bill_taxid}
                name="bill_taxid"
                onChange={updateClaim}
              />
              <Select
                inputId="bill-texid-type"
                label="Type"
                placeholder="Type"
                name="bill_taxid_type"
                value={
                  claim?.bill_taxid_type
                    ? { value: claim?.bill_taxid_type, label: claim?.bill_taxid_type }
                    : null
                }
                onChange={(event) => updateClaim(event, 'bill_taxid_type')}
                options={tax_id_types}
              />
            </div>
            <Input
              data-qa="patient-acct"
              label="26. Patient acct #"
              placeholder="Patient acct #"
              value={claim?.pcn}
              name="pcn"
              onChange={updateClaim}
              disabled
            />
            <Select
              inputId="select-accept-assign"
              label="27. Accept Assign"
              placeholder="Accept Assign"
              name="accept_assign"
              value={
                claim?.accept_assign
                  ? { value: claim?.accept_assign, label: claim?.accept_assign }
                  : null
              }
              onChange={(event) => updateClaim(event, 'accept_assign')}
              options={yesAndNoOptions}
            />
            <Input
              data-qa="total-charge"
              label="28. Total charge"
              value={claim?.total_charge}
              id="total_charge"
              placeholder="Total Charge"
              name="total_charge"
              onChange={updateClaim}
            />
            <Input
              data-qa="amount-paid"
              label="29. Amount paid"
              value={claim?.amount_paid}
              id="total_charge"
              placeholder="Amount paid"
              name="amount_paid"
              onChange={updateClaim}
            />
            <Input
              data-qa="balance-total-charge"
              label="30. Balance"
              id="total_charge"
              placeholder="Amount paid"
              name="total_charge"
              value={
                claim?.total_charge && claim?.amount_paid
                  ? claim?.total_charge - claim?.amount_paid
                  : claim?.total_charge
                    ? claim?.total_charge
                    : claim?.amount_paid
                      ? claim?.amount_paid
                      : 0
              }
              disabled
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="grid h-max gap-4">
              <div>
                <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                  31. Rendering provider (L, F, M)
                </label>
                <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                  <Input
                    data-qa="prov-name-l"
                    placeholder="Last name"
                    value={claim?.prov_name_l}
                    name="prov_name_l"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="prov-name-f"
                    placeholder="First name"
                    value={claim?.prov_name_f}
                    name="prov_name_f"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="prov-name-m"
                    placeholder="Middle name"
                    className="w-[72px]"
                    value={claim?.prov_name_m}
                    name="prov_name_m"
                    onChange={updateClaim}
                  />
                </div>
              </div>
              <Input
                data-qa="prov-taxonomy"
                label="Taxonomy"
                placeholder="Taxonomy"
                value={claim?.prov_taxonomy}
                name="prov_taxonomy"
                onChange={updateClaim}
              />
            </div>
            <div className="grid h-max gap-4">
              <Input
                data-qa="facility-name"
                label="32. Facility"
                placeholder="Facility"
                value={claim?.facility_name}
                name="facility_name"
                onChange={updateClaim}
              />
              <Input
                data-qa="facility-address-1"
                label="Address 1"
                placeholder="Address 1"
                value={claim?.facility_addr_1}
                name="facility_addr_1"
                onChange={updateClaim}
              />
              <Input
                data-qa="facility-address-2"
                label="Address 2"
                placeholder="Address 2"
                value={claim?.facility_addr_2}
                name="facility_addr_2"
                onChange={updateClaim}
              />
              <div className="grid grid-cols-[2fr,1fr,1fr] items-end gap-2">
                <Input
                  data-qa="facility-city"
                  label="City"
                  placeholder="City"
                  value={claim?.facility_city}
                  name="facility_city"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="facility-state"
                  label="ST"
                  placeholder="ST"
                  value={claim?.facility_state}
                  name="facility_state"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="facility-zip"
                  label="ZIP"
                  placeholder="ZIP"
                  value={claim?.facility_zip}
                  name="facility_zip"
                  onChange={updateClaim}
                />
              </div>
            </div>
            <div className="grid gap-4">
              <Input
                data-qa="bill-name"
                label="33. Billing provider"
                placeholder="Billing provider"
                value={claim?.bill_name}
                name="bill_name"
                onChange={updateClaim}
              />
              <Input
                data-qa="bill-addr-1"
                label="Address 1"
                placeholder="Address 1"
                value={claim?.bill_addr_1}
                name="bill_addr_1"
                onChange={updateClaim}
              />
              <Input
                data-qa="bill-addr-2"
                label="Address 2"
                placeholder="Address 2"
                value={claim?.bill_addr_2}
                name="bill_addr_2"
                onChange={updateClaim}
              />
              <div className="grid grid-cols-[2fr,1fr,1fr] items-end gap-2">
                <Input
                  data-qa="bill-city"
                  label="City"
                  placeholder="City"
                  value={claim?.bill_city}
                  name="bill_city"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="bill-state"
                  label="ST"
                  placeholder="ST"
                  value={claim?.bill_state}
                  name="bill_state"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="bill-zip"
                  label="ZIP"
                  placeholder="ZIP"
                  value={claim?.bill_zip}
                  name="bill_zip"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="bill-phone"
                  label="Phone"
                  placeholder="Phone"
                  value={claim?.bill_phone}
                  name="bill_phone"
                  onChange={updateClaim}
                />
                <Input
                  data-qa="bill-taxonomy"
                  label="Bill Taxonomy"
                  placeholder="Bill Taxonomy"
                  className="col-start-2 col-end-4"
                  value={claim?.bill_taxonomy}
                  name="bill_taxonomy"
                  onChange={updateClaim}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 !pt-4">
            <Input
              data-qa="prov-npi"
              label="Prov npi"
              placeholder="Prov npi"
              value={claim?.prov_npi}
              name="prov_npi"
              onChange={updateClaim}
            />
            <Input
              data-qa="prov-id"
              label="Prov id"
              placeholder="Prov id"
              value={claim?.prov_id}
              name="prov_id"
              onChange={updateClaim}
            />
            <Input
              data-qa="facility_npi"
              label="Facility Npi"
              placeholder="Facility Npi"
              value={claim?.facility_npi}
              name="facility_npi"
              onChange={updateClaim}
            />
            <Input
              data-qa="facility_id"
              label="Facil id"
              placeholder="Facil id"
              value={claim?.facility_id}
              name="facility_id"
              onChange={updateClaim}
            />
            <Input
              data-qa="bill_npi"
              label="Bill Npi"
              placeholder="Bill Npi"
              value={claim?.bill_npi}
              name="bill_npi"
              onChange={updateClaim}
            />
            <Input
              data-qa="bill_id"
              label="Bill id"
              placeholder="Bill id"
              value={claim?.bill_id}
              name="bill_id"
              onChange={updateClaim}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CMS1500;
