import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { createMedication } from '../../../../../../../api/Medication';
import { ia } from '../../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../../shared/Alert/Alert';
import Button from '../../../../../../shared/Buttons/Button';
import CurrentMedications from '../../../../../../shared/Forms/Custom/CurrentMedications/CurrentMedications';
import { syncHpOverviewTemplates } from '../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import Icon from '../../../../../../shared/Icon/Icon';
import Modal from '../../../../../../shared/Modal/Modal';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

const MedicationsModal = ({
  defaultValue,
  setCustomResponses,
  setCurrentHpOverviewData,
  formId
}) => {
  const queryClient = useQueryClient();
  const [currentFormData, setCurrentFormData] = useState(defaultValue);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, appointmentId } = useParams();
  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['medications'] });
  };
  const submitForm = async () => {
    try {
      const onError = (error) => {
        showAlert({
          title: 'Error saving medication!',
          message: error,
          color: 'danger'
        });
      };
      const onSuccess = () => {
        handleInvalidateQuery();
        showAlert({
          title: 'Medication saved successfully!',
          color: 'primary'
        });
        const { newResponse } = data;
        syncHpOverviewTemplates({
          formId,
          type: 'medicationHistory',
          setOverviewData: setCustomResponses,
          setCurrentHpOverviewData,
          customFormResponse: newResponse
        });
      };
      const params = {
        appointmentId,
        patientId: id,
        medication: currentFormData
      };
      const data = await createMedication(() => {}, params, onError, onSuccess);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {ia(defaultValue?.medications) ? (
        <Icon
          icon="new-edit-gray"
          onClick={() => setShowModal(true)}
          data-qa="new-plus-medications"
        />
      ) : (
        <Icon icon="new-plus" onClick={() => setShowModal(true)} data-qa="new-plus-medications" />
      )}
      <Modal
        slideFromRight
        handleOpen={showModal}
        title="Medications"
        className="z-[100] w-[924px]"
        handleClose={() => {
          setShowModal(false);
        }}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setShowModal(false);
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }>
        {loading ? (
          <div>
            <Skeleton count={1} height="74px" />
            <Skeleton count={1} height="300px" />
            <Skeleton count={1} height="300px" />
          </div>
        ) : (
          <div>
            <div className="!pt-6">
              <CurrentMedications
                defaultValue={defaultValue}
                setItemModal={setShowModal}
                fromClinicalNote={true}
                setCurrentFormData={setCurrentFormData}
                customFormsParams={{
                  patientId: id,
                  appointmentId
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MedicationsModal;
