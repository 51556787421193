import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { io } from '/lib/helpers/utility';
import PersonalInfo from './personal_info';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
const validator = require('validator');

//array of genders to select from
const genders = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

const UpdatePatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First Name required'),
  l_name: Yup.string().required('Last Name required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required.' });
    }
    if (!validator?.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  })
});

const PersonalInfoContainer = forwardRef(({ patient, setShowModal }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formRef = useRef();
  useImperativeHandle(ref, () => ({
    onSubmit: () => handleSubmit(),
    isLoading: formik.isSubmitting
  }));
  const formik = useFormik({
    initialValues: {
      id: patient?.id,
      f_name: patient?.f_name,
      m_name: patient?.m_name,
      l_name: patient?.l_name,
      dob: patient?.dob,
      gender: patient?.gender,
      email: patient?.email,
      phone: patient?.phone || ''
    },
    enableReinitialize: true,
    validationSchema: UpdatePatientValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const patientToBeUpdated = {
        patient: {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          dob: values?.dob,
          gender: values?.gender,
          email: values?.email,
          phone: values?.phone
        }
      };

      setSubmitting(true);
      await submitChanges(patientToBeUpdated.patient);
      setSubmitting(false);
    }
  });

  const submitChanges = async (updatedPatient) => {
    try {
      const changes = {};

      const values = updatedPatient;
      const initialValues = formik.initialValues;

      for (const key in values) {
        if (values[key] !== initialValues[key]) changes[key] = values[key];
      }

      if (!io(changes)) return;

      let data = await requestApi({
        url: '/api/user/update',
        params: {
          changes,
          user_id: patient.id
        },
        navigate
      });

      const { code, error } = data || {};

      switch (code) {
        case 0:
          showAlert({ title: 'Patient updated successfully!', color: 'primary' });
          queryClient.invalidateQueries(['patient']);
          queryClient.invalidateQueries(['patients']);
          setShowModal(false);

          break;

        case 1:
          toast.error(error);

        default:
          Honeybadger.notify(
            `submitChanges (updatedPatient) pID: ${updatedPatient.id}, error: ${error}`
          );
          break;
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };

  return (
    <div>
      <PersonalInfo
        patient={patient}
        genderList={genders}
        formRef={formRef}
        formik={formik}
        formikHandle={formik.handleChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
});

export default PersonalInfoContainer;
