import NotFound from 'components/NotFound';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Icon from 'components/shared/Icon/Icon';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import React from 'react';

const Chat = () => {
  const { selectedChatComponents } = useMyTemplateContext();

  const components = selectedChatComponents
    ?.map((component) => {
      const Input = component?.forms || NotFound;

      return (
        <div className="py-2" key={component.title}>
          <Input inputName={component.title} />
        </div>
      );
    })
    .filter(Boolean);

  const onTextChange = () => {};

  return (
    <div className="overflow-y-none relative flex h-full flex-col pb-24">
      {components?.length > 0 ? (
        <div className="flex-grow overflow-y-scroll px-4 pb-[100px]">
          <div className="mx-auto flex w-1/2 flex-col px-10">{components}</div>
        </div>
      ) : (
        <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto">
          <Icon icon="choose-notes" />
          <h2 className="mb-4 text-xl text-[#004F6B]">Select type of note and choose forms</h2>
          <h4 className="mb-4 text-base text-[#6D828B]">
            You can start creating new clinical notes on chart details.
          </h4>
        </div>
      )}
      <div className="px-auto absolute bottom-32 z-50 flex w-1/2 self-center bg-transparent">
        <AIChatInput onTextChange={onTextChange} />
      </div>
    </div>
  );
};

export default Chat;
