import React, { useState } from 'react';
import Icon from '../../../../shared/Icon/Icon';
import cs from 'classnames';
import Switch from '../../../../shared/Switch/Switch';
import CreateMacro from '../Sidebar/Macros/Create/CreateMacro';
import { ia, io } from '../../../../../lib/helpers/utility';
import { Popover } from '@headlessui/react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Tippy from '@tippyjs/react';
import Loader from 'components/shared/Loader/Loader';

const Options = ({
  handleUpdate = () => {},
  parentKey = null,
  childKey = null,
  sectionRef = null,
  advanced,
  options
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext();
  const sectionValue = cnDisplaySettings?.sections?.[parentKey]?.[childKey]?.advancedForm;
  const [newFormMacro, setNewFormMacro] = useState(null);

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <div>
            <Popover.Button
              data-qa="option-button"
              className={cs(
                'flex !h-6 !w-6 cursor-pointer items-center justify-center rounded-[3px] bg-transparent p-0 hover:!bg-primary-100',
                open && '!bg-primary-700 hover:!bg-primary-700'
              )}>
              <Icon icon="new-candle" color={open && 'white'} className="cursor-pointer" />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 top-full z-[90] mt-[6px] grid w-max cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
              <div>
                {parentKey && advanced && (
                  <div className="flex justify-between gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50">
                    <span className="text-sm font-400 leading-4 text-primary-900">
                      Advanced template
                    </span>
                    <Switch
                      onChange={(e) => handleUpdate(e, childKey, parentKey)}
                      checked={sectionValue}
                    />
                  </div>
                )}
                <Tippy
                  className="tippy-dark"
                  content="Macro creation for this section is not yet supported."
                  disabled={sectionRef?.current?.formData}>
                  <div
                    data-qa="create-form-macro"
                    onClick={() =>
                      sectionRef?.current && setNewFormMacro(sectionRef?.current?.formData)
                    }
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50">
                    <Icon
                      icon="new-diskette"
                      color="primary"
                      shade={900}
                      className="cursor-pointer"
                    />
                    <span className="text-sm font-400 leading-4 text-primary-900">
                      Create Form Macro
                    </span>
                  </div>
                </Tippy>
                {ia(options) &&
                  options.map(({ title, icon, onClick = () => {}, loading }, index) => (
                    <div
                      onClick={() => !loading && onClick()}
                      key={index}
                      className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50">
                      {loading ? (
                        <Loader outlined className="text-primary-900" />
                      ) : (
                        <Icon icon={icon} className="cursor-pointer" />
                      )}
                      <span className="text-sm font-400 leading-4 text-primary-900">{title}</span>
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>

      {io(newFormMacro) && (
        <CreateMacro
          macroType="form_macro"
          open={!!newFormMacro}
          handleClose={() => setNewFormMacro(null)}
          macroTitle="Form Macro"
          customMacroBody={newFormMacro}
        />
      )}
    </>
  );
};

export default Options;
