import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import state from 'components/state';
import { pm } from 'lib/helpers/utility';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Configurations from 'components/Payments/Configurations';

import './Payments.scss';

const Payments = ({
  amount,
  patientId,
  practiceId,
  appointmentId,
  invoiceId,
  smallButton,
  updateBalance = () => {
    console.warn(`${__filename} No updateBalance handler`);
  },
  updateAmount = () => {
    console.warn(`${__filename} No updateAmount handler.`);
  },
  onSuccess = () => {
    console.warn(`${__filename} No onSuccess handler.`);
  },
  terminals,
  virtualTerminals,
  surcharge,
  preAuthForm,
  showTransactionOverride = false,
  setAppointmentPayments,
  appointment,
  apptInvoice = false,
  setAppointment,
  buttonClassName
}) => {
  const [showTransaction, setShowTransaction] = useState(false);
  const p = useRecoilValue(state.permissions);

  if (!pm(p, 'billing.create')) {
    return <div>You don't possess necessary permissions</div>;
  }

  const onClose = () => {
    setShowTransaction(false);
    updateBalance(0);
  };

  if (!patientId) return <div>No payment target.</div>;

  if (showTransaction || showTransactionOverride) {
    return (
      <Modal
        slideFromRight
        isFooter={false}
        handleClose={onClose}
        handleClick={onClose}
        className="w-[1170px] p-0"
        handleOpen={showTransaction}
        bodyClassName="p-0 max-h-screen"
        title={preAuthForm ? 'Pre Authorization' : 'New Payment'}>
        <Configurations
          amount={amount}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          appointmentId={appointmentId}
          surcharge={surcharge}
          onClose={onClose}
          updateBalance={updateBalance}
          updateAmount={updateAmount}
          onSuccess={onSuccess}
          terminals={terminals}
          virtualTerminals={virtualTerminals}
          preAuthForm={preAuthForm}
          setAppointmentPayments={setAppointmentPayments}
          appointment={apptInvoice ? appointment : {}}
          setAppointment={apptInvoice ? setAppointment : null}
        />
      </Modal>
    );
  }

  return (
    <Button
      text="New Payment"
      data-qa="new-payment-btn"
      size={smallButton ? 'small' : 'medium'}
      icon="wallet-add"
      onClick={() => setShowTransaction(true)}
      className={buttonClassName || 'h-[38px] w-max'}
    />
  );
};

export default Payments;
