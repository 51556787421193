import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import ProfilePicture from '../../Profile/ProfilePicture';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import Icon from '../../shared/Icon/Icon';
import Logout from '../../shared/Logout/Logout';
import CheckinPracticeLogo from './PracticeLogo';
import state from './state';
import cs from 'classnames';
import { ia } from 'lib/helpers/utility';
import FamilyHistoryOverview from 'components/practice/charts/ClinicalNote/Sidebar/GeneralOverview/components/FamilyHistoryOverview';
import { requestApi } from 'api/Api';

const DEFAULT_STEPS = [
  { name: 'Diagnosis', path: null, status: 'complete', index: 'Diagnosis' },
  { name: 'Behavior', path: null, status: 'complete', index: 'Behavior' },
  { name: 'Goals', path: null, status: 'current', index: 'Goals' },
  { name: 'Objective', path: null, status: 'incomplete', index: 'Objective' },
  { name: 'Interventions', path: null, status: 'incomplete', index: 'Interventions' },
  { name: 'Overview', path: null, status: 'incomplete', index: 'Overview' }
];

const CheckinSidebar = ({
  steps = DEFAULT_STEPS,
  practiceLogo,
  device,
  kiosk,
  setShow,
  disableContinue
}) => {
  const navigate = useNavigate();
  const user = useRecoilValue(state.userState);
  const { pathname } = useLocation();

  return (
    <div
      className={cs(
        'col-start-1 row-span-8 row-start-1 flex h-full w-[280px] flex-col justify-start overflow-hidden text-ellipsis',
        (device === 'mobile' || device === 'tablet-sm' || device === 'tablet') &&
          'fixed z-10 bg-primary-500'
      )}>
      <div
        className={cs(
          'sticky top-0 mb-2',
          device === 'mobile' || device === 'tablet-sm' ? 'h-[56px]' : 'h-[64px]'
        )}>
        <CheckinPracticeLogo practiceLogo={practiceLogo} />
        {(device === 'mobile' || device === 'tablet-sm' || device === 'tablet') && (
          <div className="absolute left-[6px] top-[16px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center !rounded-md !bg-neutral-100 hover:bg-neutral-100">
            <Icon
              data-qa="sidebar-close-btn"
              icon="back-large"
              size={12}
              color="neutral"
              shade={600}
              onClick={() => setShow(false)}
            />
          </div>
        )}
      </div>
      <div className="m-2 overflow-y-auto">
        {ia(steps) &&
          steps.map((v, i) => (
            <div
              key={`patient-checkin-sidebar-index-${i}`}
              onClick={() => {
                if (disableContinue) return;
                navigate(v.path);
                setShow(false);
                // setShow(FamilyHistoryOverview);
              }}
              className={`${
                pathname.includes(v.path) ? 'bg-white' : 'bg-opacity-0'
              } m-2 flex flex-row rounded-[1rem] p-[0.8rem] text-left font-semibold`}
              data-qa={`${v.step_id}`}>
              <div
                className={`text-white ${
                  pathname.includes(v.path) ? 'bg-primary-500' : 'bg-primary-300'
                } flex h-[1.75rem] w-[1.75rem] items-center justify-center rounded-full`}>
                {v.completed ? (
                  <Icon icon="checkmark" color="white" size={22} className="!mt-1" />
                ) : (
                  <div className="!m-0 !aspect-square !h-4 !w-4 rounded-full border-none bg-white !p-0"></div>
                )}
              </div>
              <div
                className={`${
                  pathname.includes(v.path) ? 'text-primary-500' : 'text-white'
                } ml-[0.75rem] flex w-[80%] flex-row items-center justify-start truncate text-left text-sm`}>
                {/*{(checkinV2Map[v.name] && checkinV2Map[v.name]?.name(v)) || v?.name}*/}
                {v?.name}
              </div>
            </div>
          ))}
      </div>
      <div className="col-span-1 col-start-1 row-span-1 row-start-9 mt-auto flex w-[280px] max-w-[280px] flex-row items-center justify-between !bg-opacity-10 bg-gradient-to-b from-primary-400 to-primary-600 p-3">
        <span className="text-sm font-500 text-white">Powered by</span>
        <Icon icon="logo-full-main-white" size={88} />
      </div>
      {!user.not_logged_in && (
        <div className="flex h-[70px] w-full items-center justify-between border-0 !border-r !border-solid !border-neutral-100 bg-white !p-4">
          <div className="flex flex-row items-center text-primary-900">
            <ProfilePicture
              firstName={user.f_name}
              lastName={user.l_name}
              id={user.id}
              image={user?.profile_photo}
              size={36}
            />
            <div className="!ml-3 text-sm font-500">
              {user?.f_name && `${user.f_name} ${user.l_name}`}
            </div>
          </div>
          {!kiosk ? (
            <Logout
              showText
              textClassName="text-primary-900 text-2xl"
              iconClassName="color-primary-900"
            />
          ) : (
            <div
              className={cs(
                'flex cursor-pointer items-center justify-center !rounded-md hover:bg-primary-50'
              )}
              onClick={async () => {
                await requestApi({
                  url: '/api/user/logout',
                  params: {},
                  navigate
                });

                navigate('/kiosk/idle');
              }}
              data-qa="logout-btn">
              <span className={cs('mr-1 text-xs text-red-900')}>Exit</span>

              <Icon icon="new-logout" color="danger" className={cs('!ml-1 cursor-pointer')} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(CheckinSidebar);
