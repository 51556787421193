import React, { useEffect } from 'react';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';

import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../../../lib/helpers/utility';
import { permissions as userPermissions } from '../../../../../../../state';
import { prepareSelection } from '../../../../PreviewNote/lib/selectedHelper';
import {
  processAdvancedHPForms,
  processAdvancedSOAPForms,
  selectedInitialState
} from '../../../lib/clinicalSummaryHelpers';

import { useRecoilValue } from 'recoil';

const DemographicsAndSummary = ({ formik }) => {
  const {
    clinicalNote,
    currentHpOverviewData,
    vitals,
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    selected = {},
    setSelected = () => {}
  } = useClinicalNoteContext() || {};
  const permissions = useRecoilValue(userPermissions);

  useEffect(() => {
    getSelected();
  }, [advancedHP, advancedSOAP, cnDisplaySettings, vitals, currentHpOverviewData]);

  useEffect(() => {
    if (!selected) return;
    formik?.setFieldValue('clinical_summary', selected);
  }, [selected, formik?.values?.from_name]);

  const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
  const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);

  const getSelected = async () => {
    let selectedObj = {
      ...selectedInitialState(
        clinicalNote,
        cnDisplaySettings,
        currentHpOverviewData,
        vitals,
        permissions
      ),
      ...getAdvancedHP,
      ...getAdvancedSOAP
    };

    const clinicalSummary = formik?.values?.clinical_summary;

    if (io(clinicalSummary)) {
      const result = prepareSelection({
        savedSelection: { ...selectedObj, ...clinicalSummary },
        initialSelection: selectedObj
      });

      setSelected(result);
    } else {
      const formattedSelect = prepareSelection({ initialSelection: selectedObj });
      setSelected(formattedSelect);
    }
  };

  const isCheckAllBoxes = () => {
    return Object.keys(selected).every((key) => {
      const copyState = selected[key];

      return copyState?.checked;
    });
  };

  const handleSelectAll = (event) => {
    const checkboxValue = event.target.checked;
    let updateSelected = { ...selected };

    Object.keys(updateSelected).forEach((key) => {
      const copyState = updateSelected[key];

      const findIndexOfItem = ia(copyState?.formType)
        ? copyState?.formType?.findIndex((item) => item.title === 'Narrative')
        : -1;

      if (findIndexOfItem >= 0) copyState.formType[findIndexOfItem].checked = checkboxValue;
      copyState.checked = checkboxValue;
    });

    setSelected(updateSelected);
  };

  const onSelect = ({ event, findIndexOfItem, key }) => {
    setSelected((prevState) => {
      let copyState = { ...prevState };
      const value = event.target.checked;

      if (findIndexOfItem >= 0) copyState[key].formType[findIndexOfItem].checked = value;
      copyState[key].checked = value;

      return copyState;
    });
  };

  return (
    <div className="flex flex-col rounded-xl bg-white px-4 py-3 shadow-sm">
      <p className="text-lg font-500 text-primary-900">Demographics & Summary</p>

      <div className="!mt-4">
        <Checkbox
          label="Demographics"
          name="demographics"
          isChecked={formik?.values?.demographics}
          onChange={formik?.handleChange}
        />
        <Checkbox
          className="!mt-4"
          label="Insurance Information"
          name="insurance_info"
          isChecked={formik?.values?.insurance_info}
          onChange={formik?.handleChange}
        />
      </div>

      <div className="!my-4 border border-solid !border-primary-100"></div>

      <div>
        <p className="text-xs font-400 text-neutral-900">
          Choose which data you want to include in clinical summary
        </p>

        <div className="!my-4">
          <div className="!py-2">
            <Checkbox
              label="Clinical Summary"
              name="clinical_summary"
              isChecked={isCheckAllBoxes()}
              onChange={(event) => handleSelectAll(event)}
            />
          </div>

          <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))]">
            {io(selected) &&
              Object.keys(selected).map((key, index) => {
                const row = selected[key];

                let isChecked = row?.checked;

                const findIndexOfItem = ia(row?.formType)
                  ? row?.formType?.findIndex((item) => item.title === 'Narrative')
                  : -1;

                return (
                  <div key={index} className="flex flex-wrap items-center !px-8 !py-2">
                    <Checkbox
                      label={row?.title}
                      name={row?.title}
                      isChecked={isChecked}
                      onChange={(event) => onSelect({ event, findIndexOfItem, key })}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicsAndSummary;
