import { requestApi } from './Api';

export const getPayments = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/billing/get_payments',
    params
  });

export const createClaim = async (navigate, params) =>
  requestApi({ url: '/api/practice/billing/claim/create', params, navigate });

export const getPaymentsCSV = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/practice/billing/get_payments_csv', params });

export const getElectronicInvoices = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/get_invoices',
    params
  });

export const getInternalInvoices = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/internal/get',
    params
  });

export const getInternalInvoicesCSV = (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/billing/invoicing/internal/get_csv', params });

export const getExternalInvoices = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/external/get',
    params
  });
export const getExternalInvoicesCSV = (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/billing/invoicing/external/get_csv', params });

export const createExternalInvoices = (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/external/create',
    params
  });
};

export const deleteExternalInvoices = (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/external/delete',
    params
  });
};

export const getInternalInvoiceByIds = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/external/get_internals',
    params
  });

export const getOldExternalsByAppointmentIds = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/invoicing/external/get_old_externals',
    params
  });
