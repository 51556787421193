import React, { useState, useRef, useEffect } from 'react';
import { formatDate, formatPhoneNumber } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import PersonalInfoContainer from './personal_info_container';
import avatar from '../../../../../avatar.png';
import useRandomGradient from 'lib/hooks/useRandomGradient';
const OverviewBlock = ({ practitioner }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const childComponentRef = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (childComponentRef.current?.isLoading !== isLoading) {
        setIsLoading(childComponentRef.current?.isLoading);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isLoading]);
  const handleOnSave = (e) => {
    e.preventDefault();
    childComponentRef.current.onSubmit();
  };
  const ModalFooter = () => (
    <div className="flex items-center justify-center">
      <Button
        onClick={handleOnSave}
        loading={isLoading}
        data-qa="save-btn"
        text="Save"
        className="ml-auto"
      />
    </div>
  );

  return (
    <div className="shadow-default relative flex flex-wrap items-center justify-between gap-20 !rounded-xl bg-white !py-2 !pl-14 !pr-4">
      <div
        className="absolute -left-3 top-1/2 ml-3 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-neutral-300 bg-white shadow-small hover:!bg-neutral-50"
        onClick={() => navigate('/admin/practitioners')}>
        <Icon icon="new-arrow-left" className="flex cursor-pointer" />
      </div>
      <div className="flex flex-wrap items-center gap-x-20 gap-y-4">
        <div className="flex items-center !gap-4">
          <div
            className="rounded-smooth height-[60px] mt-1 flex w-[60px] items-center justify-center overflow-hidden"
            style={{ background: useRandomGradient(practitioner?.id) }}>
            <img src={avatar} className="h-full w-full" alt="Practitioner Avatar" />
          </div>
          <div className="flex flex-col">
            <p
              className="w-max text-lg font-500 text-primary-900"
              data-dd-privacy="allow"
              data-public>
              {practitioner?.f_name + ' ' + practitioner?.l_name}
            </p>
            <div className="flex gap-1">
              <p className="text-xs text-neutral-800">Phone:</p>
              <p className="text-xs text-neutral-800"> {formatPhoneNumber(practitioner?.phone)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-9">
          <div className="flex flex-col gap-[2px]" data-dd-privacy="allow" data-public>
            <p className="text-sm font-500 text-primary-800">ID</p>
            <p className="text-xs text-neutral-800">{practitioner?.id}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Address</p>
            <p className="text-xs text-neutral-800">{practitioner?.userAddress?.fullAddress}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Email</p>
            <p className="text-xs text-neutral-800">{practitioner?.email}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Joined</p>
            <p className="text-xs text-neutral-800">{formatDate(practitioner?.created_at)}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          data-qa="edit-profile-btn"
          text="Edit profile"
          type="secondary"
          size="small"
          icon="new-edit"
          onClick={() => setShowModal(true)}
        />
      </div>
      <Modal
        slideFromRight
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        title="Practitioner information"
        className="unset-relative-body"
        footer={<ModalFooter />}>
        <PersonalInfoContainer ref={childComponentRef} practitioner={practitioner} />
      </Modal>
    </div>
  );
};

export default OverviewBlock;
