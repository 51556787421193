import cs from 'classnames';
import React from 'react';
import Icon from '../Icon/Icon';
import './Checkbox.scss';

const Checkbox = ({
  id,
  label,
  labelTextColor,
  className,
  inputClassName,
  labelClassName,
  parentInnerLabelClassName,
  innerLabelClassName,
  dropDownClassName,
  handleClick, // recommended to use onChange instead of handleClick
  isChecked = false,
  isPartial,
  isDropdown,
  rounded,
  children,
  expanded,
  setExpanded,
  forwardedRef,
  disabled,
  color,
  radio = false,
  leftIcon = null,
  leftIconColor = null,
  leftIconClassName = null,
  ...rest
}) => {
  return (
    <div
      className={cs(
        'Checkbox',
        className,
        isChecked && 'Checkbox--checked',
        children && 'Checkbox--hasChildren',
        expanded && 'Checkbox--expanded',
        rounded && 'Checkbox--rounded'
      )}>
      {isDropdown ? (
        <div className={cs('Checkbox__isDropdown', dropDownClassName)}>
          <div
            onClick={setExpanded}
            className={cs(
              inputClassName,
              'flex w-full cursor-pointer items-center px-4 py-[11px]',
              disabled ? '!cursor-not-allowed' : 'cursor-pointer'
            )}>
            <div className="flex items-center gap-3">
              {leftIcon && (
                <Icon
                  icon={leftIcon}
                  color={leftIconColor}
                  className={cs(disabled && 'cursor-not-allowed', leftIconClassName)}
                />
              )}

              <input
                className={cs(
                  inputClassName,
                  isChecked && isPartial ? 'isPartial' : '',
                  disabled ? '!cursor-not-allowed' : 'cursor-pointer',
                  inputClassName
                )}
                data-qa={`checkbox-${id}`}
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={handleClick}
                ref={forwardedRef}
                value={isChecked}
                disabled={disabled}
                {...rest}
              />
              <span>{label}</span>
            </div>

            <Icon
              icon="new-arrow-down"
              className={cs(
                'arrow-down',
                !expanded && 'rotate-180',
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
            />
          </div>
          {children && <div className="Checkbox__children">{children}</div>}
        </div>
      ) : (
        <label htmlFor={id} className={labelClassName}>
          <div className={cs('flex w-full items-center', parentInnerLabelClassName)}>
            <div className={cs('flex items-center gap-3', innerLabelClassName)}>
              {leftIcon && (
                <Icon
                  icon={leftIcon}
                  color={leftIconColor}
                  className={cs(disabled && 'cursor-not-allowed', leftIconClassName)}
                />
              )}

              <input
                className={cs(
                  color === 'blue' && 'Checkbox--blue',
                  radio && 'Checkbox--radio',
                  disabled ? '!cursor-not-allowed' : 'cursor-pointer',
                  inputClassName,
                  isChecked && isPartial ? 'isPartial' : ''
                )}
                data-qa={`checkbox-${id}`}
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={handleClick}
                ref={forwardedRef}
                value={isChecked}
                disabled={disabled}
                {...rest}
              />
              <span className={cs('w-fit', color === 'blue' && 'text-primary-900', labelTextColor)}>
                {label}
              </span>
            </div>
          </div>
          {children && <div className="Checkbox__children">{children}</div>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
