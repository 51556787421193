import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import { ia } from '../../../lib/helpers/utility';
import Loading from '../Loading/Loading';
import NewRole from './NewRole';
import Role from './Role';
import RoleEntry from './RoleEntry';
import RoleTable from './RoleTable';
import recurseGet from './recurseGet';

const LIMIT = 25;

const Roles = () => {
  const [show, setShow] = useState({
    loading: false,
    canLoadMore: true,
    selectedRole: null,
    newRole: null
  });
  const [roles, setRoles] = useState([]);
  const [possiblePermissions, setPossiblePermissions] = useState({});
  const [permissionDescriptions, setPermissionDescriptions] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    let newShow = Object.assign({}, show);
    setShow({ ...show, loading: true });
    try {
      const res = await interimApi(
        '/api/practice/role/get',
        {
          offset: roles.length,
          limit: LIMIT,
          practiceId: id
        },
        navigate
      );
      const {
        code,
        redirect,
        error,
        role_list: loadedRoles,
        possiblePermissions: loadedPossiblePermissions,
        permissionDescriptions: loadedPermissionDescriptions
      } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedRoles)) {
            let newRoles = Object.assign([], roles);
            for (let i = 0; i < loadedRoles.length; i++) {
              // let numberOfPermissions = 0;
              let numberOfUsers = 0;
              // let arrayPermissions = []
              let rolePermissions = _.defaultsDeep(
                loadedRoles[i]?.permissions,
                loadedPossiblePermissions
              );
              let [arrayPermissions, numberOfPermissions] = recurseGet(
                rolePermissions,
                { permissions: loadedPermissionDescriptions },
                'permissions',
                'permissions'
              );
              newRoles.push({
                ...loadedRoles[i],
                arrayPermissions,
                numOfPermissions: numberOfPermissions,
                numberOfUsers
              });
            }
            setPossiblePermissions(loadedPossiblePermissions);
            setPermissionDescriptions(loadedPermissionDescriptions);
            newShow.canLoadMore = loadedRoles.length >= LIMIT;
            setRoles(newRoles);
          } else {
            newShow.canLoadMore = false;
          }
          break;
        default:
          if (error) toast.error(error);
          else toast.error(`An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
    newShow.loading = false;
    setShow(newShow);
  };

  const handleRoleEditClick = (roleId, index) => {
    let selectedRole = null;

    if (roles.length >= index) {
      selectedRole = roles[index];
    }
    if (!selectedRole || selectedRole.id !== roleId) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i]?.id === roleId) {
          selectedRole = roles[i];
        }
      }
    }

    setShow({ ...show, selectedRole });
  };

  const updateRole = (roleUpdates) => {
    setRoles(
      roles.map((v) => {
        if (v.id === show.selectedRole.id) {
          let [newPermissionsArray, numberOfPermissions] = recurseGet(
            roleUpdates.permissions,
            permissionDescriptions,
            'permissions',
            'permissions'
          );
          return {
            ...v,
            ...roleUpdates,
            arrayPermissions: newPermissionsArray,
            numOfPermissions: numberOfPermissions
          };
        } else {
          return v;
        }
      })
    );
    setShow({ ...show, selectedRole: null });
    toast.success(`Role ${roleUpdates.name} updated!`);
  };

  const openNewRolePrompt = () => {
    /*
    const [arrayPermissions, _] = recurseGet(
      possiblePermissions,
      permissionDescriptions,
      'permissions',
      'permissions'
    );
    */
    let rolePermissions = _.defaultsDeep({}, possiblePermissions);
    let [arrayPermissions, numberOfPermissions] = recurseGet(
      rolePermissions,
      { permissions: permissionDescriptions },
      'permissions',
      'permissions'
    );

    setShow({
      ...show,
      newRole: {
        name: 'New Role',
        arrayPermissions,
        permissions: rolePermissions
        // permissions: Object.assign({}, possiblePermissions)
      }
    });
  };

  const createRoleHandler = (newRole) => {
    let [newPermissionsArray, numberOfPermissions] = recurseGet(
      newRole,
      permissionDescriptions,
      'permissions',
      'permissions'
    );
    setRoles([
      ...roles,
      {
        ...newRole,
        arrayPermissions: newPermissionsArray,
        numOfPermissions: numberOfPermissions,
        numberOfUsers: 0
      }
    ]);
    setShow({ ...show, newRole: null });
  };

  return (
    <>
      {show.loading ? (
        <Loading />
      ) : (
        <div className="overflow-auto">
          <RoleTable addRoleHandler={openNewRolePrompt} loadMoreHandler={getRoles}>
            {ia(roles) &&
              roles.map((v, i) => (
                <RoleEntry
                  key={`will-this-work-index-roles-index-${i}`}
                  role={v}
                  index={i}
                  handleRoleEditClick={handleRoleEditClick}
                />
              ))}
          </RoleTable>
          {show.newRole && (
            <NewRole
              handleOpen={Boolean(show.newRole)}
              handleClose={() => setShow({ ...show, newRole: null })}
              createRoleHandler={createRoleHandler}
              role={show?.newRole}
              practiceId={id}
            />
          )}
          {show.selectedRole && (
            <Role
              handleOpen={Boolean(show.selectedRole)}
              handleClose={() => setShow({ ...show, selectedRole: null })}
              updateRoleHandler={updateRole}
              role={show?.selectedRole}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Roles;
