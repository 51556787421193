import React from 'react';

import { useOutletContext } from 'react-router-dom';
import { Capitalize, findRelationName, ia } from '../../../../../../../../lib/helpers/utility';

const InsurancePreview = ({ itemProps }) => {
  const { patient: outletPatient } = useOutletContext() || {};

  const patient = outletPatient || itemProps.patient;

  const insuranceProfiles = patient?.insuranceProfile;

  const checkIfNotEmpty = (value) => {
    return value ? value : '-';
  };

  return (
    <div>
      <div className="text-base font-500 text-neutral-800">Insurance Information</div>

      <div className="grid grid-cols-[repeat(2,1fr)] !pl-5">
        {ia(insuranceProfiles) ? (
          insuranceProfiles.map((row, index) => {
            return (
              <div key={index} className="!mt-2.5 flex !gap-3">
                <div>
                  <p className="!mt-2 font-500 text-neutral-800">Payer ID:</p>
                  <p className="!mt-2 font-500 text-neutral-800">Payer Name:</p>
                  <p className="!mt-2 font-500 text-neutral-800">Type</p>
                  <p className="!mt-2 font-500 text-neutral-800">Member ID:</p>
                  <p className="!mt-2 font-500 text-neutral-800">Group Number</p>
                  <p className="!mt-2 font-500 text-neutral-800">Plan Name:</p>
                  <p className="!mt-2 font-500 text-neutral-800">Policy Start Date</p>
                  <p className="!mt-2 font-500 text-neutral-800">Policy End Date</p>
                  <p className="!mt-2 font-500 text-neutral-800">Relation</p>
                </div>
                <div>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.payer_id)}</p>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.payer_name)}</p>
                  <p className="!mt-2 text-neutral-800">{Capitalize(row?.type)}</p>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.member_id)}</p>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.group_number)}</p>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.plan_name)}</p>
                  <p className="!mt-2 text-neutral-800">
                    {checkIfNotEmpty(row?.policy_start_date)}
                  </p>
                  <p className="!mt-2 text-neutral-800">{checkIfNotEmpty(row?.policy_end_date)}</p>
                  <p className="!mt-2 text-neutral-800">
                    {checkIfNotEmpty(findRelationName(row?.relation))}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="!mt-2 text-base text-neutral-800">No Insurance Profiles.</p>
        )}
      </div>
    </div>
  );
};

export default InsurancePreview;
