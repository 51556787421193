import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import Priority from './components/Priority';
import Status from './components/Status';
import Tabs from 'components/shared/Tabs/NewTabs';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import NewTask from 'components/practice/PracticeIQueue/Tasks/components/NewTask';

const Tasks = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [listView, setListView] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const tabsData = [
    {
      label: 'Status',
      content: <Status {...props} listView={listView} setListView={setListView} />
    },
    {
      label: 'Priority',
      content: <Priority {...props} listView={listView} setListView={setListView} />
    }
  ];
  const handleListView = () => setListView(!listView);
  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        icon="new-task-square"
        hasPlus={true}
        onPlusClick={() => setShowModal(true)}
        {...props}>
        <div
          onClick={handleListView}
          className="cursor-pointer rounded-lg bg-primary-50 !px-1 !py-1">
          <Icon
            className="cursor-pointer"
            color="primary"
            icon={listView ? 'new-graph' : 'list-unordered'}
          />
        </div>
        <div
          onClick={() => {
            navigate(`../tasks`);
          }}
          data-qa="tasks-redirect"
          className="cursor-pointer rounded-lg bg-neutral-100 !px-2 !py-2">
          <Icon className="cursor-pointer" icon="new-link"></Icon>
        </div>
      </WidgetHeader>
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
      {showModal && (
        <NewTask newTaskModal={showModal} hideNewTaskModal={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default withErrorBoundary(Tasks);
