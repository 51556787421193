import React, { useCallback, useEffect, useState } from 'react';

import returnAppliedFilter from 'components/shared/Filters/lib/returnAppliedFilter';
import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';
import { useTableLimit } from 'lib/hooks/queries/useTableLimit';
import { useNavigate } from 'react-router-dom';
import { upsertPreferences } from '../../../api/Preferences';
import { usePreferences } from '../../hooks/queries/usePreferences';
import { TableContext } from './TableContext';
import { iaRa } from 'lib/helpers/utility';

export const TableContextProvider = ({ cols, name, pagination, children, defaultFilters }) => {
  const [colDefs, setColDefs] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedPreferences, setUpdatedPreferences] = useState();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [gridApi, setGridApi] = useState(null);

  const { data: savedFilters = {}, isFetched } = useDefaultFilters({});
  const { data: savedLimit = {}, isFetched: isLimitFetched } = useTableLimit({});

  const [limit, setLimit] = useState(savedLimit[name]?.limit || 25);

  const [filters, setFilters] = useState(returnAppliedFilter(defaultFilters, savedFilters[name]));

  const navigate = useNavigate();

  const { data } = usePreferences({ params: { name }, dependencies: [name] });

  useEffect(() => {
    if (isFetched && isLimitFetched) {
      setFilters(returnAppliedFilter(filters, savedFilters[name]));
      setLimit(savedLimit[name]?.limit || 25);
    }
  }, [isFetched, isLimitFetched]);

  useEffect(() => {
    if (!updatedPreferences) {
      if (data) {
        const preferenceColumns = data?.preferences?.value?.columnOrder;

        if (!preferenceColumns || preferenceColumns.some((column) => !column?.field)) {
          setColDefs(cols);
        } else {
          const columns = data?.preferences?.value?.columnOrder.map((col) => {
            return {
              ...iaRa(cols)?.find((c) => c?.field === col?.field),
              hide: col.hide,
              pinned: col.pinned,
              rowGroup: col.rowGroup
            };
          });
          setColDefs(columns);
        }
        setUpdatedPreferences(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (gridApi) {
      if (tableLoading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [tableLoading, gridApi]);

  const onSortChanged = () => {
    if (!gridApi) return;
    const currentSort = gridApi.getColumnState().filter((col) => {
      return col.sort === 'asc' || col.sort === 'desc';
    });
    setColDefs(gridApi.getColumnDefs());

    setSort(currentSort.map((col) => ({ field: col.colId, sort: col.sort }))[0]);
  };

  const formatValue = (cols, limit) => {
    return {
      columnOrder: cols?.map((col) => ({
        hide: col.hide,
        field: col.field,
        pinned: col.pinned,
        rowGroup: col.rowGroup
      })),
      limit
    };
  };

  const onColumnMoved = async () => {
    if (!gridApi) return;

    const currentColumns = gridApi.getColumnDefs();
    setColDefs(currentColumns);

    await upsertPreferences(navigate, { name, value: formatValue(currentColumns, limit) });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);

    if (tableLoading) {
      params.api.showLoadingOverlay();
    }
  };

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedRows(selectedNodes);
  }, [gridApi, setSelectedRows]);

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        colDefs,
        gridApi,
        onGridReady,
        onColumnMoved,
        setColDefs,
        onSortChanged,
        sort,
        page,
        setPage,
        name,
        formatValue,
        tableLoading,
        setTableLoading,
        onSelectionChanged,
        selectedRows,
        setSelectedRows,
        pagination,
        filters,
        setFilters
      }}>
      {children}
    </TableContext.Provider>
  );
};
