import React, { useState } from 'react';
import { ia, iaRa } from 'lib/helpers/utility';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import ProvidersModal from './ProvidersModal';

const AddCosigners = ({ documentSignatures = [] }) => {
  const [checked, setChecked] = useState(false);

  const disabledProviders = iaRa(documentSignatures)
    ?.filter((row) => !row?.meta?.add_manual_provider)
    ?.map((row) => row?.user?.id);

  const manuallySelectedProviders = iaRa(documentSignatures)
    ?.filter((row) => row?.meta?.add_manual_provider)
    ?.map((row) => row?.user?.id);

  const providersAssigned = iaRa(documentSignatures)
    ?.map((row) => `${row?.user?.f_name} ${row?.user?.l_name}`)
    ?.join(', ');

  return (
    <div>
      <div className="flex flex-wrap">
        <Checkbox
          id="add-co-signer-check"
          label="Add more co-signers?"
          isChecked={checked}
          handleClick={() => setChecked((prev) => !prev)}
        />
        {ia(documentSignatures) && (
          <p className="!mt-1">
            ({providersAssigned} {ia(documentSignatures, 1) ? 'are' : 'is'} already assinged to this
            document)
          </p>
        )}
      </div>
      {checked && (
        <ProvidersModal
          openModal={checked}
          closeModal={() => setChecked(false)}
          disabledProviders={disabledProviders}
          manuallySelectedProviders={manuallySelectedProviders}
        />
      )}
    </div>
  );
};

export default AddCosigners;
