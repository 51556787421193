import React from 'react';
import { useEraContext } from '../../../../../lib/context/EraContext/EraContext';
import Pagination from '../../../../shared/Pagination/Pagination';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { ia } from '../../../../../lib/helpers/utility';
import TableCounter from '../../../../shared/Table/TableCounter';

const Table = ({ children, showInfoInRecordings = false }) => {
  const { filters, setFilters, eraCount, type, isLoading } = useEraContext();
  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1 });
  };

  const sortColumn = (sortBy) => {
    setFilters((prev) => {
      if (prev.column === sortBy) {
        if (prev.sort === 'asc') {
          return { ...prev, sort: 'desc' };
        } else {
          return { ...prev, sort: 'asc' };
        }
      }
      return { ...prev, column: sortBy, sort: 'asc' };
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton count={filters?.limit} />
      ) : (
        <div
          className="grid overflow-hidden !p-4 !pb-0"
          data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
          <div className="relative overflow-auto !rounded-md">
            <table className="primary-table">
              <thead>
                <tr>
                  <th onClick={() => sortColumn('paid_date')} className="cursor-pointer">
                    Payment Date
                  </th>
                  <th onClick={() => sortColumn('check_number')} className="cursor-pointer">
                    Check/Trace #
                  </th>
                  <th onClick={() => sortColumn('payer')} className="cursor-pointer">
                    Payer
                  </th>
                  {/* TODO continue with ordering */}
                  <th onClick={() => sortColumn('claim')} className=" cursor-pointer">
                    Claims
                  </th>
                  <th onClick={() => sortColumn('unmatched_claims')} className=" cursor-pointer">
                    Unmatched Claims
                  </th>
                  <th onClick={() => sortColumn('unmatched_claims')} className=" cursor-pointer">
                    Unalloc. Amt.
                  </th>
                  <th onClick={() => sortColumn('paid_amount')} className="cursor-pointer">
                    Amt. Paid
                  </th>
                  <th
                  // onClick={() => sortColumn('adjustment')} className="cursor-pointer"
                  >
                    Adjustment
                  </th>
                  <th
                  // onClick={() => sortColumn('pat_resp')} className="cursor-pointer"
                  >
                    Patient Resp.
                  </th>
                  <th onClick={() => sortColumn('payment_method')} className="cursor-pointer">
                    Payment Method
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {ia(children) ? (
                  children
                ) : (
                  <tr className="relative">
                    <td>
                      <span className="empty">No data found!</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between">
            <TableCounter page={filters.page} limit={filters.limit} count={eraCount?.[type]} />
            <Pagination
              onPageChange={changePage}
              page={filters.page}
              totalItems={eraCount ? eraCount?.[type] : null}
              perPage={filters.limit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
