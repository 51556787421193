import React, { useState, useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Exam = ({ sectionRef }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const examRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { exam: clinicalNote?.exam }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write exam notes here"
      id="exam"
      name="exam"
      className="min-h-[200px]"
      value={clinicalNote.exam}
      onChange={(e) => handleChange(e, 'exam')}
      transcribing
      forwardedRef={examRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Exam;
