import React, { useState } from 'react';
import Icon from '../../../../../../../shared/Icon/Icon';
import ViewTranscript from './Transcript/ViewTranscript';
import TranscriptModal from './TranscriptModal';

import { permissions, userState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useRecoilValue } from 'recoil';
import { useMyScribeAIContext } from '../../../../../../../../lib/context/MyScribeAI/MyScribeAIContext';
import { ia, iaRa, isEmpty } from '../../../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../../../shared/Alert/Alert';

const Transcription = () => {
  const { clinicalNote } = useClinicalNoteContext();
  const { transcriptData, showTranscriptModal, setShowTranscriptModal } =
    useAmbientListeningContext() || {};

  const user = useRecoilValue(userState);
  const permission = useRecoilValue(permissions);

  const myScribeAuthor = transcriptData?.[0]?.provider_id ?? null;
  const isCurrentUserSameAsAuthor = myScribeAuthor == (user?.id ?? user?.user_id);

  const hasPermissionToUpdate =
    permission?.myscribe?.transcription_update || isCurrentUserSameAsAuthor;
  const hasPermissionToRead = permission?.myscribe?.transcription_read || isCurrentUserSameAsAuthor;

  const [speakerClassNames, setSpeakerClassNames] = useState({});

  const copyTranscript = () => {
    const texts = iaRa(transcriptData).map((data) => {
      const text = data?.transcript_enhanced
        .map((row) => {
          const speaker =
            typeof row?.speaker === 'number' ? `Speaker ${row?.speaker}` : row?.speaker;
          return `${speaker} - ${row.merged_sentences}`;
        })
        .join('\n');

      return text;
    });

    const isEmptyTexts = texts.every((text) => isEmpty(text));

    if (isEmptyTexts) {
      showAlert({
        title: 'Copy Transcript',
        message: 'Failed while generating the copy transcript. Please try again.',
        color: 'danger'
      });

      return;
    }

    const mergedText = texts.join('\n');

    navigator.clipboard
      .writeText(mergedText)
      .then(() =>
        showAlert({
          title: 'Copy Transcript',
          message: 'Transcript copied to clipboard',
          color: 'success'
        })
      )
      .catch(() =>
        showAlert({
          title: 'Copy Transcript',
          message: 'Failed to copy transcript',
          color: 'error'
        })
      );
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-lg font-500 text-primary-700">Transcript</p>
        {ia(transcriptData) && hasPermissionToUpdate && (
          <div className="flex">
            <div onClick={copyTranscript}>
              <Icon
                size={28}
                icon="new-copy"
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
              />
            </div>

            <div>
              <Icon
                size={28}
                icon="new-edit"
                disabled={clinicalNote?.locked}
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
                onClick={() => setShowTranscriptModal(true)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="!mt-2 !bg-transparent !p-3">
        {ia(transcriptData) && !hasPermissionToRead ? (
          <p className="text-center text-base italic text-primary-900">
            You don't have the permission to view the MyScribe AI transcript.
          </p>
        ) : ia(transcriptData) ? (
          transcriptData?.map((data, index) => (
            <ViewTranscript
              part={ia(transcriptData, 1) && index + 1}
              key={index}
              transcriptData={data}
              speakerClassNames={speakerClassNames}
              setSpeakerClassNames={setSpeakerClassNames}
            />
          ))
        ) : (
          <p className="text-center text-base italic text-primary-900">
            You currently have no transcript data. You can start one by clicking on 'record visit'.
          </p>
        )}
      </div>

      <TranscriptModal
        showModal={showTranscriptModal}
        hideModal={() => setShowTranscriptModal(false)}
      />
    </div>
  );
};

export default Transcription;
