import React from 'react';
import Status from 'components/shared/Status/Status';
import { Capitalize, formatDate, formatDateAndTimeZ, mString } from 'lib/helpers/utility';
import { searchPatients } from 'components/Utility';
import Actions from '../Actions';

const shapeUser = (value) => {
  return `${value?.f_name} ${value?.l_name}`;
};

const clinicalNoteStatus = [
  {
    value: true,
    label: 'Locked'
  },
  {
    value: false,
    label: 'Unlocked'
  }
];

const superBillStatus = [
  {
    value: true,
    label: 'Scrubbed'
  },
  {
    value: false,
    label: 'Not Scrubbed'
  }
];

const claimSubmitted = [
  {
    value: true,
    label: 'Submitted'
  },
  {
    value: false,
    label: 'Not Submitted'
  }
];

const cardAndSignatureOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

const claimStatuses = [
  { value: 'draft', label: 'Draft' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'pending', label: 'Pending' },
  { value: 'denied', label: 'Denied' },
  { value: 'acknowledged', label: 'Acknowledged' },
  { value: 'accepted', label: 'Accepted' }
];

export const defaultColumns = [
  {
    field: 'starts_at',
    headerName: 'Date of Service',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? formatDateAndTimeZ(value) : ''),
    pinned: 'left'
  },
  {
    field: 'patient',
    headerName: 'Patient',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? shapeUser(value) : '')
  },
  {
    field: 'practitioner',
    headerName: 'Provider',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? shapeUser(value) : '')
  },
  {
    field: 'claim.payer_name',
    headerName: 'Payer',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => Capitalize(value)
  },
  {
    field: 'medicalHistory.locked',
    headerName: 'Note Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (value ? 'Locked' : 'Not Locked')
  },
  {
    field: 'claim.state',
    headerName: 'Superbill Status',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value && value !== 'draft' ? 'Scrubbed' : 'Not Scrubbed')
  },
  {
    field: 'claim',
    headerName: 'Claim Submitted',
    cellClass: 'no-border',
    valueFormatter: ({ value }) =>
      value?.submitted_at
        ? formatDateAndTimeZ(value?.submitted_at)
        : value?.claim_md_id
          ? 'Yes'
          : 'No'
  },
  {
    field: 'claim.state',
    headerName: 'Claim Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) =>
      value ? (
        <div className="flex h-full items-center justify-center">
          <Status
            status={value === 'draft' ? 'no claim' : value}
            className="flex min-w-[80px] items-center justify-center"
          />
        </div>
      ) : (
        ''
      )
  },
  {
    field: 'invoice.pt_payment',
    headerName: 'Patient Payment',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'invoice.pt_balance',
    headerName: 'Patient Balance',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'invoice.ins_payment',
    headerName: 'Insurance Payment',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'invoice.ins_balance',
    headerName: 'Insurance Balance',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'card',
    headerName: 'Card on File',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
    cellRenderer: null
  },
  {
    field: 'pre_auth',
    headerName: 'Signature on File',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
    cellRenderer: null
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: (params) => <Actions data={params?.data} />,
    maxWidth: 200,
    minWidth: 200,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  starts_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  payer: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Payers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: [],
    options: clinicalNoteStatus,
    title: 'Clinical Note Status'
  },
  superBillScrubbed: {
    type: 'checkbox',
    values: [],
    options: superBillStatus,
    title: 'Superbill Scrubbed'
  },
  claimSubmitted: {
    type: 'checkbox',
    values: [],
    options: claimSubmitted,
    title: 'Claim Submitted'
  },
  claimStatus: {
    type: 'checkbox',
    values: [],
    options: claimStatuses,
    title: 'Claim Status'
  },
  invoiceStatus: {
    type: 'checkbox',
    values: null,
    options: ['Paid', 'Partially Paid', 'Not Paid'],
    title: 'Invoice Status',
    preview: (values) => values.map((v) => Capitalize(v)).join(', ')
  },
  cardOnFile: {
    type: 'checkbox',
    values: null,
    options: cardAndSignatureOptions,
    title: 'Card on File',
    preview: (values) => values.map((v) => Capitalize(v)).join(', ')
  },
  signatureOnFile: {
    type: 'checkbox',
    values: null,
    options: cardAndSignatureOptions,
    title: 'Signature on File',
    preview: (values) => values.map((v) => Capitalize(v)).join(', ')
  }
};
