const transactionTypeMap = {
  CreditCard: ['CARD_PRESENT', 'COF', 'EMV_CONTACT', 'EMV_PROXIMITY', 'ONLINE', 'SWIPE'],
  Cash: ['cash'],
  Check: ['check'],
  OutsideFinancing: ['care_credit']
};

export const getCountForType = (type, data) => {
  const types = transactionTypeMap[type];
  return types.reduce((acc, key) => {
    return acc + (data?.count?.transactions?.[key] || 0);
  }, 0);
};
