import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React, { useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import Transcript from '../../../CurrentNote/TypeNotes/MyScribeAI/components/Transcript';
import Icon from 'components/shared/Icon/Icon';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const RecordVisitText = (state) => {
  const stateText = {
    START: (
      <>
        <p className="mt-5 w-2/3 text-sm font-thin text-white">
          MyScribe AI is actively documenting.
        </p>

        <p className="mt-5 w-2/3 text-sm text-white">
          For optimal performance, please keep the screen open while engaging in real conversations
          with your patients.
        </p>
      </>
    ),
    PAUSE: <p className="mt-5 w-2/3 text-sm text-white">MyScribe AI is paused...</p>
  };

  const defaultText = (
    <>
      <p className="mt-5 w-2/3 text-sm font-thin text-white">
        Our AI streamlines the documentation process by effortlessly capturing and transcribing
        conversations between healthcare providers and patients in a HIPAA complaint manner.
      </p>
      <p className="mt-5 w-2/3 text-sm text-white">
        Click below to revolutionize your charting with MyScribe AI!
      </p>
    </>
  );

  return stateText[state] ?? defaultText;
};

const AmbientListing = () => {
  const { appointmentId } = useParams();
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { currentState } = useAmbientListeningContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Ambient Listening',
      name: '/ambient-listening',
      path: pathname
    });
  }, [breadcrumbs]);

  return (
    <div>
      <div className="relative p-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      </div>
      <div className="relative flex h-screen w-full overflow-hidden bg-primary-900 shadow-2xl">
        <Icon icon="ai-bg-pattern" className="absolute left-0 top-0" />
        <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />

        <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90"></div>
        <div className="flex w-full flex-col items-center justify-center">
          <div className="absolute z-50 flex h-full flex-col items-center justify-center ">
            <div className="h-1/10 mb-5">
              <div className="relative mb-20 flex items-center justify-center">
                <div className="absolute z-50">
                  <Icon icon="ai-circles-wave" className="animate-pulse" />
                </div>
                <div className="absolute z-50 rounded-full bg-white p-1">
                  <Icon icon="new-microphone" color="primary" size={18} />
                </div>
              </div>
            </div>
            <div className="flex h-1/2 flex-col items-center text-center">
              <Icon icon="ai-bg-eclipse" className="absolute top-0 z-50" />

              <div className="flex">
                <div className="mb-5 bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
                  Ambient Listening
                </div>
                <Icon icon="stars-ai" color="primary" className="ml-1 items-start" />
              </div>
              {RecordVisitText(currentState)}
              <div className="z-50 mt-10">
                <Transcript
                  name="Start Recording"
                  className="cursor-pointer bg-white !px-[15px] !py-3 text-primary-600"
                  customId={appointmentId}
                />
              </div>
            </div>
            <Icon icon="hippa-secure" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmbientListing;
