import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPayers } from '../../../../api/Payer';

export const usePayers = ({ params = {}, options = {} }) => {
  const navigate = useNavigate();
  return useQuery(['payers'], () => getPayers(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
