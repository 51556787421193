import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Switch from 'components/shared/Switch/Switch';
import React, { useRef, useState } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

import { generateNarrative } from 'components/practice/charts/ClinicalNote/lib/advancedFormsHelpers';
import { userState } from 'components/state';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const CustomFormNarrative = ({
  narrativeData = '',
  setNarrativeUpdated,
  syncON,
  setSyncON,
  currentForm,
  setCurrentForm
}) => {
  const { clinicalNote } = useClinicalNoteContext();
  const textAreaRef = useRef();

  const user = useRecoilValue(userState);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    doNotShowAgainCheckbox: false
  });

  const clearNarrative = () => setCurrentForm((prevState) => ({ ...prevState, narrative: null }));

  const shouldHideConfirmationModalNextTime = () => {
    const narrative_sync_checkbox = localStorage.getItem('narrative_sync_checkbox');

    const parsedNarrativeSyncCheckbox =
      typeof narrative_sync_checkbox === 'string' ? JSON.parse(narrative_sync_checkbox) : {};

    return (
      parsedNarrativeSyncCheckbox?.doNotShowConfirmModal &&
      parsedNarrativeSyncCheckbox?.userId === user?.id
    );
  };

  const switchSyncNarrative = () => {
    const hasUserCheckedDoNotShowAgain = shouldHideConfirmationModalNextTime();

    if (!syncON && !hasUserCheckedDoNotShowAgain) {
      setConfirmationModal((prevState) => ({ ...prevState, show: true }));
      return;
    }

    setSyncON((prev) => !prev);
  };

  const handleContinue = () => {
    setSyncON(true);

    if (confirmationModal?.doNotShowAgainCheckbox) onCheckboxChange();

    setConfirmationModal((prevState) => ({
      ...prevState,
      show: false,
      doNotShowAgainCheckbox: false
    }));
  };

  const options = [
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Switch onChange={() => null} checked={syncON} />
          <p>{syncON ? 'Unsync' : 'Sync'} narrative</p>
        </div>
      ),
      onClick: () => switchSyncNarrative()
    },
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Icon icon="new-delete-macro" color="primary" size={19} className="cursor-pointer" />
          <p>Clear narrative</p>
        </div>
      ),
      onClick: () => clearNarrative()
    },
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Icon icon="new-npi" size={19} className="cursor-pointer" />
          <p>{currentForm?.narrative ? 'Update' : 'Generate'} narrative</p>
        </div>
      ),
      onClick: () => generateNarrative({ currentForm, setCurrentForm })
    }
  ];

  const onCheckboxChange = () => {
    const objectToInsert = JSON.stringify({
      doNotShowConfirmModal: event,
      userId: user?.id
    });

    localStorage.setItem('narrative_sync_checkbox', objectToInsert);
  };

  const handleQuillChange = (content, delta, source) => {
    if (delta?.ops?.some((op) => op.retain !== undefined) && source === 'user') {
      setSyncON(false);
    } else {
      setSyncON(true);
    }
    setNarrativeUpdated(true);
    setCurrentForm((prev) => ({ ...prev, narrative: content }));
  };

  const colorBasedOnSync = syncON ? 'text-primary-600' : 'text-danger-600';

  const NarrativeLabelWithSwitch = () => {
    return (
      <div className="flex items-center gap-1.5 transition-all hover:bg-primary-50">
        <p>
          Narrative - <span className={colorBasedOnSync}>(Sync {syncON ? 'ON' : 'OFF'})</span>
        </p>
        <div className="flex">
          <Switch onChange={switchSyncNarrative} checked={syncON} />
        </div>
      </div>
    );
  };

  return (
    <div className="!mt-3">
      <Textarea
        isEditor
        label={<NarrativeLabelWithSwitch />}
        placeholder="Type narrative"
        transcribing
        value={narrativeData}
        forwardedRef={textAreaRef}
        id="narrative-custom-form"
        data-qa="narrative-custom-form"
        name="narrative-custom-form"
        onChange={handleQuillChange}
        disabled={clinicalNote?.locked}
        className="min-h-[200px]"
        options={options}
      />

      {confirmationModal?.show && (
        <Confirm
          primaryBtnTxt="Continue"
          title="Sync Narrative"
          icon="new-warning-red"
          variant="danger"
          message={
            <div>
              <p>
                Enabling Sync Narrative will automatically generate the narrative as you interact
                with the form,{' '}
                <i className="text-danger-600">
                  causing any manually entered text in the narrative box to be lost.
                </i>
              </p>
              <div className="!mt-3">
                <Checkbox
                  id="confirm-narrative-checkbox"
                  label={<p className="text-sm">Do not show this message again</p>}
                  color="blue"
                  isChecked={confirmationModal?.doNotShowAgainCheckbox}
                  onChange={(event) =>
                    setConfirmationModal((prevState) => ({
                      ...prevState,
                      doNotShowAgainCheckbox: event.target.checked
                    }))
                  }
                />
              </div>
            </div>
          }
          handleContinue={handleContinue}
          handleOpen={confirmationModal?.show}
          handleClose={() => setConfirmationModal((prevState) => ({ ...prevState, show: false }))}
        />
      )}
    </div>
  );
};

export default CustomFormNarrative;
