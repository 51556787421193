import Tippy from '@tippyjs/react';
import React, { useRef } from 'react';
import { Capitalize, ia, Truncate } from '../../../../lib/helpers/utility';
import useOutsideClick from '../../../../lib/hooks/useOutsideClick';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';
import Popover from '../../../shared/Popovers/Popover/Popover';

export default function Eligibility({
  setShow = false,
  show = false,
  insuranceProfilesList = [],
  callback = () => {},
  loading = false,
  existEligibility = false,
  eligibilityCounter = 0,
  eligibilityLimitReached = false
}) {
  const eligibilityRef = useRef();
  useOutsideClick(eligibilityRef, () => {
    setShow(false);
  });
  const handleClick = () => {
    insuranceProfilesList.length > 1 ? setShow(!show) : callback(insuranceProfilesList[0].id);
  };
  return (
    <div ref={eligibilityRef} className="relative">
      {existEligibility ? (
        <Tippy
          content={
            !eligibilityLimitReached
              ? 'Refresh eligibility status'
              : 'You are out of eligibility requests. Please contact support!'
          }
          className="tippy-dark">
          <div className="rounded !p-1 hover:cursor-pointer hover:bg-primary-50">
            <Icon
              stroke
              color="primary"
              icon="new-refresh-arrows"
              disabled={eligibilityLimitReached}
              onClick={eligibilityCounter && handleClick}
            />
          </div>
        </Tippy>
      ) : (
        <>
          {eligibilityCounter ? (
            <Button
              size="small"
              transparent
              type="primary"
              loadingIcon={loading}
              text="Run Eligibility"
              onClick={handleClick}
            />
          ) : (
            <Tippy
              content="You are out of eligibility requests. Please contact support!"
              className="tippy-dark ">
              <div>
                <Button
                  disabled
                  size="small"
                  transparent
                  type="primary"
                  text="Run Eligibility"
                  onClick={handleClick}
                />
              </div>
            </Tippy>
          )}
        </>
      )}

      <Popover handleOpen={show} className="PopoverDate modal__up">
        <div className="w-80">
          {ia(insuranceProfilesList) &&
            insuranceProfilesList.map((i, idx) => {
              return (
                <div
                  key={idx}
                  className="!my-2 cursor-pointer rounded-lg bg-neutral-50 transition-all duration-300 hover:bg-primary-100"
                  onClick={() => callback(i.id)}>
                  <div className="!p-2">
                    <label className="cursor-pointer font-600">{Capitalize(i.type)}</label>
                    <p className="">{Truncate(i.payer_name, 30)}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </Popover>
    </div>
  );
}
