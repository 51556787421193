import { getEnabledComponents } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import CH3DFormsAI from './components/forms/3D/3DFormsAI';
import HPFormsAI from './components/forms/HP/HPFormsAI';
import INTAKEFormsAI from './components/forms/INTAKE/IntakeFormsAI';
import PROGRESSFormsAI from './components/forms/PROGRESS/PROGRESSFormsAI';
import SOAPFormsAI from './components/forms/SOAP/SOAPFormsAI';
import { generateSOAPList } from '../lib/generateSOAPList';
import { generateHPList } from '../lib/generateHPList';
import { generateIntakeList } from '../lib/generateIntakeList';
import { generateProgressList } from '../lib/generateProgressList';

export const TYPE_OF_NOTES = [
  {
    label: 'H&P',
    type: `hp`,
    onClick: () => {},
    checked: false,
    forms: HPFormsAI
  },
  {
    label: 'Intake',
    type: `intake`,
    onClick: () => {},
    checked: false,
    forms: INTAKEFormsAI
  },
  {
    label: 'SOAP',
    type: `soap`,
    onClick: () => {},
    checked: false,
    forms: SOAPFormsAI
  },
  {
    label: 'Progress',
    type: `progress`,
    onClick: () => {},
    checked: false,
    forms: PROGRESSFormsAI
  },
  {
    label: '3D Charting',
    type: `3d`,
    onClick: () => {},
    checked: false,
    forms: CH3DFormsAI
  }
];

export const getTypeOfNotes = ({
  cnDisplaySettings,
  advancedHP,
  advancedSOAP,
  customFormTypes
}) => {
  const customFormTypeEntries = Object.entries(customFormTypes || {}).reduce(
    (acc, [key, value]) => {
      acc.components = getEnabledComponents(() => {}, key, cnDisplaySettings, value, true);
      acc.label = 'Custom Form Type';
      acc.type = key;
      acc.id = key;
      acc.includedInHashtags = false;
      return { [key]: acc };
    },
    {}
  );

  const typeOfNotes = {
    hp: {
      label: 'H&P',
      type: 'hp',
      id: 'hp',
      components: getEnabledComponents(generateHPList, 'hp', cnDisplaySettings, advancedHP),
      forms: HPFormsAI,
      includedInHashtags: false
    },
    intake: {
      label: 'Intake',
      type: 'intake',
      id: 'intake',
      components: getEnabledComponents(generateIntakeList, 'intake', cnDisplaySettings, advancedHP),
      forms: INTAKEFormsAI,
      includedInHashtags: false
    },
    soap: {
      label: 'SOAP',
      type: 'soap',
      id: 'soap',
      components: getEnabledComponents(generateSOAPList, 'soap', cnDisplaySettings, advancedSOAP),
      forms: SOAPFormsAI.component,
      includedInHashtags: false
    },
    progress: {
      label: 'Progress',
      type: 'progress',
      id: 'progress',
      components: getEnabledComponents(
        generateProgressList,
        'progress',
        cnDisplaySettings,
        advancedSOAP
      ),
      forms: PROGRESSFormsAI,
      includedInHashtags: false
    },
    '3d': {
      id: '3d',
      type: '3d',
      label: '3D Charting',
      components: { face: { title: 'Face', path: 'face' } },
      forms: CH3DFormsAI,
      includedInHashtags: false
    },
    ...customFormTypeEntries
  };

  return typeOfNotes;
};
