import { Honeybadger } from '@honeybadger-io/react';
import { tippy } from '@tippyjs/react';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import { socket } from 'api/Socket';
import { clinicalNoteSections, defaultPracticeSettings, defaultSettings } from '../constants';
import { formatDate, ia, io } from 'lib/helpers/utility';
import { findOpenAndCloseClinicHours } from 'lib/helpers/workingHours';
import adminState from './admin/lib/adminState';
import practiceState from './practice/practiceState';
import state from './state';
import { uniqueId } from 'filestack-js';
import { useQueryClient } from '@tanstack/react-query';
import { getPatients } from 'api/Patients';
import _ from 'lodash';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { datadogRum } from '@datadog/browser-rum';
import { setRecoil } from 'recoil-nexus';

export const connectToSocket = (sessionToken, sessionClientId) => {
  try {
    if (socket.connected || !sessionToken) {
      return;
    }
    socket.connect();
    initializeUserSocket(socket, sessionToken, sessionClientId);
  } catch (error) {
    console.error(error);
  }
};

export const initializeUserSocket = (socket, sessionToken = null, sessionClientId = null) => {
  try {
    socket.emit('user-init', { ok: true });
    socket.on('user-init', (response) => {
      if (response?.ok) {
        socket.emit('user-auth', { token: sessionToken, sessionClientId });
      }

      socket.off('user-init');
    });

    socket.on('data-update', (data) => {
      switch (data?.kind) {
        case 'myscribe-ai':
          switch (data?.vertical) {
            case 'clinical_narrative':
              setRecoil(state.clinicalNote, (ps) => {
                if (ps[data?.form_type]) {
                  return {
                    ...ps,
                    [data?.form_type]: { ...ps[data?.form_type], narrative: data?.response }
                  };
                } else {
                  return { ...ps, [data?.form_type]: data?.response };
                }
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    });

    return () => {
      socket.off('user-auth', { sessionToken, sessionClientId });
    };
  } catch (error) {
    console.error(error);
  }
};

export default function Utility() {
  const [user_state, setUserState] = useRecoilState(state.userState);
  const [practice_state] = useRecoilState(practiceState.current_member);
  const [currentPractice, setCurrentPractice] = useRecoilState(practiceState.currentPractice);
  const [admin_state, setAdminState] = useRecoilState(adminState.current_admin);
  const [, setPermissions] = useRecoilState(state.permissions);
  const [, setOwnerPractices] = useRecoilState(practiceState.ownerPractices);
  const [settings, setSettings] = useRecoilState(state.settings);
  const [hasBeenAuthChecked, setHasBeenAuthChecked] = useRecoilState(state.hasBeenAuthChecked);
  const [ownerSelectedLocation, setOwnerSelectedLocation] = useRecoilState(
    practiceState.ownerSelectedLocation
  );
  const setKiosk = useSetRecoilState(state.kioskState);
  const setAppointmentFilters = useSetRecoilState(practiceState.calendarFilters);
  const queryClient = useQueryClient();
  const isSelfScheduling = localStorage.getItem('stepsCompleted');
  const navigate = useNavigate();
  const location = useLocation() || {};
  const { device } = useUIContext();

  const checkSessionStorage = async () => {
    let sessionClientId = sessionStorage.getItem('sessionClientId');

    if (!sessionClientId) {
      sessionClientId = uniqueId(32);
      sessionStorage.setItem('sessionClientId', sessionClientId);
    }

    return sessionClientId;
  };

  const checkUserAndSessionClientId = async () => {
    const sessionClientId = await checkSessionStorage();
    userChecks(sessionClientId);
  };

  useEffect(() => {
    if (!hasBeenAuthChecked.checked) {
      checkUserAndSessionClientId();
    }
    if (ownerSelectedLocation) {
      checkToken();
    }
  }, [hasBeenAuthChecked, ownerSelectedLocation]);

  useEffect(() => {
    try {
      let newLocation = '';
      if (location?.pathname?.includes('/portal')) {
        newLocation = 'portal';
      } else if (location?.pathname?.includes('/myportal')) {
        newLocation = 'myportal';
      } else if (location?.pathname?.includes('/admin')) {
        newLocation = 'admin';
      }
      document.documentElement.className = newLocation;
    } catch (error) {
      console.error(error);
    }

    if (!location?.pathname.includes('book') && isSelfScheduling) clearAppointmentBook();
  }, [location]);

  useEffect(() => {
    getPermissions();
  }, [user_state, practice_state, admin_state]);

  useEffect(() => {
    let path = location?.pathname.split(/\//g);
    if (path && path[1] !== 'admin') {
      getSettings();
    }
  }, [user_state, practice_state]);

  const { pathname } = useLocation();

  useEffect(() => {
    pathname.includes('login') && queryClient.invalidateQueries();
  }, [pathname]);

  const clearAppointmentBook = async () => {
    await requestApi({
      url: '/api/patient/appointment/self_schedule',
      params: { type: 'deleteCookie' },
      navigate
    });
    localStorage.removeItem('stepsCompleted');
  };

  const userChecks = async (sessionClientId) => {
    if (hasBeenAuthChecked.isImpersonate) {
      await getAdminData();
      setHasBeenAuthChecked(() => ({ isImpersonate: false, checked: true }));
      return;
    }

    // get current path
    let path = location?.pathname.split(/\//g);
    // check if user is logged in
    if (user_state.logged_in || practice_state.logged_in || admin_state.logged_in) {
      setHasBeenAuthChecked((prev) => ({ ...prev, checked: true }));
      return;
    }
    // check if the path is in the ignored list or ignored sub-paths
    if (
      ['ia'].includes(location?.pathname) ||
      [
        'password',
        'invoice',
        'ia',
        'contact',
        'book',
        'terms',
        'mfa-description',
        'privacy',
        'activate',
        'kiosk',
        'checkin',
        'start-checkin',
        'icons',
        'instant-packet',
        'select-practice',
        'unsubscribe'
      ].includes(path[1]) ||
      ['password', 'ia'].includes(path[2])
    ) {
      setHasBeenAuthChecked((prev) => ({ ...prev, checked: true }));
      return;
      // , 'terminal_integration_test', 'button'
    }
    // check if path is admin or not
    if (path[1]) {
      if (path[1] === 'admin') {
        await getAdminData();
      } else {
        await checkToken(sessionClientId);
        await getPermissions();
        await getSettings();
      }
    }

    if (location.pathname === '/') {
      // await getAdminData();
      await checkToken();
      await getPermissions();
      await getSettings();
    }
    setHasBeenAuthChecked((prev) => ({ ...prev, checked: true }));
    return;
  };

  tippy.setDefaultProps({
    theme: 'light',
    touch: device === 'desktop' || device === 'laptop' ? true : false
  });

  const getAdminData = async () => {
    const data = localStorage.getItem('admin-state');
    const resData = await requestApi({ url: '/api/user/check_token', navigate });
    if (resData?.token_valid) {
      if (data) {
        setAdminState({ ...JSON.parse(data), logged_in: true });
        getPermissions();
      }
    } else {
      localStorage.clear();
      navigate('/admin/login');
    }
  };

  const checkToken = async (sessionClientId) => {
    try {
      const resData = await requestApi({ url: '/api/user/check_token', navigate });
      const { token_valid, user_data, is_admin, session_token, socketSession, kiosk } = resData;

      if (kiosk) {
        setKiosk({
          isKiosk: true,
          ...kiosk
        });
      }

      if (token_valid) {
        connectToSocket(socketSession ?? session_token, sessionClientId);
        user_data?.display_settings &&
          io(user_data?.display_settings?.appointmentFilters) &&
          setAppointmentFilters(user_data.display_settings.appointmentFilters);

        const displaySettings = _.merge({}, defaultSettings, user_data?.display_settings);
        setUserState({
          ...user_state,
          ...user_data,
          display_settings: displaySettings || defaultSettings,
          authenticated: true,
          logged_in: true
        });
        getPermissions();

        const currPractice = await getCurrentPractice();

        let newUser = {
          id: user_data.id,
          name: `${user_data?.f_name} ${user_data?.l_name}`,
          email: user_data?.email,
          admin: is_admin
        };

        if (currPractice) {
          newUser['practiceId'] = currPractice?.id;
          newUser['practiceName'] = currPractice?.name;
        }

        datadogRum.setUser(newUser);

        if (is_admin) {
          datadogRum.LogRocket &&
            LogRocket.identify(`admin_${user_data?.user_id}`, {
              name: `${user_data?.f_name} ${user_data?.l_name}`,
              email: user_data?.email,
              isAdmin: is_admin
            });
          Honeybadger.setContext({
            user_id: user_data?.user_id,
            user_email: user_data?.email,
            admin: true
          });
        } else {
          LogRocket &&
            LogRocket.identify(user_data?.user_id, {
              name: `${user_data?.f_name} ${user_data?.l_name}`,
              email: user_data?.email,
              practiceName: currPractice?.name,
              practiceId: currPractice?.id,
              isAdmin: false
            });
          Honeybadger.setContext({
            user_id: user_data?.user_id,
            user_email: user_data?.email,
            practice_name: currPractice?.name,
            practice_id: currPractice?.id
          });
        }
      } else {
        localStorage.clear();
        localStorage.setItem('redirectTo', location?.pathname + location?.search);
        navigate('/login');
      }
    } catch (error) {
      localStorage.clear();
      Honeybadger.notify(`checkToken: ${error}`);
    }
  };

  const getCurrentPractice = async () => {
    try {
      const resData = await requestApi({ url: '/api/practice/current_practice', navigate });
      const { practice, child_practices, selected_practice_id } = resData;
      if (practice) {
        const workingHours = findOpenAndCloseClinicHours(practice?.available_times);
        setCurrentPractice({
          ...currentPractice,
          id: practice.id,
          name: practice.name,
          email: practice.email,
          phone: practice.phone,
          address: practice.practiceAddress.fullAddress,
          header_photo: practice.header_photo,
          timezone: practice.timezone,
          display_settings: { ...defaultPracticeSettings, ...practice?.display_settings },
          working_hours: workingHours,
          appointment_types: practice?.appointment_types,
          eligibility_counter: practice?.eligibility_counter,
          care_credit: practice?.care_credit,
          surcharge: practice?.surcharge,
          surcharge_percentage: practice?.surcharge_percentage,
          surcharge_enabled: practice?.surcharge_enabled,
          socket_id: practice?.socket_id
        });

        if (ia(child_practices)) {
          setOwnerPractices(child_practices);
        }
        if (selected_practice_id) {
          setOwnerSelectedLocation(selected_practice_id);
        }
      }
      return { id: currentPractice?.id, name: currentPractice?.name };
    } catch (error) {
      Honeybadger.notify(`getCurrentPractice: ${error}`);
    }
  };

  const getPermissions = async () => {
    if (
      permissions?.loaded ||
      (!user_state.logged_in &&
        !admin_state.logged_in &&
        user_state.logged_in === admin_state.logged_in)
    ) {
      return;
    }
    const resData = await requestApi({ url: '/api/permissions/get', navigate });
    const permissions = resData?.permissions;
    if (permissions) {
      setPermissions({ ...permissions });
    }
  };

  const getSettings = async () => {
    if (
      settings.loaded ||
      (!user_state.logged_in &&
        !admin_state.logged_in &&
        user_state.logged_in === admin_state.logged_in)
    ) {
      return;
    }
    const resData = await requestApi({ url: '/api/practice/settings/get', navigate });
    const loadedSettings = resData?.settings;
    if (loadedSettings) {
      setSettings({ ...clinicalNoteSections, ...loadedSettings?.clinical_notes });
    }
  };

  return <></>;
}

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    customLabel: (
      <div>
        {p?.fullName && <span>{p.fullName}</span>}
        {p.dob && <span className="pl-1 text-xs text-neutral-500">{formatDate(p.dob)}</span>}
      </div>
    ),
    label: p?.fullName,
    value: p?.id
  }));
};
