import _ from 'lodash';
import { ia, spaceToKebabCase } from '../../../../../../lib/helpers/utility';
import {
  ActiveSymptoms,
  FunctionalImpairment,
  Goals,
  HPIRevisions,
  Interventions,
  Progress,
  ReviewSystems,
  RiskFactors,
  Session
} from '../../../../../shared/Forms/Custom/CustomFormInitialStates';

export const selectedInitialState = (clinicalNote, cnDisplaySettings, currentHpOverviewData) => ({
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.complaints?.enabled !== false && {
      complaints: {
        title: 'Complaints',
        id: self.crypto.randomUUID(),
        checked: !!(clinicalNote?.present_illness || clinicalNote?.other_complaints),
        position: null
      }
    }),
  ...(cnDisplaySettings?.mha?.enabled &&
    cnDisplaySettings?.sections?.intake?.patientNeeds?.enabled && {
      patientNeeds: {
        title: 'Patient needs / presenting issues', //duplicate
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.present_illness,
        position: null
      }
    }),
  ...(cnDisplaySettings?.mha?.enabled &&
    cnDisplaySettings?.sections?.intake?.problemManifestationDefinition?.enabled !== false && {
      problemManifestationDefinition: {
        title: 'Problem manifestation / definition',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.other_complaints,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.hpi?.enabled !== false && {
      hpi: {
        title: 'History of present illness',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.hpi, HPIRevisions),
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap?.enabled &&
    cnDisplaySettings?.sections?.soap?.diagnosisCodes?.enabled !== false && {
      diagnosisCodes: {
        title: 'Diagnosis codes',
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.icd_10_codes),
        position: null
      }
    }),
  ...(cnDisplaySettings?.mha?.enabled &&
    cnDisplaySettings?.sections?.intake?.diagnosisCodes?.enabled !== false && {
      diagnosisCodes: {
        title: 'Diagnosis codes', //duplicate
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.icd_10_codes),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.diagnosisCodes?.enabled !== false && {
      diagnosisCodes: {
        title: 'Diagnosis codes', //duplicate
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.icd_10_codes),
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.ros?.enabled !== false && {
      ros: {
        title: 'Review of systems',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.ros, ReviewSystems),
        position: null
      }
    }),
  ...(cnDisplaySettings?.mha?.enabled &&
    cnDisplaySettings?.sections?.intake?.goals?.enabled !== false && {
      goals: {
        title: 'Goals',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.goals, Goals),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.goals?.enabled !== false && {
      goals: {
        title: 'Goals', //duplicate
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.goals, Goals),
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp.enabled &&
    cnDisplaySettings?.sections?.hp?.exam?.enabled !== false && {
      exam: {
        title: 'Exam',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.exam,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp.enabled &&
    cnDisplaySettings?.sections?.hp?.test?.enabled !== false && {
      test: {
        title: 'Test',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.test,
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.session?.enabled !== false && {
      session: {
        title: 'Session',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.session, Session),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.riskFactors?.enabled !== false && {
      riskFactors: {
        title: 'Risk factors',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.riskFactors, RiskFactors),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.sessionQuotesAndContents?.enabled !== false && {
      sessionQuotesAndContents: {
        title: 'Session quotes and contents',
        id: self.crypto.randomUUID(),
        checked: clinicalNote?.epn?.sessionQuotesAndContents,
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.activeSymptoms?.enabled !== false && {
      activeSymptoms: {
        title: 'Active symptoms',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.activeSymptoms, ActiveSymptoms),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.functionalImpairment?.enabled !== false && {
      functionalImpairment: {
        title: 'Functional impairment',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.functionalImpairment, FunctionalImpairment),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.interventions?.enabled !== false && {
      interventions: {
        title: 'Interventions',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.interventions, Interventions),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.progress?.enabled !== false && {
      progress: {
        title: 'Progress',
        id: self.crypto.randomUUID(),
        checked: !_.isEqual(clinicalNote?.epn?.progress, Progress),
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap.enabled &&
    cnDisplaySettings?.sections?.soap?.subjective?.enabled !== false && {
      subjective: {
        title: 'Subjective',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.subjective,
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap.enabled &&
    cnDisplaySettings?.sections?.soap?.objective?.enabled !== false && {
      objective: {
        title: 'Objective',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.objective,
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap.enabled &&
    cnDisplaySettings?.sections?.soap?.assessment?.enabled !== false && {
      assessment: {
        title: 'Assessment',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.assessment,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp.enabled &&
    cnDisplaySettings?.sections?.hp?.assessment?.enabled !== false && {
      assessment: {
        title: 'Assessment', //duplicate
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.assessment,
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap.enabled &&
    cnDisplaySettings?.sections?.soap?.plan?.enabled !== false && {
      plan: {
        title: 'Plan',
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.plan,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp.enabled &&
    cnDisplaySettings?.sections?.hp?.plan?.enabled !== false && {
      plan: {
        title: 'Plan', //duplicate
        id: self.crypto.randomUUID(),
        checked: !!clinicalNote?.plan,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.allergies?.enabled !== false && {
      allergies: {
        title: 'Allergies',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.allergies?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.vitals?.enabled !== false && {
      vitals: {
        title: 'Vitals',
        id: self.crypto.randomUUID(),
        checked: currentHpOverviewData?.vitals?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.medications?.enabled !== false && {
      medications: {
        title: 'Medications',
        id: self.crypto.randomUUID(),
        checked: ia(currentHpOverviewData?.medicationHistory?.response?.medications),
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.vaccines?.enabled !== false && {
      vaccines: {
        title: 'Vaccines',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.vaccines?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.weightLossAdministration?.enabled !== false && {
      weightLossAdministration: {
        title: 'Weight Loss Administration',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.weightLossAdministration?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.socialHistory?.enabled !== false && {
      socialHistory: {
        title: 'Social History',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.socialHistory?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.familyHistory?.enabled !== false && {
      familyHistory: {
        title: 'Family History',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.familyHistory?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.medicalHistory?.enabled !== false && {
      medicalHistory: {
        title: 'Medical History',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.medicalHistory?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.hp?.enabled &&
    cnDisplaySettings?.sections?.hp?.surgicalHistory?.enabled !== false && {
      surgicalHistory: {
        title: 'Surgical History',
        id: self.crypto.randomUUID(),
        checked: !!currentHpOverviewData?.surgicalHistory?.id,
        position: null
      }
    }),
  ...(cnDisplaySettings?.soap.enabled &&
    cnDisplaySettings?.sections?.soap?.cptCodes?.enabled !== false && {
      cptCodes: {
        title: 'CPT® codes',
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.cpt_codes),
        position: null
      }
    }),
  ...(cnDisplaySettings?.epn?.enabled &&
    cnDisplaySettings?.sections?.progress?.cptCodes?.enabled !== false && {
      cptCodes: {
        title: 'CPT® codes', //duplicated
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.cpt_codes),
        position: null
      }
    }),
  ...(((cnDisplaySettings?.hp?.enabled && cnDisplaySettings?.sections?.hp?.notes?.enabled) ||
    (cnDisplaySettings?.mha?.enabled && cnDisplaySettings?.sections?.intake?.notes?.enabled) ||
    (cnDisplaySettings?.soap?.enabled && cnDisplaySettings?.sections?.soap?.notes?.enabled) ||
    (cnDisplaySettings?.epn?.enabled && cnDisplaySettings?.sections?.progress?.notes?.enabled)) && {
    notes: {
      title: 'General Notes',
      id: self.crypto.randomUUID(),
      checked: !!clinicalNote?.notes,
      position: null
    }
  }),
  ...(cnDisplaySettings?.chart3d?.enabled &&
    ia(clinicalNote?.charts3d?.face?.points) && {
      chart3d: {
        title: '3D Charting', //duplicate
        id: self.crypto.randomUUID(),
        checked: ia(clinicalNote?.charts3d?.face?.points),
        position: null
      }
    }),
  providerSignature: {
    title: 'Provider Signature',
    id: self.crypto.randomUUID(),
    checked: true,
    position: null
  }
});

export const initialStatesRender = [
  'complaints',
  'patientNeeds',
  'problemManifestationDefinition',
  'hpi',
  'diagnosis',
  'ros',
  'goals',
  'exam',
  'test',
  'session',
  'riskFactors',
  'sessionQuotesAndContents',
  'activeSymptoms',
  'functionalImpairment',
  'interventions',
  'progress',
  'subjective',
  'objective',
  'assessment',
  'plan',
  'allergies',
  'medications',
  'vitals',
  'vaccines',
  'weightLossAdministration',
  'socialHistory',
  'medicalHistory',
  'familyHistory',
  'surgicalHistory',
  'cptCodes',
  'notes'
];

export const processAdvancedHPForms = (advancedHP) => {
  const getAdvancedHP = ia(advancedHP)
    ? Object.fromEntries(
        advancedHP.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'hp',
              id: self.crypto.randomUUID(),
              checked: !!item?.form,
              position: null,
              formType: createFormTypes(!!item?.form)
            }
          ];
        })
      )
    : {};

  return getAdvancedHP;
};

export const processAdvancedSOAPForms = (advancedSOAP) => {
  const getAdvancedSOAP = ia(advancedSOAP)
    ? Object.fromEntries(
        advancedSOAP.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'soap',
              id: self.crypto.randomUUID(),
              checked: !!item?.form,
              position: null,
              formType: createFormTypes(!!item?.form)
            }
          ];
        })
      )
    : {};

  return getAdvancedSOAP;
};

const createFormTypes = (isNarrativeChecked) => {
  return [
    {
      id: self.crypto.randomUUID(),
      title: 'Template',
      checked: false
    },
    {
      id: self.crypto.randomUUID(),
      title: 'Narrative',
      checked: isNarrativeChecked
    }
  ];
};

const advancedFormTitle = (item) => {
  return `${item?.form?.name || item?.name} #${item?.form_id || item?.id}`;
};
