import React, { useState } from 'react';
import { useMyScribeAIContext } from '../MyScribeAIContext';
import { MyTemplateContext } from './MyTemplateContext';

export const MyTemplateContextProvider = ({ children }) => {
  const { typeOfNotes } = useMyScribeAIContext();

  const [selectedChatComponents, setSelectedChatComponents] = useState([]);

  return (
    <MyTemplateContext.Provider
      value={{
        selectedChatComponents,
        setSelectedChatComponents,
        typeOfNotes
      }}>
      {children}
    </MyTemplateContext.Provider>
  );
};
