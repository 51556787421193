import cs from 'classnames';
import React, { useEffect, useRef } from 'react';
import Icon from '../../shared/Icon/Icon';
import Popup from '../../shared/Filters/Popup';
import { requestApi } from 'api/Api';
import { kinds } from './KioskRequests';

const Kiosk = ({ kiosk, idx, setShowPatients, close }) => {
  const [counter, setCounter] = React.useState(0);
  let interval = useRef(null);

  const add0 = (num) => (num < 10 ? '0' + num : num);

  useEffect(() => {
    const secondsTillNow = (new Date() - new Date(kiosk.started_at)) / 1000;
    setCounter(secondsTillNow);
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 1000);
    return () => clearInterval(interval.current);
  }, [kiosk.started_at]);

  const formatCounter = (counter) => {
    const hours = Math.floor(counter / 3600);
    const minutes = Math.floor((counter % 3600) / 60);
    const seconds = Math.floor(counter % 60);
    return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? add0(minutes) + 'm ' : ''}${add0(
      seconds
    )}s`;
  };

  return (
    <>
      <Popup
        trigger={(open) => (
          <div
            className={cs(
              'group flex w-full cursor-pointer items-center justify-between gap-6 !px-4 py-3 hover:bg-primary-50',
              open && '!bg-primary-50'
            )}
            data-qa="quick-actions-new-patient">
            <div className="flex items-center">
              <div
                style={{ backgroundColor: kiosk.color + '25' }}
                className={cs(
                  'mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg p-2 group-hover:!bg-white',
                  open && '!bg-white'
                )}>
                <Icon icon="kiosk" customColor={kiosk.color} />
              </div>
              <div className="flex flex-col">
                <span className="text-base font-500 text-primary-900">{kiosk.name}</span>
                <span className="text-xs text-neutral-600">{`${kiosk.user?.p_name || kiosk.user?.f_name || ''} ${kiosk.user?.l_name || ''}`}</span>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center">
              <p className="text-xs text-neutral-700">
                {kiosk?.started_at && formatCounter(counter)}
              </p>
              <p className="font-medium text-primary-600">{kinds[kiosk?.current_page || 'idle']}</p>
            </div>
          </div>
        )}
        width={160}
        on={['hover', 'focus']}
        contentStyle={{
          borderRadius: '8px',
          boxShadow: '0px 2px 16px 0px #004F6B33',
          marginLeft: '5px'
        }}
        position={['left center']}>
        <div className=" !py-[6px]">
          <div
            data-qa="edit-product-btn"
            className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
            onClick={() => {
              close();
              setShowPatients({ kiosk, type: 'payment' });
            }}>
            <Icon icon="money-send" className="cursor-pointer" color="primary" />
            <div className="ml-1 text-sm text-primary-900">New Payment</div>
          </div>
          <div
            data-qa="delete-product-btn"
            className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
            onClick={() => {
              close();
              setShowPatients({ kiosk, type: 'form' });
            }}>
            <Icon
              style={{ width: '18px' }}
              icon="clipboard-text"
              className="cursor-pointer"
              color="primary"
            />
            <div className="ml-1 text-sm text-primary-900">Send Form</div>
          </div>
          <div
            data-qa="delete-product-btn"
            className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
            onClick={() => {
              requestApi({
                url: '/api/kiosk/command',
                params: {
                  command: 'idle',
                  kioskId: kiosk.id
                }
              });
              close();
            }}>
            <Icon icon="timer" className="cursor-pointer" color="primary" />
            <div className="ml-1 text-sm text-primary-900">Idle</div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Kiosk;
