import React from 'react';

import { formatDate, mString } from 'lib/helpers/utility';
import { calculateProceduresTotal } from 'components/public/lib/utils';
import PatientInvoice from 'components/public/components/PatientInvoice';
import InvoiceDetailsItem from 'components/public/components/InvoiceDetailsItem';

const InvoiceDetails = ({ invoiceDetails, hasClaims }) => {
  const {
    tax = 0,
    products,
    packages,
    created_at,
    procedures,
    dateOfService,
    tax_amount_cents = 0,
    discount = 0,
    prevDiscount = 0,
    amount_paid = 0,
    surcharge_amount = 0,
    adjustment = 0
  } = invoiceDetails || {};

  const allItems = [...(procedures || []), ...(products || []), ...(packages || [])];

  const {
    charge = 0,
    pt_adjustment = 0,
    ins_payment = 0,
    ins_adjustment = 0
  } = calculateProceduresTotal(allItems);

  let total = charge - tax_amount_cents;

  const amountDue =
    total +
    tax -
    amount_paid -
    discount -
    ins_payment -
    ins_adjustment -
    tax_amount_cents -
    adjustment;

  return (
    <div className="mx-3 mt-4">
      <div className="mt-3">
        <hr className="-mx-2 my-8 h-px border-0 bg-gray-800" />

        <h3 className="font-semibold">
          {formatDate(dateOfService || created_at)} (Date of Service)
        </h3>

        {allItems.map((procedure) => {
          const {
            id,
            name,
            type,
            charge = 0,
            pt_balance,
            total_cost_cents,
            total_amount_cents
          } = procedure || {};

          const finalCharge = ['self_pay', 'cash'].includes(type)
            ? pt_balance ?? total_cost_cents ?? 0
            : total_amount_cents ?? charge ?? pt_balance ?? total_cost_cents ?? 0;

          procedure.charge = finalCharge;

          return (
            <div key={id} className="mx-3 mb-2 mt-3">
              <h6 className="text-sm font-semibold">{name}</h6>

              <InvoiceDetailsItem
                hasClaims={hasClaims}
                invoice={{
                  charge: finalCharge,
                  hasInsurance: type == 'primary' ? true : false,
                  ...calculateProceduresTotal([procedure])
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="px-3">
        <div className="flex justify-between border-t pt-3">
          <span className="font-bold">Subtotal</span>
          <span className="text-lg font-bold">{mString(total)}</span>
        </div>

        {ins_payment > 0 && (
          <div className="mt-3 flex justify-between">
            <span>Insurance Covered</span>
            <span>-{mString(ins_payment)}</span>
          </div>
        )}

        {ins_adjustment > 0 && (
          <div className="mt-3 flex justify-between">
            <span>Insurance Adjusted</span>
            <span>-{mString(ins_adjustment)}</span>
          </div>
        )}

        {amount_paid > 0 && (
          <div className="mt-3 flex justify-between">
            <span>You Paid</span>
            <span>-{mString(amount_paid)}</span>
          </div>
        )}

        {discount > 0 && (
          <div className="mt-3 flex justify-between">
            <span>Discount</span>
            <span>-{mString(discount)}</span>
          </div>
        )}

        {adjustment > 0 && (
          <div className="mt-3 flex justify-between">
            <span>Adjustment</span>
            <span>-{mString(adjustment)}</span>
          </div>
        )}

        {tax > 0 && (
          <div className="mt-3 flex justify-between">
            <span>Tax</span>
            <span>{mString(tax)}</span>
          </div>
        )}

        <div className="mt-3 flex justify-between border-t pt-3">
          <span className="font-bold">Amount Due</span>
          <span className="text-xl font-bold">{mString(amountDue)}</span>
        </div>
      </div>
    </div>
  );

  <hr className="my-8 h-px border-0 bg-gray-800" />;
};

export default InvoiceDetails;
