import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Progress from './Progress';

const ProgressSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Progress', parentKey: 'progress', childKey: 'progress' }}
      title={components?.progress?.title}
      id={components?.progress?.id}
      disabled={clinicalNote?.locked}>
      <Progress sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ProgressSection;
