import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import React, { useState } from 'react';
import FormsAI from '../FormsAI';
import AdvancedForm from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/AdvancedForm/AdvancedForm';

const getFormsList = (customFormType, cnDisplaySettings) => {
  const formsList = {
    ...assignAdvTitlesToComponent(customFormType, AdvancedForm, cnDisplaySettings)
  };
  return formsList;
};

const CustomFormsAI = ({ inputName, type }) => {
  const { customFormTypes, cnDisplaySettings } = useClinicalNoteContext();

  const customFormType = customFormTypes?.[type];

  const [finalFormsList] = useState(getFormsList(customFormType, cnDisplaySettings));

  return <FormsAI formsList={finalFormsList} inputName={inputName} />;
};

export default CustomFormsAI;
