import React from 'react';
import Icon from '../../../../shared/Icon/Icon';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../../api/Api';
import { showAlert } from '../../../../shared/Alert/Alert';
import { Popover } from '@headlessui/react';

const PayerTableRow = ({ payer, index, setUpdateAddressModal, lastIndex }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const deletePayer = async (payerId) => {
    const onSuccess = () => {
      showAlert({ title: 'Success!', message: 'Payer deleted successfully!', color: 'success' });
      queryClient.invalidateQueries(['getPayers']);
    };

    requestApi({
      url: '/api/practice/settings/payers/delete',
      params: { payerId },
      onSuccess,
      navigate
    });
  };

  return (
    <>
      <tr key={`payer-index-${index}`}>
        <td>{payer.id}</td>
        <td>{payer.name}</td>
        <td>{payer.payerAddress?.fullAddress}</td>
        <td className="relative flex justify-end">
          <Popover className="relative">
            {({ open, close }) => (
              <div>
                <Popover.Button
                  data-qa={`payer-row-${payer.name}`}
                  className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                    !open && 'hover:!bg-white'
                  } ${open && '!bg-primary-700 transition-all'}`}>
                  <Icon
                    icon={'three-dots'}
                    className="flex cursor-pointer"
                    color={open ? 'white' : 'black'}
                  />
                </Popover.Button>

                <Popover.Panel
                  className={`absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white ${
                    lastIndex ? '!-top-[90px]' : 'top-10'
                  } !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`}>
                  <div
                    data-qa="edit-package-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      setUpdateAddressModal(payer);
                      close();
                    }}>
                    <Icon icon={'new-edit'} color={'primary'} />
                    <div className="ml-1 text-sm text-primary-900">Update address</div>
                  </div>
                  <div
                    data-qa="delete-package-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      deletePayer(payer.id);
                      close();
                    }}>
                    <Icon icon={'trash'} color={'primary'} />
                    <div className="ml-1 text-sm text-primary-900">Delete</div>
                  </div>
                </Popover.Panel>
              </div>
            )}
          </Popover>
        </td>
      </tr>
    </>
  );
};

export default PayerTableRow;
