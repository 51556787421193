import React, { useEffect, useRef, useState } from 'react';
import Input from '../../../../shared/Input/Input';
import Select from '../../../../shared/Select/Select';
import CurrencyInput from '../../../../shared/CurrencyInput/CurrencyInput';
import Textarea from '../../../../shared/Textarea/Textarea';
import { Popover } from '@headlessui/react';
import { formatDate, optionify } from '../../../../../lib/helpers/utility';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { capitalize } from 'lodash';
import * as filestack from 'filestack-js';
import Icon from '../../../../shared/Icon/Icon';
import Tippy from '@tippyjs/react';
import Button from '../../../../shared/Buttons/Button';

import { components } from 'react-select';

import CreatableSelect from 'react-select/creatable';

import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from '../../../../shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../../api/Api';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';
import ColorPickerRgb from '../../../../shared/ColorPickerRgb/ColorPickerRgb';
import cs from 'classnames';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const productsStatuses = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Out of stock',
    value: 'out of stock'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Discontinued',
    value: 'discontinued'
  }
];
export default function ProductForm({ formik, categories }) {
  const { setFieldValue, values, touched, errors, handleChange, handleBlur } = formik;

  const queryClient = useQueryClient();

  const handleChangeQuantity = (value) => {
    const sanitizedValue = value?.replace(/\./g, '');
    setFieldValue('quantity', sanitizedValue);
  };

  useEffect(() => {
    handleChangePriceWithTax();
  }, [values.amount_cents, values.local_tax_rate, values.state_tax_rate]);

  const handleChangePriceWithTax = () => {
    let priceWithTax = Number(values.amount_cents);
    if (!priceWithTax) {
      setFieldValue('amount_cents_with_tax', 0);
      return;
    }
    if (values.local_tax_rate) {
      priceWithTax += Number(values.amount_cents * (values.local_tax_rate / 10000));
    }
    if (values.state_tax_rate) {
      priceWithTax += Number(values.amount_cents * (values.state_tax_rate / 10000));
    }
    setFieldValue('amount_cents_with_tax', priceWithTax);
  };

  const openFilestackPicker = () => {
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      maxSize: 3 * 1024 * 1024
    };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };

    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      if (handle) {
        formik.setFieldValue('image', JSON.stringify(handle));
      }
    } catch (error) {}
  };

  const selectInputRef = useRef();
  const navigate = useNavigate();
  const [deleteCategoryModal, setDeleteCategoryModal] = useState();

  const deleteCategory = async () => {
    const onSuccess = (data) => {
      setDeleteCategoryModal({ label: deleteCategoryModal?.label });
      queryClient.invalidateQueries(['getProductCategories']);
    };
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete categories!",
          color: 'danger'
        });
      }

      setDeleteCategoryModal({ label: deleteCategoryModal?.label });
    };
    await requestApi({
      url: 'api/product/category/delete',
      params: {
        categoryId: deleteCategoryModal?.value
      },
      onSuccess,
      onError,
      navigate
    });
  };

  const createCategory = async (newCategory) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);

      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create categories!",
          color: 'danger'
        });
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(['getProductCategories'], (prevData) => {
        return {
          ...prevData,
          categories: [...prevData.categories, data.category]
        };
      });
      showAlert({
        title: 'New category added',
        color: 'success'
      });
      formik.setFieldValue('category_id', data.category.id);
    };

    requestApi({
      url: 'api/product/category/create',
      navigate,
      onError,
      onSuccess,
      params: { category: { name: newCategory } }
    });
  };

  const Option = (props) => (
    <components.Option {...props}>
      {props.data.__isNew__ ? (
        <div className="flex cursor-pointer items-center gap-3 text-left text-sm text-neutral-800 hover:bg-primary-50">
          <Icon icon="new-add-circle"></Icon>
          {props.data.value}
        </div>
      ) : (
        <div className="flex w-full justify-between gap-3">
          {props.data.label}
          <button
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteCategoryModal(props.data);
            }}>
            <Icon className="cursor-pointer" icon="trash" color="danger"></Icon>
          </button>
        </div>
      )}
    </components.Option>
  );

  return (
    <div className="flex !h-[calc(100vh-150px)] flex-col justify-between !gap-5">
      <div className="grid !gap-5">
        <div className="grid grid-cols-2 !gap-5">
          <div>
            <div className={cs('flex gap-2 pb-3', touched.code ? 'items-center' : 'items-end')}>
              <ColorPickerRgb
                color={values.color}
                onChange={(color) => setFieldValue('color', color)}
              />
              <Input
                label="Code"
                placeholder="Enter code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.code && errors.code}
                id="productCode"
                data-qa="product-code"
                name="code"
                className="flex-1"
                required
              />
            </div>

            <Input
              label="Name"
              placeholder="Enter product name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              id="productName"
              data-qa="product-name"
              name="name"
              className="pb-3"
              required
            />
            <div>
              <div className="flex items-center gap-1">
                <label className="font-500">Category</label>
                <Tippy content="(Start typing to create a category)" className="tippy-dark ">
                  <div className="flex h-10">
                    <Icon icon="new-info" color="neutral" data-qa="click-new-category" />
                  </div>
                </Tippy>
              </div>

              <Select
                isCreatable={true}
                onChange={({ value }) => setFieldValue('category_id', value)}
                value={
                  values.category_id && {
                    label: categories?.find((item) => item.id === values.category_id)?.name
                  }
                }
                options={optionify(categories, 'name', 'id')}
                placeholder="Type and select a Category"
                noOptionsMessage={() => 'Start typing to create a category.'}
                error={touched.category_id && errors.category_id}
                id="productCategory"
                required
                onCreateOption={(e) => createCategory(e)}
                isClearable={false}
                forwardedRef={selectInputRef}
                components={{ Option }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="m-0 !pr-4 pb-[6px] text-sm font-500 text-neutral-800">Image</label>
            <div
              onClick={openFilestackPicker}
              className="flex h-auto flex-1 cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100">
              <div className="flex flex-col items-center justify-center">
                {values.image ? (
                  <img
                    className="object-contain"
                    style={{ maxWidth: '38%' }}
                    src={`https://cdn.filestackcontent.com/${JSON.parse(values.image).jpg}`}
                    alt="Product Image"
                  />
                ) : (
                  <>
                    <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                    <p className="pt-1 text-center text-sm font-400 text-neutral-500">
                      Click here or drag and <span>drop image to upload</span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Textarea
          label="Note"
          placeholder="Enter note"
          value={values.note}
          onChange={handleChange}
          error={errors.note}
          id="productNote"
          data-qa="product-note"
          name="note"
        />
        <div className="grid grid-cols-2 !gap-5">
          <div>
            <div className="flex items-center gap-1">
              <label className="font-500">Cost</label>
              <Tippy
                content="(Enter the practice’s expenses for this product. It includes manufacturing, materials, and associated costs. Helps determine profitability.)"
                className="tippy-dark ">
                <div className="flex h-10">
                  <Icon icon="new-info" color="neutral" />
                </div>
              </Tippy>
            </div>
            <CurrencyInput
              className="!h-10 !rounded-md border border-neutral-100"
              value={values.cost_cents}
              onValueChange={(val) => setFieldValue('cost_cents', val)}
              error={errors.cost_cents}
              id="productCost"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 !gap-5">
          <Select
            onChange={({ value }) => setFieldValue('status', value)}
            value={values.status && { label: capitalize(values.status) }}
            options={productsStatuses}
            placeholder="Type and select a status"
            label="Status"
            error={touched.status && errors.status}
            id="productStatus"
            required
            isClearable={false}
          />
          <Popover className="relative -mt-[3px]">
            {({ close }) => (
              <>
                <Popover.Button className="w-full p-0">
                  <Input
                    label="Expiration date"
                    rightIcon="new-calendar-gray"
                    placeholder={
                      values?.expiration_date
                        ? formatDate(formik?.values?.expiration_date)
                        : 'Select a date'
                    }
                    error={formik?.errors?.expiration_date}
                    id="productExpirationDateInput"
                    data-qa="product-calendar"
                  />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-10 flex shadow-md">
                  <Calendar
                    date={
                      values?.expiration_date
                        ? new Date(formik?.values?.expiration_date)
                        : new Date()
                    }
                    onChange={(date) => {
                      setFieldValue('expiration_date', moment.utc(date).add(12, 'h'));
                      close();
                    }}
                    id="productExpirationDateCalendar"
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>

        <div className="grid grid-cols-2 !gap-5">
          <Input
            label="Quantity"
            placeholder="Enter quantity"
            value={values.quantity}
            onChange={(e) => handleChangeQuantity(e.target.value)}
            onBlur={handleBlur}
            error={touched.quantity && errors.quantity}
            id="productQuantity"
            data-qa="product-quantity"
            name="quantity"
            type="number"
            required
          />
          <Input
            label="Quantity units"
            placeholder="Enter quantity units"
            value={values.quantity_units}
            onChange={handleChange}
            error={errors.quantity_units}
            id="productQuantityUnits"
            data-qa="quantity-units"
            name="quantity_units"
          />

          <Input
            label="LOT"
            placeholder="Enter lot"
            value={values.lot}
            onChange={handleChange}
            error={errors.lot}
            id="productLot"
            data-qa="lot"
            name="lot"
          />
        </div>
      </div>

      <div className="rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100 !p-4">
        <div className="flex w-full !gap-3">
          <CurrencyInput
            label="Price"
            value={values.amount_cents}
            onValueChange={(val) => setFieldValue('amount_cents', Number(val))}
            className="!h-10 !rounded-md border border-neutral-100"
            error={touched.amount_cents && errors.amount_cents}
            id="productPrice"
          />

          <CurrencyInput
            label="State Tax"
            value={values.state_tax_rate}
            onValueChange={(val) => setFieldValue('state_tax_rate', Number(val))}
            parentClassName="!w-[20%]"
            error={errors.state_tax_rate}
            rightIcon="new-percentage"
            id="productStateTax"
            prefix=""
          />
          <CurrencyInput
            label="Local Tax"
            prefix=""
            value={values.local_tax_rate}
            onValueChange={(val) => setFieldValue('local_tax_rate', Number(val))}
            parentClassName="!w-[20%]"
            error={errors.local_tax_rate}
            rightIcon="new-percentage"
            id="productLocalTax"
          />

          <CurrencyInput
            label="Subtotal"
            value={values.amount_cents_with_tax}
            disabled={true}
            error={errors.amount_cents_with_tax}
            key={values.amount_cents_with_tax}
            id="productSubtotal"
          />
        </div>
      </div>
      <Confirm
        handleOpen={deleteCategoryModal?.value}
        handleClose={() => setDeleteCategoryModal({ label: deleteCategoryModal?.label })}
        handleContinue={deleteCategory}
        title="Delete category"
        message={`Are you sure you want to delete "${deleteCategoryModal?.label}" category?`}
        variant="danger"
      />
    </div>
  );
}
