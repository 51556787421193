import React from 'react';
import { getEligibilityInfo } from '../eligibilityHelpers';
import { withErrorBoundary } from '../../../../../shared/Error/Boundary';

const EligibilityInfo = ({ eligibility, companyName }) => {
  const eligibilityInfo = getEligibilityInfo(eligibility);

  return (
    <div className="!rounded-lg bg-white px-4 !py-3">
      <h3 className="!mb-2 text-lg font-600 text-primary-900">{companyName}</h3>
      <div className="mt-3 flex">
        {eligibilityInfo.map((infoSection, index) => (
          <div className="flex-grow" key={index}>
            {infoSection.map((info, idx) => (
              <p key={idx} className="!mt-1 block text-sm !font-400 text-primary-900">
                <span className="mr-2 inline !font-500">{info.label}</span> {info.value}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(EligibilityInfo);
