import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import CurrentMedications from '../../../../../../../shared/Forms/Custom/CurrentMedications/CurrentMedications';

const MedicationsSection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData
}) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      updateFormType={{ name: 'Medications', parentKey: 'hp', childKey: 'medications' }}
      title={components?.medications?.title}
      id={components?.medications?.id}
      disabled={clinicalNote?.locked}>
      <CurrentMedications
        formId={formId}
        fromClinicalNote={true}
        defaultValue={defaultValue}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
        showNarrative
        setCurrentFormData={setCurrentFormData}
      />
    </Accordion>
  );
};

export default MedicationsSection;
