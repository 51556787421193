const atom = require('recoil').atom;
const { endOfDay, startOfDay } = require('date-fns');

module.exports = {
  currentPractice: atom({
    key: 'currentPractice',
    default: {
      id: null,
      name: '',
      email: '',
      phone: '',
      address: '',
      header_photo: null,
      timezone: '',
      working_hours: {},
      display_settings: null,
      appointment_types: null,
      eligibility_counter: 0
    }
  }),
  current_member: atom({
    key: 'current_member',
    default: {
      user_id: '',
      f_name: '',
      l_name: '',
      email: '',
      state: '',
      logged_in: false
    }
  }),
  messages: atom({
    key: 'practice_messages',
    default: {
      all_messages: []
    }
  }),
  reportingFilters: atom({
    key: 'reportingFilters',
    default: {
      selectedRange: [
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          key: 'selection'
        }
      ],
      id: '',
      payer: '',
      patient: null,
      transactionFilter: []
    }
  }),
  calendarFilters: atom({
    key: 'calendarFilters',
    default: {
      appointmentType: [],
      appointmentStatus: [],
      appointmentConfirmState: [],
      serviceType: [],
      patient: [],
      practitioners: [],
      resources: [],
      clinicalNoteStatus: null,
      superbillStatus: null,
      claimState: []
    }
  }),
  communicationFilter: atom({
    key: 'communicationFilter',
    default: {
      order: { label: 'Newest', value: 'desc' },
      messageType: [],
      messageApply: false,
      logType: [],
      logApply: false,
      apply: false
    }
  }),
  selectedPatient: atom({
    key: 'selectedPatient',
    default: {
      id: null
    }
  }),
  // conversation_practice_id: atom({
  //   key: 'practice_id',
  //   default: null
  // })
  // tempSearchTerm: atom({ key: 'tempSearchTerm', default: 'step1' })
  reloadCalendar: atom({
    key: 'reloadCalendar',
    default: false
  }),
  currentFormBtnRefs: atom({
    key: 'currentFormRefs',
    default: []
  }),
  ownerPractices: atom({
    key: 'ownerPractices',
    default: []
  }),
  ownerSelectedLocation: atom({
    key: 'ownerSelectedLocation',
    default: null
  })
};
