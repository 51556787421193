import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ReactFormGenerator } from 'react-form-builder2';
import { formatSections } from '../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import RenderNarrativeValue from './RenderNarrativeValue';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

const AdvancedHP = ({ form }) => {
  const { advancedHP } = useClinicalNoteContext();

  const selectedFormType = (idx = 0) => {
    const matchingAdvancedHP = advancedHP?.find(
      (item) => item.form && (item.form.name === form.title || item.name === form.title)
    );

    const item = { ...matchingAdvancedHP, checkedFormTypes: form?.checkedFormTypes } || null;

    const narrativeComponent = item.checkedFormTypes.isNarrativeChecked ? (
      <div>
        <div className="!mb-2 text-xs font-500 text-neutral-700">Narrative:</div>
        <RenderNarrativeValue
          narrative={formatSections({
            fieldData: item.form.json.fields,
            answerData: JSON.parse(item.json.fields)
          })}
        />
      </div>
    ) : null;

    const templateComponent = item.checkedFormTypes.isTemplateChecked ? (
      <ReactFormGenerator
        key={idx}
        answer_data={item.form && JSON.parse(item.json.fields)}
        data={item.form ? item.form.json.fields : item.json.fields}
        hide_actions
        read_only
      />
    ) : null;

    return (
      <div key={idx}>
        <div className="bg-white">{narrativeComponent}</div>
        <div>{templateComponent}</div>
      </div>
    );
  };

  return (
    <div className="pointer-events-none !py-2 !px-4">
      {!form ? <Skeleton count={3} /> : selectedFormType()}
    </div>
  );
};

export default AdvancedHP;
