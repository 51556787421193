import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';
import Allowed from 'components/shared/Permissions/Allowed';

const Content = ({ title, nested }) => {
  return (
    <div className={cs('flex flex-col', nested && 'pb-[6px]')}>
      <span
        className={cs(
          'text-xs font-500 leading-5',
          nested ? 'px-[10px] py-[6px]' : 'px-[6px] py-[2px]'
        )}>
        {title}
      </span>
      {nested &&
        nested.map((item, idx) => (
          <Allowed key={idx} requiredPermissions={item?.permission}>
            <NavLink
              key={idx}
              to={item.path}
              end={item.end}
              className="flex min-w-[180px] cursor-pointer select-none items-center gap-2 !px-4 !py-[6px] text-xs font-400 leading-5 hover:bg-primary-50">
              {({ isActive }) => (
                <>
                  <Icon
                    icon={item.icon}
                    size={18}
                    color="primary"
                    shade={isActive ? '500' : '900'}
                    className={cs(item.name === 'Packages')}
                  />
                  <span
                    className={cs(
                      'text-sm leading-[22px]',
                      isActive ? 'font-500 text-primary-500' : 'font-400 text-primary-900'
                    )}>
                    {item.name}
                  </span>
                </>
              )}
            </NavLink>
          </Allowed>
        ))}
    </div>
  );
};

export default Content;
