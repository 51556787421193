import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import useOutsideClick from '../../../../../lib/hooks/useOutsideClick';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import '../Custom.scss';
import { SurgicalHistory as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import SurgicalHistoryRow from './SurgicalHistoryRow';

const SurgicalHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative,
  onChange,
  fromClinicalNote = false,
  setItemModal = () => {}
}) => {
  const [surgicalHistory, setSurgicalHistory] = useState(initialValue);
  const [prevSurgeries, setPrevSurgeries] = useState(defaultValue);
  const [elRefs, setElRefs] = useState([]);
  const [currentRowIdx, setCurrentRowIdx] = useState(null);
  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData } =
    useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.surgicalHistory?.advancedForm
      : true;
  const textAreaRef = useRef();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setSurgicalHistory(JSON.parse(defaultValue));
      } else {
        setSurgicalHistory(defaultValue);
      }
    } else {
      setSurgicalHistory(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(surgicalHistory.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [surgicalHistory]);

  useEffect(() => {
    notifyChange(surgicalHistory);
  }, [surgicalHistory]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'SurgicalHistory', type: 'custom' } });
    }
  };

  const syncSurgeriesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ surgeriesObject: updatedObject });
    } else {
      setSurgicalHistory(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();

    const newSurgicalHistory = {
      surgery: null,
      date: new Date().toISOString().slice(0, 10),
      calendarPopover: false,
      note: '',
      id: self.crypto.randomUUID()
    };

    const shouldStopProcess = doEmptyFormExist({
      newForm: newSurgicalHistory,
      forms: surgicalHistory.row
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Surgical History',
        message: 'Empty surgical history exists. Please fill in the details.',
        color: 'warning'
      });

      return;
    }

    const updatedObject = {
      noDetails: false,
      row: [newSurgicalHistory, ...surgicalHistory.row]
    };
    syncSurgeriesAndClinicalNotes(updatedObject);
  };
  const mutateDeleteSurgicalHistory = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Surgical History',
          message: error,
          color: 'warning'
        });
        return;
      }
      setShowConfirmModal(false);
      setSurgicalHistory(initialValue);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'surgicalHistory'
        });
      showAlert({
        title: `Surgical History deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!formId && !fromCheckin) {
      showAlert({
        title: 'Surgical History',
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const surgicalHistoryRow = surgicalHistory?.row || [];
    if (surgicalHistoryRow.length === 1) {
      setShowConfirmModal(true);
      return;
    } else {
      const updatedObject = {
        ...surgicalHistory,
        row: surgicalHistory.row.filter((row) => row.id !== id)
      };
      syncSurgeriesAndClinicalNotes(updatedObject);
    }
  };

  useOutsideClick(elRefs[currentRowIdx], () => handlePopover(false, currentRowIdx));

  const handleOnChange = (key, event, id) => {
    const updatedObject = {
      ...surgicalHistory,
      row: surgicalHistory?.row.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: event };
        } else {
          return row;
        }
      })
    };

    syncSurgeriesAndClinicalNotes(updatedObject);
  };

  const handlePopover = (value, id) => {
    setSurgicalHistory({
      ...surgicalHistory,
      row: surgicalHistory?.row.map((row) => {
        if (row.id === id) {
          return { ...row, calendarPopover: value };
        } else if (row.id !== id) {
          return { ...row, calendarPopover: false };
        } else {
          return row;
        }
      })
    });
  };

  const handleNoDetails = () => {
    const updatedObject = { ...surgicalHistory, noDetails: !surgicalHistory.noDetails };

    syncSurgeriesAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    surgeriesObject = surgicalHistory,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...surgeriesObject, narrative: event };

    setSurgicalHistory(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ surgeriesObject = surgicalHistory, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: surgeriesObject,
      sectionData: 'row',
      sectionName: 'Surgical History',
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      surgeriesObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <div className="grid">
      <input type="hidden" value={JSON.stringify(surgicalHistory)} ref={forwardedRef} />

      {isAdvancedForm && (
        <div
          className={cs(
            'flex items-center gap-x-4',
            surgicalHistory?.noDetails && 'justify-between'
          )}>
          <Checkbox
            data-qa="no-known-surgical-history"
            label="No known surgical history"
            name="noDetails"
            isChecked={surgicalHistory?.noDetails}
            onChange={handleNoDetails}
          />
          {surgicalHistory?.noDetails && !fromCheckin && (
            <Icon
              icon="trash"
              className="cursor-pointer"
              onClick={() => {
                if (!formId) {
                  showAlert({
                    title: 'Surgical History',
                    message: 'Please create the form before deleting one.',
                    color: 'warning'
                  });
                  return;
                } else {
                  setShowConfirmModal(true);
                }
              }}
            />
          )}
        </div>
      )}

      {!surgicalHistory?.noDetails && isAdvancedForm && (
        <div className="dashed-top !mt-4">
          <div className="flex justify-end !pb-2">
            <Button
              onClick={handleAddRow}
              transparent
              text="Add surgery"
              iconRight="add-circle"
              data-qa="add-surgery"
              textClassName="text-primary-500 text-sm mb-2"
            />
          </div>
          <div className="grid gap-4">
            {surgicalHistory?.row?.map((item, idx) => (
              <SurgicalHistoryRow
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                key={item.id}
                index={idx}
                setCurrentRowIdx={setCurrentRowIdx}
                forwardedRef={elRefs[idx]}
                fromCheckin={fromCheckin}
                onChange={handleOnChange}
                onChangePopover={handlePopover}
                onDelete={handleDeleteRow}
                length={surgicalHistory?.row?.length}
                {...item}
              />
            ))}
          </div>
        </div>
      )}

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setSurgicalHistory}
            restData={{
              className: 'w-full',
              label: 'Surgical History Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-surgical-history',
              'data-qa': 'narrative-surgical-history',
              name: 'Narrative-surgical-history',
              value: surgicalHistory?.narrative,
              forwardedRef: textAreaRef
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          submitForm({
            ...customFormsParams,
            formId,
            currentFormData: surgicalHistory,
            defaultState: prevSurgeries,
            type: 'surgicalHistory'
          });
          setPrevSurgeries(surgicalHistory);
        }}
      />
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Surgical History"
          icon="trash"
          message="Are you sure you want to delete this form?"
          handleContinue={() => mutateDeleteSurgicalHistory.mutate()}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default SurgicalHistory;
