import formatMedicationsNarrative from 'components/shared/Forms/Custom/lib/formatMedicationsNarrative';
import { formatSections as formatSectionsV1 } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV1';
import { formatSections as formatSectionsV2 } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV2';
import { isEmpty } from 'lib/helpers/utility';

const FORMAT_SECTION_V1_FORMS = [
  'allergies',
  'familyHistory',
  'surgicalHistory',
  'vaccines',
  'weightLossAdministration'
];
const FORMAT_SECTION_V2_FORMS = ['medicalHistory', 'socialHistory'];
const FORMAT_MEDICATIONS_FORMS = ['medicationHistory'];

export const generateNarrativeIfEmpty = ({ form }) => {
  let result = null;

  const formType = form?.type;

  const formTypeNarrativeOptions = {
    allergies: {
      sectionData: 'allergies',
      sectionName: 'Allergy'
    },
    familyHistory: {
      sectionData: 'row',
      sectionName: 'Family History'
    },
    surgicalHistory: {
      sectionData: 'row',
      sectionName: 'Surgical History'
    },
    vaccines: {
      sectionData: 'vaccines',
      sectionName: 'Vaccine'
    },
    weightLossAdministration: {
      sectionData: 'weightLossAdministration',
      sectionName: 'Weight Loss Administration'
    },
    medicalHistory: {
      sectionName: 'Past Medical History'
    },
    socialHistory: {
      sectionName: 'Social History'
    }
  };

  const narrativeOptions = formTypeNarrativeOptions?.[formType] ?? formType;

  if (!narrativeOptions) return null;

  const completedNarrativeOptions = {
    data: form?.response,
    ...narrativeOptions
  };

  if (FORMAT_SECTION_V1_FORMS.includes(formType)) {
    result = formatSectionsV1(completedNarrativeOptions);
  } else if (FORMAT_SECTION_V2_FORMS.includes(formType)) {
    result = formatSectionsV2(completedNarrativeOptions);
  } else if (FORMAT_MEDICATIONS_FORMS.includes(formType)) {
    result = formatMedicationsNarrative(form?.response?.medications);
  }

  return !isEmpty(result?.trim()) ? result?.trim() : 'No known details';
};
