import { useQuery } from '@tanstack/react-query';
import { getStickyNotes } from 'api/StickyNote';
import { useNavigate } from 'react-router-dom';

export const useStickyNotes = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['sticky-notes', ...dependencies], () => getStickyNotes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
