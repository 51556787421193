import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Assessment from './Assessment';

const AssessmentSection = ({ components, options, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.assessment?.title}
      id={components?.assessment?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Assessment sectionRef={sectionRef} />
    </Accordion>
  );
};

export default AssessmentSection;
