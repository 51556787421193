import { getTask } from 'api/Tasks';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useTasks } from 'lib/hooks/queries/tasks/useTasks';
import usePageTitle from 'lib/hooks/usePageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import ActionButtons from './components/ActionButtons';
import NewTask from './components/NewTask';
import PreviewTask from './components/PreviewTask';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helpers';
import { useMember } from 'lib/hooks/queries/useMember';
import TableCounter from 'components/shared/Table/TableCounter';

const Tasks = () => {
  return (
    <TableContextProvider
      pagination
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns}
      name="tasks">
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Tasks');
  const category = 'tasks';
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [previewTaskModal, setPreviewTaskModal] = useState(false);
  const [singleTask, setSingleTask] = useState(null);
  const [buttonText, setButtonText] = useState('');
  const practice = useRecoilValue(currentPractice);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    limit,
    page,
    setPage,
    sort,
    selectedRows,
    setSelectedRows = () => {},
    filters,
    setFilters
  } = useTableContext();

  const { data: membersList } = useMember({});

  useEffect(() => {
    if (ia(membersList?.members)) {
      const practiceMembers = membersList.members.map((p) => ({
        label: `${p.f_name} ${p.l_name}`,
        value: p.user_id
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        owners: {
          ...prevFilters.owners,
          options: practiceMembers
        },
        requesters: {
          ...prevFilters.requesters,
          options: practiceMembers
        },
        followers: {
          ...prevFilters.followers,
          options: practiceMembers
        }
      }));
    }
  }, [membersList]);

  useEffect(() => {
    if (id) getSingleTask(parseInt(id), true);
  }, [id]);

  const {
    data = {},
    isLoading,
    isFetching
  } = useTasks({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const tasks = data.tasks;
  const count = data?.count || 0;

  const getSingleTask = async (id) => {
    try {
      const res = await getTask(navigate, { taskId: id });
      if (res.code === 0) {
        setSingleTask(res.task);
        setPreviewTaskModal(true);
      }
    } catch (error) {
      showAlert({
        title: 'Something went wrong!',
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }
  };

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'checkbox') return;
    if (e?.column?.getColId() === 'actions') return;
    if (['actions', '0'].includes(e?.column?.colId)) return;
    getSingleTask(e.data.id);
  };

  const hideNewTaskModal = () => setNewTaskModal(false);
  const hidePreviewTaskModal = () => setPreviewTaskModal(false);

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title="Tasks">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <ActionButtons selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Button
            size="small"
            text="New Task"
            icon="plus"
            data-qa="new-task-btn"
            onClick={() => {
              setButtonText('Create task');
              setSingleTask(null);
              setNewTaskModal(true);
            }}
          />
        </div>
      </Header>
      <div className="flex  h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={tasks}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              // defaultColDef={defaultColDef}
              columnDef={() => defaultColumns(practice.timezone)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {newTaskModal && (
        <NewTask
          task={singleTask}
          newTaskModal={newTaskModal}
          hideNewTaskModal={hideNewTaskModal}
          buttonText={buttonText}
        />
      )}
      {previewTaskModal && (
        <PreviewTask
          task={singleTask}
          previewTaskModal={previewTaskModal}
          hidePreviewTaskModal={hidePreviewTaskModal}
          buttonText={buttonText}
        />
      )}
    </ErrorBoundary>
  );
}

export default withErrorBoundary(Tasks);
