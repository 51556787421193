import cs from 'classnames';
import React, { useRef, useState } from 'react';
import { medicalConditions } from '../../../../../constants';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';

const FamilyHistoryRow = ({
  medicalCondition,
  motherSide,
  fatherSide,
  sibling,
  note,
  id,
  onDelete,
  onChangeMC,
  onCheckMother,
  onCheckFather,
  onCheckSibling,
  onChangeNote,
  length,
  fromCheckin,
  className
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <div className="grid w-full grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
        <Select
          inputId="medical-condition"
          label="Medical condition"
          options={medicalConditions}
          value={medicalCondition}
          name="medicalCondition"
          onChange={(event) => onChangeMC(event, id)}
          isCreatable
          disabled={disabled}
          menuPortalTarget={document.body}
        />
        <div>
          <label className="!pb-[6px] text-sm text-neutral-800">Inheritance</label>
          <div className="flex flex-wrap items-center gap-4 gap-x-6">
            <Checkbox
              data-qa="mothers-side"
              label="Mother's side"
              name="motherSide"
              isChecked={motherSide}
              onChange={() => onCheckMother(id)}
            />
            <Checkbox
              data-qa="fathers-side"
              label="Father's side"
              name="fatherSide"
              isChecked={fatherSide}
              onChange={() => onCheckFather(id)}
            />
            <Checkbox
              data-qa="sibling"
              label="Sibling"
              name="sibling"
              isChecked={sibling}
              onChange={() => onCheckSibling(id)}
            />
          </div>
        </div>
        <Textarea
          className="md:col-span-full"
          label="Please provide details"
          placeholder="Add details here"
          id="note"
          data-qa="note"
          name="note"
          value={note}
          onChange={(event) => onChangeNote(event, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
      </div>
      {(!fromCheckin || length > 1) && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default FamilyHistoryRow;
