import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import PreAuthForm from 'components/shared/Modal/PreAuthFormModal/PreAuthForm';

const PreAuth = () => {
  const { updateSteps, user } = useOutletContext();

  return (
    <div className="p-3">
      <PreAuthForm
        user={user}
        billingAddress={user?.address_object}
        patientName={user?.p_name || user?.f_name + ' ' + user?.l_name || null}
        onSuccess={() => {
          showAlert({ title: 'Pre-Auth form submitted successfully!', color: 'success' });

          updateSteps({ version: 2, newStatus: 'completed' });
        }}
        showOverrides={{
          cash: false,
          check: false,
          cardOnFile: false,
          careCredit: false,
          cardPresent: false,
          online: true,
          noFooter: false
        }}
      />
    </div>
  );
};

export default withErrorBoundary(PreAuth);
