import moment from 'moment';
import getPlaceOfService from './getPlaceOfService';

const { iaRa, ia } = require('lib/helpers/utility');

export const reshapeProceduresWithClaim = ({ procedures = [] }) => {
  const reshapedProcedures = iaRa(procedures)
    .filter(filterProcedure)
    ?.map((procedure) => {
      return {
        ...procedure,
        units: procedure?.units || 1
      };
    });

  return sortProcedures({ procedures: reshapedProcedures });
};

export const reshapeProceduresWithoutClaim = ({
  superbill = {},
  diagnosisPointers = [],
  practice = {}
}) => {
  const procedures = superbill?.encounter?.appointment?.invoice?.procedures;

  const reshapedProcedures = iaRa(procedures)
    .filter(filterProcedure)
    .map((procedure) => {
      const startAt = moment(superbill.encounter.appointment.starts_at).toDate();

      return {
        ...procedure,
        modifiers: procedure.modifiers || iaRa(procedure.modifiers).join(','),
        diagnosis:
          procedure?.diagnosis ||
          (ia(superbill.encounter.icd_10_codes)
            ? diagnosisPointers.slice(0, superbill.encounter.icd_10_codes.length).join('')
            : null),
        from_date: procedure?.from_date || startAt,
        thru_date: procedure?.thru_date || startAt,
        narrative: procedure?.narrative || '',
        units: procedure?.units || '1',
        place_of_service: procedure?.place_of_service || getPlaceOfService(superbill, practice)
      };
    });

  return sortProcedures({ procedures: reshapedProcedures });
};

const filterProcedure = (procedure) =>
  !procedure?.invoiceId && procedure?.procedure_code && procedure?.type === 'primary';

const sortProcedures = ({ procedures = [] }) => {
  return iaRa(procedures)?.sort((a, b) => {
    const dateA = a?.created_at ? new Date(a.created_at) : null;
    const dateB = b?.created_at ? new Date(b.created_at) : null;

    if (dateA && dateB) {
      return dateB - dateA;
    }

    if (dateA) return -1;
    if (dateB) return 1;
    return 0;
  });
};

export const formatProcedureModifiers = ({ procedures = [] }) => {
  return iaRa(procedures).map((procedure) => {
    if (typeof procedure?.modifiers === 'string') {
      const splittedModifiers = procedure?.modifiers.split(',');
      procedure.modifiers = iaRa(splittedModifiers).map((modifier) => modifier.trim());
    }

    return procedure;
  });
};
