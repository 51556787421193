import React, { useState, useEffect } from 'react';
import Button from '../../../Buttons/Button';
import OtherProblemsRow from './OtherProblemsRow';
import { OtherProblems as initialValue } from '../CustomFormInitialStates';

const OtherProblems = ({ forwardedRef, defaultValue, onChange }) => {
  const [otherProblems, setOtherProblems] = useState(initialValue);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setOtherProblems(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setOtherProblems(defaultValue);
      }
    } else {
      setOtherProblems(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(otherProblems);
  }, [otherProblems]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'OtherProblems', type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    setOtherProblems([
      {
        note: '',
        problems: null,
        id: self.crypto.randomUUID()
      },
      ...otherProblems
    ]);
  };

  const handleDeleteRow = (id) => {
    setOtherProblems(otherProblems.filter((row) => row.id !== id));
  };

  const handleOnChange = (key, event, id) => {
    setOtherProblems(
      otherProblems.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: event };
        } else {
          return row;
        }
      })
    );
  };

  return (
    <div className="OtherProblems CustomForms">
      <div className="CustomForms__head">
        <Button text="Add new" iconRight="add-circle" textClassName='text-primary-500' transparent onClick={handleAddRow} />
      </div>
      <input type="hidden" value={JSON.stringify(otherProblems)} ref={forwardedRef} />
      <div className="CustomForms__body">
        {otherProblems?.map((item) => (
          <OtherProblemsRow
            key={item.id}
            onChange={handleOnChange}
            onDelete={handleDeleteRow}
            length={otherProblems.length}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default OtherProblems;
