import * as Yup from 'yup';
const validator = require('validator');
export const genders = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const UpdatePatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First Name required'),
  l_name: Yup.string().required('Last Name required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required.' });
    }
    if (!validator?.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  })
});
export const patientInitialValues = (initialPatient) => ({
  id: initialPatient?.id,
  f_name: initialPatient?.f_name,
  m_name: initialPatient?.m_name,
  l_name: initialPatient?.l_name,
  dob: initialPatient?.dob,
  gender: initialPatient?.gender,
  email: initialPatient?.email,
  phone: initialPatient?.phone || ''
});
