const { ia } = require('../../../../../../lib/helpers/utility');
const { initialStatesRender } = require('./initials');

exports.mapRenderedInitialState = (selected) => {
  return createInitialRenderStates(selected);
};

const createInitialRenderStates = (selected) => {
  return initialStatesRender.map((key) => {
    return {
      key,
      checked: selected?.[key]?.checked,
      position: selected?.[key]?.position,
      checkedFormTypes: getCheckedFormTypes(selected?.[key]?.formType)
    };
  });
};

const getCheckedFormTypes = (selected) => {
  const selectedArray = ia(selected) ? selected : [];

  return {
    isNarrativeChecked: selectedArray
      ?.filter((s) => s.title === 'Narrative')
      ?.every((obj) => obj.checked === true),
    isTemplateChecked: selectedArray
      ?.filter((s) => s.title === 'Template')
      ?.every((obj) => obj.checked === true),
    isChecked: (form) =>
      selectedArray?.filter((s) => s.title === form)?.every((obj) => obj.checked === true)
  };
};

exports.processSelectedForms = ({ selected, rendered }) => {
  const objectsToAdd = [];
  const hpForms = [];
  const soapForms = [];
  const advancedForms = [];

  for (const key in selected) {
    if (selected.hasOwnProperty(key)) {
      // Compare selected with rendered
      const objectExistsInRendered = rendered.some((item) => item.key === key);
      // Add the new object
      if (!objectExistsInRendered) {
        const newFormObject = {
          key,
          advancedType: selected[key].advancedType,
          title: selected[key].title,
          checked: selected[key].checked,
          position: selected[key].position,
          checkedFormTypes: getCheckedFormTypes(selected?.[key]?.formType)
        };
        objectsToAdd.push(newFormObject);
        if (selected[key].advancedType === 'hp') {
          hpForms.push(newFormObject);
        } else if (selected[key].advancedType === 'soap') {
          soapForms.push(newFormObject);
        } else if (selected[key].advancedType === 'customForm') {
          advancedForms.push(newFormObject);
        }
      }
    }
  }

  const updatedRendered = rendered.concat(objectsToAdd);

  const checkedRendered = updatedRendered
    .filter((obj) => obj.checked)
    .sort((a, b) => {
      const posA = a.position;
      const posB = b.position;

      if (typeof posA === 'number' && typeof posB === 'number') {
        return posA - posB;
      }
      if (typeof posA === 'number') {
        return -1;
      }
      if (typeof posB === 'number') {
        return 1;
      }
      return 0;
    });

  return { checkedRendered, hpForms, soapForms, advancedForms };
};
