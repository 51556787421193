import React from 'react';
import { ia } from '../../../lib/helpers/utility';
import { AlertContent } from '../Alert/Alert';

const ErrorRequirements = ({
  requirements = [],
  title = `Note: ePrescribe Requirements not met, please fulfill the requirements to enable
          prescribing for this patient.`
}) => {
  if (!ia(requirements)) {
    return null;
  }

  return (
    <AlertContent
      className="mt-[1rem]"
      color="danger"
      title={title}
      message={
        <>
          <ul className="list-disc text-danger-600">
            {ia(requirements) &&
              requirements.map((v, i) => (
                <li className="pl-[1rem]" key={`requirements-index-${i}`}>{`- ${v}`}</li>
              ))}
          </ul>
        </>
      }
      data-dd-privacy="allow"
      data-public
    />
  );
};

export default ErrorRequirements;
