import React from 'react';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { userState } from 'components/state';
import headerPattern from '../../../../assets/Patterns/header-pattern.svg';
import cs from 'classnames';

const Header = () => {
  const currentUser = useRecoilValue(userState);
  const practice = useRecoilValue(currentPractice);
  const currentTime = moment().tz(practice.timezone);

  let greeting;
  if (currentTime.isBefore(moment('12:00 PM', 'h:mm A'))) {
    greeting = 'Good Morning';
  } else if (currentTime.isBefore(moment('6:00 PM', 'h:mm A'))) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  return (
    <div className="h-[146px] relative p-[18px] !pl-6 sm:">
      <img src={headerPattern} className="absolute top-0 left-0 w-full h-full object-cover" />
      <div className="!pt-2 z-10 relative sm:!pt-2 md:!pt-0">
        <h3 className="text-white text-2xl font-500 leading-8 md:text-xl sm:text-xl">{`${greeting} ${currentUser?.f_name} ${currentUser?.l_name}`}</h3>
        <p
          className={cs(
            'text-sm text-white font-400 !pt-1 sm:!pt-3 md:w-2/5 sm:w-2/5',
            window.innerWidth < 768 && 'w-full'
          )}>
          We're delighted to have your expertise here in the clinic today.
        </p>
      </div>
    </div>
  );
};

export default Header;
