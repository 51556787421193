import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from 'lib/helpers/utility';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const WeightLossAdministration = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !currentHpOverviewData?.weightLossAdministration?.id) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">
        Weight loss administration
      </h3>
      {isTemplateChecked &&
        iaRa([currentHpOverviewData?.weightLossAdministration])?.map((weightLossAdministration) =>
          weightLossAdministration?.response?.row?.map((item, idx) => (
            <div
              className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
              key={idx}>
              {weightLossAdministration?.response?.goalWeight && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Goal weight</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {weightLossAdministration?.response?.goalWeight}
                  </p>
                </div>
              )}
              {weightLossAdministration?.response?.initialWeight && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Initial weight
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {weightLossAdministration?.response?.initialWeight}
                  </p>
                </div>
              )}
              {item?.medication?.value && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Medication</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.medication?.value}
                  </p>
                </div>
              )}
              {item?.location && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Location</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.location}
                  </p>
                </div>
              )}
              {item?.typeOfInjection && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Type of injection
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.typeOfInjection}
                  </p>
                </div>
              )}
              {item?.date && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Date</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.date}
                  </p>
                </div>
              )}
              {item?.weight && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Weight</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {`${item?.weight} lbs`}
                  </p>
                </div>
              )}
              {item?.notes && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Notes</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {`${item?.notes} lbs`}
                  </p>
                </div>
              )}
            </div>
          ))
        )}

      {isNarrativeChecked && currentHpOverviewData?.weightLossAdministration?.id && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.weightLossAdministration]}
            noDetailsText="No known weight loss administration"
          />
        </div>
      )}
    </div>
  );
};

export default WeightLossAdministration;
