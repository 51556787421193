import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updateDocumentSign } from '../../../../../../api/DocumentSign';
import { updateUser } from '../../../../../../api/User';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { isEmpty } from '../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../shared/Alert/Alert';
import { userState } from '../../../../../state';

import Confirm from '../../../../../shared/Modal/Confirm/Confirm';
import AddCosigners from './AddCosigners';
import SignPad from './SignPad';

const defaultConfirmModalProps = {
  title: 'Sign treatment plan',
  message: 'Are you sure you want to sign this treatment plan?',
  primaryBtnTxt: 'Sign treatment plan',
  secondaryBtnTxt: 'Cancel',
  icon: 'new-document-sign'
};

const SignModal = ({
  documentId,
  documentTypes = ['treatment_plan'],
  documentSignatures = [],
  setShowConfirmationModal,
  showConfirmationModal,
  overrideStatus = null,
  alertUsers = true,
  onDocumentSignSuccess = null,
  confirmModalProps = {},
  onlyReturnSignatureProps = false,
  returnedData = () => null,
  showInPopover = true
}) => {
  const { clinicalNote } = useClinicalNoteContext() || {};
  const [user, setUser] = useRecoilState(userState);
  const sigPad = useRef(null);
  const [signature, setSignature] = useState(null);
  const [updateSignature, setUpdateSignature] = useState(false);
  const [loading, setLoading] = useState(false);

  const isCurrentUserCNAuthor = (user?.id ?? user?.user_id) == clinicalNote?.practitioner_id;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(user.signature)) setSignature(user.signature);
  }, [user.signature]);

  const queryClient = useQueryClient();

  const updateDocumentSignMutation = useMutation((data) => updateDocumentSign(navigate, data), {
    onSuccess: (data) => {
      if (data.code === 7) {
        showAlert({
          title: "You're not assigned to sign this document!",
          color: 'danger'
        });
        return;
      }

      showAlert({
        title: 'Success!',
        message: `Document signed successfully!`,
        color: 'success'
      });
      queryClient.invalidateQueries(['documentSign']);
      onDocumentSignSuccess && onDocumentSignSuccess(data);
    }
  });

  const updateUserMutation = useMutation((data) => updateUser(navigate, data), {
    onSuccess: (data) => {
      if (data.code !== 0) {
        showAlert({
          title: 'Signature Error!',
          message: 'Something went wrong. Please try again!',
          color: 'danger'
        });

        return;
      }

      setUser((prev) => ({ ...prev, signature }));
    }
  });

  const handleContinue = async () => {
    setLoading(true);
    let trimmedSignature = signature;

    if (!signature) trimmedSignature = trimSignature();

    if (!trimmedSignature) {
      showAlert({
        title: 'Signature Error!',
        message: "You can't save a blank signature!",
        color: 'danger'
      });

      setLoading(false);
      return;
    }

    if (updateSignature || !user?.signature) {
      await updateUserMutation.mutateAsync({
        user_id: user.id,
        changes: { signature: trimmedSignature }
      });
    }

    const signatureData = {
      documentId,
      documentTypes,
      signature: trimmedSignature,
      currentUser: user.id,
      link: user.kind === 'patient' ? `/portal/charts/${user.id}/clinical-notes` : pathname,
      overrideStatus,
      alertUsers
    };

    if (!onlyReturnSignatureProps) {
      await updateDocumentSignMutation.mutateAsync(signatureData);
    } else {
      returnedData(signatureData);
    }

    setLoading(false);
    setShowConfirmationModal(false);
  };

  const trimSignature = () => {
    if (sigPad?.current) {
      if (sigPad.current?.isEmpty()) {
        return;
      }

      const trimmedSignature = sigPad.current?.getTrimmedCanvas().toDataURL('image/png');
      setSignature(trimmedSignature);

      return trimmedSignature;
    }
  };

  const clearSignature = (e) => {
    e.preventDefault();

    if (sigPad?.current) sigPad.current?.clear();

    setSignature(null);
  };

  const showAddCoSigners = isCurrentUserCNAuthor && documentTypes?.[0] === 'clinical_note';

  const DisplayType = ({ children }) => {
    if (showInPopover) {
      return (
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={handleContinue}
          {...defaultConfirmModalProps}
          {...confirmModalProps}
          overlayClassName="z-[300]"
          loading={loading}>
          {children}
        </Confirm>
      );
    }

    return children;
  };

  return (
    <DisplayType>
      <div className="!mt-6">
        {showAddCoSigners && <AddCosigners documentSignatures={documentSignatures} />}
        <SignPad
          user={user}
          clearSignature={clearSignature}
          sigPad={sigPad}
          signature={signature}
          updateSignature={updateSignature}
          setUpdateSignature={setUpdateSignature}
        />
      </div>
    </DisplayType>
  );
};

export default SignModal;
