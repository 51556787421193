import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { formatDate, io } from 'lib/helpers/utility';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const Vaccines = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(currentHpOverviewData?.vaccines)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Vaccines</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.vaccines]?.map((vaccine) =>
          vaccine?.response?.row?.map((item, idx) => (
            <div
              className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
              key={idx}>
              {item?.vaccine?.value && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Vaccine</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.vaccine?.value}
                  </p>
                </div>
              )}
              {item?.doses?.value && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Doses taken</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.doses?.value}
                  </p>
                </div>
              )}
              {item?.lastDose && item?.lastDoseDisabled === false && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Date of last dose
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.notSure ? 'Not sure' : formatDate(item?.lastDose)}
                  </p>
                </div>
              )}
              {vaccine?.response?.generalNote && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {vaccine?.response?.generalNote}
                  </p>
                </div>
              )}
            </div>
          ))
        )}

      {isNarrativeChecked && !!io(currentHpOverviewData?.vaccines) && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.vaccines]}
            noDetailsText="No known vaccines"
          />
        </div>
      )}
    </div>
  );
};

export default Vaccines;
