import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useInvoices } from 'lib/hooks/queries/billing/useInvoices';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getColDefs, DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS } from './configs';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import { ia, mapValues } from 'lib/helpers/utility';
import { getInvoicesCSV } from 'api/Transaction';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import useCreateExternalInvoice from 'lib/hooks/useCreateExternalInvoice';
import { getProceduresTotal } from 'components/public/lib/utils';
import InvoicePrintView from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoicePrintView';
import NewInvoice from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoice';
import NewInvoiceForm from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoiceForm';
import {
  getInvoicesFromSelectedRows,
  onSendEmail,
  onSendText
} from 'components/practice/BeyondBilling/ElectronicInvoicing/lib/utils';
import { InvoiceModal } from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoiceModal';
import Button from 'components/shared/Buttons/Button';
import ContactButton from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/ContactButton';
import Icon from 'components/shared/Icon/Icon';
import AltContact from 'components/practice/BeyondBilling/ElectronicInvoicing/components/AltContact';
import CustomContact from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/CustomContact';
import ReactToPrint from 'react-to-print';
import NewInvoiceView from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoiceView';
import { CustomSendModal } from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/CustomSendModal';
import { ModalCreateFooter } from 'components/practice/BeyondBilling/ElectronicInvoicing/InternalInvoices';

const PatientInvoices = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider
      name="patient_invoices"
      cols={getColDefs(practiceTimezone)}
      pagination
      defaultFilters={DEFAULT_FILTERS}>
      <Table />
    </TableContextProvider>
  );
};
const initialView = {
  title: 'Create Invoice',
  step: 0,
  mode: 'create'
};

function Table() {
  const category = 'patient_invoices';

  const navigate = useNavigate();

  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const claims = practice?.display_settings?.claims;

  const { id: patientId } = useParams();

  const pageToPrintRef = useRef();

  const [activeView, setActiveView] = useState(initialView);
  const [externalInvoices, setExternalInvoices] = useState([]);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');
  const [customModalVisible, setCustomModalVisible] = useState(false);

  const {
    limit,
    page,
    setPage,
    sort,
    filters,
    setFilters,
    selectedRows = [],
    setSelectedRows = () => {},
    gridApi
  } = useTableContext();

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useInvoices({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      user_id: patientId,
      unpaid: false
    },
    dependencies: [limit, page, sort, mapValues(filters), patientId]
  });
  const { data: providers = {} } = useProviders({});

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.providers.options = providerOptions;

  const invoices = data?.invoices;
  const count = data?.count || 0;
  const statusPanelData = data?.statusPanelData;

  let finalGroupInvoices = [];
  const selectedRowData = selectedRows?.map((row) => row.data);

  if (selectedRowData?.length > 0) {
    finalGroupInvoices = getInvoicesFromSelectedRows(selectedRowData);
  }
  console.log({ finalGroupInvoices });
  const initialValues = {};

  finalGroupInvoices?.map((invoice) => {
    initialValues[invoice.id] = {
      internal_invoice_ids: invoice.internal_invoice_ids,
      patient: invoice.patient,
      due_date: new Date(),
      memo: '',
      amount_cents: invoice.procedures.reduce((acc, curr) => {
        const currCharge = curr?.charge || 0;
        acc += currCharge;
        return acc;
      }, 0),
      total_amount_cents: 0,
      discount_amount_cents: 0,
      tax_percentage: 0,
      tax_amount_cents: 0,
      surcharge_amount: 0
    };
  });

  const { invoiceFormik, isCreateInvoicesLoading } = useCreateExternalInvoice({
    initialValues,
    navigate,
    setActiveView,
    setExternalInvoices
  });

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [invoices, count, page, limit, onPageChange, statusPanelData]);

  const exportCSV = async () => {
    const data = await getInvoicesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      user_id: patientId
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'invoices.csv';
    a.click();
  };

  let modalContent = null;

  if (selectedRows?.length > 0 && finalGroupInvoices.length > 0) {
    modalContent = (
      <>
        {finalGroupInvoices.map((finalInvoice) => {
          const invoiceDetails = getProceduresTotal([finalInvoice]);

          const hasMany = ia(finalInvoice.internal_invoice_ids, 1);
          const isAEOB = invoiceFormik?.values?.[finalInvoice.id]?.aeob ?? true;

          return (
            <div key={finalInvoice.id}>
              <InvoicePrintView
                practice={practice}
                patient={finalInvoice?.patient}
                createdAt={!hasMany ? finalInvoice?.dateofservice : null}
                content={
                  <NewInvoice invoice={finalInvoice} isAEOB={isAEOB}>
                    <NewInvoiceForm
                      formik={invoiceFormik}
                      invoiceId={finalInvoice.id}
                      initialValues={initialValues}
                      invoiceDetails={invoiceDetails}
                      hasClaims={claims}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
  }

  function handleInvoiceModalClose() {
    setActiveView((prev) => ({ ...prev, mode: 'create', step: 0, title: 'Create Invoice' }));

    invoiceFormik.resetForm();

    setInvoiceModalVisible(false);
    // setInternalInvoice(null);
    gridApi.deselectAll();
  }

  const onRowClicked = async (e) => {
    const clickedInvoice = e.data;
    const selectedNodes = e.api.getSelectedNodes();

    // Deselect all preselected rows
    selectedNodes.forEach((node) => {
      node.setSelected(false);
    });

    // Select the clicked row
    e.node.setSelected(true);
    setSelectedRows([{ data: clickedInvoice }]);

    // setInternalInvoice(clickedInvoice);
    setInvoiceModalVisible(true);
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }
    await onRowClicked(e);
  };

  function handleInvoiceModalOpen() {
    setInvoiceModalVisible(true);
  }

  let modalFooter = null;
  if (activeView.step > 1 || (selectedRows?.length > 0 && finalGroupInvoices.length > 0)) {
    modalFooter = (
      <ModalCreateFooter
        invoiceIds={finalGroupInvoices.map((invoice) => invoice?.id)}
        formik={invoiceFormik}
        loading={isCreateInvoicesLoading}
        handleInvoiceModalClose={handleInvoiceModalClose}
      />
    );
  }

  let headButton = null;
  let prevButton = null;

  if (activeView.mode === 'preview') {
    const handleCustomSendModalOpen = (type) => {
      setCustomContactType(type);
      setCustomModalVisible(true);
    };
    modalContent = (
      <>
        {finalGroupInvoices.map((finalInvoice, idx) => {
          const externalInvoice = externalInvoices.find(
            (invoice) => invoice.patient_id === finalInvoice.patient.id
          );

          const invoiceDetails = getProceduresTotal([finalInvoice]);

          return (
            <div key={idx}>
              <InvoicePrintView
                practice={practice}
                patient={finalInvoice?.patient}
                createdAt={finalInvoice?.dateofservice}
                content={
                  <NewInvoice invoice={finalInvoice} isView isAEOB={externalInvoice?.aeob}>
                    <NewInvoiceView
                      hasClaims={claims}
                      invoiceDetails={{
                        ...invoiceDetails,
                        discount: externalInvoice?.discount_amount_cents || 0,
                        tax_percentage: externalInvoice?.tax_percentage || 0,
                        tax_amount_cents: externalInvoice?.tax_amount_cents || 0,
                        surcharge_amount: externalInvoice?.surcharge_amount || 0,
                        memo: externalInvoice?.memo,
                        aeob: externalInvoice?.aeob
                      }}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
    headButton = (
      <div className="flex w-full justify-end">
        <ReactToPrint
          trigger={() => (
            <Button
              color=""
              size="small"
              type="link"
              text="Print"
              icon="new-printer-bulk"
              className="text-primary-900"
            />
          )}
          content={() => pageToPrintRef?.current}
        />
      </div>
    );
    prevButton = null;
    modalFooter = (
      <div className="flex w-full justify-between">
        <Button
          outlined
          text="Cancel"
          color="neutral"
          onClick={handleInvoiceModalClose}
          id="cancelInvoiceModalBtn"
          data-qa="cancel-btn"
        />
        <div className="flex">
          <ContactButton type="text">
            {externalInvoices.some((invoice) => invoice?.patient?.phone) && (
              <div
                data-qa="send-primary-text"
                onClick={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                <Icon icon="text-message" size="15px" />{' '}
                {finalGroupInvoices.length > 1
                  ? 'Primary phone'
                  : `${externalInvoices[0]?.patient?.phone} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_phone) && (
              // Check for alternative phone
              <AltContact
                type="phone"
                handleAlt={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltPhone: true,
                    isPopover: true
                  })
                }>
                alternative
                {finalGroupInvoices.length > 1
                  ? 'Alternative phone'
                  : `${externalInvoices[0]?.patient?.alt_phone} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('text')} type="phone" />
          </ContactButton>

          <ContactButton type="email">
            {externalInvoices.some((invoice) => invoice?.patient?.email) && (
              <div
                data-qa="send-primary-email"
                onClick={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                {finalGroupInvoices.length > 1
                  ? 'Primary email'
                  : `${externalInvoices[0]?.patient?.email} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_email) && (
              // Check for alternative email
              <AltContact
                type="email"
                handleAlt={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltEmail: true,
                    isPopover: true
                  })
                }>
                {finalGroupInvoices.length > 1
                  ? 'Alternative email'
                  : `${externalInvoices[0]?.patient?.alt_email} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('email')} type="email" />
          </ContactButton>
        </div>
      </div>
    );
  }

  // headButton = (
  //   <div className="flex w-full justify-end">
  //     <ReactToPrint
  //       trigger={() => (
  //         <Button
  //           color=""
  //           size="small"
  //           type="link"
  //           text="Print"
  //           icon="new-printer-bulk"
  //           className="text-primary-900"
  //         />
  //       )}
  //       content={() => pageToPrintRef?.current}
  //     />
  //   </div>
  // );

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header>
        <div className="-mt-[10px] mb-1 flex flex-wrap justify-end gap-2 pt-0">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
          />
          <ExportData
            title="Generate Internal Invoice report"
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            onGenerateClick={exportCSV}
          />
          <Button
            disabled={!selectedRows?.length}
            size="small"
            data-qa="send-invoice-btn"
            onClick={handleInvoiceModalOpen}
            text="Invoice Patient"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isGetInvoicesLoading || isFetching ? (
            <Skeleton count={25} />
          ) : (
            <AGTable
              defaultColDef={DEFAULT_COLUMN_DEFS}
              gridOptions={GRID_OPTIONS}
              animateRows={true}
              data={invoices}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              isRowSelectable={() => true}
              customClassName="ag-grid-interactive"
              enableRangeSelection={true}
              masterDetail={false}
              statusBar={statusBar}
              reactiveCustomComponents
              onCellClicked={onCellClicked}
            />
          )}
        </div>
        {invoiceModalVisible ? (
          <InvoiceModal
            prevButton={prevButton}
            title={activeView.title}
            invoiceModalVisible={invoiceModalVisible}
            onClose={handleInvoiceModalClose}
            modalContent={
              <div ref={pageToPrintRef} className="h-full w-auto">
                {modalContent}
              </div>
            }
            footer={modalFooter}
            headButton={headButton}
          />
        ) : null}
        {customModalVisible ? (
          <CustomSendModal
            ids={externalInvoices.map((invoice) => invoice.id)}
            contactType={customContactType}
            visible={customModalVisible}
            onClose={handleCustomSendModalClose}
            onPrev={handleCustomSendModalClose}
          />
        ) : null}
      </div>
    </ErrorBoundary>
  );
}

export default PatientInvoices;
