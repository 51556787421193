import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from 'lib/helpers/utility';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const SurgicalHistory = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !currentHpOverviewData?.surgicalHistory?.id) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Surgical history</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.surgicalHistory]?.map((surgicalHistory) =>
          surgicalHistory?.response?.row.map((item, idx) => {
            if (io(item?.surgery))
              return (
                <div
                  className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
                  key={idx}>
                  <div className="grid auto-rows-min">
                    <p className="text-xs font-500 text-neutral-700 print:text-base">Surgery</p>
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {item?.surgery.value}
                    </p>
                  </div>
                  {item?.date && (
                    <div className="grid auto-rows-min">
                      <p className="text-xs font-500 text-neutral-700 print:text-base">Date</p>
                      <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                        {item?.date}
                      </p>
                    </div>
                  )}
                  {item?.note && (
                    <div className="col-start-3 col-end-5 grid auto-rows-min">
                      <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>
                      <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                        {item?.note}
                      </p>
                    </div>
                  )}
                  {io(item?.surgeon) && (
                    <div className="col-start-1 col-end-5 grid auto-rows-min">
                      <p className="text-xs font-500 text-neutral-700 print:text-base">Surgeon</p>
                      <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                        {item?.surgeon?.value}
                      </p>
                    </div>
                  )}
                </div>
              );
          })
        )}

      {isNarrativeChecked && currentHpOverviewData?.surgicalHistory?.id && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.surgicalHistory]}
            noDetailsText="No known surgical history"
          />
        </div>
      )}
    </div>
  );
};

export default SurgicalHistory;
