import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as filestack from 'filestack-js';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { updateProfilePicture } from '../../../../api/Patients';
import { Capitalize, formatPhoneNumber } from '../../../../lib/helpers/utility';
import ProfilePicture from '../../../Profile/ProfilePicture';
import { showAlert } from '../../../shared/Alert/Alert';
import Icon from '../../../shared/Icon/Icon';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { permissions } from '../../../state';
import StickyNotes from '../../StickyNotes/StickyNotes';
import OverviewPopover from './Popovers/OverviewPopover';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import CreatePopover from './Popovers/CreatePopover';
import Tippy from '@tippyjs/react';
import SellPackageProductModal from '../../../Patient/sell/SellPackageProductModal';
import Modal from '../../../shared/Modal/Modal';
import Configurations from '../../../Payments/Configurations';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const OverviewBlock = ({ patient, isLoading = false, showNewAppointmentModal }) => {
  const permission = useRecoilValue(permissions);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { device } = useUIContext();
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const mutateProfilePicture = useMutation({
    mutationFn: (data) =>
      updateProfilePicture(navigate, {
        handle: { jpg: data.filesUploaded[0].handle },
        userId: patient.id
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['patient', patient?.id]);
      showAlert({ message: 'Profile picture updated successfully!' });
    }
  });

  if (!patient || isLoading)
    return (
      <div className="overflow-hidden rounded-2xl bg-white">
        <Skeleton count={1} height="92px" background="white" highlight="#f8f8f8" />
      </div>
    );

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = (data) => mutateProfilePicture.mutate(data);
    client.picker(pickerOptions).open();
  };

  const commonProps = {
    showNewAppointmentModal,
    setSellModalVisible,
    setShowPaymentModal
  };

  return (
    <div className="relative flex items-center justify-between gap-7 border !border-x-0 !border-b !border-t-0 !border-neutral-100 bg-white py-[10px] pl-1 pr-7 lg:gap-4">
      <div className="flex items-center gap-2 overflow-hidden">
        <div
          className="flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full border border-solid border-neutral-300 bg-white shadow-small hover:!bg-neutral-50"
          onClick={() => navigate('/portal/charts')}>
          <Icon icon="new-arrow-left" className="flex cursor-pointer" size="12px" />
        </div>
        <div className="flex items-center gap-x-14 gap-y-4 overflow-hidden sm:gap-x-4 md:gap-x-4">
          <div className="flex items-center gap-x-7 gap-y-4 sm:gap-x-2 md:gap-x-2">
            <div onClick={() => openFilestackPicker()}>
              <ProfilePicture
                firstName={patient.f_name}
                lastName={patient.l_name}
                id={patient.id}
                image={patient.profile_photo}
                size={device === 'desktop' || device === 'laptop' ? 46 : 36}
              />
            </div>
            <div className="flex flex-col">
              <p
                className="w-max text-lg font-500 text-primary-900 sm:text-base md:text-base"
                data-dd-privacy="allow"
                data-public>
                {`${patient.f_name} ${patient.l_name} ${
                  patient?.p_name ? `(${patient.p_name})` : ''
                }`}
                {device !== 'desktop' && device !== 'laptop' && ` #${patient.id}`}
              </p>
              <div className="flex gap-1">
                <p className="text-xs text-neutral-800">Phone:</p>
                <p className="whitespace-nowrap text-xs text-neutral-800">
                  {formatPhoneNumber(patient.phone)}
                </p>
              </div>
            </div>
          </div>
          {device === 'desktop' || device === 'laptop' ? (
            <div className="flex gap-8 overflow-hidden">
              <div className="flex flex-col gap-[2px]" data-dd-privacy="allow" data-public>
                <p className="whitespace-nowrap text-sm font-500 text-primary-800">Chart ID</p>
                <p className="whitespace-nowrap text-xs text-neutral-800">{patient.id}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
                <p className="whitespace-nowrap text-sm font-500 text-primary-800">Date of birth</p>
                <p className="whitespace-nowrap text-xs text-neutral-800">
                  {moment.utc(patient.dob).format('Do MMM, YYYY')}
                </p>
              </div>
              <div className="flex flex-col gap-[2px]">
                <p className="text-sm font-500 text-primary-800">Age</p>
                <p className="text-xs text-neutral-800">
                  {moment.utc().diff(patient.dob, 'years')}
                </p>
              </div>
              <div className="flex flex-col gap-[2px] overflow-hidden">
                <p className="text-sm font-500 text-primary-800">Address</p>
                <Tippy content={patient?.userAddress?.fullAddress} placement="bottom">
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
                    {patient?.userAddress?.fullAddress}
                  </p>
                </Tippy>
              </div>
              <div className="flex flex-col gap-[2px] overflow-hidden">
                <p className="text-sm font-500 text-primary-800">Email</p>
                <Tippy content={patient.email} placement="bottom">
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
                    {patient.email}
                  </p>
                </Tippy>
              </div>
            </div>
          ) : (
            <div className="flex gap-8 overflow-hidden sm:gap-x-4 md:gap-x-4">
              <div className="flex flex-col gap-[2px]">
                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                  {moment.utc().diff(patient.dob, 'years')}
                  {patient.gender && ` - ${Capitalize(patient.gender)}`}
                </p>
                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                  {moment.utc(patient.dob).format('Do MMM, YYYY')}
                </p>
              </div>
              <div className="flex flex-col gap-[2px] overflow-hidden">
                <Tippy content={patient?.userAddress?.full_street_address} placement="bottom">
                  <p
                    className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                    title={patient?.userAddress?.full_street_address}>
                    {patient?.userAddress?.full_street_address}
                  </p>
                </Tippy>
                <Tippy content={patient.email} placement="bottom">
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-[17px] text-neutral-800">
                    {patient.email}
                  </p>
                </Tippy>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {device === 'desktop' && (
          <>
            {patient && (
              <StickyNotes
                patientId={patient.id}
                customStyle={{
                  button:
                    'cursor-pointer items-center bg-warning-50 flex gap-x-[6px] !px-[14px] !py-[5px] rounded-full'
                }}
              />
            )}
            <CreatePopover {...commonProps} />
          </>
        )}
        {permission?.patients?.delete && <OverviewPopover patient={patient} {...commonProps} />}
        {sellModalVisible && (
          <SellPackageProductModal
            sellModalVisible={sellModalVisible}
            setSellModalVisible={setSellModalVisible}
          />
        )}
        <Modal
          isOpen={showPaymentModal}
          handleClose={() => setShowPaymentModal(false)}
          slideFromRight
          handleOpen={showPaymentModal}
          title="New payment">
          <Configurations patientId={id} onClose={() => setShowPaymentModal(false)} />
        </Modal>
      </div>
    </div>
  );
};

export default OverviewBlock;
