import { iaRa } from 'lib/helpers/utility';

export const cleanNote = (note) => {
  delete note?.id;
  delete note?.appointment_id;
  delete note?.practitioner_id;
  delete note?.practice_id;
  delete note?.locked;
  delete note?.lock_history;
  delete note?.state;

  return note;
};

export const modifyData = ({
  data = {},
  currentHpOverviewData,
  appointmentId,
  patientId,
  practiceId
}) => {
  Object.keys(data || {}).forEach((key) => {
    if (key !== 'medicationHistory') {
      if (data?.[key]?.id) {
        data[key].id = currentHpOverviewData?.[key]?.id;
      } else {
        delete data?.[key]?.id;
      }
    } else {
      iaRa(data?.[key]?.response?.medications).forEach((med, index) => {
        med.id =
          currentHpOverviewData?.medicationHistory?.response?.medications?.[index]?.id || null;

        med.appointment_id = Number(appointmentId);
        med.patient_id = Number(patientId);
        med.practice_id = Number(practiceId);
      });
    }
  });

  return data;
};
