import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import ClinicalNotes from './ClinicalNotes';
import Superbills from './Superbills';
import Claims from './Claims';
import Tabs from 'components/shared/Tabs/NewTabs';

const EncounterReports = (props) => {
  const item = { name: 'Encounter Reports' };
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Clinical Notes',
      content: <ClinicalNotes {...props} />
    },
    {
      label: 'Superbills',
      content: <Superbills {...props} />
    },
    {
      label: 'Claims',
      content: <Claims {...props} />
    }
  ];

  return (
    <div>
      <WidgetHeader icon="new-notev2" item={item} hasPlus={false} />
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default EncounterReports;
