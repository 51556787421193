import React, { useMemo } from 'react';
import NotFound from '../components/NotFound';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import AGChart from 'components/shared/AGChart/AGChart';
import { io, mString } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';

const Income = (props) => {
  const item = { name: 'Income' };
  const { dashboardData, item: widgetItem, layout } = props;

  const widgetName = widgetItem?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const formattedData = dashboardData?.financialGraphData
    ? Object.keys(dashboardData.financialGraphData).map((timestamp) => {
        const dataPoint = dashboardData.financialGraphData[timestamp];
        const totalPayments =
          parseFloat(dataPoint.insurancePayments) + parseFloat(dataPoint.patientPayments);
        return {
          date: new Date(parseInt(timestamp, 10) * 1000),
          totalPayments
        };
      })
    : [];

  const options = {
    height: height > 0 ? height - 30 - height / proportion : 0,
    width,
    data: formattedData,
    theme: {
      overrides: {
        line: {
          series: {
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${mString(datum[yKey].toFixed(0), false)}` };
              }
            }
          }
        }
      }
    },
    series: [
      {
        type: 'line',
        xKey: 'date',
        yKey: 'totalPayments',
        stroke: '#00ABE8',
        marker: { fill: '#00ABE8', size: 10 }
      }
    ],
    axes: [
      {
        type: 'time',
        nice: true,
        position: 'bottom'
      },
      {
        type: 'number',
        position: 'left',
        nice: true,
        label: {
          formatter(params) {
            const isWholeNumber = params.value % 1 === 0;
            return `$${parseFloat(params.value).toLocaleString('en-US', {
              minimumFractionDigits: isWholeNumber ? 0 : 2,
              maximumFractionDigits: isWholeNumber ? 0 : 2
            })}`;
          }
        }
      }
    ],
    animation: true,
    seriesArea: { padding: { right: 18 } }
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  return (
    <div>
      <WidgetHeader icon="new-money-receive" item={item} hasPlus={false} />
      {io(dashboardData?.financialGraphData) ? (
        <div>
          <div className="!px-5 pt-[14px]">
            <div className="text-md text-neutral-500">Total</div>
            <div className="text-2xl font-bold text-primary-900">
              {mString(
                parseFloat(dashboardData?.count?.financial?.insurancePayments) +
                  parseFloat(dashboardData?.count?.financial?.patientPayments),
                false
              )}
            </div>
          </div>
          <AGChart options={options} key={chartKey} />
        </div>
      ) : (
        <NotFound
          icon="new-illustration-5"
          title="No income records yet!"
          description="You can start creating new tasks right now."
        />
      )}
    </div>
  );
};

export default Income;
