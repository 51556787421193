import { ia } from 'lib/helpers/utility';

export const getInitialData = (appointment, diffMin) => ({
  ...appointment,
  currentAmount: appointment?.amount,
  // modifiedDeductible: appointment?.deductible || 0,
  modifiedDeductible: null,
  // modifiedCopayment: appointment?.co_payment || 0,
  modifiedCopayment: null,
  // modifiedCoinsurance: appointment?.co_insurance || 0,
  modifiedCoinsurance: null,
  procedures: appointment?.invoice?.procedures || [],
  products: appointment?.invoice?.products || [],
  appointmentLength: diffMin,
  practice_id: appointment?.practitioner?.id,
  resource_id: ia(appointment?.resources)
    ? appointment?.resources?.[0].id || appointment?.resources?.[0].resource_id
    : undefined
});

export const fields = [
  'type',
  'state',
  'form_ids',
  'products',
  'location',
  'procedures',
  'service_id',
  'practice_note',
  'practitioner_id',
  'modifiedCopayment',
  'modifiedDeductible',
  'modifiedCoinsurance',
  'tag_ids'
];
