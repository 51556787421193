import Honeybadger from '@honeybadger-io/js';
import { interimApi } from 'api/InterimApi';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { formatDate } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';

const SuperbillHeaderActions = ({ superbill, loading, setLoading, exportRef }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const practice = useRecoilValue(currentPractice);
  const { superbill: superbillId } = useParams();
  const navigate = useNavigate();

  const sendEmail = async () => {
    setLoading({ ...loading, export: true });

    try {
      let res = await interimApi(
        '/api/practice/billing/superbill/export',
        {
          superbillId,
          medicalHistoryId: superbill?.encounter_id,
          appointmentId: superbill?.encounter?.appointment?.id
        },
        navigate
      );
      const { code } = res.data;

      switch (code) {
        case 0:
          setShowConfirmationModal(false);
          showAlert({
            color: 'success',
            message: 'Email sent successfully!'
          });
          break;
      }
    } catch (error) {
      showAlert({
        color: 'danger',
        message: 'There was an error while sending the email!'
      });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }

    setLoading({ ...loading, export: false });
  };
  return (
    <>
      <div className="flex justify-between">
        <div className="title">
          Superbill - {formatDate(superbill?.encounter?.appointment?.starts_at, practice?.timezone)}
        </div>
        <div className="flex gap-x-2.5">
          <Button
            data-qa="email-to-patient-btn"
            transparent
            text="Email to patient"
            className="print:hidden"
            loading={loading.export}
            onClick={() => setShowConfirmationModal(true)}
          />

          <ReactToPrint
            trigger={() => (
              <Button primary className="print:hidden" transparent text="Export PDF" />
            )}
            content={() => exportRef.current}
          />

          <Button
            transparent
            text="Edit SOAP notes"
            className="print:hidden"
            onClick={() =>
              navigate(
                `/portal/charts/${superbill.patient.id}/clinical-notes/${superbill.encounter.appointment.id}`
              )
            }
          />
        </div>
      </div>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={sendEmail}
        title="Send email"
        message="Are you sure you want to email this superbill to the patient?"
        primaryBtnTxt="Send email"
        icon="new-email-blue"
        loading={loading.export}
      />
    </>
  );
};

export default withErrorBoundary(SuperbillHeaderActions);
