import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Select from 'components/shared/Select/Select';
import * as Yup from 'yup';
import { genders, marital_status, races } from 'constants';
import { createPatient } from 'api/Patients';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Button from 'components/shared/Buttons/Button';
import Switch from 'components/shared/Switch/Switch';
import Modal from 'components/shared/Modal/Modal';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import { showAlert } from 'components/shared/Alert/Alert';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useOccupations } from 'lib/hooks/queries/useOccupations';
import { useStates } from 'lib/hooks/queries/useStates';
import { ia, randomString } from 'lib/helpers/utility';

const NewPatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  dob: Yup.string().required('Date of birth required')
});

const NewPatient = ({ newPatientModal, hideNewPatientModal }) => {
  const navigate = useNavigate();

  const { data, isLoading: practitionerIsLoading } = usePractitioners();
  const { data: statesData, isLoading: statesIsLoading } = useStates();
  const { data: occupationData, isLoading: occupationIsLoading } = useOccupations({
    returnAll: true
  });
  const practitioners = data?.practitioners;
  const states = statesData?.states;
  const occupations = occupationData?.occupations;

  const [selectedState, setSelectedState] = useState(null);
  const [selectedSecondaryState, setSelectedSecondaryState] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState();
  const [selectedPractitionerId, setSelectedPractitionerId] = useState('');
  const [invitationEmail, setInvitationEmail] = useState(true);
  const [noEmail, setNoEmail] = useState(false);
  const [openSecondaryAddress, setOpenSecondaryAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      f_name: '',
      m_name: '',
      l_name: '',
      email: '',
      dob: '',
      gender: '',
      personal_id: '',
      ssn: '',
      phone: '',
      marital_status: '',
      title: '',
      primary_doctor: '',
      race: '',
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: '',
      zip: '',
      secondary_address_ln_1: '',
      secondary_address_ln_2: '',
      secondary_city: '',
      secondary_state: '',
      secondary_zip: '',
      practitioner_id: '',
      invitationEmail: true,
      occupation_id: null
    },
    validationSchema: NewPatientValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const patientToBeCreated = {
        patient: {
          f_name: values.f_name,
          m_name: values.m_name,
          l_name: values.l_name,
          email: values.email,
          dob: values.dob,
          gender: values.gender,
          personal_id: values.personal_id,
          ssn: values.ssn,
          phone: values.phone,
          marital_status: values.marital_status,
          title: values.title,
          primary_doctor: values.primary_doctor,
          race: values.race
        },
        ...filterNullValues(
          {
            address_ln_1: values.address_ln_1,
            address_ln_2: values.address_ln_2,
            city: values.city,
            state: values.state,
            zip: values.zip
          },
          'address'
        ),
        ...filterNullValues(
          {
            address_ln_1: values.secondary_address_ln_1,
            address_ln_2: values.secondary_address_ln_2,
            city: values.secondary_city,
            state: values.secondary_state,
            zip: values.secondary_zip
          },
          'secondary_address'
        ),

        practitioner_id: selectedPractitionerId,
        occupation_id: values.occupation_id?.value || null,
        invitationEmail: values.invitationEmail
      };

      const modifiedPatient = {
        ...patientToBeCreated.patient,
        gender: formik.values.gender?.value,
        marital_status: formik.values.marital_status?.value,
        race: formik.values.race?.value
      };

      const modifiedData = {
        ...patientToBeCreated,
        patient: modifiedPatient
      };

      mutateCreatePatient.mutate(modifiedData);
      resetForm();
    }
  });

  useEffect(() => {
    if (ia(practitioners)) {
      setSelectedPractitioner(practitioners[0]);
      const [firstPractitioner] = practitioners?.slice(0, 1);
      if (firstPractitioner) {
        const idOfFirstPractitioner = Object?.values(firstPractitioner)[0];
        setSelectedPractitionerId(idOfFirstPractitioner?.toString());
      }
    }
  }, [practitioners]);

  const filterNullValues = (obj, name) => {
    let allNullValues = true;
    let result = {};
    for (let key in obj) {
      if (!!obj[key]) {
        result[key] = obj[key];
        allNullValues = false;
      }
    }

    return allNullValues ? {} : { [name]: result };
  };

  const handleSelectPractitioner = (practitioner) => {
    setSelectedPractitioner(practitioner);
    setSelectedPractitionerId(practitioner?.value?.toString());
    formik.setFieldValue('practitioner_id', practitioner?.value);
  };

  const handleSelectState = (state, field) => {
    if (field === 'secondary_state') {
      setSelectedSecondaryState(state);
      formik.setFieldValue('secondary_state', state?.value);
    } else {
      setSelectedState(state);
      formik.setFieldValue('state', state?.value);
    }
  };

  const handleInvitationEmail = (value) => {
    setInvitationEmail(!invitationEmail);
    formik.setFieldValue('invitationEmail', value);
  };

  const queryClient = useQueryClient();

  const mutateCreatePatient = useMutation({
    mutationFn: (data) => createPatient(navigate, data),
    onSuccess: (data) => {
      formik.setSubmitting(false);
      hideNewPatientModal();
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['patients']);
          showAlert({
            title: 'Patient created successfully!',
            buttons: [
              { text: 'View Chart', onClick: () => navigate(`/portal/charts/${data.patientId}`) }
            ],
            icon: 'user-add'
          });
          break;
        case 3:
          showAlert({
            title: 'Patient duplication!',
            message: 'A patient with this email already exists in your practice.',
            buttons: [
              {
                text: 'View Chart',
                color: 'warning',
                onClick: () => navigate(`/portal/charts/${data.patientId}`)
              }
            ],
            color: 'warning',
            icon: 'user-add'
          });
          break;

        default:
          break;
      }
    }
  });

  const handleSubmitNewPatient = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };

  return (
    <Modal
      handleOpen={newPatientModal}
      handleClose={hideNewPatientModal}
      title="Create new patient"
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between" data-dd-privacy="allow">
          <Button
            transparent
            onClick={hideNewPatientModal}
            text="Cancel"
            color="neutral"
            outlined
          />
          <Button
            onClick={handleSubmitNewPatient}
            loadingIcon={formik.isSubmitting}
            text="Create patient"
            data-qa="create-patient-btn"
          />
        </div>
      }>
      <form ref={formRef} data-dd-privacy="allow">
        <div className="groupColumns !pt-0">
          <h3 className="groupColumns__title">Account information</h3>
          <div className="groupColumnsFour">
            <Input
              label="First name"
              placeholder="First name"
              name="f_name"
              data-qa="first-name"
              value={formik.values.f_name}
              onChange={formik.handleChange}
              id="firstName"
              error={formik.errors.f_name}
            />
            <div>
              <Input
                label="Middle name"
                placeholder="Middle name"
                name="m_name"
                data-qa="middle-name"
                value={formik.values.m_name}
                onChange={formik.handleChange}
                id="middleName"
                error={formik.errors.m_name}
              />
            </div>
            <div>
              <Input
                label="Last name"
                placeholder="Last name"
                name="l_name"
                data-qa="last-name"
                value={formik.values.l_name}
                onChange={formik.handleChange}
                id="lastName"
                error={formik.errors.l_name}
              />
            </div>
            <div className="grid gap-y-1">
              <Input
                label="Email"
                placeholder="email@example.com"
                name="email"
                data-qa="email"
                value={noEmail ? '' : formik.values.email}
                onChange={formik.handleChange}
                id="email"
                disabled={noEmail}
                error={formik.errors.email}
              />
              <Checkbox
                label="No email"
                name="no_email"
                data-qa="no-email"
                isChecked={noEmail}
                onChange={() => {
                  setNoEmail(!noEmail);
                  formik.setFieldValue(
                    'email',
                    !noEmail ? `autogen_${randomString(16)}@gen.myriad.health` : ''
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Personal information</h3>
          <div className="groupColumnsFour">
            <div>
              <DatePopover
                label="Date of birth"
                labelClassName="w-max"
                placeholder="Date of birth"
                name="other_ins_dob"
                value={formik.values.dob}
                onChange={(event) => formik.setFieldValue('dob', event)}
              />
              {formik.errors.dob && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.dob}</span>
              )}
            </div>

            <Input
              label="Phone number"
              placeholder="+1(123)4567890"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              id="phoneNumber"
              data-qa="phone-number"
              error={formik.errors.phone}
            />
            <div>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="gender">
                Gender
              </label>
              <Select
                placeholder="Type and select gender"
                value={formik.values.gender}
                onChange={(value) => formik.setFieldValue('gender', value)}
                options={genders}
                inputId="gender"
              />
              {formik.errors.gender && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.gender}
                </span>
              )}
            </div>
            <div>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="race">
                Race
              </label>
              <Select
                placeholder="Type and select race"
                value={formik.values.race}
                onChange={(value) => {
                  formik.setFieldValue('race', value);
                }}
                options={races}
                inputId="race"
              />
            </div>
            <div>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="maritalStatus">
                Marital Status
              </label>
              <Select
                placeholder="Type and select status"
                value={formik.values.marital_status}
                onChange={(value) => formik.setFieldValue('marital_status', value)}
                options={marital_status}
                inputId="maritalStatus"
              />
            </div>
            <Input
              label="SSN"
              placeholder="Social Security Number"
              name="ssn"
              value={formik.values.ssn}
              onChange={formik.handleChange}
              id="socialSecurityNumber"
              data-qa="social-security-number"
            />
            <Input
              label={`Driver's License`}
              placeholder="Driver's License"
              name="personal_id"
              value={formik.values.personal_id}
              onChange={formik.handleChange}
              id="driverLicense"
              data-qa="driver-license"
            />
            <div>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="maritalStatus">
                Occupation
              </label>
              <Select
                placeholder="Type and select status"
                value={formik.values.occupation_id}
                onChange={(value) => formik.setFieldValue('occupation_id', value)}
                options={
                  ia(occupations)
                    ? occupations.map((occupation) => ({
                        value: occupation.id,
                        label: occupation.name
                      }))
                    : []
                }
                inputId="occupation"
              />
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Physical address</h3>
          <div className="groupColumnsFour">
            <Input
              label="Address"
              placeholder="Address"
              name="address_ln_1"
              value={formik.values.address_ln_1}
              onChange={formik.handleChange}
              id="addressFirst"
              data-qa="address-first"
              error={formik.errors.address_ln_1}
            />
            <Input
              label="Address 2"
              placeholder="Address 2"
              name="address_ln_2"
              value={formik.values.address_ln_2}
              onChange={formik.handleChange}
              id="addressSecond"
              data-qa="address-second"
              error={formik.errors.address_ln_2}
            />

            <Input
              label="City"
              placeholder="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              id="city"
              data-qa="city"
              error={formik.errors.city}
            />
            <div>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="state">
                State
              </label>
              <Select
                placeholder="Type and select state"
                value={selectedState}
                onChange={(value) => handleSelectState(value)}
                options={
                  ia(states)
                    ? states.map((state) => ({
                        value: state.id,
                        label: state.name
                      }))
                    : []
                }
                inputId="state"
              />
              {formik.errors.state && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.state}
                </span>
              )}
            </div>
            <Input
              label="Zip code"
              placeholder="Zip"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              id="zipCode"
              data-qa="zip-code"
              error={formik.errors.zip}
            />
          </div>
        </div>
        <div className="groupColumns">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-500">Secondary Physical address</h3>
            <div className="flex gap-4">
              <Switch
                onChange={(value) => setOpenSecondaryAddress(value)}
                checked={openSecondaryAddress}
                data-qa="secondary-physical-address"
              />
            </div>
          </div>
          {openSecondaryAddress && (
            <div className="groupColumnsFour pt-2">
              <Input
                label="Address"
                placeholder="Address"
                name="secondary_address_ln_1"
                value={formik.values.secondary_address_ln_1}
                onChange={formik.handleChange}
                id="addressFirst"
                data-qa="address-first-2"
                error={formik.errors.secondary_address_ln_1}
              />
              <Input
                label="Address 2"
                placeholder="Address 2"
                name="secondary_address_ln_2"
                value={formik.values.secondary_address_ln_2}
                onChange={formik.handleChange}
                id="addressSecond"
                data-qa="address-second-2"
                error={formik.errors.secondary_address_ln_2}
              />

              <Input
                label="City"
                placeholder="City"
                name="secondary_city"
                value={formik.values.secondary_city}
                onChange={formik.handleChange}
                id="city"
                data-qa="city-2"
                error={formik.errors.secondary_city}
              />
              <div>
                <label
                  className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                  htmlFor="state">
                  State
                </label>
                <Select
                  placeholder="Type and select state"
                  value={selectedSecondaryState}
                  onChange={(value) => handleSelectState(value, 'secondary_state')}
                  options={states}
                  inputId="state-second-address"
                />
                {formik.errors.secondary_secondary_state && (
                  <span className="!pt-2 text-left text-sm text-danger-500">
                    {formik.errors.state}
                  </span>
                )}
              </div>
              <Input
                label="Zip code"
                placeholder="Zip"
                name="secondary_zip"
                value={formik.values.secondary_zip}
                onChange={formik.handleChange}
                id="zipCode"
                data-qa="zip-code-2"
                error={formik.errors.secondary_zip}
              />
            </div>
          )}
        </div>
        <div className="groupColumns !pb-6">
          <div className="groupColumnsTwo">
            <div>
              <h3 className="groupColumns__title">Practitioners</h3>
              <label
                className="m-0 block pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="practitioner">
                Practitioner
              </label>
              <Select
                placeholder="Type and select practitioner"
                value={selectedPractitioner}
                onChange={(value) => handleSelectPractitioner(value)}
                options={practitioners?.map((practitioner) => ({
                  value: practitioner.id,
                  label: `${practitioner.f_name} ${practitioner.l_name}`
                }))}
                inputId="practitioner"
                menuPlacement="top"
              />
            </div>
            <div className="w-max">
              <h3 className="groupColumns__title notifications-title">Notifications</h3>
              <div className="flex items-center gap-2">
                <Switch
                  onChange={(value) => handleInvitationEmail(value)}
                  checked={invitationEmail}
                  data-qa="send-invitation-email"
                />
                <label
                  className="m-0 p-0 text-sm font-500 font-500 text-neutral-800"
                  htmlFor="invitationEmail">
                  Send invitation email
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewPatient;
