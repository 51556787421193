import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import HipaaFormModal from '../Modal/HipaaFormModal/HipaaFormModal';
import CheckinStepsStep from './Step';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';

const CheckinStepsHIPAA = ({ appointment, step }) => {
  const [showHipaaModal, setShowHipaaModal] = useState(false);

  const [hipaaForm, setHipaaForm] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getHipaaForm();
  }, []);

  const getHipaaForm = async () => {
    try {
      const res = await requestApi({
        url: '/api/patient/checkin/get_hipaa_form',
        params: {
          appointmentId: appointment?.id
        },
        navigate
      });

      if (res?.hipaa_consent?.data) {
        setHipaaForm(decodeURIComponent(JSON.parse(res?.hipaa_consent?.data)?.htmlData));
      } else {
        setHipaaForm('noHIPAA');
      }
    } catch (e) {
      console.error(e);
      setHipaaForm(
        '<div>There has been an error retrieving HIPAA form. Please try again later.</div>'
      );
    }
  };

  return (
    <>
      <CheckinStepsStep
        step={{ ...step, completed: hipaaForm !== 'noHIPAA' }}
        onClick={() => setShowHipaaModal(true)}
        onEdit={() => setShowHipaaModal(true)}
        addInfo={
          (step?.completed && `updated: ${moment(appointment?.updated_at).format('MM/DD/YYYY')}`) ||
          ''
        }
      />
      <HipaaFormModal
        hipaaForm={hipaaForm}
        showHipaaModal={showHipaaModal}
        hideHipaaModal={() => setShowHipaaModal(false)}
      />
    </>
  );
};

export default CheckinStepsHIPAA;
