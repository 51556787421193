import React from 'react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, hasObjectTrueValues } from 'lib/helpers/utility';
import { isNumber } from 'lodash';

const Superbills = ({ data }) => {
  if (!hasObjectTrueValues(data))
    return (
      <NotFound
        icon="new-illustration-8"
        iconClassName="!pb-3"
        title="No Superbills found!"
        description="You can start creating new superbills on chart details."
      />
    );

  const chartData = [
    { name: 'Draft', value: data?.draft || 0, fill: '#5090DC' },
    { name: 'Completed', value: data?.completed || 0, fill: '#FFA03A' }
  ].filter((item) => item.value > 0);

  const options = {
    data: chartData,
    width: 200,
    height: 200,
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'name',
        angleKey: 'value',
        innerRadiusRatio: 0.7,
        calloutLabel: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: false
    }
  };

  return (
    <div className="flex items-center gap-4 !p-6">
      <AGChart options={options} />
      <div className="!grid w-full auto-rows-max grid-cols-[repeat(auto-fill,minmax(100px,max-content))] !gap-4">
        {options?.data?.map((item, index) => (
          <div className="flex h-max flex-col gap-1" key={index}>
            <div className="flex items-center gap-2">
              <div className="h-3 w-1 rounded-lg" style={{ backgroundColor: item.fill }}></div>
              <p className="font-medium text-neutral-500">{item.name}</p>
            </div>

            {isNumber(item.value) && (
              <p className="!ml-2 text-xl font-semibold text-primary-900">
                {formatLargeNumber(item.value)}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Superbills;
