import React, { useState } from 'react';
import Navigation from './components/Navigation/Navigation';
import Days from './components/Days';
import Arrows from './components/Arrows';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import { useNavigate } from 'react-router-dom';
import Appointments from './components/Appointments/Appointments';
import { useAppointmentEvents } from 'lib/hooks/queries/appointment/useAppointmentEvents';
import { useQueryClient } from '@tanstack/react-query';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { mapValues } from 'lib/helpers/utility';
import _, { isEqual } from 'lodash';
import moment from 'moment-timezone';

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [, setCurrentWeek] = useState(moment().week());
  const [selectedDate, setSelectedDate] = useState(moment());
  const { filters, setFilters } = useDashboardContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading } = useAppointmentEvents({
    defaultDate: selectedDate,
    navigate,
    withEvents: true,
    filters: mapValues({
      ...filters,
      practitioners: filters?.practitioners?.values?.map((item) => item.value),
      serviceType: filters?.serviceType?.values?.map((item) => item.value),
      appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
      appointmentTags: filters?.appointmentTags?.values?.map((item) => item.id),
      resources: filters?.resources?.values?.map((item) => item.value)
    })
  });

  const changeMonthHandle = (newMonth) => setCurrentMonth(newMonth);

  const changeWeekHandle = (btnType) => {
    if (btnType === 'prev') {
      setCurrentMonth(moment(currentMonth).subtract(1, 'weeks'));
      setCurrentWeek(moment(currentMonth).subtract(1, 'weeks').week());
    }
    if (btnType === 'next') {
      setCurrentMonth(moment(currentMonth).add(1, 'weeks'));
      setCurrentWeek(moment(currentMonth).add(1, 'weeks').week());
    }
  };

  const goToTodayHandle = (today) => {
    setCurrentMonth(today);
    setSelectedDate(today);
  };

  const onDateClickHandle = async (day) => {
    setSelectedDate(day);

    queryClient.invalidateQueries(['appointment-events']);
  };

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="bg-primary-50 !py-3">
        <Navigation {...{ currentMonth, changeMonthHandle, goToTodayHandle }} />
        <div className="relative">
          <Days {...{ currentMonth, selectedDate, onDateClickHandle }} />
          <Arrows {...{ currentMonth, changeWeekHandle }} />
        </div>
      </div>
      <div className="flex flex-col overflow-auto">
        <Appointments {...{ data, isLoading, selectedDate }} />
      </div>
      <FooterWrapper onClick={() => navigate('appointments')} text="Go to calendar" />
    </div>
  );
};

export default Calendar;
