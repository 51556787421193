import cs from 'classnames';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { ia } from '../../../lib/helpers/utility';
import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import PermissionsTable from './PermissionsTable';

const PermissionsEntry = ({ permissions, onChange = () => {}, allExpanded, edit, index }) => {
  const [show, setShow] = useState({ expanded: false });

  if (ia(permissions?.properties)) {
    return (
      <>
        <tr
          className={cs('hover:bg-neutral-100', (show.expanded || allExpanded) && 'bg-neutral-100')}
          onClick={() => setShow({ ...show, expanded: !show.expanded })}
          key={`permissions-entry-path-${permissions?.path}-0`}>
          <td>{capitalize(String(permissions?.name).replace(/\_/g, ' '))}</td>
          <td colSpan={2}>{permissions?.message}</td>
          <td>
            <Button
              id={`permissions-${index}`}
              icon={show.expanded ? 'chevron-down' : 'chevron-up'}
              onClick={() => setShow({ ...show, expanded: !show.expanded })}
              clear
              style={{
                padding: '0',
                margin: 'auto auto',
                backgroundColor: 'transparent'
              }}></Button>
          </td>
        </tr>
        {(show.expanded || allExpanded) && (
          <tr
            className="bg-neutral-100 hover:bg-neutral-100"
            key={`permissions-entry-path-${permissions?.path}-1`}>
            <td colSpan={4}>
              <div>
                <PermissionsTable>
                  {permissions?.properties?.map((v, i) => (
                    <PermissionsEntry
                      permissions={v}
                      onChange={(o) => {
                        let newRecursePath =
                          typeof index === 'number'
                            ? ['properties', index, ...o.recursePath]
                            : o.recursePath;
                        onChange({ ...o, recursePath: newRecursePath });
                      }}
                      allExpanded={allExpanded}
                      edit={edit}
                      index={i}
                    />
                  ))}
                </PermissionsTable>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  }

  if (permissions?.name) {
    return (
      <tr key={`permissions-entry-path-${permissions?.path}-2`}>
        <td>{permissions.name}</td>
        <td colSpan={2}>{permissions.message}</td>
        <td>
          <Switch
            onChange={(e) =>
              onChange({
                path: permissions.path,
                newValue: e,
                recursePath: ['properties', index]
              })
            }
            checked={!!permissions.value}
            name={permissions.path}
          />
        </td>
      </tr>
    );
  }

  return null;
};

export default PermissionsEntry;
