import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getKiosk, getKiosks, kioskRequests } from '../../../api/Kiosk';

export const useKiosks = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['kiosks', ...dependencies], () => getKiosks(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useKiosk = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['kiosk', ...dependencies], () => getKiosk(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useKioskRequests = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['kiosk-requests', ...dependencies], () => kioskRequests(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
