import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Icon from 'components/shared/Icon/Icon';
import Tabs from 'components/shared/Tabs/NewTabs';
import { useNavigate } from 'react-router-dom';
import Claims from './components/Claims';
import ERAEOB from './components/ERA&EOB';
import Superbills from './components/Superbills';
import Invoices from './components/Invoices';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const BeyondBilling = (props) => {
  const [listView, setListView] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [navigatePath, setNavigatePath] = useState('claims');
  const navigate = useNavigate();
  const tabsData = [
    {
      label: 'Claims',
      content: (
        <Claims
          {...props}
          listView={listView}
          setNavigatePath={setNavigatePath}
          navigatePath={navigatePath}
        />
      )
    },
    {
      label: 'ERA/EOB',
      content: (
        <ERAEOB
          {...props}
          listView={listView}
          setNavigatePath={setNavigatePath}
          navigatePath={navigatePath}
        />
      )
    },
    {
      label: 'Superbills',
      content: (
        <Superbills
          {...props}
          listView={listView}
          setNavigatePath={setNavigatePath}
          navigatePath={navigatePath}
        />
      )
    },
    {
      label: 'Invoices',
      content: (
        <Invoices
          {...props}
          listView={listView}
          setNavigatePath={setNavigatePath}
          navigatePath={navigatePath}
        />
      )
    }
  ];
  const handleListView = () => setListView(!listView);
  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader icon="new-billing" hasPlus={false} {...props}>
        <div
          onClick={handleListView}
          className="cursor-pointer rounded-lg bg-primary-50 !px-1 !py-1">
          <Icon
            className="cursor-pointer"
            color="primary"
            icon={listView ? 'new-graph' : 'list-unordered'}
          />
        </div>
        <div
          onClick={() => {
            navigate(`/portal/beyond-billing/${navigatePath}`);
          }}
          className="cursor-pointer rounded-lg bg-neutral-100 !px-2 !py-2">
          <Icon className="cursor-pointer" icon="new-link"></Icon>
        </div>
      </WidgetHeader>
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default withErrorBoundary(BeyondBilling);
