import { Popover } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { ia } from 'lib/helpers/utility';
import _, { update } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { createMedication, deleteMedication, getMedications } from '../../../../../api/Medication';
import { socket } from '../../../../../api/Socket';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import SSOAction from '../../../ePrescribe/SSOAction';
import { patient } from '../../../ePrescribe/SSOKinds';
import '../Custom.scss';
import { CurrentMedications as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { deleteCustomFormCheck } from '../lib/deleteCustomFormCheck';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import formatMedicationsNarrative from '../lib/formatMedicationsNarrative';
import CurrentMedicationsRow from './CurrentMedicationsRow';
import { areMedicationsFilled, medicationInitialValue } from './lib';

const CurrentMedications = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  showNarrative = false,
  onChange,
  hideNoKnownMedications = false,
  fromClinicalNote = false,
  fromOverview = false,
  setItemModal = () => {}
}) => {
  const newMedication = { ...medicationInitialValue, id: self.crypto.randomUUID() };
  const { cnDisplaySettings, setCurrentHpOverviewData, setOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const queryClient = useQueryClient();
  const [currentMedications, setCurrentMedications] = useState(initialValue);
  const [prevCurrentMedications, setPrevCurrentMedications] = useState(initialValue);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModal2, setConfirmationModal2] = useState(false);
  const navigate = useNavigate();
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.medications?.advancedForm
      : true;
  const textAreaRef = useRef();
  const practice = useRecoilValue(currentPractice);
  const location = useLocation();
  const fromCheckin = location?.pathname?.includes('/checkin');
  const [medicationToDelete, setMedicationToDelete] = useState(null);

  const { id: patientId, appointmentId } = useParams();

  const currentAppointmentId = customFormsParams?.appointmentId || appointmentId;
  const currentPatientId = customFormsParams?.id || patientId;
  let idToDeleteClincalNoteCase;

  useEffect(() => {
    let jsonData = defaultValue;
    if (typeof defaultValue == 'string') {
      jsonData = JSON.parse(defaultValue);
    }
    if ((jsonData && ia(jsonData?.medications)) || jsonData?.noDetails) {
      setCurrentMedications(jsonData);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(currentMedications);
  }, [currentMedications]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setCurrentMedications;
    }
  }, []);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'CurrentMedications', type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event?.preventDefault();

    const newMedication = { ...medicationInitialValue, id: self.crypto.randomUUID() };

    const shouldStopProcess = doEmptyFormExist({
      newForm: newMedication,
      forms: currentMedications?.medications
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Medications',
        message: 'Empty medication exists. Please fill in the details.',
        color: 'warning'
      });
      return;
    }

    const updatedObject = {
      ...currentMedications,
      noDetails: false,
      medications: [newMedication, ...currentMedications.medications]
    };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const syncAllergiesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ currentMedicationsObject: updatedObject });
    } else {
      setCurrentMedications(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };
  const mutateDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMedication(navigate, {
        medicationId: currentMedications?.medications[0]?.id
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }
      setConfirmationModal(false);
      setCurrentMedications(initialValue);
      fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId: currentMedications?.medications[0]?.id,
          type: 'medicationHistory'
        });
      showAlert({
        title: `Medication deleted successfully`
      });
      queryClient.invalidateQueries(['medications']);
      setItemModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!currentMedications?.medications[0]?.id && !id) {
      showAlert({
        title: 'Medications',
        message: 'Please create medication before deleting one.',
        color: 'warning'
      });
      setMedicationToDelete(null);
      setConfirmationModal(false);
      setCurrentMedications(initialValue);
      return;
    }
    const medicationRows = currentMedications?.medications || [];
    const checkIfNumber = typeof currentMedications?.medications[0]?.id === 'number';
    if ((medicationRows.length === 1 && fromOverview) || (fromClinicalNote && checkIfNumber)) {
      setConfirmationModal(true);
    } else if (medicationRows.length === 1 && fromClinicalNote) {
      setConfirmationModal2(true);
      idToDeleteClincalNoteCase = id;
    } else {
      const updatedObject = {
        ...currentMedications,
        medications: currentMedications.medications.filter((row) => row.id !== id)
      };
      setConfirmationModal(false);
      medicationToDelete && setMedicationToDelete(null);
      syncAllergiesAndClinicalNotes(updatedObject);
    }
  };
  const fromClincalNoteDeleteCase = () => {
    setConfirmationModal2(false);
    const updatedObject = {
      ...currentMedications,
      medications: [initialValue]
    };
    setCurrentMedications(updatedObject);
    deleteFormOverviewAndHpNote({
      setOverviewData,
      setHpNoteData: setCurrentHpOverviewData,
      formId: idToDeleteClincalNoteCase,
      type: 'medicationHistory'
    });
    showAlert({
      title: `Medication deleted successfully`
    });
  };

  const handleOnChange = (key, event, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleOnChangeMulti = (values, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, ...values };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleNoDetails = (skipConfirmation = false) => {
    if (
      areMedicationsFilled(currentMedications?.medications) &&
      !currentMedications.noDetails &&
      !skipConfirmation
    ) {
      setConfirmationModal(true);
      return;
    }

    const updatedObject = {
      ...currentMedications,
      noDetails: !currentMedications.noDetails,
      medications: currentMedications.noDetails ? [medicationInitialValue] : []
    };
    setCurrentMedications(currentMedications.noDetails ? [medicationInitialValue] : []);
    syncAllergiesAndClinicalNotes(updatedObject);
    setConfirmationModal(false);
  };

  const handleNarrative = ({
    currentMedicationsObject = currentMedications,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);
    const updatedObject = { ...currentMedicationsObject, narrative: event };

    setCurrentMedications(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon
            icon="new-clinical-narrative"
            className="cursor-pointer"
            data-qa="new-clinical-narrative"
          />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    currentMedicationsObject = currentMedications,
    scrollable = false
  } = {}) => {
    const formattedNarrative = formatMedicationsNarrative(currentMedicationsObject.medications);
    handleNarrative({
      currentMedicationsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  const handleRefetchMedications = async () => {
    const params = {
      appointmentId: currentAppointmentId || null,
      patientId: currentPatientId
    };
    const data = await getMedications(null, params);
    setCurrentHpOverviewData &&
      setCurrentHpOverviewData((prev) => ({ ...prev, medicationHistory: data.medicationHistory }));
  };

  useEffect(() => {
    socket.on('veradigm-prescribe', () => {
      handleRefetchMedications();
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  const handleStartVeradigmSessionSocket = () => {
    const data = {
      appointmentId: currentAppointmentId || null,
      patientId: currentPatientId,
      practice_id: practice.id,
      user_id: user.id
    };
    socket.emit('veradigm-prescribe', { ...data });
  };

  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['medications'] });
  };

  return (
    <div className="CurrentMedications CustomForms">
      <input type="hidden" value={JSON.stringify(currentMedications)} ref={forwardedRef} />
      {!hideNoKnownMedications && isAdvancedForm && (
        <Checkbox
          data-qa="no-known-medications"
          label="No known medications"
          name="noDetails"
          isChecked={currentMedications.noDetails}
          onChange={() => handleNoDetails()}
        />
      )}

      {!currentMedications.noDetails && isAdvancedForm && (
        <div className="dashed-top !mt-4">
          <div className="flex justify-end !pb-2">
            <Popover className="relative">
              <Popover.Button>
                <Button
                  data-qa="add-medication"
                  text="Add medication"
                  iconRight="add-circle"
                  transparent
                  textClassName="text-primary-500 text-sm"
                />
              </Popover.Button>
              <Popover.Panel className="absolute z-40 mt-[6px] grid w-max cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
                {({ close }) => (
                  <>
                    <div
                      className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                      onClick={() => {
                        handleAddRow();
                        close();
                      }}>
                      <Icon icon="new-export-bulk" />
                      <span className="text-sm font-400 text-primary-900">Past medication</span>
                    </div>
                    {user?.kind === 'practitioner' && (
                      <SSOAction
                        enableEnrollPatient
                        patientId={currentPatientId}
                        kind={patient}
                        handleStartVeradigmSessionSocket={handleStartVeradigmSessionSocket}
                        isButton
                      />
                    )}
                  </>
                )}
              </Popover.Panel>
            </Popover>
          </div>
          <div className="CustomForms__body">
            {currentMedications?.medications?.map((item, idx) => (
              <CurrentMedicationsRow
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                key={item.id}
                onChange={handleOnChange}
                lastMedications={currentMedications}
                fromCheckin={fromCheckin}
                initialObject={newMedication}
                medicationInitialValue={medicationInitialValue}
                setConfirmationModal={setConfirmationModal}
                setMedicationToDelete={setMedicationToDelete}
                onChangeMulti={handleOnChangeMulti}
                onDelete={handleDeleteRow}
                length={currentMedications.medications.length}
                {...item}
              />
            ))}
          </div>
        </div>
      )}

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setCurrentMedications}
            restData={{
              className: 'w-full',
              label: 'Current Medications Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-medication',
              'data-qa': 'narrative-medication',
              name: 'Narrative-medication',
              value: currentMedications?.narrative,
              forwardedRef: textAreaRef,
              formName: 'currentMedications'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          if (_.isEqual(currentMedications, prevCurrentMedications)) {
            return;
          }
          const params = {
            appointmentId: currentAppointmentId || null,
            patientId: currentPatientId,
            medication: currentMedications
          };

          createMedication(() => {}, params).then(handleInvalidateQuery);
          setPrevCurrentMedications(currentMedications);
        }}
      />
      {confirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete medications"
          icon="trash"
          message={
            deleteCustomFormCheck(medicationToDelete, currentMedications?.medications)
              ? 'Are you sure you want to delete this medication?'
              : 'Are you sure you want to delete this form?'
          }
          handleContinue={() => {
            if (currentMedications?.noDetails) {
              handleNoDetails(true);
            } else if (deleteCustomFormCheck(medicationToDelete, currentMedications?.medications)) {
              handleDeleteRow(medicationToDelete);
            } else {
              if (!fromCheckin && typeof medicationToDelete !== 'number') {
                const updatedObjet = {
                  ...initialValue,
                  medications: [newMedication]
                };
                setCurrentMedications(updatedObjet);
                setConfirmationModal(false);
                return;
              }
              mutateDeleteMedication.mutate();
            }
          }}
          handleOpen={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
        />
      )}
      {confirmationModal2 && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete medication"
          icon="trash"
          message="Are you sure you want to delete medication"
          handleContinue={() => {
            fromClincalNoteDeleteCase();
          }}
          handleOpen={confirmationModal2}
          handleClose={() => setConfirmationModal2(false)}
        />
      )}
    </div>
  );
};

export default CurrentMedications;
