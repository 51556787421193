import cs from 'classnames';
import React, { useRef, useState } from 'react';
import { allergies, typicalReactions } from '../../../../../constants';
import Icon from '../../../Icon/Icon';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';

const AllergiesRow = ({
  allergy,
  typicalReaction,
  note,
  id,
  onDelete,
  onChange,
  className,
  fromCheckin = false,
  length
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <div className="grid w-full grid-cols-2 gap-4 sm:flex sm:flex-col">
        <Select
          inputId="select-allergy"
          label="Allergy"
          placeholder="Type and select allergy"
          options={allergies}
          value={allergy}
          name="allergy"
          isCreatable
          onChange={(event) => onChange('allergy', event, id)}
          disabled={disabled}
        />
        <Select
          inputId="select-reaction"
          label="Typical reaction"
          placeholder="Type and select reaction"
          options={typicalReactions}
          value={typicalReaction}
          name="typicalReaction"
          isCreatable
          onChange={(event) => onChange('typicalReaction', event, id)}
          disabled={disabled}
        />
        <Textarea
          label="Notes"
          className="col-span-2"
          placeholder="Add additional notes here"
          id="note"
          data-qa="notes"
          name="note"
          value={note}
          onChange={(event) => onChange('note', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />

        {/* <div className="grow basis-[45%] lg:basis-[280px]">
          <Select
            label="Medication provided"
            placeholder="Type and select medication"
            options={medications}
            value={medication}
            name="medication"
            isCreatable
            onChange={(event) => onChange('medication', event, id)}
            disabled={disabled}
          />
        </div> */}

        {/* <div className="w-full">
          <label className="m-0 !pr-4 text-sm !font-500 text-neutral-800" htmlFor="typicalReaction">
            Does patient carry an epi-pen?
          </label>
          <div className="flex mt-10">
            <RadioButton
              label="yes"
              id={`yes-${index}`}
              value="yes"
              isSelected={epiPen === 'yes'}
              onChange={(event) => onChange('epiPen', event.target.value, id)}
              disabled={disabled}
            />
            <RadioButton
              label="no"
              id={`no-${index}`}
              value="no"
              isSelected={epiPen === 'no'}
              onChange={(event) => onChange('epiPen', event.target.value, id)}
              disabled={disabled}
            />
          </div>
        </div> */}
      </div>
      {(!fromCheckin || length > 1) && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default AllergiesRow;
