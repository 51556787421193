import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import Icon from '../Icon/Icon';
import { customStyle } from './style';

const Select = ({
  parentClassName,
  className,
  placeholder = 'Select',
  value = null,
  options = [],
  isImage = false,
  imageClassName = null,
  onChange = null,
  isMulti = false,
  isClearable = true,
  defaultOptions = [],
  isCreatable = false, // creatable
  onCreateOption, // creatable
  isAsync = false, // async
  isLoading, // async
  onInputChange, // async
  loadOptions, // async
  icon, // async
  formatOptionLabel,
  formatCreateLabel,
  formatMultiValueContainer,
  inputValue,
  width = null,
  minWidth = null,
  height = null,
  id,
  name,
  label,
  tooltip,
  labelIcon,
  disabled = false,
  error,
  forwardedRef,
  menuPortalTarget,
  required,
  filterOption,
  rightIcon,
  rightIconSize,
  rightIconColor,
  rightIconShade,
  rightIconClick = () => {},
  styles,
  noDropdown,
  ...rest
}) => {
  if (isAsync && (!onChange || !loadOptions)) {
    return (
      <div className="grid !rounded border !border-danger-500 !p-2 font-500 text-danger-500">
        <p>Async props are missing!</p>
        <p>{__dirname}</p>
      </div>
    );
  }

  const formatIsImage = ({ image, value, label }) => {
    return (
      <div className="flex items-center gap-x-2">
        {image ? (
          <div className="rounded-smooth flex h-6 w-6 items-center justify-center overflow-hidden bg-neutral-100">
            <img
              className={cs(imageClassName ? imageClassName : 'h-full w-full object-cover')}
              src={image}
              alt={value}
            />
          </div>
        ) : (
          <Icon icon="new-no-img" className="cursor-pointer" />
        )}
        {label}
      </div>
    );
  };

  const rightIconFn = () => 
    <Icon
      data-qa="new-tags-btn"
      icon={rightIcon}
      size={rightIconSize}
      color={rightIconColor}
      shade={rightIconShade}
      className="absolute right-[10px] cursor-pointer"
      onClick={(e) => rightIconClick(e)}
    />

  const customNoOptionsMessage = () => {
    if (!inputValue) {
      return null;
    } else {
      return `No options for "${inputValue}"`;
    }
  };

  return (
    <div className={parentClassName}>
      {(label || tooltip || labelIcon) && (
        <div className="flex min-h-[28px] items-center justify-between !pb-1">
          <div className="flex items-center gap-x-4">
            {label && (
              <label
                className="m-0 flex !gap-1 !p-0 text-sm !font-500 text-neutral-800"
                htmlFor={id}>
                {label}
                {required && <span className="text-danger-500">*</span>}
              </label>
            )}
            {tooltip && (
              <Tippy content={<span>{tooltip}</span>} arrow>
                <div className="flex">
                  <Icon icon="info" className="cursor-pointer" />
                </div>
              </Tippy>
            )}
          </div>
          <div className="ml-auto flex items-center gap-x-1">{labelIcon}</div>
        </div>
      )}
      {isCreatable && !isAsync ? (
        <div className="relative flex items-center">
          <CreatableSelect
            className={cs('select', className)}
            classNamePrefix="select"
            id={id || name || label ? `select-${id || name || label}` : 'selectId'}
            placeholder={placeholder}
            value={value}
            options={options}
            isLoading={isLoading}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={disabled}
            styles={customStyle({
              width,
              minWidth,
              height,
              disabled,
              error,
              icon,
              isMulti,
              rightIcon,
              styles
            })}
            inputId={id}
            components={
              isImage
                ? {
                    Option: formatIsImage,
                    IndicatorSeparator: () => null,
                    ...(formatMultiValueContainer && {
                      MultiValueContainer: formatMultiValueContainer
                    })
                  }
                : {
                    IndicatorSeparator: () => null,
                    ...(formatMultiValueContainer && {
                      MultiValueContainer: formatMultiValueContainer
                    })
                  }
            }
            formatCreateLabel={formatCreateLabel}
            formatOptionLabel={formatOptionLabel}
            onCreateOption={onCreateOption}
            menuPortalTarget={menuPortalTarget}
            menuPlacement="auto"
            ref={forwardedRef}
            noOptionsMessage={customNoOptionsMessage}
            {...rest}
          />
          {!!rightIcon && rightIconFn()}
        </div>
      ) : isAsync && !isCreatable ? (
        <div className="relative flex items-center">
          {icon && (
            <Icon
              icon={icon}
              size="20px"
              htmlFor={id}
              className="absolute left-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer"
            />
          )}
          <AsyncSelect
            className={cs('select relative', className)}
            classNamePrefix="select"
            id={id || name || label ? `select-${id || name || label}` : 'selectId'}
            placeholder={placeholder}
            value={value}
            inputValue={inputValue}
            defaultOptions={defaultOptions}
            options={options}
            loadOptions={loadOptions}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={disabled}
            isLoading={isLoading}
            onInputChange={onInputChange}
            styles={customStyle({
              width,
              minWidth,
              height,
              disabled,
              error,
              icon,
              isMulti,
              rightIcon,
              styles
            })}
            inputId={id}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => <span className="w-2 opacity-0">none</span>
            }}
            formatOptionLabel={formatOptionLabel}
            menuPortalTarget={menuPortalTarget}
            menuPlacement="auto"
            ref={forwardedRef}
            noOptionsMessage={customNoOptionsMessage}
            {...rest}
          />
          {!!rightIcon && rightIconFn()}
        </div>
      ) : isAsync && isCreatable ? (
        <div className="relative flex items-center">
          {icon && (
            <Icon
              icon={icon}
              size="20px"
              htmlFor={id}
              className="absolute left-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer"
            />
          )}
          <AsyncCreatableSelect
            className={cs('select relative', className)}
            classNamePrefix="select"
            id={id || name || label ? `select-${id || name || label}` : 'selectId'}
            placeholder={placeholder}
            value={value}
            inputValue={inputValue}
            defaultOptions={defaultOptions}
            options={options}
            loadOptions={loadOptions}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={disabled}
            isLoading={isLoading}
            onInputChange={onInputChange}
            styles={customStyle({
              width,
              minWidth,
              height,
              disabled,
              error,
              icon,
              isMulti,
              rightIcon,
              styles
            })}
            inputId={id}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => <span className="w-2 opacity-0">none</span>,
              ...(formatMultiValueContainer && {
                MultiValueContainer: formatMultiValueContainer
              })
            }}
            formatCreateLabel={formatCreateLabel}
            formatOptionLabel={formatOptionLabel}
            menuPortalTarget={menuPortalTarget}
            menuPlacement="auto"
            ref={forwardedRef}
            noOptionsMessage={customNoOptionsMessage}
            {...rest}
          />
          {!!rightIcon && rightIconFn()}
        </div>
      ) : (
        <div className="relative flex items-center">
          {icon && (
            <Icon
              icon={icon}
              size="20px"
              htmlFor={id}
              className="absolute left-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer"
            />
          )}
          <ReactSelect
            className={cs('select', className)}
            classNamePrefix="select"
            id={id || name || label ? `select-${id || name || label}` : 'selectId'}
            placeholder={placeholder}
            value={value}
            options={options}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={disabled}
            styles={customStyle({
              width,
              minWidth,
              height,
              icon,
              disabled,
              error,
              isMulti,
              rightIcon,
              styles
            })}
            inputId={id}
            components={{
              IndicatorSeparator: () => null,
              ...(noDropdown && {
                DropdownIndicator: () => <span className="w-2 opacity-0">none</span>
              })
            }}
            formatOptionLabel={isImage ? formatIsImage : formatOptionLabel}
            menuPortalTarget={menuPortalTarget}
            menuPlacement="auto"
            ref={forwardedRef}
            filterOption={filterOption}
            {...rest}
          />
          {!!rightIcon && rightIconFn()}
        </div>
      )}
      {error && <p className={cs('!pt-2 text-sm text-danger-500')}>{error}</p>}
    </div>
  );
};

export default Select;
