import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { showAlert } from 'components/shared/Alert/Alert';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { io, mString } from '../../../../lib/helpers/utility';
import { withErrorBoundary } from '../../../shared/Error/Boundary';
import Icon from '../../../shared/Icon/Icon';
import Loading from '../../../shared/Loading/Loading';
import PreAuthFormModal from '../../../shared/Modal/PreAuthFormModal/PreAuthFormModal';
import state from '../../../state';
import { propertyPathMap } from '../constants';
import makePayment from './icons/make_payment.svg';
import Invoices from './invoices';
import { useQuery } from '@tanstack/react-query';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const Payment = () => {
  const [appointment, setAppointment] = useState();

  const [show, setShow] = useState({
    loading: false,
    payment_result: 0,
    completed: false,
    paymentSelected: ''
  });
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const userState = useRecoilValue(state.userState);
  const [showPreAuthModal, setShowPreAuthModal] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const {
    updateSteps,
    checkinInsuranceEnabled,
    appointmentId,
    checkinButton,
    setContinueText,
    defaultContinueText,
    setContinueColor,
    defaultContinueColor
  } = useOutletContext();

  const { device } = useUIContext();

  const { data } =
    useQuery({
      queryFn: getAppointment,
      queryKey: ['get_balance', appointmentId],
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getAppointment() {
    const response = await requestApi({
      navigate,
      url: '/api/patient/checkin/payment/get_balance'
    });

    return response;
  }

  useEffect(() => {
    const appointmentData = data?.appointment;

    if (io(appointmentData)) {
      setAppointment(appointmentData);
      setBalance(appointmentData?.balance);

      if (appointmentData?.balance === 0) setShow({ ...show, paymentSelected: 'cof' });
    }
  }, [data]);

  useEffect(() => {
    // showAlert({ message: `Pay ${mString(balance)}`, color: 'success' });
    setContinueText(balance > 0 ? `Pay ${mString(balance)}` : 'Add Card-On-File');
    if (balance > 0) {
      setContinueColor('success');
    }
    return () => {
      defaultContinueText();
      defaultContinueColor();
    };
  }, [balance]);

  const hidePreAuthModal = () => {
    setShowPreAuthModal(false);
  };

  const noPaymentAvailable = async () => {
    try {
      const res = await interimApi(
        '/api/appointment/no_payment_selected',
        {
          id: appointment.id
        },
        navigate
      );
      const { code, redirect } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          updateSteps({ formName: propertyPathMap.payment.name(), isCompleted: true });
          return true;

        default:
          Honeybadger.notify(`requestNoPaymentSelectedAppointment`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`requestNoPaymentSelectedAppointment: ${error}`);
    }
  };

  const onContinue = () => {
    switch (show.paymentSelected) {
      case 'noPaymentAvailable':
        noPaymentAvailable();
        break;
      case 'cof':
        setShowPreAuthModal(true);
        break;
      case 'pay':
        changePath({ target: { name: '/checkin/pay' } });
        break;
      default:
        changePath({ target: { name: '/checkin/pay' } });
    }
  };

  const changePath = (e) => {
    navigate(e.target.name);
  };

  if (!appointment) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full grow flex-col justify-between">
        <div className="">
          <div className="flex flex-wrap items-center !py-1 px-3 shadow-[0px_0px_10px_0px_#004F6B14]">
            <div className="flex sm:w-1/2">
              {/* TODO: Enable once we implement the info graphic */}
              <div className="flex gap-6 rounded-full border !border-[#B0EAFF] bg-[#F5FCFF] !px-[22px] !py-[8px] opacity-0 sm:gap-3 md:gap-4">
                <p className="text-primary-800">Explanation</p>
                <Icon icon="arrow-square-down" />
              </div>
            </div>

            <div className="flex flex-1 items-center justify-center !py-3 sm:order-2">
              <p className="text-base font-medium text-primary-900">
                Estimated balance due for your visit:{' '}
                <span className="text-primary-600">{mString(balance)}</span>
              </p>
            </div>
            <div className="flex justify-end sm:w-1/2">
              <div
                onClick={() => setInvoiceDetails(!invoiceDetails)}
                className={cs(
                  'flex cursor-pointer gap-2 rounded-full border !border-[#B0EAFF] bg-[#F5FCFF] !px-[22px] !py-[8px] sm:gap-3 md:gap-4',
                  invoiceDetails && '!bg-primary-900 !text-white'
                )}>
                <Icon
                  color={invoiceDetails && 'white'}
                  icon={invoiceDetails ? 'arrow-square-up' : 'arrow-square-down'}
                />
                <p className={cs('text-sm', invoiceDetails ? 'text-white' : 'text-primary-800')}>
                  Invoice Details
                </p>
              </div>
            </div>
          </div>
          {invoiceDetails ? (
            <Invoices appointment={appointment} />
          ) : (
            <div className="!px-4 !pt-2">
              <div className="!py-5 !pb-2 md:py-4">
                {balance > 0 ? (
                  <p className="flex w-full justify-center text-center text-sm italic text-primary-600">
                    Payment arrangements are requested prior to receiving treatment.
                  </p>
                ) : (
                  <p className="flex w-full justify-center text-center text-sm italic text-primary-600">
                    You have no balance for this visit. We recommend leaving a PCI/HIPAA-secure card
                    on file for ease of payment in the future.
                  </p>
                )}
                <div className="flex flex-col pt-4">
                  {balance > 0 ? (
                    <div className="!my-2 flex w-full justify-center">
                      <div
                        onClick={() => {
                          setShow((ps) => ({ ...ps, paymentSelected: 'pay' }));
                          onContinue();
                        }}
                        className="flex cursor-pointer flex-col items-center gap-x-6 !rounded-lg border !border-primary-100 bg-[#F5FCFF] !px-24 !py-5 shadow-[0px_0px_10px_0px_#004F6B14] hover:bg-primary-50">
                        <p className="text-neutral-600">Suggested action:</p>
                        <img src={makePayment} alt="Make Payment"></img>
                        <p className="text-base font-500 text-primary-900">Pay in full now</p>
                        <p className="text-neutral-600">By debit or credit card</p>
                      </div>
                    </div>
                  ) : (
                    <div className="!my-2 flex w-full justify-center">
                      <div
                        onClick={() => setShowPreAuthModal(true)}
                        className="flex cursor-pointer flex-col items-center gap-x-6 !rounded-lg border !border-primary-100 bg-[#F5FCFF] !px-24 !py-5 shadow-[0px_0px_10px_0px_#004F6B14] hover:bg-primary-50">
                        <p className="text-neutral-600">Suggested action:</p>
                        <img src={makePayment} alt="Make Payment"></img>
                        <p className="text-base font-500 text-primary-900">Add Card-on-File</p>
                        <p className="text-neutral-600">To guarantee any outstanding balance</p>
                      </div>
                    </div>
                  )}
                  <p className="mb-1 w-full text-center text-base text-neutral-600">or</p>
                  <div
                    className={`flex ${
                      balance > 0 ? 'justify-between' : 'justify-center'
                    } my-[0.5rem] sm:flex-col xs:flex-col`}>
                    {balance > 0 && (
                      <div
                        className="flex w-1/2 cursor-pointer gap-x-6 !rounded-lg border !border-white !px-6 py-3 shadow-[0px_0px_10px_0px_#004F6B14] hover:!border-primary-100 hover:bg-primary-50 sm:w-full"
                        onClick={() => setShowPreAuthModal(true)}>
                        <Icon icon="new-guarantee-lg" />
                        <div className="grid" data-qa="guarantee">
                          <p className="text-base font-500 text-primary-900">Add Card-On-File</p>
                          <p className="text-neutral-600">To guarantee any outstanding balance</p>
                        </div>
                      </div>
                    )}

                    <div
                      className={cs(
                        balance > 0 ? '' : 'justify-center',
                        'flex w-1/2 cursor-pointer gap-x-6 !rounded-lg !px-6 py-3 hover:!border-primary-100 hover:bg-primary-50 sm:w-full'
                      )}
                      onClick={noPaymentAvailable}>
                      <Icon icon="new-forbidden-lg" />
                      <div className="grid" data-qa="none-of-above">
                        <p className="text-base font-500 text-primary-900">
                          {balance > 0 ? 'Defer Payment' : 'Skip Card-On-File'}
                        </p>
                        <p className="text-neutral-600">
                          {balance > 0
                            ? 'Request to see office manager'
                            : "I'll pay in full at the time of service"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!invoiceDetails && (
          <div>
            <p className=" mx-2 pb-1 text-center text-neutral-600 md:w-full">
              Your estimated balance is in accordance with the No Surprises Act. Estimates are based
              on proposed services, and may change if services are altered.
            </p>
            <p className=" mx-2 pb-1 text-center text-neutral-600 md:w-full">
              Our office is fully accredited under the Visa & Mastercard Healthcare Program. This
              allows for encrypted card and signature on-file for prior authorizations with full PCI
              & HIPAA protection.
            </p>
          </div>
        )}
      </div>

      <PreAuthFormModal
        showPreAuthModal={showPreAuthModal}
        hidePreAuthModal={hidePreAuthModal}
        appointment={appointment}
        practiceId={appointment?.practice?.id}
        practiceName={appointment?.practice?.name}
        patientId={appointment?.patient?.id}
        patientName={`${appointment?.patient?.f_name} ${appointment?.patient?.l_name}`}
        billingAddress={appointment?.patient?.userAddress}
        type={!appointment?.insurance_benefits ? 'annual' : 'insurance_balance'}
        subModal={false}
        onSuccess={async () => {
          setShow({ ...show, loading: true });
          showAlert({
            message: 'Pre-Authorized Healthcare Form Saved Successfully!',
            position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
          });
          await interimApi('/api/patient/checkin/complete', { id: appointment.id }, navigate);
          updateSteps({ formName: propertyPathMap.payment.name(), isCompleted: true });
        }}
        showOverrides={{
          cash: false,
          check: false,
          cardOnFile: false,
          careCredit: false,
          cardPresent: false,
          online: true,
          noFooter: false
        }}
      />
      <button hidden onClick={onContinue} ref={checkinButton} />
    </>
  );
};

export default withErrorBoundary(Payment);
