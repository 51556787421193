import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { Capitalize, ia, io, sortObjectByProperty } from '../../../../../../../lib/helpers/utility';
import { filterFalsyValues } from '../../../lib/filterFalsyValues';
import { generateProgressList } from '../../../lib/generateProgressList';

import NotFound from 'components/practice/Dashboard/components/NotFound';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import AdvancedForm from '../AdvancedForm/AdvancedForm';
import AdvancedSoap from '../SOAPNote/components/AdvancedSoap';

const CustomFormNote = ({ context }) => {
  const { hpNoteOverviewRefs } = context;

  const {
    components,
    setComponents,
    setOverviewData,
    cnDisplaySettings,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton,
    customFormTypes,
    loading
  } = useClinicalNoteContext();
  const { id, appointmentId, formName, type } = useParams();

  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  };

  const [formsList, setFormList] = useState({});

  const customFormType = customFormTypes?.[type];

  const firstActiveComponent = Object.keys(components || {})?.[0];

  const currAdvForm = customFormType?.find((item) => {
    const title = item?.custom_title;
    if ((!formName && title === firstActiveComponent) || formName === title) return item;
  });

  const currentCustomFormSection = cnDisplaySettings?.sections?.[type];

  useEffect(() => {
    const advancedForms = customFormType?.map((item) => {
      if (currentCustomFormSection?.[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    });

    const filteredAdvancedCustomForms = advancedForms.filter((value) => io(value));

    const advancedTitles =
      ia(filteredAdvancedCustomForms) &&
      Object.fromEntries(
        filteredAdvancedCustomForms?.map((item) => {
          const title = item?.custom_title;
          return [
            title,
            {
              title: item?.form?.name || item?.name,
              path: title,
              position: currentCustomFormSection?.[item.custom_title]?.position
            }
          ];
        })
      );

    const assignAdvTitlesToComponent = Object.keys(advancedTitles).reduce((acc, key) => {
      acc[key] = () => <AdvancedForm currAdvForm={currAdvForm} displaySectionTitle="progress" />;
      return acc;
    }, {});

    setFormList((prevState) => ({ ...prevState, ...assignAdvTitlesToComponent }));

    const sortedComponents = sortObjectByProperty(advancedTitles, 'position');

    setComponents(sortedComponents);
  }, [cnDisplaySettings?.sections, customFormType, formName, currAdvForm]);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  const propsForNotFoundNote = {
    icon: 'new-notev1',
    iconSize: 128,
    title: 'Note not found',
    titleClassName: '!mt-3',
    description: (
      <div>
        The form{' '}
        {formName && (
          <em className="text-md font-600 text-primary-900">"{Capitalize(formName)}"</em>
        )}{' '}
        you are looking for is not found.
      </div>
    )
  };

  if (loading) return <Skeleton height="78vh" />;

  if (!io(components) || !CurrentForm) return <NotFound {...propsForNotFoundNote} />;

  return (
    <div className="flex h-full flex-col gap-y-3">
      <CurrentForm
        key={formName}
        components={components}
        formId={currentHpOverviewData?.[formName]?.id}
        defaultValue={currentHpOverviewData?.[formName]?.response}
        hpNoteOverviewRef={hpNoteOverviewRefs?.[formName]}
        customFormsParams={customFormsParams}
        setCurrentFormData={(newData) =>
          setCurrentHpOverviewData((prevState) => ({
            ...prevState,
            [formName]: { ...prevState[formName], response: newData }
          }))
        }
      />
    </div>
  );
};

export default CustomFormNote;
