import React, { useEffect, useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment';
import { showAlert } from 'components/shared/Alert/Alert';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ia } from '../../../lib/helpers/utility';
import InsuranceProfiles from '../../practice/charts/InsuranceProfile/InsuranceProfiles';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import state from './state';
import './style.scss';
import { requestApi } from '../../../api/Api';
import CheckinLoginWarning from './LoginWarning';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const Insurance = () => {
  const {
    updateSteps,
    checkinButton,
    appointmentId,
    fakeModal,
    handleExternalSubmit,
    isInstantPacket
  } = useOutletContext();
  const [noInsurance, setNoInsurance] = useState(true);
  const [selected, setSelected] = useState(null);
  const [insuranceProfiles, setInsuranceProfiles] = useState([]);
  const selections = useRecoilValue(state.selectionState);
  const [practiceId, setPracticeId] = useState(null);
  const user = useRecoilValue(state.userState);
  const [show, setShow] = useState({ optionSelected: false, modified: false });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { device } = useUIContext();

  useEffect(() => {
    if (appointmentId) {
      if (selections?.insurance !== null) {
        if (!user.authenticated && !user.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
          // navigate('/login');
        }
        setShow((ps) => ({ ...ps, optionSelected: true }));
      }
      getPatientInsuranceProfiles();
    } else {
      setShow((ps) => ({ ...ps, optionSelected: true }));
    }
  }, [appointmentId, selections]);

  const getCheckinStatus = async () => {
    if (isInstantPacket) return;
    if (selected?.id) {
      let res = await requestApi({
        url: 'api/patient/insurance/select',
        params: {
          insuranceProfileId: selected.id
        },
        navigate
      });
      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          // updateSteps({
          //   version: 2,
          //   // formId: 1,
          //   newStatus: 'completed'
          //   // formName: propertyPathMap.insuranceIdComplete.name(),
          //   // isCompleted: true
          // });
          break;
        default:
          Honeybadger.notify(
            `file: checkin/insurance, method: getCheckinStatus - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error)
            showAlert({
              message: error,
              color: 'danger',
              position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
            });
          break;
      }
    } else {
      await requestApi({
        url: 'api/patient/insurance/no_insurance',
        params: {
          appointmentId
        },
        navigate
      });
    }
    updateSteps({
      version: 2,
      // formId: 1,
      newStatus: 'completed'
      // formName: propertyPathMap.insuranceIdComplete.name(),
      // isCompleted: true
    });
    // updateSteps({ formName: propertyPathMap.insuranceIdComplete.name(), isCompleted: true });
    return true;
  };

  const getPatientInsuranceProfiles = async () => {
    try {
      const res = await requestApi({ url: '/api/insurance_profiles/get', navigate });
      const { insuranceProfile } = res;
      setInsuranceProfiles(insuranceProfile);
      if (ia(insuranceProfile)) {
        setSelected(insuranceProfile[0]);
      } else {
        setNoInsurance(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const selectInsurance = (value) => {
    setNoInsurance(false);
    setSelected(value);
  };

  // if (!show.optionSelected) {
  //   // if (!show.optionSelected || (!user.authenticated && !user.isOnboarding)) {
  //   return <PatientCheckinEditingSelector />;
  // }

  return (
    <div className={cs('h-full bg-neutral-50 ', fakeModal ? 'p-0' : '!px-4 !py-[6px]')}>
      {!user?.authenticated && !user?.isOnboarding && (
        <CheckinLoginWarning
          data="insurance profiles"
          note="Please note that previously selected insurance profile will be selected if no insurance is chosen."
        />
      )}
      <InsuranceProfiles
        insuranceProfiles={insuranceProfiles}
        selected={selected}
        setSelected={selectInsurance}
        practiceId={practiceId}
        noInsurancePress={() => {
          setNoInsurance(true);
          setSelected(null);
          handleExternalSubmit();
        }}
      />
      <div hidden ref={checkinButton} onClick={() => getCheckinStatus()}></div>
    </div>
  );
};

export default withErrorBoundary(Insurance);
