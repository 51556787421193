import React from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import Tippy from '@tippyjs/react';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../../shared/Icon/Icon';
import { isEmpty } from '../../../../../../lib/helpers/utility';

const SignPad = ({ clearSignature, sigPad, signature, updateSignature, setUpdateSignature }) => {
  return (
    <div className="!mt-3">
      <div className="!mb-1 flex justify-between">
        <p className="self-center text-sm font-500 text-neutral-800">Your Signature</p>
        <Tippy placement="bottom" content="Clear" className="tippy-black">
          <div className="flex">
            <Icon
              data-qa="reset-signature"
              icon="new-reset-neutral"
              className="flex h-8 w-8 justify-center rounded-md transition-all hover:bg-neutral-100"
              onClick={clearSignature}
            />
          </div>
        </Tippy>
      </div>

      <div>
        {isEmpty(signature) || !signature?.includes('base64') ? (
          <ReactSignatureCanvas
            penColor="black"
            canvasProps={{
              height: '200px',
              width: '450px',
              className: 'SignatureCanvas'
            }}
            ref={sigPad}
          />
        ) : (
          <img
            src={signature}
            className="h-[200px] w-full rounded-[10px] border-2 border-dashed border-neutral-100 bg-[#f8fdff] object-contain"
            data-qa="user-signature"
          />
        )}

        <div className="!mt-1 flex gap-3">
          {!signature && (
            <Checkbox
              id="update-signature-on-file"
              label="Update Signature On File"
              color="blue"
              isChecked={updateSignature}
              onChange={(v) => setUpdateSignature(v.target.checked)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignPad;
