import { Honeybadger } from '@honeybadger-io/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { growthOptionChart, growthOptionGender, growthOptionMeasurement } from '/constants';
import { io, isEmpty, snakeToTitleCase } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Select from 'components/shared/Select/Select';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';

const NewAndUpdateGrowth = ({
  mode,
  formik,
  loading,
  hideModal,
  showModal,
  setLoading,
  setGrowthData
}) => {
  const navigate = useNavigate();
  const completeFormValidation = () => {
    Object.keys(formik.values).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, true);
    });

    if (io(formik?.errors)) return false;

    if (!formik.values || Object.values(formik.values).every((value) => isEmpty(value)))
      return false;

    return true;
  };

  const handleCreate = async () => {
    if (!completeFormValidation()) return;

    setLoading({ ...loading, create: true });

    try {
      const onSuccess = ({ growthData }) => {
        hideModal();
        formik.resetForm();
        showAlert({ title: 'New Growth data added.', color: 'primary' });
        setGrowthData((prev) => [growthData, ...prev]);
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
      };

      await requestApi({
        url: `/api/admin/growth_data/create`,
        params: {
          fields: formik.values
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(`handleCreate: ${error}`);
      console.log(error);
      Honeybadger.notify(
        `file: /databank/GrowthData/newAndUpdate, method: handleUpdate - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }

    setLoading({ ...loading, create: false });
  };

  const handleUpdate = async () => {
    if (!completeFormValidation()) return;

    setLoading({ ...loading, update: true });

    try {
      const onSuccess = ({ growthData }) => {
        setGrowthData((prev) => {
          const index = prev.findIndex((g) => g.id === growthData.id);
          prev[index] = growthData;
          return prev;
        });

        hideModal();
        formik.resetForm();
        showAlert({ title: 'Growth data updated.', color: 'primary' });
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
      };
      await requestApi({
        url: `/api/admin/growth_data/update`,
        params: {
          fields: formik.values
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.log(error);
      Honeybadger.notify(
        `file: /databank/GrowthData/newAndUpdate, method: handleUpdate - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
      Honeybadger.notify(`handleUpdate newAndUpdateCpt: ${error}`);
    }

    setLoading({ ...loading, update: false });
  };

  const findMeasurementValueUnit = () =>
    growthOptionMeasurement.find((o) => o.value === formik?.values?.measurement_type)?.unit || '';

  const findChartTypeUnit = () => {
    switch (formik?.values?.chart_type) {
      case 'weight_for_age':
      case 'weight_for_height':
      case 'weight_for_length':
        return 'kg';
      case 'head_circumference_for_age':
      case 'length_for_age':
        return 'cm';
    }
  };

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={`${mode?.update ? 'Update' : 'New'} Growth Data`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={hideModal} />

          {mode?.update ? (
            <Button text="Update" loading={loading?.update} onClick={handleUpdate} />
          ) : (
            <Button text="Create" loading={loading?.create} onClick={handleCreate} />
          )}
        </div>
      }>
      <div className="grid gap-y-4">
        <Select
          inputId="measurement_type"
          options={growthOptionMeasurement}
          label={'Measurement Type'}
          placeholder="Type and select measurement"
          value={{ label: snakeToTitleCase(formik.values?.measurement_type) }}
          onChange={({ value }) => formik.setFieldValue('measurement_type', value)}
          error={formik.touched.measurement_type && formik.errors.measurement_type}
        />
        <Input
          id="measurement_value"
          name="measurement_value"
          label="Measurement Value"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findMeasurementValueUnit()}</span>
          }
          value={formik?.values?.measurement_value}
          error={formik.touched.measurement_value && formik?.errors?.measurement_value}
          onChange={formik && formik?.handleChange}
        />
        <Select
          inputId="chart_type"
          options={growthOptionChart}
          label={'Chart Type'}
          placeholder="Type and select chart type"
          value={{ label: snakeToTitleCase(formik?.values?.chart_type) }}
          onChange={({ value }) => formik.setFieldValue('chart_type', value)}
          error={formik.touched.chart_type && formik.errors.chart_type}
        />

        <Select
          inputId="gender"
          options={growthOptionGender}
          label={'Gender'}
          placeholder="Type and select a gender"
          value={{ label: snakeToTitleCase(formik?.values?.gender) }}
          onChange={({ value }) => formik.setFieldValue('gender', value)}
          error={formik.touched.gender && formik.errors.gender}
        />

        <Input
          id="l"
          name="l"
          label="L"
          type="number"
          value={formik?.values?.l}
          error={formik?.errors?.l}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="m"
          name="m"
          label="M"
          type="number"
          value={formik?.values?.m}
          error={formik?.errors?.m}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="s"
          name="s"
          label="S"
          type="number"
          value={formik?.values?.s}
          error={formik?.errors?.s}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="sd"
          name="sd"
          label="SD"
          type="number"
          value={formik?.values?.sd}
          error={formik?.errors?.sd}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p01"
          name="p01"
          label="P01"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p01}
          error={formik?.errors?.p01}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p1"
          name="p1"
          label="P1"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p1}
          error={formik?.errors?.p1}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p3"
          name="p3"
          label="P3"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p3}
          error={formik?.errors?.p3}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p5"
          name="p5"
          label="P5"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p5}
          error={formik?.errors?.p5}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p10"
          name="p10"
          label="P10"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p10}
          error={formik?.errors?.p10}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p15"
          name="p15"
          label="P15"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p15}
          error={formik?.errors?.p15}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p25"
          name="p25"
          label="P25"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p25}
          error={formik?.errors?.p25}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p50"
          name="p50"
          label="P50"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p50}
          error={formik?.errors?.p50}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p75"
          name="p75"
          label="P75"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p75}
          error={formik?.errors?.p75}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p85"
          name="p85"
          label="P85"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p85}
          error={formik?.errors?.p85}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p90"
          name="p90"
          label="P90"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p90}
          error={formik?.errors?.p90}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p95"
          name="p95"
          label="P95"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p95}
          error={formik?.errors?.p95}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p97"
          name="p97"
          label="P97"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p97}
          error={formik?.errors?.p97}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p99"
          name="p99"
          label="P99"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p99}
          error={formik?.errors?.p99}
          onChange={formik && formik?.handleChange}
        />

        <Input
          id="p999"
          name="p999"
          label="P999"
          type="number"
          rightText={
            <span className="text-sm font-500 text-primary-500">{findChartTypeUnit()}</span>
          }
          value={formik?.values?.p999}
          error={formik?.errors?.p999}
          onChange={formik && formik?.handleChange}
        />
      </div>
    </Modal>
  );
};

export default NewAndUpdateGrowth;
