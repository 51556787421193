import { importPatients } from 'api/Admin/PatientImports';
import correctInsuranceRelation from './correctInsuranceRelation';
import { showAlert } from 'components/shared/Alert/Alert';

export default async function ({
  show,
  setShow,
  headerValue,
  file,
  id,
  navigate,
  patientDetailsFormat
}) {
  let allPatientsData = [...file.data];

  let newShow = {};
  setShow((prevState) => ({ ...prevState, loading: true }));
  try {
    let patientDataConfig = {};
    headerValue.forEach((v, i) => {
      if (v) {
        patientDataConfig[v.value] = i;
      }
    });

    if (patientDataConfig.dob >= 0) {
      patientDataConfig['dobFormat'] = patientDetailsFormat?.dobFormat?.value;
      if (!patientDetailsFormat?.dobFormat?.value) {
        throw 2;
      }
    }

    if (patientDataConfig.name >= 0) {
      patientDataConfig['nameFormat'] = patientDetailsFormat?.patientName?.value;
      if (!patientDetailsFormat?.patientName?.value) {
        throw 3;
      }
    }

    if (patientDataConfig.insurance_policy_start_date >= 0) {
      patientDataConfig['policyStartDateFormat'] =
        patientDetailsFormat?.policyStartDateFormat?.value;
      if (!patientDetailsFormat?.policyStartDateFormat?.value) {
        throw 4;
      }
    }

    if (patientDataConfig.insurance_policy_end_date >= 0) {
      patientDataConfig['policyEndDateFormat'] = patientDetailsFormat?.policyEndDateFormat?.value;
      if (!patientDetailsFormat?.policyEndDateFormat?.value) {
        throw 5;
      }
    }

    // calling function to correct the insurance relation
    if (patientDataConfig.insurance_relation >= 0) {
      allPatientsData = await correctInsuranceRelation(
        patientDataConfig.insurance_relation,
        file.data
      );

      allPatientsData?.forEach((patient) => {
        if (
          patient[patientDataConfig.insurance_relation] &&
          patient[patientDataConfig.insurance_relation] != 18
        ) {
          const otherRelationName = patient['insurance_other_relation_name'];
          const otherRelationDate = patient['insurance_other_relation_date'];
          const otherRelationPhone = patient['insurance_other_relation_phone'];
          const otherRelationAddress = patient['insurance_relation_address'];
          if (
            !otherRelationName ||
            !otherRelationDate ||
            !otherRelationPhone ||
            !otherRelationAddress
          ) {
            throw 6;
          }
        }
      });
    }

    if (patientDataConfig.appointment_provider >= 0) {
      patientDataConfig['providerNameFormat'] = patientDetailsFormat?.providerName?.value;
      if (!patientDetailsFormat?.providerName?.value) {
        throw 7;
      }
    }

    if (patientDataConfig.transaction_amount >= 0) {
      patientDataConfig['transactionAmountFormat'] =
        patientDetailsFormat?.transactionAmountFormat?.value;
      if (!patientDetailsFormat?.transactionAmountFormat?.value) {
        throw 8;
      }
    }

    if (patientDataConfig.transaction_amount_paid_self >= 0) {
      patientDataConfig['transactionAmountSelfFormat'] =
        patientDetailsFormat?.transactionAmountSelfFormat?.value;
      if (!patientDetailsFormat?.transactionAmountSelfFormat?.value) {
        throw 9;
      }
    }

    let payload = {
      practiceId: id,
      patientData: allPatientsData,
      patientDataConfig,
      fileName: file.fileName,
      generateEmails: show.generateEmails,
      defaultService: show.defaultService,
      defaultProvider: show.defaultProvider
    };

    const res = await importPatients(navigate, payload);
    const { code, redirect, error } = res;
    switch (code) {
      case -1:
        navigate(redirect);
        newShow['loading'] = false;
        break;
      case 0:
        newShow.stage = 'fileSuccess';
        newShow.loading = false;
        break;
      default:
        showAlert({
          color: 'danger',
          message: error || `An unexpected error has occurred. Please try again later.`
        });
        break;
    }
  } catch (error) {
    if (typeof error === 'number') {
      switch (error) {
        case 2:
          showAlert({
            color: 'danger',
            message: `DOB Format needs to be selected`
          });
          break;
        case 3:
          showAlert({
            color: 'danger',
            message: `Name Format needs to be selected`
          });
          break;
        case 4:
          showAlert({
            color: 'danger',
            message: `Policy Start Date Format needs to be selected`
          });
          break;
        case 5:
          showAlert({
            color: 'danger',
            message: `Policy End Date Format needs to be selected`
          });
          break;
        case 6:
          showAlert({
            color: 'danger',
            message: `Other Insurance Relation information is needed`
          });
          break;
        case 7:
          showAlert({
            color: 'danger',
            message: `Provider Name Format needs to be selected`
          });
          break;
        case 8:
          showAlert({
            color: 'danger',
            message: `Currency format needs to be selected`
          });
          break;
        case 9:
          showAlert({
            color: 'danger',
            message: `Currency format needs to be selected`
          });
          break;
        default:
          break;
      }
    } else {
      console.error(error);
      showAlert({
        color: 'danger',
        message: `There's been an unexpected error. Please try again later.`
      });
    }
    newShow.loading = false;
  }
  setShow((prevState) => ({ ...prevState, ...newShow, loading: false }));
}
