import { currentPractice } from 'components/practice/practiceState';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Modal from 'components/shared/Modal/Modal';
import { formatDateAndTimeZ, ia, iaRa } from 'lib/helpers/utility';
import { useICDOveview } from 'lib/hooks/queries/widgets/useOverview';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

export const ICDRow = ({ item }) => {
  const practice = useRecoilValue(currentPractice);

  return (
    <div className="flex !cursor-pointer flex-col gap-3 px-[20px] py-3 hover:bg-primary-25">
      <div className="flex flex-wrap items-center gap-2">
        {iaRa(item?.icd_10_codes).map((code, index) => (
          <p key={index} className="font-medium text-neutral-800">
            {item?.icd_10_codes.length > 1 && item?.icd_10_codes[++index]
              ? `${code?.code} - ${code?.name},`
              : `${code?.code} - ${code?.name}`}
          </p>
        ))}
      </div>
      <p className="mt-2 text-neutral-600">
        {formatDateAndTimeZ(item?.appointment?.starts_at, practice?.timezone)}{' '}
        <span className="px-1">•</span> {item?.practitioner?.fullName}
      </p>
    </div>
  );
};

export default function ICD10(props) {
  const { patientId } = props;
  const [show, setShow] = useState(false);
  const { data } = useICDOveview({ params: { patientId }, dependencies: [patientId] });

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader icon="icd-codes" hasPlus={false} iconColor="primary" {...props} />
      <div className="flex flex-1 flex-col overflow-y-auto">
        {ia(data?.icdCodes) ? (
          <FooterWrapper
            onClick={() => setShow(true)}
            text={` View all Problems (${data?.icdCodes?.length})`}>
            <List
              items={data?.icdCodes}
              renderItemComponent={(item) => <ICDRow item={item} />}></List>
          </FooterWrapper>
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No ICD10 Codes</p>
          </div>
        )}

        <Modal
          handleOpen={show}
          className="w-[1020px] bg-white"
          title="All Problems"
          slideFromRight
          handleClose={() => setShow(false)}>
          {iaRa(data?.icdCodes).map((item, index) => (
            <ICDRow key={index} item={item} />
          ))}
        </Modal>
      </div>
    </div>
  );
}
