import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../../../shared/Modal/Modal';
import SendFax from '../SendFax/SendFax';
import Button from '../../../../../shared/Buttons/Button';
import Icon from '../../../../../shared/Icon/Icon';
import { getSingleDraftFax } from '../../../../../../api/Fax';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
function createFileFromBuffer(buffer, fileName, mimeType) {
  const blob = new Blob([buffer], { type: mimeType });
  let file = new File([blob], fileName, { type: mimeType });
  file['id'] = file.size;
  return file;
}
const SendFaxModal = ({
  sendFaxModalVisible,
  setSendFaxModalVisible,
  faxData,
  isDraft = false
}) => {
  const [loading, setLoading] = useState({ draft: false, send: false, preview: false });
  const [faxDraftLoading, setFaxDraftLoading] = useState(isDraft);
  const [fax, setFax] = useState(faxData);
  const navigate = useNavigate();

  const getFax = async () => {
    const fax_data = await getSingleDraftFax(navigate, faxData.id);
    setFax({
      ...fax_data?.fax,
      files: fax_data?.fax?.draft_files?.map((file) => {
        return createFileFromBuffer(
          new Uint8Array(file?.buffer?.data),
          file?.originalname,
          file?.mimetype
        );
      })
    });
    setFaxDraftLoading(false);
  };
  useEffect(() => {
    if (isDraft) {
      getFax();
    }
  }, []);

  const sendFaxRef = useRef(null);
  return (
    <Modal
      handleOpen={!!sendFaxModalVisible}
      handleClose={() => setSendFaxModalVisible(false)}
      title={'Send a fax'}
      slideFromRight={true}
      customStyling={{ width: '924px' }}
      bodyClassName={'!p-0'}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setSendFaxModalVisible(false)}
            data-qa="cancel-select-files-btn"
          />
          <div className="flex gap-4 items-center">
            {!faxData && (
              <Button
                transparent
                type="primary"
                color="neutral"
                text="Save draft"
                onClick={() => {
                  sendFaxRef?.current?.submitForm('draft');
                }}
                size="small"
                className="!p-0"
                loadingIcon={loading.draft}
                disabled={loading.draft || loading.send || loading.preview}
              />
            )}
            <Icon
              icon="play-circle"
              disabled={loading.draft || loading.send || loading.preview}
              onClick={() => sendFaxRef?.current?.submitForm('preview')}
            />
            <Button
              data-qa="send-fax-btn"
              text="Send fax"
              onClick={() => {
                sendFaxRef?.current?.submitForm('send');
              }}
              loadingIcon={loading.send}
              disabled={loading.draft || loading.send || loading.preview}
            />
          </div>
        </div>
      }>
      {!faxDraftLoading && fax ? (
        <SendFax ref={sendFaxRef} fax={fax} setLoading={setLoading} />
      ) : (
        <Skeleton count={3} height={150} />
      )}
    </Modal>
  );
};
export default SendFaxModal;
// resendFax(navigate, fax.id).then(() =>
//   refetchFaxes().then(() => {
//     showAlert({ color: 'success', message: 'Fax deleted' });
//     close();
//   })
// );
