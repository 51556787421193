import React, { useState, useEffect } from 'react';
import useScrollBlock from '../../../../lib/hooks/useScrollBlock';
import NewPharmacy from './NewPharmacy';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import Button from '../../../shared/Buttons/Button';
import Pharmacy from './Pharmacy';
import { iaRa } from 'lib/helpers/utility';

export default function Pharmacies({
  pharmacies = [],
  patientId,
  selected,
  setSelected,
  practiceId
}) {
  const [pharmaciesList, setPharmaciesList] = useState(pharmacies);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);

  const handleNewPharmacy = () => {
    blockScroll();
    setShowPharmacyModal(true);
  };
  const hidePharmacyModal = () => {
    allowScroll();
    setShowPharmacyModal(false);
  };

  useEffect(() => {
    setPharmaciesList(pharmacies);
  }, [pharmacies]);

  const newPharmacy = (newProfile) => {
    setPharmaciesList((prev) => {
      const pharmacyIndex = prev.findIndex((row) => row.id === newProfile.id);

      if (pharmacyIndex === -1) {
        return [...prev, newProfile];
      }

      return prev.map((row, index) => (index === pharmacyIndex ? newProfile : row));
    });
  };

  const updatePharmacy = (updatedPharmacy) => {
    setPharmaciesList((prev) =>
      prev.map((pharmacy) => {
        if (pharmacy.id === updatedPharmacy.id) {
          return updatedPharmacy;
        }
        return pharmacy;
      })
    );
  };

  const handleDeletePharmacy = (pharmacyId) => {
    setPharmaciesList((prev) => prev.filter((pharmacy) => pharmacy.id !== pharmacyId));
  };

  if (!Array.isArray(pharmaciesList)) {
    return (
      <div className="mt-3 w-full">
        <Skeleton count={8} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        <label className="text-lg font-600">Pharmacies Information</label>
        {pharmaciesList?.length < 5 && (
          <Button
            size="small"
            type="secondary"
            text="New Pharmacy"
            onClick={handleNewPharmacy}
            data-qa="new-pharmacy-btn"
          />
        )}
      </div>
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <NewPharmacy {...{ patientId, showPharmacyModal, hidePharmacyModal, newPharmacy }} />
        {pharmaciesList?.map((pharmacy, index) => {
          return (
            <Pharmacy
              {...{
                pharmacy,
                index,
                selected,
                setSelected,
                updatePharmacy,
                deletePharmacy: handleDeletePharmacy,
                practiceId,
                newPharmacy,
                patientId
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
