import { requestApi } from 'api/Api';
import * as filestack from 'filestack-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './shared/Buttons/Button';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const BoundingBoxImage = ({ imageUrl, boundingBoxes }) => {
  return (
    <div className="relative">
      <img src={imageUrl} alt="Bounding Box Image" />
      {boundingBoxes?.map((box, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            border: '2px solid red',
            boxSizing: 'border-box',
            top: `${box[0].y}px`,
            left: `${box[0].x}px`,
            width: `${box[1].x - box[0].x}px`,
            height: `${box[2].y - box[0].y}px`
          }}
        />
      ))}
    </div>
  );
};

const getCasedString = (searchString, keyword) => {
  let regex = new RegExp(keyword, 'i');
  let match = searchString.match(regex);
  let matchedSubstring = match ? match[0] : null;
  return matchedSubstring;
};

const searchableString = (word) => {
  return word.toLowerCase().trim();
};

const findNearBox = ({ results, keywordFound, lines, x, y }, func) => {
  if (keywordFound && results[keywordFound] === undefined) {
    const nextBox = lines.find((nextLine) => {
      const box = nextLine.bounding_box;
      const boxy = [box[0].y, box[2].y];
      const boxx = [box[0].x, box[1].x];

      const middleY = (boxy[0] + boxy[1]) / 2;
      const thresholdX = [boxx[0] - 20, boxx[1] + 20];
      const middleX = (boxx[0] + boxx[1]) / 2;

      return func({ x, y, boxx, boxy, middleX, middleY, thresholdX });
    });
    results[keywordFound] = nextBox?.text;
  }
};

const DocumentDetection = () => {
  const [url, setUrl] = useState(null);
  const [document, setDocument] = useState(null);

  const navigate = useNavigate();

  const getData = (data) => {
    const keywords = [
      'members',
      'web',
      'phone',
      'providers',
      'medical claims',
      'pharmacists',
      'pharmacy claims',
      'medical plan',
      'member name',
      'part d/plan benefit',
      'member id',
      'member',
      'group number',
      'pcp',
      'spec',
      'er',
      'urgcare',
      'payer id',
      'coins',
      'inpthosp',
      'payerid',
      'rx bin',
      'rx pcn',
      'rx grp',
      'rxbin',
      'rxpcn',
      'rxgrp',
      'issuer',
      'plan',
      'inn',
      'oon'
    ];

    const docs = data.document.text_areas;
    const lines = docs.flatMap((doc) => doc.lines);

    const results = {};

    docs.map((doc) => {
      const text = doc.text;
      if (text.includes(':')) {
        const keywordsFound = keywords.filter((keyword) =>
          searchableString(text).includes(keyword + ':')
        );

        if (keywordsFound.length === 1) {
          const [_, value] = text.replace(': ', ':').split(':');
          if (value.length > 0) {
            results[keywordsFound[0]] = value;
          }
        }
      }
    });

    lines.map((line) => {
      let text = line.text;

      const boundingBox = line.bounding_box;
      const y = [boundingBox[0].y, boundingBox[2].y];
      const x = [boundingBox[0].x, boundingBox[1].x];

      const keywordFound = keywords.find(
        (keyword) =>
          (searchableString(text).includes(keyword + ':') ||
            searchableString(text) == keyword ||
            searchableString(text).indexOf(keyword + ' ') == 0) &&
          results[keyword] === undefined
      );

      if (keywordFound && searchableString(text).includes(keywordFound + ' ')) {
        const key = getCasedString(text, keywordFound);
        const place = text.indexOf(key);
        text = text.replace(key + ' ', '');
        if (text.length > 0 && place === 0) {
          results[keywordFound] = text;
        }
      }

      if (keywordFound && text.includes(':')) {
        text = text.replace(': ', ':');
        const [_, value] = text.split(':');
        if (value.length > 0 && results[keywordFound] === undefined) {
          results[keywordFound] = value;
        }
      }

      // horizontal search
      findNearBox(
        { results, keywordFound, lines, x, y },
        ({ x, y, boxx, middleY }) => x[1] < boxx[0] && y[0] < middleY && y[1] > middleY
      );

      // vertical start
      findNearBox(
        { results, keywordFound, lines, x, y },
        ({ x, y, boxy, thresholdX }) =>
          y[1] < boxy[0] && x[0] > thresholdX[0] && x[0] < thresholdX[1]
      );

      //vertical middle
      findNearBox(
        { results, keywordFound, lines, x, y },
        ({ x, y, boxy, middleX }) => y[1] < boxy[0] && x[0] < middleX && x[1] > middleX
      );

      return line;
    });
  };

  const openFilestackPicker = async () => {
    const options = {
      onUploadDone: async (res) => {
        const handle = res.filesUploaded[0].handle;
        const data = await requestApi({
          url: '/api/utilities/document_crop',
          navigate,
          params: { handle, detection: false, ocr: true, url: res.filesUploaded[0].url }
        });
        setUrl(data.url);
        setDocument(data.json?.document);
        getData(data.json);
      }
    };
    client.picker(options).open();
    const data = await requestApi({
      url: '/api/utilities/document_crop',
      navigate
      // params: { handle }
    });
    setUrl(data.url);
    // getData(require('./data.json'));
  };

  return (
    <div>
      <Button text="Upload" onClick={openFilestackPicker}></Button>
      <BoundingBoxImage
        imageUrl={url}
        boundingBoxes={document?.text_areas?.flatMap((d) => d.lines).map((l) => l.bounding_box)}
      />
    </div>
  );
};

export default DocumentDetection;
