import React from 'react';
import cs from 'classnames';

import { useRecoilValue } from 'recoil';
import { formatDate, mString } from 'lib/helpers/utility';
import { currentPractice } from 'components/practice/practiceState';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';

const DEFAULT_COL_DEFS = {
  enableRowGroup: true,
  resizable: true,
  filter: false,
  suppressMenu: true,
  suppressMovable: true
};

let GRID_OPTIONS = {
  domLayout: 'autoHeight',
  animateRows: true,
  rowGroupPanelShow: 'always'
};

const NewInvoiceDetailsTable = ({ invoices, isView = false }) => {
  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;
  const practiceTimezone = practice.timezone;

  const COLUMN_DEFS = [
    {
      field: 'details',
      minWidth: 170,
      cellClass: 'no-border'
    },
    {
      field: 'dateofservice',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, practiceTimezone, true),
      maxWidth: 130,
      cellClass: 'no-border'
    },
    { field: 'type', cellClass: 'no-border' },
    { field: 'qty', maxWidth: 80, minWidth: 70, cellClass: 'no-border' },
    {
      field: 'charge',
      valueFormatter: ({ value }) => mString(value || 0),
      cellClass: 'no-border'
    },
    {
      field: 'ins_payment',
      headerName: 'Ins Pay',
      valueGetter: (params) => {
        const insPayment = params?.data?.ins_payment;
        const finalInsPayment = mString(insPayment || 0);
        const isEstimated = params?.data?.isEstimated;

        if (isEstimated) {
          return `estimated ${finalInsPayment}`;
        }
        return finalInsPayment;
      },
      hide: !claims,
      cellClass: 'no-border'
    },
    {
      field: 'ins_adjustment',
      headerName: 'Ins Adj',
      valueGetter: (params) => {
        const insAdj = params?.data?.ins_adjustment;
        const finalInsAdj = mString(insAdj || 0);
        const isEstimated = params?.data?.isEstimated;

        if (isEstimated) {
          return `estimated ${finalInsAdj}`;
        }
        return finalInsAdj;
      },
      hide: !claims,
      cellClass: 'no-border'
    },
    {
      field: 'ptBalance',
      valueFormatter: ({ value }) => mString(value || 0),
      maxWidth: 120,
      cellClass: 'no-border'
    }
  ];

  return (
    <div className={cs('flex w-auto flex-col overflow-hidden !p-3 !pb-2', !isView ? 'h-full' : '')}>
      <AGBasicTable
        animateRows={true}
        defaultColDef={DEFAULT_COL_DEFS}
        columnDefs={COLUMN_DEFS}
        rowData={invoices}
        suppressRowClickSelection={true}
        customClassName="ag-grid-interactive"
        gridOptions={{ ...GRID_OPTIONS, rowGroupPanelShow: !isView }}
      />
    </div>
  );
};

export default NewInvoiceDetailsTable;
