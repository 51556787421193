import moment from 'moment';
import React, { useRef } from 'react';
import Button from '../../../../shared/Buttons/Button';
import Modal from '../../../../shared/Modal/Modal';
import Vitals from './Vitals';

const VitalsModal = ({
  showVitalsModal = false,
  hideVitalsModal = () => {},
  lastVitals = {},
  fromOverview = false,
  setLastVitals = () => {},
  isGrowthChartEnabled = false,
  setOverviewVitals = () => {}
}) => {
  const childComponentRef = useRef(null);

  const handleOnSave = () => {
    childComponentRef.current.saveVitals();
  };

  const handleOnReset = () => {
    childComponentRef.current.resetVitals();
  };

  const headButtonContent = (
    <div className="ml-auto flex items-center gap-x-4">
      {lastVitals?.created_at && (
        <p className="text-sm text-neutral-600">
          Vitals taken on {moment(lastVitals?.created_at).format('MM/DD/YYYY, hh:mm A')}
        </p>
      )}
      <Button
        onClick={handleOnReset}
        text="Reset vitals"
        icon="new-reset"
        outlined
        data-qa="reset-vitals"
      />
    </div>
  );

  return (
    <Modal
      isOpen={showVitalsModal}
      handleClose={hideVitalsModal}
      title="Vitals"
      portalClassName="VitalsModal"
      slideFromRight
      headButton={!fromOverview ? headButtonContent : null}
      footer={
        <div className="flex w-full items-center justify-between">
          <Button onClick={hideVitalsModal} outlined text="Close" data-qa="close-btn" />
          <Button onClick={handleOnSave} text="Save vitals" data-qa="save-vitals-btn" />
        </div>
      }>
      <Vitals
        ref={childComponentRef}
        lastVitals={lastVitals}
        hideVitalsModal={hideVitalsModal}
        fromOverview={fromOverview}
        setLastVitals={setLastVitals}
        isGrowthChartEnabled={isGrowthChartEnabled}
        setOverviewVitals={setOverviewVitals}
      />
    </Modal>
  );
};

export default VitalsModal;
