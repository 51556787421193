import React, { useEffect, useRef, useState } from 'react';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import Select from '../Select/Select';
import TagCreate from './Create';
import Icon from '../Icon/Icon';
import cs from 'classnames';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTag } from 'api/Tags';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../Alert/Alert';
import Confirm from '../Modal/Confirm/Confirm';
import { withErrorBoundary } from '../Error/Boundary';

const TagSelect = ({
  currTags,
  kind,
  setTagIds = () => { },
  label = 'Tags',
  className,
  ...rest
}) => {
  const [selectedTag, setSelectedTag] = useState(currTags || []);
  const { data, isLoading } = useTags({ params: { kind }, dependencies: kind });
  const tags = data?.tags;
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentTagId, setCurrentTagId] = useState(null);
  const [tagName, setTagName] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectRef = useRef(null);

  const openCreateModal = () => setIsCreateModal(true);
  const closeCreateModal = () => setIsCreateModal(false);
  const openConfirmModal = () => setIsConfirmModal(true);
  const closeIsConfirmModal = () => {
    setIsConfirmModal(false);
    setTagName('');
  }

  const { mutateAsync: mutateDeleteTag, isLoading: isDeleting } = useMutation({
    mutationFn: (data) => deleteTag(navigate, data)
  });

  const addNewTag = (newTag) => {
    setSelectedTag([...selectedTag, newTag]);
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleOnChange = (tag) => {
    setSelectedTag(tag);
    const tagIds = tag.map((tag) => tag.id);
    setTagIds(tagIds);
  };

  const formatOption = (props) => {
    const { label, color, value, id } = props;

    return (
      <div
        className="relative flex w-full items-center justify-between gap-[6px]"
        data-qa={`tag-option-${label}`}>
        <span
          className={cs(
            'h-[24px] cursor-default rounded-3xl py-[2px] leading-5 text-white',
            props.__isNew__ ? '!px-1' : 'px-[10px]'
          )}
          style={{ backgroundColor: color }}>
          {label}
        </span>
        {!props.__isNew__ && (
          <Icon
            icon="trash"
            onClick={(event) => handleRemoveOption(value, event, id)}
            data-qa={`tag-option-remove-${label}`}
          />
        )}
      </div>
    );
  };

  const formatMultiValueContainer = ({ ...props }) => {
    const { color, label, value, id } = props.data;

    const handleRemove = (event) => {
      event.stopPropagation();
      const removedTag = props.selectProps.value.find((tag) => tag.value === value);
      const updatedValue = props.selectProps.value.filter((tag) => tag.value !== value);
      props.selectProps.onChange(updatedValue, {
        action: 'remove-value',
        removedValue: removedTag
      });
    };

    return (
      <div
        className="flex h-[24px] cursor-default items-center gap-[6px] rounded-3xl px-[10px] py-[2px]"
        style={{ backgroundColor: color || '#13B9FF' }}>
        <span className="leading-5 text-white">{label}</span>
        <Icon
          icon="new-close-sm"
          size={8}
          color="white"
          stroke
          className="cursor-pointer"
          onClick={(e) => handleRemove(e)}
          data-qa={`tag-remove-${label}`}
        />
      </div>
    );
  };

  const formatCreateLabel = (inputValue) => {
    return (
      <div
        className="absolute -left-[12px] -top-2 flex h-[calc(100%+16px)] w-[calc(100%+24px)] cursor-pointer items-center gap-1 rounded-[4px] border-0 !border-t border-solid !border-danger-100 bg-primary-50 !pl-[10px]"
        onClick={openCreateModal}
        data-qa="tag-create-new">
        <span className="text-sm font-400 leading-[22px] text-neutral-900">Create a tag for</span>
        <span className="text-sm font-500 leading-[22px] text-neutral-900">"{inputValue}"</span>
      </div>
    );
  };

  const handleRemoveOption = (value, event, id) => {
    event.stopPropagation();
    openConfirmModal();
    if (id) setCurrentTagId(id);
  };

  const handleContinue = async () => {
    await mutateDeleteTag(currentTagId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tags']);
        showAlert({ title: 'Tag deleted successfully!', color: 'success' });
        closeIsConfirmModal();
      }
    });
  };

  const onCreateOption = (inputValue) => {
    const tagIds = tags.map(tag => tag.id);
    const largestId = Math.max(...tagIds);
    const newTagId = largestId + 1;
    const existingOption = tags.find(option => option.value === inputValue);
    if (!existingOption) {
      if (typeof inputValue === 'string') {
        setTagName(inputValue)
      }
      openCreateModal();
      setTagIds([...selectedTag.map(tag => tag.id), newTagId]);
    }
  };

  return (
    <>
      <Select
        label={label}
        parentClassName={cs('w-full', className)}
        forwardedRef={selectRef}
        isMulti
        isCreatable
        rightIcon="new-plus"
        rightIconColor='neutral'
        rightIconShade={400}
        rightIconSize={20}
        rightIconClick={onCreateOption}
        styles={{
          valueContainer: {
            gap: '2px 8px',
            padding: '4px 10px'
          },
          menu: {
            padding: 4
          },
          option: {
            borderRadius: 4
          }
        }}
        placeholder="Search tag"
        inputId="tag-select"
        isClearable={false}
        isLoading={isLoading || isDeleting}
        options={tags || []}
        value={selectedTag}
        onChange={(v) => handleOnChange(v)}
        formatOptionLabel={formatOption}
        formatMultiValueContainer={formatMultiValueContainer}
        formatCreateLabel={formatCreateLabel}
        onCreateOption={onCreateOption}
        {...rest}
      />
      {isCreateModal && (
        <TagCreate
          isOpen={isCreateModal}
          handleClose={closeCreateModal}
          kind={kind}
          name={tagName}
          addNewTag={addNewTag}
        />
      )}
      <Confirm
        handleOpen={isConfirmModal}
        handleClose={closeIsConfirmModal}
        handleContinue={handleContinue}
        title="Delete tag?"
        message="Are you sure you want to delete this tag?"
        variant="danger"
      />
    </>
  );
};

export default withErrorBoundary(TagSelect);
