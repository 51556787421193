import React from 'react';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { formatDateAndTimeZ, ia } from 'lib/helpers/utility';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { everyoneSigned } from 'components/practice/charts/ClinicalNote/shared/helpers/documentSignatureHelpers';
import { updateMultipleDocumentSign } from 'api/DocumentSign';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const SignAndLockProcess = ({
  allowToSign,
  showConfirmationModal,
  setShowConfirmationModal,
  setSelectedRows,
  selectedRows,
  signMessage,
  areFinalized
}) => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signAndLockTable = () => {
    return (
      <table className="w-full text-left">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2">Clinical Note ID</th>
            <th className="px-4 py-2">Date of Service</th>
            <th className="px-4 py-2">Provider</th>
          </tr>
        </thead>
        <tbody>
          {allowToSign?.map((row, index) => (
            <tr key={index} className="odd:bg-white even:bg-gray-50">
              <td className="px-4 py-2">{row?.id}</td>
              <td className="px-4 py-2">{formatDateAndTimeZ(row?.appointment?.starts_at)}</td>
              <td className="px-4 py-2">
                {row?.practitioner?.f_name} {row?.practitioner?.l_name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const updateDocumentSignMutation = useMutation(
    (data) => updateMultipleDocumentSign(navigate, data),
    {
      onSuccess: (data) => {
        if (data.code === 7) {
          showAlert({
            title: "You're not assigned to sign this document!",
            color: 'danger'
          });
          return;
        }
      }
    }
  );

  const confirmSignAndLock = async () => {
    if (!ia(allowToSign)) {
      setShowConfirmationModal(false);
      return;
    }
    let clinicalNotes = [],
      signatures = [];
    for (const clinicalNote of allowToSign) {
      try {
        const { services, patient, ...rest } = clinicalNote;

        const newLockState = everyoneSigned({
          documentSignatures: clinicalNote?.document_sign,
          userState: user
        });
        const newState = areFinalized ? 'draft' : newLockState ? 'finalized' : 'draft';
        const lockHistory = rest.lock_history ? [...rest.lock_history] : [];
        lockHistory.push({
          lock: areFinalized ? false : newLockState,
          timestamp: new Date(),
          user_id: user.id
        });
        const user_id = rest?.appointment?.user_id;
        const appointment_id = rest?.appointment_id;

        clinicalNotes.push({
          id: clinicalNote?.id,
          locked: areFinalized ? false : newLockState,
          state: newState,
          lock_history: lockHistory,
          user_id,
          appointment_id
        });

        if (areFinalized) {
          signatures.push({
            documentId: clinicalNote?.id,
            currentUser: user.id,
            overrideStatus: 'ready'
          });
        } else {
          signatures.push({
            documentId: clinicalNote?.id,
            documentTypes: ['clinical_note'],
            signature: user?.signature,
            currentUser: user.id,
            link: `/portal/charts/${user_id}/clinical-notes`,
            overrideStatus: null,
            alertUsers: true
          });
        }
      } catch (error) {
        showAlert({
          title: 'Document sign & lock failed',
          message: error ?? 'Something went wrong',
          color: 'danger'
        });
      }
    }

    try {
      let params = { clinicalNotes };
      await requestApi({ url: '/api/clinical_note/upsert_multiple', navigate, params });
      await updateDocumentSignMutation.mutateAsync({ documentData: signatures });

      showAlert({
        title: 'Success!',
        message: `Documents signed successfully!`,
        color: 'success'
      });
    } catch (error) {
      showAlert({
        title: 'Document sign & lock failed',
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }
    setSelectedRows([]);
    setShowConfirmationModal(false);
    queryClient.invalidateQueries(['clinicalNotes']);
    queryClient.resetQueries(['claim']);
    queryClient.resetQueries(['appointment']);
    queryClient.resetQueries(['documentSign']);
  };

  return (
    <Confirm
      primaryBtnTxt={ia(allowToSign) ? (areFinalized ? 'Unlock' : 'Sign & Lock') : 'Close'}
      title="Sign & Lock documents"
      icon="new-lockv2"
      message={
        ia(allowToSign) ? (
          <div>
            {selectedRows.length == allowToSign.length && (
              <p className="mb-2">
                You're only able to {areFinalized ? 'unlock' : 'sign & lock'} the following:
              </p>
            )}
            {signAndLockTable()}
            <p className="mt-2">
              Are you sure you want to {areFinalized ? 'unsign' : 'sign'}
              {allowToSign.length > 1 ? ' these documents?' : ' this document?'}
            </p>
          </div>
        ) : (
          <p>{signMessage}</p>
        )
      }
      handleOpen={showConfirmationModal}
      handleClose={() => setShowConfirmationModal(false)}
      handleContinue={confirmSignAndLock}
    />
  );
};

export default withErrorBoundary(SignAndLockProcess);
