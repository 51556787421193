import React from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { concat, map } from 'lodash';

import { formatDate, mString } from '../../../../../../lib/helpers/utility';
import { calcAdjustment, handleAdjustmentCodes } from '../ERA&EOBHelpers';
import Button from '../../../../../shared/Buttons/Button';

const UnMatchedClaimsRows = ({ era, timezone, codes, setSelectedClaim }) => {
  return (
    <table className={'primary-table responsive-table'}>
      <tr className="!w-[100%] !background-transparent">
        <td className="">
          <table className="success">
            <thead>
              <tr>
                <th>
                  <span className="!text-primary-900 !font-semibold">DoS</span>
                </th>
                <th>
                  <span className="!text-primary-900 !font-semibold">Patient</span>/
                  <span className="!text-primary-700 !font-medium">Service</span>
                </th>
                <th>
                  <span className="!text-primary-900 !font-semibold">Provider</span>
                </th>
                <th>
                  <span className="!text-primary-900 !font-semibold">Billed</span>
                </th>
                <th>
                  <span className="!text-primary-900 !font-semibold">Adjmt</span>
                </th>
                <th>
                  <span className="!text-primary-900 !font-semibold">Ins. Paid</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="overflow-hidden">
              {era.unmatched_claims.map((claim, i) => {
                return (
                  <tbody key={i} className="border-bottom">
                    <tr>
                      <th className="!text-primary-900 !font-semibold">
                        {claim.from_dos && formatDate(claim.from_dos, timezone)}
                      </th>
                      <th
                        className="cursor-pointer hover:underline !text-primary-900 !font-semibold"
                        onClick={() =>
                          claim.user
                            ? window.open(`/portal/charts/${claim.pcn}/billing`, '_blank')
                            : null
                        }>
                        <Tippy
                          content={claim.user ? 'View Chart' : null}
                          placement="right"
                          className="tippy-dark"
                          key={i}>
                          <div className="flex">
                            <span
                              className={cs(
                                'flex w-full cursor-pointer select-none items-center justify-center !rounded-lg p-1 text-center text-sm'
                              )}>
                              {claim.user ? claim.user.fullName : `User ID: ${claim.pcn}`}
                            </span>
                          </div>
                        </Tippy>
                      </th>
                      <th className="!text-primary-900 !font-semibold">N/A</th>
                      <th className="!text-primary-900 !font-semibold">
                        {mString(claim?.total_charge * 100)}
                      </th>
                      <th className="!text-primary-900 !font-semibold">
                        {mString(calcAdjustment(concat(...map(claim.charge, 'adjustment'))))}
                      </th>
                      <th className="!text-primary-900 !font-semibold">
                        {mString(claim.total_paid * 100)}
                      </th>
                      <th className="!text-primary-900 !font-semibold flex justify-center height-inherit">
                        <Button
                          text="Post"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedClaim({ claim, index: i, eraId: era.id });
                          }}
                        />
                      </th>
                    </tr>

                    {claim.charge.map((charge, index) => {
                      return (
                        <tr key={index} className="!text-primary-900 !font-semibold">
                          <td>{charge.from_dos && formatDate(charge?.from_dos, timezone)}</td>
                          <td className="!text-primary-700 !font-medium">{charge.proc_code}</td>
                          <td>
                            {`Qty: ${charge.units ? charge.units : ''} ·
                        Mods: ${[charge.mod1, charge.mod2, charge.mod3, charge.mod4]
                          .filter((f) => f !== null)
                          .join(', ')}
                        `}
                          </td>
                          <td>{mString(charge?.charge * 100)}</td>
                          <td>{handleAdjustmentCodes(charge?.adjustment, codes)}</td>
                          <td>{mString(charge?.paid * 100)}</td>
                          <td colSpan={1}></td>
                        </tr>
                      );
                    })}
                  </tbody>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default UnMatchedClaimsRows;
