import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClinicalNotes } from '../../../../../api/Widgets';
import { useClinicalNotes } from '../../../../../lib/hooks/queries/widgets/useOverview';
import FooterWrapper from '../../../../shared/CustomizableBoard/widgets/components/FooterWrapper';
import List from '../../../../shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from '../../../../shared/CustomizableBoard/widgets/helpers/formatDate';
import Icon from '../../../../shared/Icon/Icon';

const ClinicalNote = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`./clinical-notes/${item?.appointment?.id}/`);
      }}
      className="flex !cursor-pointer flex-col gap-3 px-[20px] py-3 hover:bg-primary-25">
      <div className="flex !cursor-pointer items-center justify-between gap-2">
        <div className="flex gap-2">
          <p className="font-medium text-primary-800">
            {item?.appointment.location == 'in_person' ? 'Office Visit' : 'Online Visit'} •{' '}
            {item?.practitioner?.fullName}
          </p>
          {item.locked && (
            <Icon icon="new-lockv2" className="cursor-pointer" color="primary" shade={800} />
          )}
        </div>
        <div className="flex gap-2">
          <p className="text-primary-800">{formatDate(item?.created_at)}</p>
          <Icon
            icon="new-chevron-right"
            className="cursor-pointer"
            stroke
            color="primary"
            shade={700}
          />
        </div>
      </div>
      {item?.presentIllness && (
        <p className="line-clamp-3 text-sm text-neutral-600">{item?.presentIllness}</p>
      )}
    </div>
  );
};

export default function ClinicalNotes(props) {
  const { patientId, changedSizes, layout } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateSize = async () => {
    const size = changedSizes.find((size) => size.i === props.item.i)?.h;

    if (size) {
      const newData = await getClinicalNotes(navigate, { limit: size * 2, patientId });
      queryClient.setQueryData(['overview-clinical-notes', patientId], newData);
    }
  };

  useEffect(() => {
    updateSize();
  }, [changedSizes]);

  const { data } = useClinicalNotes({
    params: { limit: layout.find((l) => l.i === props.item.i)?.h * 2, patientId },
    dependencies: [patientId]
  });

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader hasPlus={false} icon="clipboard-text" {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col overflow-y-auto">
        {data?.clinicalNotes?.length > 0 ? (
          <FooterWrapper
            link
            onClick={() => navigate(`./clinical-notes/`)}
            text={`Clinical Notes (${data?.count})`}>
            <List
              items={data?.clinicalNotes}
              renderItemComponent={(item) => <ClinicalNote item={item} />}></List>
          </FooterWrapper>
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Clinical Notes</p>
          </div>
        )}
      </div>
    </div>
  );
}
