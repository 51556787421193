import React from 'react';
import Icon from '../Icon/Icon';
import cs from 'classnames';

const StickyNotesButton = ({ onClick, customStyle = null }) => {
  return (
    <button
      data-qa="sticky-notes-btn"
      onClick={onClick}
      className={cs(
        'group',
        customStyle?.button ??
          'flex cursor-pointer items-center gap-[6px] rounded-full border !border-warning-300 bg-warning-50 px-[14px] py-[5px] hover:!bg-warning-100'
      )}>
      <Icon
        icon="new-note"
        className="flex cursor-pointer"
        size={customStyle?.icon?.size}
        color={customStyle?.icon?.color}
      />
      <span
        className={cs(
          customStyle?.text ??
            'text-xs font-500 leading-5 text-warning-600 group-hover:!text-warning-700'
        )}>
        Notes
      </span>
    </button>
  );
};

export default StickyNotesButton;
