import React from 'react';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';

const FooterButtons = ({
  buttonText,
  nextTo,
  backTo,
  showSaveAndExit = false,
  isLastStep,
  setBreadCrumbs,
  showSkip
}) => {
  const {
    enableNextTo,
    formik,
    showNavigationButtons,
    handleClose,
    isEdit,
    selectedLength,
    setIsSaveAndExit
  } = useFormBuilderContext();

  const navigateViews = ({ componentToView = '', isNext = false }) => {
    setBreadCrumbs((prevCrumbs) =>
      prevCrumbs.map((crumb) => {
        if (crumb.view === componentToView) {
          return { ...crumb, active: true };
        } else if (isNext && crumb.active && !crumb.isFinished) {
          return { ...crumb, isFinished: true, active: false };
        } else {
          return { ...crumb, active: false };
        }
      })
    );
  };
  const handleSubmit = (hasSaveAndExit) => {
    if (hasSaveAndExit) {
      setIsSaveAndExit(true);
    }
    formik.handleSubmit();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex  w-full items-center justify-between">
        {showNavigationButtons ? (
          <Button
            disabled={!backTo}
            outlined
            color="neutral"
            text="Back"
            data-qa="back-btn"
            onClick={() => navigateViews({ componentToView: backTo })}
          />
        ) : (
          <Button color="neutral" text="Close" outlined onClick={handleClose} data-qa="close-btn" />
        )}
        <div className="flex-end flex gap-4">
          {showSaveAndExit && (
            <Button
              text="Save & Exit"
              onClick={() => handleSubmit(true)}
              outlined
              data-qa="save-exit-btn"
            />
          )}

          {showNavigationButtons && nextTo && (
            <Button
              disabled={!enableNextTo}
              text={buttonText}
              onClick={() => navigateViews({ componentToView: nextTo, isNext: true })}
              type="primary"
              data-qa={`${buttonText}-btn`}
            />
          )}
          {showSkip && (
            <Button text="Skip" onClick={() => handleSubmit()} type="primary" outlined data-qa="skip-btn" />
          )}
          {isLastStep && (
            <Button
              text={isEdit ? 'Update' : 'Add'}
              onClick={() => handleSubmit()}
              disabled={isEdit ? false : !selectedLength}
              type="primary"
              data-qa="submit-btn"
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default FooterButtons;
