import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { isArray, isObject } from 'lodash';
import React from 'react';

const ClaimErrors = ({ errors }) => {
  return (
    <div className="box">
      <label className="subtitle" style={{ color: 'red' }}>
        Claim errors
      </label>
      {Object.entries(errors)?.map(([key, value]) => {
        return (
          <>
            {isArray(value) ? (
              value.map((v) => (
                <div key={key} style={{ color: 'red' }}>
                  {isObject(v) ? v?.charge : v}
                </div>
              ))
            ) : (
              <div key={key} style={{ color: 'red' }}>
                {value}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default withErrorBoundary(ClaimErrors);
