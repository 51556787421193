import { getPatientLedgerCSV } from 'api/Ledger';
import cs from 'classnames';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { usePatientLedger } from 'lib/hooks/queries/ledger/usePatientLedger';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS,
  getColDefs,
  getPatientDefaultFilters
} from './configs';

const getFinalColDefs = (name, claims, timezone) => {
  let colDefs = [...getColDefs(timezone, claims)];
  if (name === 'patient_ledger') {
    colDefs[1] = {
      field: 'patient_name',
      headerName: 'Patient',
      rowGroup: true,
      hide: true,
      pinned: 'left'
    };
  }

  if (name === 'ledger') {
    colDefs = colDefs.filter((col) => col.field !== 'patient_name');
  }
  return colDefs;
};

const NewPatientLedger = ({ name }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice?.timezone;
  const claims = practice?.display_settings?.claims;
  const NEW_COL_DEFS = getFinalColDefs(name, claims, practiceTimezone);

  return (
    <TableContextProvider
      name={name}
      defaultFilters={name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()}
      cols={NEW_COL_DEFS}
      pagination>
      <Table name={name} key={name} />
    </TableContextProvider>
  );
};

const Table = ({ name }) => {
  const category = name;

  const navigate = useNavigate();

  const { state: locationState } = useLocation();
  const { patient = null } = useOutletContext() || {};
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice?.timezone;

  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const { data, isLoading, isFetching } = usePatientLedger({
    params: {
      limit,
      page,
      sort,
      filters: mapValues({
        ...filters,
        ...(name === 'ledger' && {
          patients: {
            type: 'search',
            multiple: true,
            loadOptions: [],
            values: [
              {
                label: patient?.fullName,
                value: patient?.id
              }
            ],
            title: 'Patients',
            preview: (values) => values.map((v) => v.label).join(', ')
          }
        })
      }),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    name
  });

  const { data: providers = {} } = useProviders({});

  const count = data?.count || 0;
  const ledgers = data?.ledgers;
  const statusPanelData = data?.statusPanelData;

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.providers.options = providerOptions;

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: ledgers,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: ledgers,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [ledgers, count, page, limit, onPageChange, statusPanelData]);

  const exportCSV = async () => {
    const data = await getPatientLedgerCSV(navigate, {
      filters: mapValues({
        ...filters,
        ...(name === 'ledger' && {
          patients: {
            type: 'search',
            multiple: true,
            loadOptions: [],
            values: [
              {
                label: patient?.fullName,
                value: patient?.id
              }
            ],
            title: 'Patients',
            preview: (values) => values.map((v) => v.label).join(', ')
          }
        })
      }),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'ledger.csv';
    a.click();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {name === 'ledger' ? (
        <Header>
          <div
            className="-mt-[10px] mb-1 flex flex-wrap justify-end gap-2 pt-0"
            data-dd-privacy="allow">
            <DisplayButton />
            <Filter
              category={category}
              defaultFilters={
                name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()
              }
              filters={filters}
              setFilters={setFilters}
              menuPortalTarget={document.body}
            />
            <ExportData
              title="Generate Internal Invoice report"
              defaultFilters={
                name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()
              }
              filters={filters}
              onGenerateClick={exportCSV}
            />
          </div>
        </Header>
      ) : (
        <Header title="Patient Ledger" subtitle={locationState}>
          <div className="flex flex-wrap items-center justify-end gap-4" data-dd-privacy="allow">
            <div className="flex gap-2">
              <DisplayButton />
              <Filter
                category={category}
                defaultFilters={
                  name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()
                }
                filters={filters}
                setFilters={setFilters}
              />
              <ExportData
                title="Generate Patient Ledgers report"
                defaultFilters={
                  name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()
                }
                filters={filters}
                onGenerateClick={exportCSV}
              />
            </div>
          </div>
        </Header>
      )}
      <div className={cs('flex h-full flex-col overflow-hidden !pb-0')} data-dd-privacy="allow">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={25} />
          ) : (
            <AGTable
              defaultColDef={DEFAULT_COLUMN_DEFS}
              columnDef={getColDefs(practiceTimezone)}
              gridOptions={GRID_OPTIONS}
              animateRows={true}
              // getRowId={getRowId}
              data={ledgers}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              isRowSelectable={() => true}
              customClassName="ag-grid-interactive"
              enableRangeSelection={true}
              masterDetail={false}
              statusBar={statusBar}
              reactiveCustomComponents
              // loading={isLoading || isFetching}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NewPatientLedger;
