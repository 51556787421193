import cs from 'classnames';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ia } from '../../../../../../../lib/helpers/utility';
import Checkbox from '../../../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../../../shared/Icon/Icon';
import { getItemStyle } from '../../lib/renderedDragAndDropHelper';
import FormList from './FormList';

const List = ({
  item,
  index,
  toggleExpand,
  handleClick,
  keyParent,
  handleClickDropdown,
  onDragEndChildren
}) => {
  const { clinicalNote } = useClinicalNoteContext() || {};

  const listClassName = ia(item.formType)
    ? 'w-full'
    : 'flex h-10 w-full items-center rounded-[4px] bg-neutral-100';
  const listLabelClassName = ia(item.formType) ? 'cursor-pointer' : '!py-2 !px-[14px] w-full';
  const listInputClassName = ia(item.formType)
    ? 'h-10 !py-2 !px-3 rounded !border border-solid border-transparent bg-neutral-100'
    : 'border-neutral-400';

  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={clinicalNote?.locked}>
      {(provided, snapshot) => (
        <div
          key={item.id}
          className="!mb-3 flex cursor-pointer items-start gap-x-[2px]"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
          <Icon icon="new-sort" className="cursor-grab !pt-[0.75rem]" />
          <Checkbox
            disabled={clinicalNote?.locked}
            data-qa={`form-${item.id}`}
            label={item.title || item.label}
            isChecked={item.checked}
            onChange={(e) => handleClick(keyParent, e.target.value)}
            className={cs(listClassName, snapshot.isDragging && 'bg-neutral-200')}
            labelClassName={listLabelClassName}
            inputClassName={listInputClassName}
            isDropdown={ia(item.formType)}
            expanded={item?.expanded}
            setExpanded={() => toggleExpand(keyParent)}>
            {ia(item.formType) && (
              <div className="flex flex-col gap-3">
                <FormList
                  forms={item.formType}
                  keyParent={keyParent}
                  handleClickDropdown={handleClickDropdown}
                  onDragEndChildren={onDragEndChildren}
                />
              </div>
            )}
          </Checkbox>
        </div>
      )}
    </Draggable>
  );
};

export default List;
