import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Subjective from './Subjective';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { permissions as userPermissions } from '/components/state';

const SubjectiveSection = ({ components }) => {
  const { clinicalNote, setEnhanceNarrativeParams, refetchEnhanceNarrative } =
    useClinicalNoteContext();
  const subjectiveRef = useRef(null);
  const sectionRef = useRef();
  const location = useLocation() || {};
  const permissions = useRecoilValue(userPermissions);

  const handleEnhanceNarrative = () => {
    setEnhanceNarrativeParams({
      kind:
        location?.pathname?.includes('/hp') || location?.pathname?.includes('/intake')
          ? 'hp'
          : 'soap',
      name: 'Subjective',
      body: subjectiveRef.current?.value
    });
    refetchEnhanceNarrative();
  };

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.subjective?.title}
      id={components?.subjective?.id}
      disabled={clinicalNote?.locked}
      options={
        permissions.is_admin
          ? [
              {
                title: 'Enhance Narrative',
                icon: 'new-myscribe-stars',
                onClick: () => handleEnhanceNarrative()
              }
            ]
          : []
      }>
      <Subjective sectionRef={sectionRef} subjectiveRef={subjectiveRef} />
    </Accordion>
  );
};

export default SubjectiveSection;
