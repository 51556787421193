import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getClinicalNote } from '../../../../../../../../api/ClinicalNote';
import {
  formatDate,
  ia,
  iaRa,
  spaceToKebabCase
} from '../../../../../../../../lib/helpers/utility';
import { currentPractice } from '../../../../../../practiceState';
import {
  mapRenderedInitialState,
  processSelectedForms
} from '../../../../PreviewNote/lib/renderedHelper';

import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import RenderedComponents from '../../../../PreviewNote/Rendered/components/RenderedComponents';
import { getOverview } from '../../../../lib/getOverview';

const ClinicalSummaryReview = ({ itemProps }) => {
  const { id, appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [componentProps, setComponentProps] = useState({
    checkedRendered: null,
    hpForms: null,
    soapForms: null
  });
  const practice = useRecoilValue(currentPractice);

  const isOnlyCurrentCN =
    itemProps?.clinical_notes.length === 1 && itemProps?.clinical_notes[0] === appointmentId;

  useEffect(() => {
    if (isOnlyCurrentCN) {
      prepareForms();
    }

    if (itemProps?.clinical_notes && itemProps?.clinical_summary) {
      setLoading(true);

      prepareComponentProps();

      prepareForms();
    }
  }, [itemProps]);

  const prepareComponentProps = () => {
    const selected = itemProps?.clinical_summary;
    const rendered = mapRenderedInitialState(selected);

    const { checkedRendered, hpForms, soapForms } = processSelectedForms({ selected, rendered });

    setComponentProps({ checkedRendered, hpForms, soapForms });
  };

  const navigate = useNavigate();

  const prepareForms = async () => {
    const compProps = [];
    const cnIds = itemProps?.clinical_notes;

    await Promise.all(
      cnIds.map(async (item) => {
        const result = await getClinicalNote(navigate, {
          appointmentId: item,
          loadedPracticeId: practice?.id
        });

        const { clinicalNote, advancedHP, advancedSOAP, serviceCPT } = result;

        const processedAdvancedHP = advancedHP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));
        const processedAdvancedSOAP = advancedSOAP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));

        const cpt_codes = iaRa(serviceCPT).map((cpt) => {
          const matchingCPTIndex = iaRa(clinicalNote?.cpt_codes).findIndex(
            (row) => row?.code?.value === cpt.value && !row.processed
          );
          let modifiers;
          if (matchingCPTIndex !== -1) {
            modifiers = clinicalNote.cpt_codes?.[matchingCPTIndex]?.modifiers;
            clinicalNote.cpt_codes[matchingCPTIndex].processed = true;
          }
          return {
            code: {
              ...cpt,
              charge: cpt.charge
                ? parseFloat(
                    (cpt.charge / 100) *
                      (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage / 100 ||
                        1)
                  ).toFixed(2)
                : 0
            },
            modifiers: iaRa(modifiers),
            id: self.crypto.randomUUID()
          };
        });

        compProps.push({
          customClinicalNote: {
            clinicalNote: { ...clinicalNote, cpt_codes },
            advancedHP: processedAdvancedHP,
            advancedSOAP: processedAdvancedSOAP,
            currentHpOverviewData: await getOverview({
              patientId: id,
              appointmentId: item,
              navigate,
              isKebab: false
            })
          }
        });
      })
    );

    setResult(compProps);
    setLoading(false);
  };

  return (
    <div>
      <div className="text-base font-500 text-neutral-800">Clinical Summary</div>
      {loading ? (
        <Skeleton count={5} />
      ) : ia(result) ? (
        result.map((row, index) => {
          const displayDivider = result?.length > index + 1;

          return (
            <div key={index} className="!mt-2.5">
              <p className="w-fit rounded-lg bg-primary-50 !px-2 !py-1 font-500 text-neutral-800">
                {formatDate(
                  row?.customClinicalNote?.clinicalNote?.created_at,
                  practice?.timezone,
                  true
                )}
              </p>

              <RenderedComponents {...componentProps} {...row} className="!bg-transparent" />

              {displayDivider && <div className="border border-solid !border-primary-100"></div>}
            </div>
          );
        })
      ) : (
        <p className="text-neutral-800">No clinical summary found</p>
      )}
    </div>
  );
};

export default ClinicalSummaryReview;
