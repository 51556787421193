import { formatDate } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import Type from '../Type';
import Actions from '../Actions';
import { showAlert } from 'components/shared/Alert/Alert';
import { updateMultipleNotifications } from 'api/Notifications';

export const notificationIconType = (type) => {
  switch (type) {
    case 'treatment_plan':
      return 'new-document-sign';
    case 'clinical_note':
      return 'new-notev2';
    case 'e_fax':
      return 'clipboard-import';
    case 'e_fax_signature':
      return 'new-document-sign';
    case 'task':
      return 'new-task';
    default:
      return 'new-notifications';
  }
};

export const defaultColumns = [
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'no-border',
    cellRenderer: Type,
    maxWidth: 200,
    minWidth: 100,
    resizable: false
  },
  {
    field: 'title',
    headerName: 'Title',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value),
    flex: 1
  },
  {
    field: 'content',
    headerName: 'Content',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value,
    flex: 2
  },
  {
    field: 'is_read',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (value ? 'Read' : 'Unread'),
    flex: 1
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value),
    flex: 1
  },
  {
    field: '',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right',
    flex: 1
  }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Created At',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  title: {
    type: 'search',
    values: null,
    title: 'Notification Title',
    placeholder: 'Search title'
  },
  type: {
    type: 'search',
    values: null,
    title: 'Notification Type',
    placeholder: 'Search type'
  }
};

export const updateMultiple = async ({
  notifications,
  data,
  setSelectedRows,
  navigate,
  queryClient
}) => {
  const params = { notifications, ...data };

  await updateMultipleNotifications(navigate, params);
  queryClient.invalidateQueries(['unreadNotificationsCount']);
  queryClient.invalidateQueries(['notifications']);
  setSelectedRows([]);
  showAlert({ title: `Successfully updated  notifications` });
};
