import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { useFormik } from 'formik';
import { iaRa } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import NewFacilityProvider from './NewFacilityProvider';
import Row from './Row';

export default function FacilityProvider() {
  const navigate = useNavigate();
  const { practice } = useOutletContext();
  const [facilityInfo, setFacilityInfo] = useState([]);
  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const FacilityProviderValidationSchema = Yup.object().shape({
    practice_id: Yup.number().required('Practice is required'),
    facility_name: Yup.string().required('Facility name is required.'),
    facility_npi: Yup.string(),
    facility_id: Yup.string(),
    facility_addr_1: Yup.string().required('Address line one is required.'),
    facility_addr_2: Yup.string().required(
      'Address line two is required, type N/A if not applicable.'
    ),
    facility_city: Yup.string().required('City is required'),
    facility_state: Yup.string().required('State is required'),
    facility_zip: Yup.string()
      .min(9, 'Too Short!')
      .max(9, 'Too Long!')
      .required('Zip code is required (9 digits)'),
    facility_phone: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      practice_id: practice?.id,
      facility_name: practice?.name,
      facility_npi: '',
      facility_id: '',
      facility_addr_1: practice?.practiceAddress?.address_ln_1,
      facility_addr_2: practice?.practiceAddress?.address_ln_2,
      facility_city: practice?.practiceAddress?.city,
      facility_state: practice?.practiceAddress?.state,
      facility_zip: practice?.practiceAddress?.zip,
      practitioner_ids: []
    },
    validationSchema: FacilityProviderValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await submitFacilityProvider(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    getFacilityInfo();
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `file: /facility/FacilityProvider, method: getStates, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const getFacilityInfo = async () => {
    try {
      const onSuccess = ({ facilityInfo }) => {
        setFacilityInfo(facilityInfo);
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
      };

      await requestApi({
        url: '/api/admin/practice/facility/read',
        params: {
          practiceId: practice.id
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /facility/FacilityProvider, method: getFacilityInfo - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const submitFacilityProvider = async (values) => {
    const newFacilityProvider = { ...values };
    try {
      const res = await requestApi({
        url: '/api/admin/practice/facility/upsert',
        params: {
          newFacilityProvider: {
            ...newFacilityProvider
          }
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Success', color: 'primary' });
          getFacilityInfo();
          setShowModal(false);
        } else {
          Honeybadger.notify(
            `file: /facility/FacilityProvider, method: submitFacilityProvider, error: An unexpected error has occurred.`
          );
          showAlert({ title: 'There was an error.', color: 'danger' });
        }
      } else {
        Honeybadger.notify(
          `file: /facility/FacilityProvider, method: submitFacilityProvider, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /facility/FacilityProvider, method: submitFacilityProvider - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleDeletion = async (id) => {
    try {
      const res = await requestApi({
        url: '/api/admin/practice/facility/delete',
        params: {
          id
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Deleted successfully', color: 'primary' });
          getFacilityInfo();
          setShowModal(false);
        } else {
          showAlert({ title: 'There was an error deleting.', color: 'danger' });
          Honeybadger.notify(
            `file: /facility/FacilityProvider, method: handleDeletion, error: An unexpected error has occurred.`
          );
        }
      } else {
        Honeybadger.notify(
          `file: /facility/FacilityProvider, method: handleDeletion, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /facility/FacilityProvider, method: handleDeletion - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const selectFacility = (biller) => {
    formik.setFieldValue('id', biller.id);
    formik.setFieldValue('practice_id', biller.practice_id);
    formik.setFieldValue('facility_name', biller.facility_name);
    formik.setFieldValue('facility_npi', biller.facility_npi);
    formik.setFieldValue('facility_id', biller.facility_id);
    formik.setFieldValue('facility_addr_1', biller.facility_addr_1);
    formik.setFieldValue('facility_addr_2', biller.facility_addr_2);
    formik.setFieldValue('facility_city', biller.facility_city);
    formik.setFieldValue('facility_state', biller.facility_state);
    formik.setFieldValue('facility_zip', biller.facility_zip);
    formik.setFieldValue('practitioner_ids', biller.practitioner_ids);
    setShowModal(true);
  };

  return (
    <div className="flex h-fit min-h-[30vh] flex-col p-[1rem]">
      <Button
        icon="plus"
        onClick={() => setShowModal(true)}
        text="New"
        size="small"
        className="w-[100px]"
      />
      <div className="!mt-6 overflow-auto !pr-4">
        {iaRa(facilityInfo)?.map((row) => {
          return (
            <Row
              key={row.id}
              row={row}
              members={practice?.members}
              onClick={() => selectFacility(row)}
              states={states}
            />
          );
        })}
      </div>
      <NewFacilityProvider
        formik={formik}
        isLoading={formik.isSubmitting}
        open={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={formik.submitForm}
        states={states}
        loadedMembers={iaRa(practice?.members).map((member) => {
          return { label: `${member.f_name} ${member.l_name}`, value: member.id };
        })}
        handleDeletion={handleDeletion}
      />
    </div>
  );
}
