import React, { useCallback, useState } from 'react';
import Icon from '../Icon/Icon';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import Transcribe from '../Transcribe/Transcribe';
import useAutosizeElement from '../../../lib/hooks/useAutosizeElement';
import { ia } from '../../../lib/helpers/utility';
import { Popover } from '@headlessui/react';
import TextEditor from '../TextEditor/TextEditor';

const Textarea = ({
  label,
  icon,
  transcribing,
  options,
  tooltip,
  placeholder,
  value,
  isEditor = false,
  id = label || 'textareaId',
  name = 'textareaName',
  className,
  width,
  disabled = false,
  setDisabled = () => { },
  media = { toolbar: [], formats: [] },
  error,
  forwardedRef,
  onChange = () => { },
  textareaClassName,
  maxLength,
  ...rest
}) => {
  useAutosizeElement(forwardedRef, value);

  const getTextLength = (text) => useCallback(text.length, [text]);

  if (isEditor)
    return (
      <TextEditor
        onChange={onChange}
        value={value}
        defaultValue={value}
        error={error}
        label={label}
        transcribing={transcribing}
        options={options}
        media={media}
        className={className}
        disabled={disabled}
        icon={icon}
        name={name}
        id={id}
      />
    );

  return (
    <div className={cs(className)} style={{ width }}>
      {(label || tooltip || transcribing || icon || options) && (
        <div className="flex min-h-[28px] items-center justify-between !pb-1">
          <div className="flex w-full items-center gap-x-1">
            {label && (
              <label className="m-0 !pr-4 text-sm font-500 text-neutral-800" htmlFor={id}>
                {label}
              </label>
            )}
            {tooltip && (
              <Tippy content={<span>{tooltip}</span>} arrow>
                <div className="flex">
                  <Icon icon="info" />
                </div>
              </Tippy>
            )}
            {maxLength && (
              <label className="!mt-1 text-xs font-500 text-neutral-500" htmlFor={id}>
                {`${getTextLength(value)} / ${maxLength}`}
              </label>
            )}
          </div>
          <div className="ml-auto flex items-center gap-x-1">
            {transcribing && (
              <Transcribe
                value={value}
                setValue={(text) =>
                  onChange({
                    target: {
                      value: text,
                      name
                    }
                  })
                }
                setDisable={setDisabled}
                disabled={disabled}
              />
            )}
            {icon}
            {ia(options) && (
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={cs(
                        'custom-btn flex !h-6 !w-6 cursor-pointer items-center justify-center rounded-[3px] bg-transparent p-0 hover:!bg-neutral-100',
                        open && '!bg-primary-700 hover:!bg-primary-700'
                      )}>
                      <Icon icon="new-candle" className="cursor-pointer" color={open && 'white'} />
                    </Popover.Button>
                    <Popover.Panel className="absolute right-0 z-10 mt-[6px] w-max rounded-[4px] bg-white shadow-medium">
                      <div className="grid">
                        {options.map((option, idx) => (
                          <div
                            className="even:border-0 even:!border-b-[1px] even:!border-t-[1px] even:border-solid even:border-neutral-100"
                            key={idx}>
                            <div className="!px-2 py-[6px]" onClick={option.onClick}>
                              <div className="grid cursor-pointer items-center rounded-[4px] !px-2 py-[6px] hover:bg-primary-50">
                                <span className="text-xs font-500 text-primary-900">
                                  {option.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            )}
          </div>
        </div>
      )}
      <textarea
        className={cs(
          '!m-0 flex h-10 w-full !resize-none items-center justify-between gap-x-3 overflow-hidden rounded-md border border-neutral-200 bg-white !px-3 py-[10px] text-sm leading-5 text-neutral-900 placeholder:text-neutral-500 focus-within:outline focus-within:!outline-2',
          textareaClassName,
          error
            ? '!border-danger-300 caret-danger-500 focus-within:!outline-danger-100'
            : 'caret-primary-500 focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
          disabled && 'cursor-not-allowed text-neutral-400'
        )}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        name={name}
        disabled={disabled}
        ref={forwardedRef}
        maxLength={maxLength}
        {...rest}
      />
      {error && <p className={cs('!pt-2 text-sm text-danger-500')}>{error}</p>}
    </div>
  );
};

export default Textarea;
