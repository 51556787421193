import { Popover } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Switch from 'components/shared/Switch/Switch';
import { formatDate, iaRa, snakeToTitleCase } from 'lib/helpers/utility';
import moment from 'moment';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { useRecoilValue } from 'recoil';
import practiceState from '../practiceState';
import { conditionalFormatTimeZ } from './SuperBillPage/lib/conditionTimeFormat';
import { generateBaseUnit } from './SuperBillPage/lib/generateBaseUnit';
import { getHours } from './SuperBillPage/lib/getHours';
import { handleOnChangeUnit } from './SuperBillPage/lib/handleOnChangeUnit';
import { handleUnitSwitch } from './SuperBillPage/lib/handleUnitSwitch';
import { handleUnitValue } from './SuperBillPage/lib/handleUnitValue';
import { shouldDisableProcedureProduct } from './SuperBillPage/lib/shouldDisableProcedure';

export default function CPTRow({
  item,
  handleProcedureChanges,
  index,
  posOptions,
  handleDeletion,
  handleCPTSearch,
  selectCPT,
  superbill,
  convertProcedureToPrimary
}) {
  const disableProcedure = shouldDisableProcedureProduct({
    superbill,
    procedure: item
  });
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const formattedStartTime = conditionalFormatTimeZ(
    item?.appointment_start,
    currPractice?.timezone
  );
  const formattedEndTime = conditionalFormatTimeZ(item?.appointment_end, currPractice?.timezone);
  const hours = getHours(currPractice, formattedStartTime, formattedEndTime);
  let timeOptions = iaRa(hours).map((time) => ({
    value: time,
    label: time
  }));

  const isNotPrimary = item?.type && item.type !== 'primary';
  const [manualChange, setManualChange] = useState(false);

  return (
    <div
      className={cs(
        'gap-x-4 gap-y-1 !rounded-lg border border-solid border-neutral-300 p-2 odd:bg-neutral-50',
        isNotPrimary && '!border-warning-300 !bg-warning-50'
      )}>
      {isNotPrimary && (
        <div className="mb-3 flex justify-between">
          <div className="flex gap-1.5">
            <Icon icon="new-info" color="warning" />
            <p className="self-center text-sm text-warning-600">
              This service type is <em className="font-600">"{snakeToTitleCase(item.type)}"</em>, it
              will not be transferred to the claim unless converted to insurance type
            </p>
          </div>
          <Button
            text="Convert to insurance"
            size="small"
            outlined
            onClick={() => convertProcedureToPrimary(index)}
          />
        </div>
      )}
      <div className={cs('grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-x-4 gap-y-1 ')}>
        <TippyContainer isDisabled={disableProcedure}>
          <Select
            label="Code"
            isAsync
            loadOptions={handleCPTSearch}
            onChange={(e) => selectCPT(e, index)}
            cacheOptions
            value={{ label: item.procedure_code }}
            noOptionsMessage={() => 'Start typing the code or description.'}
            inputId="cptCodes"
            menuPortalTarget={document.body}
            disabled={disableProcedure}
            isClearable={false}
          />
        </TippyContainer>

        <Input
          label="NDC"
          name="narrative"
          value={item.narrative}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <Input
          label="Modifier"
          name="modifiers"
          value={item.modifiers}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <Input
          label="Diagnosis pointer"
          name="diagnosis"
          value={item.diagnosis}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <CurrencyInput
          label="Charges"
          name="charge"
          value={item.charge}
          onValueChange={(value) =>
            handleProcedureChanges({ target: { value, name: 'charge' } }, index)
          }
        />
        <div className="flex items-center justify-center">
          <Input
            label="Days/Units"
            name="unit_time_length"
            customIcon={
              item?.base_unit && (
                <>
                  <Switch
                    checked={item?.isTimeUnit || false}
                    onChange={(checked) => {
                      item.isTimeUnit = checked;
                      handleUnitSwitch({
                        procedure: item,
                        isTimeUnit: item?.isTimeUnit,
                        handleProcedureChanges,
                        index,
                        startTime: formattedStartTime,
                        endTime: formattedEndTime
                      });
                    }}
                  />
                  <p>Min</p>
                </>
              )
            }
            value={handleUnitValue(
              item,
              item?.isTimeUnit,
              item?.start_time || formattedStartTime,
              item?.end_time || formattedEndTime,
              handleProcedureChanges,
              index,
              manualChange
            )}
            onChange={(e) => {
              setManualChange(true);
              handleOnChangeUnit({
                procedure: item,
                isTimeUnit: item?.isTimeUnit,
                handleProcedureChanges,
                index,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                value: e.target.value
              });
            }}
            min={1}
            type="number"
          />
        </div>
        <Select
          label="POS"
          className="print:hidden"
          options={posOptions}
          value={posOptions.find((p) => p.value === String(item.place_of_service))}
          onChange={(e) =>
            handleProcedureChanges({ target: { value: e?.value, name: 'place_of_service' } }, index)
          }
          isMulti={false}
        />
        <Input
          label="Place of service"
          className="hidden print:block"
          value={item.place_of_service}
          type="text"
        />
        <Popover className="relative bg-white">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  className={cs(isNotPrimary && '!bg-warning-50')}
                  rightIcon="new-calendar-gray"
                  label="From date"
                  value={formatDate(item.from_date)}
                />
              </Popover.Button>
              <Popover.Panel className="absolute top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
                <Calendar
                  showDateDisplay
                  className="w-full"
                  onChange={(date) => {
                    const now = new Date();
                    date.setHours(now.getHours());
                    handleProcedureChanges(
                      { target: { value: moment(date).format('MM/DD/YYYY'), name: 'from_date' } },
                      index
                    );
                    close();
                  }}
                  date={new Date(formatDate(item.from_date))}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>

        <Popover className="relative bg-white">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  className={cs(isNotPrimary && '!bg-warning-50')}
                  rightIcon="new-calendar-gray"
                  label="To date"
                  value={formatDate(item.thru_date)}
                />
              </Popover.Button>

              <Popover.Panel className="absolute top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
                <Calendar
                  showDateDisplay
                  className="w-full"
                  onChange={(date) => {
                    const now = new Date();
                    date.setHours(now.getHours());
                    handleProcedureChanges(
                      { target: { value: moment(date).format('MM/DD/YYYY'), name: 'thru_date' } },
                      index
                    );
                    close();
                  }}
                  date={new Date(formatDate(item.thru_date))}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>

        {item?.base_unit && (
          <>
            <Select
              label="Start Time"
              parentClassName="w-full"
              isClearable={false}
              className="!-mt-[2px]"
              rightIcon="new-clock-gray"
              options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
              onChange={(val) => {
                handleProcedureChanges({ target: { value: val.value, name: 'start_time' } }, index);
                generateBaseUnit({
                  procedure: item,
                  formattedStartTime,
                  formattedEndTime,
                  index,
                  updateProcedures: handleProcedureChanges
                });
              }}
              value={
                timeOptions.find((option) => option.value == item.start_time) ||
                timeOptions.find((option) => option.value == formattedStartTime)
              }
            />
            <Select
              label="End Time"
              parentClassName="w-full"
              isClearable={false}
              className="!-mt-[2px]"
              rightIcon="new-clock-gray"
              options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
              onChange={(val) => {
                handleProcedureChanges({ target: { value: val.value, name: 'end_time' } }, index);
                generateBaseUnit({
                  procedure: item,
                  formattedStartTime,
                  formattedEndTime,
                  index,
                  updateProcedures: handleProcedureChanges
                });
              }}
              value={
                timeOptions.find((option) => option.value == item.end_time) ||
                timeOptions.find((option) => option.value == formattedEndTime)
              }
            />
          </>
        )}

        <TippyContainer className="mb-1 mt-auto max-h-[40px]" isDisabled={disableProcedure}>
          <Button
            disabled={disableProcedure}
            close
            onClick={() => handleDeletion(index)}
            text="Remove"
            iconColor="white"
            icon="trash"
            color="danger"
            outlined
            className="mt-auto max-h-[40px] w-full"
          />
        </TippyContainer>
      </div>
    </div>
  );
}

const TippyContainer = ({ isDisabled = false, className = null, children }) => {
  return (
    <Tippy
      className="tippy-dark"
      disabled={!isDisabled}
      content="Procedure cannot be removed because the clinical note is locked.">
      <div className={className}>{children}</div>
    </Tippy>
  );
};
