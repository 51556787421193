/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getAppointment } from 'api/Appointment';

export const useAppointment = ({ params, options }) => {
  const navigate = useNavigate();
  const { id } = params;

  return useQuery(['appointment', id], ({ signal }) => getAppointment(navigate, params, signal), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
