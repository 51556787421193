import React, { useState } from 'react';
import { IncomeIQueueContext } from './IncomeIQueueContext';

export const IncomeIQueueContextProvider = ({ children }) => {
  const [actions, setActions] = useState([]);
  return (
    <IncomeIQueueContext.Provider value={{ actions, setActions }}>
      {children}
    </IncomeIQueueContext.Provider>
  );
};
