import { requestApi } from './Api';

export const detectDocument = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/document/detection',
    params
  });
};

export const getSignatures = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/document/signatures',
    params
  });
};
