import React from 'react';
import cs from 'classnames';
import List from './List';
import Management from './Management/Management';
import SelectNote from './SelectNote';
import ArrowToggle from '../../shared/ArrowToggle';
import { useOutletContext } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
const Sidebar = ({ className, appointment }) => {
  const { open, setOpen } = useOutletContext();
  const { cnDisplaySettings, setCNDisplaySettings } = useClinicalNoteContext();

  const toggleSidebar = () => {
    setOpen(!open);
    const updatedSettings = {
      ...cnDisplaySettings,
      toggleLSidebar: {
        ...cnDisplaySettings?.toggleLSidebar,
        enabled: !cnDisplaySettings?.toggleLSidebar?.enabled
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  return (
    <div
      className={cs(
        'fixed top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        className,
        open ? 'w-[264px]' : 'w-[128px]'
      )}
      data-dd-privacy="allow">
      <ArrowToggle open={open} setOpen={toggleSidebar} />
      <SelectNote appointment={appointment} openLSidebar={open} />
      <Management open={open} appointment={appointment} />
      <List open={open} />
    </div>
  );
};

export default Sidebar;
