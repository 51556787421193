import { optionify } from 'lib/helpers/utility';

export default function () {
  let times = [];
  for (let i = 0; i < 24 * 12; i++) {
    // 24 hours * 12 intervals per hour (5 minutes each)
    let hour = Math.floor(i / 12);
    let minutes = (i % 12) * 5;
    let period = hour < 12 ? 'AM' : 'PM';
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
    let time =
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ' ' +
      period;
    times.push(time);
  }
  return optionify(times);
}
