import React, { useState } from 'react';
import cs from 'classnames';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';

export default function ExportData({ filters, defaultFilters, title, onGenerateClick = () => { } }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        onClick={() => setShow(true)}
        className={cs(
          'flex py-[5px] px-[11px] bg-white justify-center gap-2 items-center rounded-lg',
          'shadow-[0px_0px_8px_0px_#1032540A] !border-neutral-100 border cursor-pointer border-solid'
        )}>
        <Icon size={16} color={'neutral'} className={'cursor-pointer'} icon="new-export-v2"></Icon>
      </div>
      <Modal
        icon="list-unordered"
        title={title}
        handleOpen={show}
        handleClose={() => setShow(false)}>
        <div>
          {Object.keys(filters)?.map((key) => {
            return (
              !_.isEqual(_.sortBy(filters[key].values), _.sortBy(defaultFilters[key]?.values)) && (
                <div className="flex !px-2 mb-1 justify-between">
                  <p className="text-neutral-600 font-medium">{filters[key].title}</p>
                  <p className="text-primary-900 font-medium">
                    {filters[key].preview(filters[key].values)}
                  </p>
                </div>
              )
            );
          })}
          <div
            onClick={onGenerateClick}
            className="border mt-3 !border-primary-100 flex justify-between !rounded-lg hover:bg-primary-100 bg-primary-50 !p-2 items-center !px-4 cursor-pointer ">
            <p className="text-base text-primary-900 font-medium">Generate CSV file</p>
            <Icon className="cursor-pointer" icon="file-csv"></Icon>
          </div>
        </div>
      </Modal>
    </>
  );
}
