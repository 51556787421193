import { Honeybadger } from '@honeybadger-io/react';
import * as filestack from 'filestack-js';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getStates as getStatesAPI } from '../../../../api/State';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import Button from '../../../shared/Buttons/Button';
import Modal from '../../../shared/Modal/Modal';
import InsuranceForms from './components/InsuranceForms';
import UploadCards from './components/UploadCards';
import { requestApi } from '../../../../api/Api';
import { usePayersCheckin } from 'lib/hooks/queries/payers/usePayersCheckin';
import ImagePicker from 'components/shared/Picker/ImagePicker';
import useKeyboardStatus from 'lib/hooks/useKeyboardStatus';
import Icon from 'components/shared/Icon/Icon';

import { useRecoilValue } from 'recoil';
import state from 'components/Patient/checkin/state';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const EditInsuranceProfile = ({
  insuranceProfile,
  index,
  showEditInsuranceProfile,
  hideEditInsuranceProfile,
  updateProfile,
  kiosk,
  patientId,
  fakeModal,
  setFakeModal
}) => {
  if (!insuranceProfile) return null;
  const isKiosk = !!localStorage.getItem('myriadIsKiosk');
  const { device } = useUIContext();
  const editInsuranceProfile = useRef();
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState({});
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const [imageEdit, setImageEdit] = useState(null);
  const user = useRecoilValue(state.userState);

  const isTablet = device === 'tablet';

  const { data } = usePayersCheckin({});

  const payers = data?.payers || [];

  const isKeyboardOpen = useKeyboardStatus();

  const [showImages, setShowImages] = useState(true);

  useEffect(() => {
    if (isKeyboardOpen) {
      setShowImages(false);
    }
  }, [isKeyboardOpen]);

  useEffect(() => {
    if (insuranceProfile?.card_front) {
      getInsuranceCard(insuranceProfile?.card_front, 'card_front');
    } else {
      setPhoto((prev) => ({ ...prev, card_front: null }));
    }
    if (insuranceProfile?.card_back) {
      getInsuranceCard(insuranceProfile?.card_back, 'card_back');
    } else {
      setPhoto((prev) => ({ ...prev, card_back: null }));
    }

    if (insuranceProfile?.relation_address) {
      getRelationAddress(insuranceProfile?.relation_address);
    }
  }, [insuranceProfile]);

  useEffect(() => {
    getStates();
  }, []);

  const getPayerAddress = async (selected) => {
    try {
      if (!selected) {
        formik.setValues({
          ...formik.values,
          ...address,
          payer_phone: '',
          payer_name: '',
          payer_id: ''
        });
        return;
      }
      const res = await requestApi({
        url: '/api/payers/read',
        params: {
          id: selected?.value || formik?.values?.payer_id
        },
        navigate
      });
      const payerName = selected ? selected.payer_name : formik?.values?.payer_name;

      const payerId = selected ? selected.value : formik?.values?.payer_id;
      let address = {
        address_id: null,
        address_ln_1: '',
        address_ln_2: '',
        zip: '',
        city: '',
        state: ''
      };
      let phone = '';
      if (res) {
        phone = res.payers?.phone;
        const pAddress = res.payers.payerAddress;
        if (pAddress) {
          address = {
            ...address,
            ...pAddress
          };
        }
      }
      formik.setValues({
        ...formik.values,
        ...address,
        payer_phone: phone,
        payer_name: payerName,
        payer_id: payerId
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true, method: 'get' });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getRelationAddress = async (id) => {
    try {
      const res = await requestApi({ url: '/api/address/get', params: { id }, navigate });
      const { address } = res;
      if (address) {
        formik.setValues({
          ...formik.values,
          other_relation_address_ln_1: address?.address_ln_1,
          other_relation_address_ln_2: address?.address_ln_2,
          other_relation_city: address?.city,
          other_relation_state: address?.state,
          other_relation_zip: address?.zip
        });
      } else {
        formik.setValues({
          relation_address: null,
          other_relation_address_ln_1: '',
          other_relation_address_ln_2: '',
          other_relation_city: '',
          other_relation_state: '',
          other_relation_zip: ''
        });
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleEnterPreventDefault = (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const InsuranceProfileValidationSchema = Yup.object().shape({
    payer_id: Yup.string().required('Insurance company required.'),
    type: Yup.string().required('Type required.'),
    member_id: Yup.string().required('Member id required.'),
    relation: Yup.string().required('Relation required.')
  });

  const formik = useFormik({
    initialValues: {
      practice_id: insuranceProfile.practice_id,
      payer_id: insuranceProfile.payer_id,
      payer_name: insuranceProfile.payer_name,
      type: insuranceProfile.type,
      member_id: insuranceProfile.member_id,
      group_number: insuranceProfile.group_number,
      plan_name: insuranceProfile.plan_name,
      prior_auth: insuranceProfile.prior_auth,
      policy_start_date: insuranceProfile.policy_start_date,
      policy_end_date: insuranceProfile.policy_end_date,
      relation: insuranceProfile.relation,
      ignore_warnings: insuranceProfile.ignore_warnings,
      other_relation_name: insuranceProfile.other_relation_name,
      other_relation_date: insuranceProfile.other_relation_date,
      other_relation_phone: insuranceProfile.other_relation_phone,
      card_front: insuranceProfile.card_front,
      card_back: insuranceProfile.card_back,
      copay: insuranceProfile.copay,
      deductible: insuranceProfile.deductible,
      co_insurance: insuranceProfile.co_insurance,
      secondary_copay: null,
      // other_relation address
      relation_address: insuranceProfile?.relation_address,
      other_relation_address_ln_1: '',
      other_relation_address_ln_2: '',
      other_relation_city: '',
      other_relation_state: null,
      other_relation_zip: '',
      // payer_address
      address_id: null,
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: null,
      zip: '',
      payer_phone: ''
    },
    enableReinitialize: true,
    validationSchema: InsuranceProfileValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const updatedInsuranceProfile = {
        practice_id: values.practice_id,
        payer_id: values.payer_id,
        payer_name: values.payer_name,
        type: values.type,
        member_id: values.member_id,
        group_number: values.group_number,
        plan_name: values.plan_name,
        prior_auth: values.prior_auth,
        policy_start_date: values.policy_start_date,
        policy_end_date: values.policy_end_date,
        relation: values.relation,
        ignore_warnings: values.ignore_warnings,
        other_relation_name: values.other_relation_name,
        other_relation_date: values.other_relation_date,
        other_relation_phone: values.other_relation_phone,
        card_front: values.card_front,
        card_back: values.card_back,
        copay: values.copay,
        deductible: values.deductible,
        co_insurance: values.co_insurance,
        secondary_copay: null,
        phone: values.phone,
        relation_address: parseInt(values.relation_address),
        address: {
          address_ln_1: values.other_relation_address_ln_1,
          address_ln_2: values.other_relation_address_ln_2,
          city: values.other_relation_city,
          state: values.other_relation_state,
          zip: values.other_relation_zip
        }
      };
      const payer = {
        payerAddress: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        phone: values.payer_phone
      };
      setSubmitting(true);
      await updateInsuranceProfile(updatedInsuranceProfile, payer, 'submit');
    }
  });

  const updateInsuranceProfile = async (updatedInsuranceProfile, payer, type) => {
    try {
      let res = null;
      if (updatedInsuranceProfile) {
        if (updatedInsuranceProfile.relation === '18') {
          delete updatedInsuranceProfile?.address;
          updatedInsuranceProfile = {
            ...updatedInsuranceProfile,
            other_relation_name: null,
            other_relation_date: null,
            other_relation_phone: null,
            relation_address: null
          };
        }

        res = await requestApi({
          url: '/api/insurance_profiles/update',
          params: {
            id: insuranceProfile.id,
            updatedObject: updatedInsuranceProfile
          },
          navigate
        });
        if (res.updated) {
          updateProfile({ ...updatedInsuranceProfile, id: insuranceProfile.id }, index);
          showAlert({ message: 'Insurance profile successfully updated!' });
        }
      }
      const hasAddress =
        payer.payerAddress?.address_ln_1 && payer.payerAddress?.zip && payer.payerAddress?.state;
      if (hasAddress || payer?.phone) {
        await requestApi({
          url: '/api/payers/update',
          params: {
            id: insuranceProfile.payer_id,
            updatedData: payer,
            updateAddress: hasAddress
          },
          navigate
        });
      }
      type === 'submit' && hideEditInsuranceProfile();
      return res;
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const handleSave = async (handle, type) => {
    setLoading(true);
    switch (type) {
      case 'card_front':
        formik.setFieldValue('card_front', handle);
        break;
      case 'card_back':
        formik.setFieldValue('card_back', handle);
        break;
      default:
        showAlert({ message: "There's been an error while uploading the image.", color: 'danger' });

        break;
    }
    setLoading(false);
  };

  const handleDelete = async (type) => {
    switch (type) {
      case 'card_front':
        setPhoto((prev) => ({ ...prev, [type]: null }));
        formik.setFieldValue('card_front', '');
        break;
      case 'card_back':
        setPhoto((prev) => ({ ...prev, [type]: null }));
        formik.setFieldValue('card_back', '');
        break;
      default:
        showAlert({ message: "There's been an error while uploading the image.", color: 'danger' });
        break;
    }
  };

  const getInsuranceCard = async (handle, type) => {
    try {
      const res = await requestApi({
        url: '/api/filestack/read',
        params: { handle, patientId: user.id },
        navigate
      });
      const { file } = res;

      if (!file)
        showAlert({ message: 'There was a problem retrieving the image.', color: 'danger' });

      setPhoto((prev) => ({ ...prev, [type]: file }));
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const insideContents = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        onKeyPress={handleEnterPreventDefault}
        className={!isTablet && 'grid gap-4'}>
        {!isTablet || showImages ? (
          <>
            <div className="mb-4 grid grid-cols-2 gap-2">
              <div
                onClick={() => setImageEdit('card_front')}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {formik.values.card_front ? (
                  <img
                    className="aspect-[5/3] w-full  overflow-hidden rounded-xl object-cover"
                    src={`https://cdn.filestackcontent.com/${formik.values.card_front}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>

              <div
                onClick={() => setImageEdit('card_back')}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {formik.values.card_back ? (
                  <img
                    className="aspect-[5/3] w-full  overflow-hidden rounded-xl object-cover"
                    src={`https://cdn.filestackcontent.com/${formik.values.card_back}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-center">
            <p
              onClick={() => setShowImages(true)}
              className=" mb-2 cursor-pointer py-2 text-primary-500">
              Show images
            </p>
          </div>
        )}

        <InsuranceForms
          states={states}
          payers={payers}
          formik={formik}
          kiosk={kiosk}
          getPayerAddress={getPayerAddress}
          isTablet={isTablet}
        />
      </form>
    );
  };

  return (
    <>
      {fakeModal ? (
        <div className="p-3">
          {insideContents()}
          <div className="flex w-full justify-end gap-2 p-4">
            <Button
              color="neutral"
              outlined
              text="Close"
              onClick={(e) => {
                e.stopPropagation();
                setFakeModal(null);
              }}
              data-qa="edit-back-btn"
            />
            <Button
              data-qa="create-insurance-btn"
              onClick={() => formik.submitForm()}
              forwardedRef={editInsuranceProfile}
              loading={loading}
              disabled={!formik.isValid}
              text="Update"
            />
          </div>
        </div>
      ) : (
        <Modal
          isOpen={true}
          handleClose={hideEditInsuranceProfile}
          className={isTablet ? 'h-full w-full !rounded-none' : 'w-[612px] !rounded-none'}
          title="Edit insurance profile"
          slideFromRight={!isTablet}
          isFooter={!isTablet}
          headButton={
            isTablet && (
              <div className="ml-auto mr-3 flex gap-2">
                <Button
                  data-qa="create-insurance-btn"
                  onClick={() => formik.submitForm()}
                  forwardedRef={editInsuranceProfile}
                  loading={loading}
                  disabled={!formik.isValid}
                  text={'Update'}
                />
              </div>
            )
          }
          footer={
            !isTablet && (
              <>
                {device !== 'mobile' && (
                  <Button
                    color="neutral"
                    outlined
                    onClick={hideEditInsuranceProfile}
                    text="Cancel"
                  />
                )}
                <Button
                  data-qa="create-insurance-btn"
                  onClick={() => formik.submitForm()}
                  forwardedRef={editInsuranceProfile}
                  loading={loading}
                  disabled={!formik.isValid}
                  text="Update"
                />
              </>
            )
          }>
          {insideContents()}
        </Modal>
      )}
      <Modal
        handleOpen={!!imageEdit}
        handleClose={() => setImageEdit(null)}
        className={
          isTablet
            ? 'h-full w-full !rounded-none bg-neutral-800'
            : 'w-[612px] !rounded-none bg-neutral-800'
        }
        title="Edit insurance profile"
        slideFromRight={!isTablet}
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {formik?.values[imageEdit] && !loading
              ? 'Looks good?'
              : 'Start with the front of the card'}
          </p>
          <p className="mt-2 px-16 text-white">
            {formik?.values[imageEdit] && !loading
              ? 'Make sure the picture is clear'
              : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
          </p>

          <ImagePicker
            label="Front of card"
            handle={formik?.values[imageEdit]}
            handleDelete={handleDelete}
            name={imageEdit}
            kiosk={kiosk}
            resetHandle={() => formik.setFieldValue(imageEdit, '')}
            handleContinue={() => setImageEdit(null)}
            onCapture={handleSave}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditInsuranceProfile;
