import React, { useEffect } from 'react';
import usePageTitle from 'lib/hooks/usePageTitle';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState as user_state } from 'components/state';
import { generatePracticeIQueueList } from './lib/generatePracticeIQueueList';
import SecondarySidebar from 'components/Sidebars/SecondarySidebar/SecondarySidebar';

const PracticeIQueue = () => {
  usePageTitle('PracticeIQueue');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const userState = useRecoilValue(user_state);
  const practiceIQueueList = generatePracticeIQueueList(userState);

  useEffect(() => {
    if (pathname === '/portal/practice-i-queue')
      navigate('/portal/practice-i-queue/overview', { replace: true });
  }, [pathname]);

  return (
    <div className="flex h-full overflow-hidden bg-neutral-50">
      <SecondarySidebar list={practiceIQueueList} target="practiceIQueue" title="PRACTICEIQUEUE" />
      <div className="flex w-full flex-col">
        <Outlet />
      </div>
    </div>
  );
};

export default PracticeIQueue;
