const { capitalize } = require('lodash');
const { ia, io, isEmpty } = require('../../../../../lib/helpers/utility');
const { textItalic, textBoldAndUnderline } = require('./markdownHelpers');

exports.formatSections = ({ fieldData, answerData, createdBy }) => {
  if (!ia(answerData)) return;

  const checkedParentObjects = [];
  const resultArray = [];

  fieldData?.forEach((field, index) => {
    const checkIfHasParagraph = field.element === 'Paragraph' ? field.content : '';
    const objectChecked = checkedParentObjects.includes(field.id);

    if (objectChecked) return;

    if (field.element === 'CustomElement')
      processCustomElement({ field, answerData, resultArray, paragraph: field.text });

    if (field.isContainer && ia(field.childItems)) {
      let paragraph = '';
      paragraph =
        fieldData[index - 1]?.element === 'Paragraph'
          ? extractTextFromHTML(fieldData[index - 1]?.content)
          : '';
      if (isEmpty(paragraph))
        paragraph =
          fieldData[index - 2]?.element === 'Paragraph'
            ? extractTextFromHTML(fieldData[index - 2]?.content)
            : '';
      processContainerElement({ field, fieldData, answerData, resultArray, paragraph });
    }

    if (['Tags'].includes(field.element) && !field.parentId)
      processTagsElement({ field, answerData, resultArray, paragraph: checkIfHasParagraph });

    if (field.element === 'HyperLink')
      processHyperLinkElement({ field, resultArray, paragraph: checkIfHasParagraph });

    if (isInputTypeElement(field.element) && !field.parentId)
      processInputTypeElement({ field, answerData, resultArray, paragraph: checkIfHasParagraph });

    if (isSelectTypeElement(field.element) && !field.parentId)
      processSelectTypeElement({ field, answerData, resultArray, paragraph: checkIfHasParagraph });

    checkedParentObjects.push(field.id);
  });

  const finalNarrative = objectToNarrative({ data: resultArray, createdBy });
  return finalNarrative;
};

const processCustomElement = ({ field, answerData, resultArray, paragraph }) => {
  const answerObject = answerData.find((answer) => answer.name === field.field_name);

  if (!io(answerObject?.value)) return;

  const isAnswerObjectString = typeof answerObject.value === 'string';

  resultArray.push({
    paragraph,
    answers: isAnswerObjectString ? JSON.parse(answerObject.value) : answerObject.value
  });
};

const processContainerElement = ({ field, fieldData, answerData, resultArray, paragraph }) => {
  const multipleAnswers = [];

  field.childItems.forEach((childId) => {
    const foundChildObject = fieldData.find((fieldObj) => fieldObj.id === childId);

    if (!foundChildObject) return;

    const answerObject = answerData.find((answer) => answer.name === foundChildObject.field_name);

    if (!answerObject) return;

    if (isInputTypeElement(foundChildObject.element) && answerObject.value) {
      const textFromHtml = extractTextFromHTML(foundChildObject.label);

      if (isEmpty(textFromHtml)) return;

      const label = extractTextFromHTML(foundChildObject.label);
      multipleAnswers.push({ question: label, answer: answerObject.value });
    }

    if (
      isSelectTypeElement(foundChildObject.element) &&
      (ia(answerObject.value) || !isEmpty(answerObject.value))
    ) {
      let optionAnswers = '';

      if (typeof answerObject?.value === 'string') {
        const findCheckedOption = foundChildObject.options.find(
          (option) => option.value === answerObject.value
        );

        if (findCheckedOption) optionAnswers = findCheckedOption.text;
      } else if (ia(answerObject?.value)) {
        optionAnswers = answerObject.value
          .map((value) => {
            const findCheckedOption = foundChildObject.options.find(
              (option) => option.key === value
            ).text;

            if (findCheckedOption) return findCheckedOption;
          })
          .filter(Boolean);
      }

      if (isEmpty(optionAnswers)) return;

      multipleAnswers.push([{ question: foundChildObject?.label, answer: optionAnswers }]);
    }
  });

  ia(multipleAnswers) && resultArray.push({ paragraph, answers: multipleAnswers });
};
const extractTextFromHTML = (html) => {
  const htmlRegex = /<[^>]*>([^<]+)<\/[^>]*>/;

  if (!htmlRegex) return html;

  const div = document.createElement('div');
  div.innerHTML = html;

  if (isEmpty(div.textContent) && isEmpty(div.innerText)) return;
  return div?.innerText || div?.textContent || div?.outerText || '';
};

const processTagsElement = ({ field, answerData, resultArray, paragraph }) => {
  const answerObject = answerData.find((answer) => answer.name === field.field_name);

  if (ia(answerObject.value)) {
    resultArray.push({
      paragraph,
      answers: { question: field.label, answer: answerObject.value.map((tag) => tag.text) }
    });
  }
};

const processHyperLinkElement = ({ field, resultArray, paragraph }) => {
  resultArray.push({ paragraph, answers: { question: field.text, answer: field.href } });
};

const processInputTypeElement = ({ field, answerData, resultArray, paragraph }) => {
  const answerObject = answerData.find((answer) => answer.name === field.field_name);

  if (!answerObject) return;

  const textFromHtml = extractTextFromHTML(field.label);

  if (isEmpty(textFromHtml)) return;

  const label = extractTextFromHTML(field.label);

  resultArray.push({ paragraph, answers: { question: label, answer: answerObject.value } });
};

const isInputTypeElement = (element) => {
  return ['DatePicker', 'TextInput', 'Range', 'Rating', 'TextArea'].includes(element);
};

const processSelectTypeElement = ({ field, answerData, resultArray, paragraph }) => {
  const result = [];
  answerData.forEach((item) => {
    if (typeof item.value === 'string') {
      const findCheckedOption = field.options.find((option) => option.key === item.value);

      if (findCheckedOption) result.push(findCheckedOption);
    } else if (ia(item.value)) {
      item.value.forEach((value) => {
        const findCheckedOption = field.options.find((option) => option.key === value)?.text;

        if (findCheckedOption) result.push(findCheckedOption);
      });
    }
  });

  const label = extractTextFromHTML(field.label);

  ia(result) &&
    resultArray.push({
      paragraph,
      answers: [{ ...(label && { question: label }), answer: result.join(', ') }]
    });
};

const isSelectTypeElement = (element) => {
  return ['Checkboxes', 'RadioButtons', 'Dropdown'].includes(element);
};

const objectToNarrative = ({ data = [], createdBy = {} }) => {
  let narrativeText = '';

  const { currentUser, responseCreator } = createdBy;

  if (currentUser && responseCreator) {
    narrativeText += `${
      currentUser === responseCreator
        ? textItalic({ text: '' })
        : textItalic({ text: 'Patient reports: \n\n' })
    }`;
  }

  data.forEach((item, index) => {
    if (checkIfCustomForm(item.paragraph)) {
      if (item?.answers?.noDetails) {
        narrativeText += textBoldAndUnderline({
          text: `No notable ${item?.paragraph?.toLowerCase()}\n\n`
        });
        return;
      }

      if (isEmpty(item?.answers?.narrative)) return;

      if (!isEmpty(item.paragraph)) {
        narrativeText += `${textBoldAndUnderline({ text: item.paragraph })}: \n`;
      }

      const cleanedString = item.answers.narrative?.replace(/\n+/g, '\n');
      narrativeText += cleanedString + '\n';
    } else {
      let paragraph = '';
      let body = '';

      if (item.paragraph) paragraph = textBoldAndUnderline({ text: item.paragraph }) + '\n';

      Object.keys(item?.answers).forEach((key) => {
        const answer = item.answers[key];

        if (Array.isArray(answer)) {
          answer.forEach((subItem) => {
            if (typeof subItem === 'object') {
              Object.keys(subItem).forEach((subKey) => {
                const answerLabel = subItem[subKey]?.value || subItem[subKey];

                if (subKey === 'question') {
                  body += `${textBoldAndUnderline({ text: `${answerLabel}` })}`;
                } else {
                  body += `${answerLabel}\n`;
                }
              });
            } else {
              body += `${subItem}\n`;
            }
          });
        } else if (typeof answer === 'object') {
          delete answer?.id;
          Object.keys(answer).forEach((subKey) => {
            if (answer[subKey]) {
              const answerLabel = answer[subKey]?.value || answer[subKey];

              if (subKey === 'question') {
                body += `${textBoldAndUnderline({ text: `${answerLabel}\n` })}`;
              } else {
                body += `${answerLabel}\n`;
              }
            }
          });
        } else {
          const question = data[index]?.answers?.question;

          if (!isEmpty(answer) && !key.includes('question')) {
            body += `${textBoldAndUnderline({ text: question })}\n ${answer}\n`;
          }
        }
      });

      if (!isEmpty(body)) narrativeText += `${textBoldAndUnderline({ text: paragraph })} ${body}`;
    }

    if (index < data.length - 1) {
      narrativeText += '\n';
    }
  });
  return narrativeText.replace(/\n\n\n+/g, '\n');
};

const checkIfCustomForm = (currentValue) => {
  return [
    'Family History',
    'Social History',
    'Surgical History',
    'Review of Systems',
    'Allergies',
    'Vaccines',
    'Current Medications',
    'History of present illness',
    'Order and Result',
    'Past medical history'
  ].includes(currentValue);
};
