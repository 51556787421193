import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { ia, optionify } from '../../../lib/helpers/utility';
import state from '../../state';
import Loading from '../Loading/Loading';
import StaffEntry from './StaffEntry';
import StaffMember from './StaffMember';
import StaffTable from './StaffTable';
import SupervisorSettings from './SupervisorSettings';

const LIMIT = 25;

const Staff = () => {
  const [show, setShow] = useState({
    loading: false,
    canLoadMore: true,
    selectedStaff: null,
    supervisorSettings: null
  });
  const [staff, setStaff] = useState([]);
  const [roles, setRoles] = useRecoilState(state.roles);
  const [roleOptions, setRoleOptions] = useRecoilState(state.roleOptions);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (roles.practiceId !== id) {
      loadRoles();
    }
    getData();
  }, []);

  const loadRoles = async () => {
    let newShow = Object.assign({}, show);
    try {
      const res = await interimApi(
        '/api/practice/role/get',
        {
          practiceId: id,
          limit: 100,
          offset: 0
        },
        navigate
      );
      const { code, redirect, error, role_list: loadedRoles } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          const newRoleOptions = optionify(loadedRoles);
          setRoles({ practiceId: id, data: loadedRoles });
          setRoleOptions(newRoleOptions);
          break;
        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`loadRoles: ${error}`);
          } else Honeybadger.notify(`loadRoles: unexpected error`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`loadRoles: unexpected error ${error}`);
    }
    setShow(newShow);
  };

  const getData = async () => {
    let newShow = Object.assign({}, show);
    setShow({ ...show, loading: true });
    try {
      const res = await interimApi(
        '/api/practice/member/get',
        {
          practiceId: id,
          offset: staff.length,
          limit: LIMIT
        },
        navigate
      );
      const { code, redirect, error, members: loadedStaff } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (Array.isArray(loadedStaff)) {
            setStaff([...staff, ...loadedStaff]);
            newShow.canLoadMore = loadedStaff.length >= LIMIT;
          }
          break;
        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`getData: ${error}`);
          } else Honeybadger.notify(`getData staff: unexpected error`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`getData staff: unexpected error ${error}`);
    }
    setShow(newShow);
  };

  const onEditHandler = (e) => {
    // handle editing handler
    const targetId = parseInt(e.target.id);
    const staffMemberIndex = staff.findIndex((v) => v.user_id === targetId);
    if (staffMemberIndex !== -1) {
      setShow({ ...show, selectedStaff: staff[staffMemberIndex] });
    } else {
      Honeybadger.notify(`onEditHandler unexpected error`);
    }
  };

  const saveHandler = (staffMember) => {
    const newStaff = staff.map((v) => {
      if (v.id === staffMember.id) {
        return { ...v, ...staffMember };
      } else {
        return v;
      }
    });
    setStaff(newStaff);
    setShow({ ...show, selectedStaff: null });
    toast.success(
      `Updated ${staffMember.f_name} ${staffMember.m_name ? `${staffMember.m_name} ` : ''}${
        staffMember.l_name
      }`
    );
  };

  const hideSupervisorSettings = () =>
    setShow((prevState) => ({ ...prevState, supervisorSettings: null }));

  const showSupervisorSettings = (member) =>
    setShow((prevState) => ({ ...prevState, supervisorSettings: member }));

  return (
    <>
      {show.loading ? (
        <Loading />
      ) : (
        <div className="h-fit min-h-[30vh] p-[1rem]">
          <StaffTable>
            {ia(staff) &&
              staff.map((v, i) => (
                <StaffEntry
                  index={i}
                  practiceId={id}
                  key={`staff-index-staff-entry-index-${i}`}
                  member={v}
                  onEdit={onEditHandler}
                  showSupervisorSettings={showSupervisorSettings}
                />
              ))}
          </StaffTable>
          {show.newStaff && <StaffMember />}
          {show.selectedStaff && (
            <StaffMember
              practiceId={id}
              member={show.selectedStaff}
              handleOpen={Boolean(show.selectedStaff)}
              onClose={() => setShow({ ...show, selectedStaff: null })}
              onSave={saveHandler}
            />
          )}
          {show.selectedStaff && (
            <StaffMember
              practiceId={id}
              member={show.selectedStaff}
              handleOpen={Boolean(show.selectedStaff)}
              onClose={() => setShow({ ...show, selectedStaff: null })}
              onSave={saveHandler}
            />
          )}
          {!!show.supervisorSettings && (
            <SupervisorSettings
              member={show.supervisorSettings}
              showModal={!!show.supervisorSettings}
              hideModal={hideSupervisorSettings}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Staff;
