import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';
import { formatDate, iaRa } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import SuperbillActions from '../components/SuperbillActions';

export default function (timezone) {
  return [
    { field: 'id', headerName: 'Superbill ID' },
    {
      field: 'practitioner.fullName',
      headerName: 'Rend. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'claim.bill_name',
      headerName: 'Bill. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'claim.payer_name',
      headerName: 'Payer',
      valueFormatter: ({ value }) => capitalize(value),
      sortable: false
    },
    {
      field: 'encounter.appointment.starts_at',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, timezone, true),
      sortable: false
    },
    {
      field: 'created_at',
      headerName: 'Superbill date',
      valueFormatter: ({ value }) => formatDate(value, timezone, true)
    },
    {
      field: 'services',
      headerName: 'Services',
      valueGetter({ data }) {
        const procedures = iaRa(data?.encounter?.appointment?.invoice?.procedures)
          .filter(filterSuperbillClaimProcedure)
          ?.map((item) => item.procedure_code)
          ?.join(', ');

        return procedures;
      },
      sortable: false
    },
    {
      field: 'claim',
      headerName: 'Claim',
      valueFormatter: ({ data }) => capitalize(data.claim?.id),
      sortable: false
    },
    {
      field: 'state',
      headerName: 'Status',
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: SuperbillActions,
      maxWidth: 100
    }
  ];
}
