import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import toast from 'react-hot-toast';
import { ia } from '../../../lib/helpers/utility';

export const AlertContent = ({
  icon = 'new-info',
  iconShade = 500,
  color = 'primary',
  title,
  message,
  buttons = [],
  customButtons,
  filled = true,
  width = 420,
  handleClose,
  className: wrapperClassName = ''
}) => {
  if (
    !['white', 'primary', 'secondary', 'success', 'danger', 'warning', 'neutral'].includes(color)
  ) {
    color = 'primary';
  }

  const getClassName = (property, level) => {
    let className;

    switch (color) {
      case 'white':
        className = `${property}-neutral-${level}`;
        break;
      case 'primary':
      case 'secondary':
      case 'success':
      case 'danger':
      case 'warning':
      case 'neutral':
        className = `${property}-${color}-${level}`;
        break;
      default:
        className = '';
    }

    return className;
  };

  return (
    <div
      className={cs(
        `flex overflow-hidden  rounded-md !border !border-solid `,
        color === 'secondary'
          ? '!border-secondary-500'
          : color === 'success'
            ? '!border-success-500'
            : color === 'white' || color === 'neutral'
              ? 'border-neutral-500'
              : color === 'warning'
                ? 'border-warning-500'
                : color === 'danger'
                  ? 'border-danger-500'
                  : `!border-${color}-500`,
        color === 'white' || !filled ? 'bg-white' : `bg-${color}-50`,
        wrapperClassName
      )}
      style={{ width: width === 'full' ? '100%' : `${width}px` }}>
      <div
        className={cs(
          'h-auto w-[6px]',
          color === 'white' || color === 'neutral' ? 'bg-neutral-200' : `bg-${color}-500`
        )}
      />
      <div className="flex flex-grow gap-2 !px-4 !py-3">
        {icon && (
          <div className="flex h-5 w-5 items-center justify-center rounded">
            <Icon icon={icon} color={color === 'white' ? 'neutral' : color} shade={iconShade} />
          </div>
        )}
        <div className="flex flex-grow flex-col gap-2">
          {title && (
            <label className={cs('!m-0 text-sm !font-500', getClassName('text', '700'))}>
              {title}
            </label>
          )}
          {message && (
            <label className={cs('m-0 text-sm !font-400', getClassName('text', '600'))}>
              {message}
            </label>
          )}
          {(ia(customButtons) || ia(buttons)) && (
            <div className="mt-[2px] flex gap-4">
              {ia(customButtons)
                ? customButtons.slice(0, 3).map((btn) => btn)
                : buttons
                    .slice(0, 3)
                    .map((button, index) => (
                      <Button
                        data-qa={`alert-action-${message || title}`}
                        key={index}
                        text={button.text}
                        onClick={button.onClick}
                        outlined
                        transparent
                        size={button.size || 'sm'}
                        color={button.color || 'primary'}
                        icon={button.icon}
                      />
                    ))}
            </div>
          )}
        </div>
        {handleClose && (
          <div
            className={cs(
              'rounded-smooth flex h-5 min-h-[20px] w-5 min-w-[20px] cursor-pointer items-center justify-center',
              color === 'white' ? 'bg-neutral-100' : `bg-${color}-100`
            )}
            onClick={handleClose}>
            <Icon
              stroke
              color={color === 'white' ? 'neutral' : color}
              icon="new-close-sm"
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const showAlert = ({
  icon,
  color,
  title,
  message,
  buttons,
  customButtons,
  filled,
  position = 'top-right',
  duration
}) => {
  toast.custom(
    (t) => (
      <AlertContent
        icon={icon}
        color={color}
        title={title}
        filled={filled}
        message={message}
        buttons={buttons}
        customButtons={customButtons}
        handleClose={() => toast.remove(t?.id)}
      />
    ),
    { position, duration }
  );
};
