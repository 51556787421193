import moment from 'moment';
import { ia } from '../lib/helpers/utility';
import { requestApi } from './Api';

const getAppointments = async (defaultParams) => {
  try {
    const {
      currentView,
      defaultDate,
      filters,
      getDates,
      navigate,
      rangeDate,
      selectedView,
      setFilters,
      withEvents,
      isInitial,
      withalRest,
      limit,
      sort,
      page,
      pagination
    } = defaultParams;

    let params = {
      startDate: moment(defaultDate).startOf('day'),
      endDate: moment(defaultDate).endOf('day'),
      withEvents,
      filters,
      isInitial,
      withal: {
        patient: true,
        practice: true,
        practitionerPractice: true,
        pendingCount: true,
        resources: true,
        ...withalRest
      },
      limit,
      sort,
      page,
      pagination
    };

    if (filters?.selectedView) {
      getDates(null, filters?.selectedView);
      setFilters((prevState) => ({ ...prevState, selectedView: undefined }));
    }

    if (selectedView == 'agenda' || currentView == 'agenda') {
      params = {
        ...params,
        startDate: moment.utc(defaultDate).startOf('day'),
        endDate: moment.utc(defaultDate).endOf('day')
      };
    }

    if (currentView === 'week' || currentView === 'month') {
      const by = currentView === 'week' ? 'isoWeek' : 'month';

      params = {
        ...params,
        startDate: moment.utc(defaultDate).startOf(by),
        endDate: moment.utc(defaultDate).endOf(by)
      };
    }

    if (rangeDate?.startDate && rangeDate?.endDate) {
      params = {
        ...params,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate
      };
    }
    ``;

    if (ia(filters?.appointmentTags)) {
      params = {
        ...params,
        appointmentTags: filters?.appointmentTags
      };
    }

    if (ia(filters?.appointmentType)) {
      params = {
        ...params,
        appointmentType: filters?.appointmentType
      };
    }

    if (ia(filters?.serviceType)) {
      params = {
        ...params,
        serviceType: filters?.serviceType
      };
    }

    if (ia(filters?.patient)) {
      params = { ...params, patientIds: filters?.patient };
    }

    if (ia(filters?.practitioners)) {
      params = { ...params, practitionerIds: filters?.practitioners };
    }

    if (ia(filters?.resources)) {
      params = { ...params, resourceIds: filters?.resources };
    }

    if (ia(filters?.appointmentStatus)) {
      params = { ...params, appointmentStatus: filters?.appointmentStatus };
    }

    if (ia(filters?.appointmentConfirmState)) {
      params = { ...params, appointmentConfirmState: filters?.appointmentConfirmState };
    }

    if (ia(filters?.clinicalNoteStatus)) {
      params = { ...params, clinicalNoteStatus: filters?.clinicalNoteStatus };
    }

    if (ia(filters?.superbillStatus)) {
      params = { ...params, superbillStatus: filters?.superbillStatus };
    }

    if (ia(filters?.claimState)) {
      params = { ...params, claimState: filters?.claimState };
    }

    return await requestApi({ url: '/api/appointment/get_multiple', params, navigate });
  } catch (error) {
    console.log('appointment error', error);
  }
};

const getAppointment = async (navigate, params, signal) => {
  if (params?.type !== 'event') {
    return requestApi({ url: '/api/appointment/get_one', navigate, params, signal });
  } else {
    return null;
  }
};

const creationActionAppointment = async (navigate, params, others) =>
  requestApi({
    url: '/api/appointment/action/creation',
    navigate,
    params,
    ...others
  });

const remindAppointment = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/reminder', navigate, params, ...others });

const cancelAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/cancel',
    navigate,
    params
  });

const acceptAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/accept',
    navigate,
    params
  });

const remindAppointmentMultiple = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/reminder_multiple', navigate, params, ...others });

const creationActionAppointmentMultiple = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/creation_multiple', navigate, params, ...others });

const cancelMultipleAppointments = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/cancel_multiple',
    navigate,
    params
  });

export {
  acceptAppointment,
  cancelAppointment,
  creationActionAppointment,
  getAppointment,
  getAppointments,
  remindAppointment,
  remindAppointmentMultiple,
  creationActionAppointmentMultiple,
  cancelMultipleAppointments
};
