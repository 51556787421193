import React, { useCallback, useMemo, useState } from 'react';
import { PatientBalanceContextApi, PatientBalanceContextData } from './PatientBalanceContext';

export const PatientBalanceContextProvider = ({ children }) => {
  const [form, setForm] = useState();
  const [allocating, setAllocating] = useState({});
  const [mode, setMode] = useState({ writeoff: false, allocate: false });
  const [tabIndex, setTabIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const finalData = useMemo(
    () => ({
      formData: form,
      allocatingRow: allocating,
      activeMode: mode,
      activeTab: tabIndex,
      isSubmitButtonDisabled: isButtonDisabled
    }),
    [form, allocating, mode, tabIndex, isButtonDisabled]
  );

  const setActiveMode = useCallback(setMode, [setMode]);
  const setFormData = useCallback(setForm, [setForm]);
  const setAllocatingRow = useCallback(setAllocating, [setAllocating]);
  const setActiveTab = useCallback(setTabIndex, [setTabIndex]);
  const setIsSubmitButtonDisabled = useCallback(setIsButtonDisabled, [setIsButtonDisabled]);
  const api = useMemo(
    () => ({
      setFormData,
      setActiveMode,
      setAllocatingRow,
      setActiveTab,
      setIsSubmitButtonDisabled
    }),
    [setActiveTab, setAllocatingRow, setFormData, setActiveMode, setIsSubmitButtonDisabled]
  );

  return (
    <PatientBalanceContextData.Provider value={finalData}>
      <PatientBalanceContextApi.Provider value={api}>{children}</PatientBalanceContextApi.Provider>
    </PatientBalanceContextData.Provider>
  );
};
