import React from 'react';
import ClinicalSummaryReview from './components/ClinicalSummaryReview';
import ContactPreview from './components/ContactPreview';
import DemographicsPreview from './components/DemographicsPreview';
import InstructionsPreview from './components/InstructionsPreview';
import InsurancePreview from './components/InsurancePreview';
import YourSignaturePreview from './components/YourSignaturePreview';

const OrderReferralPreview = ({ itemProps }) => {
  const showClinicalSummary = Object.entries(itemProps?.clinical_summary || {})?.some(
    ([_, value]) => value?.checked
  );

  return (
    <div className="!mx-6">
      <div className="flex justify-between !gap-3">
        <ContactPreview fromProvider itemProps={itemProps} />
        <ContactPreview toProvider itemProps={itemProps} />
      </div>

      <div className="!mt-6">
        <InstructionsPreview itemProps={itemProps} />
      </div>

      {itemProps?.demographics && (
        <div className="!mt-6">
          <DemographicsPreview itemProps={itemProps} />
        </div>
      )}

      {itemProps?.insurance_info && (
        <div className="!mt-6">
          <InsurancePreview itemProps={itemProps} />
        </div>
      )}

      {showClinicalSummary && (
        <div className="!mt-6">
          <ClinicalSummaryReview itemProps={itemProps} />
        </div>
      )}

      <div className="!mt-6">
        <YourSignaturePreview itemProps={itemProps} />
      </div>
    </div>
  );
};

export default OrderReferralPreview;
