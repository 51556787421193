import { useEffect } from 'react';

const useAutosizeElement = (elementRef, value) => {
  useEffect(() => {
    elementRef = elementRef?.current;

    if (value?.length > 0 && elementRef) {
      if (elementRef.style) {
        elementRef.style.height = '';
      }
    }
    if (elementRef && elementRef.style) {
      elementRef.style.height = '';
      const scrollHeight = elementRef.scrollHeight;
      elementRef.style.height = scrollHeight + 'px';
    }
  }, [elementRef, value]);
};

export default useAutosizeElement;
