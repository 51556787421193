import getPlaceOfService from './getPlaceOfService';

export default function getInitialProcedure(practice) {
  return [
    {
      code: '',
      modifiers: [],
      charge: 0,
      diagnosis: [],
      from_date: new Date(),
      thru_date: new Date(),
      narrative: '',
      units: '',
      place_of_service: getPlaceOfService({}, practice)
    }
  ];
}
