import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';
import { FunctionalImpairment as initialValue } from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from '../../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from '../../../../../../../shared/Icon/Icon';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { userState } from '../../../../../../../state';

const FunctionalImpairment = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote, cnDisplaySettings } = useClinicalNoteContext();
  const [timer, setTimer] = useState(null);
  const [functionalImpairment, setFunctionalImpairment] = useState([]);
  const otherNoteRef = useRef();

  const [syncNarrative, setSyncNarrative] = useState(true);
  const user = useRecoilValue(userState);
  const isAdvancedForm = cnDisplaySettings
    ? cnDisplaySettings?.sections?.progress?.functionalImpairment?.advancedForm
    : true;
  const textAreaRef = useRef();

  useEffect(() => {
    setFunctionalImpairment(clinicalNote?.epn?.functionalImpairment || initialValue);
  }, [clinicalNote]);

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { functionalImpairment } }
  }));

  const syncFunctionalImpairmentAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ functionalImpairmentObject: updatedObject });
    } else {
      setFunctionalImpairment(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleChange = (e, key) => {
    const { name, value } = e.target;

    const updatedObject = {
      ...functionalImpairment,
      [key ?? name]: key ? value : !functionalImpairment[name]
    };

    syncFunctionalImpairmentAndClinicalNotes(updatedObject);
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      epn: {
        ...prevState.epn,
        functionalImpairment: updatedObject
      }
    }));
  };

  const handleNarrative = ({
    functionalImpairmentObject = functionalImpairment,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...functionalImpairmentObject, narrative: event };

    setFunctionalImpairment(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    functionalImpairmentObject = functionalImpairment,
    scrollable = false
  } = {}) => {
    const narrativeOptions = {
      data: functionalImpairmentObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'functional impairment'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      functionalImpairmentObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <React.Fragment>
      {isAdvancedForm && (
        <div>
          <p
            className="!pb-3 text-sm font-500
         text-neutral-800">
            Functional impairment
          </p>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 ">
            <Checkbox
              label="Self care"
              name="selfCare"
              isChecked={functionalImpairment.selfCare}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="Relationships"
              name="relationships"
              isChecked={functionalImpairment.relationships}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="School or work"
              name="schoolOrWork"
              isChecked={functionalImpairment.schoolOrWork}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="General tasks/demands"
              name="generalTasksDemands"
              isChecked={functionalImpairment.generalTasksDemands}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="Community participation"
              name="communityParticipation"
              isChecked={functionalImpairment.communityParticipation}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="None"
              name="none"
              isChecked={functionalImpairment.none}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <div className="grid gap-y-2">
              <Checkbox
                label="Other"
                name="other"
                isChecked={functionalImpairment.other}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {functionalImpairment.other && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  value={functionalImpairment.otherNote}
                  transcribing
                  forwardedRef={otherNoteRef}
                  onChange={(event) => handleChange(event, 'otherNote')}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {cnDisplaySettings && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setFunctionalImpairment}
            restData={{
              className: 'w-full',
              label: 'Functional Impairment Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-functional-impairment',
              'data-qa': 'narrative-functional-impairment',
              name: 'Narrative-functional-impairment',
              value: functionalImpairment?.narrative,
              forwardedRef: textAreaRef,
              formName: 'functionalImpairment'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FunctionalImpairment;
