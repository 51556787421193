import React from 'react';
import Tippy from '@tippyjs/react';
import { Popover } from '@headlessui/react';

import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Button from 'components/shared/Buttons/Button';
import DateRangePicker from 'components/shared/DateRange/DateRangePicker';
import { useEraContext } from 'lib/context/EraContext/EraContext';

const Filters = ({ setNewEOB, refresh }) => {
  const { filters, setFilters } = useEraContext();
  const searchTypes = [
    {
      value: 'Patient',
      label: 'Patient'
    },
    {
      value: 'ERA/EOB',
      label: 'ERA/EOB'
    },
    {
      value: 'Claim',
      label: 'Claim'
    },
    {
      value: 'Payer',
      label: 'Payer'
    },
    {
      value: 'Provider',
      label: 'Provider'
    },
    {
      value: 'Check/Trace',
      label: 'Check/Trace'
    }
  ];

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 !pb-2">
      <DateRangePicker
        startDate={filters?.startDate}
        endDate={filters?.endDate}
        onChange={(e) => {
          setFilters((prev) => ({
            ...prev,
            page: 1,
            startDate: e.selection.startDate,
            endDate: e.selection.endDate
          }));
        }}
        ranges={[
          {
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            key: 'selection'
          }
        ]}
        customStyling={{ right: '-506px' }}
        minDate={-900}
      />
      <Popover className="relative">
        {({ close, open }) => (
          <>
            <Popover.Button className="relative flex cursor-pointer items-center justify-center gap-[6px] rounded-md bg-white p-[8px] shadow-md">
              <label htmlFor="" className="!m-0 cursor-pointer text-neutral-500 ">
                Show
              </label>

              <label htmlFor="" className="!m-0 cursor-pointer font-500 text-primary-900">
                {filters.limit}
              </label>
              <Icon
                icon="new-chevron-down-sm"
                className={`cursor-pointer ${open && 'rotate-180'}`}
              />
            </Popover.Button>

            <Popover.Panel className="absolute right-0 z-10 mt-1 flex shadow-md">
              <div className="rounded-md bg-white py-[14px] shadow-md">
                {[25, 50, 100].map((item) => (
                  <div
                    key={item}
                    className="flex h-[30px] w-[50px] cursor-pointer items-center justify-center text-primary-900 hover:bg-primary-50"
                    onClick={() => {
                      setFilters({ ...filters, limit: item });
                      close();
                    }}>
                    {item}
                  </div>
                ))}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      <div className="relative">
        <Input
          data-qa="searchFilter"
          type="string"
          placeholder={`Search ${filters.searchType}...`}
          value={filters.searchTerm}
          icon="new-search"
          className={` !rounded-2xl !border-secondary-400 md:w-[200px] `}
          onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          units={searchTypes}
          currentUnit={filters.searchType}
          onUnitChange={(type) => setFilters({ ...filters, searchType: type, page: 1 })}
          rightText={
            filters.searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setFilters({ ...filters, searchTerm: '', page: 1 });
                }}
              />
            )
          }
        />
      </div>

      <Tippy content="Refresh" className="tippy-dark">
        <div className="p-[8px]">
          <Icon icon="new-reset" onClick={() => refresh?.mutate()} />
        </div>
      </Tippy>

      <Button
        text="New EOB"
        icon="new-claim"
        onClick={() => setNewEOB(true)}
        type="primary"
        outlined={true}
        data-qa="new-eob-btn"
      />
    </div>
  );
};
export default Filters;
