import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { useNavigate } from 'react-router-dom';

const ActivityModal = ({ isOpen, onAfterOpen, onScroll, handleClose, children }) => {
  const navigate = useNavigate();
  const showAllLogs = () => {
    navigate('/portal/audit/logs');
    handleClose();
  };

  return (
    <Modal
      slideFromRight
      bodyClassName="!p-4 bg-white"
      id="ActivityModal"
      className="w-[450px]"
      title="User Activity"
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      handleClose={handleClose}
      portalClassName="bg-primary-50"
      isFooter={false}
      onScroll={onScroll}
      headerCloseIcon={false}
      headButton={
        <Button
          className="ml-auto"
          outlined
          text="Show all logs"
          color="neutral"
          onClick={showAllLogs}
        />
      }
      headerClassName="flex justify-end">
      {children}
    </Modal>
  );
};

export default ActivityModal;
