import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, ia, io, snakeToTitleCase } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
const Practices = (props) => {
  const { dashboardData, item, layout } = props;

  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const practiceCountData = {
    new: dashboardData?.thisPeriodAndTotalCount?.periodPracticeCount,
    total: dashboardData?.thisPeriodAndTotalCount?.totalPracticeCount
  };

  const formattedData = dashboardData?.practicesGraphData
    ? Object.entries(dashboardData?.practicesGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const localDate = new Date(momentTime.valueOf());
        return {
          timestamp: localDate,
          inactive: parseFloat(data.inactive),
          active: parseFloat(data.active),
          pending: parseFloat(data.pending)
        };
      })
    : null;

  function getColorForStatus(status) {
    switch (status) {
      case 'active':
        return '#00ABE8';
      case 'inactive':
        return '#DC2626';
      case 'pending':
        return '#F59E0B';
      default:
        return '#000000';
    }
  }

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData || [],
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },
    series:
      dashboardData?.count?.practices?.map?.((item) => ({
        type: 'line',
        xKey: 'timestamp',
        yKey: item.status === 'inactive' ? 'inactive' : item.status,
        yName: `${snakeToTitleCase(item.status)} (${formatLargeNumber(item.count)})`,
        stroke: getColorForStatus(item.status),
        marker: {
          size: 6,
          fill: getColorForStatus(item.status),
          stroke: getColorForStatus(item.status)
        }
      })) || [],
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  if (!io(dashboardData?.practicesGraphData) || !ia(dashboardData?.count.practices)) {
    return (
      <>
        <NotFound
          icon="new-illustration-3"
          iconClassName="!pb-5"
          title="No practices report for this period!"
          description="You can try a different period"
        />
      </>
    );
  }

  return (
    <div className="!px-5 !py-[10px]">
      <div className="flex h-full items-center justify-between">
        <p className="pb-3 text-lg font-500 leading-6 text-primary-900">
          {`This Period: ${formatLargeNumber(practiceCountData.new)} Practices`}
        </p>
        <p className="pb-3 text-lg font-500 leading-6 text-primary-900">
          {`Total: ${formatLargeNumber(practiceCountData.total)} Practices`}
        </p>
      </div>
      <AGChart options={options} key={chartKey} />
    </div>
  );
};

export default Practices;
