import React, { useState, createRef, useEffect, useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import Button from '../../../../../../../shared/Buttons/Button';
import Box from '../../../../shared/Box';
import Icon from '../../../../../../../shared/Icon/Icon';
import Input from '../../../../../../../shared/Input/Input';
import { ia, iaRa, isEmpty } from '../../../../../../../../lib/helpers/utility';
import {
  Goals as initialValue,
  GoalsNarrative
} from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import {
  formatSections,
  mergeGoalNarratives
} from '../../../../../../../shared/Forms/Custom/lib/goalsFormatFormHelper';
import { userState } from '../../../../../../../state';
import { useRecoilValue } from 'recoil';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';

const Goals = ({
  section,
  isNarrative = true,
  isAdvancedForm = null,
  title,
  isTPlan,
  sectionRef = null
}) => {
  const {
    clinicalNote,
    setClinicalNote,
    cnDisplaySettings,
    selectedTreatmentPlan,
    setSelectedTreatmentPlan
  } = useClinicalNoteContext() || {};
  const [goals, setGoals] = useState();
  const [timer, setTimer] = useState(null);
  const [elRefs, setElRefs] = useState([]);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [narrative, setNarrative] = useState(
    clinicalNote?.goals_narrative ? clinicalNote?.goals_narrative : GoalsNarrative.narrative
  );
  const user = useRecoilValue(userState);
  const isAdvForm = isAdvancedForm ?? cnDisplaySettings?.sections?.[section]?.goals?.advancedForm;
  const textAreaRef = useRef();

  useEffect(() => {
    if (isTPlan) {
      setGoals(selectedTreatmentPlan.goals || initialValue);
    } else {
      setGoals(ia(clinicalNote?.goals) ? clinicalNote?.goals : initialValue);
    }
  }, [selectedTreatmentPlan, clinicalNote]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(goals?.length)
        .fill()
        ?.map((_, i) => elRefs[i] || createRef())
    );
  }, [goals]);

  useEffect(() => {
    if (isEmpty(narrative)) initializeNarrative({});
  }, [narrative]);

  useImperativeHandle(sectionRef, () => ({
    formData: { goals }
  }));

  const initializeNarrative = ({ goalsData = goals }) => {
    if (!syncNarrative) return;

    const { narrative } = mergeGoalNarratives(goalsData);
    setNarrative(narrative.trim());
  };

  const syncGoalsAndClinicalNotes = (updatedGoals) => {
    if (syncNarrative) {
      generateNarrative({ goalsObject: updatedGoals });
    } else {
      setGoals(updatedGoals);

      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        if (isTPlan) {
          setSelectedTreatmentPlan({ ...selectedTreatmentPlan, goals: updatedGoals });
        } else {
          setClinicalNote({ ...clinicalNote, goals: updatedGoals });
        }
      }, 500);

      setTimer(newTimer);
    }
  };

  const preventDefaultCheck = (event = null) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
  };

  const handleChange = ({ event, key, goalIndex, objectiveIndex, treatmentIndex }) => {
    preventDefaultCheck(event);

    const updatedGoals = [...goals];

    const goal = updatedGoals[goalIndex];
    if (goal) {
      if (key === 'goal') {
        goal.goal = event.target.value;
      } else if (key === 'objective') {
        const objective = goal.objectives[objectiveIndex];
        if (objective) {
          objective.objective = event.target.value;
        }
      } else if (key === 'treatment') {
        const objective = goal.objectives[objectiveIndex];
        if (objective && objective.treatments[treatmentIndex]) {
          const treatment = objective.treatments[treatmentIndex];
          treatment.treatment = event.target.value;
        }
      }
    }

    if (isTPlan) {
      setSelectedTreatmentPlan({ ...selectedTreatmentPlan, goals: updatedGoals });
    } else {
      syncGoalsAndClinicalNotes(updatedGoals);
    }
  };

  const handleAddItem = (event, key, goalIndex, objectiveIndex) => {
    preventDefaultCheck(event);

    setGoals((prevGoals) => {
      const updatedGoals = [...prevGoals];

      if (key === 'treatment') {
        // Add treatment to the specified objective
        const goal = updatedGoals[goalIndex];
        if (goal && goal.objectives && goal.objectives[objectiveIndex]) {
          const objective = goal.objectives[objectiveIndex];

          if (!objective.treatments) {
            objective.treatments = [];
          }

          const newTreatment = {
            treatment: null
          };

          objective.treatments.push(newTreatment);
        }
      } else if (key === 'objective') {
        // Add objective to the specified goal
        const goal = updatedGoals[goalIndex];
        if (goal) {
          const newObjective = {
            objective: null,
            treatments: [
              {
                treatment: null
              }
            ]
          };

          if (!goal.objectives) {
            goal.objectives = [];
          }

          goal.objectives.push(newObjective);
        }
      } else {
        // Add a new goal
        const newGoal = {
          goal: null,
          objectives: [
            {
              objective: null,
              treatments: [
                {
                  treatment: null
                }
              ]
            }
          ]
        };

        updatedGoals.push(newGoal);
      }

      if (isTPlan) {
        setSelectedTreatmentPlan((prevState) => ({ ...prevState, goals: updatedGoals }));
      } else {
        setClinicalNote({ ...clinicalNote, goals: updatedGoals });
      }
      return updatedGoals;
    });
  };

  const handleDeleteItem = (event, key, goalIndex, objectiveIndex, treatmentIndex) => {
    preventDefaultCheck(event);

    setGoals((prevGoals) => {
      const updatedGoals = [...prevGoals];

      if (key === 'treatment') {
        // Delete treatment from the specified objective
        const goal = updatedGoals[goalIndex];
        if (goal && goal.objectives && goal.objectives[objectiveIndex]) {
          const objective = goal.objectives[objectiveIndex];
          if (objective.treatments && objective.treatments[treatmentIndex]) {
            objective.treatments.splice(treatmentIndex, 1);
          }
        }
      } else if (key === 'objective') {
        // Delete objective from the specified goal
        const goal = updatedGoals[goalIndex];
        if (goal && goal.objectives && goal.objectives[objectiveIndex]) {
          goal.objectives.splice(objectiveIndex, 1);
        }
      } else if (key === 'goal') {
        // Delete goal
        updatedGoals.splice(goalIndex, 1);
      }

      if (isTPlan) {
        setSelectedTreatmentPlan((prevState) => ({ ...prevState, goals: updatedGoals }));
      } else {
        setClinicalNote({ ...clinicalNote, goals: updatedGoals });
      }
    });
  };

  const handleNarrative = ({ event, updatedGoals = goals, scrollable = false, sync = true }) => {
    setSyncNarrative(sync);

    setNarrative(event);
    setGoals(updatedGoals);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      setClinicalNote({
        ...clinicalNote,
        goals: updatedGoals,
        goals_narrative: event
      });
    }, 400);

    setTimer(newTimer);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ goalsObject = goals, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: goalsObject,
      setData: setGoals,
      isProvider: user?.kind === 'practitioner'
    };

    const { narrative, updatedGoals } = formatSections(narrativeOptions);

    handleNarrative({
      event: narrative.trim(),
      updatedGoals,
      scrollable
    });
  };

  return (
    <>
      {isAdvForm && (
        <div>
          <div className={cs('flex justify-end !py-3', !isTPlan && 'dashed-top')}>
            {title && (
              <span className="!mr-auto text-lg font-500 leading-7 text-primary-900">Goals</span>
            )}
            <Button
              onClick={(event) => handleAddItem(event)}
              transparent
              text="Add another goal"
              iconRight="add-circle"
              className="!p-0"
              data-qa="add-another-goal"
            />
          </div>
          <div className="grid gap-y-4">
            {ia(goals) &&
              goals?.map((goal, goalIdx) => (
                <div
                  className={cs(
                    'flex items-center gap-x-[10px] !pt-4 first-of-type:!pt-0',
                    goalIdx !== 0 && 'dashed-top'
                  )}
                  key={goalIdx}>
                  <Box className="w-full gap-y-6">
                    <Textarea
                      label={`Goal ${goalIdx + 1}`}
                      placeholder="Write goal notes here"
                      id={`goal-${goalIdx + 1}`}
                      name={`goal-${goalIdx + 1}`}
                      value={goal.goal}
                      onChange={(event) => handleChange({ event, key: 'goal', goalIndex: goalIdx })}
                      transcribing
                      forwardedRef={elRefs[goalIdx]}
                      disabled={!isTPlan && clinicalNote?.locked}
                      data-qa="goal-1"
                    />
                    {goal?.objectives?.map((objective, objectiveIdx) => (
                      <div key={objectiveIdx}>
                        <div className="flex items-end gap-x-[6px] !pl-4">
                          <Input
                            className="w-full"
                            label={`Objective ${objectiveIdx + 1}`}
                            data-qa="write-objective"
                            customIcon={
                              <Button
                                onClick={(event) =>
                                  handleAddItem(event, 'objective', goalIdx, objectiveIdx)
                                }
                                transparent
                                text="Add objective"
                                iconRight="add-circle"
                                color="secondary"
                                className="!p-0"
                                data-qa="add-objective"
                              />
                            }
                            placeholder="Write objective here"
                            value={objective.objective}
                            onChange={(event) =>
                              handleChange({
                                event,
                                key: 'objective',
                                goalIndex: goalIdx,
                                objectiveIndex: objectiveIdx
                              })
                            }
                          />
                          <Icon
                            className="!pb-3"
                            icon="new-minus"
                            disabled={goal?.objectives.length <= 1}
                            onClick={(event) =>
                              handleDeleteItem(event, 'objective', goalIdx, objectiveIdx)
                            }
                          />
                        </div>
                        {objective?.treatments?.map((treatment, treatmentIdx) => (
                          <div
                            className="flex items-end gap-x-[6px] !pl-12 !pt-3"
                            key={treatmentIdx}>
                            <Input
                              className="w-full"
                              label={`Treatment ${treatmentIdx + 1}`}
                              data-qa="write-treatment"
                              customIcon={
                                <Button
                                  onClick={(event) =>
                                    handleAddItem(event, 'treatment', goalIdx, objectiveIdx)
                                  }
                                  transparent
                                  text="Add treatment"
                                  iconRight="add-circle"
                                  color="neutral"
                                  className="!p-0"
                                  data-qa="add-treatment"
                                />
                              }
                              placeholder="Write treatment here"
                              value={treatment.treatment}
                              onChange={(event) =>
                                handleChange({
                                  event,
                                  key: 'treatment',
                                  goalIndex: goalIdx,
                                  objectiveIndex: objectiveIdx,
                                  treatmentIndex: treatmentIdx
                                })
                              }
                            />
                            <Icon
                              className="!pb-3"
                              icon="new-minus"
                              disabled={objective?.treatments.length <= 1}
                              onClick={(event) =>
                                handleDeleteItem(
                                  event,
                                  'treatment',
                                  goalIdx,
                                  objectiveIdx,
                                  treatmentIdx
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </Box>
                  <Icon
                    disabled={goals.length <= 1}
                    icon="trash"
                    onClick={(event) => handleDeleteItem(event, 'goal', goalIdx)}
                  />
                </div>
              ))}
          </div>
        </div>
      )}

      {cnDisplaySettings && isNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setNarrative}
            restData={{
              className: 'w-full',
              label: 'Goals Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-goals',
              'data-qa': 'narrative-goals',
              name: 'Narrative-goals',
              value: narrative,
              forwardedRef: textAreaRef,
              formName: 'goals'
            }}
            narrativeOptions={isAdvForm ? narrativeOptions : []}
          />
        </div>
      )}
    </>
  );
};

export default Goals;
