import React from 'react';

import { formatPhoneNumber } from '../../../../lib/helpers/utility';

const ReceiptHeader = ({ practice, transaction }) => {
  return (
    <div className="inline-flex flex-col items-start relative w-full">
      <div className="relative flex items-center w-full h-[94px] bg-primary-600 rounded-[8px] mb-16 !gap-4 !px-5">
        <div className="!mt-14 w-fit rounded-[24px] overflow-hidden !border border-solid border-neutral-200 shadow-[0px_2px_19px_0px_rgba(48,95,151,0.07),0px_2px_4px_0px_rgba(48,95,151,0.02)]">
          {practice?.header_photo && (
            <img
              alt="Image"
              className="w-[112px] h-[112px] object-cover"
              src={`https://cdn.filestackcontent.com/${JSON.parse(practice?.header_photo).jpg}`}
            />
          )}
        </div>

        <div className="flex items-start justify-between flex-1 !gap-4 !pl-2 !pr-1">
          <div className="inline-flex flex-col items-start gap-[4px]">
            <div className="inline-flex items-start">
              <div className="w-fit font-semibold text-white text-[12px] leading-[16px]">
                {practice?.name}
              </div>
            </div>

            <div className="inline-flex items-start ">
              <div className=" font-500 text-white text-xxs leading-[12px]">
                {formatPhoneNumber(practice?.phone)}
              </div>
            </div>

            <div className="inline-flex items-start ">
              <div className="font-500 text-white text-xxs leading-[12px]">{practice?.email}</div>
            </div>
          </div>

          <div className="w-[97px] pt-[2px] pb-0 px-0 flex items-start">
            <p className="flex-1 font-500 text-white text-xxs leading-[12px]">
              {practice?.address && typeof practice?.address !== 'number' && practice?.address}
            </p>
          </div>

          <div className="px-[9px] py-[7px] relative bg-white rounded-[24px]">
            <p className="relative w-fit text-primary-600 text-xs">
              <span className="font-700">RECEIPT</span>
              {transaction?.id && <span className="font-500">: {transaction?.id}</span>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptHeader;
