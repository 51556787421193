import React from 'react';
import { groupByBenefitCoverageDescription, returnInplanName } from '../../eligibilityHelpers';

import BenefitCategory from './BenefitCategory';
import { withErrorBoundary } from '../../../../../../shared/Error/Boundary';

const Benefits = ({ eligibilityStructured }) => {
  return (
    <>
      {eligibilityStructured.map((elig, index) => (
        <div id={elig.groupName} className="mt-3 !rounded-lg bg-white px-4 !py-3" key={index}>
          <h3 className="!mb-2   font-600 text-primary-900">{elig.groupName}</h3>
          {Object.entries(elig.groupedData).map((item, index) => {
            return (
              <div className="my-2" key={index}>
                {Object.entries(item[1]).map((it, idx) => (
                  <div key={idx} className="!mb-2">
                    {item[0] === 'undefined' ? null : (
                      <h3 className="my-3 text-base font-600 text-primary-900">
                        {item[0] + ' ' + returnInplanName(it[0])}
                      </h3>
                    )}
                    {groupByBenefitCoverageDescription(it[1]).map(
                      (benefitCoverageDescription, benefitIdx) => (
                        <BenefitCategory
                          key={benefitIdx}
                          benefitArray={benefitCoverageDescription}
                          item={item}
                          it={it}
                        />
                      )
                    )}
                    <span className="mt-2 h-[1px] w-full bg-primary-100"></span>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default withErrorBoundary(Benefits);
