import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import SessionQuotesAndContents from './SessionQuotesAndContents';

const SessionQuotesAndContentsSection = ({ components, sectionRef, options }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.sessionQuotesAndContents?.title}
      id={components?.sessionQuotesAndContents?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <SessionQuotesAndContents sectionRef={sectionRef} />
    </Accordion>
  );
};

export default SessionQuotesAndContentsSection;
