import React, { useState } from 'react';
import illustration from './icons/payment_success.svg';
import paymentPattern from 'assets/Patterns/payment_pattern.svg';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';

const PaySuccess = ({ onCancel, transactionId, continueText }) => {
  const [show, setShow] = useState({ receipt: false });
  const navigate = useNavigate();

  const emailReceipt = async () => {
    const res = await requestApi({
      url: '/api/transactions/receipt/email',
      params: { transactionId },
      navigate
    });
    if (res?.code === 0) {
      showAlert({
        color: 'success',
        title: 'Receipt Emailed'
      });
    } else {
      showAlert({
        color: 'warning',
        title: 'Receipt NOT Emailed'
      });
    }
  };

  return (
    <div className="flex h-[100vh] w-full flex-col items-center !p-20">
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full">
        <img src={paymentPattern} className="h-full w-full"></img>
      </div>
      <img alt="Payment Success" className="w-64" src={illustration}></img>
      <p className="mt-3 text-center text-2xl font-medium text-primary-600">Payment successful!</p>
      <p className="text-center text-primary-500">The payment was successfully processed.</p>
      {transactionId && (
        <div className="mt-3 flex items-center gap-2">
          <Button
            text="View receipt"
            icon="receipt-item"
            onClick={() => setShow((ps) => ({ ...ps, receipt: true }))}
          />
          <Button text="Email receipt" icon="new-email-blue" outlined onClick={emailReceipt} />
        </div>
      )}
      <div className="flex h-full flex-1 items-end">
        <Button
          outlined
          size="large"
          iconRight="new-chevron-right"
          text={(continueText && continueText) || 'Continue'}
          onClick={() => (onCancel ? onCancel() : null)}
        />
      </div>
      {show?.receipt && (
        <ReceiptModal
          transactionId={transactionId}
          isOpen={show.receipt}
          onClose={() => setShow((ps) => ({ ...ps, receipt: false }))}
        />
      )}
    </div>
  );
};

export default PaySuccess;
