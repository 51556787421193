import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useFormPackets } from 'lib/hooks/queries/forms/useFormPackets';
import usePageTitle from 'lib/hooks/usePageTitle';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helpers';
import { mapValues } from 'lib/helpers/utility';
import { useForms } from 'lib/hooks/queries/forms/useForms';
import { useServices } from 'lib/hooks/queries/services/useServices';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import { PacketBuilderContextProvider } from 'lib/context/PacketBuilder/PacketBuilderContextProvider';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import Wizard from 'components/shared/Wizard/Wizard';
import NameAndAutomation from './components/NameAndAutomation/NameAndAutomation';
import Providers from './components/Providers';
import Services from './components/Services';
import Tags from './components/Tags';
import Forms from './components/Forms';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import FooterButtons from './components/FooterButtons';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { ReactFormGenerator } from 'react-form-builder2';
import Modal from 'components/shared/Modal/Modal';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import cs from 'classnames';

const FormPackets = () => {
  return (
    <PacketBuilderContextProvider>
      <TableContextProvider
        defaultFilters={DEFAULT_FILTERS}
        cols={defaultColumns}
        name="forms_packets">
        <Table />
      </TableContextProvider>
    </PacketBuilderContextProvider>
  );
};

function Table() {
  usePageTitle('Form Packets');
  const category = 'form_packets';
  const practice = useRecoilValue(currentPractice);

  const {
    openModal,
    setOpenModal,
    autoSave,
    saveButtonText,
    breadCrumbs,
    handleClose,
    handleSave,
    handleAutoSave,
    debouncedSearchTerm,
    isFormSavingLoading,
    formId,
    setIsEdit,
    setBreadCrumbs,
    setPacket,
    formik,
    editInitialBreadcrumbs,
    isEdit
  } = usePacketBuilderContext();

  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();
  const { device } = useUIContext();
  const [viewForm, setViewForm] = useState(false);
  const [formToView, setFormToView] = useState();

  const {
    data = {},
    isLoading,
    isFetching
  } = useFormPackets({
    params: {
      withGraphFetched: {
        providers: true,
        services: true,
        allForms: true,
        tags: true
      },
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const { data: providersList } = useProviders({
    params: {
      searchTerm: debouncedSearchTerm?.providers
    },
    dependencies: [debouncedSearchTerm?.providers]
  });

  const { data: formsList } = useForms({
    params: {
      filters: {
        name: debouncedSearchTerm?.forms
      }
    },
    dependencies: [debouncedSearchTerm?.forms]
  });

  const { data: servicesList } = useServices({
    params: {
      filters: {
        searchTerm: debouncedSearchTerm?.services
      }
    },
    dependencies: [debouncedSearchTerm?.services]
  });

  const { data: tagsList } = useTags({
    params: { kind: 'appointment', searchTerm: debouncedSearchTerm?.tags },
    dependencies: [debouncedSearchTerm?.tags]
  });

  const providerOptions = useMemo(() => {
    if (providersList?.practitioners?.length > 0) {
      return providersList.practitioners.map((p) => ({
        name: p?.f_name + ' ' + p?.l_name,
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providersList]);
  filters.providers.options = providerOptions;

  const formOptions = useMemo(() => {
    if (formsList?.forms?.length > 0) {
      return formsList.forms.map((f) => ({
        label: f?.name,
        value: f?.id
      }));
    }
    return [];
  }, [formsList]);
  filters.forms.options = formOptions;

  const serviceOptions = useMemo(() => {
    if (servicesList?.services?.length > 0) {
      return servicesList.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [servicesList]);
  filters.services.options = serviceOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags.map((t) => ({
        label: t?.name,
        value: t?.id
      }));
    }
    return [];
  }, [tagsList]);
  filters.tags.options = tagOptions;

  const handleFormView = (item) => {
    setFormToView(item);
    setOpenModal(false);
    setViewForm(true);
  };

  const packets = data?.packets || [];
  const count = data?.count || 0;

  const actionViews = {
    name_and_automation: {
      component: (
        <NameAndAutomation
          currentBreadcrumb="name_and_automation"
          handleFormView={handleFormView}
        />
      ),
      footer: <FooterButtons />
    },
    providers: {
      component: <Providers currentBreadcrumb="providers" providersList={providersList} />,
      footer: <FooterButtons nextTo={true} backTo={true} showNavigationButtons={true} />
    },
    services: {
      component: <Services currentBreadcrumb="services" servicesList={servicesList} />,
      footer: <FooterButtons nextTo={true} backTo={true} showNavigationButtons={true} />
    },
    tags: {
      component: <Tags currentBreadcrumb="tags" tagsList={tagsList} />,
      footer: <FooterButtons nextTo={true} backTo={true} showNavigationButtons={true} />
    },
    forms: {
      component: <Forms currentBreadcrumb="forms" formsList={formsList} formId={formId} />,
      footer: <FooterButtons nextTo={true} backTo={true} showNavigationButtons={true} isLastStep />
    }
  };

  const getCurrentView = () => {
    let currentView = breadCrumbs?.find((row) => row.active)?.view;
    return actionViews[currentView]?.component;
  };

  const getCurrentFooter = () => {
    let currentView = breadCrumbs?.find((row) => row.active)?.view;
    return actionViews[currentView]?.footer;
  };

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'actions') return;
    if (e?.column?.colId === '0') return;
    setIsEdit(true);
    setBreadCrumbs(editInitialBreadcrumbs);
    setPacket({
      ...e.data
    });
    formik.setValues({
      ...formik.values,
      ...e.data,
      tag_ids: e?.data?.tag_ids?.map((tag) => tag?.value),
      provider_ids: e?.data?.provider_ids.map((provider) => provider?.value),
      service_ids: e?.data?.service_ids?.map((service) => service?.value),
      form_ids: e?.data?.form_ids?.map((form) => form?.value)
    });
    setOpenModal(true);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title="Form Packets">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Button
            text="New Packet"
            onClick={() => setOpenModal(true)}
            data-qa="new-packet-btn"
            className="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex  h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={packets}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              columnDef={() => defaultColumns(practice.timezone)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {openModal && (
        <Wizard
          isOpen={openModal}
          handleClose={handleClose}
          handleSaveProgress={handleSave}
          saveProgressLoading={isFormSavingLoading}
          handleAutoSave={handleAutoSave}
          showAutoSave={true}
          showSaveProgress={true}
          title="Packet Builder"
          autoSave={autoSave}
          saveButtonText={saveButtonText}
          breadcrumbs={breadCrumbs}
          setBreadCrumbs={setBreadCrumbs}
          enableNavigation={isEdit}
          footerButtons={getCurrentFooter()}>
          {getCurrentView()}
        </Wizard>
      )}
      {viewForm && (
        <Modal
          slideFromRight
          title={formToView?.name}
          isOpen={viewForm}
          handleClose={() => setViewForm(false)}
          className={cs(
            '!bg-white',
            device === 'tablet-sm' ? 'w-[729px]' : device === 'tablet' ? 'w-[984px]' : 'w-[1240px]'
          )}>
          <ReactFormGenerator
            data={formToView?.json?.fields}
            read_only
            answer_data={null}
            hide_actions
          />
        </Modal>
      )}
    </ErrorBoundary>
  );
}

export default withErrorBoundary(FormPackets);
