import Tippy from '@tippyjs/react';
import { iaRa } from 'lib/helpers/utility';
import React, { useRef } from 'react';
import { filterSuperbillClaimProcedure } from '../../lib/procedureUtils';

const ServiceCellTooltip = ({ data, node }) => {
  const ref = useRef();

  if (!!node?.group) {
    return;
  }

  const procedures = iaRa(data?.invoice_procedures).filter(filterSuperbillClaimProcedure);
  const finalCodes = procedures.map((procedure) => procedure.procedure_code).filter(Boolean);

  const [firstClaim, ...restClaims] = finalCodes || [];

  return (
    <div className="flex flex-row">
      {firstClaim && <div>{firstClaim}</div>}
      {restClaims.length > 0 && (
        <div className="ml-3">
          <div ref={ref}>+{restClaims.length}</div>
          <Tippy
            className="custom-tooltip tippy-dark !rounded-md "
            reference={ref}
            content={
              <div className="flex-column p-2">
                {restClaims?.map((claim) => (
                  <div key={claim} className="m-1 rounded-md p-1 text-slate-50">
                    {claim}
                  </div>
                ))}
              </div>
            }
            allowHTML
            interactive
            appendTo={() => document.body}
            placement="right"
            offset={[0, 10]}
          />
        </div>
      )}
    </div>
  );
};

export default ServiceCellTooltip;
