import React from 'react';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import LabOrderPreview from '../../../Orders/views/LabOrderPreview/LabOrderPreview';

import { useParams } from 'react-router-dom';
import { ia } from 'lib/helpers/utility';
import { useLabOrder } from 'lib/hooks/queries/labOrder/useLabOrder';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const LabOrImagingOrders = ({ customClinicalNote = null, checkedFormTypes = {} }) => {
  const { isTemplateChecked } = checkedFormTypes;
  const { clinicalNote } = useClinicalNoteContext() || {};

  const customAppointmentId = customClinicalNote?.clinicalNote?.appointment_id;

  const { appointmentId } = useParams();

  const apptID = customAppointmentId ?? appointmentId ?? clinicalNote?.appointment_id;

  const { data: labOrder, isFetching: labOrderFetching } = useLabOrder({
    dependencies: [apptID, clinicalNote?.id],
    params: {
      appointmentIds: [apptID],
      withRelations: { provider: true, tests: true }
    },
    options: {
      enabled: isTemplateChecked,
      select: ({ data }) => data?.labOrder
    }
  });

  if (labOrderFetching) return <Skeleton height="300px" />;

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Lab/Imaging Orders</h3>

      {ia(labOrder) ? (
        labOrder.map((row, index) => {
          return (
            <div key={index}>
              <LabOrderPreview itemProps={{ labOrder: row, labOrderTests: row?.tests }} />
              {labOrder?.length - 1 > index && <div className="border border-solid" />}
            </div>
          );
        })
      ) : (
        <p className="text-sm">No Lab/Imaging Orders</p>
      )}
    </div>
  );
};

export default LabOrImagingOrders;
