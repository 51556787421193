import { requestApi } from './Api';

export const getStickyNotes = async (navigate, params, onError, onSuccess) =>
  await requestApi({
    navigate,
    url: '/api/practice/patient/sticky_notes/read',
    params,
    onError,
    onSuccess
  });
export const createStickyNote = async (navigate, params, onError, onSuccess) => {
  return await requestApi({
    navigate,
    url: '/api/practice/patient/sticky_notes/create',
    params,
    onError,
    onSuccess
  });
};

export const deleteStickyNote = async (navigate, params, onError, onSuccess) => {
  return await requestApi({
    navigate,
    url: '/api/practice/patient/sticky_notes/delete',
    params,
    onError,
    onSuccess
  });
};
