import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import RequestAssistance from 'components/Kiosk/components/RequestAssistance';
import Button from 'components/shared/Buttons/Button';
import PatientCheckinLogin from './Login';
import Sidebar from './Sidebar';
import './style.scss';
import Icon from 'components/shared/Icon/Icon';
import { requestApi } from 'api/Api';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import AutoIdle from 'components/Kiosk/components/AutoIdle';
import { useRecoilValue } from 'recoil';
import { userState } from './state';
import { useCheckinUser } from 'lib/hooks/queries/user/useCheckinUser';

const DEFAULT_CONTINUE_TEXT = 'Save & Continue';
const DEFAULT_CONTINUE_COLOR = 'primary';

const CheckinSteps = ({
  checkinBackgroundPNG,
  loginRequest,
  setLoginRequest,
  device,
  practiceLogo,
  steps,
  currentStepName,
  currentStepNumber,
  updateCompleteStep,
  updateSteps,
  isEverythingCompleted,
  navigateToIncompleted,
  stepObject,
  setSteps,
  checkinButton,
  checkinInsuranceEnabled,
  showButton,
  handleExternalSubmit,
  appointmentId,
  handleBack,
  customOnSuccess,
  practice,
  numCompleted,
  updateChoices,
  isInstantPacket
}) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disableContinue, setDisableContinue] = useState(false);
  const [continueText, setContinueText] = useState(DEFAULT_CONTINUE_TEXT);
  const [continueColor, setContinueColor] = useState(DEFAULT_CONTINUE_COLOR);
  const [kiosk, setKiosk] = useState(false);
  const [fakeModal, setFakeModal] = useState(null);
  const userSt = useRecoilValue(userState);
  const { data } = useCheckinUser({});

  const [responses, setResponses] = useState({});

  const [cid, setCid] = useState(appointmentId);
  const { pathname } = useLocation();
  const isPreAuth = pathname.includes('/instant-packet/pre-auth');

  const defaultContinueText = () => {
    setContinueText(DEFAULT_CONTINUE_TEXT);
  };

  const defaultContinueColor = () => {
    setContinueColor(DEFAULT_CONTINUE_COLOR);
  };

  useEffect(() => {
    requestApi({
      url: '/api/kiosk/token',
      onSuccess: (data) => {
        setKiosk(data.token);
        setCid(data.cid);
      }
    });
  });

  useEffect(() => {
    if (numCompleted) setProgress(window.screen.availWidth * (numCompleted / steps.length));
  }, [window.screen.availWidth, numCompleted, steps]);

  return (
    <div
      className={cs(
        'h-[100svh] overflow-hidden bg-transparent p-[1.2rem] sm:p-0 md:p-0',
        fakeModal && '!m-0 !p-0'
      )}>
      {kiosk && <AutoIdle />}

      <img
        src={checkinBackgroundPNG}
        className="absolute left-0 top-0 z-10 h-full w-full object-cover"
      />
      {loginRequest && (
        <div className="relative z-20 flex h-full w-full overflow-hidden rounded-xl sm:rounded-none md:rounded-none">
          <PatientCheckinLogin setLoginRequest={setLoginRequest} />
        </div>
      )}
      {!loginRequest && (
        <div className="relative z-20 flex h-full overflow-hidden rounded-xl sm:!rounded-none md:rounded-none">
          {(device === 'desktop' || device === 'laptop' || show) && (
            <div className="h-full max-w-[360px] border-0 !border-l !border-solid border-white bg-transparent">
              <Sidebar
                kiosk={kiosk}
                steps={steps}
                practiceLogo={practiceLogo}
                device={device}
                setShow={setShow}
                disableContinue={disableContinue}
              />
            </div>
          )}
          <div
            className="flex h-full w-full flex-col overflow-hidden bg-neutral-50"
            onClick={() => {
              show && setShow(false);
            }}>
            {(device === 'mobile' || device === 'tablet-sm' || device === 'tablet') && (
              <>
                <div
                  className="sticky top-0 w-full bg-neutral-50 text-2xl font-600 text-primary-500"
                  onClick={() => {
                    setShow((ps) => !ps);
                  }}>
                  <div className="!m-2 flex flex-row">
                    <div className="flex cursor-pointer items-center">
                      <Icon icon="new-hamburger" className="!mr-2 cursor-pointer" />
                      <div className="text-md flex aspect-square h-[3rem] w-[3rem] flex-row items-center justify-center rounded-full bg-primary-500 text-center text-white">{`${
                        currentStepNumber || 1
                      }`}</div>

                      <div className="ml-[0.5rem] flex flex-col items-start justify-center">
                        <div className="text-xs text-neutral-600">
                          {numCompleted} / {steps.length - 1} Steps completed
                        </div>

                        <div className="w-fit text-lg font-600 text-primary-500">
                          {currentStepName}
                        </div>
                      </div>
                    </div>

                    <div className="ml-auto mr-1 flex items-center gap-2">
                      {kiosk && <RequestAssistance className="!mr-2" page={currentStepName} />}
                      {device &&
                        (device === 'mobile' || device === 'tablet-sm' || device === 'tablet') &&
                        !isPreAuth &&
                        steps.length !== currentStepNumber &&
                        !fakeModal && (
                          <>
                            {handleBack && (
                              <Button
                                color="neutral"
                                outlined
                                text="Back"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBack();
                                }}
                                data-qa="wizard-back-btn"
                              />
                            )}

                            <Tippy
                              className="tippy-dark"
                              disabled={!disableContinue}
                              content="Please sign the HIPAA Consent form">
                              <div className="flex">
                                <Button
                                  text={continueText}
                                  color={continueColor}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleExternalSubmit();
                                  }}
                                  data-qa="continue-primary-btn"
                                  disabled={disableContinue}
                                />
                              </div>
                            </Tippy>
                          </>
                        )}

                      {fakeModal && (
                        <Button
                          color="neutral"
                          outlined
                          text="Close"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFakeModal(null);
                          }}
                          data-qa="wizard-back-btn"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex h-[5px] flex-row overflow-hidden bg-neutral-200">
                  <div
                    className={`h-full bg-primary-500 ${
                      (stepObject.numCompleted !== steps.length && 'rounded-r-full') || ''
                    }`}
                    style={{ width: `${progress}px` }}></div>
                </div>
              </>
            )}
            <div
              className={cs(
                'checkin-steps__outlet !ml-0 grid !h-full overflow-y-auto',
                fakeModal ? '!m-0 !p-0' : '!m-1 '
              )}>
              <Outlet
                context={{
                  kiosk,
                  updateCompleteStep,
                  updateSteps,
                  isEverythingCompleted,
                  navigateToIncompleted,
                  steps,
                  stepObject,
                  setSteps,
                  checkinButton,
                  checkinInsuranceEnabled,
                  setLoginRequest,
                  appointmentId,
                  customOnSuccess,
                  practiceLogo,
                  practice,
                  updateChoices,
                  setDisableContinue,
                  cid,
                  setContinueText,
                  defaultContinueText,
                  setContinueColor,
                  defaultContinueColor,
                  handleBack,
                  fakeModal,
                  setFakeModal,
                  handleExternalSubmit,
                  isInstantPacket,
                  responses,
                  setResponses,
                  user: { ...userSt, ...(data?.user || {}), address_object: data?.address }
                }}
              />
            </div>
            {showButton() &&
              !(device === 'mobile' || device === 'tablet' || device === 'tablet-sm') &&
              !isPreAuth && (
                <div
                  className={`flex w-full ${
                    device && (device === 'mobile' || device === 'tablet')
                      ? 'justify-between'
                      : 'justify-end'
                  } sticky bottom-0 items-center gap-4 bg-white !p-4`}
                  style={{ boxShadow: '0px -2px 16px 0px #1B21231A' }}
                  data-dd-privacy="allow"
                  data-public>
                  {kiosk && <RequestAssistance page={currentStepName} />}
                  {steps.length !== currentStepNumber && (
                    <Tippy
                      className="tippy-dark"
                      disabled={!disableContinue}
                      content="Please sign the HIPAA Consent form">
                      <div className="flex">
                        <Button
                          color={continueColor}
                          data-dd-privacy="allow"
                          data-public
                          text={continueText}
                          onClick={handleExternalSubmit}
                          data-qa="continue-primary-btn"
                          disabled={disableContinue}
                        />
                      </div>
                    </Tippy>
                  )}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(CheckinSteps);
