import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { ia, spaceToKebabCase } from 'lib/helpers/utility';
import { createPortal } from 'react-dom';
import Loader from 'components/shared/Loader/Loader';

const Popover = ({
  position = 'right', // by default is 'right', means in the right position where the button is
  isFixed = false,
  options = [],
  icon = 'new-context-menu-dots',
  iconSize = 16,
  iconClassName = '',
  iconRight = '',
  iconRightSize = 16,
  iconRightClassName = '',
  optionIconSize = 16,
  optionIconColor = 'primary',
  text = '',
  disabled,
  className = '',
  buttonClassName = '',
  buttonTheme = '',
  textClassName = '',
  panelClassName = '',
  isDropdown = false
}) => {
  const buttonRef = useRef(null);
  const panelRef = useRef(null);
  const panElement = useRef(document.createElement('popover'));
  const [panelFixedPosition, setPanelFixedPosition] = useState({});
  const [hoveredIndexes, setHoveredIndexes] = useState({});
  const [panelWidth, setPanelWidth] = useState(140); // default width, this is used only to calculate the position of panel

  const updatePopoverPosition = useCallback(() => {
    if (isFixed && buttonRef.current) {
      const buttonRect = buttonRef?.current?.getBoundingClientRect();

      let left, right;
      if (position === 'right') {
        left =
          buttonRect.left + panelWidth > window.innerWidth
            ? `${window.innerWidth - panelWidth}px`
            : `${buttonRect.left}px`;
        right = 'unset';
      } else {
        right =
          window.innerWidth - buttonRect.right + panelWidth > window.innerWidth
            ? `${window.innerWidth - panelWidth}px`
            : `${window.innerWidth - buttonRect.right}px`;
        left = 'unset';
      }

      setPanelFixedPosition({
        position: 'fixed',
        top: `${buttonRect.bottom}px`,
        left: left !== 'unset' ? left : 'unset',
        right: right !== 'unset' ? right : 'unset'
      });
    }
  }, [isFixed, position, panelWidth]); // these dependencies are added only for developers in cases where they change props to update position immediately

  useEffect(() => {
    const root = document.getElementById('root');
    let panContainer = document.getElementById('popovers');

    if (!panContainer && root) {
      panContainer = document.createElement('div');
      panContainer.id = 'popovers';
      root.appendChild(panContainer);
    }
    if (isFixed && panContainer) {
      panContainer.appendChild(panElement.current);

      updatePopoverPosition();

      const handleResize = () => updatePopoverPosition();

      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleResize, true);

      return () => {
        window.removeEventListener('resize', handleResize);
        window.addEventListener('scroll', handleResize, true);

        if (panElement.current.parentNode === panContainer) {
          panContainer.removeChild(panElement.current);
        }
      };
    }
  }, [updatePopoverPosition]);

  useEffect(() => {
    if (panelRef.current) {
      const width = panelRef.current.getBoundingClientRect().width;
      setPanelWidth(width);
    }
  }, [panelRef.current]);

  const handleMouseEnter = (level, index) => {
    setHoveredIndexes((prev) => {
      if (prev[level] !== index) {
        return { ...prev, [level]: index };
      }
      return prev;
    });
  };

  const handleMouseLeave = (level) => {
    setHoveredIndexes((prev) => ({
      ...prev,
      [level]: null
    }));
  };

  const handleOnClick = (option, handleClose, event) => {
    if (option.onClick) {
      event.stopPropagation();
      option.onClick();
      handleClose();
    }
  };

  const renderOptions = (options, level = 1, handleClose) =>
    options.map((option, index) => (
      <div
        key={index}
        className={cs(
          'relative flex items-center gap-x-2 py-[6px] !pl-3 hover:bg-primary-50',
          option.onClick && !ia(option.children) ? 'cursor-pointer !pr-3' : 'cursor-default pr-2'
        )}
        data-qa={`popover-option-${spaceToKebabCase(option.label)}`}
        onMouseEnter={() => option.children && handleMouseEnter(level, index)}
        onMouseLeave={() => option.children && handleMouseLeave(level)}
        onClick={(event) => handleOnClick(option, handleClose, event)}>
        {option.loading ? (
          <Loader
            outlined
            className={`text-${optionIconColor}-500`}
            type={hoveredIndexes[level] === index ? 'secondary' : 'primary'}
          />
        ) : (
          option.icon && (
            <Icon
              icon={option.icon}
              size={optionIconSize}
              color={optionIconColor}
              stroke={option.stroke}
            />
          )
        )}
        <span className="select-none whitespace-nowrap text-sm font-400 text-primary-900">
          {option.label}
        </span>
        {option.children && (
          <Icon
            icon="new-chevron-right"
            size={optionIconSize}
            className="ml-auto"
            color={optionIconColor}
            stroke
          />
        )}
        {option.children && hoveredIndexes[level] === index && (
          <div
            className={cs(
              'absolute -top-[14px] z-50 mt-[6px] grid min-w-[120px] gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium',
              option.position === 'left'
                ? 'right-full'
                : option.position === 'right'
                  ? 'left-full'
                  : position === 'left'
                    ? 'right-full'
                    : 'left-full'
            )}>
            {renderOptions(option.children, level + 1, handleClose)}
          </div>
        )}
      </div>
    ));

  const Panel = ({ handleClose }) => (
    <HeadlessPopover.Panel
      ref={panelRef}
      style={isFixed ? panelFixedPosition : {}}
      className={cs(
        'z-50 mt-[6px] grid min-w-[120px] gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium',
        panelClassName,
        isFixed ? '' : `absolute ${position === 'left' ? 'right-0' : 'left-0'}`
      )}>
      {renderOptions(options, 1, handleClose)}
    </HeadlessPopover.Panel>
  );

  return (
    <HeadlessPopover className={cs('relative w-fit', className)}>
      {({ open, close }) => (
        <>
          <HeadlessPopover.Button
            ref={buttonRef}
            data-qa="popover-button"
            className={cs(
              'flex min-h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-md p-0 font-500',
              open && 'bg-primary-700 text-white',
              !open && !buttonTheme && 'bg-transparent text-neutral-800 hover:!bg-neutral-50',
              !open && buttonTheme && `text-${buttonTheme}-500 bg-${buttonTheme}-50`,
              text && '!pr-4 pl-2',
              (iconRight || isDropdown) && 'pl-4',
              buttonClassName
            )}
            disabled={disabled}>
            {icon && (
              <Icon
                icon={icon}
                size={iconSize}
                color={open ? 'white' : (buttonTheme ?? '')}
                className={iconClassName}
              />
            )}
            {text && (
              <span className={cs('text-sm first-letter:capitalize', textClassName)}>{text}</span>
            )}
            {iconRight && !isDropdown && (
              <Icon
                icon={iconRight}
                size={iconRightSize}
                color={open ? 'white' : (buttonTheme ?? '')}
                className={iconRightClassName}
              />
            )}
            {isDropdown && (
              <Icon
                icon="new-chevron-down"
                size={iconRightSize}
                color={open ? 'white' : (buttonTheme ?? '')}
                className={cs('ml-1 duration-150', open && 'rotate-180')}
              />
            )}
          </HeadlessPopover.Button>
          {open &&
            (isFixed ? (
              createPortal(<Panel handleClose={() => close()} />, panElement.current)
            ) : (
              <Panel handleClose={() => close()} />
            ))}
        </>
      )}
    </HeadlessPopover>
  );
};

export default Popover;
