import React, { useEffect } from 'react';
import useChartSize from 'lib/hooks/useChartSize';
import ListView from './ListView';
import { invoices } from '../lib/constants';
import ChartView from './ChartView';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Invoices = (props) => {
  const { overview, item, layout, listView, setNavigatePath, navigatePath } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const { height, width, proportion } = useChartSize(container);

  const chartData = [
    {
      name: 'Paid',
      value: parseInt(overview?.invoices?.state?.paid) || 0,
      fill: '#4ADE80',
      tableName: 'electronic-invoices'
    },
    {
      name: 'Unpaid',
      value: parseInt(overview?.invoices?.state?.unpaid) || 0,
      fill: '#FCD34D',
      tableName: 'external'
    }
  ];

  const options = {
    data: chartData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    series: [
      {
        data: chartData,
        type: 'donut',
        angleKey: 'value',
        fills: chartData.map((item) => item.fill),
        innerRadiusRatio: 0.7,
        innerLabels: [
          {
            text: `${overview?.invoices?.all}`,
            color: '#004F6B',
            fontSize: 19
          },
          { text: ' ', fontSize: 10 },
          { text: 'Invoices', color: '#6D828B', fontSize: 12 }
        ],
        innerCircle: {
          fill: 'transparent'
        }
      }
    ]
  };

  useEffect(() => {
    setNavigatePath('electronic-invoices');
  }, []);

  return (
    <>
      {listView ? (
        <ListView
          options={options}
          constantType={invoices}
          data={overview?.invoices?.state}
          total={overview?.invoices?.all}
          navigatePath={navigatePath}
        />
      ) : (
        <ChartView
          options={options}
          constantType={invoices}
          data={overview?.invoices?.state}
          total={overview?.invoices?.all}
          navigatePath={navigatePath}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Invoices);
