import React from 'react';
import Icon from '../Icon/Icon';
import cs from 'classnames';

const ErrorMessage = ({
  error,
  resetErrorBoundary,
  additionalText = '',
  isImage = false,
  className = ''
}) => (
  <div
    className={cs('mb-2 rounded-lg bg-white !p-5', className)}
    data-public
    data-dd-privacy="allow">
    {additionalText && additionalText}
    <p className="mb-2 block text-base font-400 text-neutral-800">Something went wrong!</p>
    <p className="block text-danger-500">{error?.message}</p>
    {isImage && <Icon icon="new-something-went-wrong" className="py-4" />}
    <button
      className="cursor-pointer rounded-lg border border-solid !border-primary-400 bg-primary-50 !px-4 !py-2 text-sm font-500 text-primary-500 transition-all hover:bg-primary-50"
      onClick={resetErrorBoundary}>
      Please try again!
    </button>
  </div>
);

export default ErrorMessage;
