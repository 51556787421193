import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { userState } from '../../../components/state';
import { ClinicalNoteContext } from './ClinicalNoteContext';
import { useEnhanceNarrative } from 'lib/hooks/queries/myscribeAI/useEnhanceNarrative';
import { io } from 'lib/helpers/utility';

export const ClinicalNoteContextProvider = ({ children, outsideClinicalNoteContext = false }) => {
  const [tab, setTab] = useState(null);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [clinicalNote, setClinicalNote] = useState({});
  const [staticClinicalNote, setStaticClinicalNote] = useState({});
  const [components, setComponents] = useState({});
  const [advancedHP, setAdvancedHP] = useState([]);
  const advancedHPRef = useRef();
  const advancedSOAPRef = useRef();
  const advancedFormRef = useRef();
  const [advancedHPElRefs, setAdvancedHPElRefs] = useState([]);
  const [advancedSOAP, setAdvancedSOAP] = useState([]);
  const [customFormTypes, setCustomFormTypes] = useState([]);
  const [advancedSOAPElRefs, setAdvancedSOAPElRefs] = useState([]);
  const [serviceCPT, setServiceCPT] = useState();
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [currentHpOverviewData, setCurrentHpOverviewData] = useState({});
  const [vitals, setVitals] = useState([]);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [cnError, setCnError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [cnDisplaySettings, setCNDisplaySettings] = useState(
    currentUser?.display_settings.clinicalNote
  );
  const [cnDisplaySettingsLoading, setCNDisplaySettingsLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [selected, setSelected] = useState({});
  const [showPrevNoteModal, setShowPrevNoteModal] = useState(false);
  const [previewNoteModal, setPreviewNoteModal] = useState(false);
  const [isExport, setIsExport] = useState(true);
  const [patientResponseSidebar, setPatientResponseSidebar] = useState(false);
  const navigate = useNavigate();
  const [confirmSignLock, showConfirmSignLock] = useState(false);
  const saveNoteRef = useRef(null);
  const vitalsRef = useRef();
  const [saveButtonText, setSaveButtonText] = useState('Save');
  const [forceSave, setForceSave] = useState({
    advancedForm: false
  });
  const [documentSignInfo, setDocumentSignInfo] = useState({});
  const [enhanceNarrativeParams, setEnhanceNarrativeParams] = useState({
    kind: '',
    name: '',
    body: ''
  });
  const {
    isFetching: isFetchingEnhanceNarrative,
    isLoading: isLoadingEnhanceNarrative,
    refetch: refetchEnhanceNarrative
  } = useEnhanceNarrative({
    params: {
      kind: enhanceNarrativeParams.kind,
      name: enhanceNarrativeParams.name,
      body: enhanceNarrativeParams.body,
      id: clinicalNote?.id
    },
    options: {
      enabled: !!clinicalNote?.id && !!enhanceNarrativeParams.kind,
      onSettled: (data) => {
        console.log('data', data);
      }
    },
    dependencies: []
  });

  useEffect(() => {
    if (outsideClinicalNoteContext) return;
    updateUserSettings();
  }, [cnDisplaySettings]);

  const updateSaveButton = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText('Saved');
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText('Save');
  };

  const updateUserSettings = async () => {
    await handleSave();
  };

  const handleSave = async () => {
    setCNDisplaySettingsLoading(true);

    try {
      let params = {
        practitioner_id: currentUser.id,
        fields: {
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        }
      };
      let res = await interimApi('/api/practice/settings/practice_user/update', params, navigate);
      if (res.data) {
        setCurrentUser({
          ...currentUser,
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        });
      }
    } catch (error) {
      Honeybadger.notify(`cnDisplaySettings save - catch error - ${error}`);
    }

    setCNDisplaySettingsLoading(false);
  };

  const applyForceSave = ({ type = null, value = false }) => {
    if (!type) return;

    setForceSave((prevState) => ({ ...prevState, [type]: value }));
  };

  return (
    <ClinicalNoteContext.Provider
      value={{
        clinicalNote,
        setClinicalNote,
        staticClinicalNote,
        setStaticClinicalNote,
        components,
        setComponents,
        advancedHP,
        setAdvancedHP,
        advancedSOAP,
        setAdvancedSOAP,
        customFormTypes,
        setCustomFormTypes,
        advancedHPRef,
        advancedSOAPRef,
        advancedFormRef,
        advancedHPElRefs,
        setAdvancedHPElRefs,
        advancedSOAPElRefs,
        setAdvancedSOAPElRefs,
        loading,
        setLoading,
        serviceCPT,
        setServiceCPT,
        overviewData,
        setOverviewData,
        vitals,
        setVitals,
        selectedTreatmentPlan,
        setSelectedTreatmentPlan,
        currentHpOverviewData,
        setCurrentHpOverviewData,
        cnError,
        setCnError,
        tab,
        setTab,
        alert,
        setAlert,
        cnDisplaySettings,
        setCNDisplaySettings,
        cnDisplaySettingsLoading,
        selected,
        setSelected,
        showPrevNoteModal,
        setShowPrevNoteModal,
        previewNoteModal,
        setPreviewNoteModal,
        isExport,
        setIsExport,
        saveNoteRef,
        vitalsRef,
        confirmSignLock,
        showConfirmSignLock,
        patientResponseSidebar,
        setPatientResponseSidebar,
        saveButtonText,
        setSaveButtonText,
        updateSaveButton,
        forceSave,
        setForceSave,
        applyForceSave,
        documentSignInfo,
        setDocumentSignInfo,
        outsideClinicalNoteContext,
        setEnhanceNarrativeParams,
        refetchEnhanceNarrative
      }}>
      {children}
    </ClinicalNoteContext.Provider>
  );
};
