import { currentPractice } from 'components/practice/practiceState';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NewPatientClaims from './NewPatientClaims';
import { DEFAULT_FILTERS, getColDefs } from './configs';

const getFinalColDefs = (name, timezone) => {
  let colDefs = [...getColDefs(name, timezone)];

  return colDefs;
};

const NewClaims = () => {
  const name = 'claims';
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const { type = 'all' } = useParams();

  let tableName = name;
  if (name === 'claims') {
    tableName = `${name}_${type}`;
  }

  let NEW_COL_DEFS = getFinalColDefs(name, practiceTimezone);

  if (type === 'all') {
    NEW_COL_DEFS = [
      ...NEW_COL_DEFS.slice(0, 7),
      {
        headerName: 'Status',
        field: 'state',
        valueFormatter: ({ value }) => value?.toUpperCase()
      },
      ...NEW_COL_DEFS.slice(7)
    ];
  }

  return (
    <TableContextProvider
      key={NEW_COL_DEFS}
      name={tableName}
      cols={NEW_COL_DEFS}
      pagination
      defaultFilters={DEFAULT_FILTERS}>
      <div
        className="mt-0 flex h-full flex-col overflow-hidden bg-white p-0 pt-3"
        data-dd-privacy="allow">
        <NewPatientClaims name={name} />
      </div>
    </TableContextProvider>
  );
};

export default NewClaims;
