import { getFaxesCSV } from 'api/Fax';
import { currentPractice } from 'components/practice/practiceState';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useDraftFaxes } from 'lib/hooks/queries/fax/useDraftFaxes';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SendFaxModal from '../components/SendFaxModal/SendFaxModal';
import NewFaxesTable from '../NewFaxesTable';
import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, getColDefs, GRID_OPTIONS } from './configs';

const NewDraftFaxes = () => {
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;
  return (
    <TableContextProvider
      name="drafts_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone)}
      pagination>
      <DraftFaxesTable />
    </TableContextProvider>
  );
};

function DraftFaxesTable() {
  const category = 'draft_faxes';

  const navigate = useNavigate();

  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const [draftFax, setDraftFax] = useState(null);
  const [sendFaxModalVisible, setSendFaxModalVisible] = useState(false);

  const { limit, page, sort, filters } = useTableContext();

  const { data, isFetching, isLoading } = useDraftFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const draftFaxes = data?.draftFaxes || [];
  const count = data?.count || 0;

  const practice = useRecoilValue(currentPractice);
  const { data: patientsData } = usePatients({
    params: { practiceId: practice.id }
  });

  const patients = patientsData?.patients;

  const patientOptions = useMemo(() => {
    if (patients?.length > 0) {
      return patients.map((patient) => ({
        id: patient.user_id,
        label: patient?.f_name + ' ' + patient?.l_name,
        value: patient?.id
      }));
    }
    return [];
  }, [patients]);

  filters.patients.options = patientOptions;

  const onOpen = (fax) => {
    setSendFaxModalVisible(true);
    setDraftFax(fax);
  };

  const onRowClicked = (clickedFax) => {
    onOpen(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'drafts'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };

  return (
    <div className="h-full">
      <NewFaxesTable
        onExportCSV={onExportCSV}
        data={draftFaxes}
        category={category}
        name="Draft Faxes"
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
        count={count}
      />
      {sendFaxModalVisible && (
        <SendFaxModal
          {...{ sendFaxModalVisible, setSendFaxModalVisible, faxData: draftFax, isDraft: true }}
        />
      )}
    </div>
  );
}

export default NewDraftFaxes;
