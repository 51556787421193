import React, { useState } from 'react';
import CPTCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import VitalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import GeneralNoteSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import GoalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GoalsSection';
import ActiveSymptomsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/ActiveSymptomsSection';
import FunctionalImpairmentSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/FunctionalImpairmentSection';
import InterventionsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/InterventionsSection';
import ProgressSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/ProgressSection';
import RiskFactorsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/RiskFactorsSection';
import SessionQuotesAndContentsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/SessionQuotesAndContentsSection';
import SessionSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/SessionSection';
import OrdersSection from 'components/practice/charts/ClinicalNote/Orders/OrdersSection';
import FormsAI from '../FormsAI';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import AdvancedSOAP from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/AdvancedSOAP';

const getFormsList = (advancedSOAP, cnDisplaySettings) => {
  const formsList = {
    goals: GoalsSection,
    session: SessionSection,
    'risk-factors': RiskFactorsSection,
    'session-quotes-and-contents': SessionQuotesAndContentsSection,
    'active-symptoms': ActiveSymptomsSection,
    'functional-impairment': FunctionalImpairmentSection,
    vitals: VitalsSection,
    interventions: InterventionsSection,
    progress: ProgressSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection,
    ...assignAdvTitlesToComponent(advancedSOAP, AdvancedSOAP, cnDisplaySettings)
  };

  return formsList;
};

const PROGRESSFormsAI = ({ inputName }) => {
  const { advancedSOAP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedSOAP, cnDisplaySettings));

  return <FormsAI formsList={formsList} inputName={inputName} />;
};

export default PROGRESSFormsAI;
