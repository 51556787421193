import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css';
import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { ia } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Button/Button';
import Modal from '../../../shared/Modal/Modal';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { calendarFilters, currentPractice } from '../../practiceState';
import './style/RBCalendar.scss';

const PendingAppointments = ({
  view,
  loadingPending,
  pendingAppointments,
  setPendingAppointments,
  showPendingAppointments,
  hidePendingAppointments
}) => {
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const setFilters = useSetRecoilState(calendarFilters);

  const updateAppointmentStatus = async (appointment, status) => {
    try {
      const params = { appointment, update_appointment: true, status };
      const res = await interimApi('/api/appointment/pending_appointment', params, navigate);
      if (res.data.affectedRows > 0) {
        setPendingAppointments((prevState) => prevState?.filter((p) => p.id !== appointment.id));
        setFilters((prevState) => ({ ...prevState, selectedView: view }));
        toast.success('Appointment was updated successfully');
      } else {
        toast.error('There was an error updating this appointment');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <Modal
      handleOpen={showPendingAppointments}
      handleClose={hidePendingAppointments}
      title="Pending Appointments"
      slideFromRight>
      {loadingPending ? (
        <Skeleton className="!mb-4 !rounded-md" height="250px" count={3} />
      ) : ia(pendingAppointments) ? (
        pendingAppointments.map((appointment, idx) => {
          const date = moment.tz(appointment.starts_at, practice?.timezone).format('M/D/YYYY');
          const startsAt = moment.tz(appointment.starts_at, practice?.timezone).format('h:mm A z');
          const endsAt = moment.tz(appointment.ends_at, practice?.timezone).format('h:mm A z');
          return (
            <div key={idx} className="Modal__body__group">
              <div className={'pendingAppointmentContainer'}>
                <div>
                  <p className={'pendingAppointmentLabel'}>Patient:&nbsp;</p>
                  <p className={'pendingAppointmentValue'}>{appointment.patient.fullName}</p>
                </div>
                <div>
                  <p className={'pendingAppointmentLabel'}>Practitioner:&nbsp;</p>
                  <p className={'pendingAppointmentValue'}>{appointment.practitioner.fullName}</p>
                </div>
                <div>
                  <p className={'pendingAppointmentLabel'}>Service:&nbsp;</p>
                  <p className={'pendingAppointmentValue'}>
                    {appointment.invoice?.procedures?.map?.((p) => p?.name).join(', ')}
                  </p>
                </div>
                <div>
                  <p className={'pendingAppointmentLabel'}>Time:&nbsp;</p>
                  <p className={'pendingAppointmentValue'}>
                    {date} - {startsAt} to {endsAt}
                  </p>
                </div>
                <div className={'pendingAppointmentButtons'}>
                  <Button
                    green
                    onClick={() => updateAppointmentStatus(appointment, 'approved')}
                    data-qa={`approved-btn-${idx}`}>
                    Approve
                  </Button>
                  <Button
                    red
                    onClick={() => updateAppointmentStatus(appointment, 'cancelled')}
                    data-qa={`reject-btn-${idx}`}>
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="font-600">No Pending Appointments.</div>
      )}
    </Modal>
  );
};

export default PendingAppointments;
