import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { enhanceNarrative } from 'api/MyScribeAi';
import _ from 'lodash';

export const useEnhanceNarrative = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['enhanceNarrative', ...dependencies], () => enhanceNarrative(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled:
      !_.isNil(params.kind) &&
      !_.isNil(params.name) &&
      !_.isNil(params.body) &&
      !_.isNil(params.id),
    ...options
  });
};
