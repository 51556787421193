import React, { useState, useEffect } from 'react';
import useScrollBlock from '../../../../lib/hooks/useScrollBlock';
import NewInsuranceProfile from './NewInsuranceProfile';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import InsuranceProfile from './InsuranceProfile';
import Icon from '../../../shared/Icon/Icon';
import cs from 'classnames';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import InsuranceProfileMobile from './InsuranceProfileMobile';
import { useOutletContext } from 'react-router-dom';
import EditInsuranceProfile from './EditInsuranceProfile';
import { showAlert } from 'components/shared/Alert/Alert';

export default function InsuranceProfiles({
  insuranceProfiles,
  patientId,
  selected,
  setSelected,
  practiceId,
  noInsurancePress
}) {
  const [insuranceProfileList, setInsuranceProfileList] = useState(insuranceProfiles);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [showNewInsuranceProfile, setShowNewInsuranceProfile] = useState(false);
  const [showEditInsuranceProfile, setShowEditInsuranceProfile] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const initialIsOpenState = insuranceProfiles?.reduce((acc, curr) => {
    acc[curr.id] = true;
    return acc;
  }, {});
  const [isOpen, setIsOpen] = useState(initialIsOpenState);
  const { device } = useUIContext();

  const { kiosk, fakeModal, setFakeModal } = useOutletContext();

  //new insurance profile modal
  const handleNewInsuranceProfile = () => {
    !kiosk && blockScroll();
    !kiosk && setShowNewInsuranceProfile(true);
    kiosk && setFakeModal({ type: 'new' });
  };
  const hideNewInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowNewInsuranceProfile(false);
    kiosk && setFakeModal(null);
  };

  const handleEditInsuranceProfile = (data, index) => {
    !kiosk && blockScroll();
    !kiosk && setShowEditInsuranceProfile({ data, index });
    kiosk && setFakeModal({ type: 'edit', data, index });
  };
  const hideEditInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowEditInsuranceProfile(null);
    kiosk && setFakeModal(null);
  };

  useEffect(() => {
    setInsuranceProfileList(insuranceProfiles);
  }, [insuranceProfiles]);

  const newProfile = (newProfile) => {
    setInsuranceProfileList((prev) => [...prev, newProfile]);
    setSelected && setSelected(newProfile);
  };

  const updateProfile = (updatedProfile, index) => {
    const profilesCopy = [...insuranceProfileList];
    profilesCopy[index] = updatedProfile;
    setInsuranceProfileList(profilesCopy);
  };

  const deleteProfile = (insuranceProfile) => {
    setInsuranceProfileList((prev) => prev.filter((i) => i.id !== insuranceProfile.id));
  };

  const toggleOne = (key) => {
    setIsOpen({ ...isOpen, [key]: isOpen && !isOpen[key] });
  };

  if (!Array.isArray(insuranceProfileList)) {
    return (
      <div className="mt-3 w-full">
        <Skeleton count={8} />
      </div>
    );
  }

  // console.log(showEditInsuranceProfile, fakeModal);

  return (
    <div className="h-full">
      {!fakeModal && (
        <>
          <label className="text-lg font-600 leading-10 text-neutral-800">
            Select Insurance Profile
          </label>

          <div className="grid grid-cols-2 gap-4 !pb-8 xs:flex xs:flex-col">
            {insuranceProfileList?.length === 0 && (
              <div
                data-qa="practice-charts-insuranceProfile-NoInsuranceButton"
                onClick={noInsurancePress}
                className="flex min-h-[350px] flex-col items-center justify-center !rounded-lg border-2 !border-primary-100 bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)] sm:min-h-[210px] xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4">
                <Icon
                  icon={
                    device === 'mobile' ? 'new-frame-heart-search-sm' : 'new-frame-heart-search'
                  }
                  className="animate-pulse"
                />
                <p className="!pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm">
                  I don't have insurance profile
                </p>
              </div>
            )}
            {insuranceProfileList?.length < 5 && (
              <div
                data-qa="practice-charts-insuranceProfile-NewInsuranceButton"
                onClick={handleNewInsuranceProfile}
                className={cs(
                  'group col-end-3 flex min-h-[350px] cursor-pointer flex-col items-center justify-center !rounded-lg border-2 !border-dotted border-neutral-400 py-6 shadow-[0px_4px_16px_rgba(0,0,0,0.04)] hover:!border-primary-100 hover:bg-primary-50 sm:min-h-[210px] md:col-end-2 lg:py-0 xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4',
                  { 'order-2': device === 'tablet' }
                )}
                style={{
                  gridRowEnd:
                    insuranceProfileList.length >= 3
                      ? insuranceProfileList.length
                      : insuranceProfileList.length + 1
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}>
                <div className="flex h-[54px] w-[54px] items-center justify-center rounded-2xl bg-neutral-100 group-hover:bg-[#9EE2FF] xs:h-[32px] xs:w-[32px] xs:!rounded-lg">
                  <Icon
                    icon="new-plus"
                    color={isHover ? 'primary' : 'neutral'}
                    className="cursor-pointer"
                  />
                </div>
                <p className="!pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm">
                  Create new insurance profile
                </p>
              </div>
            )}

            {insuranceProfileList?.map((insuranceProfile, index) => {
              return device === 'mobile' ? (
                <InsuranceProfileMobile
                  key={index}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                  isOpen={isOpen}
                  kiosk={kiosk}
                  toggleOne={toggleOne}
                />
              ) : (
                <InsuranceProfile
                  key={index}
                  fakeModal={fakeModal}
                  setFakeModal={setFakeModal}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  selected={selected}
                  kiosk={kiosk}
                  setSelected={setSelected}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                />
              );
            })}
          </div>
        </>
      )}
      {(showNewInsuranceProfile || fakeModal?.type === 'new') && (
        <NewInsuranceProfile
          fakeModal={fakeModal}
          newProfile={newProfile}
          patientId={patientId}
          kiosk={kiosk}
          showNewInsuranceProfile={showNewInsuranceProfile}
          hideNewInsuranceProfile={hideNewInsuranceProfile}
          practiceId={practiceId}
          insuranceProfiles={insuranceProfileList}
        />
      )}

      {(!!showEditInsuranceProfile || fakeModal?.type === 'edit') && (
        <EditInsuranceProfile
          updateProfile={updateProfile}
          index={showEditInsuranceProfile ? showEditInsuranceProfile?.index : fakeModal?.index}
          kiosk={kiosk}
          insuranceProfile={
            showEditInsuranceProfile ? showEditInsuranceProfile?.data : fakeModal?.data
          }
          patientId={patientId}
          showEditInsuranceProfile={showEditInsuranceProfile}
          hideEditInsuranceProfile={hideEditInsuranceProfile}
          fakeModal={fakeModal}
          setFakeModal={setFakeModal}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
}
