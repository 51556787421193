import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';

const Content = ({ title, nested }) => {
  return (
    <div className={cs('flex flex-col', nested && 'pb-[6px]')}>
      <span
        className={cs(
          'font-500 text-xs leading-5',
          nested ? 'px-[10px] py-[6px]' : 'px-[6px] py-[2px]'
        )}>
        {title}
      </span>
      {nested &&
        nested.map((item, idx) => (
          <NavLink
            key={idx}
            to={item.path}
            end={item.end}
            className="!px-4 !py-[6px] font-400 text-xs leading-5 flex gap-2 items-center select-none cursor-pointer hover:bg-primary-50 min-w-[180px]">
            {({ isActive }) => (
              <>
                <Icon
                  icon={item.icon}
                  size={18}
                  color="primary"
                  shade={isActive ? '500' : '900'}
                  className={cs(item.name === 'Packages')}
                />
                <span
                  className={cs(
                    'text-sm leading-[22px]',
                    isActive ? 'text-primary-500 font-500' : 'text-primary-900 font-400'
                  )}>
                  {item.name}
                </span>
              </>
            )}
          </NavLink>
        ))}
    </div>
  );
};

export default Content;
