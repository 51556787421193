import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { findStateName, snakeToTitleCase } from '../../../lib/helpers/utility';
import Icon from '../../shared/Icon/Icon';
import Input from '../../shared/Input/Input';
import Select from '../../shared/Select/Select';
import { permissions } from '../../state';
import CheckinLoginWarning from './LoginWarning';
import ErrorBoundary from '../../shared/Error/Boundary';
import Checkbox from '../../shared/Checkbox/Checkbox';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import moment from 'moment';
import { currentPractice } from 'components/practice/practiceState';
import ImagePicker from 'components/shared/Picker/ImagePicker';
import { showAlert } from 'components/shared/Alert/Alert';
import Honeybadger from '@honeybadger-io/js';
import Modal from 'components/shared/Modal/Modal';

export default function PersonalInformation({
  formik,
  genderList,
  raceList,
  stateList,
  maritalStatusList,
  getSSN,
  decodedSSN,
  setDecodedSSN,
  openFilestackPicker,
  checkinButton,
  allDataEditable,
  editableData = { emailEdit: true, phoneEdit: true },
  showWarning = false,
  kiosk
}) {
  const handleChange = formik.handleChange;
  const currentPermissions = useRecoilValue(permissions);

  const practice = useRecoilValue(currentPractice);

  const [imageEdit, setImageEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(formik?.values?.profile_photo);

  const saveImage = async (imgHandle, type) => {
    try {
      setLoading(true);
      formik.setFieldValue('profile_photo', imgHandle);
      setLoading(false);
    } catch (error) {
      Honeybadger.notify(`Error saving ${type} image: ${error}`);
      showAlert({ message: 'Uploading image failed. Please try again', color: 'danger' });
    }
  };

  return (
    <div className="!p-6">
      {showWarning && <CheckinLoginWarning data="personal data" />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="block p-3 ">
        <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
          <div className="mb-3 w-full">
            <label className="!mb-3 text-lg font-600">Basic Information</label>
            <div className="template grid grid-cols-3 gap-4 sm:flex sm:flex-col">
              {/*<Input*/}
              {/*  id="f_name"*/}
              {/*  label={'First name'}*/}
              {/*  name="f_name"*/}
              {/*  placeholder={'First Name'}*/}
              {/*  value={formik.values.f_name}*/}
              {/*  disabled={!allDataEditable}*/}
              {/*/>*/}
              {/*<Input*/}
              {/*  id="m_name"*/}
              {/*  label={'Middle name'}*/}
              {/*  type="text"*/}
              {/*  placeholder="Middle Name"*/}
              {/*  name={'m_name'}*/}
              {/*  value={formik.values.m_name}*/}
              {/*  disabled={!allDataEditable}*/}
              {/*/>*/}
              <div id="photo" className="col-span-2 hover:cursor-pointer">
                <div className="flex items-center justify-between gap-x-2">
                  <label className="pb-[6px] text-sm font-500 text-neutral-800">
                    Profile Photo
                  </label>
                  {/* <div className="flex items-center gap-x-2">
                    <Icon icon="new-edit" onClick={() => openFilestackPicker()} />
                    <Icon
                      icon="trash"
                      color="primary"
                      onClick={() => formik.setFieldValue('profile_photo', null)}
                    />
                  </div> */}
                </div>
                <div
                  onClick={() => setImageEdit('front')}
                  className="flex h-72 w-72 cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                  {formik?.values?.profile_photo ? (
                    <img
                      className=" w-full  overflow-hidden rounded-xl object-cover"
                      src={`https://cdn.filestackcontent.com/${formik?.values?.profile_photo}`}></img>
                  ) : (
                    <>
                      <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                        <Icon icon="new-file-upload" />
                      </div>
                      <div className="mt-2 text-xs font-500 text-primary-800">
                        Click here to upload.
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/*<Input*/}
              {/*  id="l_name"*/}
              {/*  label="Last name"*/}
              {/*  type="text"*/}
              {/*  placeholder="Last Name"*/}
              {/*  name={'l_name'}*/}
              {/*  value={formik.values.l_name}*/}
              {/*  disabled={!allDataEditable}*/}
              {/*/>*/}
              <div id="gender">
                <Select
                  label="Gender"
                  placeholder="Type and select Gender"
                  options={genderList}
                  value={
                    (formik.values.gender && {
                      label: snakeToTitleCase(formik.values.gender)
                    }) ||
                    null
                  }
                  onChange={({ value }) => formik.setFieldValue('gender', value)}
                />
                {formik.errors.gender && (
                  <span className="text-sm text-red-600">{formik.errors.gender}</span>
                )}
              </div>
            </div>
          </div>
          <div className="!mt-3 w-full">
            <label className="!mb-3 text-lg font-600">Background Information</label>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
              <DatePopover
                id="dob"
                name="dob"
                label="Date of birth"
                value={
                  formik.values.dob
                    ? new Date(moment.tz(new Date(formik.values.dob), practice?.timezone) || null)
                    : null
                }
                onChange={handleChange}
                formik={formik}
                error={formik.errors.dob}
              />
              <Select
                label="Ethnicity"
                options={raceList}
                placeholder="Type and select Race"
                value={(formik.values.race && { label: formik.values.race }) || null}
                onChange={({ value }) => formik.setFieldValue('race', value)}
              />
              <Input
                inputClassName="!min-w-[unset]"
                type={!decodedSSN ? 'password' : 'text'}
                label="Social Security Number"
                placeholder="Social Security Number"
                name="ssn"
                rightIcon={decodedSSN ? 'new-eye' : 'new-eye-slash'}
                value={decodedSSN ? formik.values.ssn : '*****'}
                disabled={
                  !allDataEditable &&
                  (currentPermissions?.secure?.update === false || decodedSSN === false)
                }
                onChange={handleChange}
                rightIconClick={() => (decodedSSN ? setDecodedSSN(false) : getSSN())}
              />
              <Select
                label="Marital Status"
                placeholder="Type and select Marital Status"
                options={maritalStatusList}
                value={
                  (formik.values.marital_status && { label: formik.values.marital_status }) || null
                }
                onChange={({ value }) => formik.setFieldValue('marital_status', value)}
              />
              {/* <Input
                id="personal_id"
                label="Personal ID"
                type="text"
                placeholder="Personal ID"
                name="personal_id"
                value={formik.values.personal_id}
                onChange={handleChange}
              /> */}
            </div>
            {/*{(editableData.emailEdit || editableData.phoneEdit) && (*/}
            <div className="!mt-6 w-full">
              <label className="!m-0 text-lg font-600">Contact Information</label>
              <div className="!my-3 grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4">
                {editableData.phoneEdit && (
                  <div>
                    <Input
                      id="phone_contact"
                      label="Phone number"
                      type="text"
                      placeholder="+1(123)4567890"
                      name="phone"
                      value={formik.values.phone}
                      onChange={handleChange}
                      error={formik.errors.phone}
                      required
                    />
                  </div>
                )}
                <div className="flex flex-col gap-1">
                  <Input
                    data-qa="alt_phone"
                    name="alt_phone"
                    label="Alternative Phone"
                    onChange={handleChange}
                    placeholder="+1(321)4567890"
                    value={formik?.values?.alt_phone}
                    error={formik?.errors?.alt_phone?.replace('alt_phone', 'Alternative Phone')}
                  />
                  {formik?.values?.alt_phone && (
                    <Checkbox
                      isChecked={formik.values.alt_phone_notify}
                      handleClick={() =>
                        handleChange({
                          target: {
                            name: 'alt_phone_notify',
                            value: !formik?.values?.alt_phone_notify
                          }
                        })
                      }
                      label="Enable for notifications"
                    />
                  )}
                </div>
                {editableData.emailEdit && (
                  <div>
                    <Input
                      id="email"
                      label="Email"
                      type="text"
                      placeholder="email@example.com"
                      name="email"
                      value={formik.values.email}
                      error={formik.errors.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
                <div className="flex flex-col gap-1">
                  <Input
                    data-qa="alt_email"
                    name="alt_email"
                    label="Alternative Email"
                    placeholder="alternative_email@example.com"
                    onChange={handleChange}
                    value={formik.values.alt_email}
                    error={formik?.errors?.alt_email?.replace('alt_email', 'Alternative Email')}
                  />
                  {formik?.values?.alt_email && (
                    <Checkbox
                      isChecked={formik.values.alt_email_notify}
                      handleClick={() =>
                        handleChange({
                          target: {
                            name: 'alt_email_notify',
                            value: !formik?.values?.alt_email_notify
                          }
                        })
                      }
                      label="Enable for notifications"
                    />
                  )}
                </div>
              </div>
            </div>
            {/*)}*/}
          </div>
          <div className="!mt-3 w-full">
            <label className="text-lg font-600">Address</label>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
              <div>
                <Input
                  id="address_ln_1"
                  label="Address"
                  type="text"
                  placeholder="Address"
                  name="address_ln_1"
                  value={formik.values?.address_ln_1}
                  onChange={handleChange}
                  required
                  error={formik.errors.address_ln_1}
                />
              </div>
              <div>
                <Input
                  id="address_ln_2"
                  label="Address 2"
                  type="text"
                  placeholder="Address 2"
                  name="address_ln_2"
                  value={formik.values?.address_ln_2}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Input
                  id="city"
                  label="City"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formik.values?.city}
                  onChange={handleChange}
                  required
                  error={formik.errors.city}
                />
              </div>
              <div>
                <Select
                  id="state"
                  options={stateList}
                  placeholder="Type and select State"
                  value={
                    (formik.values.state && {
                      label: findStateName({ states: stateList, stateId: formik.values.state })
                    }) ||
                    null
                  }
                  label="State"
                  onChange={({ value }) => formik.setFieldValue('state', value)}
                />
                {formik.errors.state && (
                  <span className="text-base text-red-600">{formik.errors.state}</span>
                )}
              </div>
              <div>
                <Input
                  id="zip"
                  label="Zip code"
                  type="text"
                  placeholder="Zip"
                  name="zip"
                  value={formik.values?.zip}
                  onChange={handleChange}
                  required
                  error={formik.errors.zip}
                />
              </div>
            </div>
          </div>
          <div className="!mt-3 w-full">
            <label className="text-lg font-600">Emergency Contact</label>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
              <div>
                <Input
                  id="name"
                  label="Name"
                  type="text"
                  placeholder="Full name"
                  name="name"
                  value={formik.values.emergency_contact.name}
                  onChange={(e) => formik.setFieldValue('emergency_contact.name', e.target.value)}
                />
              </div>
              <div>
                <Input
                  id="phone_emergency"
                  label="Phone Number"
                  type="text"
                  placeholder="Phone Number"
                  name="phone_emergency"
                  value={formik.values.emergency_contact.phone}
                  onChange={(e) => formik.setFieldValue('emergency_contact.phone', e.target.value)}
                />
              </div>
              <div>
                <Input
                  id="relationship"
                  label="Relationship"
                  type="text"
                  placeholder="Relation to patient"
                  name="relationship"
                  value={formik.values.emergency_contact.relationship}
                  onChange={(e) =>
                    formik.setFieldValue('emergency_contact.relationship', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered block p-3 pt-6">
          <button
            hidden
            onClick={() => {
              formik?.submitForm();
            }}
            ref={checkinButton}
            className="cursor-pointer justify-center whitespace-nowrap border border-solid border-[#dbdbdb] bg-success-500 px-4 py-2 text-center text-[#363636]">
            Continue
          </button>
        </div>
      </form>

      <Modal
        handleOpen={!!imageEdit}
        handleClose={() => setImageEdit(null)}
        className="h-full w-full !rounded-none bg-neutral-800"
        title="Add Image"
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {formik?.values.profile_photo && !loading
              ? 'Looks good?'
              : 'Start with the front of the card'}
          </p>
          <p className="mt-2 px-16 text-white">
            {formik?.values?.profile_photo && !loading
              ? 'Make sure the picture is clear'
              : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
          </p>

          <ImagePicker
            name={imageEdit}
            resetHandle={() => formik.setFieldValue('profile_photo', null, true)}
            handle={formik?.values?.profile_photo}
            onCapture={saveImage}
            handleContinue={() => setImageEdit(false)}
            kiosk={kiosk}
            dimensions={[1080, 1080]}
            loading={loading}
            handleDelete={() => formik.setFieldValue('profile_photo', null, true)}
          />
        </div>
      </Modal>
    </div>
  );
}
