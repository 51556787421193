import React from 'react';
import Patients from '../Patients';
const PatientDashboardPanel = (props) => {
  const SelectedComponent = Patients;

  return (
    <div className="p-4">
      <SelectedComponent {...props} />
    </div>
  );
};

export default PatientDashboardPanel;
