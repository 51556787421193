import { Honeybadger } from '@honeybadger-io/react';
import React from 'react';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import Button from '../../shared/Buttons/Button';
import Icon from '../../shared/Icon/Icon';
import state from './state';

const CheckinComplete = () => {
  const { isEverythingCompleted, navigateToIncompleted, steps } = useOutletContext();
  const allFinished = isEverythingCompleted(steps);
  // const allFinished = true;
  const user = useRecoilValue(state.userState);

  const navigate = useNavigate();

  const completeCheckin = async () => {
    try {
      const result = await interimApi(
        '/api/patient/checkin/complete',
        {
          stepsCompleted: allFinished
        },
        navigate
      );
      const { code, redirect = '/login', error } = result.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          navigate(redirect);
          break;
        default:
          Honeybadger.notify(
            `file: checkin/CheckinComplete, method: completeCheckin - try, error: ${error ?? 'Theres been an error'
            }`
          );
          if (error) showAlert({ message: error, color: 'danger' });
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/CheckinComplete, method: completeCheckin - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  // <div className="max-w-screen-xl mx-auto px-4 py-32 lg:flex lg:h-screen lg:items-center">
  // {/* <div className="mx-auto max-w-3xl rounded-lg bg-neutral-50 !p-4 text-center"> */}
  return (
    <div className="flex flex-col justify-center items-center !p-4">
      <Icon icon={'success-2'} />
      <h1 className="text-2xl text-neutral-900 sm:text-3xl !mt-6 !px-6 text-center sm:!px-4 ">
        {allFinished
          ? 'Thank you for providing this vital information to ensure an excellent patient experience.'
          : 'Thank you for completing some of the medical steps.'}
      </h1>
      <h3 className="text-lg text-primary-700 sm:block sm:text-xl !px-6 text-center !pt-2 sm:!px-4">
        {allFinished
          ? user?.isOnboarding
            ? 'Please set up your patient portal login to access future healthcare information from your provider.'
            : 'All done!'
          : 'Please finish the remaining steps so we can provide the best possible care.'}
      </h3>

      {/* <p className="mt-4 w-full sm:text-sm">
        {allFinished
          ? 'Let us know if you have any questions or concerns.'
          : 'Contact us if you need help or have questions.'}
      </p> */}

      <div className="mt-8 w-full flex flex-col flex-wrap justify-center items-center gap-2 !pt-4">
        {!allFinished && (
          <Button
            data-qa="return-to-steps-btn"
            text="Return to steps"
            onClick={() => navigateToIncompleted({ steps, ignoreIsStepDone: true })}
            className={'w-full max-w-[20rem]'}
          />
        )}
        <Button
          text={user.isOnboarding ? 'Continue to Account Setup' : 'Complete'}
          color="primary"
          outlined={!allFinished}
          onClick={() => completeCheckin()}
          data-qa="complete-btn"
          className={'w-full max-w-[20rem]'}
        />
      </div>
    </div>
  );
  // </div>
};

export default CheckinComplete;
