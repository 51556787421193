import React, { Suspense, useEffect, useRef, useState } from 'react';

import { Environment, Line, OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';

import { useChart3dContext } from 'lib/context/Chart3dContext/Chart3dContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa, io } from 'lib/helpers/utility';
import { capitalize, isEmpty, isEqual } from 'lodash';
import { ACESFilmicToneMapping } from 'three';
import Model from '../../../CurrentNote/TypeNotes/3DNote/components/Model';
import Point from '../../../CurrentNote/TypeNotes/3DNote/components/Point';
import RenderPoint from '../../../CurrentNote/TypeNotes/3DNote/components/RenderPoint';
import RenderNarrativeValue from './RenderNarrativeValue';

import { sides } from '../../../../../../../lib/context/Chart3dContext/lib/initials';

const Scene = ({ preview, isChecked, side, setSide, setImages, points, items, sides, model }) => {
  const sceneRef = useRef();
  const { gl, camera, scene } = useThree();

  const timeout = useRef();

  const render = (nextSide) => {
    setTimeout(() => {
      gl.autoClear = true;
      gl.render(scene, camera);
      gl.autoClear = false;
      gl.clearDepth();
      gl.render(sceneRef?.current, camera);

      const screenshot = gl.domElement.toDataURL();

      setImages((images) => [...images, screenshot]);

      setSide(nextSide);
    }, 150);
  };

  useEffect(() => {
    if (side == 'front') clearTimeout(timeout.current);
    if (side != null && side != 'print') {
      timeout.current = setTimeout(() => {
        const pos = sides?.[side]?.position;
        camera.position.set(pos[0], pos[1], pos[2]);
        if (isChecked(sides?.[side]?.name)) {
          render(sides?.[side]?.nextSide);
        } else {
          setSide(sides?.[side]?.nextSide);
        }
      }, 200);
    }
  }, [side]);

  return (
    <Suspense fallback={null}>
      {/* <Model preview={preview} /> */}
      {/* <group dispose={null}>
        <primitive scale={0.7} object={model?.scene} />
      </group> */}

      <Model />
      {points?.map(
        (point) =>
          point?.draw &&
          point?.draw?.map(
            (line, idx) =>
              line?.length > 1 && (
                <Line
                  key={'line' + idx}
                  points={line}
                  color={items?.find((p) => p.id === point.product)?.color || 'white'}
                  lineWidth={2}></Line>
              )
          )
      )}
      <scene ref={sceneRef}>
        {side
          ? points?.map((point, index) => (
              <RenderPoint
                key={'p' + index}
                sides={sides}
                side={side}
                items={items}
                point={point}
                index={index}
              />
            ))
          : points?.map((point, index) => (
              <Point preview={preview} point={point} index={index} key={'p' + index} />
            ))}
      </scene>
    </Suspense>
  );
};

const Chart3d = ({ checkedFormTypes = {}, customClinicalNote }) => {
  const { previewNoteModal, isExport, clinicalNote, selected } = useClinicalNoteContext() || {};

  const charts3d = customClinicalNote
    ? customClinicalNote?.clinicalNote?.charts3d
    : clinicalNote?.charts3d;

  const [side, setSide] = useState('front');
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (selected?.chart3d?.checked) {
      setImages([]);
      setSide('front');
    }
  }, [
    selected?.chart3d?.checked,
    selected?.chart3d?.formType[0],
    selected?.chart3d?.formType[1],
    selected?.chart3d?.formType[2]
  ]);

  const type = 'face';
  const note = charts3d?.[type]?.note;

  const points = charts3d?.[type]?.points;
  const products = charts3d?.products;
  const procedures = charts3d?.procedures;
  const items = [...iaRa(products), ...iaRa(procedures), ...iaRa(charts3d?.icd10)];

  const [narrative, setNarrative] = useState();

  const male = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_male.glb`);
  // const female = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_female.glb`);
  // const muscle = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_muscles.glb`);

  const model = male;

  useEffect(() => {
    if (!io(checkedFormTypes)) {
      return;
    }
    setNarrative(generateNarrative());
  }, [products, procedures, type]);

  const canvasRef = useRef();

  const generateNarrative = () => {
    let narratives = [];
    const uniqueAreas = [...new Set(points?.map((obj) => obj?.area))];
    uniqueAreas.forEach((area) => {
      let combinedPoints = [];
      const areaPoints = points.filter((point) => point?.area === area);
      areaPoints.forEach((point) => {
        const item = items.find((item) => item.id === point.product)?.name;

        const prevPoint = combinedPoints?.find(
          (p) => p?.item === item && p?.type === point.type && p?.note == point.note
        );

        if (prevPoint) {
          prevPoint.units += point.units;
        } else {
          combinedPoints.push({ ...point, item });
        }
      });

      combinedPoints.forEach((point) => {
        narratives.push(
          `${point.units} ${point.units > 1 ? 'units' : 'unit'} of "${point.item}" ${point.type} ${
            point?.area
          }` + (point.note != null ? `\nWith note: ${point.note}\n` : '\n')
        );
      });
    });

    const narrative =
      (note != null ? `General Note: ${note}\n\n` : '') + `${narratives.join('\n')}`;

    return narrative;
  };

  const areItemsChecked = checkedFormTypes?.isChecked('Items');

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <div ref={canvasRef} className="grid gap-1">
        <h3 className="mb-3 text-sm  font-500 text-[#003A4B]">3D Charting</h3>

        {(previewNoteModal || isExport) && side !== 'print' && model?.scene && side ? (
          <Canvas
            className="!h-[600px] flex-1 rounded-xl bg-primary-50"
            linear
            gl={{ antialias: true, toneMappingExposure: 3, toneMapping: ACESFilmicToneMapping }}
            flat
            camera={{ position: [0, 15, 30], fov: 25 }}>
            <Scene
              side={side}
              setSide={setSide}
              points={points}
              setImages={setImages}
              items={items}
              sides={sides}
              isChecked={checkedFormTypes?.isChecked}
              preview={true}
              model={model}
            />

            <OrbitControls
              makeDefault
              enableZoom={false}
              maxPolarAngle={Math.PI / 1.7}
              maxDistance={6.5}
              minDistance={5}
            />
          </Canvas>
        ) : (
          <></>
        )}

        {images && images.length > 0 && (
          <div className={`grid grid-cols-${images.length} gap-1`}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className={`rounded-xl ${
                  images.length == 1 ? 'mx-auto w-1/2' : 'w-full'
                } aspect-[0.7] object-cover`}
              />
            ))}
          </div>
        )}

        {areItemsChecked && (
          <>
            <div className="text-md mt-3 font-500 text-neutral-700">Items</div>
            <table className="primary-table mt-2 shadow-none">
              <thead className="shadow-none">
                <tr className="px-3">
                  <th>Color</th>
                  <th>LOT</th>
                  <th>Name</th>
                  <th>Type</th>

                  <th>Category</th>
                  <th>Units</th>
                </tr>
              </thead>
              <tbody>
                {iaRa(items).map((product, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        className="h-[20px] w-[20px]"
                        style={{ backgroundColor: product?.color }}></div>
                    </td>
                    <td>{product?.lot}</td>
                    <td>{product?.name}</td>
                    <td>{capitalize(product?.item_type) || 'Product'}</td>
                    <td>{product?.category}</td>
                    <td>
                      <b>{product?.sales_count}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {checkedFormTypes.isNarrativeChecked && !isEmpty(narrative) && (
          <div className="!mt-3">
            <RenderNarrativeValue narrative={narrative} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart3d;
