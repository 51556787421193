import React from 'react';
import Icon from '../../../../../shared/Icon/Icon';
import Input from '../../../../../shared/Input/Input';
import { withErrorBoundary } from '../../../../../shared/Error/Boundary';

const EligibilitySideBar = ({
  searchTerm,
  setSearchTerm,
  filteredArrays,
  selectedBenefit,
  handleScrollToSection
}) => {
  const windowHeight = window.innerHeight - 73;

  return (
    <div
      className={`h-[${windowHeight.toString()}px] flex !w-[370px] flex-col overflow-auto  !px-4 !py-5`}>
      <Input
        placeholder="Search benefit plan category"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        icon={'search'}
        rounded="full"
        rightText={!!searchTerm && <Icon icon="new-close" onClick={() => setSearchTerm('')} />}
      />
      <div className="mt-3 flex-grow  !rounded-2xl border border-solid !border-primary-200 bg-primary-50 px-3 !py-5">
        {filteredArrays.map((elig, index) => {
          const isSelected = elig.groupName === selectedBenefit;
          return (
            <div
              className={`mt-3 cursor-pointer !px-2 py-[6px] ${
                isSelected && '!rounded-lg border border-solid !border-primary-500 bg-white'
              }`}
              key={index}
              onClick={() => handleScrollToSection(elig.groupName || 1000)}>
              <p className={`text-sm ${isSelected && 'font-600 leading-6 text-primary-500'}`}>
                {elig.groupName}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withErrorBoundary(EligibilitySideBar);
