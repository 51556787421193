import React, { useEffect, useState } from 'react';

import Button from '../../../../../../../shared/Buttons/Button';

import cs from 'classnames';

import ProductsModal from './ProductsModal';
import Icon from '../../../../../../../shared/Icon/Icon';
import { useChart3dContext } from '../../../../../../../../lib/context/Chart3dContext/Chart3dContext';
import Confirm from '../../../../../../../shared/Modal/Confirm/Confirm';
import { formatDate } from '../../../../../../../../lib/helpers/utility';
import Modal from '../../../../../../../shared/Modal/Modal';
import Input from '../../../../../../../shared/Input/Input';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { capitalize } from 'lodash';

export default function App({ openSidebar, locked = false }) {
  const {
    selectedItem,
    setSelectedItem,
    items,
    setNewItemModalVisible,
    newItemModalVisible,
    setSelected,
    setPoints,
    filterItem,
    setItems,
    note,
    setNote,
    setDrawing
  } = useChart3dContext();

  const [deleteItemModal, setDeleteItemModal] = useState(null);

  const [addDilutionModal, setAddDilutionModal] = useState(null);

  const setProduct = (product) => {
    if (locked) return;

    const itemIndex = items?.findIndex((p) => p.id === product.id);
    if (itemIndex >= 0) {
      setItems((items) => {
        const newItems = [...items];
        return newItems;
      });
    } else {
      setItems([...items, { visible: true, ...filterItem(product) }]);
    }
    setSelectedItem(product.id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (locked) return;

    const itemIndex = items?.findIndex((p) => p.id === deleteItemModal);
    setDrawing(false);
    setSelected(null);
    if (selectedItem == deleteItemModal) {
      setSelectedItem(null);
    }
    if (itemIndex >= 0) {
      const newItems = [...items];
      newItems.splice(itemIndex, 1);

      setPoints((points) => points?.filter((p) => p.product !== items[itemIndex].id));
      setItems(newItems);
    }
    setDeleteItemModal(null);
  };

  const dilutionUnits = [
    { value: 'ml', label: 'mL' },
    { value: 'units', label: 'Units' }
  ];

  const setDilution = ({ dilution, unit }) => {
    if (locked) return;

    if (addDilutionModal >= 0) {
      const newItems = [...items];
      if (dilution !== null) {
        newItems[addDilutionModal].dilution = dilution;
        newItems[addDilutionModal].dilution_unit =
          newItems[addDilutionModal].dilution_unit ?? dilutionUnits[0].label;
      }
      if (unit) {
        newItems[addDilutionModal].dilution_unit = dilutionUnits.find((d) => d.value == unit).label;
      }
      setItems(newItems);
    }
  };

  const setData = (type, value) => {
    if (locked) return;

    if (addDilutionModal >= 0) {
      const newItems = [...items];
      newItems[addDilutionModal][type] = value;
      setItems(newItems);
    }
  };

  return (
    <>
      <ProductsModal
        handleOpen={newItemModalVisible}
        handleClose={() => setNewItemModalVisible(false)}
        setProduct={setProduct}
      />

      <div
        className={cs(
          'flex cursor-pointer items-center justify-between rounded-lg !border border-solid border-transparent border-l-primary-50 border-r-primary-100 bg-gradient-to-r from-primary-50 to-primary-100 !px-4 !py-2',
          open && '!border-primary-500',
          locked && '!cursor-not-allowed'
        )}
        data-qa="add"
        onClick={() => {
          if (!locked) setNewItemModalVisible(true);
        }}>
        <Icon
          icon="new-dropdown-select"
          className="grid h-9 w-9 cursor-pointer place-content-center rounded-full bg-white"
        />

        <label className="!mb-0 cursor-pointer text-sm font-500 text-primary-500">Add</label>

        <Icon
          color="primary"
          icon="new-add-circle"
          className={cs(
            'grid h-6 w-6 cursor-pointer place-content-center rounded-full bg-primary-200'
          )}
        />
      </div>

      {items && items.length > 0 ? (
        items.map((item, index) => {
          const active = selectedItem !== null && item.id === selectedItem;

          return (
            <div
              key={(item?.id || item?.procedure_code) + index}
              onClick={() => setProduct(item)}
              className={cs(
                'flex flex-col cursor-pointer gap-3 p-3 border border-solid border-neutral-100 rounded',
                active && '!border-primary-500 bg-primary-50 !border-dashed shadow-sm'
              )}>
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    {/* {product.image ? (
                      <div className="flex h-[28px] w-[28px]">
                        <img
                          className="h-full rounded-lg object-cover"
                          src={`https://cdn.filestackcontent.com/${
                            JSON.parse(product.image).jpg
                          }`}></img>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    <div
                      className="w-[20px] h-[20px] rounded-lg"
                      style={{ backgroundColor: item.color }}></div>
                    <p className="text-primary-900 flex-1 flex text-lg items-end pr-3">
                      {item.name}
                      <span className="text-xs ml-[6px] text-primary-900 opacity-70 mb-1">
                        {capitalize(item.item_type)}
                      </span>
                    </p>
                  </div>

                  <div className="flex gap-2 mt-1 mb-1">
                    {(item?.item_type === 'product' || !item?.item_type) && (
                      <Icon
                        data-qa="edit-icon"
                        className="cursor-pointer"
                        icon="glass"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddDilutionModal(index);
                        }}></Icon>
                    )}
                    <Icon
                      data-qa="visible-icon"
                      className="cursor-pointer"
                      icon={item.visible ? 'new-eye' : 'new-eye-slash'}
                      color={item.visible ? 'primary' : 'neutral'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setItems((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems[index] = {
                            ...updatedItems[index],
                            visible: !updatedItems[index].visible
                          };
                          setSelected(null);

                          return updatedItems;
                        });
                      }}></Icon>
                    <Icon
                      data-qa="delete-icon"
                      className="cursor-pointer"
                      icon="trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteItemModal(item.id);
                      }}></Icon>
                  </div>
                </div>
                <div className="flex justify-between mt-1 pt-1 gap-3">
                  <div
                    className={cs(
                      openSidebar && '!flex-col',
                      'flex lg:flex-col md:flex-col sm:flex-col gap-2'
                    )}>
                    {item.lot && (
                      <p className="text-primary-900  items-center flex text-xs">
                        Lot
                        <span
                          className={cs(
                            'text-primary-900 text-sm ml-1 flex font-semibold justify-center items-center rounded-full px-[8px] !min-w-[16px] !h-[24px] bg-primary-900 bg-opacity-10 '
                          )}>
                          {item.lot}
                        </span>
                      </p>
                    )}
                    {item.expiration_date && (
                      <p className="text-primary-900 items-center flex text-xs">
                        Expiration
                        <span
                          className={cs(
                            'text-primary-900 text-sm ml-1 flex font-semibold justify-center items-center rounded-full px-[8px] !min-w-[16px] !h-[24px] bg-primary-900 bg-opacity-10 '
                          )}>
                          {formatDate(item.expiration_date)}
                        </span>
                      </p>
                    )}
                    {item.dilution && (
                      <p className="text-primary-900 items-center flex text-xs">
                        Dilution
                        <span
                          className={cs(
                            'text-primary-900 ml-1 text-sm flex font-semibold justify-center items-center rounded-full px-[8px] !min-w-[16px] !h-[24px] bg-primary-900 bg-opacity-10 '
                          )}>
                          {item.dilution} {item.dilution_unit}
                        </span>
                      </p>
                    )}
                  </div>
                  <div
                    className={cs(
                      openSidebar && '!flex-col !items-end',
                      'flex justify-end md:items-end md:flex-col lg:items-end lg:flex-col sm:flex-col sm:items-end gap-2'
                    )}
                    data-qa="productServiceIcd-click">
                    <p className={cs('text-neutral-900  items-center flex text-xs')}>
                      Units
                      <span
                        className={cs(
                          'text-neutral-900 ml-1 text-sm flex font-semibold justify-center items-center rounded-full px-[8px] !min-w-[16px] !h-[24px] bg-primary-900 bg-opacity-10'
                        )}>
                        {item.sales_count >= item.invoice_sales_count
                          ? item.sales_count
                          : item.invoice_sales_count}
                      </span>
                    </p>
                    {(item.item_type == 'product' || item.item_type == null) && (
                      <p className={cs('text-primary-900  items-center flex text-xs')}>
                        Inventory
                        <span
                          className={cs(
                            'text-primary-900 ml-1 flex text-sm font-semibold justify-center items-center rounded-full px-[8px] !min-w-[16px] !h-[24px] bg-primary-900 bg-opacity-10 '
                          )}>
                          {item.quantity - item.sales_count}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex flex-col flex-1 justify-center mb-12 items-center">
          <Icon
            icon="new-dropdown-select"
            className="grid h-12 w-12 mb-7 scale-150 place-content-center rounded-full bg-primary-50"
          />
          <p className="text-lg text-center font-medium">
            Get started by adding items <br /> for your 3D Charting!{' '}
          </p>
          <Button
            onClick={() => setNewItemModalVisible(true)}
            icon="add-square"
            text="Add Item"
            className="mt-3"
          />
        </div>
      )}

      <Textarea
        onChange={(e) => setNote(e.target.value)}
        value={note}
        placeholder="Add your note here..."></Textarea>

      {deleteItemModal !== null && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title={`Delete ${capitalize(
            items.find((p) => p.id === deleteItemModal)?.item_type || 'product'
          )}`}
          icon="trash"
          message={`Are you sure you want to delete ${capitalize(
            items.find((p) => p.id === deleteItemModal)?.item_type || 'product'
          )} and all its points?`}
          handleContinue={handleDelete}
          handleOpen={deleteItemModal !== null}
          handleClose={() => setDeleteItemModal(null)}
        />
      )}

      {items[addDilutionModal] != null && (
        <Modal
          isOpen={addDilutionModal !== null}
          slideFromRight
          title="Edit product"
          handleClose={() => setAddDilutionModal(null)}>
          <div className="flex flex-col gap-3">
            <Input
              data-qa="dilution"
              label="Dilution"
              id="dilution"
              placeholder="Enter dilution amount"
              type="number"
              value={items[addDilutionModal]?.dilution}
              onChange={(e) => setDilution({ dilution: e.target.value })}
              units={dilutionUnits}
              currentUnit={
                items[addDilutionModal]?.dilution_unit
                  ? items[addDilutionModal]?.dilution_unit
                  : dilutionUnits[0].label
              }
              onUnitChange={(value) => setDilution({ unit: value })}
            />

            <Input
              data-qa="enter-lot"
              label="LOT"
              id="lot"
              placeholder="Enter LOT"
              value={items[addDilutionModal]?.lot}
              onChange={(e) => setData('lot', e.target.value)}
            />

            <Popover className="relative -mt-[3px]">
              {({ close }) => (
                <>
                  <Popover.Button className="w-full p-0">
                    <Input
                      label="Expiration date"
                      name="expiration_date"
                      rightIcon="new-calendar-gray"
                      placeholder={
                        items[addDilutionModal]?.expiration_date
                          ? formatDate(items[addDilutionModal]?.expiration_date)
                          : 'Select a date'
                      }
                      id="productExpirationDateInput"
                      data-qa="product-calendar"
                    />
                  </Popover.Button>

                  <Popover.Panel className="absolute right-0 z-10 flex shadow-md">
                    <Calendar
                      date={
                        items[addDilutionModal]?.expiration_date
                          ? new Date(items[addDilutionModal]?.expiration_date)
                          : new Date()
                      }
                      onChange={(date) => {
                        setData('expiration_date', moment.utc(date).add(12, 'h'));
                        close();
                      }}
                      id="productExpirationDateCalendar"
                    />
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
        </Modal>
      )}
    </>
  );
}
