import NewPatientLedger from 'components/practice/BeyondBilling/PatientLedger/NewPatientLedger';
import React from 'react';

const Ledger = () => {
  return (
    <div className="mt-0 flex h-full flex-col overflow-hidden bg-white !p-0">
      <NewPatientLedger name="ledger" />
    </div>
  );
};

export default Ledger;
