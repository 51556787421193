import { Popover } from '@headlessui/react';
import cs from 'classnames';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ia } from '../../../lib/helpers/utility';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';
import Transcribe from '../Transcribe/Transcribe';
import './TextEditor.scss';

const TextEditor = ({
  className,
  placeholder,
  error,
  label,
  value = '',
  onChange = () => {},
  media = { toolbar: [], formats: [] },
  transcribing = false,
  options,
  disabled = false,
  setDisabled = () => {},
  icon,
  name
}) => {
  const [isTranscribing, setIsTranscribing] = useState(false);

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { header: '3' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      media.toolbar
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'blockquote',
    'list',
    'bullet',
    'indent',
    ...media.formats
  ];

  const onSetDisable = (event) => {
    setDisabled(event);
    setIsTranscribing(event);
  };

  return (
    <div>
      {(label || transcribing || options) && (
        <div className="flex min-h-[28px] items-center justify-between">
          <label className="m-0 !pb-2 !pr-4 text-sm font-500 text-neutral-800">{label}</label>
          <div className="ml-auto flex items-center gap-x-1">
            {transcribing && (
              <Transcribe
                value={value}
                setValue={onChange}
                setDisable={onSetDisable}
                disabled={disabled}
              />
            )}
            {icon}
          </div>
          {ia(options) && (
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={cs(
                      'custom-btn flex !h-6 !w-6 cursor-pointer items-center justify-center rounded-[3px] bg-transparent p-0 hover:!bg-neutral-100',
                      open && '!bg-primary-700 hover:!bg-primary-700'
                    )}>
                    <Icon icon="new-candle" className="cursor-pointer" color={open && 'white'} />
                  </Popover.Button>
                  <Popover.Panel className="absolute right-0 top-full z-[90] mt-[6px] grid w-max cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
                    <div>
                      {ia(options) &&
                        options.map(({ title, icon, onClick = () => {}, loading }, index) => (
                          <div
                            onClick={() => !loading && onClick()}
                            key={index}
                            className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50">
                            {loading ? (
                              <Loader outlined className="text-primary-900" />
                            ) : (
                              <Icon icon={icon} className="cursor-pointer" />
                            )}
                            <span className="text-sm font-400 leading-4 text-primary-900">
                              {title}
                            </span>
                          </div>
                        ))}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          )}
        </div>
      )}
      <ReactQuill
        className={cs(
          'relative z-20 whitespace-pre-line !rounded-lg bg-white',
          className,
          disabled && 'cursor-not-allowed'
        )}
        onChange={onChange}
        value={value}
        modules={modules}
        formats={formats}
        bounds=".app"
        placeholder={placeholder}
        readOnly={disabled}
      />
      {error && <p className={cs('!pt-2 text-sm text-danger-500')}>{error}</p>}
    </div>
  );
};

export default TextEditor;
