import React from 'react';
import { useOutletContext } from 'react-router-dom';

import Status from '../../../shared/Status/Status';
import { formatDateAndTime, ia, mString } from '../../../../lib/helpers/utility';

const AppointmentsRow = ({ type }) => {
  const {
    practice,
    appointments,
    setShowModal,
    getAppointment,
    getAdvancedSOAP,
    setSelectedAppointment
  } = useOutletContext();

  if (ia(appointments)) {
    return appointments.map((appointment, i) => (
      <tr
        data-qa={`${type}-appointment-${i}`}
        key={i}
        className="cursor-pointer odd:!bg-[#F8FDFF] even:!bg-white"
        onClick={() => {
          setShowModal(true);
          getAppointment(appointment?.id);
          setSelectedAppointment(appointment);
          getAdvancedSOAP(appointment?.id, appointment?.patient?.id);
        }}>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {appointment.practitioner?.fullName}
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {(appointment?.invoice?.procedures || []).map?.((p) => p?.name).join(', ')}
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {mString(appointment?.invoice?.balance)}
        </td>
        <td className="font-300 !border-0 !p-2 text-sm text-primary-900">
          {<Status status={appointment.status} className="w-max" />}
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {formatDateAndTime(appointment?.starts_at, practice?.timezone)}
        </td>
      </tr>
    ));
  } else {
    return (
      <tr>
        <td colSpan={5} className="text-center">
          No appointments!
        </td>
      </tr>
    );
  }
};

export default AppointmentsRow;
