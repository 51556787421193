import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { cmToFt, ia, iaRa, separatePoundsAndOunces } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';

const Vitals = ({ customClinicalNote = null }) => {
  const { currentHpOverviewData, overviewData } = customClinicalNote ?? useClinicalNoteContext();
  const [vital, setVital] = useState(currentHpOverviewData?.vitals ?? overviewData?.vitals?.[0]);
  const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);

  useEffect(() => {
    setVital(currentHpOverviewData?.vitals ?? overviewData?.vitals?.[0]);
  }, [currentHpOverviewData?.vitals, overviewData?.vitals]);

  if (customClinicalNote && !vital.id) {
    return null;
  }

  const bloodPressure =
    vital?.blood_pressure_systolic || vital?.blood_pressure_diastolic
      ? `${vital?.blood_pressure_systolic || ''} / ${vital?.blood_pressure_diastolic || ''}`
      : '';

  const weight = pounds || ounces ? `${pounds || ''} lb ${ounces || ''} oz` : '';

  const vitalMapping = [
    { label: 'Blood pressure', value: bloodPressure, unit: 'mmHg' },
    { label: 'Heart rate', value: vital?.heart_rate, unit: 'bpm' },
    { label: 'Respirations', value: vital?.respirations, unit: 'bpm' },
    { label: 'Weight', value: weight },
    { label: 'Temperature', value: vital?.temperature, unit: '°F' },
    { label: 'Height', value: cmToFt(vital?.height), unit: 'ft' },
    { label: 'Bmi', value: vital?.bmi, unit: 'kgm²' },
    { label: 'Oxygen level', value: vital?.oxygen_level, unit: '%' },
    { label: 'Note', value: vital?.notes, colSpan: true }
  ];

  const renderVital = ({ label, value, unit, colSpan }) => {
    return (
      value &&
      value !== 'null' &&
      value !== '' && (
        <div className={`grid ${colSpan ? 'col-start-1 col-end-4' : ''}`}>
          <p className="text-sm font-500 text-neutral-700 print:text-base">{label}</p>
          <p className="text-sm font-500 text-[#003A4B] first-letter:capitalize print:text-base">
            {value} {unit || ''}
          </p>
        </div>
      )
    );
  };

  return (
    <div className="bg-white !px-6 !py-5">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Vitals</h3>
      {vital?.id ? (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(90px,1fr))] gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0 print:grid-cols-6 print:gap-x-6">
          {iaRa(vitalMapping).map((v, idx) => renderVital({ ...v, key: idx }))}
        </div>
      ) : (
        <p className="!text-sm font-500 text-[#003A4B] print:text-base">No vitals data available</p>
      )}
    </div>
  );
};

export default Vitals;
