import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { usePatient } from '../../../../../../lib/hooks/queries/patients/usePatient';
import { Capitalize, formatPhoneNumber } from '../../../../../../lib/helpers/utility';
import ProfilePicture from '../../../../../Profile/ProfilePicture';
import moment from 'moment-timezone';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import SkeletonLib from 'react-loading-skeleton';
import Icon from '../../../../../shared/Icon/Icon';
import { useUIContext } from '../../../../../../lib/context/UIContext/UIContext';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const PatientOverview = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = usePatient({ params: { id: id }, dependencies: [id] });
  const patient = data?.patient;
  const { openSidebar, setOpenSidebar } = useOutletContext();
  const { device } = useUIContext();
  const { cnDisplaySettings, setCNDisplaySettings } = useClinicalNoteContext();

  if (isLoading || isFetching)
    return (
      <div className="grid grid-cols-[auto,1fr] gap-x-3 !py-[6px] !px-4">
        <SkeletonLib
          circle
          count={1}
          width={36}
          height={36}
          baseColor="#EEEEEE"
          highlightColor="#FAFAFA"
        />
        <div className="grid grid-cols-5 gap-x-3">
          <Skeleton height="34px" />
          <Skeleton height="34px" />
          <Skeleton height="34px" />
          <Skeleton height="34px" />
        </div>
      </div>
    );

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
    const updatedSettings = {
      ...cnDisplaySettings,
      toggleRSidebar: {
        ...cnDisplaySettings?.toggleRSidebar,
        enabled: !cnDisplaySettings?.toggleRSidebar?.enabled
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  return (
    <div className="flex justify-between items-center bg-primary-50 !py-2 !px-4 z-20 sticky top-0 sm:gap-3">
      <div className="flex gap-x-3">
        <ProfilePicture
          firstName={patient?.f_name}
          lastName={patient?.l_name}
          id={patient.id}
          image={patient.profile_photo}
          size={36}
        />
        {device === 'desktop' ? (
          <div className="flex gap-x-6 lg:gap-x-4 gap-y-2">
            <div className="grid gap-y-[1px]">
              <div className="flex gap-x-1 items-center">
                <span className="font-500 text-base text-primary-900 capitalize leading-4 whitespace-nowrap">{`${patient.f_name} ${patient.l_name}`}</span>
                <span className="text-sm text-primary-800 italic leading-4">{`#${patient.id}`}</span>
              </div>
              <span className="text-neutral-800 text-sm leading-4">{`Phone: ${formatPhoneNumber(
                patient.phone
              )}`}</span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="font-500 text-sm text-primary-800 leading-4">Date of birth</span>
              <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                {moment.utc(patient.dob).format('Do MMM, YYYY')}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="font-500 text-sm text-primary-800 leading-4">Age</span>
              <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                {moment.utc().diff(patient.dob, 'years')}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="font-500 text-sm text-primary-800 leading-4">Gender</span>
              <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                {Capitalize(patient.gender)}
              </span>
            </div>
            {device === 'desktop' && (
              <div className="grid gap-y-[1px]">
                <span className="font-500 text-sm text-primary-800 leading-4">Address</span>
                <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                  {patient?.userAddress?.fullAddress}
                </span>
              </div>
            )}
            <div className="grid gap-y-[1px]">
              {device === 'desktop' ? (
                <span className="font-500 text-sm text-primary-800 leading-4">Email</span>
              ) : (
                <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                  {patient?.userAddress?.fullAddress}
                </span>
              )}
              <span className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap">
                {patient.email}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex !gap-3 gap-y-2">
            <div className="grid gap-y-[1px]">
              <div className="flex gap-x-1 items-center">
                <span className="font-500 text-base text-primary-900 capitalize leading-4 whitespace-nowrap">{`${patient.f_name} ${patient.l_name}`}</span>
                <span className="text-sm text-primary-800 italic leading-4">{`#${patient.id}`}</span>
              </div>
              <span className="text-neutral-800 text-sm leading-4">{`Phone: ${formatPhoneNumber(
                patient.phone
              )}`}</span>
            </div>
            <div className="grid gap-y-[1px]">
              <div className="flex gap-x-[2px]">
                <span
                  className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap"
                  title={moment.utc().diff(patient.dob, 'years')}>
                  {moment.utc().diff(patient.dob, 'years')}
                </span>
                {patient.gender && (
                  <span
                    className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap"
                    title={Capitalize(patient.gender)}>
                    {`- ${Capitalize(patient.gender)}`}
                  </span>
                )}
              </div>
              <span
                className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap"
                title={moment.utc(patient.dob).format('Do MMM, YYYY')}>
                {moment.utc(patient.dob).format('Do MMM, YYYY')}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span
                className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap"
                title={patient?.userAddress?.fullAddress}>
                {patient?.userAddress?.fullAddress}
              </span>
              <span
                className="text-sm text-neutral-800 leading-4 text-ellipsis overflow-hidden whitespace-nowrap"
                title={patient.email}>
                {patient.email}
              </span>
            </div>
          </div>
        )}
      </div>
      {!openSidebar && (
        <div
          data-qa="toggle-right-sidebar"
          className="w-[30px] h-[30px] min-w-[30px] flex items-center cursor-pointer justify-center !rounded-md border !border-primary-100 border-solid"
          onClick={toggleSidebar}>
          <Icon icon="new-notev1" className="cursor-pointer" />
        </div>
      )}
    </div>
  );
};

export default PatientOverview;
