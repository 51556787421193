import React from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { concat, map } from 'lodash';

import {
  displayDiagnosis,
  calcAdjustment,
  handleAdjustmentCodes,
  handleProcAdjustmentCodes
} from '../ERA&EOBHelpers';
import { formatDate, mString } from '../../../../../../lib/helpers/utility';
import { calcInsPaid } from './lib/calcInsPaid';
import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';

const MatchedClaimsRows = ({ era, timezone, codes }) => {
  const findERAIndex = (insurance_payment) => {
    return insurance_payment?.era?.findIndex((claim) => claim.era_id == era?.id);
  };

  return (
    <table className="primary-table responsive-table">
      <tr className="!background-transparent !w-[100%]">
        <td className="">
          <table className="success">
            <thead>
              <tr>
                <th>
                  <span className="font-semibold text-primary-900">Posted</span>/
                  <span className="font-medium text-primary-700">DoS</span>
                </th>
                <th>
                  <span className="font-semibold text-primary-900">Patient</span>/
                  <span className="font-medium text-primary-700">Service</span>
                </th>
                <th>
                  <span className="font-semibold text-primary-900">Provider</span>/
                  <span className="font-medium text-primary-700">Modifiers</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Diagnosis</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Billed</span>
                </th>
                <th className="w-[140px]">
                  <span className="!font-semibold !text-primary-900">Adjmt</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Ins. Paid</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Pt. Paid</span>
                </th>
              </tr>
            </thead>

            <tbody className="overflow-hidden">
              {era.claims.map((claim, i) => {
                const insType = claim?.payer_order || 'primary';

                return (
                  <tbody key={claim?.id + i} className="border-bottom">
                    <tr>
                      <th className="!font-semibold !text-primary-900">
                        {formatDate(era.created_at, timezone)}
                      </th>
                      <th
                        className="cursor-pointer !font-semibold !text-primary-900 hover:underline"
                        onClick={() =>
                          window.open(`/portal/charts/${claim.pcn}/billing`, '_blank')
                        }>
                        <Tippy
                          content="View Chart"
                          placement="right"
                          className="tippy-dark"
                          key={i + 'chart'}>
                          <div className="flex">
                            <span
                              className={cs(
                                'flex h-full select-none items-center !rounded-lg text-center text-sm'
                              )}>
                              {claim.pat_name_f} {claim.pat_name_l}
                            </span>
                          </div>
                        </Tippy>
                      </th>
                      <th className="!font-semibold !text-primary-900">
                        {claim.prov_name_f} {claim.prov_name_l}
                      </th>
                      <th className="break-words !font-semibold !text-primary-900">
                        {displayDiagnosis(claim)}
                      </th>
                      <th
                        className="cursor-pointer !font-semibold !text-primary-900 hover:underline"
                        onClick={() => {
                          window.open(`/portal/beyond-billing/claims/all/${claim.id}`, '_blank');
                        }}>
                        <Tippy
                          content="View Claim"
                          placement="right"
                          className="tippy-dark"
                          key={i + 'claim'}>
                          <div className="flex">
                            <span
                              className={cs(
                                'flex h-full select-none items-center !rounded-lg p-1 text-center text-sm'
                              )}>
                              ${claim.total_charge}
                            </span>
                          </div>
                        </Tippy>
                      </th>
                      <th className="w-[140px] !font-semibold !text-primary-900">
                        {mString(calcAdjustment(concat(...map(claim.procedures, 'adjustment'))))}
                      </th>
                      <th className="!font-semibold !text-primary-900">
                        {mString(
                          calcInsPaid({ eraId: era.id, insurancePayment: claim.insurance_payment })
                        )}
                      </th>
                      <th className="!font-semibold !text-primary-900">
                        {mString(claim?.invoice?.amount_paid)}
                      </th>
                    </tr>

                    {/* {claim.procedures.map((procedure, index) => {
                      return (
                        <tr key={index + 1} className="!font-semibold !text-primary-900">
                          <td className="font-medium !text-primary-700">
                            {procedure.from_date && formatDate(procedure.from_date, timezone)}
                          </td>
                          <td className="font-medium !text-primary-700">{procedure.code}</td>
                          <td className="font-medium !text-primary-700">
                            Qty: {procedure?.units} · Mods: {procedure.modifiers}
                          </td>
                          <td></td>
                          <td>{mString(procedure.charge * 100 || 0)}</td>
                          <td className="w-[140px]">
                            {handleAdjustmentCodes(procedure?.adjustment, codes)}
                          </td>
                          <td colSpan={2}></td>
                        </tr>
                      );
                    })} */}

                    {claim?.invoice?.procedures
                      ?.filter((p) => filterSuperbillClaimProcedure(p) && p?.from_date)
                      .map?.((procedure, index) => {
                        return (
                          <tr key={index + 2} className="!font-semibold !text-primary-900">
                            <td className="font-medium !text-primary-700">
                              {procedure.from_date && formatDate(procedure.from_date, timezone)}
                            </td>
                            <td className="font-medium !text-primary-700">
                              {procedure.procedure_code}
                            </td>
                            <td className="font-medium !text-primary-700">
                              Qty: {procedure?.units} · Mods: {procedure.modifiers}
                            </td>
                            <td></td>
                            <td>{mString(procedure.charge || 0)}</td>
                            <td className="w-[140px]">
                              {procedure?.adjustment
                                ? handleAdjustmentCodes(procedure?.adjustment, codes)
                                : handleProcAdjustmentCodes(
                                    procedure[insType],
                                    codes,
                                    findERAIndex(claim?.insurance_payment)
                                  )}
                            </td>
                            <td colSpan={2}></td>
                          </tr>
                        );
                      })}
                  </tbody>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default MatchedClaimsRows;
