import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import PromptsSidebar from './components/PromptsSidebar';
import PatientOverview from '../../../CurrentNote/PatientOverview/PatientOverview';
import Chat from './components/Chat';
import Button from 'components/shared/Buttons/Button';
import PatientHistorySidebar from './components/PatientHistorySidebar';

const MyScribeTemplate = () => {
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;

  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [isRightSideOpen, setIsRightSideOpen] = useState(true);

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Map Templates with MyScribe',
      name: '/map-template-with-myscribe',
      path: pathname
    });
  }, [breadcrumbs]);

  return (
    <div>
      <div className="p-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      </div>
      <div className="flex h-screen w-full">
        <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200">
          <PromptsSidebar
            className="relative h-full"
            isOpen={isLeftSideOpen}
            setIsOpen={setIsLeftSideOpen}
          />
        </div>
        <div className="flex w-full">
          {!isLeftSideOpen && (
            <div className="flex h-[52px] items-center bg-primary-50 py-2">
              <Button
                onClick={() => setIsLeftSideOpen(true)}
                icon="new-hamburger"
                className="z-30 m-1 !py-1"
                outlined
              />

              <Button
                // onClick={() => setIsLeftSideOpen(true)}
                icon="comment-plus"
                className="z-30 m-1 !py-1"
                outlined
              />
            </div>
          )}
          <div className="flex h-[calc(100vh-50px)] w-full flex-col">
            <PatientOverview />
            <Chat />
          </div>
          {!isRightSideOpen && (
            <div className="flex h-[52px] items-center bg-primary-50 py-2">
              <Button
                onClick={() => setIsRightSideOpen(true)}
                icon="new-hamburger"
                className="z-30 m-1 !py-1"
                outlined
              />
            </div>
          )}
          <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 duration-200">
            <PatientHistorySidebar
              className="relative h-full"
              isOpen={isRightSideOpen}
              setIsOpen={setIsRightSideOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyScribeTemplate;
