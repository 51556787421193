import React, { useState } from 'react';

import useAIBreadcrumbs from 'components/practice/charts/ClinicalNote/MyScribeAI/hooks/useAIBreadcrumbs';
import { MyScribeAIContext } from './MyScribeAIContext';

export const MyScribeAIContextProvider = ({ children }) => {
  const [favoriteTemplateId, setFavoriteTemplateId] = useState();

  const breadcrumb = useAIBreadcrumbs();

  return (
    <MyScribeAIContext.Provider
      value={{
        favoriteTemplateId,
        setFavoriteTemplateId,
        breadcrumb
      }}>
      {children}
    </MyScribeAIContext.Provider>
  );
};
