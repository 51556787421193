import React, { useEffect, useState } from 'react';
import Icon from '../../../../shared/Icon/Icon';
import Modal from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/Buttons/Button';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import LoadPreviousNoteTable from './LoadPreviousNoteTable';

import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useClinicalNoteRead } from 'lib/hooks/queries/clinicalNotes/useClinicalNote';
import { ia, iaRa, io, spaceToKebabCase } from '../../../../../lib/helpers/utility';
import { useOverview } from 'lib/hooks/queries/overview/useOverview';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../../../../api/Api';
import { useQuery } from '@tanstack/react-query';

function cleanNote(note) {
  delete note?.id;
  delete note?.appointment_id;
  delete note?.practitioner_id;
  delete note?.practice_id;
  delete note?.locked;
  delete note?.lock_history;
  delete note?.state;
  return note;
}

const PreviousNotes = () => {
  const {
    clinicalNote,
    setClinicalNote,
    setAdvancedHP,
    setAdvancedSOAP,
    setLoading,
    showPrevNoteModal,
    setShowPrevNoteModal,
    setServiceCPT,
    setCurrentHpOverviewData,
    saveNoteRef = { current: null }
  } = useClinicalNoteContext();

  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
  const { id, appointmentId } = useParams();
  const navigate = useNavigate();

  const [showPreviewProps, setShowPreviewProps] = useState({ clinicalNoteId: null });

  const { data, isLoading } = useQuery({
    queryKey: ['getClinicalNotes', id],
    queryFn: getClinicalNotes,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
  const allClinicalNotes = iaRa(data?.clinicalNote)?.filter((note) => note?.id);

  const { data: cnRead, isFetching: isFetchingCurrentCN } = useClinicalNoteRead({
    dependencies: [selectedNoteIndex],
    params: {
      patientId: id,
      appointmentId: allClinicalNotes?.[selectedNoteIndex]?.appointment_id
    },
    options: {
      enabled: selectedNoteIndex >= 0,
      refetchOnMount: true
    }
  });

  const { data: overviewData, isFetching: isFetchingOverview } = useOverview({
    dependencies: [selectedNoteIndex],
    params: {
      patientId: Number(id),
      appointmentId: Number(allClinicalNotes?.[selectedNoteIndex]?.appointment_id),
      allVersions: true
    },
    options: {
      enabled: selectedNoteIndex >= 0,
      refetchOnMount: true
    }
  });

  const isDataLoaded = !isFetchingCurrentCN && !isFetchingOverview;

  useEffect(() => {
    prepareDataToPreviewAndLoad();
  }, [isDataLoaded]);

  const prepareDataToPreviewAndLoad = () => {
    if (!isDataLoaded) return;

    if (!cnRead && !overviewData) return;

    const { clinicalNote, advancedHP, advancedSOAP, serviceCPT } = cnRead || {};

    let overviewDataArray = {};
    if (io(overviewData)) {
      overviewDataArray = Object.entries(overviewData).reduce((acc, [key, value]) => {
        acc[key] = ia(value) ? value : [value];
        return acc;
      }, {});
    }

    setShowPreviewProps({
      clinicalNoteId: cnRead?.clinicalNote?.id,
      customClinicalNote: {
        clinicalNote,
        advancedHP,
        advancedSOAP,
        serviceCPT,
        overviewData: overviewDataArray
      },
      originalOverviewData: overviewData
    });
  };

  async function getClinicalNotes() {
    const response = await requestApi({
      url: '/api/clinical_note/read_multiple',
      navigate,
      params: {
        patientId: id,
        pagination: false
      }
    });
    return response;
  }

  const loadNote = async () => {
    setLoading(true);

    const {
      clinicalNote: cn,
      advancedHP,
      advancedSOAP,
      serviceCPT
    } = showPreviewProps?.customClinicalNote || {};

    const overView = showPreviewProps?.originalOverviewData;

    setCurrentHpOverviewData(overView);

    const cleanedNote = cleanNote(cn);

    setClinicalNote({ ...clinicalNote, ...cleanedNote });

    if (advancedHP) {
      setAdvancedHP(
        advancedHP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }))
      );
    }

    if (advancedSOAP) {
      setAdvancedSOAP(
        advancedSOAP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }))
      );
    }

    if (serviceCPT) setServiceCPT(serviceCPT);

    setShowPrevNoteModal(false);

    setTimeout(async () => {
      await saveNoteRef?.current?.onSaveNote();
      await saveAdvancedForms([...advancedHP, ...advancedSOAP]);
    }, 500);
  };

  const handleNoteClick = (index) => {
    setSelectedNoteIndex(index);
  };

  // TODO: OPTIMIZE - CREATE NEW ENDPOINT TO HANDLE MULTIPLE UPSERTS
  const saveAdvancedForms = async (items) => {
    try {
      for (let item of items) {
        if (!item?.form?.id) continue;

        const fields =
          typeof item?.json?.fields === 'string'
            ? item?.json?.fields
            : JSON.stringify(item?.json?.fields);

        let params = {
          responseId: item?.id,
          formId: item?.form_id || item?.id,
          json: { fields },
          appointmentId,
          patientId: id,
          formVersion: item?.form_version || item?.version,
          isNotClinicalNote: false
        };

        await requestApi({ url: '/api/form/upsert_response', params });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    setLoading(false);
  };

  const onCancelPreviewNote = () => {
    setShowPreviewProps({});
    setSelectedNoteIndex(null);
  };

  return (
    <Modal
      handleOpen={showPrevNoteModal}
      handleClose={() => setShowPrevNoteModal(false)}
      title="Load from previous notes"
      className="w-[600px]"
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            onClick={() => setShowPrevNoteModal(false)}
            outlined
            text="Cancel"
            color="neutral"
            data-qa="cancel-btn"
          />
        </div>
      }>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-500 leading-6 text-neutral-800">Previous Clinical Notes</h3>
        </div>
        {isLoading ? (
          <Skeleton count={5} />
        ) : (
          <LoadPreviousNoteTable
            allClinicalNotes={allClinicalNotes}
            showPreviewProps={showPreviewProps}
            selectedNoteIndex={selectedNoteIndex}
            isDataLoaded={isDataLoaded}
            handleNoteClick={handleNoteClick}
            onCancelPreviewNote={onCancelPreviewNote}
            loadNote={loadNote}
          />
        )}
      </div>
      <Icon icon="new-pattern-two-rect" className="!absolute bottom-[68px] left-0" />
    </Modal>
  );
};

export default PreviousNotes;
