import React from 'react';
import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';

export default function Eligibility({
  insuranceProfilesList = [],
  callback = () => {},
  loading = false,
  existEligibility = false,
  eligibilityCounter = 0,
  eligibilityLimitReached = false
}) {
  const handleClick = () => {
    callback(insuranceProfilesList[0].id);
  };

  return (
    <div className="relative">
      {existEligibility ? (
        <Tippy
          content={
            !eligibilityLimitReached
              ? 'Refresh eligibility status'
              : 'You are out of eligibility requests. Please contact support!'
          }
          className="tippy-dark">
          <div
            className="rounded !p-1 hover:cursor-pointer hover:bg-primary-50"
            data-dd-privacy="allow">
            <Icon
              stroke
              color="primary"
              icon="new-refresh-arrows"
              disabled={eligibilityLimitReached}
              onClick={eligibilityCounter && handleClick}
            />
          </div>
        </Tippy>
      ) : (
        <>
          {eligibilityCounter ? (
            <Button
              size="small"
              transparent
              type="primary"
              loadingIcon={loading}
              text="Run Eligibility"
              onClick={handleClick}
            />
          ) : (
            <Tippy
              content="You are out of eligibility requests. Please contact support!"
              className="tippy-dark ">
              <div>
                <Button
                  disabled
                  size="small"
                  transparent
                  type="primary"
                  text="Run Eligibility"
                  onClick={handleClick}
                />
              </div>
            </Tippy>
          )}
        </>
      )}
    </div>
  );
}
