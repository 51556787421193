import { useQueryClient } from '@tanstack/react-query';
import { cancelAppointment } from 'api/Appointment';
import { deleteCalendarEvent } from 'api/CalendarEvent';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Confirmations = ({ id, type, show, selectedDate }) => {
  const { confirmModal, setConfirmModal } = useDashboardContext();
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleCancelAppointment = async () => {
    const params = { id };

    const { code } = await cancelAppointment(navigate, params);

    if (code === 0) {
      queryClient.resetQueries(['appointment', id]);

      queryClient.invalidateQueries('appointment-events');

      showAlert({ message: 'The appointment has been cancelled!' });
    }

    setConfirmModal({ id: null, type: '', show: false });
  };

  const handleSetType = async (type) => {
    setDeleting(true);
    const { code } = await deleteCalendarEvent(navigate, {
      id,
      type,
      start_date: selectedDate
    });
    if (code === 0) {
      setDeleting(false);
      queryClient.invalidateQueries('appointment-events');
      showAlert({
        message: 'The event has been cancelled!'
      });
    }
    setConfirmModal({ id: null, type: '', show: false, title: '' });
  };

  if (type === 'appointment' && show)
    return (
      <Confirm
        handleOpen={show}
        handleClose={() => setConfirmModal({ id: null, type: null, show: null })}
        handleContinue={handleCancelAppointment}
        title="Cancel appointment"
        message="Are you sure you want to cancel this appointment?"
        primaryBtnTxt="Cancel appointment"
        secondaryBtnTxt="Keep"
        icon="new-calendar-red"
        variant="danger"
      />
    );

  if (type === 'event' && show)
    return (
      <Confirm
        handleOpen={show}
        variant="danger"
        handleClose={() => setConfirmModal({ id: null, type: null, show: null })}
        handleContinue={() => handleSetType(confirmModal.eventKind, 'delete')}
        primaryBtnTxt="Delete"
        title={`Delete ${confirmModal.title}`}
        icon="new-document-remove-red"
        message={`Are you sure you want to delete ${confirmModal.title} event?`}
        loading={deleting}
      />
    );
};

export default Confirmations;
