import React from 'react';
import Icon from '../Icon/Icon';
import cs from 'classnames';

const InfoBox = ({ color = 'warning', text, icon = 'new-info', className, children, ...rest }) => {
  return (
    <div
      className={cs(
        'flex items-center !gap-2 !rounded py-[6px] !px-4',
        `bg-${color}-50`,
        className
      )}
      {...rest}>
      <Icon icon={icon} color={color} />
      <p className="text-sm leading-[22px] text-neutral-800">{text}</p>
      {children}
    </div>
  );
};

export default InfoBox;
