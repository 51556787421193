import React, { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Axios from 'configuredAxios';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import SurgicalHistoryForm from 'components/shared/Forms/Custom/SurgicalHistory/SurgicalHistory';

const SurgicalHistoryRow = ({ item, openItemModal }) => {
  const isNoDetailsTrue = !!item?.original?.noDetails;

  return (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      {isNoDetailsTrue ? (
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No notable surgical history</p>
          <Icon className="cursor-pointer" icon="new-chevron-right" />
        </div>
      ) : (
        <List
          items={item?.list}
          dividerType="none"
          renderItemComponent={(listItem, index) => (
            <>
              <div className="flex items-center justify-between gap-2">
                <p className="font-medium text-neutral-800">Surgical History</p>
                {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
              </div>
            </>
          )}
        />
      )}

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function SurgicalHistory(props) {
  const { customData, layout, patientId } = props;
  const surgicalHistory = customData?.surgicalHistory ? customData?.surgicalHistory : null;

  const defaultSize = useMemo(
    () => layout.find((l) => l.i === props.item.i)?.h,
    [surgicalHistory, layout]
  );

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () =>
      limitItems(surgicalHistory, defaultSize, {
        description: 0.15,
        createdAt: 0.2,
        group: 0.11,
        item: 0.3
      }),
    [surgicalHistory, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [itemModal, setItemModal] = useState(null);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    const formItem = customData?.surgicalHistory.find((i) => i.id === item?.id);
    setItemModal(formItem);
    setCurrentFormData(formItem?.response);
  };

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId: itemModal?.id,
        patientId,
        response: currentFormData,
        type: 'surgicalHistory'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ title: 'Successfully saved!' });
        setItemModal();
        setCurrentFormData();
        queryClient.invalidateQueries(['customFormResponse']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader onPlusClick={() => setItemModal({})} icon="history" {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <FooterWrapper
          hasMore={hasMore}
          onClick={() => setShow(true)}
          text={`Show all Surgical History (${count})`}>
          {items ? (
            <List
              items={items}
              renderItemComponent={(item, index) => (
                <SurgicalHistoryRow openItemModal={openItemModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Surgical History</p>
            </div>
          )}
        </FooterWrapper>
      </div>

      <Modal
        handleOpen={show}
        title="Surgical History"
        slideFromRight
        className="w-[1020px] bg-white"
        handleClose={() => setShow(false)}>
        {surgicalHistory ? (
          <List
            items={surgicalHistory}
            renderItemComponent={(item, index) => (
              <SurgicalHistoryRow openItemModal={openItemModal} item={item} />
            )}
          />
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Surgical History</p>
          </div>
        )}
      </Modal>

      <Modal
        className="w-[1020px]"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setItemModal();
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }
        handleOpen={!!itemModal}
        title="Surgical History"
        slideFromRight
        handleClose={() => setItemModal()}>
        <SurgicalHistoryForm
          setItemModal={setItemModal}
          formId={itemModal?.id}
          defaultValue={itemModal?.response}
          setCurrentFormData={setCurrentFormData}
        />
      </Modal>
    </div>
  );
}
