import React, { useEffect, useRef } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '!style-loader!css-loader!react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import Button from '../../shared/Buttons/Button';
import Loading from '../../shared/Loading/Loading';
import './History.scss';
import Textarea from 'components/shared/Textarea/Textarea';

export default function History({
  notes,
  note,
  setNote,
  saveNote,
  forwardedRef,
  loading,
  setShowNotes
}) {
  const bottomOfComponent = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      bottomOfComponent.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }, [notes, loading, bottomOfComponent]);

  const TimeLineElement = ({ item, key }) => {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: '#fff',
          color: '#4a4a4a'
        }}
        contentArrowStyle={{
          borderRight: '7px solid  rgb(239,250,244)'
        }}
        date={moment(item?.timestamp).format('MMM DD, YYYY hh:mm A')}
        dateClassName="DateStyling"
        iconClassName="CustomIcon"
        iconStyle={{
          background: '#d9dbe0',
          color: '#fff'
        }}>
        <h5
          className="vertical-timeline-element-title"
          style={{
            color: '#4a4a4a'
          }}>
          {item?.user?.fullName}
        </h5>
        <h6 style={{ marginTop: '8px' }} ref={key === notes.length - 1 ? bottomOfComponent : null}>
          {item?.note}
        </h6>
      </VerticalTimelineElement>
    );
  };

  return (
    <div className="History" ref={forwardedRef}>
      <div className="VTimelineWrapper">
        {loading ? (
          <div className="LoadingWrapper">
            <Loading />
          </div>
        ) : (
          <>
            <VerticalTimeline lineColor="#d9dbe0" layout="1-column-left" className="VTimeline">
              {notes?.map((item, index) => {
                return TimeLineElement({ key: index, item: item });
              })}
            </VerticalTimeline>
            <div ref={bottomOfComponent} />
          </>
        )}
      </div>
      <Textarea
        data-qa="stickyNotes-write-note"
        placeholder="Write note here..."
        textareaClassName='min-h-[100px] resize-y'
        name="note"
        forwardedRef={textareaRef}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className="flex justify-between items-center !mt-4">
        <Button
          onClick={() => setShowNotes(false)}
          data-qa="stickyNotes-save-btn"
          text="Close"
          color="neutral"
          outlined
        />
        <Button onClick={saveNote} data-qa="stickyNotes-save-btn" text="Save" />
      </div>
    </div>
  );
}
