import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ia } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import { showAlert } from 'components/shared/Alert/Alert';
import StickyNoteButton from 'components/shared/StickyNotesButton/StickyNotesButton';
import practiceState from '../practiceState';
import History from './History';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { requestApi } from 'api/Api';

export default function StickyNotes({ patientId, className, customStyle }) {
  const { data } = usePatient({ params: { id: patientId }, dependencies: [patientId] });
  const patient = data?.patient;
  const practice = useRecoilValue(practiceState.currentPractice);
  const [note, setNote] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { device } = useUIContext();
  const ref = useRef();
  const navigate = useNavigate();

  useOutsideClick(ref, () => setShowNotes(false));

  const getStickyNotes = async () => {
    setLoading(true);
    try {
      const res = await requestApi({
        url: '/api/practice/patient/sticky_notes/read',
        params: {
          patientId: patient?.id
        },
        navigate
      });
      const { notes: loadedNotes } = res;
      setNotes(loadedNotes);
      setLoading(false);

      if (
        practice.display_settings?.sticky_notes?.alert &&
        loadedNotes?.length > 0 &&
        device === 'desktop'
      )
        getNotes();
    } catch (error) {
      Honeybadger.notify(
        `file: /stickyNotes, method: getStickyNotes - catch, error: ${
          error?.response?.data ?? 'An unexpected error has occurred.'
        }`
      );
    }
    setLoading(false);
  };

  const saveStickyNotes = async () => {
    if (note) {
      try {
        const res = await requestApi({
          url: '/api/practice/patient/sticky_notes/create',
          params: {
            patientId: patient?.id,
            note
          },
          navigate
        });
        const { code, error, newStickyNote } = res;
        switch (code) {
          case 0:
            showAlert({ color: 'success', message: 'Sticky note saved successfully!' });
            setNotes(ia(notes) ? [...notes, newStickyNote] : [newStickyNote]);
            setNote('');
            break;
          case 2:
            showAlert({ color: 'danger', message: error });
            break;

          default:
            Honeybadger.notify(
              `file: /stickyNotes, method: saveStickyNotes - try, error: ${
                error ?? 'An unexpected error has occurred.'
              }`
            );
            break;
        }
      } catch (error) {
        console.error(error);
        Honeybadger.notify(
          `file: /stickyNotes, method: saveStickyNotes - catch, error: ${
            error ?? 'An unexpected error has occurred.'
          }`
        );
      }
    }
  };

  const getNotes = () => {
    setShowNotes(!showNotes);
  };

  useEffect(() => {
    if (patient) getStickyNotes();
  }, [patient]);

  return (
    <div ref={ref} className={className} id="stickyNotes" data-qa="sticky-notes-btn">
      {patient?.id && <StickyNoteButton onClick={getNotes} customStyle={customStyle} />}
      {showNotes && (
        <History
          notes={notes}
          note={note}
          setNote={setNote}
          saveNote={() => saveStickyNotes()}
          setShowNotes={setShowNotes}
          loading={loading}
        />
      )}
    </div>
  );
}
