import moment from 'moment';

export function calculateBaseUnit(startTime, endTime, baseUnit) {
  const start = moment(startTime, 'hh:mm A');
  const end = moment(endTime, 'hh:mm A');
  const differenceInMinutes = end.diff(start, 'minutes');
  if (differenceInMinutes < 0) {
    return 0;
  }
  const totalUnit = differenceInMinutes / 15 + Number(baseUnit);
  return totalUnit < 0 ? 0 : totalUnit;
}
