import React from 'react';
import AGChart from 'components/shared/AGChart/AGChart';
import { getPercentage } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const ChartView = ({ options, data, total }) => {
  return (
    <div className="flex w-full items-center justify-between px-4">
      <div className="flex flex-col gap-4">
        {options?.data?.map((item, index) => (
          <div className="flex h-max justify-between gap-[100px]" key={index}>
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full" style={{ backgroundColor: item?.fill }}></div>
              <p className="text-sm font-medium">{item?.name}</p>
            </div>
            <div className="flex justify-between gap-2">
              {data && total && (
                <p className="text-sm font-400 text-neutral-300">
                  {getPercentage(data, item?.key, total).toFixed()}%
                </p>
              )}
              <p className="text-sm font-400">{item?.value}</p>
            </div>
          </div>
        ))}
      </div>
      <AGChart options={options} />
    </div>
  );
};

export default withErrorBoundary(ChartView);
