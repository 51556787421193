import React, { Fragment, useState } from 'react';

import RadioButton from '../../../../shared/RadioButton/RadioButton';
import CurrencyInput from '../../../../shared/CurrencyInput/CurrencyInput';
import SearchPatients from '../../../../Search/SearchPatients/SearchPatients';
import {
  formatDate,
  formatDateAndTime,
  getKeysByType,
  ia,
  mString
} from '../../../../../lib/helpers/utility';
import { claimsStatusCodes } from 'constants';
import Select from 'components/shared/Select/Select';
import { updateAdjustmentProperty } from './EOB/EOBHelpers';
import AllocateAdjustment from './EOB/components/AllocateAdjustment';
import { useEraContext } from '../../../../../lib/context/EraContext/EraContext';
import { useClaims } from '../../../../../lib/hooks/queries/claims/useClaims';
import { handleAdjustmentCodes, sumAdjustment, displayDiagnosis } from './ERA&EOBHelpers';

export default function AllocateEOB() {
  const {
    era,
    codes,
    selectedUnmatchedClaim,
    setSelectedUnmatchedClaim,
    procedures,
    setAmount,
    setProcedures,
    status_code,
    setStatusCode
  } = useEraContext();

  const [patientId, setPatientId] = useState();

  const { data } = useClaims({
    params: {
      withCount: true,
      filters: {
        patients: [
          {
            label: 'Patient',
            value: patientId
          }
        ]
      }
    },
    dependencies: [patientId],
    options: { enabled: !!patientId }
  });

  const handleSelectPatient = (selectedClaim) => {
    setSelectedUnmatchedClaim(selectedClaim.id);

    setProcedures(selectedClaim.procedures || []);

    const totalCharge = selectedClaim ? Number(selectedClaim.total_charge) * 100 : 0;

    const amount =
      totalCharge > era?.unallocated_amount_cents ? era?.unallocated_amount_cents : totalCharge;

    setAmount(amount);

    setStatusCode(null);
  };

  return (
    <div>
      <div>
        <p className="!mb-2 !mt-6 text-base text-primary-800">Patient Name/ID</p>

        <div className="w-[304px]">
          <SearchPatients
            className="rounded-full border-neutral-200 bg-neutral-50"
            handleClick={(e) => {
              if (e?.value) {
                setPatientId(e.value);
              } else {
                setPatientId(null);
              }
            }}
          />
        </div>

        {ia(data?.claims) && patientId && (
          <div className="overflow-x-auto">
            <table className="primary-table mt-3">
              <thead>
                <tr>
                  <th></th>
                  <th>Posted/DoS</th>
                  <th>Patient/Service</th>
                  <th>Provider</th>
                  <th>Diagnosis</th>
                  <th>Billed</th>
                  <th>Ins. Paid</th>
                  <th>Adjustment</th>
                  <th>Pt. Paid</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {data?.claims?.map?.((claim, i) => {
                  const trClassName =
                    claim.id === selectedUnmatchedClaim
                      ? '!border border-solid border-primary-500 !bg-primary-50 font-600'
                      : '';

                  return (
                    <Fragment key={i}>
                      <tr className={trClassName}>
                        <th className="relative left-3 top-3 flex w-12">
                          <RadioButton
                            id={claim.id}
                            value={claim.id}
                            isSelected={claim.id === selectedUnmatchedClaim}
                            onChange={() => handleSelectPatient(claim)}
                          />
                        </th>
                        <th>{formatDateAndTime(claim.created_at)}</th>
                        <th>{claim.superbill?.patient.fullName}</th>
                        <th>{claim.superbill?.practitioner.fullName}</th>
                        <th>{displayDiagnosis(claim)}</th>
                        <th>{mString(claim.total_charge * 100)}</th>
                        <th>{mString(claim.insurance_payment.total)}</th>
                        <th>
                          {mString(
                            sumAdjustment(
                              claim.id === selectedUnmatchedClaim ? procedures : claim.procedures
                            )
                          )}
                        </th>
                        <th>{mString(claim?.invoice_amount_paid)}</th>
                        <th></th>
                      </tr>

                      {(claim.id === selectedUnmatchedClaim ? procedures : claim.procedures)?.map(
                        (procedure, index) => {
                          return (
                            <tr key={claim.id} className={trClassName}>
                              <td></td>
                              <td>{formatDate(procedure.from_date)}</td>
                              <td>{procedure.code}</td>
                              <td>
                                Qty: {procedure.units} Mods: {procedure.modifiers}
                              </td>
                              <td></td>
                              <td>{mString((procedure.charge || 0) * 100)}</td>
                              <td className="align-top">
                                {claim.id === selectedUnmatchedClaim ? (
                                  <CurrencyInput
                                    label="Paid"
                                    value={procedure?.paid || 0}
                                    parentClassName="w-32 !py-1"
                                    maxValue={(procedure.charge || 0) * 100}
                                    onValueChange={(amount) => {
                                      updateAdjustmentProperty(
                                        setProcedures,
                                        procedure?.code,
                                        index,
                                        'paid',
                                        +amount,
                                        true
                                      );
                                    }}
                                  />
                                ) : null}
                              </td>
                              <td className="!py-1">
                                {claim.id === selectedUnmatchedClaim ? (
                                  <AllocateAdjustment
                                    setProcedures={setProcedures}
                                    procedureCode={procedure?.code}
                                    adjustments={procedure?.adjustment}
                                    codeList={getKeysByType(codes, 'number')}
                                    groupList={getKeysByType(codes, 'letter')}
                                  />
                                ) : (
                                  handleAdjustmentCodes(procedure?.adjustment, codes)
                                )}
                              </td>
                              <td colSpan={2}></td>
                            </tr>
                          );
                        }
                      )}

                      {claim.id === selectedUnmatchedClaim && (
                        <tr className={trClassName}>
                          <td></td>

                          <td colSpan={2}>
                            <Select
                              label="Status"
                              isClearable={false}
                              options={claimsStatusCodes}
                              menuPortalTarget={document.body}
                              parentClassName="!py-2 font-400 w-[300px]"
                              onChange={(e) => setStatusCode(e.value)}
                              value={
                                !!(status_code || claim?.status_code) &&
                                claimsStatusCodes.find(
                                  (o) => o?.value === (claim?.status_code || status_code)
                                )
                              }
                            />
                          </td>

                          <td colSpan={7}></td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!ia(data?.claims) && <p className="!m-2">No Claims.</p>}
      </div>
    </div>
  );
}
