import { pick } from 'lodash';

import { showAlert } from '../../../../../shared/Alert/Alert';

export default function handleAddItem({
  type,
  selectedItems,
  newItems,
  setNewItems,
  list,
  setFieldValue,
  apptId,
  selectedService,
  setSelectedService,
  setSelectedTimeLength,
  additionalTimes
}) {
  const newSelectedItems = [...selectedItems];

  newItems.forEach((item) => {
    let calc = item?.sales_count + (additionalTimes?.length || 0) + (item?.count || 0);

    if (!apptId) calc += 1;

    const isPackage = item?.isPackage || item?.invoiceId;

    if (isPackage && type === 'procedures' && item?.quantity < calc) {
      showAlert({
        color: 'danger',
        title: item?.name || 'Service',
        message: `Unable to add service. The quantity is not enough.`
      });
      return;
    }

    if (type === 'procedures') {
      const { time_length } = item || {};

      if (list?.length > 1 && time_length > (selectedService?.time_length || 10)) {
        if (!!setSelectedService) setSelectedService(item);

        if (!!setSelectedTimeLength) {
          setFieldValue('appointmentLength', time_length);
          setSelectedTimeLength({ value: time_length, label: `${time_length} min` });
        }
      }

      // If service has type of 'copay' and no procedure code, change to 'self_pay'
      if (!isPackage && !item?.procedure_code && item?.charge_type === 'copay') {
        item.type = 'self_pay';
        item.charge_type = 'cash';
      }
    }

    const fields =
      type === 'products'
        ? [
            'id',
            'name',
            'amount_cents',
            'total_amount_cents',
            'sales_count',
            'state_tax_rate',
            'local_tax_rate',
            'amount_cents_with_tax',
            'wasSelected'
          ]
        : isPackage
          ? [
              'id',
              'name',
              'timeLength',
              'charge_type',
              'procedure_code',
              'quantity',
              'sales_count',
              'packageId',
              'invoiceId',
              'wasSelected'
            ]
          : [
              'id',
              'name',
              'time_length',
              'charge_type',
              'procedure_code',
              'total_cost_cents',
              'invoiceId',
              'state_tax_rate',
              'local_tax_rate'
            ];

    const selectedFields = pick(item, fields);

    let procType =
      item?.charge_type === 'cash'
        ? 'self_pay'
        : item?.charge_type === 'copay'
          ? 'primary'
          : 'unapplied';

    if (procType === 'primary') selectedFields.total_cost_cents = 0;

    if (!!item?.procedure_code) selectedFields.charge = item.standard_charge;

    const newItem =
      type === 'procedures' && !isPackage ? { ...selectedFields, type: procType } : selectedFields;

    if (type === 'products') {
      const existingItem = newSelectedItems.findIndex((i) => i?.id === item?.id);

      if (existingItem > -1) {
        newSelectedItems[existingItem].sales_count += item.count;
        newSelectedItems[existingItem].total_amount_cents += item.amount_cents * item.count;
      } else {
        newSelectedItems.push({
          ...newItem,
          sales_count: item.count,
          total_amount_cents: item.amount_cents * item.count
        });
      }
    } else if (type === 'procedures') {
      for (let i = 0; i < item.count; i++) {
        newSelectedItems.push({
          ...newItem,
          created_at: new Date(),
          uuid: self.crypto.randomUUID()
        });
      }
    }
  });

  setFieldValue(type, newSelectedItems);
  setNewItems([]);
}
