import React from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useKioskContext } from 'lib/context/KioskContext/KioskContext';
import { hexToRGBA } from 'lib/helpers/utility';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Spinner from 'components/shared/spinner/spinner';
import Icon from 'components/shared/Icon/Icon';
import ScanQRCode from './ScanQRCode';
import cs from 'classnames';

const IdleWrapper = ({ children }) => {
  const { kiosk, url, mobile } = useKioskContext();
  const context = useOutletContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isIdle = pathname === '/kiosk' || pathname.includes('idle');

  if (context?.status?.loading)
    return (
      <div className="relative z-20 flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="relative z-20 !mt-3 flex h-full w-full flex-col justify-between !px-10 !pb-4 !pt-0 xs:!p-4">
      <div className="flex flex-col !gap-5">
        <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-2">
          <div
            className={cs(
              'h-[44px] items-center justify-center gap-x-[6px] whitespace-nowrap rounded-[32px] bg-white px-[20px] py-[4px] xxs:w-full',
              kiosk ? 'flex' : 'grid'
            )}>
            {kiosk ? (
              <ProfilePicture
                size={44}
                autoBackground={false}
                image={kiosk?.practice?.header_photo}
                firstName={kiosk?.practice?.name}
              />
            ) : (
              <Icon icon="logo-symbol" size={30} />
            )}
          </div>
          <div className="text-center sm:order-3 sm:w-full sm:!pt-3">
            <p className="text-2xl font-600 leading-8 text-white">{`Welcome to ${
              kiosk?.practice?.dba_name || kiosk?.practice?.name || 'Myriad health'
            }`}</p>
            <p className="text-base font-400 leading-6 text-white">
              Please take a moment to sign-in below upon arrival.
            </p>
          </div>
          <div
            className="flex h-[44px] items-center gap-x-2 rounded-[32px] px-[20px] py-[10px] xxs:w-full"
            style={{ backgroundColor: hexToRGBA(kiosk?.color || '#ffffff', kiosk ? 50 : 30) }}>
            <div
              className="rounded-smooth flex h-[30px] w-[30px] items-center justify-center"
              style={{ background: kiosk?.color || '#E6F8FF' }}>
              <Icon icon="kiosk" color={kiosk ? 'white' : 'primary'} size={24} />
            </div>
            <span className="text-sm font-500 leading-[22px] text-white">
              {kiosk?.name || 'Unlinked Kiosk'}
            </span>
          </div>
        </div>
        <div className="mx-auto w-full max-w-[976px] rounded-2xl bg-white">{children}</div>
        {kiosk && isIdle && !mobile && <ScanQRCode url={url} />}
      </div>
      <p
        className="!mb-4 ml-auto cursor-pointer select-none text-sm font-400 text-white"
        onClick={() => {
          localStorage.setItem('myriad-kiosk-prev-url', pathname);
          navigate('/terms');
        }}>
        Terms & Conditions
      </p>
    </div>
  );
};

export default IdleWrapper;
