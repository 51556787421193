import { useEffect } from 'react';

const useOutsideClick = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking refs element or descendent elements
        if (!ref?.current || ref?.current?.contains(event.target)) return;
        handler(event);
      };
      document.addEventListener('click', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('click', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
};

export default useOutsideClick;
