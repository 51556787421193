module.exports = {
  columnHeaderBaseOptions: [
    { label: null, value: null },
    {
      label: 'First Name',
      variations: ['name first', 'f name', 'name f', 'given name', 'name given'],
      value: 'f_name',
      missingMessage: {
        className: 'warning',
        message: `First Name is highly recommended to be included`
      }
    },
    {
      label: 'Last Name',
      variations: ['name last', 'l name', 'name l', 'family name', 'name family'],
      value: 'l_name',
      missingMessage: {
        className: 'warning',
        message: `Last Name is highly recommended to be included`
      }
    },
    { label: 'Name', value: 'name' },
    { label: 'Middle Name', variations: ['name middle', 'm name', 'name m'], value: 'm_name' },
    {
      label: 'Email Primary',
      variations: ['email', 'email address', 'primary email', 'home email'],
      value: 'email',
      missingMessage: {
        className: 'error',
        message: `Email is required for patient import`
      }
    },
    {
      label: 'Email 2',
      variations: [
        'secondary email',
        'secondary email address',
        'second email address',
        'email address secondary',
        'email address second',
        'second email',
        'work email'
      ],
      value: 'email_2'
    },
    {
      label: 'Email 3',
      variations: [
        'third email',
        'third email',
        'third email address',
        'email address third',
        'work email'
      ],
      value: 'email_3'
    },
    {
      label: 'Phone Primary',
      variations: ['phone', 'phone number', 'primary phone', 'cell'],
      value: 'phone',
      missingMessage: {
        className: 'warning',
        message: `Phone Number is highly recommended to be included`
      }
    },
    { label: 'Phone 2', variations: ['secondary phone', 'work phone'], value: 'phone_2' },
    { label: 'Phone 3', value: 'phone_3' },
    { label: 'Address Line 1', variations: ['street address', 'street'], value: 'address_ln_1' },
    {
      label: 'Address Line 2',
      variations: ['suite', 'apartment', 'aprtmnt'],
      value: 'address_ln_2'
    },
    { label: 'City', value: 'city' },
    { label: 'State', value: 'state' },
    { label: 'Zip', variations: ['postal code'], value: 'zip' },
    {
      label: 'Address Line 1 (Second)',
      variations: ['secondary address line 1', 'secondary street address'],
      value: 'address_ln_1_2'
    },
    {
      label: 'Address Line 2 (Second)',
      variations: ['secondary address line 2', 'secondary aptartment', 'secondary suite'],
      value: 'address_ln_2_2'
    },
    { label: 'City (Second)', variations: ['secondary city'], value: 'city_2' },
    { label: 'State (Second)', variations: ['secondary state'], value: 'state_2' },
    { label: 'Zip (Second)', variations: ['secondary zip'], value: 'zip_2' },
    { label: 'Date Of Birth', variations: ['dob', 'birthday'], value: 'dob' },
    { label: 'SSN', variations: ['social security number'], value: 'ssn' },
    { label: 'Gender', variations: ['sex'], value: 'gender' },
    { label: 'Race', variations: [], value: 'race' },
    { label: 'Marital Status', variations: [], value: 'marital_status' },
    {
      label: 'Custom ID',
      variations: ['custom id', 'customer id', 'square customer id', 'square id'],
      value: 'custom_id'
    },
    { label: 'Insurance ID', variations: [], value: 'insurance_id' },
    { label: 'Insurance Name', variations: [], value: 'insurance_name' },
    { label: 'Insurance Type', variations: [], value: 'insurance_type' },
    {
      label: 'Insurance Member ID',
      variations: ['Insurance ID Number', 'insurance id number'],
      value: 'insurance_member_id'
    },
    { label: 'Insurance Group Number', variations: [], value: 'insurance_group_number' },
    { label: 'Insurance Plan Name', variations: [], value: 'insurance_plan_name' },
    {
      label: 'Insurance Policy Start Date',
      variations: ['Policy Start Date', 'policy start date'],
      value: 'insurance_policy_start_date'
    },
    {
      label: 'Insurance Policy End Date',
      variations: ['Policy End Date', 'policy end date'],
      value: 'insurance_policy_end_date'
    },
    { label: 'Insurance Relation', variations: [], value: 'insurance_relation' },
    {
      label: 'Insurance Other Relation Name',
      variations: [],
      value: 'insurance_other_relation_name'
    },
    {
      label: 'Insurance Other Relation Date',
      variations: [],
      value: 'insurance_other_relation_date'
    },
    {
      label: 'Insurance Other Relation Phone',
      variations: [],
      value: 'insurance_other_relation_phone'
    },
    { label: 'Insurance Relation Address', variations: [], value: 'insurance_relation_address' },
    {
      label: 'Appointment Start Date',
      variations: [
        'Appointment Date',
        'appointment date',
        'Appointment Start Date',
        'appointment start date',
        'appointment'
      ],
      value: 'appointment_starts_at'
    },
    {
      label: 'Appointment Duration',
      variations: [
        'Appointment Duration',
        'appointment duration',
        'Appointment End Date',
        'appointment end date'
      ],
      value: 'appointment_ends_at'
    },
    {
      label: 'Service',
      variations: ['Appointment Service', 'appointment service', 'service'],
      value: 'appointment_service'
    },
    {
      label: 'Appointment Status',
      variations: ['Appointment Status', 'appointment status', 'status'],
      value: 'appointment_status'
    },
    {
      label: 'Appointment Location',
      variations: ['Appointment Location', 'appointment location', 'location'],
      value: 'appointment_location'
    },
    {
      label: 'Appointment Amount',
      variations: ['Appointment amount', 'appointment amount', 'amount'],
      value: 'appointment_amount'
    },
    {
      label: 'Appointment Provider',
      variations: [
        'Appointment Provider',
        'appointment provider',
        'Provider',
        'Appointment Practitioner',
        'appointment practitioner',
        'Practitioner'
      ],
      value: 'appointment_provider'
    },
    { label: 'Transaction Amount', variations: [], value: 'transaction_amount' },
    {
      label: 'Transaction Amount Paid Self',
      variations: [],
      value: 'transaction_amount_paid_self'
    },
    { label: 'Transaction Date', variations: [], value: 'transaction_date' }
  ],
  dateFormats: [
    // { label: 'Separated', value: 'delimited' },
    {
      label: 'Month Day Year',
      options: [
        { label: 'MMDDYYYY', value: 'MMDDYYYY' },
        { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
        { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
        { label: 'MM.DD.YYYY', value: 'MM.DD.YYYY' },
        { label: 'MM_DD_YYYY', value: 'MM_DD_YYYY' },
        { label: 'MM DD YYYY', value: 'MM DD YYYY' }
      ]
    },
    {
      label: 'Month Day 2 Digit Year',
      options: [
        { label: 'MMDDYY', value: 'MMDDYY' },
        { label: 'MM/DD/YY', value: 'MM/DD/YY' },
        { label: 'MM-DD-YY', value: 'MM-DD-YY' },
        { label: 'MM.DD.YY', value: 'MM.DD.YY' },
        { label: 'MM_DD_YY', value: 'MM_DD_YY' },
        { label: 'MM DD YY', value: 'MM DD YY' }
      ]
    },
    {
      label: 'Day Month Year',
      options: [
        { label: 'DDMMYYYY', value: 'DDMMYYYY' },
        { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
        { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
        { label: 'DD.MM.YYYY', value: 'DD.MM.YYYY' },
        { label: 'DD_MM_YYYY', value: 'DD_MM_YYYY' },
        { label: 'DD MM YYYY', value: 'DD MM YYYY' }
      ]
    },
    {
      label: 'Year Month Day',
      options: [
        { label: 'YYYYMMDD', value: 'YYYYMMDD' },
        { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
        { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
        { label: 'YYYY.MM.DD', value: 'YYYY.MM.DD' },
        { label: 'YYYY_MM_DD', value: 'YYYY_MM_DD' },
        { label: 'YYYY MM DD', value: 'YYYY MM DD' }
      ]
    }
  ],
  nameFormatOptions: [
    { label: 'First Last', value: 'first_last' },
    { label: 'Last First', value: 'last_first' },
    { label: '% Last First %', value: '%last_first%' },
    { label: '% First Last %', value: '%first_last%' },
    { label: '%,First,Last,%', value: '%,first,_last,%' },
    { label: '%,Last,First,%', value: '%,last,_first,%' }
  ],
  currencyFormats: [
    { label: '10.00$ (in dollars)', value: 'dollars' },
    { label: '1000cents (in cents)', value: 'cents' }
  ]
};
