import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Tippy from '@tippyjs/react';
import Content from './Content';
import Icon from 'components/shared/Icon/Icon';
import usePermitted from 'lib/hooks/usePermitted';
import practiceState from 'components/practice/practiceState';
import { useUnreadFaxes } from 'lib/hooks/queries/fax/useUnreadFaxes';
import Button from 'components/shared/Buttons/Button';
import { getUnreadFaxes } from 'api/Fax';
import Allowed from 'components/shared/Permissions/Allowed';

const NAVLINK_MAP = {
  Dashboard: '',
  Calendar: 'appointments.read',
  Charts: 'patients.read',
  'Beyond Billing': 'billing.read',
  'Comm Center': ''
};

const SidebarList = ({ list }) => {
  const [selectedPatient, setSelectedPatient] = useRecoilState(practiceState.selectedPatient);
  const { data = {} } = useUnreadFaxes();
  const { isPermitted } = usePermitted();

  return (
    <div className="!py-2 !px-1 flex flex-col gap-2">
      {list?.map(
        (item, idx) =>
          (!NAVLINK_MAP[item?.name] || isPermitted(NAVLINK_MAP[item?.name])) && (
            <Allowed key={idx} requiredPermissions={item?.permission}>
              <Tippy
                key={idx}
                className="custom-tooltip arrow-small z-40"
                content={<Content title={item.name} nested={item.children} />}
                placement="right"
                interactive
                appendTo={() => document.body}>
                <NavLink
                  data-qa={`sidebar-links-${item.name.toLowerCase()}`}
                  key={idx}
                  to={item.path}
                  end={item.end}
                  onClick={() => setSelectedPatient({ ...selectedPatient, id: null })}
                  className={({ isActive }) =>
                    isActive
                      ? 'flex cursor-pointer flex-col items-center justify-center !rounded-lg border-solid border !border-primary-500 !bg-primary-50 w-[32px] h-[32px] first:!mt-0 hover:bg-primary-50 shadow-[0_0_8px_rgba(0,79,107,0.16)]'
                      : 'flex cursor-pointer flex-col items-center justify-center !rounded-lg border border-white w-[32px] h-[32px] first:!mt-0 hover:bg-primary-50'
                  }>
                  {({ isActive }) => (
                    <div className="relative">
                      <Icon
                        icon={item.icon}
                        className="cursor-pointer"
                        color="primary"
                        shade={isActive ? '500' : '900'}
                        size={16}
                      />
                      {item.name === 'Comms Center' && data?.unread > 0 && (
                        <div className="absolute -top-1 -right-1 flex items-center justify-center w-[6px] h-[6px] rounded-full  z-50 bg-red-400 text-white text-xs">
                          {data?.length}
                        </div>
                      )}
                    </div>
                  )}
                </NavLink>
              </Tippy>
            </Allowed>
          )
      )}
    </div>
  );
};

export default SidebarList;
