import { requestApi } from './Api';
import { showAlert } from '../components/shared/Alert/Alert';
import { navigate } from 'react-big-calendar/lib/utils/constants';

export const getPayers = async (navigate, params) => {
  const onError = (error, code) => {
    if (code === 2) {
      showAlert({
        title: 'No permission!',
        message: "You're not authorized to read payers!",
        color: 'danger'
      });
    }
  };

  const response = await requestApi({
    navigate,
    url: '/api/practice/settings/payers/get',
    params,
    onError
  });
  return response;
};

export const getPayerAddress = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/payers/address/get',
    params
  });

export const getPayersCheckin = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/patient/checkin/payers',
    params
  });

export const readPayers = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/payers/read',
    params
  });

export const createPayer = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/payers/create',
    params
  });

export const deletePayer = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/payers/delete',
    params
  });

export const updatePayerAddress = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/payers/address/update',
    params
  });
