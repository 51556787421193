import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getAppointments } from '../../../../api/Appointment';
import { formatAppointments, formatEvents } from '../../../helpers/appointment';
import { currentPractice } from '../../../../components/practice/practiceState';
import { useRecoilValue } from 'recoil';

export const useAppointmentEvents = (params, options) => {
  const currPractice = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const {
    practice,
    defaultDate,
    rangeDate,
    reloadAppointment,
    currentView,
    filters,
    setEvent,
    withalRest
  } = params;

  return useQuery(
    [
      'appointment-events',
      practice,
      defaultDate,
      rangeDate,
      reloadAppointment,
      currentView,
      filters
    ],
    () => getAppointments({ ...params, navigate }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        return [
          ...formatAppointments({ appointments: data?.appointment, currentView }),
          ...formatEvents({
            calendarEvents: data?.events,
            setEvent,
            timezone: currPractice.timezone,
            currentView
          })
        ];
      },
      ...options
    }
  );
};
