import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import RiskAndCoMorbidities from './RiskAndCoMorbidities';

const RiskAndCoMorbiditiesSection = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.riskAndCoMorbidities?.title}
      id={components?.riskAndCoMorbidities?.id}
      disabled={clinicalNote?.locked}>
      <RiskAndCoMorbidities sectionRef={sectionRef} />
    </Accordion>
  );
};

export default RiskAndCoMorbiditiesSection;
