import React from 'react';

import TH from 'components/shared/Receipt/components/table/TH';
import { formatDateAndTime, ia, iaRa, mString } from 'lib/helpers/utility';

const ReceiptItems = ({ transactions, walkout, timezone }) => {
  return iaRa(transactions).map((transaction) => {
    const { amount_paid_self, surcharge_amount, invoice, date_of_service } = transaction || {};

    const hasItems = ia(invoice?.procedures) || ia(invoice?.products) || ia(invoice?.packages);

    const amountPaid = amount_paid_self - (surcharge_amount || 0) || 0;

    const previousPayments = invoice?.amount_paid - amountPaid;

    const items = [
      ...(invoice?.procedures ?? []),
      ...(invoice?.products ?? []),
      ...(invoice?.packages ?? [])
    ];

    // Don't render standalone sale if there are multiple transactions
    if (transactions?.length > 1 && !invoice?.id) return;

    return (
      <div
        key={transaction?.id}
        className="flex flex-col items-end overflow-hidden rounded-[12px] !border-[0.6px] border-solid border-neutral-200 shadow-[0px_1px_3px_0px_rgba(25,33,61,0.05),0px_2px_8px_0px_rgba(25,33,61,0.04)]">
        <div className="flex h-[38px] w-full items-center justify-between rounded-t-lg bg-primary-50 !px-5">
          <p className="text-sm font-700 text-primary-900">
            {invoice ? `#Invoice ${invoice?.id}` : 'Standalone sale'}
          </p>

          <div className="flex gap-[2px]">
            <label className="m-0 text-xxs">Date of service:</label>
            <label className="m-0 text-xxs font-600 text-neutral-900">
              {formatDateAndTime(date_of_service || invoice?.created_at, timezone)}
            </label>
          </div>
        </div>

        <div className="w-full !px-5">
          <table className="w-full text-right">
            <thead>
              <tr className="border-[0] border-b-[0.6px] border-solid border-neutral-200">
                <TH label={hasItems ? 'Item' : 'Description'} align="start" />
                {hasItems && (
                  <>
                    <TH label="Type" align="end" />
                    <TH label="Quantity" align="center" />
                    <TH label="Price" align="end" />
                  </>
                )}
                <TH label={hasItems ? 'Total' : 'Charge'} align="end" />
              </tr>
            </thead>

            <tbody>
              {hasItems ? (
                items.map((item, i) => {
                  const {
                    name,
                    type,
                    invoiceId,
                    pt_balance,
                    sales_count,
                    total_cost_cents,
                    total_amount_cents
                  } = item || {};

                  const price = pt_balance ?? total_cost_cents ?? total_amount_cents ?? 0;

                  const isService = type || invoiceId;

                  return (
                    <tr
                      key={name + i}
                      className="border-[0] border-b-[0.6px] border-solid border-neutral-200 hover:!bg-transparent">
                      <td className="w-2/5 !px-0 !py-2 text-left align-middle text-xs font-600 leading-3 text-neutral-900">
                        {name}
                      </td>
                      <td className="!px-0 !py-2 align-middle text-xs text-neutral-700">
                        {isService && 'Service'}
                        {item.hasOwnProperty('image') && 'Package'}
                        {!isService && !item.hasOwnProperty('image') && 'Product'}
                      </td>
                      <td className="!px-0 !py-2 text-center align-middle text-xs text-neutral-700">
                        {sales_count || 1}
                      </td>
                      <td className="!px-0 !py-2 align-middle text-xs text-neutral-700">
                        {mString(isService ? price : price / sales_count)}
                      </td>
                      <td className="!px-0 !py-2 align-middle text-xs font-600 text-neutral-900">
                        {mString(price)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="hover:!bg-transparent">
                  <td className="!py-2 px-0 text-left text-xs font-600 leading-[14px] text-neutral-900">
                    Sale
                  </td>
                  <td className="!py-2 px-0 text-right text-xs font-600 leading-[14px] text-neutral-900">
                    {mString(amountPaid)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {(walkout || transactions.length > 1) && (
          <div className="!mb-3 flex w-[265px] flex-col items-end  justify-end !gap-3 rounded-[8px] !px-5">
            <div className="flex w-full flex-col items-start">
              <div className="flex w-full items-center justify-between border-b-[0.6px] border-neutral-200 px-0 py-[8px] [border-bottom-style:solid]">
                <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">Subtotal</p>
                <p className="w-fit text-xs leading-[14px] text-neutral-900">
                  {mString(invoice?.total_amount || 0)}
                </p>
              </div>

              <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-neutral-200 px-0 py-[8px] [border-bottom-style:solid]">
                <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">Discount</p>

                <p className="w-fit text-xs leading-[14px] text-neutral-900">
                  {mString(-invoice?.discount?.amount_cents || 0)}
                </p>
              </div>

              <div className="flex w-full items-center justify-between border-b-[0.6px] border-neutral-200 px-0 py-[8px] [border-bottom-style:solid]">
                <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">
                  Previous Payments
                </p>

                <p className="w-fit text-xs leading-[14px] text-neutral-900">
                  {mString(previousPayments > 0 ? -previousPayments : 0)}
                </p>
              </div>

              <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-neutral-200 px-0 py-[8px] [border-bottom-style:solid]">
                <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">Tax</p>

                <p className="w-fit text-xs leading-[14px] text-neutral-900">
                  {mString(invoice?.tax || 0)}
                </p>
              </div>
            </div>

            <div className="flex w-full flex-col items-end justify-end !gap-[2px]">
              <div className="flex w-full items-center  justify-between">
                <p className="w-fit text-xs font-600 leading-[14px] text-neutral-800">
                  Current Amount Due
                </p>

                <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-900">
                  {mString((invoice?.balance || 0) + amountPaid)}
                </p>
              </div>

              {transaction?.id && (
                <>
                  <div className="flex w-full items-center  justify-between">
                    <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">Paid</p>

                    <p className="w-fit text-[18px] leading-[22px] text-neutral-900">
                      {mString(amountPaid || 0)}
                    </p>
                  </div>

                  <div className="flex w-full items-center justify-between">
                    <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-800">
                      Remaining Balance
                    </p>

                    <p className=" w-fit text-xs font-600 leading-[14px] text-neutral-900">
                      {mString(invoice?.balance || 0)}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  });
};

export default ReceiptItems;
