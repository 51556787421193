import React, { useState } from 'react';
import Financials from './components/Financials';
import Appointments from './components/Appointments';
import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';

const DashboardPanel = (props) => {
  const [selectedOption, setSelectedOption] = useState('appointments');
  const options = [
    { value: 'appointments', label: 'Appointments', icon: 'calendar-tick' },
    { value: 'financials', label: 'Financials', icon: 'chart' }
  ];

  const components = {
    appointments: Appointments,
    financials: Financials
  };

  const SelectedComponent = components[selectedOption];

  return (
    <div>
      <WidgetHeader
        selected={{ selectedOption, setSelectedOption }}
        options={options}
        hasPlus={false}
      />
      <div className="!p-5">
        <SelectedComponent {...props} />
      </div>
    </div>
  );
};

export default DashboardPanel;
