import React, { useState } from 'react';
import cs from 'classnames';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import { creationActionAppointment, remindAppointment } from 'api/Appointment';

const Footer = ({
  oldAppointment,
  handleUnsavedChanges,
  setShowConfirmationModal,
  loading,
  isReschedule,
  formik,
  setRestoreConfirmationModal
}) => {
  const [appointmentConfirmation, setAppointmentConfirmation] = useState(false);
  const [appointmentReminder, setAppointmentReminder] = useState(false);
  const navigate = useNavigate();

  const handleRemind = async (type = '') => {
    const params = { appointment_id: oldAppointment.id, type };
    await remindAppointment(navigate, params);
    showAlert({ message: 'Appointment reminder sent successfully', type: 'success' });
    setAppointmentReminder(false);
  };

  const handleCreationAction = async (type = '') => {
    const params = {
      appointment_id: oldAppointment.id,
      type
    };
    await creationActionAppointment(navigate, params);
    showAlert({ message: 'Appointment creation sent successfully', type: 'success' });
    setAppointmentConfirmation(false);
  };

  return (
    <div className="flex w-full justify-between">
      {oldAppointment.status !== 'cancelled' && (
        <>
          <Button
            outlined
            text="Close"
            color="neutral"
            onClick={handleUnsavedChanges}
            data-qa="close"
          />
          <div className="flex items-center gap-3">
            <Popover className="relative">
              {({ close }) => (
                <div>
                  <Popover.Button
                    className={`flex h-[34px]  items-center justify-center  bg-white shadow-small`}>
                    <div
                      data-qa="cancel-appointment"
                      className="flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-md  bg-primary-700">
                      <Icon icon="three-dots" />
                    </div>
                  </Popover.Button>

                  <Popover.Panel
                    className={cs(
                      'absolute -top-[130px] right-1 z-[99999] min-w-[270px] cursor-pointer   rounded-lg  bg-white !py-[6px] shadow-lg'
                    )}>
                    <div
                      className="relative z-50 flex items-center justify-between gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onMouseEnter={() => setAppointmentConfirmation(true)}
                      onMouseLeave={() => setAppointmentConfirmation(false)}>
                      <div className="flex items-center gap-1">
                        <Icon icon="calendar-tick" />
                        <div className="text-sm text-primary-900">Resend Appointment Creation</div>
                      </div>
                      <Icon icon="chevron-right" color="primary" />
                      {appointmentConfirmation && (
                        <div className="absolute -right-[125px] -top-1  z-30 rounded-md bg-white !py-[6px] shadow-sm">
                          <div
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleCreationAction('email')}>
                            <Icon icon="new-email-blue" size="15px" />
                            <div className="text-sm text-primary-900">Email</div>
                          </div>
                          <div
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleCreationAction('text_message')}>
                            <Icon icon="text-message" size="15px" />
                            <div className="text-sm text-primary-900">Text Message</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="flex items-center justify-between gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        close();
                      }}
                      onMouseEnter={() => setAppointmentReminder(true)}
                      onMouseLeave={() => setAppointmentReminder(false)}>
                      <div className="flex items-center gap-1">
                        <Icon icon="calendar-reminder" />
                        <div className="text-sm text-primary-900">
                          Resend Appointment Reminder/Forms{' '}
                        </div>
                      </div>
                      <Icon icon="chevron-right" color="primary" className="ml-3" />
                      {appointmentReminder && (
                        <div className="absolute -right-[125px] top-10  z-30 rounded-md bg-white !py-[6px] shadow-sm">
                          <div
                            className="relative flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleRemind('email')}>
                            <Icon icon="new-email-blue" size="15px" />
                            <div className="text-sm text-primary-900">Email</div>
                          </div>
                          <div
                            className="flex cursor-pointer items-center gap-1 !p-2 hover:bg-primary-50"
                            onClick={() => handleRemind('text_message')}>
                            <Icon icon="text-message" size="15px" />
                            <div className="text-sm text-primary-900">Text Message</div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        setShowConfirmationModal(true);
                        close();
                      }}>
                      <Icon icon="calendar-remove" color="primary" size="18px" />
                      <div className="text-sm text-primary-900">Cancel Appointment </div>
                    </div>
                  </Popover.Panel>
                </div>
              )}
            </Popover>

            <Button
              data-qa="update-appointment"
              color="primary"
              loading={loading.update}
              onClick={() => {
                setTimeout(() => {
                  formik.handleSubmit();
                }, 100);
              }}
              text={`Update ${isReschedule ? ' & Reschedule' : ''} Appointment`}
            />
          </div>
        </>
      )}
      {oldAppointment.status === 'cancelled' && (
        <>
          <div></div>
          <div className="flex items-center gap-3">
            <Button
              data-qa="restore-appointment"
              color="warning"
              loading={loading.restore}
              onClick={() => {
                setRestoreConfirmationModal(true);
              }}
              text="Restore Appointment"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
