import { getClinicalNote } from 'api/ClinicalNote';
import { getLabOrder } from 'api/LabOrder';
import { getMyScribe } from 'api/MyScribe';
import { getNonERXPrescription } from 'api/NonERXPrescription';
import { getReferralOrder } from 'api/ReferalOrder';
import cs from 'classnames';
import { getOverview } from 'components/practice/charts/ClinicalNote/lib/getOverview';
import { processCustomFormTypes } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import {
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from 'components/practice/charts/ClinicalNote/Orders/lib/clinicalSummaryHelpers';
import { selectedInitialState } from 'components/practice/charts/ClinicalNote/PreviewNote/lib/initials';
import {
  mapRenderedInitialState,
  processSelectedForms
} from 'components/practice/charts/ClinicalNote/PreviewNote/lib/renderedHelper';
import { prepareSelection } from 'components/practice/charts/ClinicalNote/PreviewNote/lib/selectedHelper';
import RenderedComponents from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/RenderedComponents';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { userState } from 'components/state';
import html2pdf from 'html2pdf.js';
import { formatDate, ia, iaRa, io, spaceToKebabCase } from 'lib/helpers/utility';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';

const ClinicalNotePDF = ({
  itemProps = [],
  renderedRef = [],
  exportRef,
  patient = {},
  contentId = '',
  setEmailContent = () => {},
  downloadPDF = false,
  setButtonLoading = () => {},
  setSelectedRows = () => {}
}) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const navigate = useNavigate();

  const practice = useRecoilValue(currentPractice);
  const currentUser = useRecoilValue(userState);
  const cnDisplaySettings = currentUser?.display_settings?.clinicalNote;

  useEffect(() => {
    if (itemProps) {
      setLoading(true);
      prepareForms();
    }
  }, [itemProps]);

  const prepareForms = async () => {
    const cnData = itemProps;

    const newResult = await Promise.all(
      cnData.map(async (item) => {
        const appointmentId = item?.appointmentId;
        const patientId = item?.patient?.id;
        const result = await getClinicalNote(navigate, {
          appointmentId,
          loadedPracticeId: practice?.id
        });

        const currentHpOverviewData = await getOverview({
          patientId,
          appointmentId,
          navigate,
          isKebab: false
        });

        const myScribe = await getMyScribe(navigate, { appointmentId });

        const referalOrder = await getReferralOrder(navigate, { clinicalNoteIds: [appointmentId] });

        const nonERXPrescription = await getNonERXPrescription(navigate, {
          appointmentIds: [appointmentId]
        });

        const labOrder = await getLabOrder(navigate, {
          appointmentIds: [appointmentId],
          withRelations: { provider: true, tests: true }
        });

        const { clinicalNote, advancedHP, advancedSOAP, customFormTypes, serviceCPT } = result;

        const processedAdvancedHP = iaRa(advancedHP).map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));
        const processedAdvancedSOAP = iaRa(advancedSOAP).map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));

        const cpt_codes = iaRa(serviceCPT);

        const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });
        const getAdvancedHP = processAdvancedHPForms(processedAdvancedHP, cnDisplaySettings);
        const getAdvancedSOAP = processAdvancedSOAPForms(processedAdvancedSOAP, cnDisplaySettings);

        const getSelected = async () => {
          let selectedObj = {
            ...selectedInitialState(
              clinicalNote,
              cnDisplaySettings,
              currentHpOverviewData,
              null,
              iaRa(myScribe?.data?.myscribe),
              iaRa(referalOrder?.data?.referrals),
              iaRa(nonERXPrescription?.data?.nonERXPrescription),
              iaRa(labOrder?.data?.labOrder)
            ),
            ...getAdvancedHP,
            ...getAdvancedSOAP,
            ...processedCustomFormTypes
          };

          const clinicalSummary = null;

          if (io(clinicalSummary)) {
            const result = prepareSelection({
              savedSelection: { ...selectedObj, ...clinicalSummary },
              initialSelection: selectedObj
            });

            return result;
          } else {
            const formattedSelect = prepareSelection({ initialSelection: selectedObj });
            return formattedSelect;
          }
        };

        const selectedResult = await getSelected();

        const prepareComponentProps = async () => {
          const selected = selectedResult;
          const rendered = mapRenderedInitialState(selected);

          const { checkedRendered, hpForms, soapForms } = processSelectedForms({
            selected,
            rendered
          });

          setEmailContent((prevEmailContent) => [
            ...prevEmailContent,
            {
              medicalHistoryId: item?.cnId,
              appointmentId,
              selection: selected
            }
          ]);

          return { checkedRendered, hpForms, soapForms };
        };
        const componentProps = await prepareComponentProps();

        return {
          customClinicalNote: {
            clinicalNote: { ...clinicalNote, cpt_codes },
            advancedHP: processedAdvancedHP,
            advancedSOAP: processedAdvancedSOAP,
            currentHpOverviewData,
            customFormTypes: processedCustomFormTypes
          },
          componentProps: { ...componentProps },
          appointmentDate: item?.startsAt,
          patient: item?.patient,
          provider: item?.provider
        };
      })
    );
    setResult(newResult);
    setLoading(false);
  };

  const print = useReactToPrint({
    content: () => {
      const combinedContent = document.createElement('div');
      combinedContent.style.margin = '10px';

      renderedRef?.current?.forEach((element, index) => {
        if (element) {
          const clonedElement = element.cloneNode(true);
          if (index > 0) {
            const pageBreak = document.createElement('div');
            pageBreak.style.pageBreakBefore = 'always';
            combinedContent.appendChild(pageBreak);
          }
          combinedContent.appendChild(clonedElement);
        }
      });

      return combinedContent;
    },
    print: async (printIframe) => {
      const generateAndSavePDF = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const content = printIframe.contentDocument.documentElement.cloneNode(true);
        const opt = {
          margin: 10,
          filename: `${patient?.fullName}_clinical_notes.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true,
            logging: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        try {
          await html2pdf().set(opt).from(content).save();
          setSelectedRows([]);
        } catch (error) {
          showAlert({ title: 'Error generating PDF:', message: error });
          setSelectedRows([]);
        }
      };
      await generateAndSavePDF();
    }
  });

  useImperativeHandle(exportRef, () => ({
    triggerPrint: print
  }));

  useEffect(() => {
    if (
      downloadPDF &&
      renderedRef?.current?.length === result?.length &&
      renderedRef?.current?.every((ref) => ref !== null)
    ) {
      try {
        const generateAndSavePDF = async () => {
          let index = 0;
          for (const rendered of renderedRef.current) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const opt = {
              margin: 10,
              filename: `${itemProps[index]?.startsAt}_${itemProps[index]?.patient?.fullName}_clinical_notes.pdf`,
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: {
                scale: 2,
                useCORS: true,
                logging: true
              },
              jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
              pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
            };
            try {
              await html2pdf().set(opt).from(rendered).save();
              setSelectedRows([]);
            } catch (error) {
              showAlert({ title: 'Error generating PDF:', message: error });
              setButtonLoading(false);
              setSelectedRows([]);
            }
            index++;
          }
        };
        generateAndSavePDF();
      } catch (error) {
        showAlert({ title: 'Error generating PDF:', message: error });
        setButtonLoading(false);
        setSelectedRows([]);
      } finally {
        setButtonLoading(false);
        setSelectedRows([]);
      }
    }
  }, [result, renderedRef?.current]);

  return (
    <>
      <div className={cs('p-3', downloadPDF && 'hidden')} id={contentId}>
        {loading ? (
          <Skeleton count={5} />
        ) : ia(result) ? (
          result.map((row, index) => {
            return (
              <div
                ref={(el) => (renderedRef.current[index] = el)}
                key={index}
                className="print:!page-break-before">
                <div className="flex items-center justify-between bg-white !py-4 !pl-6 !pr-4">
                  {practice?.header_photo && (
                    <div className="flex h-[90px] w-[140px] justify-start">
                      <img
                        className="!w-auto object-contain"
                        src={`https://cdn.filestackcontent.com/${JSON.parse(practice?.header_photo).jpg}`}
                      />
                    </div>
                  )}
                  <div className="ml-auto grid">
                    <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
                      {practice.name}
                    </p>
                    <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
                      {row?.provider?.fullName}
                    </p>
                    <p className="text-right text-xs text-[#003A4B] print:text-base">
                      {practice.address}
                    </p>
                    <p className="text-right text-xs text-[#003A4B] print:text-base">
                      {practice.phone}
                    </p>
                    <p className="text-right text-xs text-[#003A4B] print:text-base">
                      {practice.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between bg-[#003A4B] !py-3 !pl-6 !pr-4">
                  <div className="grid">
                    <p className="text-sm font-600 !text-white print:text-xl">
                      {row?.patient?.fullName}
                    </p>
                    <ul className="flex items-center gap-x-1">
                      <li className="text-xs !text-white print:text-base">
                        {moment(row?.patient?.dob).format('MM/DD/YYYY')}
                      </li>
                      <span className="text-xs !text-white print:text-base">•</span>
                      <li className="text-xs !text-white print:text-base">
                        {moment.utc().diff(row?.patient?.dob, 'years')} years
                      </li>
                      {row?.patient?.gender && (
                        <>
                          <span className="text-xs !text-white print:text-base">•</span>
                          <li className="text-xs capitalize !text-white print:text-base">
                            {row?.patient?.gender}
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div className="grid">
                    <p className="text-right text-sm font-600 !text-white print:text-xl">
                      {formatDate(row.appointmentDate, practice?.timezone)}
                    </p>
                    <p className="text-right text-xs !text-white print:text-base">
                      Chart ID: {row?.patient?.id}
                    </p>
                  </div>
                </div>
                <div className="print-wrapper !mb-[10%] flex flex-col gap-[6px] whitespace-pre-line !outline-none print:!mb-0 print:break-inside-auto">
                  <RenderedComponents
                    {...row.componentProps}
                    customClinicalNote={{ ...row?.customClinicalNote, patient: row?.patient }}
                    className="!bg-transparent"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-neutral-800">No clinical summary found</p>
        )}
      </div>
    </>
  );
};

export default ClinicalNotePDF;
