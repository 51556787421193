import {
  modifyPackages,
  modifyProducts
} from 'components/practice/BeyondBilling/ElectronicInvoicing/lib/internal/configs';
import numOrDefault from 'lib/helpers/numOrDefault';
import { isNil, orderBy } from 'lodash';

const primaryCalculation = (procedure) => {
  let charge = 0;
  let ins_payment = 0;
  let ins_adjustment = 0;
  let pt_adjustment = 0;

  charge += ['self_pay', 'cash'].includes(procedure.type)
    ? procedure.pt_balance ?? procedure?.total_cost_cents ?? 0
    : procedure.charge || 0;

  let check = true;
  ['tertiary', 'secondary', 'primary'].forEach((insType) => {
    if (procedure?.[insType]) {
      procedure?.[insType]?.forEach?.((ins) => {
        ins_payment += Math.round(ins?.paid || 0) || 0;
        if (check) {
          if (ins?.pt_adj) {
            ins?.pt_adj?.forEach((adj) => {
              pt_adjustment += adj?.amount > 0 ? Math.round(+adj.amount * 100) : 0;
            });
          }
        }
      });
      check = false;
    }
  });

  let insAdj = numOrDefault(charge) - numOrDefault(ins_payment) - numOrDefault(pt_adjustment);

  if (['product', 'package', 'self_pay'].includes(procedure.type || 0)) {
    insAdj = 0;
  }

  ins_adjustment += insAdj;

  return { charge, ins_adjustment, pt_adjustment, ins_payment };
};

const secondCalculation = (procedure) => {
  const { ins_payment = 0, pt_balance = 0 } = procedure;
  const final_ins_payment = ins_payment - pt_balance;

  return Math.max(final_ins_payment, 0);
};

export function calculateProceduresTotal(procedures) {
  let final_charge = 0;
  let final_ins_payment = 0;
  let final_ins_adjustment = 0;
  let final_pt_adjustment = 0;

  procedures?.forEach?.((procedure) => {
    if (Object?.keys?.(procedure).includes('primary')) {
      const {
        charge = 0,
        ins_adjustment = 0,
        pt_adjustment = 0,
        ins_payment = 0
      } = primaryCalculation(procedure);

      final_charge += numOrDefault(charge);
      final_ins_adjustment += numOrDefault(ins_adjustment);
      final_ins_payment += numOrDefault(ins_payment);
      final_pt_adjustment += numOrDefault(pt_adjustment);
    } else if (
      procedure?.ins_payment > 0 &&
      procedure?.ins_adjustment > 0 &&
      procedure?.charge === procedure?.ins_payment + procedure?.ins_adjustment
    ) {
      const { charge = 0, pt_adjustment = 0, ins_adjustment = 0, pt_balance } = procedure;

      const ins_payment = secondCalculation(procedure);

      final_charge += charge || pt_balance || 0;
      final_ins_adjustment += numOrDefault(ins_adjustment);
      final_ins_payment += numOrDefault(ins_payment);
      final_pt_adjustment += numOrDefault(pt_adjustment);
    } else {
      let {
        charge = 0,
        total_amount_cents = 0,
        pt_balance,
        total_cost_cents = 0,
        charge_type,
        ins_payment,
        ins_adjustment
      } = procedure || {};

      let insAdj = Math.max(charge - pt_balance, 0);

      if (['product', 'package', 'self_pay', 'cash'].includes(procedure.type)) {
        insAdj = 0;
        charge = 0;
      }

      if (
        isNil(pt_balance) &&
        charge_type == 'copay' &&
        isNil(ins_payment) &&
        isNil(ins_adjustment)
      ) {
        insAdj = charge;
      }

      final_charge += (charge || pt_balance) ?? (total_cost_cents || total_amount_cents);
      final_ins_adjustment += numOrDefault(insAdj);
      final_pt_adjustment += numOrDefault(pt_balance);
    }
  });

  return {
    charge: final_charge,
    ins_adjustment: final_ins_adjustment,
    pt_adjustment: final_pt_adjustment,
    ins_payment: final_ins_payment
  };
}

export const getProceduresTotal = (invoices) => {
  let services = [];
  const procedures = invoices.map((invoice) => invoice.procedures || []).flat();

  if (procedures?.length) {
    services.push(...procedures);
  }
  const products = invoices
    .map((invoice) => modifyProducts({ products: invoice?.products || [], isTotal: true }))
    .flat();
  if (products?.length) {
    services.push(...products);
  }
  const packages = invoices
    .map((invoice) => modifyPackages({ packages: invoice?.packages || [], isTotal: true }))
    .flat();
  if (packages?.length) {
    services.push(...packages);
  }

  const { amount_paid, prevDiscount, tax, adjustment } = invoices.reduce(
    (acc, invoice) => {
      acc.amount_paid += invoice?.amount_paid || 0;
      acc.prevDiscount += invoice?.discount?.amount_cents || 0;
      acc.tax += invoice?.tax || 0;
      acc.adjustment += invoice?.adjustment || 0;

      return acc;
    },
    { amount_paid: 0, prevDiscount: 0, tax: 0, adjustment: 0 }
  );

  const calculatedProcedures = calculateProceduresTotal(services);

  return { ...calculatedProcedures, amount_paid, prevDiscount, tax, adjustment };
};

export const getInvoicesDetails = (invoices) => {
  const details = orderBy(invoices, ['dateofservice'], 'desc').map((invoice) => {
    return {
      id: invoice.id,
      dateOfService: invoice.dateofservice,
      discount: invoice?.discount?.amount_cents || 0,
      procedures: invoice.procedures,
      products: invoice.products,
      packages: invoice.packages,
      tax: invoice.tax || 0,
      amount_paid: invoice.amount_paid || 0,
      adjustment: invoice.adjustment || 0,
      created_at: invoice.created_at
    };
  });
  return details;
};
