import { iaRa } from 'lib/helpers/utility';

export default function calculateTotalCharge(procedures = []) {
  let totalCharge = 0.0;
  iaRa(procedures)?.map((procedure) => {
    totalCharge +=
      parseFloat(procedure?.charge || 0) * parseFloat(procedure?.units || 0).toFixed(2);
  });

  return isNaN(totalCharge) ? 0 : totalCharge?.toFixed(2);
}
