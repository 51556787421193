import React from 'react';
import { formatDate, ia, isEmpty } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import MedicationsModal from '../Modals/MedicationsModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const MedicationHistoryOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = [],
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview.medications) return null;

  return (
    <SimpleAccordion
      title="Medications"
      onToggle={() => toggleOne('medicationHistory')}
      open={isOpen.medicationHistory}
      edit={
        <MedicationsModal
          formId={currentHpOverviewData?.medicationHistory?.id}
          defaultValue={currentHpOverviewData?.medicationHistory?.response}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
          setCustomResponses={setOverviewData}
        />
      }>
      {overviewData?.medicationHistory?.map((medicationHistory, index) => {
        if (medicationHistory?.response?.noDetails) {
          return (
            <NoKnown
              form={medicationHistory}
              description="No known medications"
              shouldShowDivider={index !== 0}
            />
          );
        }

        return (
          ia(medicationHistory?.response?.medications) &&
          !medicationHistory?.response?.noDetails &&
          medicationHistory?.response?.medications.map((item, idx) => {
            const medicationLabel = item?.medication;
            const prescribingPhysicianLabel = item?.prescriber?.label;

            return (
              <React.Fragment key={idx}>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>

                <div className="grid gap-y-1">
                  {!isEmpty(medicationLabel) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Medication:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {medicationLabel}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.sig) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        SIG:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.sig}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.strength) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Strength:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.strength}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.quantity) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Quantity:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.quantity}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.days_supply) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Days Supply
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.days_supply}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.refills) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Refills:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.refills}
                      </span>
                    </div>
                  )}

                  {ia(item.condition) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Condition:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.condition.map((condition) => (
                          <span className="m-0 block first-letter:capitalize">
                            {condition.label}
                          </span>
                        ))}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.start_date) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Date Started:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {formatDate(item.start_date)}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.end_date) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Date Ended:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {formatDate(item.end_date)}
                      </span>
                    </div>
                  )}

                  {!isEmpty(prescribingPhysicianLabel) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Prescribing physician:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {prescribingPhysicianLabel}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item.note) && (
                    <div className="flex flex-wrap justify-between gap-x-4">
                      <span className="w-max text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Note:
                      </span>
                      <span className="break-all text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.note}
                      </span>
                    </div>
                  )}
                </div>

                <CreationInfo
                  createdAt={medicationHistory?.createdAt}
                  createdBy={medicationHistory?.createdBy?.fullName}
                />
              </React.Fragment>
            );
          })
        );
      })}
    </SimpleAccordion>
  );
};

export default MedicationHistoryOverview;
