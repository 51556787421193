import { Popover } from '@headlessui/react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import * as filestack from 'filestack-js';
import { Calendar } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createEob } from 'api/ERA';
import { interimApi } from 'api/InterimApi';
import { paymentMethods } from 'constants.js';
import Icon from 'components/shared/Icon/Icon';
import { formatDate } from 'lib/helpers/utility';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Select from 'components/shared/Select/Select';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';
import numOrDefault from 'lib/helpers/numOrDefault';
import CashPayment from 'components/Payments/cash/cash';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const NewEOBValidationSchema = Yup.object().shape({
  payerid: Yup.string().required('Insurance Payer required'),
  check_number: Yup.string().required('Payer Trace number required'),
  payment_method: Yup.string().required('Payment method required'),
  total_paid: Yup.number().required('Amount paid required'),
  paid_date: Yup.string().required('Check date required'),
  handle: Yup.string()
});

export default function NewEOB({ setNewEOB, newEOB }) {
  const [step, setStep] = useState(1); // State to track current step
  const [selectedPayerId, setSelectedPayerId] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [retry, setRetry] = useState(false); // State to handle retry

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      payerid: '',
      check_number: '',
      payment_method: '',
      total_paid: 0,
      paid_date: new Date(),
      handle: ''
    },
    validationSchema: NewEOBValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const newEob = {
        payerid: values.payerid,
        check_number: values.check_number,
        payment_method: values.payment_method,
        total_paid: values.total_paid,
        paid_date: values.paid_date,
        handle: values.handle
      };

      mutateCreateEOB.mutate(newEob);

      resetForm();
    }
  });

  const values = formik?.values;

  const mutateCreateEOB = useMutation({
    mutationFn: (data) => createEob(navigate, data),
    onMutate: () => {
      formik.setSubmitting(true);
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
    onSuccess: (data) => {
      setNewEOB(false);
      setSelectedPayerId(null);
      setSelectedPaymentMethod(null);
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: 'EOB created successfully!',
            buttons: [
              {
                text: 'View EOB',
                onClick: () => navigate(`/portal/beyond-billing/era-eob/all/${data?.eob?.id}`)
              }
            ]
          });
          break;

        default:
          break;
      }
    }
  });

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        let { data } = await interimApi('/api/payers/read', { searchTerm }, navigate);

        if (data) {
          return data.payers.map((payer) => {
            return { value: payer.id, label: payer.name };
          });
        }
      } catch (error) {
        console.error(error);
        toast.error('There was an error getting all payers.');
      }
    }
  };

  const selectPayer = (e) => {
    formik.setFieldValue('payerid', e.value);
    setSelectedPayerId(e);
  };

  const selectPaymentMethod = (e) => {
    formik.setFieldValue('payment_method', e.value);
    setSelectedPaymentMethod(e);
  };

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*', '.pdf'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    formik.setFieldValue('handle', data.filesUploaded[0].handle);
  };

  const renderStepOne = () => (
    <form className="grid gap-3">
      <Select
        isAsync
        onChange={selectPayer}
        value={selectedPayerId}
        loadOptions={searchPayers}
        placeholder="Choose Payer"
        label="Insurance Payer Name/ID"
        error={formik?.errors?.payerid}
        noOptionsMessage={() => 'Start typing the ID or name.'}
      />

      <Input
        label="Payer trace number"
        value={formik.values.check_number}
        name="check_number"
        onChange={formik.handleChange}
        error={formik?.errors?.check_number}
      />

      <Select
        label="Payment Method"
        placeholder="Choose Payment Method"
        options={paymentMethods}
        onChange={selectPaymentMethod}
        value={selectedPaymentMethod}
        error={formik?.errors?.payment_method}
      />

      <CurrencyInput
        id="total_paid"
        label={values?.payment_method === 'vPayment' ? 'Total' : 'Total paid'}
        value={formik.values.total_paid}
        error={formik?.errors?.total_paid}
        className="!h-10 !rounded-md border border-neutral-100"
        onValueChange={(v) => formik.setFieldValue('total_paid', v)}
      />

      <Popover>
        {({ close }) => (
          <>
            <label className="mb-[6px] text-sm text-neutral-900">Check Date</label>
            <Popover.Button className="relative flex w-full items-center justify-center rounded-lg border border-solid border-neutral-200 !p-0 focus:!border-primary-500 focus:!shadow-[0px_0px_0px_2px_#CBF0FD]">
              <input
                className="w-full cursor-pointer rounded-lg border-none !px-3 !py-2 text-sm text-neutral-800 placeholder-neutral-800"
                placeholder={formatDate(formik?.values?.paid_date)}
              />
              <Icon icon="new-calendar-gray" className="absolute right-3 cursor-pointer" />
            </Popover.Button>

            <Popover.Panel className="flex shadow-md">
              <Calendar
                onChange={(date) => {
                  formik.setFieldValue('paid_date', moment.utc(date).add(12, 'h'));
                  close();
                }}
                className="text-base"
                date={new Date(formik?.values?.paid_date) || new Date()}
                showDateDisplay
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      <div>
        <label className="!m-0 pb-[6px]">Scanned EOB</label>
        <Button
          outlined
          icon="new-export-bulk"
          onClick={() => openFilestackPicker()}
          text={formik.values.handle === '' ? 'Upload' : 'File uploaded'}
        />
      </div>
    </form>
  );

  const onProceed = () => {
    setStep(2);
  };

  const handleOnFail = () => {
    let newErrorMessage = 'EOB was not created, please try again.';
    setRetry(true);
    showAlert({
      title: 'Transaction failed.',
      color: 'danger',
      message: newErrorMessage
    });
  };

  const onSuccessPay = () => {
    formik.submitForm();
    formik.setValues({});
    setNewEOB(false);
  };

  const handleOnSuccess = () => {
    let newErrorMessage = 'Your payment has been processed successfully. Thank you!';
    onSuccessPay();
    showAlert({
      title: 'Transaction Finished.',
      color: 'success',
      message: newErrorMessage
    });
  };

  const renderStepTwo = () => (
    <div>
      {process.env.ENV === 'local' ? (
        <CashPayment
          amount={numOrDefault(values?.total_paid)}
          payerId={values?.payerid}
          onSuccess={onSuccessPay}
          onFail={handleOnFail}
        />
      ) : (
        <LightboxWrapper
          amount={numOrDefault(values?.total_paid)}
          payerId={values?.payerid}
          onApproval={handleOnSuccess}
          onCancel={handleOnFail}
        />
      )}
      {retry && (
        <div className="mt-5 flex justify-center">
          <Button
            text="Retry Payment"
            onClick={() => {
              setRetry(false);
              onProceed();
            }}
          />
        </div>
      )}
    </div>
  );

  return (
    <Modal
      isOpen={Boolean(newEOB)}
      handleClose={() => setNewEOB(false)}
      title="New EOB"
      slideFromRight
      footer={
        <div className="fixed bottom-0 left-0 right-0 flex w-full justify-between border-x-0 border-b-0 border-t border-solid border-neutral-100 bg-white !p-4">
          <Button
            outlined
            text={step === '1' ? 'Cancel' : 'Back'}
            color="neutral"
            onClick={() => {
              if (step === 2) {
                setStep(1);
              }
              setNewEOB(false);
            }}
          />

          {step === 1 && values?.payment_method === 'vPayment' ? (
            <Button text="Proceed to Pay" onClick={onProceed} />
          ) : (
            <Button text="Create" onClick={() => formik.submitForm()} />
          )}
        </div>
      }>
      {values?.payment_method === 'vPayment' ? (
        <>{step === 1 ? renderStepOne() : renderStepTwo()}</>
      ) : (
        renderStepOne()
      )}
    </Modal>
  );
}
