import React, { useEffect, useState } from 'react';
import cs from 'classnames';

const FadeInText = ({ texts, delay = 2000 }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [prevTextIndex, setPrevTextIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setPrevTextIndex(currentTextIndex);
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, delay);

    return () => clearInterval(interval);
  }, [currentTextIndex, delay, texts.length]);

  return (
    <div className="relative h-16 overflow-hidden">
      {texts.map((text, index) => (
        <div
          key={index}
          className={cs(
            'absolute left-0 top-0 w-full text-3xl transition-all duration-1000 ease-in-out',
            index === currentTextIndex
              ? 'translate-y-0 opacity-100'
              : index === prevTextIndex
                ? 'translate-y-full opacity-0'
                : '-translate-y-full opacity-0'
          )}>
          {text}
        </div>
      ))}
    </div>
  );
};

export default FadeInText;
