const _ = require('lodash');

let recurseGet = (obj, path, name, pA) => {
  if (!obj)
    return [{ properties: [], path: path, name: name, message: _.get(pA, `${path}.message`) }, 0];
  let keys = Object.keys(obj);
  let numberOfPermissions = 0;
  const pAValue = _.get(pA, path);
  let objectName = name;
  if (pAValue && pAValue?.name) {
    objectName = pAValue.name;
  }
  if (typeof obj !== 'object') {
    return [
      {
        path: `${path}`,
        value: obj,
        name: objectName,
        message: _.get(pA, `${path}.message`)
      },
      obj ? 1 : 0
    ];
  }
  let r = [];
  for (let i = 0; i < keys.length; i++) {
    let [newA, newNumOfPermissions] = recurseGet(
      obj[keys[i]],
      path ? `${path}.${keys[i]}` : keys[i],
      keys[i],
      pA
    );
    r.push(newA);
    numberOfPermissions += newNumOfPermissions;
  }
  return [
    { properties: r, path: path, name: objectName, message: _.get(pA, `${path}.message`) },
    numberOfPermissions
  ];
};

module.exports = (permisisonsObject, permissionAssignment, path, name) => {
  return recurseGet(permisisonsObject, path, name, permissionAssignment);
};

/** test
let a = {
  date: new Date(),
  data: {
    permission: {
      create: false,
      updated: false,
      delete: false,
      get: false
    },
    someOtherPermission: {
      create: false,
      updated: false,
      delete: false,
      get: false
    }
  }
};
let aP = {
  data: {
    permission: {
      enabled: {
        default: true
      },
      create: {
        message: 'Create permissions'
      }
    }
  }
};
let b = {
  clinicalNote: {
    hp: {
      enabled: true
    },
    soap: {
      enabled: true
    },
    dap: {
      enabled: false
    },
    mha: {
      enabled: false
    },
    epn: {
      enabled: false
    },
    vitals: {
      enabled: true
    },
    treatment_plan: {
      enabled: true
    },
    overview: {
      enabled: true,
      vitals: true,
      allergies: true,
      medications: true,
      weightLossAdministration: false,
      vaccines: true,
      alerts: true,
      conditions: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true
    },
    macros: {
      enabled: true
    }
  },
  appointmentFilters: {
    appointmentType: [],
    appointmentStatus: [],
    appointmentConfirmState: [],
    serviceType: [],
    patient: [],
    practictioners: [],
    selectedView: null
  }
};
let bP = {};
console.log(module.exports(a,aP,'permission','permission'))
//  */
