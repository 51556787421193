const { isEmpty, io, ia } = require('../../../../../lib/helpers/utility');
const { convertToNormalText } = require('./customFormsHelper');
const { textBoldAndUnderline, textItalic } = require('./markdownHelpers');

const deleteUnnecessaryKeys = (data) => {
  if (!data?.noDetails) delete data.noDetails;
  delete data?.narrative;
  delete data?.id;

  const shouldDeleteNoModifyingFactors =
    data?.modifyingFactors && data?.modifyingFactors?.noModifyingFactors === false;

  if (shouldDeleteNoModifyingFactors) {
    delete data.modifyingFactors.noModifyingFactors;
  }
};

const shouldSkipModifyingFactors = ({ key, value }) => {
  if (key !== 'modifyingFactors') return { skip: false };

  if (value.noModifyingFactors)
    return { skip: true, text: textItalic({ text: 'No modifying factors \n' }) };

  if (everyFieldIsEmpty(value)) return { skip: true };

  return { skip: false };
};

const everyFieldIsEmpty = (value) => {
  return Object.values(value).every((v) => isEmpty(v));
};

exports.formatSections = ({
  data = {},
  indent = '',
  sectionName = null,
  result = '',
  isProvider
}) => {
  if (!io(data)) {
    return;
  }

  if (data?.noDetails) {
    return textItalic({
      text: `${sectionName ? `No notable ${sectionName}` : 'No Details'}\n\n`
    })?.trim();
  }

  deleteUnnecessaryKeys(data);

  for (const key in data) {
    const value = data[key];

    const { skip, text = '' } = shouldSkipModifyingFactors({ key, value });
    if (text) result += text;

    if (skip) continue;

    if (value !== null && value !== undefined && value !== '') {
      if (typeof value === 'object' && io(value)) {
        if (!isEmpty(value.value)) {
          result += `${indent}${textBoldAndUnderline({
            text: `${convertToNormalText(key)}`
          })} : ${value.value}\n`;
        } else if (shouldContinueRecursion(value)) {
          result += `\n`;
          result += `${indent}${textBoldAndUnderline({
            text: `${convertToNormalText(key)}`
          })} :\n`;
          result = `${this.formatSections({
            data: value,
            indent: `${indent}  `,
            result
          })}\n`;
        }
      } else if (
        typeof value === 'boolean' ||
        (typeof value === 'string' && ['yes', 'no'].includes(value?.toLowerCase()))
      ) {
        if (!value) continue;

        const shouldAppendNoText = value === 'no' ? 'No' : 'Yes';
        result += `${indent} ${textBoldAndUnderline({
          text: `${convertToNormalText(key)}:`
        })} ${shouldAppendNoText}\n`;
      } else if (!isEmpty(value)) {
        const shouldShowLabel = isNaN(key) ? `${convertToNormalText(key)}: ` : '';

        result += `${indent}${textBoldAndUnderline({ text: `${shouldShowLabel}` })} ${value}\n`;
      }
    }
  }

  if (!isEmpty(result?.trim())) {
    if (isProvider !== undefined) {
      result = isProvider
        ? `${textItalic({ text: `Provider reports: \n\n` })} ${result}`
        : `${textItalic({ text: `Patient reports: \n\n` })} ${result}`;
    }

    return result.trim();
  }

  return `No notable ${sectionName?.toLowerCase()}\n\n`;
};

const shouldContinueRecursion = (values) => {
  if (!values) return false;

  return Object.keys(values).some((key) => {
    const valueType = typeof values[key];

    return (
      (valueType === 'array' && ia(values[key])) ||
      (valueType === 'object' && io(values[key])) ||
      (valueType === 'string' && values[key]?.length > 0) ||
      valueType === 'number' ||
      (valueType === 'boolean' && values[key])
    );
  });
};
