import React from 'react';
import TagsView from 'components/shared/Tags/View';
import { useFaxContext } from 'lib/context/FaxContext/FaxContext';
import Icon from 'components/shared/Icon/Icon';

const DisplayTags = ({ data }) => {
  const { setOpenTagModal, setFaxTags, setFaxId, setPrevTags } = useFaxContext();
  return (
    <>
      {data?.tags ? (
        <div className="flex h-full items-center justify-end gap-2">
          <TagsView currTags={data?.tags || []} kind="fax" itemsToShow={3} label={false} />
          <Icon
            icon="new-plus"
            color="neutral"
            className="!bg-none"
            onClick={() => {
              setFaxId(data?.id);
              setFaxTags(data?.tags?.map((v) => ({ ...v, label: v?.name, value: v?.name })));
              setPrevTags(data?.tags?.map((v) => ({ ...v, label: v?.name, value: v?.name })));
              setOpenTagModal(true);
            }}
          />
        </div>
      ) : (
        <div className="flex h-full items-center justify-end">
          <Icon
            icon="new-plus"
            color="neutral"
            className="!bg-none"
            onClick={() => {
              setFaxId(data?.id);
              setOpenTagModal(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default DisplayTags;
