import React from 'react';
import Icon from '../../../../shared/Icon/Icon';

const NoPaymentBoxInfo = () => (
  <div className="flex items-start gap-2 rounded-md bg-warning-100 !px-4 !py-2">
    <Icon icon="new-info" />
    <p className="text-sm text-warning-600">
      This patient has failed to pay the estimated patient portion or guarantee payment on a credit
      card. Treating this patient carries increased risk of non-payment.
      <br />
      Please ensure the patient makes this payment or guarantees payment on a credit card before
      treatment is rendered.
    </p>
  </div>
);

export default NoPaymentBoxInfo;
