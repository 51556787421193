import { Tab } from '@headlessui/react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import generatePDF, { Resolution } from 'react-to-pdf';
import { Patients } from './Tabs';
import SelectedFiles from './Tabs/SelectedFiles';
import { ia } from '/lib/helpers/utility';

const options = {
  method: 'build',
  resolution: Resolution.NORMAL,
  page: {
    margin: 7,
    format: 'letter',
    orientation: 'portrait'
  },
  canvas: {
    mimeType: 'image/png',
    qualityRatio: 1
  },
  overrides: {
    pdf: {
      compress: true
    },
    canvas: {
      useCORS: true,
      scale: 2.4
    }
  }
};
const DocsModal = ({ docsModalVisible, setDocsModalVisible, handleInsertFiles }) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [formFiles, setFormFiles] = useState([]);
  const inputFieldRef = useRef(null);
  const [filters, setFilters] = useState({
    patientSearchTerm: '',
    docSearchTerm: '',
    patientsPage: 1,
    docsPage: 1,
    limit: 15
  });
  const [tabState, setTabState] = useState({
    selectedPatient: null,
    selectedFiles: [],
    allPatients: [],
    patientDocuments: []
  });

  useEffect(() => {
    if (docsModalVisible) {
      inputFieldRef.current?.focus();
    }
  }, [docsModalVisible]);

  const forwardProps = { filters, setFilters, tabState, setTabState, setSearchTerm };

  const tabsData = [
    {
      label: 'Patients',
      content: <Patients {...{ ...forwardProps }} />,
      icon: 'profile-2user',
      length: tabState.allPatients.length || 0
    },
    {
      label: 'Selected',
      content: <SelectedFiles {...{ ...forwardProps }} />,
      icon: 'attach',
      length: tabState.selectedFiles.length
    }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (ia(formFiles)) {
      convert(formFiles);
    }
  }, [formFiles]);

  const handleChangeSearchTerm = (searchText) => {
    if (
      (tabState.selectedPatient && searchText == filters.docSearchTerm) ||
      (!tabState.selectedPatient && searchText === filters.patientSearchTerm)
    )
      return;
    setTabState((prev) => ({
      ...prev,
      [tabState.selectedPatient ? 'patientDocuments' : 'allPatients']: []
    }));
    setFilters({
      ...filters,
      [tabState.selectedPatient ? 'docSearchTerm' : 'patientSearchTerm']: searchText,
      [tabState.selectedPatient ? 'docsPage' : 'patientsPage']: 1
    });
  };
  function createFileFromBuffer(buffer, fileName, mimeType) {
    const blob = new Blob([buffer], { type: mimeType });

    let file = new File([blob], fileName, { type: mimeType });
    file['id'] = file.size;
    return file;
  }
  const handleClearSearchTerm = () => {
    setTabState((prev) => ({
      ...prev,
      [tabState.selectedPatient ? 'patientDocuments' : 'allPatients']: []
    }));
    setSearchTerm('');
    setFilters({
      ...filters,
      docSearchTerm: '',
      patientSearchTerm: '',
      docsPage: 1,
      patientsPage: 1
    });
  };
  const ref = useRef();

  const convertFormToPdf = async (doc) => {
    try {
      const contentId = `cover-page-${doc?.form_id}`;
      const getTargetElement = () => document.getElementById(contentId);
      const generatedPdf = await generatePDF(getTargetElement, options);
      const pdfArrayBuffer = generatedPdf?.output('arraybuffer');
      const pdfBuffer = new Uint8Array(pdfArrayBuffer);

      const file = {
        buffer: pdfBuffer,
        name: doc.name + '.pdf',
        originalname: doc.name + '.pdf',
        mimetype: 'application/pdf',
        size: pdfBuffer.byteLength,
        fieldname: 'files',
        id: doc.form_id
      };

      const buffer = new Uint8Array(file.buffer).buffer;
      const createdFile = createFileFromBuffer(buffer, file.name, file.mimetype);

      return createdFile;
    } catch (error) {
      console.log('error converting form to a pdf', error);
    }
  };

  const selectFiles = () => {
    try {
      const formFiles = tabState.selectedFiles.filter((file) => !!file.json);
      if (!ia(formFiles)) {
        convert(formFiles);
      }
      for (const file of formFiles) {
        const { id, form_id, appointment_id, json, form_json, form_version, name } = file;
        if (form_id) {
          const form = {
            response_id: id,
            appointment_id,
            form_id,
            json,
            form_json,
            form_version,
            name
          };
          const jsx = (
            <div
              className="PrintForm bg-onprint-white"
              ref={ref}
              id={`cover-page-${form_id}`}
              key={form_id}>
              <ReactFormGenerator
                answer_data={JSON.parse(form?.json?.fields)}
                data={form?.form_json?.fields}
                submitButton={<div />}
                read_only={true}
              />
            </div>
          );

          setFormFiles((prev) => [...prev, { ...form, jsx }]);
        }
      }
    } catch (error) {
      console.log('error selecting files');
    }
  };

  const convert = async () => {
    setLoading(true);
    const arraybufferFiles = [];
    for (const file of formFiles) {
      const convertedFile = await convertFormToPdf(file);
      arraybufferFiles.push(convertedFile);
    }
    const pdfFiles = tabState.selectedFiles.filter((file) => !file.json);
    handleInsertFiles([...arraybufferFiles, ...pdfFiles]);
    setLoading(false);
  };

  return (
    <Modal
      handleOpen={!!docsModalVisible}
      handleClose={() => setDocsModalVisible(false)}
      title="Search from existing files"
      customStyling={{ width: '500px' }}
      className="!p-0 text-primary-900 bg-primary-10"
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setDocsModalVisible(false)}
            data-qa="cancel-select-files-btn"
          />
          <Button
            onClick={selectFiles}
            text="Insert files"
            id="createContactModalBtn"
            data-qa="select-files-btn"
            loadingIcon={loading}
            disabled={loading}
          />
        </div>
      }>
      <div>
        <div>
          <Input
            id="searchPatient"
            type="string"
            focusOnMount={true}
            forwardedRef={inputFieldRef}
            placeholder={
              tabState.selectedPatient
                ? `Search ${tabState.selectedPatient.fullName} docs..`
                : 'Search patient...'
            }
            value={searchTerm}
            icon="new-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            rightText={
              (filters.docSearchTerm || filters.patientSearchTerm) && (
                <Icon icon="new-close" onClick={handleClearSearchTerm} />
              )
            }
          />
        </div>
        <Tab.Group>
          <Tab.List className="!mb-3 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
            {tabsData.map((tab, i) => {
              return (
                <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                  {({ selected }) => (
                    <button
                      className={cs(
                        'flex cursor-pointer items-center gap-1 border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                        selected
                          ? ' !border-primary-600 font-600 text-primary-500'
                          : 'font-500 text-neutral-800'
                      )}>
                      <Icon icon={tab.icon} />
                      {tab.label}
                      {tabState.selectedPatient && tab.label == 'Patients' && selected ? (
                        <div className="flex items-center">
                          <p className="font-500">-</p>--tw-scroll-snap-strictness
                          <h4 className="text-16 leading-24 font-s !px-1 text-sm font-600 text-primary-900">
                            {tabState.selectedPatient.f_name}
                          </h4>
                          <Icon
                            icon="new-close-square"
                            className="ml-1"
                            onClick={() => {
                              setTabState((prev) => ({ ...prev, selectedPatient: null }));
                              setFilters((prev) => ({ ...prev, patientSearchTerm: '' }));
                              setSearchTerm('');
                            }}
                          />
                        </div>
                      ) : (
                        tab.length > 0 && (
                          <label
                            className={cs(
                              '!ml-1 mb-0 grid !h-6 !w-6 cursor-pointer place-content-center rounded-full text-xs',
                              selected
                                ? 'bg-primary-100 text-primary-500'
                                : 'bg-neutral-100 text-neutral-700'
                            )}>
                            {tab.length}
                          </label>
                        )
                      )}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {tabsData.map((tab, i) => (
              <Tab.Panel key={i}>
                {({ selected }) => {
                  return selected ? tab.content : null;
                }}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
        <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
          {formFiles.map((file) => file.jsx)}
        </div>
      </div>
    </Modal>
  );
};

export default DocsModal;
