import React from 'react';
import cs from 'classnames';
import './Status.scss';

const Status = ({ status, className, textClassName }) => {
  return (
    <div className={cs('Status', `Status--${status?.replaceAll(' ', '-')}`, className)}>
      <span className={textClassName}>{status}</span>
    </div>
  );
};

export default Status;
