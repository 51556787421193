import React from 'react';
import Select from '../../../../shared/Select/Select';

const Resources = ({ formik, resources }) => {
  return (
    <div>
      <p className="text-sm font-500">Resource</p>
      <Select
        placeholder="Type and select a resource"
        value={resources?.find((resource) => resource?.value === formik?.values?.resource_id)}
        onChange={(value) => {
          formik.setFieldValue('resource_id', value?.value || null);
        }}
        options={resources}
        inputId="resourceSelect"
        error={formik.errors.resource_id}
        className="!mt-2"
        isImage
      />
    </div>
  );
};

export default Resources;
