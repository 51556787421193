import React, { useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Plan = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const planRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { plan: clinicalNote?.plan }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };
  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write plan notes here"
      id="plan"
      name="plan"
      className="min-h-[200px]"
      value={clinicalNote?.plan}
      onChange={(e) => handleChange(e, 'plan')}
      transcribing
      forwardedRef={planRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Plan;
