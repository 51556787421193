import NotFound from 'components/practice/Dashboard/components/NotFound';
import Loader from 'components/shared/Loader/Loader';
import { iaRa, spaceToKebabCase } from 'lib/helpers/utility';
import { useClinicalNoteRead } from 'lib/hooks/queries/clinicalNotes/useClinicalNote';
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Modal from '../../../../../shared/Modal/Modal';
import { currentPractice } from '../../../../practiceState';
import { getOverview } from '../../lib/getOverview';
import { processCustomFormTypes } from '../../lib/processCustomNoteTypes';
import PreviewNote from '../../PreviewNote/PreviewNote';

const OutsideClinicalNoteExport = ({
  showExportModal,
  setShowExportModal,
  selectedClinicalNote,
  setSelectedClinicalNote = () => null
}) => {
  const {
    setClinicalNote,
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    setCurrentHpOverviewData,
    clinicalNote,
    advancedHP,
    advancedSOAP,
    customFormTypes
  } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);
  const { patient } = useOutletContext() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { isFetching } = useClinicalNoteRead({
    dependencies: [selectedClinicalNote?.appointment?.id],
    params: {
      patientId: patient?.id || selectedClinicalNote?.appointment?.user_id,
      appointmentId: selectedClinicalNote?.appointment?.id,
      loadedPracticeId: practice?.id,
      exporting: true
    },
    options: {
      enabled: !!selectedClinicalNote?.appointment?.id,
      refetchOnMount: true,
      onSettled: async (data) => {
        setLoading(true);
        let { clinicalNote, advancedHP, advancedSOAP, customFormTypes, serviceCPT } = data || {};

        if (!clinicalNote?.id) {
          setClinicalNote(null);
          setAdvancedHP(null);
          setAdvancedSOAP(null);
          setCustomFormTypes(null);
        }

        setClinicalNote(structuredClone({ ...clinicalNote, cpt_codes: iaRa(serviceCPT) }));

        if (advancedHP) {
          setAdvancedHP(
            advancedHP.map((item) => ({
              ...item,
              custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
                item?.form_id || item?.id
              }`
            }))
          );
        }

        if (advancedSOAP) {
          setAdvancedSOAP(
            advancedSOAP.map((item) => ({
              ...item,
              custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
                item?.form_id || item?.id
              }`
            }))
          );
        }

        if (customFormTypes) {
          const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });

          setCustomFormTypes(processedCustomFormTypes);
        }

        const currentHpOverviewData = await getOverview({
          patientId: clinicalNote?.user_id,
          appointmentId: clinicalNote?.appointment_id,
          navigate,
          isKebab: false
        });

        setCurrentHpOverviewData(currentHpOverviewData);

        setLoading(false);
      }
    }
  });

  const closeModal = () => {
    setClinicalNote(null);
    setAdvancedHP(null);
    setAdvancedSOAP(null);
    setCustomFormTypes(null);
    setShowExportModal({ open: false });
    setSelectedClinicalNote(null);
  };

  return (
    <Modal
      handleOpen={showExportModal?.open}
      handleClose={closeModal}
      title="Clinical note export"
      className="w-[1020px]"
      bodyClassName="p-0"
      slideFromRight>
      <DisplayContent
        clinicalNote={clinicalNote}
        showExportModal={showExportModal}
        selectedClinicalNote={selectedClinicalNote}
        isFetching={isFetching || loading}
        advancedHP={advancedHP}
        advancedSOAP={advancedSOAP}
        customFormTypes={customFormTypes}
      />
    </Modal>
  );
};

const DisplayContent = ({
  clinicalNote,
  showExportModal,
  selectedClinicalNote,
  isFetching,
  advancedHP,
  advancedSOAP,
  customFormTypes
}) => {
  if (isFetching) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div>
          <Loader className="!h-32 !w-32" outlined color="success" />
        </div>

        <p>Loading, please wait...</p>
        <p>
          Your content is being prepared. This might take a moment. Thank you for your patience!
        </p>
      </div>
    );
  }

  if (!clinicalNote && !advancedHP && !advancedSOAP && !customFormTypes) {
    return (
      <NotFound
        title="Clinical Note Not Found"
        description="Clinical note not found. Please refresh and try again. Contact support if the issue persists."
      />
    );
  }

  return (
    <PreviewNote
      patientId={selectedClinicalNote?.appointment?.user_id}
      isExport={showExportModal?.showOnlyExport}
      outsideAppointmentId={selectedClinicalNote?.appointment?.id}
    />
  );
};

export default OutsideClinicalNoteExport;
