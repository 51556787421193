import _ from 'lodash';
import { ia, io } from '../../../../../../lib/helpers/utility';

export const selectedInitialState = (
  clinicalNote,
  cnDisplaySettings,
  currentHpOverviewData,
  vitals,
  currentMyScribe,
  referralsData,
  nonERXPrescription,
  labOrder
) => ({
  ...((clinicalNote?.present_illness || clinicalNote?.other_complaints) && {
    complaints: {
      title: 'Complaints',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.present_illness && {
    patientNeeds: {
      title: 'Patient needs / presenting issues',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.other_complaints && {
    problemManifestationDefinition: {
      title: 'Problem manifestation / definition',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.mha?.timeFrames && {
    timeFrames: {
      title: 'Time Frames',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.mha?.riskAndCoMorbidities && {
    riskAndCoMorbidities: {
      title: 'Risk and co-Morbidities',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.hpi) && {
    hpi: {
      title: 'History of present illness',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(clinicalNote?.icd_10_codes) && {
    diagnosisCodes: {
      title: 'Diagnosis codes',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),

  ...(clinicalNote?.ros && {
    ros: {
      title: 'Review of systems',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(clinicalNote?.goals) && {
    goals: {
      title: 'Goals',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(clinicalNote?.goals) && {
    goals: {
      title: 'Goals', //duplicate
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.exam && {
    exam: {
      title: 'Exam',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.test && {
    test: {
      title: 'Test',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.session) && {
    session: {
      title: 'Session',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.riskFactors) && {
    riskFactors: {
      title: 'Risk factors',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.epn?.sessionQuotesAndContents && {
    sessionQuotesAndContents: {
      title: 'Session quotes and contents',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.activeSymptoms) && {
    activeSymptoms: {
      title: 'Active symptoms',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.functionalImpairment) && {
    functionalImpairment: {
      title: 'Functional impairment',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.interventions) && {
    interventions: {
      title: 'Interventions',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(io(clinicalNote?.epn?.progress) && {
    progress: {
      title: 'Progress',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.subjective && {
    subjective: {
      title: 'Subjective',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.objective && {
    objective: {
      title: 'Objective',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.assessment && {
    assessment: {
      title: 'Assessment',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.plan && {
    plan: {
      title: 'Plan',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.allergies && {
    allergies: {
      title: 'Allergies',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData.vitals && {
    vitals: {
      title: 'Vitals',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(currentHpOverviewData?.medicationHistory?.response?.medications) && {
    medications: {
      title: 'Medications',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.vaccines && {
    vaccines: {
      title: 'Vaccines',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.weightLossAdministration && {
    weightLossAdministration: {
      title: 'Weight Loss Administration',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.socialHistory && {
    socialHistory: {
      title: 'Social History',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.familyHistory && {
    familyHistory: {
      title: 'Family History',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.medicalHistory && {
    medicalHistory: {
      title: 'Medical History',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(currentHpOverviewData?.surgicalHistory && {
    surgicalHistory: {
      title: 'Surgical History',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(clinicalNote?.cpt_codes) && {
    cptCodes: {
      title: 'CPT® codes',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(clinicalNote?.notes && {
    notes: {
      title: 'General Notes',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(clinicalNote?.charts3d?.face?.points) && {
    chart3d: {
      title: '3D Charting',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(referralsData) && {
    referralOrders: {
      title: 'Referral Orders',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(nonERXPrescription) && {
    nonERXPrescriptionOrders: {
      title: 'Non ERX Prescription Orders',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(labOrder) && {
    labOrImagingOrders: {
      title: 'Lab/Imaging Orders',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  ...(ia(currentMyScribe) && {
    myscribe: {
      title: 'MyScribe AI Narrative',
      id: self.crypto.randomUUID(),
      checked: true,
      position: null
    }
  }),
  providerSignature: {
    title: 'Provider Signature',
    id: self.crypto.randomUUID(),
    checked: true,
    position: null
  }
});

export const initialStatesRender = [
  'complaints',
  'patientNeeds',
  'problemManifestationDefinition',
  'hpi',
  'diagnosisCodes',
  'ros',
  'goals',
  'exam',
  'test',
  'session',
  'riskFactors',
  'sessionQuotesAndContents',
  'activeSymptoms',
  'functionalImpairment',
  'interventions',
  'progress',
  'subjective',
  'objective',
  'assessment',
  'plan',
  'allergies',
  'medications',
  'timeFrames',
  'riskAndCoMorbidities',
  'vitals',
  'vaccines',
  'weightLossAdministration',
  'socialHistory',
  'medicalHistory',
  'familyHistory',
  'surgicalHistory',
  'cptCodes',
  'referralOrders',
  'nonERXPrescriptionOrders',
  'labOrImagingOrders',
  'myscribe',
  'notes'
];
