import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io, sortObjectByProperty } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { filterFalsyValues } from '../../../lib/filterFalsyValues';
import { generateProgressList } from '../../../lib/generateProgressList';
import OrdersSection from '../../../Orders/OrdersSection';
import VitalsSection from '../HPNote/components/VitalsSection';
import AdvancedSoap from '../SOAPNote/components/AdvancedSoap';
import ActiveSymptomsSection from './components/ActiveSymptomsSection';
import CPTCodesSection from './components/CPTCodesSection';
import FunctionalImpairmentSection from './components/FunctionalImpairmentSection';
import GeneralNoteSection from './components/GeneralNoteSection';
import GoalsSection from './components/GoalsSection';
import ICDCodesSection from './components/ICDCodesSection';
import InterventionsSection from './components/InterventionsSection';
import ProgressSection from './components/ProgressSection';
import RiskFactorsSection from './components/RiskFactorsSection';
import SessionQuotesAndContentsSection from './components/SessionQuotesAndContentsSection';
import SessionSection from './components/SessionSection';

const ProgressNote = ({ context }) => {
  const { hpNoteOverviewRefs } = context;

  const {
    components,
    setComponents,
    advancedSOAP,
    setOverviewData,
    cnDisplaySettings,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  } = useClinicalNoteContext();
  const { id, appointmentId, formName } = useParams();
  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  };

  const [formsList, setFormList] = useState({
    goals: GoalsSection,
    session: SessionSection,
    'risk-factors': RiskFactorsSection,
    'session-quotes-and-contents': SessionQuotesAndContentsSection,
    'active-symptoms': ActiveSymptomsSection,
    'functional-impairment': FunctionalImpairmentSection,
    vitals: VitalsSection,
    interventions: InterventionsSection,
    progress: ProgressSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection
  });

  const firstActiveComponent = Object.keys(components)[0];
  const currAdvForm = advancedSOAP?.find((item) => {
    const title = item?.custom_title;
    if ((!formName && title === firstActiveComponent) || formName === title) return item;
  });

  useEffect(() => {
    const advancedSOAPForms = advancedSOAP?.map((item) => {
      if (cnDisplaySettings?.sections?.progress[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    });
    const filteredAdvancedSOAPForms = advancedSOAPForms.filter((value) => io(value));
    const advancedTitles =
      ia(filteredAdvancedSOAPForms) &&
      Object.fromEntries(
        filteredAdvancedSOAPForms?.map((item) => {
          const title = item?.custom_title;
          return [
            title,
            {
              title: item?.form?.name || item?.name,
              path: title,
              position: cnDisplaySettings?.sections?.progress?.[item?.custom_title]?.position
            }
          ];
        })
      );

    const assignAdvTitlesToComponent = Object.keys(advancedTitles).reduce((acc, key) => {
      acc[key] = () => <AdvancedSoap currAdvForm={currAdvForm} displaySectionTitle="progress" />;
      return acc;
    }, {});

    setFormList((prevState) => ({ ...prevState, ...assignAdvTitlesToComponent }));

    const enabledComponents = filterFalsyValues(
      generateProgressList(cnDisplaySettings, advancedTitles)
    );
    const sortedComponents = sortObjectByProperty(enabledComponents, 'position');
    setComponents(sortedComponents);
  }, [cnDisplaySettings?.sections, advancedSOAP, formName, currAdvForm]);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  if (!io(components) || !CurrentForm) return <Skeleton height="464px" />;

  return (
    <div className="flex h-full flex-col gap-y-3">
      <CurrentForm
        key={formName}
        components={components}
        formId={currentHpOverviewData?.[formName]?.id}
        defaultValue={currentHpOverviewData?.[formName]?.response}
        hpNoteOverviewRef={hpNoteOverviewRefs?.[formName]}
        customFormsParams={customFormsParams}
        setCurrentFormData={(newData) =>
          setCurrentHpOverviewData((prevState) => ({
            ...prevState,
            [formName]: { ...prevState[formName], response: newData }
          }))
        }
      />
    </div>
  );
};

export default ProgressNote;
