import React from 'react';
import { Outlet } from 'react-router-dom';

const Fax = () => (
  <div className="flex h-full flex-col bg-white" data-dd-privacy="allow">
    <Outlet />
  </div>
);

export default Fax;
