import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import ICDCodes from './ICDCodes';

const ICDCodesSection = ({ components, sectionRef, options }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.diagnosisCodes?.title}
      id={components?.diagnosisCodes?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <ICDCodes sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ICDCodesSection;
