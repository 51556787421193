import html2pdf from 'html2pdf.js';

const createFileFromBuffer = (buffer, fileName, mimeType) => {
  const blob = new Blob([buffer], { type: mimeType });
  let file = new File([blob], fileName, { type: mimeType });
  file['id'] = file.size;
  return file;
};

export const convertFormToPdf = async (props) => {
  const {
    setFaxData,
    setFaxLoading,
    setSendFaxModalVisible,
    itemProps,
    patientName,
    contentId = 'referral_order',
    subject = 'Order for'
  } = props || {};

  const { to_phone = null, to_name = null } = itemProps || {};

  setFaxLoading(true);

  try {
    // const contentId = 'referral_order';
    const getTargetElement = () => document.getElementById(contentId);
    html2pdf()
      .set({
        jsPDF: {
          unit: 'pt',
          format: 'a4',
          orientation: 'portrait',
          compress: true
        },
        pagebreak: { mode: 'avoid-all', before: '.pagebreak', after: '.pagebreak' },
        margin: 36
      })

      .from(getTargetElement())
      .outputPdf('arraybuffer')
      .then((arrayBuffer) => {
        const pdfBuffer = new Uint8Array(arrayBuffer);
        const file = {
          buffer: pdfBuffer,
          name: contentId + '.pdf',
          originalname: contentId + '.pdf',
          mimetype: 'application/pdf',
          size: pdfBuffer.byteLength,
          fieldname: 'files',
          id: self.crypto.randomUUID()
        };

        const buffer = new Uint8Array(file.buffer).buffer;
        const createdFile = createFileFromBuffer(buffer, file.name, file.mimetype);
        setFaxData({
          subject: `${subject} ${patientName}`,
          deliver_to: to_name ?? '',
          fax_numbers: [to_phone],
          files: [createdFile]
        });

        setSendFaxModalVisible(true);
      });
  } catch (error) {
    console.log(error);
  }

  setFaxLoading(false);
};
