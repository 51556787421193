import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Loader from 'components/shared/Loader/Loader';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useMacrosContext } from '../../../../../../lib/context/MacrosContext/MacrosContext';
import Icon from '../../../../../shared/Icon/Icon';
import PreviousNotes from '../../LoadFrom/PreviousNotes';
import CreateMacro from '../../Sidebar/Macros/Create/CreateMacro';
import LoadMacros from '../../Sidebar/Macros/Load/LoadMacros';
import { showPreviewNote } from '../../lib/showPreviewNote';

const LoadAndExport = () => {
  const {
    clinicalNote,
    setClinicalNote,
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    showPrevNoteModal,
    setShowPrevNoteModal,
    setPreviewNoteModal,
    setIsExport,
    saveNoteRef,
    saveButtonText
  } = useClinicalNoteContext();
  const { loadMacrosModal, setLoadMacrosModal, filters, setFilters } = useMacrosContext();
  const [macrosPopover, setMacrosPopover] = useState(false);
  const [createMacroModal, setCreateMacroModal] = useState(false);
  const [disabled, setDisabled] = useState(clinicalNote?.locked);
  const [prevNotesPopover, setPrevNotesPopover] = useState(false);
  const [showPreviewNoteLoading, setShowPreviewNoteLoading] = useState(false);

  const { id, appointmentId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <div>
            <Popover.Button className="p-0" data-qa="clinical-notes-menu">
              <Icon
                icon="new-firstline"
                className={cs(
                  'flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md bg-primary-800',
                  open && '!bg-primary-700'
                )}
              />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
              <div
                className="relative flex justify-between gap-x-2 py-[6px] !pl-4 !pr-2 transition-all hover:bg-primary-50"
                onMouseEnter={() => {
                  setMacrosPopover(true);
                }}
                onMouseLeave={() => {
                  setMacrosPopover(false);
                }}>
                <div className="flex gap-x-[9px]">
                  <Icon icon="new-notev1" />
                  <span
                    className="select-none text-sm font-400 text-primary-900"
                    data-qa="appointment-templates">
                    Appointment macros
                  </span>
                </div>
                <Icon icon="new-chevron-right" />
                {macrosPopover && (
                  <div className="absolute -top-3 right-[96%] z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                    {!disabled && (
                      <div
                        data-qa="load-from-appointments-templates"
                        className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFilters({ ...filters, macroType: 'appointment_macro' });
                          setMacrosPopover(false);
                          setLoadMacrosModal(true);
                          close();
                        }}>
                        <Icon icon="new-file-download" />
                        <span className="text-sm font-400 text-primary-900">
                          Load from appointment macros
                        </span>
                      </div>
                    )}
                    <div
                      data-qa="create-new-appointment-template"
                      className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMacrosPopover(false);
                        setCreateMacroModal(true);
                        close();
                      }}>
                      <Icon icon="new-diskette" color="primary" />
                      <span className="text-sm font-400 text-primary-900">
                        Save as new appointment macro
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {!disabled && (
                <div
                  className="relative flex justify-between gap-x-2 py-[6px] !pl-4 !pr-2 transition-all hover:bg-primary-50"
                  onMouseEnter={() => {
                    setPrevNotesPopover(true);
                  }}
                  onMouseLeave={() => {
                    setPrevNotesPopover(false);
                  }}>
                  <div className="flex gap-x-[9px]">
                    <Icon icon="new-reset-history" />
                    <span
                      className="select-none text-sm font-400 text-primary-900"
                      data-qa="previous-notes">
                      Previous notes
                    </span>
                  </div>
                  <Icon icon="new-chevron-right" />
                  {prevNotesPopover && (
                    <div className="absolute -top-3 right-[96%] z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                      <div
                        data-qa="load-from-previous-notes"
                        className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPrevNotesPopover(false);
                          setShowPrevNoteModal(true);
                          close();
                        }}>
                        <Icon icon="new-file-download" color="primary" />
                        <span className="text-sm font-400 text-primary-900">
                          Load from previous notes
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <div
                      className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMacrosPopover(false);
                        close();
                      }}>
                      <Icon icon="new-attach-square" />
                      <span className="text-sm font-400 text-primary-900">Attach</span>
                    </div> */}
              <div
                data-qa="export"
                className="flex items-center gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                onClick={async (e) => {
                  e.stopPropagation();

                  if (showPreviewNoteLoading) {
                    return;
                  }

                  await showPreviewNote({
                    patientId: clinicalNote?.user_id || id,
                    appointmentId: clinicalNote?.appointment_id || appointmentId,
                    saveButtonText,
                    exportNote: true,
                    setClinicalNote,
                    setAdvancedHP,
                    setAdvancedSOAP,
                    setCustomFormTypes,
                    setPreviewNoteModal,
                    setIsExport,
                    setLoading: setShowPreviewNoteLoading,
                    navigate,
                    onSaveNote: saveNoteRef.current.onSaveNote()
                  });

                  setMacrosPopover(false);
                }}>
                <Icon icon="new-export-bulk" />
                <span className="text-sm font-400 text-primary-900">Export</span>
                {showPreviewNoteLoading && <Loader outlined />}
              </div>
              {/*   <div
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMacrosPopover(false);
                      close();
                    }}>
                    <Icon icon="new-e-fax" />
                    <span className="text-sm font-400 text-primary-900">eFax</span>
                  </div>
                  <div
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMacrosPopover(false);
                      close();
                    }}>
                    <Icon icon="new-mail" />
                    <span className="text-sm font-400 text-primary-900">Send as email</span>
                  </div>
                  <div
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMacrosPopover(false);
                      close();
                    }}>
                    <Icon icon="new-import" />
                    <span className="text-sm font-400 text-primary-900">Download as PDF</span>
                  </div> */}
            </Popover.Panel>
          </div>
        )}
      </Popover>
      {createMacroModal && (
        <CreateMacro
          macroTitle="Appointment Macro"
          macroType="appointment_macro"
          open={createMacroModal}
          handleClose={() => setCreateMacroModal(false)}
        />
      )}
      {loadMacrosModal && <LoadMacros type="appointment_macro" />}
      {showPrevNoteModal && <PreviousNotes />}
    </>
  );
};

export default LoadAndExport;
