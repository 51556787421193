import { useQueryClient } from '@tanstack/react-query';
import { createClaim as createClaimApi } from 'api/Billing';
import { getPatientSuperbills } from 'api/Superbill';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useFormik } from 'formik';
import { ia } from 'lib/helpers/utility';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getStates } from '../../../../api/State';
import { diagnosisPointers } from '../../../../constants';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { currentPractice } from '../../practiceState';
import ClaimErrors from './components/ClaimErrors';
import { FacilityInformation } from './components/FacilityInformation';
import PatientInfo from './components/PatientInfo';
import ServicesAndDiagnosis from './components/ServicesAndDiagnosis';
import SuperbillFooter from './components/SuperbillFooter';
import SuperbillHeaderActions from './components/SuperbillHeaderActions';
import {
  calculateTotalCharge,
  claimValidationSchema,
  getClaimFromSuperbillWhenNoClaim,
  getClaimInfoFromSuperbill,
  getInitialProcedure,
  getPlaceOfService,
  initialValues
} from './lib';

function SuperbillPage() {
  const navigate = useNavigate();
  const practice = useRecoilValue(currentPractice);
  const [superbill, setSuperbill] = useState();
  const [showErrors, setShowErrors] = useState(false);
  const [states, setStates] = useState();
  const queryClient = useQueryClient();
  const [procedures, setProcedures] = useState(getInitialProcedure(practice));
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState({ export: false });
  const exportRef = useRef();
  const { id, superbill: superbillId } = useParams();

  const formik = useFormik({
    initialValues: initialValues(id),
    validationSchema: claimValidationSchema,
    onSubmit: () => {
      setShowErrors(false);
      createClaim();
    }
  });
  const { values: claim, errors, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue('procedures', procedures);
    setFieldValue('total_charge', calculateTotalCharge(procedures));
  }, [procedures]);

  useEffect(() => {
    getStatesMethod().then((res) => {
      if (res) {
        getSuperbill();
      }
    });
  }, []);

  useEffect(() => {
    if (superbill) {
      if (superbill?.claim) {
        const claimFieldsToUpdated = getClaimInfoFromSuperbill({
          superbill,
          states
        });
        Object.entries(claimFieldsToUpdated).forEach(([fieldName, value]) => {
          setFieldValue(fieldName, value);
        });
        setProcedures(
          superbill.claim.procedures
            ? superbill.claim.procedures
            : [
              {
                code: '',
                modifiers: [],
                charge: 0,
                diagnosis: [],
                from_date: new Date(),
                thru_date: new Date(),
                narrative: '',
                units: '',
                place_of_service: getPlaceOfService(superbill, practice)
              }
            ]
        );
        setFieldValue('total_charge', calculateTotalCharge(superbill?.claim?.procedures || []));
      } else {
        const claimFieldsToUpdated = getClaimFromSuperbillWhenNoClaim({
          claim,
          superbill,
          states
        });

        Object.entries(claimFieldsToUpdated).forEach(([fieldName, value]) => {
          setFieldValue(fieldName, value);
        });
        let procedureArray = [];
        Array.isArray(superbill.encounter.cpt_codes) &&
          superbill?.encounter?.cpt_codes?.map((item) => {
            procedureArray.push({
              code: item.cpt || item?.code?.value,
              modifiers: item.modifiers ? item.modifiers.join(',') : [],
              charge: 0,
              diagnosis: ia(superbill.encounter.icd_10_codes)
                ? diagnosisPointers.slice(0, superbill.encounter.icd_10_codes.length).join('')
                : null,
              from_date: moment(superbill.encounter.appointment.starts_at).toDate(),
              thru_date: moment(superbill.encounter.appointment.starts_at).toDate(),
              narrative: '',
              units: '1',
              place_of_service: getPlaceOfService(superbill, practice)
            });
          });
        setFieldValue('total_charge', calculateTotalCharge(procedureArray));
        setProcedures(procedureArray);
      }
      setPatient(superbill.patient);
    }
  }, [superbill]);

  const createClaim = async () => {
    let params = {
      newClaim: {
        ...claim,
        procedures,
        state: 'ready'
      },
      superbillId: superbill.id
    };
    let res = await createClaimApi(navigate, params);
    if (res.code == 0) {
      showAlert({
        color: 'success',
        message: 'Superbill is ready for claim'
      });
      queryClient.resetQueries(['patient_superbill']);
      queryClient.resetQueries(['claims']);
      queryClient.resetQueries(['claim']);
      await getSuperbill();
    }
  };

  const getStatesMethod = async () => {
    let res = await getStates(navigate);
    setStates(res);
    return res;
  };

  const getSuperbill = async () => {
    let params = {
      id: superbillId
    };
    let res = await getPatientSuperbills(navigate, params);

    if (res.superbill) {
      setSuperbill(res.superbill);
    }
  };

  const handleSubmitClaim = async () => {
    setFieldValue('total_charge', calculateTotalCharge(procedures)).then(() => {
      if (Object.keys(errors).length) {
        setShowErrors(true);
        scrollToFooter();
      }
      handleSubmit();
    });
  };

  const scrollToFooter = () => {
    const footer = document.getElementById('superbill-page-footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth', block: 'end' });
      showAlert({
        title: 'Claim errors!',
        message:
          'Please scroll down to see the errors and correct them.',
        color: 'danger'
      });
    }
  };
  return (
    <div className="h-full w-full overflow-auto">
      {superbill ? (
        <div className="!px-4 !py-6 print:!bg-[#fff]" ref={exportRef}>
          <SuperbillHeaderActions {...{ exportRef, loading, setLoading, superbill }} />
          {claim?.state === 'rejected' && (
            <div className="grid gap-1 w-full">
              {claim?.errors?.map((error, index) => {
                return (
                  <AlertContent key={index} message={error.message} color='danger' />
                );
              })}
            </div>
          )}
          <PatientInfo
            {...{
              formik,
              states,
              patient,
              superbill
            }}
          />
          <FacilityInformation {...{ formik, states, superbill }} />
          <ServicesAndDiagnosis
            {...{
              formik,
              handleSubmitClaim,
              procedures,
              setProcedures,
              superbill,
              states
            }}
          />
          {showErrors && Object.keys(errors).length > 0 && <ClaimErrors errors={errors} />}
        </div>
      ) : (
        <div className="!rounded-lg bg-white p-12">
          <Skeleton count={2} height="290px" />
        </div>
      )}
      <SuperbillFooter />
    </div>
  );
}

export default withErrorBoundary(SuperbillPage);
