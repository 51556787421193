import { Honeybadger } from '@honeybadger-io/react';
import * as filestack from 'filestack-js';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { showAlert } from '../../shared/Alert/Alert';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../api/InterimApi';
import { getStates } from '../../../api/State';
import { genders, marital_status, races } from '../../../constants';
import { ia } from '../../../lib/helpers/utility';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import { propertyPathMap } from './constants';
import PersonalInformation from './personal_information';
import state from './state';
import { capitalize, pick } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const client = filestack.init(process.env.REACT_APP_FILESTACK);
const validator = require('validator');

const UpdatePatientValidationSchema = Yup.object().shape({
  email: Yup.string().email(), //.required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required' });
    }

    if (!validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number' });
    }

    return true;
  }),
  dob: Yup.date().required('Date of Birth required'),
  gender: Yup.string().required('Gender required'),
  state: Yup.string().required('State required'),
  address_ln_1: Yup.string().required('Address required'),
  city: Yup.string().required('City required'),
  zip: Yup.string().required('Zip code required')
});

const PersonalInformationContainer = () => {
  const { updateSteps, checkinButton, appointmentId, kiosk, user } = useOutletContext();
  const [patient, setPatient] = useState({});
  const [address, setAddress] = useState({});
  const [states, setStates] = useState([]);
  const [decodedSSN, setDecodedSSN] = useState(false);

  const [show, setShow] = useState({ optionSelected: false, modified: false });
  const selections = useRecoilValue(state.selectionState);
  const [allDataEditable, setAllDataEditable] = useState(false);
  const [editableData, setEditableData] = useState({ emailEdit: false, phoneEdit: false });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { device } = useUIContext();

  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      id: patient?.id,
      f_name: patient?.f_name || user?.f_name || '',
      m_name: patient?.m_name || user?.m_name || '',
      l_name: patient?.l_name || user?.l_name || '',
      dob: patient?.dob || user?.dob || '',
      gender: patient?.gender || user?.gender || '',
      ...(editableData?.emailEdit && { email: patient?.email || '' }),
      alt_email: patient?.alt_email || user?.alt_email || '',
      alt_email_notify: patient?.alt_email_notify || user?.alt_email_notify || false,
      ...(editableData?.phoneEdit && { phone: patient?.phone || '' }),
      alt_phone: patient?.alt_phone || user?.alt_phone || '',
      alt_phone_notify: patient?.alt_phone_notify || user?.alt_phone_notify || false,
      race: patient?.race || user?.race || '',
      ssn: patient?.ssn || '',
      marital_status: patient?.marital_status || user?.marital_status || '',
      // personal_id: patient?.personal_id || '',
      emergency_contact: {
        name: patient?.emergency_contact?.name || user?.emergency_contact?.name || '',
        phone: patient?.emergency_contact?.phone || user?.emergency_contact?.phone || '',
        relationship:
          patient?.emergency_contact?.relationship || user?.emergency_contact?.relationship || ''
      },
      profile_photo: patient?.profile_photo,
      address_ln_1: address?.address_ln_1 || user?.address_object?.address_ln_1 || '',
      address_ln_2: address?.address_ln_2 || user?.address_object?.address_ln_2 || '',
      city: address?.city || user?.address_object?.city || '',
      state: address?.state || user?.address_object?.state || '',
      zip: address?.zip || user?.address_object?.zip || ''
    },
    enableReinitialize: true,
    // validationSchema: UpdatePatientValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newPatient = {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          dob: values?.dob,
          gender: values?.gender,
          email: values?.email,
          alt_email: values?.alt_email,
          alt_email_notify: values?.alt_email_notify,
          ssn: values?.ssn,
          marital_status: values?.marital_status,
          phone: values?.phone,
          alt_phone: values?.alt_phone,
          alt_phone_notify: values?.alt_phone_notify,
          race: values?.race,
          // personal_id: values?.personal_id,
          emergency_contact: {
            name: values?.emergency_contact?.name,
            phone: values?.emergency_contact?.phone,
            relationship: values?.emergency_contact?.relationship
          }
        };
        const newAddress = {
          address_ln_1: values?.address_ln_1,
          address_ln_2: values?.address_ln_2,
          city: values?.city,
          state: values?.state,
          zip: values?.zip
        };

        if (values?.profile_photo !== patient?.profile_photo) {
          newPatient['profile_photo'] = { jpg: values?.profile_photo };
        }

        setSubmitting(true);
        await submitUpdate(newPatient, newAddress);

        setSubmitting(false);
      } catch (e) {
        console.error(e);
      }
    }
  });

  useEffect(() => {
    getStatesMethod();
    queryClient.invalidateQueries(['user']);
  }, []);

  useEffect(() => {
    if (appointmentId) {
      if (selections?.personal_info !== null) {
        if (!user.authenticated && !user.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
          // navigate('/login');
        }
        setShow((ps) => ({ ...ps, optionSelected: true }));
      }
      getPatient();
    }
  }, [appointmentId]);

  const getSecure = async () => {
    if (user.isOnboarding) {
      setDecodedSSN(true);
      return;
    }
    try {
      let res = await interimApi(
        '/api/user/get_secure',
        {
          id: patient?.id
        },
        navigate
      );
      const { ssn } = res.data;
      if (res.data) {
        formik.setValues({ ...formik.values, ssn });
        setDecodedSSN(true);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getPatient = async () => {
    try {
      let res = await interimApi(
        '/api/patient/checkin/get_patient',
        {
          appointmentId
        },
        navigate
      );
      const { patient: loadedPatient, all_editable, show: editableInfoShow } = res.data;
      setEditableData(editableInfoShow);
      setAllDataEditable(all_editable);
      if (loadedPatient) {
        setPatient(
          pick(loadedPatient, [
            'f_name',
            'l_name',
            'm_name',
            'p_name',
            'email',
            'alt_email',
            'alt_email_notify',
            'phone',
            'alt_phone',
            'alt_phone_notify',
            'gender',
            'dob',
            'race',
            'ssn',
            'marital_status',
            // 'personal_id',
            'profile_photo',
            'emergency_contact',
            ''
          ])
        );
        setAddress(loadedPatient.userAddress);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/insurance, method: getPatient - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getStatesMethod = async () => {
    try {
      let res = await getStates(navigate, { method: 'get', optionify: true });
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/insurance, method: getStates - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const keyChanges = (initial, altered) => {
    let changes = {};
    for (const key in altered) {
      if (altered[key] !== initial?.[key]) changes[key] = altered?.[key];
    }
    return changes;
  };

  const submitUpdate = async (updatedUser, updatedAddress) => {
    try {
      const patientChanges = keyChanges(patient, updatedUser);
      const addressChanges = keyChanges(address, updatedAddress);

      if (Object.keys(patientChanges).length === 0 && Object.keys(addressChanges).length === 0) {
        updateSteps({
          version: 2,
          formName: propertyPathMap.personalInfoComplete.name(),
          isCompleted: true,
          newStatus: 'completed'
        });
        return;
      }

      let res = await interimApi(
        '/api/user/update',
        {
          changes: patientChanges,
          addressChanges
        },
        navigate
      );
      if (res?.data?.code === 0) {
        const { holds, updated } = res.data;
        if (ia(holds)) {
          showAlert({
            color: 'warning',
            title: 'Updated with some errors',
            message: 'Your account has been updated, but some info may be invalid.',
            position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
          });
          let newErrors = {};
          holds.forEach((v) => {
            newErrors[v?.field] = capitalize(v?.message);
          });
          await formik.setErrors(newErrors);
        } else if (ia(updated)) {
          updateSteps({
            version: 2,
            formName: propertyPathMap.personalInfoComplete.name(),
            isCompleted: true,
            newStatus: 'completed'
          });
        }
      } else {
        console.error(res.data?.message);
        console.error(res.data?.error);
        Honeybadger.notify(
          `file: checkin/personal_information_container, method: submitUpdate - else, error: Theres been an error`
        );
        showAlert({
          message: 'There was an error updating your account',
          color: 'danger',
          position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
        });
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    if (kiosk) pickerOptions.fromSources = ['webcam'];
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    const handle = {
      jpg: data.filesUploaded[0].handle
    };
    let res = await interimApi(
      '/api/filestack/profile_photo',
      {
        handle,
        userId: patient.id
      },
      navigate
    );
    if (res.data.profilePhoto > 0) {
      formik.setFieldValue('profile_photo', handle.jpg);
    } else {
      Honeybadger.notify(
        `file: checkin/personal_information_container, method: saveImage - else, error: Theres been an error`
      );
      // setErrorUploading('There was an error uploading this image. Please try again.');
      showAlert({
        color: 'danger',
        title: 'Profile Photo Error',
        message: 'Uploading image failed. Please try again',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  // if (!show.optionSelected) {
  //   return <PatientCheckinEditingSelector />;
  // }

  return (
    <div>
      {states.length > 0 && (
        <PersonalInformation
          onSubmit={handleSubmit}
          checkinButton={checkinButton}
          formik={formik}
          formRef={formRef}
          genderList={genders}
          raceList={races}
          stateList={states}
          maritalStatusList={marital_status}
          getSSN={getSecure}
          openFilestackPicker={openFilestackPicker}
          decodedSSN={decodedSSN}
          setDecodedSSN={setDecodedSSN}
          allDataEditable={allDataEditable}
          editableData={editableData}
          kiosk={kiosk}
          showWarning={!user?.authenticated && !user?.isOnboarding}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(PersonalInformationContainer);
