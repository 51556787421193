import moment from 'moment';

export default function calculateDateDifference(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const daysDifference = end.diff(start, 'days');
  const monthsDifference = end.diff(start, 'months');
  const yearsDifference = end.diff(start, 'years');

  const remainingMonths = monthsDifference % 12;
  const remainingDays = daysDifference % 30;

  let result = '';

  if (yearsDifference > 0) {
    result += `${yearsDifference} year${yearsDifference != 1 ? 's' : ''}, `;
  }

  if (remainingMonths > 0) {
    result += `${remainingMonths} month${remainingMonths != 1 ? 's' : ''} & `;
  }

  result += `${remainingDays} day${remainingDays != 1 ? 's' : ''}`;

  return result;
}
