import React from 'react';
import Practices from '../Practices';

const PracticeDashboardPanel = (props) => {
  const SelectedComponent = Practices;

  return (
    <div className="p-4">
      <SelectedComponent {...props} />
    </div>
  );
};

export default PracticeDashboardPanel;
