import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import usePageTitle from '../../../lib/hooks/usePageTitle';
import SecondarySidebar from '../../Sidebars/SecondarySidebar/SecondarySidebar';
import { userState as user_state } from '../../state';
import { generateBeyondBillingList } from './lib/generateBeyondBillingList';

export const BeyondBilling = () => {
  usePageTitle('Beyond Billing');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userState = useRecoilValue(user_state);
  const beyondBillingLinks = generateBeyondBillingList(userState);

  useEffect(() => {
    if (pathname === '/portal/beyond-billing')
      navigate('/portal/beyond-billing/overview', { replace: true });
  }, [navigate, pathname]);

  return (
    <div className="flex h-full overflow-hidden bg-neutral-50">
      <SecondarySidebar list={beyondBillingLinks} target="beyondBilling" title="Reports & Queues" />
      <div className="flex w-full flex-col">
        <Outlet />
      </div>
    </div>
  );
};
export default BeyondBilling;
