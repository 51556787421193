export function generatePracticeIQueueList() {
  return {
    overview: {
      name: 'Overview',
      path: 'overview',
      icon: 'new-graph'
    },
    tasks: {
      name: 'Tasks',
      path: 'tasks',
      icon: 'new-task'
    },
    'clinical-notes': {
      name: 'Clinical Notes',
      path: 'clinical-notes',
      icon: 'new-notev2'
    },
    notifications: {
      name: 'Notifications',
      path: 'notifications',
      icon: 'new-notifications'
    },
    appointments: {
      name: 'Appointments',
      path: 'appointments',
      icon: 'new-calendar'
    },
    'form-packets': {
      name: 'Forms',
      path: 'forms',
      icon: 'new-calendar-note'
    }
  };
}
