import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Tabs from '../../../shared/Tabs/NewTabs';

const tabsData = [
  { label: 'Purchased Packages', path: '', end: true },
  { label: 'Quotes', path: 'quotes' },
  { label: 'Settings', path: 'settings' },
  { label: 'CC on file', path: 'cards' }
];

const Account = () => {
  const parentContext = useOutletContext();

  return (
    <div className="h-full bg-white">
      <Tabs tabsData={tabsData} theme="secondary" className="w-full" />
      <Outlet context={parentContext} />
    </div>
  );
};

export default Account;
