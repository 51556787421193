const { Honeybadger } = require('@honeybadger-io/react');
const { default: Axios } = require('../../../../../configuredAxios');
const { ia, camelToKebabKeys } = require('../../../../../lib/helpers/utility');
const { getMedications } = require('../../../../../api/Medication');

exports.getOverview = async ({ patientId, appointmentId, isKebab = false } = {}) => {
  if (!patientId || !appointmentId) return;

  try {
    const vitalsRes = await Axios.post('/api/clinical_note/vitals/read', {
      patientId,
      appointmentId
    });
    const customResponsesRes = await Axios.post('/api/custom_form_response/read', {
      patientId,
      appointmentId,
      type: [
        'allergies',
        'medicalHistory',
        'vaccines',
        'conditions',
        'familyHistory',
        'socialHistory',
        'surgicalHistory',
        'weightLossAdministration'
      ]
    });
    const { vitals } = vitalsRes.data;
    const lastVitals = ia(vitals) ? vitals[0] : {};
    delete lastVitals.created_by;
    delete lastVitals.updated_at;
    delete lastVitals.patient_id;
    delete lastVitals.practice_id;

    const desiredOrder = [
      'id',
      'heart_rate',
      'respirations',
      'blood_pressure_systolic',
      'blood_pressure_diastolic',
      'weight',
      'temperature',
      'height',
      'bmi',
      'oxygen_level',
      'head_circumference',
      'head_circumference_unit',
      'notes',
      'created_at'
    ];

    const orderedVitals = {};
    desiredOrder.forEach((key) => {
      if (lastVitals.hasOwnProperty(key)) {
        orderedVitals[key] = lastVitals[key];
      }
    });

    let { response } = customResponsesRes.data;

    const data = await getMedications(() => {}, { patientId, appointmentId });

    response = {
      ...response,
      medicationHistory: data.medicationHistory
    };

    const kebabResponse = camelToKebabKeys(response);

    return { ...(isKebab ? { ...kebabResponse } : { ...response }), vitals: orderedVitals };
  } catch (error) {
    console.error(error);
    Honeybadger.notify(
      `Clinical note get custom responses - patientId: ${patientId}, error: ${error}`
    );
  }
};
