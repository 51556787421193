import React, { useLayoutEffect, useMemo, useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Icon from 'components/shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import ListView from './components/ListView';
import ChartView from './components/ChartView';
import useChartSize from 'lib/hooks/useChartSize';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const ClinicalNotes = (props) => {
  const [listView, setListView] = useState(false);
  const [container, setContainer] = useState();
  const { overview, item, layout } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const { height, width, proportion } = useChartSize(container);
  const navigate = useNavigate();

  const handleListView = () => setListView(!listView);
  const chartData = [
    {
      name: 'Draft',
      value: overview?.clinical_notes?.status?.draft || 0,
      fill: '#FCA5A5'
    },
    {
      name: 'Not Started',
      value: overview?.clinical_notes?.status?.not_started || 0,
      fill: '#F87171'
    },
    {
      name: 'Completed',
      value: overview?.clinical_notes?.status?.finalized || 0,
      fill: '#4ADE80'
    },
    {
      name: 'Awaiting Co-Sign',
      value: overview?.clinical_notes?.status?.awaiting_cosign || 0,
      fill: '#33C9FF'
    }
  ];

  const options = {
    data: chartData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    series: [
      {
        data: chartData,
        type: 'donut',
        angleKey: 'value',
        fills: chartData.map((item) => item.fill),
        innerRadiusRatio: 0.7,
        innerLabels: [
          {
            text: `${overview?.clinical_notes?.count?.count}`,
            color: '#004F6B',
            fontSize: 19
          },
          { text: ' ', fontSize: 10 },
          { text: 'Clinical Notes', color: '#6D828B', fontSize: 12 }
        ],
        innerCircle: {
          fill: 'transparent'
        }
      }
    ]
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  useLayoutEffect(() => {
    const element = document.getElementsByClassName('react-grid-item')[widgetIndex];

    if (element && element !== container) {
      setContainer(element);
    }
  }, [widgetIndex, container]);

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader icon="new-notev2" hasPlus={false} {...props}>
        <div
          onClick={handleListView}
          className="cursor-pointer rounded-lg bg-primary-50 !px-1 !py-1">
          <Icon
            className="cursor-pointer"
            color="primary"
            icon={listView ? 'new-graph' : 'list-unordered'}
          />
        </div>
        <div
          onClick={() => {
            navigate(`../clinical-notes`);
          }}
          data-qa="clinical-note-redirect"
          className="cursor-pointer rounded-lg bg-neutral-100 !px-2 !py-2">
          <Icon className="cursor-pointer" icon="new-link"></Icon>
        </div>
      </WidgetHeader>

      {listView ? (
        <ListView
          options={options}
          data={overview?.clinical_notes?.status}
          total={overview?.clinical_notes?.count?.count}
        />
      ) : (
        <div className="flex w-full flex-1 items-center justify-between">
          <ChartView
            options={options}
            data={overview?.clinical_notes?.status}
            total={overview?.clinical_notes?.count?.count}
            chartKey={chartKey}
          />
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(ClinicalNotes);
