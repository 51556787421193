import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';

const FormsAI = ({ inputName, formsList }) => {
  const { components } = useClinicalNoteContext();

  const Component = formsList[inputName];
  return <Component components={components} /> || <></>;
};

export default FormsAI;
