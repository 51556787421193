import * as Yup from 'yup';
import { formatDate } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import Actions from '../components/Actions';
import { categories, priorities, statuses } from './constants';
import { getMembersList } from './getMembers';
import TableCheckbox from '../../TableCheckbox';
import TableHeaderCheckbox from '../../TableHeaderCheckbox';

const removeHtmlTags = (html) => {
  return html?.replace(/<[^>]*>/g, '');
};

const matchElement = (value, key) => {
  const element = key.find((elem) => elem.value === value);
  return element ? element.value : '';
};

const showLabel = (value, key) => {
  const element = key.find((elem) => elem.value === value);
  return element ? element.label : '';
};

const showUsersFormat = (value, kind) => {
  const taskUsers = value
    .filter((taskUser) => taskUser?.user_kind === kind)
    .map((users) => users?.user?.map((data) => `${data?.f_name} ${data?.l_name}`));
  return taskUsers;
};

export const TaskValidationSchema = Yup.object().shape({
  task: Yup.object().shape({
    title: Yup.string().required('Title is required'),
    status: Yup.string().required('Status is required'),
    category: Yup.string().required('A category is required')
  }),

  users: Yup.object().shape({
    requester: Yup.string().required('A requester is required'),
    owners: Yup.array().min(1, 'At least one owner is required')
  })
});

export const taskInitialValues = (initialTask) => ({
  task: {
    title: initialTask?.title || '',
    description: initialTask?.description || '',
    category: matchElement(initialTask?.category, categories) || '',
    priority: matchElement(initialTask?.priority, priorities) || null,
    status: matchElement(initialTask?.status, statuses) || 'requested',
    due_date: initialTask?.due_date || new Date(),
    data: initialTask?.data || null
  },
  users: {
    requester:
      initialTask?.users?.filter((user) => user.user_kind === 'requester')[0]?.user_id || '',
    owners:
      initialTask?.users
        ?.filter((user) => user.user_kind === 'owner')
        .map((owner) => owner.user_id) || [],
    followers:
      initialTask?.users
        ?.filter((user) => user.user_kind === 'follower')
        .map((follower) => follower.user_id) || []
  }
});

export const shapeTaskValues = (values) => ({
  task: {
    title: values?.task?.title,
    description: removeHtmlTags(values?.task?.description),
    category: values?.task?.category,
    priority: values?.task?.priority,
    status: values?.task?.status,
    due_date: values?.task?.due_date,
    data: values?.task?.data
  },
  users: {
    requester: values?.users?.requester,
    owners: values?.users?.owners,
    followers: values?.users?.followers
  }
});

export const defaultColumns = [
  {
    field: 'checkbox',
    headerName: '',
    cellRenderer: TableCheckbox,
    headerComponent: TableHeaderCheckbox,
    maxWidth: 60,
    cellClass: 'no-border',
    resizable: false,
    sortable: false
  },
  {
    field: 'title',
    headerName: 'Title',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'description',
    headerName: 'Description',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'category',
    headerName: 'Category',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => showLabel(value, categories)
  },
  {
    field: 'priority',
    headerName: 'Priority',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => showLabel(value, priorities)
  },
  {
    field: 'users',
    headerName: 'Requester',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) => showUsersFormat(value, 'requester')
  },
  {
    field: 'users',
    headerName: 'Owners',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) => showUsersFormat(value, 'owner')
  },
  {
    field: 'users',
    headerName: 'Followers',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) => showUsersFormat(value, 'follower')
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'due_date',
    headerName: 'Due Date',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'actions',
    headerName: '',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
export const DEFAULT_COLUMN_DEFS = { resizable: true, enableRowGroup: true };

export const DEFAULT_FILTERS = {
  due_date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Due Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  title: {
    type: 'search',
    values: null,
    title: 'Task name',
    placeholder: 'Search name'
  },
  description: {
    type: 'search',
    values: null,
    title: 'Task description',
    placeholder: 'Search description'
  },
  requesters: {
    type: 'search',
    multiple: true,
    loadedOptions: getMembersList,
    values: [],
    title: 'Requesters',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search requester'
  },
  owners: {
    type: 'search',
    multiple: true,
    loadedOptions: getMembersList,
    values: [],
    title: 'Owners',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search owners'
  },
  followers: {
    type: 'search',
    multiple: true,
    loadedOptions: getMembersList,
    values: [],
    title: 'Followers',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search followers'
  },
  status: {
    type: 'checkbox',
    values: null,
    options: statuses,
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  },
  priority: {
    type: 'checkbox',
    values: null,
    options: priorities,
    title: 'Priority',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  }
};

export const getChangedTaskFields = (initialTask, shapedTaskObject) => {
  const changedFields = {};
  Object.keys(shapedTaskObject).forEach((key) => {
    if (initialTask[key] !== shapedTaskObject[key]) {
      changedFields[key] = shapedTaskObject[key];
    }
  });
  return changedFields;
};

export const getChangedValues = (initialValues, currentValues) => {
  let changedValues = [];
  for (let i = 0; i < initialValues.length; i++) {
    const initialValue = initialValues[i];

    if (!currentValues.includes(initialValue)) {
      changedValues.push(initialValue);
    }
  }

  return changedValues;
};

export const changedRequester = (initialValue, currentValue) => {
  if (initialValue !== currentValue) {
    return currentValue;
  }
  return null;
};
