import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import FamilyHistory from '../../../../../../../shared/Forms/Custom/FamilyHistory/FamilyHistory';

const FamilyHistorySection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData
}) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      updateFormType={{
        name: 'Family History',
        parentKey: 'hp',
        childKey: 'familyHistory'
      }}
      title={components?.familyHistory?.title}
      id={components?.familyHistory?.id}
      disabled={clinicalNote?.locked}>
      <FamilyHistory
        fromClinicalNote={true}
        formId={formId}
        defaultValue={defaultValue}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
        showNarrative
        setCurrentFormData={setCurrentFormData}
      />
    </Accordion>
  );
};

export default FamilyHistorySection;
