import { insurance_type } from '../../../../../../constants';
import { ia, iaRa } from '../../../../../../lib/helpers/utility';
import { routine_options } from './handleFormik';

export const testDefaultObject = {
  id: self.crypto.randomUUID(),
  test_order_code: null,
  test_cpt: null,
  test_name: null,
  test_ref_lab: null,
  test_status: null,
  test_rbo_result: null,
  qa: [{ id: self.crypto.randomUUID(), question: null, answer: null }],
  sample_type: null,
  container: null,
  draw_req: null,
  transport_mode: null,
  transport_group: null
};

export const patientMapper = ({ patient }) => {
  return {
    pat_id: patient?.id ?? '',
    pat_l_name: patient?.l_name ?? '',
    pat_f_name: patient?.f_name ?? '',
    pat_mi: patient?.middle_name ?? '',
    pat_sex: patient?.gender ?? '',
    pat_dob: patient?.dob ?? '',
    pat_ssn: patient?.ssn ?? '',
    pat_addr: patient?.userAddress?.fullAddress ?? '',
    pat_city: patient?.city ?? '',
    pat_st: patient?.state ?? '',
    pat_zip: patient?.zip ?? '',
    pat_home_phone: patient?.phone ?? '',
    pat_work_phone: patient?.phone ?? ''
  };
};

export const insuranceAndGuarantorMapper = ({ insuranceProfile }) => {
  const insurance = findInsuranceByImportance({ insuranceProfiles: insuranceProfile });

  return {
    ins_code: insurance?.payer_id ?? '',
    ins_name: insurance?.payer_name ?? '',
    ins_group_num: insurance?.group_number ?? '',
    ins_group_name: insurance?.plan_name ?? '',
    ins_plan_eff_dt: insurance?.policy_start_date ?? '',
    ins_plan_exp_dt: insurance?.policy_end_date ?? '',
    ins_plan_type: insurance?.type ?? '',
    ins_policy_num: insurance?.member_id ?? '',
    ins_subscriber_last: insurance?.other_relation_name ?? '',
    ins_subscriber_first: insurance?.other_relation_name ?? '',
    ins_subscriber_middle_init: insurance?.other_relation_name ?? '',
    ins_relationship: insurance?.relation ?? '',
    ins_subscriber_dob: insurance?.other_relation_date ?? '',
    ins_subscriber_addr: insurance?.relation_address ?? '',
    ins_bill_to: insurance?.other_relation_name ?? '',
    ...guarantorMapper({ insuranceProfile })
  };
};

export const findInsuranceByImportance = ({ insuranceProfiles }) => {
  if (!ia(insuranceProfiles)) return [];

  let foundInsurance;

  for (let type of insurance_type) {
    foundInsurance = insuranceProfiles.find((profile) => profile.type === type.value);
    if (foundInsurance) break;
  }

  return foundInsurance;
};

export const guarantorMapper = ({ insuranceProfile }) => {
  return {
    guarantor_last_name: insuranceProfile?.other_relation_name ?? '',
    guarantor_first_name: insuranceProfile?.other_relation_name ?? '',
    guarantor_dob: insuranceProfile?.other_relation_date ?? '',
    guarantor_relationship: insuranceProfile?.relation ?? '',
    guarantor_addr: insuranceProfile?.relation_address ?? ''
    // guarantor_city: insuranceProfile?.relation_address ?? '',
    // guarantor_st: insuranceProfile?.relation_address ?? '',
    // guarantor_zip: insuranceProfile?.relation_address ?? '',
    // guarantor_home_phone: insuranceProfile?.other_relation_phone ?? '',
    // guarantor_work_phone: insuranceProfile?.other_relation_phone ?? '',
    // guarantor_ssn: insuranceProfile?.other_relation_ssn ?? '',
    // guarantor_sex: ...
  };
};

export const orderMapper = ({ currentUserInfo, practice }) => {
  return {
    order_phys_last: currentUserInfo?.l_name ?? '',
    order_phys_first: currentUserInfo?.f_name ?? '',
    order_phys_middle: currentUserInfo?.m_name ?? '',
    order_phys_suffix: currentUserInfo?.suffix ?? '',
    order_location: practice?.address,
    order_accno: '',
    order_collector: '',
    order_rcv_dt: '',
    order_sample_type: '',
    order_comments: ''
  };
};

export const orderPhysicianMapper = ({ currentUserInfo }) => {
  return {
    order_physician_upin: currentUserInfo?.upin ?? '',
    order_physician_last_name: currentUserInfo?.l_name ?? '',
    order_physician_first_name: currentUserInfo?.f_name ?? '',
    order_physician_middle_name: currentUserInfo?.m_name ?? '',
    order_physician_suffix: currentUserInfo?.suffix ?? '',
    order_physician_npi: currentUserInfo?.npi ?? ''
  };
};

export const findLabStatus = ({ value }) =>
  iaRa(routine_options).find((option) => option.value === value)?.label;

export const insuranceKeys = [
  'ins_addr',
  'ins_bill_to',
  'ins_city',
  'ins_code',
  'ins_contact_first_name',
  'ins_contact_last_name',
  'ins_contact_phone',
  'ins_group_name',
  'ins_group_num',
  'ins_name',
  'ins_plan_eff_dt',
  'ins_plan_exp_dt',
  'ins_plan_type',
  'ins_policy_num',
  'ins_relationship',
  'ins_st',
  'ins_subscriber_addr',
  'ins_subscriber_city',
  'ins_subscriber_dob',
  'ins_subscriber_first',
  'ins_subscriber_last',
  'ins_subscriber_middle_init',
  'ins_subscriber_st',
  'ins_subscriber_zip',
  'ins_wc_flag',
  'ins_zip'
];
