import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import ICDCodes from '../../SOAPNote/components/ICDCodes';

const ICDCodesSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.diagnosisCodes?.title}
      id={components?.diagnosisCodes?.id}
      disabled={clinicalNote?.locked}>
      <ICDCodes sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ICDCodesSection;
