import { getPatients } from 'api/Patients';
import { getServices } from 'api/Service';
import cs from 'classnames';
import { currentPractice as current_practice } from 'components/practice/practiceState';
import Filter from 'components/shared/Filters/Filter';
import {
  appointmentConfirmState,
  appointmentStatus,
  claimState,
  clinicalNoteStatus,
  superbillStatus
} from 'constants';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { formatDate, objMap, optionify, reShapePractitioners } from 'lib/helpers/utility';
import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

export const searchPatients = async (searchTerm) => {
  const patients = await getPatients(() => {}, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    customLabel: (
      <div>
        {p?.fullName && <span>{p.fullName}</span>}
        {p.dob && <span className="pl-1 text-xs text-neutral-500">{formatDate(p.dob)}</span>}
      </div>
    ),
    label: p?.fullName,
    value: p?.id
  }));
};

export const searchServices = async (searchTerm) => {
  const services = await getServices(() => {}, {
    limit: 25,
    filters: { searchTerm },
    forPractice: true,
    offset: 0
  });

  return services.services.map((p) => ({
    label: p?.name,
    value: p?.id
  }));
};
export const apptDefaultFilters = ({
  searchPatients,
  loadPractitioners,
  loadResources,
  searchServices,
  currentPractice,
  tags
}) => ({
  patient: {
    type: 'search',
    multiple: true,
    loadOptions: (e) => searchPatients(e),
    values: [],
    title: 'Patients',
    placeholder: 'Search patients'
  },
  practitioners: {
    type: 'search',
    multiple: true,
    options: reShapePractitioners(loadPractitioners?.practitioners),
    values: [],
    title: 'Providers',
    placeholder: 'Select providers',
    icon: false
  },
  resources: {
    type: 'search',
    multiple: true,
    options: loadResources,
    values: [],
    title: 'Resources',
    placeholder: 'Select resources',
    icon: false
  },
  serviceType: {
    type: 'search',
    multiple: true,
    loadOptions: (e) => searchServices(e),
    values: [],
    title: 'Service',
    placeholder: 'Search services'
  },
  appointmentType: {
    type: 'search',
    multiple: true,
    values: [],
    options: [
      ...(currentPractice?.appointment_types || []),
      { label: 'No type', value: 'no_type' }
    ],
    title: 'Type',
    placeholder: 'Select appointment type',
    icon: false
  },
  appointmentTags: {
    type: 'search',
    multiple: true,
    options: tags,
    values: [],
    title: 'Tags',
    placeholder: 'Select appointment tags',
    icon: false
  },
  appointmentStatus: {
    type: 'checkbox',
    values: null,
    options: appointmentStatus,
    title: 'Status'
  },
  appointmentConfirmState: {
    type: 'checkbox',
    values: null,
    options: appointmentConfirmState,
    title: 'State'
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: null,
    options: clinicalNoteStatus,
    title: 'Clinical note status'
  },
  superbillStatus: {
    type: 'checkbox',
    values: null,
    options: superbillStatus,
    title: 'Superbill'
  },
  claimState: {
    type: 'checkbox',
    values: null,
    options: claimState,
    title: 'Claim'
  }
});

const Filters = ({ buttonClassNames /* todo: remove this prop */ }) => {
  const { data: loadPractitioners } = usePractitioners();
  const { data: resourceData } = useResourcesAsOptions();
  const { data: payers } = usePayers({
    options: { select: (response) => optionify(response.payers, 'name', 'id') }
  });
  const currentPractice = useRecoilValue(current_practice);
  const { data: loadTags } = useTags({ params: { kind: 'appointment' } });
  const { device } = useUIContext();
  const { filters, setFilters } = useDashboardContext();

  useEffect(() => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      const defaultFilters = apptDefaultFilters({
        currentPractice,
        loadPractitioners,
        loadResources: resourceData?.resources,
        searchPatients,
        searchServices,
        tags: loadTags?.tags
      });
      return objMap(newFilters, (key, f) => ({ ...f, options: defaultFilters[key].options }));
    });
  }, [loadPractitioners?.practitioners, payers, loadTags?.tags, resourceData?.resources]);

  return (
    <Filter
      category="dashboard_calendar"
      filters={filters}
      defaultFilters={apptDefaultFilters({
        currentPractice,
        loadPractitioners,
        loadResources: resourceData?.resources,
        searchPatients,
        searchServices
      })}
      setFilters={setFilters}
      saveButton
      btnClassName={cs(
        'text-primary-900 bg-white !border-transparent leading-[24px] !py-1',
        buttonClassNames
      )}
      modal={device === 'desktop' ? false : true}
      width={630}
      position="left top"
      contentStyle={{ maxHeight: '80%' }}
    />
  );
};

export default Filters;
