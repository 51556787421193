import { useMutation } from '@tanstack/react-query';
import { upsertMyScribe } from 'api/MyScribe';
import { showAlert } from 'components/shared/Alert/Alert';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from 'lib/helpers/utility';
import { useMyScribe } from 'lib/hooks/queries/myscribe/useMyScribe';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AmbientListeningContext } from './AmbientListeningContext';

export const AmbientListeningContextProvider = ({ children }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const { id: patientId, appointmentId } = useParams();
  const navigate = useNavigate();
  const actionRef = useRef(null);

  const [currentState, setCurrentState] = useState(false);
  const [recording, setRecording] = useState(false);
  const [transcriptData, setTranscriptData] = useState({});
  const [editTranscriptData, setEditTranscriptData] = useState(false);
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentAppointmentId = appointmentId ?? clinicalNote?.appointment_id;

  const { data: currentMyScribe, isFetching } = useMyScribe({
    params: { appointmentId: currentAppointmentId },
    dependencies: [currentAppointmentId],
    options: {
      enabled: !!currentAppointmentId,
      select: (data) => {
        return data?.data?.myscribe;
      }
    }
  });

  useEffect(() => {
    setTranscriptData(iaRa(currentMyScribe));
  }, [currentMyScribe]);

  const upsertMyScribeMutation = useMutation((data) => upsertMyScribe(navigate, data), {
    onSuccess: () => {
      showAlert({
        title: 'Transcript',
        message: 'Transcript saved successfully',
        color: 'success'
      });

      navigate(
        `/portal/charts/${patientId}/clinical-notes/${appointmentId}/myscribe/transcription`
      );
      setShowTranscriptModal(false);
    },
    onSettled: (data) => {
      if (data?.data?.myscribe) {
        setTranscriptData(data?.data?.myscribe);
      }
    }
  });
  const isUpsertProcessLoading = upsertMyScribeMutation?.isLoading;

  const updateSummary = ({ fromSummary, index, data }) => {
    if (fromSummary || index === 0) {
      return transcriptData[0]?.summary;
    }

    return data?.summary;
  };

  const upsertMyscribeTranscript = async ({ fromSummary = false } = {}) => {
    const upsertData = transcriptData?.map((data, index) => {
      return {
        ...(!!data?.id && { ...data }),
        appointment_id: appointmentId,
        patient_id: patientId,
        temp_id: data?.temp_id,
        transcript_plain: data?.plain,
        transcript_enhanced: data?.transcript_enhanced,
        summary: updateSummary({ fromSummary, index, data }),
        general_confidence: data?.general_confidence,
        speakers: data?.speakers
      };
    });

    await upsertMyScribeMutation.mutateAsync({ upsertData });
  };

  useImperativeHandle(actionRef, () => ({
    upsert: upsertMyscribeTranscript
  }));

  return (
    <AmbientListeningContext.Provider
      value={{
        currentMyScribe,
        isFetching,
        currentState,
        setCurrentState,
        recording,
        setRecording,
        transcriptData,
        setTranscriptData,
        editTranscriptData,
        setEditTranscriptData,
        showTranscriptModal,
        setShowTranscriptModal,
        isUpsertProcessLoading,
        actionRef,
        loading,
        setLoading
      }}>
      {children}
    </AmbientListeningContext.Provider>
  );
};
