module.exports = {
  appointment_statuses: [
    { label: 'Unconfirmed', value: 'unconfirmed' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Patient Forms Not Complete', value: 'patient_forms_not_complete' },
    { label: 'Patient Forms Partially Complete', value: 'patient_forms_partially_complete' },
    { label: 'Patient Forms Complete', value: 'patient_forms_complete' },
    { label: 'Not Arrived', value: 'not_arrived' },
    { label: 'Arrived', value: 'arrived' },
    { label: 'Reminders Not Sent', value: 'reminders_not_sent' },
    { label: 'Reminders Sent', value: 'reminders_sent' },
    { label: 'No Insurance', value: 'no_insurance' },
    { label: 'Insurance On File', value: 'insurance_on_file' },
    { label: 'Benefits Not Verified', value: 'benefits_not_verified' },
    { label: 'Benefits Verified', value: 'benefits_verified' },
    { label: 'Insurance Prior Auth Not Required', value: 'insurance_prior_auth_not_required' },
    { label: 'Insurance Prior Auth Required', value: 'insurance_prior_auth_required' },
    { label: 'Insurance Prior Auth Requested', value: 'insurance_prior_auth_requested' },
    { label: 'Insurance Prior Auth Approved', value: 'insurance_prior_auth_approved' },
    { label: 'Pre-Auth Not Signed', value: 'pre_auth_not_signed' },
    { label: 'Pre-Auth Signed', value: 'pre_auth_signed' },
    { label: 'Patient Portion Not Paid', value: 'patient_portion_not_paid' },
    { label: 'Patient Portion Partially Paid', value: 'patient_portion_partially_paid' },
    { label: 'Patient Portion Fully Paid', value: 'patient_portion_fully_paid' },
    { label: 'With Nurse', value: 'with_nurse' },
    { label: 'With Provider', value: 'with_provider' },
    { label: 'Clinical Note Not Started', value: 'clinical_note_not_started' },
    { label: 'Clinical Note Started', value: 'clinical_note_started' },
    { label: 'Clinical Note Awaiting Co-Sign', value: 'clinical_note_awaiting_co_sign' },
    { label: 'Clinical Note Signed & Locked', value: 'clinical_note_signed_and_locked' },
    { label: 'Treatment Plan Not Started', value: 'treatment_plan_not_started' },
    { label: 'Treatment Plan Started', value: 'treatment_plan_started' },
    { label: 'Superbill Not Created', value: 'superbill_not_created' },
    { label: 'Superbill Created', value: 'superbill_created' },
    { label: 'Superbill Scrubbed With Errors', value: 'superbill_scrubbed_with_errors' },
    { label: 'Superbill Scrubbed Successfully', value: 'superbill_scrubbed_successfully' },
    { label: 'Claim Not Created', value: 'claim_not_created' },
    { label: 'Claim Ready', value: 'claim_ready' },
    { label: 'Claim Acknowledged', value: 'claim_acknowledged' },
    { label: 'Claim Denied', value: 'claim_denied' },
    { label: 'Claim Paid', value: 'claim_paid' },
    { label: 'Claim Rejected', value: 'claim_rejected' },
    { label: 'Claim Pending', value: 'claim_pending' }
  ]
};
