import React, { useState } from 'react';
import Input from '../../shared/Input/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../shared/Buttons/Button';
import Icon from '../../shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import IdleWrapper from '../components/IdleWrapper';
import RequestAssistance from '../components/RequestAssistance';
import { requestApi } from 'api/Api';
import { useKioskContext } from 'lib/context/KioskContext/KioskContext';
import Modal from 'components/shared/Modal/Modal';
import VerificationCode from 'components/shared/VerificationCode/VerificationCode';
import { showAlert } from 'components/shared/Alert/Alert';

const KioskPhone = () => {
  const [confirmModal, setConfirmModal] = useState();
  const [status, setStatus] = useState('pending');
  const [loading, setLoading] = useState(false);

  const { userIds, mobile } = useKioskContext();

  const formik = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: Yup.object({
      phone: Yup.string().required('Phone number is required')
    }),
    onSubmit: (values) => {
      const onSuccess = (data, res) => {
        setConfirmModal(data.mfaId);
      };
      requestApi({
        url: mobile ? '/api/kiosk/mobile/mfa' : '/api/kiosk/mfa',
        params: {
          phone: values.phone,
          userIds
        },
        onSuccess,
        onError: () => showAlert({ title: 'Wrong phone number!', color: 'danger' }),
        navigate,
        filePath: `${__dirname}/${__filename}`
      });
    }
  });

  const onComplete = async (code) => {
    const onSuccess = (data, res) => {
      const userId = data.userId;
      setStatus('success');
      setTimeout(async () => {
        setLoading(true);
        const onSuccess = async (data, res) => {
          !mobile &&
            (await requestApi({
              url: '/api/kiosk/command',
              params: {
                url: res?.redirectTo,
                command: 'url-change',
                page: 'checkin',
                kioskId: res?.kioskId,
                patientId: res?.patientId
              },
              navigate
            }));
          navigate(res?.redirectTo);
          setLoading(false);
        };
        const onError = (error, code) => {
          const errors = {
            2: "Couldn't find appointment",
            3: "Couldn't find user"
          };
          showAlert({ title: errors[code], color: 'danger' });
          setLoading(false);
          setStatus('pending');
        };

        await requestApi({
          url: mobile ? '/api/kiosk/mobile/checkin' : '/api/kiosk/checkin',
          onSuccess,
          onError,
          params: { userId },
          navigate,
          filePath: `${__dirname}/${__filename}`
        });
      }, [500]);
    };
    await requestApi({
      url: mobile ? '/api/kiosk/mobile/mfa' : '/api/kiosk/mfa',
      params: {
        code,
        mfaId: confirmModal
      },
      onSuccess,
      onError: () => setStatus('error'),
      navigate,
      filePath: `${__dirname}/${__filename}`
    });
  };

  const navigate = useNavigate();

  return (
    <IdleWrapper>
      <div className="rounded-t-2xl bg-primary-50 !px-8 !py-3 xs:!px-4">
        <div className="flex items-center justify-between gap-4">
          <p className="text-lg font-600 leading-7 text-primary-900">Enter phone number</p>
          <div
            className="ml-auto flex cursor-pointer select-none gap-1"
            onClick={() => navigate('../idle')}>
            <Icon
              size={20}
              stroke
              color="primary"
              shade={900}
              icon="new-arrow-left"
              className="cursor-pointer"
            />
            <p className="text-base font-600 leading-7 text-primary-900">Go back</p>
          </div>
        </div>
        <p className="font-regular mt-4 text-base text-neutral-600">
          We need to confirm your phone number to start checkin
        </p>
      </div>
      <div className="!px-8 !py-4 xs:!px-4 xs:!pb-3 xs:!pt-2">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(140px,1fr))] gap-4 xs:flex xs:flex-col xs:gap-3">
          <Input
            label="Enter phone number"
            name="phone"
            inputWrapperClassName="!h-[46px] sm:!h-[40px]"
            labelClassName="text-primary-900"
            placeholder="(123) - 456 - 7890"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone}
          />
        </div>
        <Button
          size="large"
          text="Continue"
          className="!mt-4 w-full"
          onClick={() => formik.submitForm()}
        />
        <div className="!mt-4 flex select-none flex-wrap items-center justify-between gap-x-6 gap-y-2">
          <RequestAssistance />
          <div className="ml-auto flex items-center gap-x-[6px]">
            <p className="text-xs font-400 text-primary-500">Powered by</p>
            <Icon icon="logo-without-symbol" />
          </div>
        </div>
      </div>

      <Modal
        title="Confirm your phone number"
        isOpen={!!confirmModal}
        handleClose={() => setConfirmModal(null)}>
        <div className="!p-4">
          <p className="!mb-2">Enter the code sent to {formik.values.phone}</p>
          <VerificationCode
            status={status}
            setStatus={setStatus}
            onComplete={onComplete}></VerificationCode>
        </div>
      </Modal>
    </IdleWrapper>
  );
};

export default KioskPhone;
