import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from 'lib/helpers/utility';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const MedicalHistory = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !currentHpOverviewData?.medicalHistory?.id) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Medical history</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.medicalHistory]?.map((medicalHistory, idx) => {
          const item = medicalHistory?.response;
          if (
            (!item?.allergies?.allergy &&
              !item?.allergies?.allergy &&
              !item?.chronic?.illness &&
              !item?.chronic?.note &&
              !item?.surgeries?.surgery) ||
            medicalHistory?.response?.noDetails
          )
            return null;

          return (
            <div
              className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
              key={idx}>
              {io(item?.chronic) && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Chronic</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.chronic?.illness}
                  </p>
                  {item?.chronic?.note && (
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {item?.chronic?.note}
                    </p>
                  )}
                </div>
              )}
              {io(item?.surgeries) && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Surgeries</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.surgeries?.surgery}
                  </p>
                  {item?.surgeries?.note && (
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {item?.surgeries?.note}
                    </p>
                  )}
                </div>
              )}
              {io(item?.allergies) && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Allergies</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.allergies?.allergy}
                  </p>
                  {item?.allergies?.note && (
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {item?.allergies?.note}
                    </p>
                  )}
                </div>
              )}
              {io(item?.hospitalization) && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Hospitalization
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.hospitalization?.illnessOrInjury}
                  </p>
                  {item?.hospitalization?.note && (
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {item?.hospitalization?.note}
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}

      {isNarrativeChecked && currentHpOverviewData?.medicalHistory?.id && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.medicalHistory]}
            noDetailsText="No notable medical history"
          />
        </div>
      )}
    </div>
  );
};

export default MedicalHistory;
