import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import Icon from 'components/shared/Icon/Icon';
import mapMyScribeTemplate from '../../../../../../assets/Images/myscribe-template.png';
import { userState } from 'components/state';
import { useRecoilValue } from 'recoil';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

const CARDS = [
  {
    id: 'map-my-own-template',
    title: 'Map My Own Templates',
    description: 'Write or transcribe my note in separate templates to map my note typing category',
    image: mapMyScribeTemplate,
    link: '/map-my-own-template',
    favorite: false
  },
  {
    id: 'map-template-with-myscribe',
    title: 'Map Templates with MyScribe',
    description: 'Write or transcribe my note in separate templates to map my note typing category',
    image: mapMyScribeTemplate,
    link: '/map-template-with-myscribe'
  },
  {
    id: 'ambient-listening',
    title: 'Ambient Listening',
    description: 'Write or transcribe my note in separate templates to map my note typing category',
    image: mapMyScribeTemplate,
    link: '/ambient-listening'
  },
  {
    id: 'enhance-current-note',
    title: 'Enhance Current Note',
    description: 'Write or transcribe my note in separate templates to map my note typing category',
    image: mapMyScribeTemplate,
    link: '/enhance-current-note'
  }
];

function Card({ card, isFavorite = false }) {
  const { breadcrumb, setFavoriteTemplateId } = useMyScribeAIContext();
  const { pushBreadcrumb } = breadcrumb;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const onCardClick = () => {
    // pushBreadcrumb({
    //   label: card.title,
    //   name: card.link,
    //   path: `${pathname}${card.link}`
    // });

    navigate(`${pathname}${card.link}`);
  };

  const onFavoriteClick = (id) => {
    setFavoriteTemplateId(id);
  };

  return (
    <div
      className="relative flex h-[300px] w-[300px] transform cursor-pointer flex-col rounded-lg bg-white p-5 shadow-md transition-transform hover:scale-105"
      onClick={onCardClick}>
      <div
        className="absolute right-4 top-4 cursor-pointer rounded p-2 hover:bg-[#ECEFF0]"
        onClick={(e) => {
          e.stopPropagation();
          onFavoriteClick(card.id);
        }}
        ref={ref}>
        <Icon icon={isFavorite ? 'filled-fav-star' : 'fav-star'} />
      </div>
      <Tippy
        className="tippy-dark !rounded-md"
        reference={ref}
        content="Set as my default selection"
        allowHTML
        interactive
        appendTo={() => document.body}
        placement="bottom"
      />
      <img
        src={card.image}
        alt={card.title}
        className="h-1/2 w-full rounded-t-lg object-contain "
      />
      <div className="flex flex-col p-4">
        <h3 className="text-lg font-semibold">{card.title}</h3>
        <p className="mt-2 text-gray-600">{card.description}</p>
      </div>
    </div>
  );
}

const Welcome = () => {
  const [cards] = useState(CARDS);
  const { favoriteTemplateId } = useMyScribeAIContext();
  const currentUser = useRecoilValue(userState);

  return (
    <>
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 bg-gradient-to-r p-4 pb-0">
        <h1 className="mb-4 text-[36px] font-bold text-sky-400">Hello, {currentUser?.f_name}</h1>
        <h2 className="mb-8 text-xl text-[#8FA0A7]">How can I help you today?</h2>

        <div className="mb-3 flex max-w-6xl flex-wrap justify-center gap-3">
          {cards.map((card) => (
            <Card key={card.id} card={card} isFavorite={card.id === favoriteTemplateId} />
          ))}
        </div>
        <div className="flex items-center justify-center">
          <Icon icon="hippa-secure" />
        </div>
      </div>
    </>
  );
};

export default Welcome;
