import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useUIContext } from '../../../../../lib/context/UIContext/UIContext';
import { ia } from '../../../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import ArrowToggle from '../shared/ArrowToggle';
import GeneralOverview from './GeneralOverview/GeneralOverview';
import Macros from './Macros/Macros';
import MyScribeAISummary from './MyScribeAISummary/MyScribeAISummary';
import PatientResponse from './PatientResponse/PatientResponse';
import PreviousNotes from './PreviousNotes/PreviousNotes';
import SidebarPopover from './SidebarPopover';
import TreatmentPlan from './TreatmentPlan/TreatmentPlan';

const OPTIONS = [
  { component: 'patient_general_overview', title: 'Patient History' },
  { component: 'macros', title: 'Macros' },
  { component: 'patient_response', title: 'Patient Response' },
  { component: 'previous_note_preview', title: 'Previous Note Preview' },
  { component: 'myscribe_ai_narrative', title: 'MyScribe AI Narrative', note: 'myscribe' }
];

const Sidebar = ({ handleSidebar }) => {
  const {
    patientResponseSidebar,
    setPatientResponseSidebar,
    cnDisplaySettings,
    setCNDisplaySettings
  } = useClinicalNoteContext();
  const [selectedView, setSelectedView] = useState(OPTIONS[0]);
  const { openSidebar, setOpenSidebar } = handleSidebar;
  const { id, type } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const { device } = useUIContext();
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (!openSidebar) {
      timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 200);
    } else {
      setShouldRender(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openSidebar]);

  useEffect(() => {
    openSideBarBasedOnType();
  }, [type]);

  const openSideBarBasedOnType = () => {
    if (!type) return;

    const hasWantedView = OPTIONS?.some(({ note }) => note === type);

    if (hasWantedView) {
      setSelectedView(OPTIONS.find(({ note }) => note === type));
      setOpenSidebar(true);
    }
  };

  const treatmentPlanExists = OPTIONS.some((option) => option.component === 'treatment_plan');
  if (ia(treatmentPlan) && !treatmentPlanExists) {
    OPTIONS.splice(2, 0, { component: 'treatment_plan', title: 'Treatment Plan' });
  }

  const showPatientResponse = () => {
    if (openSidebar && selectedView.component === 'patient_response') {
      setPatientResponseSidebar((prevState) => ({ ...prevState, open: false }));
      setOpenSidebar(false);
      return;
    }

    if (patientResponseSidebar?.open) {
      setSelectedView(OPTIONS.find(({ component }) => component === 'patient_response'));
      setOpenSidebar(true);
      return;
    }
  };

  useEffect(() => {
    showPatientResponse();
  }, [patientResponseSidebar.open]);

  const currentView = (wantedComponent) => {
    const view = {
      patient_general_overview: <GeneralOverview />,
      previous_note_preview: <PreviousNotes />,
      macros: <Macros />,
      treatment_plan: <TreatmentPlan />,
      patient_response: <PatientResponse />,
      myscribe_ai_narrative: <MyScribeAISummary />
    };

    return view[wantedComponent] ?? null;
  };

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
    const updatedSettings = {
      ...cnDisplaySettings,
      toggleRSidebar: {
        ...cnDisplaySettings?.toggleRSidebar,
        enabled: !cnDisplaySettings?.toggleRSidebar?.enabled
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  return (
    <div
      className={cs(
        'z-30 flex h-full shrink-0 flex-col border-0 !border-l-[1px] !border-solid !border-neutral-100 bg-white shadow-[-4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        device === 'desktop' || device === 'laptop'
          ? 'sticky top-0'
          : 'fixed right-0 top-[52px] h-[calc(100vh-117px)]',
        openSidebar ? 'w-[356px]' : 'w-[0]'
      )}>
      {openSidebar && (
        <ArrowToggle defaultArrow="right" open={openSidebar} setOpen={toggleSidebar} />
      )}
      <SidebarPopover
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        options={OPTIONS}
        open={openSidebar}
      />
      {shouldRender ? currentView(selectedView.component) : null}
    </div>
  );
};

export default Sidebar;
