import { getFaxesCSV, previewSavedFax } from 'api/Fax';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useArchivedFaxes } from 'lib/hooks/queries/fax/useArchivedFaxes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewFaxesTable from '../NewFaxesTable';
import PreviewPdfModal from '../components/SendFax/components/PreviewPdfModal';
import { getColDefs, DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS } from './configs';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const NewArchivedFaxes = () => {
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;
  return (
    <TableContextProvider
      name="archived_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone)}
      pagination>
      <ArchivedFaxesTable />
    </TableContextProvider>
  );
};

function ArchivedFaxesTable() {
  const category = 'archived_faxes';

  const navigate = useNavigate();

  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);
  const [previewFax, setPreviewFax] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const { limit, page, sort, filters } = useTableContext();

  const { data, isFetching, isLoading } = useArchivedFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const draftFaxes = data?.archivedFaxes || [];
  const count = data?.count || [];

  const onPreviewFax = async (fax) => {
    previewSavedFax(navigate, fax.id, true).then(async (data) => {
      setPreviewFax(fax);
      setPdfData(data.url);
      setPreviewFaxModalVisible(true);
    });
  };

  const onRowClicked = (clickedFax) => {
    onPreviewFax(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'archived'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };

  return (
    <div className="h-full" data-dd-privacy="allow">
      <NewFaxesTable
        count={count}
        onExportCSV={onExportCSV}
        data={draftFaxes}
        category={category}
        name="Archived Faxes"
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
      />
      {previewFaxModalVisible && (
        <PreviewPdfModal
          {...{
            pdfData,
            previewFax,
            previewFaxModalVisible,
            setPreviewFaxModalVisible,
            hasEdit: true
          }}
        />
      )}
    </div>
  );
}

export default NewArchivedFaxes;
