import React from 'react';
import { formatDate, ia } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import * as Yup from 'yup';
import Actions from '../components/Actions';
import TableTippy from '../components/TableTippy';

export const getCurrentStep = (breadcrumbs, currentBreadcrumb) => {
  // const hiddenSteps = breadcrumbs.filter((breadcrumb) => breadcrumb.hidden);
  let index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.view === currentBreadcrumb);
  if (index >= 0) {
    index = index + 1;
  }
  return index;
};

export const getCurrentWithoutHidden = (breadcrumbs, currentBreadcrumb) => {
  const hiddenSteps = breadcrumbs.filter((breadcrumb) => !breadcrumb.hidden);
  let index = hiddenSteps.findIndex((breadcrumb) => breadcrumb.view === currentBreadcrumb);
  if (index >= 0) {
    index = index + 1;
  }
  return index;
};

export const getStepsLeft = (breadcrumbs, currentBreadcrumb) => {
  //get breadcrumbs which are not hidden
  const nonHiddenSteps = breadcrumbs.filter((breadcrumb) => !breadcrumb.hidden);
  const currentIndex = nonHiddenSteps.findIndex(
    (breadcrumb) => breadcrumb.view === currentBreadcrumb
  );

  if (currentIndex === -1) {
    return -1;
  }

  const remainingSteps = nonHiddenSteps.slice(currentIndex + 1);

  return remainingSteps.length;
};
export const getAllStepsLeft = (breadcrumbs, currentBreadcrumb) => {
  //get all breadcrumbs with hidden included
  const currentIndex = breadcrumbs.findIndex((breadcrumb) => breadcrumb.view === currentBreadcrumb);

  if (currentIndex === -1) {
    return -1;
  }

  const remainingSteps = breadcrumbs.slice(currentIndex + 1);

  return remainingSteps.length;
};

export const PacketInitialValues = (packet) => ({
  id: packet?.id || null,
  name: packet?.name || '',
  provider_ids: packet?.provider_ids || [],
  service_ids: packet?.service_ids || [],
  tag_ids: packet?.tag_ids || [],
  form_ids: packet?.form_ids || [],
  patient_creation: packet?.patient_creation || false,
  automation: {
    providers: packet?.providers || false,
    services: packet?.services || false,
    tags: packet?.forms || false,
    patient_creation: packet?.patient_creation || false
  }
});

export const PacketValidationSchema = Yup.object().shape({
  name: Yup.string().required('Packet name is required')
});

export const defaultColumns = [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value
  },
  {
    field: 'form_ids',
    headerName: 'Forms',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => <TableTippy value={value} />
  },
  {
    field: 'service_ids',
    headerName: 'Services',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => <TableTippy value={value} />
  },
  {
    field: 'provider_ids',
    headerName: 'Providers',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => <TableTippy value={value} />
  },
  {
    field: 'tag_ids',
    headerName: 'Tags',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => <TableTippy value={value} />
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'updated_at',
    headerName: 'Updated On',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  name: {
    type: 'search',
    values: null,
    title: 'Packet name',
    placeholder: 'Search name'
  },
  forms: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Form',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search forms'
  },
  services: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Services',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search service'
  },
  providers: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Providers',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search providers'
  },
  tags: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Tags',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search tags'
  }
};

export const removeAutomation = (packet) => {
  // eslint-disable-next-line no-unused-vars
  const { automation, ...rest } = packet;
  return rest;
};

export const removeAutomationAndEmptyValues = (packet) => {
  // eslint-disable-next-line no-unused-vars
  const { automation, ...data } = packet;
  Object.keys(data).forEach((key) => {
    if (ia(data[key])) {
      data[key] = data[key].filter((item) => item !== '' && item !== null);
    }
  });
  return data;
};

export const isArrayWithoutEmptyValues = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }

  const nonEmptyValues = arr.filter((element) => element !== '');

  return nonEmptyValues.length > 0;
};
