import { Honeybadger } from '@honeybadger-io/react';
import { pick } from 'lodash';
import LogRocket from 'logrocket';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { requestApi } from '../../api/Api';
import { interimApi } from '../../api/InterimApi';
import adminState from '../../components/admin/lib/adminState';
import practiceState from '../../components/practice/practiceState';
import state from '../../components/state';
import { defaultSettings } from '../../constants';
import { io } from '../helpers/utility';
import { findOpenAndCloseClinicHours } from '../helpers/workingHours';

const useProcessLogin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(state.userState);
  const [admin, setAdminUser] = useRecoilState(adminState.current_admin);
  const [practice, setPractice] = useRecoilState(practiceState.currentPractice);
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  const setHasBeenAuthChecked = useSetRecoilState(state.hasBeenAuthChecked);
  const setAppointmentFilters = useSetRecoilState(practiceState.calendarFilters);

  const getPermissions = async () => {
    if (
      permissions.loaded ||
      (!(user.logged_in === admin.logged_in) && (user.logged_in || admin.logged_in))
    ) {
      return;
    }

    const onSuccess = (data) => {
      if (data?.permissions) setPermissions(data?.permissions);
    };
    await requestApi({ url: '/api/permissions/get', navigate, onSuccess });
  };

  const getCurrentPractice = async () => {
    try {
      const res = await interimApi('/api/practice/current_practice', {}, navigate);
      const { code, practice, errors } = res.data;
      if (practice) {
        const workingHours = findOpenAndCloseClinicHours(practice?.available_times);
        setPractice({
          ...practice,
          ...pick(practice, [
            'id',
            'name',
            'email',
            'phone',
            'header_photo',
            'timezone',
            'display_settings',
            'appointment_types',
            'eligibility_counter',
            'care_credit',
            'surcharge',
            'surcharge_percentage',
            'surcharge_enabled'
          ]),
          address: practice?.practiceAddress?.fullAddress,
          working_hours: workingHours
        });
      }
      return { id: practice.id, name: practice.name };
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const processLogin = async ({ user, redirect, navigateToLast }) => {
    setUser({
      ...user,
      authenticated: true,
      display_settings: user?.display_settings || defaultSettings,
      logged_in: true
    });
    getPermissions();
    const currPractice = await getCurrentPractice();
    try {
      LogRocket &&
        LogRocket.identify(user?.user_id, {
          name: `${user?.f_name} ${user?.l_name}`,
          email: user?.email,
          practiceName: currPractice?.name,
          practiceId: currPractice?.id
        });
    } catch (error) {
      console.error(error);
    }
    try {
      Honeybadger.setContext({
        user_id: user?.user_id,
        user_email: user?.email,
        practice_name: currPractice?.name,
        practice_id: currPractice?.id
      });
    } catch (error) {
      console.error(error);
    }
    if (user?.display_settings && io(user?.display_settings?.appointmentFilters)) {
      setAppointmentFilters(user?.display_settings?.appointmentFilters);
    }
    setHasBeenAuthChecked((prev) => ({ isImpersonate: false, checked: true }));
    if (navigateToLast) {
      navigateToLast(redirect);
    } else {
      navigate(redirect);
    }
  };

  return processLogin;
};

export default useProcessLogin;
