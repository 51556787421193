import React from 'react';
import { formatDate, ia, isEmpty } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import VaccinesModal from '../Modals/VaccinesModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const VaccinesOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview.vaccines) return null;
  return (
    <SimpleAccordion
      title="Vaccines"
      onToggle={() => toggleOne('vaccines')}
      open={isOpen.vaccines}
      edit={
        <VaccinesModal
          formId={currentHpOverviewData?.vaccines?.id}
          defaultValue={currentHpOverviewData?.vaccines?.response}
          setCustomResponses={setOverviewData}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
        />
      }>
      {overviewData?.vaccines?.map((vaccine, index) => {
        if (vaccine?.response?.noDetails) {
          return (
            <NoKnown
              key={index}
              form={vaccine}
              description="No known vaccines"
              shouldShowDivider={index !== 0}
            />
          );
        }

        return (
          <>
            {!vaccine?.response?.noDetails &&
              ia(vaccine?.response?.row) &&
              vaccine?.response?.row?.map(
                (item, idx) =>
                  item.vaccine && (
                    <React.Fragment key={idx}>
                      <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                      <div className="grid gap-y-1">
                        {!isEmpty(item?.vaccine?.value) && (
                          <div className="flex justify-between">
                            <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                              Vaccine:
                            </span>
                            <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                              {item.vaccine.value}
                            </span>
                          </div>
                        )}
                        {!isEmpty(item?.doses?.value) && (
                          <div className="flex justify-between">
                            <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                              Doses taken:
                            </span>
                            <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                              {item.doses.value}
                            </span>
                          </div>
                        )}
                        {!isEmpty(item?.lastDose) && (
                          <div className="flex justify-between">
                            <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                              Date of last dose:
                            </span>
                            <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                              {item?.notSure ? 'Not sure' : formatDate(item?.lastDose)}
                            </span>
                          </div>
                        )}
                        {!isEmpty(item?.note) && (
                          <div className="flex flex-wrap justify-between">
                            <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                              Note:
                            </span>
                            <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                              {item.note}
                            </span>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )
              )}
            {!vaccine?.response?.noDetails && vaccine?.response?.generalNote && (
              <>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                  General Note:
                </span>
                <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                  {vaccine?.response?.generalNote}
                </span>
              </>
            )}
            <CreationInfo createdAt={vaccine?.createdAt} createdBy={vaccine?.createdBy?.fullName} />
          </>
        );
      })}
    </SimpleAccordion>
  );
};

export default VaccinesOverview;
