import moment from 'moment';

export const groupByBenefitCode = (data = []) => {
  const groupedBenefitByCode = data.reduce((acc, item) => {
    const { benefit_code } = item;

    const existingGroup = acc.find((group) => group[0].benefit_code === benefit_code);

    if (existingGroup) {
      existingGroup.push(item);
    } else {
      acc.push([item]);
    }

    return acc;
  }, []);

  let benefitArray = groupedBenefitByCode.slice();

  benefitArray = benefitArray.map((item) => {
    const groupedData = {};

    item.forEach((obj) => {
      const benefitLevel = obj.benefit_level_description;
      const inplanNetwork = obj.inplan_network;

      if (!groupedData[benefitLevel]) {
        groupedData[benefitLevel] = {};
      }

      if (!groupedData[benefitLevel][inplanNetwork]) {
        groupedData[benefitLevel][inplanNetwork] = [];
      }

      groupedData[benefitLevel][inplanNetwork].push(obj);
    });

    return {
      groupedData,
      groupName: !!item[0]?.benefit_description
        ? item[0]?.benefit_description
        : item[1]?.benefit_description
          ? item[1]?.benefit_description
          : item[0]?.benefit_coverage_description
    };
  });

  return benefitArray;
};

export const getEligibilityInfo = (eligibility) => {
  let eligibilityInfo = [
    [
      { label: 'Patient:', value: eligibility.pat_name_l + ', ' + eligibility.pat_name_f },
      {
        label: 'Date of Birth:',
        value: moment(eligibility.pat_dob || eligibility?.ins_dob).format('MM/DD/YYYY')
      },
      { label: 'Gender:', value: eligibility.pat_sex && getGender(eligibility.pat_sex) },
      { label: 'Group Number:', value: eligibility.group_number },
      {
        label: 'Plan Being:',
        value: eligibility.plan_begin_date && getPlanBeginDate(eligibility.plan_begin_date)
      }
    ],
    [
      { label: 'Policy:', value: eligibility.ins_number },
      { label: 'Insured Name:', value: eligibility.ins_name_l + ', ' + eligibility.ins_name_f },
      {
        label: 'Insured Address:',
        value:
          eligibility.ins_addr_1 +
          ' ' +
          eligibility.ins_addr_2 +
          ', ' +
          eligibility.ins_city +
          ', ' +
          eligibility.ins_state +
          ', ' +
          eligibility.ins_zip
      },
      { label: 'Insured DOB:', value: moment(eligibility.ins_dob).format('MM/DD/YYYY') },
      { label: 'Insured Gender:', value: getGender(eligibility.ins_sex) }
    ]
  ];

  eligibilityInfo = eligibilityInfo.map((item) =>
    item.filter((it) => !!it.value && !it.value.includes('undefined'))
  );

  return eligibilityInfo;
};

export const hideStickyNotes = () => {
  const stickyNotesElement = document.getElementById('stickyNotes');
  if (stickyNotesElement) {
    stickyNotesElement.style.display = 'none';
  }
};

export const showStickyNotes = () => {
  const stickyNotesElement = document.getElementById('stickyNotes');
  if (stickyNotesElement) {
    stickyNotesElement.style.display = 'block';
  }
};

export const filterBySearchTerm = (data, searchTerm) => {
  return data.filter((item) => item?.groupName?.toLowerCase().includes(searchTerm.toLowerCase()));
};

export const returnInplanName = (code) => {
  let name = '';
  if (code == 'Y') name = 'In-Plan-Network';
  if (code == 'N') name = 'Out-Of-Plan-Network';
  return name;
};

export const groupByBenefitCoverageDescription = (data) => {
  const groupedBenefitsCoverageDescription = {};

  data.forEach((item) => {
    const key = `${item.insurance_plan}_${item.benefit_coverage_description}`;
    if (!groupedBenefitsCoverageDescription[key]) {
      groupedBenefitsCoverageDescription[key] = [];
    }
    groupedBenefitsCoverageDescription[key].push(item);
  });

  const result = Object.values(groupedBenefitsCoverageDescription);

  return result;
};

export const scrollToElement = (id) => {
  const container = document.getElementById(id);

  container.classList.add('blink');

  container.scrollIntoView({ behavior: 'smooth', block: 'start' });

  setTimeout(() => {
    container.classList.remove('blink');
  }, 2000);
};

function getGender(g) {
  let gender = '';
  if (g === 'M') {
    gender = 'Male';
  } else if (g === 'F') {
    gender = 'Female';
  } else {
    gender = 'other';
  }
  return gender;
}

function getPlanBeginDate(planBeginDate) {
  try {
    planBeginDate = planBeginDate.split('-');
    let date1 = moment(planBeginDate[0]).format('MM/DD/YYYY');
    let date2 = moment(planBeginDate[1]).format('MM/DD/YYYY');
    return date1 + '-' + date2;
  } catch (error) {
    console.log(error);
  }
}

export const getAuthorizationRequired = (auth) => {
  let auth_required = '';
  if (auth === 'Y') {
    auth_required = 'Auth/Cert Required';
  }
  if (auth === 'N') {
    auth_required = 'Auth/Cert No Required';
  }

  return auth_required;
};
