import React, { useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Test = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const testRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { test: clinicalNote?.test }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };
  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write test notes here"
      id="test"
      name="test"
      className="min-h-[200px]"
      value={clinicalNote?.test}
      onChange={(e) => handleChange(e, 'test')}
      transcribing
      forwardedRef={testRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Test;
