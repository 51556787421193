import cs from 'classnames';
import * as filestack from 'filestack-js';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Button from '../Buttons/Button';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';
import Loading from '../Loading/Loading';
import Resizer from 'react-image-file-resizer';
const client = filestack.init(process.env.REACT_APP_FILESTACK);
import arrow from '../../Patient/checkin/payment/icons/arrow2.svg';

import camera from '../../practice/charts/InsuranceProfile/icons/camera.svg';
import gallery from '../../practice/charts/InsuranceProfile/icons/gallery.svg';
import refresh from '../../practice/charts/InsuranceProfile/icons/refresh.svg';
import overlay from '../../practice/charts/InsuranceProfile/icons/overlay.svg';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const ImagePicker = ({
  handle,
  onCapture = () => {},
  name,
  kiosk,
  processing,
  handleContinue,
  resetHandle,
  resetCrop,
  dimensions = [1280, 720]
}) => {
  const webcamRef = useRef(null);

  const [zoomed, setZoomed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');

  const captureHighResScreenshot = () => {
    const videoElement = webcamRef.current.video;

    // Store the original width and height
    const originalWidth = videoElement.videoWidth;
    const originalHeight = videoElement.videoHeight;

    // Set the video element to a higher resolution temporarily
    videoElement.style.width = `${originalWidth}px`;
    videoElement.style.height = `${originalHeight}px`;

    // Capture the screenshot
    const screenshot = webcamRef.current.getScreenshot();

    // Revert the video element size
    videoElement.style.width = '';
    videoElement.style.height = '';

    return screenshot;
  };

  const videoConstraints = {
    facingMode: { ideal: facingMode },
    width: { ideal: dimensions[0] || 1280 },
    height: { ideal: dimensions[1] || 720 }
  };
  const [url, setUrl] = useState(handle ? `https://cdn.filestackcontent.com/${handle}` : null);

  const { device } = useUIContext();

  useEffect(() => {
    setUrl(handle ? `https://cdn.filestackcontent.com/${handle}` : null);
  }, [handle]);

  const flipCamera = () => {
    setFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1400, // new width
        1400, // new height
        'JPEG',
        90,
        0,
        (uri) => {
          // Convert base64/URI to Blob
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              resolve(blob);
            });
        },
        'base64'
      );
    });

  const capture = async () => {
    setLoading(true);
    const imageSrc = captureHighResScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    const resizedImageBlob = await resizeFile(blob);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await client.upload(resizedImageBlob);
    onCapture(response?.handle, name);
    setLoading(false);
    setIsOpen(false);
  };

  const onFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const resizedImageBlob = await resizeFile(file);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await client.upload(resizedImageBlob);
    onCapture(response?.handle, name);
    setLoading(false);
    setIsOpen(false);
  };

  const renderer = () => {
    if (loading) {
      return (
        <div className="flex gap-2">
          <Loader color="neutral" shade="800"></Loader>
          <p>Uploading picture...</p>
        </div>
      );
    }

    if (processing) {
      return (
        <div className="flex gap-2">
          <Loader color="neutral" shade="800"></Loader>
          <p>Detecting Document...</p>
        </div>
      );
    }

    if (handle) {
      return (
        <div className="flex flex-col items-center">
          <div className="flex gap-2">
            <Button
              color="white"
              text="Re-take picture"
              icon="rotate-ccw"
              iconColor="white"
              iconIsStroke
              outlined
              className="w-[11rem] cursor-pointer gap-1 border border-white !bg-transparent"
              transparent
              onClick={resetHandle}></Button>

            <Button
              text="Looks good, continue"
              icon="new-check"
              iconColor="white"
              iconIsStroke
              className="cursor-pointer gap-1"
              onClick={handleContinue}></Button>
          </div>
          <div className="mt-5 flex items-center gap-2">
            <Icon icon="rotate-ccw"></Icon>
            <p className=" cursor-pointer text-sm text-primary-700" onClick={resetCrop}>
              Reset Crop
            </p>
          </div>
        </div>
      );
    }
    return (
      <>
        <div
          onClick={() => document.getElementById(`modal-${name}-upload`).click()}
          className="flex cursor-pointer items-center justify-center rounded-full border !border-neutral-600 !p-2 transition-all hover:scale-110">
          <img src={gallery} />
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={onFileChange}
          className="hidden"
          id={`modal-${name}-upload`}
        />

        <div
          onClick={capture}
          className=" relative flex cursor-pointer items-center justify-center rounded-full bg-white !p-3">
          <img src={camera} />
          <div className="absolute -bottom-1 -left-1 -right-1 -top-1 flex items-center justify-center rounded-full border border-white transition-all hover:-bottom-2 hover:-left-2 hover:-right-2 hover:-top-2 focus:bottom-0 focus:left-0 focus:right-0 focus:top-0"></div>
        </div>

        <div
          onClick={flipCamera}
          className="flex cursor-pointer items-center justify-center rounded-full border !border-neutral-600 !p-2 transition-all hover:scale-110">
          <img src={refresh} />
        </div>
      </>
    );
  };

  return (
    <div className="flex w-full flex-col items-center px-6">
      <div
        style={{ maxWidth: '30rem', maxHeight: '60vh' }}
        className="relative mt-4 overflow-hidden rounded-xl">
        {url ? (
          <>
            <img
              className={cs(
                'h-full !w-full rounded-xl object-contain',
                !processing && !loading && 'border !border-dashed border-white shadow-xl '
              )}
              src={url}></img>
            {(processing || loading) && !kiosk && (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl ">
                <img className="!w-full" src={overlay}></img>
              </div>
            )}
          </>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              className="h-full w-full rounded-xl object-contain"></Webcam>
            {!kiosk && (
              <div
                className={cs(
                  'absolute  left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl',
                  device === 'tablet'
                    ? 'bottom-0'
                    : device === 'mobile' || device === 'tablet-sm'
                      ? 'bottom-[4.38px]'
                      : 'bottom-[5.5px]'
                )}>
                <img className="!w-full" src={overlay}></img>
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-5 flex items-center justify-center gap-4 text-white">{renderer()}</div>
    </div>
  );
};

export default ImagePicker;
