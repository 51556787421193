import { Popover } from '@headlessui/react';
import cs from 'classnames';
import React, { useMemo, useRef, useState } from 'react';

import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import Input from '../../../../shared/Input/Input';

import { useNewAppointmentContext } from '../../../../../lib/context/Appointment/NewAppointmentContext';
import { arrayToObject, ia, iaRa, mString } from '../../../../../lib/helpers/utility';
import useOutsideClick from '../../../../../lib/hooks/useOutsideClick';
import EmptyProductsPackages from '../../../../Patient/sell/EmptyProductsPackages';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import { NewItemCounter, SelectedItems, ServicesFromPackages } from './components';
import { addNewItems, handleAddItems, isProcedureChecked } from './lib';

const AddProceduresProducts = ({
  label,
  list,
  type,
  packageList = [],
  formik,
  originalProceduresProducts
}) => {
  const ref = useRef(null);

  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};

  const { values, errors, touched, setFieldValue } = formik;

  const selectedItems = [...values[type]];

  const [newItems, setNewItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = list?.filter?.((item) =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const servicesObject = useMemo(() => arrayToObject(list, 'id', 'image'), [list]);

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength
    });
  };

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  return (
    <div ref={ref}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className="w-full p-0"
              data-qa={`add-btn-type-${type}`}
              onClick={() => {
                open && handleAddItemsFunc();
                !values?.id && !open && setTimeout(() => scrollToElement(), 100);
              }}>
              <div
                className={cs(
                  'flex cursor-pointer items-center justify-between rounded-lg !border border-solid border-transparent border-l-primary-50 border-r-primary-100 bg-gradient-to-r from-primary-50 to-primary-100 !px-4 !py-2',
                  open && '!border-primary-500'
                )}>
                <Icon
                  icon="new-dropdown-select"
                  className="grid h-9 w-9 cursor-pointer place-content-center rounded-full bg-white"
                />

                <label className="!mb-0 cursor-pointer text-sm font-500 text-primary-500">
                  {`Add ${label}`}
                </label>

                <Icon
                  color="primary"
                  icon="new-chevron-down"
                  className={cs(
                    open && 'rotate-180',
                    'grid h-6 w-6 cursor-pointer place-content-center rounded-full bg-primary-200'
                  )}
                />
              </div>
            </Popover.Button>

            <Popover.Panel
              className={cs(
                'absolute z-20 mt-[6px] flex w-full  flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]',
                values?.id && 'top-[-415px]'
              )}>
              <div
                className={cs(
                  'relative h-[300px] overflow-y-auto !p-3 !pr-[6px]',
                  ia(filteredData) && 'h-[400px]'
                )}>
                {type === 'procedures' && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}

                <div>
                  {ia(list) ? (
                    <>
                      <Input
                        icon="search"
                        rounded="full"
                        value={searchTerm}
                        id={`search-${type}`}
                        data-qa={`search-${label}`}
                        placeholder={`Search ${label}`}
                        rightIcon={searchTerm && 'new-close'}
                        rightIconClick={() => setSearchTerm('')}
                        onChange={(e) => setSearchTerm(e?.target?.value)}
                        className="sticky -top-3 z-10 !mb-2 w-full bg-white !p-2"
                      />

                      <p className="text-xs font-500 leading-5 text-neutral-500">{label}</p>
                    </>
                  ) : null}

                  {ia(filteredData) ? (
                    filteredData?.map((item, index) => {
                      return (
                        <div
                          className={cs(
                            'flex !min-h-[34px] items-center justify-between rounded-md !px-2 py-[3px]',
                            !!isProcedureChecked(newItems, item) && 'bg-primary-50'
                          )}
                          key={item?.id}>
                          <Checkbox
                            id={index}
                            label={item?.name}
                            onBlur={formik.handleBlur}
                            labelTextColor="leading-4"
                            handleClick={() =>
                              addNewItems({ newItem: item, newItems, setNewItems })
                            }
                            isChecked={
                              type === 'procedures'
                                ? !!isProcedureChecked(newItems, item)
                                : newItems.some((sItem) => sItem.id === item.id)
                            }
                          />

                          <div className="flex items-center !gap-2">
                            <NewItemCounter
                              newItem={item}
                              newItems={newItems}
                              setNewItems={setNewItems}
                            />

                            {type === 'products' && (
                              <p className="w-14 text-xs text-neutral-500">
                                {mString(item?.amount_cents)}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : type === 'products' ? (
                    <EmptyProductsPackages type="product" />
                  ) : null}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      <SelectedItems
        type={type}
        label={label}
        values={values}
        setFieldValue={setFieldValue}
        selectedItems={selectedItems}
        servicesObject={servicesObject}
        list={type === 'products' ? list : []}
        isClinicalNoteLocked={isClinicalNoteLocked}
        originalProceduresProducts={originalProceduresProducts}
      />

      {type === 'procedures' && touched?.procedures && errors?.procedures && (
        <p className="!pt-2 text-sm text-danger-500">{errors?.procedures}</p>
      )}

      {type === 'products' && ia(additionalTimes) && ia(values?.products) && (
        <p className="text-sm text-warning-500">
          Products are only available for the current appointment.
        </p>
      )}
    </div>
  );
};

export default AddProceduresProducts;
