import Pagination from 'components/shared/Pagination/Pagination';
import React from 'react';

export const CustomStatusBarPagination = ({
  data = [],
  count,
  page,
  limit,
  onPageChange = () => {}
}) => {
  return (
    <div className="flex flex-wrap space-x-4 p-3 text-sm">
      {data?.length > 0 ? (
        <Pagination
          containerClassName="flex"
          onPageChange={onPageChange}
          perPage={limit}
          totalItems={count}
          page={page}
        />
      ) : null}
    </div>
  );
};
