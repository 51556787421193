import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { useFormik } from 'formik';
import { iaRa } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import NewBillingProvider from './NewBillingProvider';
import Row from './Row';

export default function AdminBilling() {
  const navigate = useNavigate();

  const { practice } = useOutletContext();
  const [billingInfo, setBillingInfo] = useState([]);
  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const PracticeBillingValidationSchema = Yup.object().shape({
    practice_id: Yup.number().required('Practice is required'),
    bill_name: Yup.string().required('Bill name is required.'),
    bill_npi: Yup.string(),
    bill_taxid: Yup.string(),
    bill_taxid_type: Yup.object(),
    bill_taxonomy: Yup.string(),
    bill_id: Yup.string(),
    bill_addr_1: Yup.string().required('Address line one is required.'),
    bill_addr_2: Yup.string().required('Address line two is required, type N/A if not applicable.'),
    bill_city: Yup.string().required('City is required'),
    bill_state: Yup.string().required('State is required'),
    bill_zip: Yup.string()
      .min(9, 'Too Short!')
      .max(9, 'Too Long!')
      .required('Zip code is required (9 digits)'),
    bill_phone: Yup.string(),
    practitioner_ids: Yup.array().min(1, 'Please select at least one practitioner')
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      practice_id: practice?.id,
      bill_name: practice?.name,
      bill_npi: '',
      bill_taxid: '',
      bill_taxid_type: '',
      bill_taxonomy: '',
      bill_id: '',
      bill_addr_1: practice?.practiceAddress?.address_ln_1,
      bill_addr_2: practice?.practiceAddress?.address_ln_2,
      bill_city: practice?.practiceAddress?.city,
      bill_state: practice?.practiceAddress?.state,
      bill_zip: practice?.practiceAddress?.zip,
      bill_phone: practice.phone,
      practitioner_ids: []
    },
    validationSchema: PracticeBillingValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await submitBillingProvider(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (!practice?.id) return;

    getBillingInfo();
    getStates();
  }, [practice?.id]);

  const getStates = async () => {
    try {
      const statesArray = await getStatesAPI(navigate, { optionify: true });
      setStates(statesArray);
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: getStates, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const getBillingInfo = async () => {
    try {
      const onSuccess = ({ billInfo }) => {
        setBillingInfo(billInfo);
      };
      const onError = (error) => {
        console.log('error', error);
        showAlert({ title: error, color: 'danger' });
      };
      await requestApi({
        url: `/api/admin/practice/billing/read`,
        params: {
          practiceId: practice.id
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: getBillingInfo - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const submitBillingProvider = async (values) => {
    const newBillingProvider = { ...values };
    try {
      const res = await requestApi({
        url: '/api/admin/practice/billing/upsert',
        params: {
          newBillingProvider: {
            ...newBillingProvider,
            bill_taxid_type: newBillingProvider.bill_taxid_type.value
          }
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Success!', color: 'primary' });
          getBillingInfo();
          setShowModal(false);
        } else {
          Honeybadger.notify(
            `file: /billing/AdminBilling, method: submitBillingProvider, error: An unexpected error has occurred.`
          );
          showAlert({ title: 'There was an error.', color: 'danger' });
        }
      } else {
        Honeybadger.notify(
          `file: /billing/AdminBilling, method: submitBillingProvider, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: submitBillingProvider - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleDeletion = async (id) => {
    try {
      const res = await requestApi({
        url: '/api/admin/practice/billing/delete',
        params: {
          id
        },
        navigate
      });

      if (res) {
        if (res.success) {
          showAlert({ title: 'Successfully deleted!', color: 'primary' });
          getBillingInfo();
          setShowModal(false);
        } else {
          showAlert({ title: 'There was an error deleting', color: 'danger' });
          Honeybadger.notify(
            `file: /billing/AdminBilling, method: handleDeletion, error: An unexpected error has occurred.`
          );
        }
      } else {
        Honeybadger.notify(
          `file: /billing/AdminBilling, method: handleDeletion, error: An unexpected error has occurred.`
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /billing/AdminBilling, method: handleDeletion - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const selectBiller = (biller) => {
    formik.setFieldValue('id', biller.id);
    formik.setFieldValue('practice_id', biller.practice_id);
    formik.setFieldValue('bill_name', biller.bill_name);
    formik.setFieldValue('bill_npi', biller.bill_npi);
    formik.setFieldValue('bill_taxid', biller.bill_taxid);
    formik.setFieldValue('bill_taxid_type', biller.bill_taxid_type);
    formik.setFieldValue('bill_taxonomy', biller.bill_taxonomy);
    formik.setFieldValue('bill_id', biller.bill_id);
    formik.setFieldValue('bill_addr_1', biller.bill_addr_1);
    formik.setFieldValue('bill_addr_2', biller.bill_addr_2);
    formik.setFieldValue('bill_city', biller.bill_city);
    formik.setFieldValue('bill_state', biller.bill_state);
    formik.setFieldValue('bill_zip', biller.bill_zip);
    formik.setFieldValue('bill_phone', biller.bill_phone);
    formik.setFieldValue('practitioner_ids', biller.practitioner_ids);
    setShowModal(true);
  };

  return (
    <div className="flex h-fit min-h-[30vh] flex-col p-[1rem]">
      <Button
        icon="plus"
        onClick={() => setShowModal(true)}
        text="New"
        size="small"
        className="w-[100px]"
      />
      <div className="!mt-6 overflow-auto !pr-4">
        {iaRa(billingInfo)?.map((row) => {
          return (
            <Row
              key={row.id}
              row={row}
              members={practice?.members}
              onClick={() => selectBiller(row)}
              states={states}
            />
          );
        })}
      </div>
      <NewBillingProvider
        formik={formik}
        open={showModal}
        handleClose={() => setShowModal(false)}
        isLoading={formik.isSubmitting}
        handleSubmit={formik.submitForm}
        states={states}
        loadedMembers={iaRa(practice?.members).map((member) => {
          return { label: `${member.f_name} ${member.l_name}`, value: member.id };
        })}
        handleDeletion={handleDeletion}
      />
    </div>
  );
}
