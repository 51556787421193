import React, { useRef, useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Assessment = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const assessmentRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { assessment: clinicalNote?.assessment }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write assessment notes here"
      id="assessment"
      name="assessment"
      className="min-h-[200px]"
      value={clinicalNote?.assessment}
      onChange={(e) => handleChange(e, 'assessment')}
      transcribing
      forwardedRef={assessmentRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Assessment;
