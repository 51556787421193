import { isArray } from 'lodash';

export const returnCount = (constantType, label, data, keyName) => {
  let type;
  let totalCount = 0;

  if (isArray(constantType)) {
    type = constantType?.find((p) => p?.label === label);
    for (const key in data) {
      if (data[key]?.[keyName] === type?.value) {
        const count = parseInt(data[key]?.count, 10);
        totalCount += isNaN(count) ? 0 : count;
      }
    }
  }
  return totalCount;
};

export const getPercentage = (constantType, label, data, count, keyName) => {
  let type;
  if (isArray(constantType)) {
    type = constantType?.find((p) => p?.label === label);
  }
  let totalCount = 0;
  for (const key in data) {
    if (data[key]?.[keyName] === type?.value) {
      const count = parseInt(data[key]?.count, 10);
      totalCount += isNaN(count) ? 0 : count;
    }
  }

  const percentage = (totalCount / count) * 100;

  return percentage;
};

export const getIconByPercentage = (constantType, label, data, count, keyName) => {
  const percentage = getPercentage(constantType, label, data, count, keyName);
  if (percentage === 0) {
    return 'new-arrow-right-v2';
  } else if (percentage < 0) {
    return 'new-arrow-down-v2';
  } else {
    return 'new-arrow-up-v2';
  }
};

export const getIconColorByPercentage = (constantType, label, data, count, keyName) => {
  const percentage = getPercentage(constantType, label, data, count, keyName);
  if (percentage === 0) {
    return 'neutral';
  } else if (percentage < 0) {
    return 'danger';
  } else {
    return 'success';
  }
};

export const getClassNamesByPercentage = (constantType, label, data, count, keyName) => {
  const percentage = getPercentage(constantType, label, data, count, keyName);
  if (percentage === 0) {
    return 'border-solid border-neutral-200  bg-neutral-50 text-neutral-600';
  } else if (percentage < 0) {
    return 'border-solid border-red-200 bg-red-50 text-danger-500';
  } else {
    return 'border-solid border-success-200 bg-success-50 text-success-600';
  }
};
