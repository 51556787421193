import React from 'react';
import cs from 'classnames';

import NewItemCounter from './NewItemCounter';
import { ia } from '../../../../../../lib/helpers/utility';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import { addNewItems, getValidityLength, isProcedureChecked, procedureAvailability } from '../lib';

const ServicesFromPackages = ({ packageList, values, newItems, setNewItems, additionalTimes }) => {
  return packageList.map((invoicePackage) => {
    const { id: invoiceId, name, procedures, packages, created_at } = invoicePackage || {};

    return (
      <div className="!mb-2  grid w-full !gap-2 rounded-lg bg-primary-50 !p-4">
        {ia(procedures) && (
          <div className="grid w-full !gap-2 !mb-2">
            <label className="text-sm font-500 text-primary-500">{name}</label>

            {procedures?.map?.((procedure, index) => (
              <div className="flex items-center justify-between !gap-2" key={procedure?.id + invoiceId}>
                <div className="flex items-center">
                  <Checkbox
                    data-qa={`checkbox-${index}`}
                    isChecked={isProcedureChecked(newItems, procedure, null, invoiceId)}
                    handleClick={() =>
                      addNewItems({
                        newItems,
                        setNewItems,
                        newItem: { ...procedure, invoiceId, isPackage: true }
                      })
                    }
                  />

                  <p className="text-sm text-neutral-900">{procedure.name}</p>
                </div>

                <div className="flex !gap-2 items-center">
                  <NewItemCounter
                    newItem={procedure}
                    newItems={newItems}
                    invoiceId={invoiceId}
                    setNewItems={setNewItems}
                  />

                  <p className="whitespace-nowrap text-xs font-400 text-neutral-500">
                    {procedureAvailability({
                      newItems,
                      procedure,
                      invoiceId,
                      additionalTimes,
                      hasId: !!values?.id,
                      selectedProcedures: values.procedures,
                      oldProcedures: values?.invoice?.procedures
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {packages?.map?.((pack) => {
          const { id: packageId, name: packageName, procedures: packageProcedures } = pack || {};

          return (
            <div key={invoiceId * packageId} className="!mb-2">
              <div className="grid w-full !gap-2">
                <div className="flex items-center justify-between">
                  <label className="text-xs font-500 text-primary-500">{packageName}</label>

                  <label
                    className={cs(
                      'text-xs font-400',
                      `text-${getValidityLength(pack, created_at, true)}-600`
                    )}>
                    {getValidityLength(pack, created_at)}
                  </label>
                </div>

                {packageProcedures?.map?.((procedure, index) => (
                  <>
                    <div
                      className="flex items-center justify-between !gap-2"
                      key={procedure?.id + invoiceId}>
                      <div className="flex items-center">
                        <Checkbox
                          data-qa={`checkbox-${index}`}
                          isChecked={isProcedureChecked(newItems, procedure, packageId, invoiceId)}
                          handleClick={() =>
                            addNewItems({
                              newItems,
                              setNewItems,
                              newItem: { ...procedure, packageId, invoiceId, isPackage: true }
                            })
                          }
                        />

                        <p className="text-sm text-neutral-900">{procedure?.name}</p>
                      </div>

                      <div className="flex !gap-2 items-center">
                        <NewItemCounter
                          newItem={procedure}
                          newItems={newItems}
                          packageId={packageId}
                          invoiceId={invoiceId}
                          setNewItems={setNewItems}
                        />

                        <p className="whitespace-nowrap text-xs font-400 text-neutral-500 w-[80px] text-end">
                          {procedureAvailability({
                            newItems,
                            procedure,
                            packageId,
                            invoiceId,
                            additionalTimes,
                            hasId: !!values?.id,
                            selectedProcedures: values.procedures,
                            oldProcedures: values?.invoice?.procedures
                          })}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  });
};

export default ServicesFromPackages;
