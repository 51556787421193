import React from 'react';
import Practices from '../Practices';

const PracticeDashboardPanel = (props) => {
  return (
    <div className="p-4">
      <Practices {...props} />
    </div>
  );
};

export default PracticeDashboardPanel;
