import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';

const AmbientListing = () => {
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb } = breadcrumb;

  return (
    <div className="p-2">
      <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      <div className="flex h-screen">EnhanceNote</div>
    </div>
  );
};

export default AmbientListing;
