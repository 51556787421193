import Honeybadger from '@honeybadger-io/js';
import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { requestApi } from '../../../../../api/Api';
import { getStates as getStatesAPI } from '../../../../../api/State';
import { showAlert } from '../../../../shared/Alert/Alert';
import UpdateAddressForm from './UpdateAddressForm';

// eslint-disable-next-line react/display-name
const UpdateAddress = forwardRef(({ onClose, payer }, ref) => {
  const [states, setStates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Payer name is required.')
  });

  const [address, setAddress] = useState({});

  useEffect(() => {
    getAddress();
  }, []);

  async function getAddress() {
    const response = await requestApi({
      url: '/api/practice/settings/payers/address/get',
      params: {
        withCount: true,
        payerId: payer?.id
      },
      navigate,
      onSuccess: (data) => {
        setAddress(data?.address);
      }
    });

    return response;
  }

  const formik = useFormik({
    initialValues: {
      name: payer?.name,
      address_ln_1: address?.address_ln_1,
      address_ln_2: address?.address_ln_2,
      city: address?.city,
      state: address?.state,
      zip: address?.zip
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let addressObject = {
        address_ln_1: values.address_ln_1,
        address_ln_2: values.address_ln_2,
        city: values.city,
        state: values.state,
        zip: values.zip
      };

      await updateAddress(addressObject, resetForm);
      setSubmitting(false);
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const updateAddress = async (address, resetForm) => {
    const onSuccess = (data) => {
      resetForm();
      onClose();
      showAlert({ title: 'Success!', message: 'Payer requested successfully!', color: 'success' });
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create payer!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/practice/settings/payers/address/update',
      params: { payerId: payer.id, address },
      onSuccess,
      navigate,
      onError
    });
  };

  return (
    <div>
      <UpdateAddressForm states={states} formik={formik} />
    </div>
  );
});

export default UpdateAddress;
