import React, { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import ReactToPrint from 'react-to-print';

import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Receipt from 'components/shared/Receipt/Receipt';
import { Capitalize, formatDate, mString } from 'lib/helpers/utility';

const AppointmentPayments = ({ appointment, payments, timezone }) => {
  const { patient, invoice, starts_at } = appointment || {};

  const receiptRef = useRef();

  const [receipt, setReceipt] = useState({});
  const [show, setShow] = useState({ receipt: false });

  const handleReceipt = () => {
    setShow({ ...show, receipt: true });

    setReceipt({
      invoice,
      patient,
      transactions: payments,
      transaction: { date_of_service: starts_at }
    });
  };

  return (
    <div>
      <div className="!mb-2 flex items-center gap-x-1" data-dd-privacy="allow">
        <label className="label  mb-0">Payment(s)</label>
        <Tippy content={<span>Payment(s) applied to this appointment</span>} className="tippy-dark">
          <div className="flex">
            <Icon icon="info" />
          </div>
        </Tippy>
      </div>
      <div className="!rounded-lg bg-white !pb-4" data-dd-privacy="allow">
        <table className="primary-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {payments?.map?.((payment, i) => {
              const { amount_paid_self, type, status, created_at, surcharge_amount } =
                payment || {};

              return (
                <tr key={i} data-dd-privacy="allow" data-public>
                  <td>{formatDate(created_at, timezone)}</td>
                  <td className="flex h-[50px] items-center !gap-1">
                    {mString(amount_paid_self)}
                    {surcharge_amount > 0 && (
                      <Tippy
                        className="tippy-dark"
                        content={
                          <>
                            <p className="text-xs">
                              Charge: {mString(amount_paid_self - (surcharge_amount || 0))}
                            </p>
                            <p className="text-xs">Technology fee: {mString(surcharge_amount)}</p>
                          </>
                        }>
                        <div className="flex">
                          <Icon icon="info" />
                        </div>
                      </Tippy>
                    )}
                  </td>
                  <td>{Capitalize(String(type).replaceAll('_', ' '))}</td>
                  <td>{Capitalize(status)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="!mr-4 !mt-2 flex justify-end">
          <Button
            outlined
            color="primary"
            icon="receipt-text"
            onClick={handleReceipt}
            text="View walkout receipt"
            className="!bg-primary-50"
          />
        </div>
      </div>

      {show.receipt && (
        <Modal
          slideFromRight
          title="Receipt"
          className="w-[1040px]"
          headButton={
            <div className="flex w-full items-center justify-end">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color=""
                    size="small"
                    type="link"
                    text="Print"
                    icon="new-printer-bulk"
                    className="text-primary-900"
                  />
                )}
                content={() => receiptRef.current}
              />
            </div>
          }
          handleOpen={show.receipt}
          handleClose={() => setShow({ ...show, receipt: false })}
          handleClick={() => setShow({ ...show, receipt: false })}
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                outlined
                transparent
                text="Close"
                color="neutral"
                data-qa="close-btn"
                onClick={() => setShow({ ...show, receipt: false })}
              />
            </div>
          }>
          <Receipt receipt={receipt} parentRef={receiptRef} walkout />
        </Modal>
      )}
    </div>
  );
};

export default AppointmentPayments;
