import React, { useState, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { PatternFormat } from 'react-number-format';
import moment from 'moment-timezone';
import cs from 'classnames';
import Icon from '../../Icon/Icon';
import { dateFormats } from '../../../../constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const generateFormat = (format) => {
  return format.replace('MM', '##').replace('DD', '##').replace('YYYY', '####');
};

const correctYear = (dateStr) => {
  let dateArray = dateStr.replaceAll('_', '').split('/');
  const year = dateArray[2];

  if (year?.length === 2) {
    const currentYear = moment().year();
    const threshold = (currentYear % 100) + 5;

    if (year <= threshold) {
      dateArray[2] = 2000 + parseInt(year);
    } else {
      dateArray[2] = 1900 + parseInt(year);
    }

    return dateArray.join('/');
  }

  return dateStr;
};

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const DatePopover = ({
  label,
  labelClassName,
  inputClassName,
  iconClassName,
  className,
  placeholder = 'Add date',
  icon = 'new-calendar-gray',
  format = 'MM/DD/YYYY',
  value,
  placementX,
  placementY = 'top-[110%]',
  onChange = () => {},
  disabled = false,
  required = false,
  id,
  name,
  formik,
  focusNextFieldOnEnter = false,
  error,
  dataQaInput = 'date-popover-input',
  dataQaIcon = 'date-popover-icon',
  ...rest
}) => {
  const autoFormat = dateFormats.find((format) => moment(value, format, true).isValid());
  const [formattedValue, setFormattedValue] = useState(moment(value, autoFormat).format(format));
  const inputFormat = generateFormat(format);
  const debouncedCorrectYear = useRef(debounce((value) => handleChange(value), 1000)).current;

  const handleChange = (value) => {
    const correctedValue = correctYear(value, format);
    setFormattedValue(correctedValue);
    onChange(correctedValue);
  };

  const handleValueChange = (formattedValue) => {
    debouncedCorrectYear(formattedValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && focusNextFieldOnEnter && formik) {
      const fields = Object.keys(formik.values);
      const index = fields.indexOf(name);
      if (index >= 0) {
        const nextFieldIndex = index + 1;

        if (nextFieldIndex < fields.length) {
          const nextFieldName = fields[nextFieldIndex];
          document.querySelector(`input[name="${nextFieldName}"]`).focus();
        } else {
          formik.submitForm();
        }
      }
    }
  };

  return (
    <Popover className={className}>
      {({ open, close }) => (
        <>
          {label && (
            <div className="flex min-h-[28px] items-center justify-between !pb-1">
              <label
                className={cs('m-0 flex text-sm font-500  text-neutral-800', labelClassName)}
                htmlFor={id}>
                {label}
                {required && <span className="text-danger-500">*</span>}
              </label>
            </div>
          )}
          <div className="relative">
            <PatternFormat
              className={cs(
                '!h-10 w-full !rounded-md border border-solid !border-neutral-200 !p-0 !px-3 text-sm text-neutral-900 outline-offset-2 placeholder:text-neutral-500 focus-visible:!border-primary-300 focus-visible:!outline-[#CBF0FD]',
                inputClassName
              )}
              mask="_"
              format={inputFormat}
              placeholder={placeholder}
              value={formattedValue}
              onValueChange={({ formattedValue }) => handleValueChange(formattedValue)}
              disabled={disabled}
              id={id}
              name={name}
              data-qa={dataQaInput}
              onKeyDown={handleKeyPress}
            />
            <Popover.Button
              data-qa={dataQaIcon}
              className={cs(
                'absolute right-[10px] top-1/2 flex -translate-y-1/2 cursor-pointer items-center justify-center p-0',
                iconClassName
              )}>
              <Icon icon={icon} className="cursor-pointer" />
            </Popover.Button>
            <Transition
              show={open}
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-0 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-0 opacity-0"
              className={cs(
                'absolute z-10 flex w-max overflow-hidden !rounded-lg bg-white !px-2 shadow-md',
                placementX,
                placementY
              )}>
              <Popover.Panel>
                <Calendar
                  showDateDisplay={false}
                  date={
                    moment(formattedValue, format ?? autoFormat).isValid()
                      ? moment(formattedValue, format ?? autoFormat).toDate()
                      : moment().toDate()
                  }
                  onChange={(event) => {
                    const formattedDate = moment.utc(event).add(12, 'h').format(format);
                    setFormattedValue(formattedDate);
                    onChange(formattedDate);
                    close();
                  }}
                  {...rest}
                />
              </Popover.Panel>
            </Transition>
          </div>
          {error && <p className={cs('!pt-2 text-left text-sm text-danger-500')}>{error}</p>}
        </>
      )}
    </Popover>
  );
};

export default DatePopover;
