import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Textarea from 'components/shared/Textarea/Textarea';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  calculateBMI,
  cmToInch,
  io,
  isEmpty,
  separateFeetAndInches,
  separatePoundsAndOunces
} from 'lib/helpers/utility';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Vitals = forwardRef(
  (
    {
      lastVitals,
      setLastVitals,
      hideVitalsModal = () => null,
      fromOverview = false,
      // customFormsParams,
      setOverviewVitals,
      isGrowthChartEnabled = false
    },
    ref
  ) => {
    const {
      setSaveButtonText = () => null,
      currentHpOverviewData,
      overviewData,
      updateSaveButton = () => null
    } = useClinicalNoteContext() || {};
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    console.log({ currentHpOverviewData, overviewData });

    const [vitals, setVitals] = useState({});
    const { id, appointmentId } = useParams();

    const unitsOptions = [
      {
        value: 'in',
        label: 'inch (in)'
      },
      {
        value: 'cm',
        label: 'centimeters (cm)'
      }
    ];

    const [currentUnit, setCurrentUnit] = useState(lastVitals?.head_circumference_unit || 'in');

    useEffect(() => {
      setVitals(lastVitals);
    }, [lastVitals]);

    const saveVitals = async () => {
      delete vitals?.created_by;
      delete vitals?.created_at;
      if (fromOverview) {
        delete vitals?.appointment_id;
        delete vitals?.patient_id;
        delete vitals?.practice_id;
      }
      const params = {
        patientId: id,
        vitals,
        appointmentId: appointmentId ? appointmentId : null
      };
      try {
        if (!io(vitals)) return;
        if (_.isEqual(vitals, lastVitals)) return;

        setSaveButtonText('Saving');
        const onSuccess = (data) => {
          const userType = data?.newVitals && data?.newPatientVitals ? 'insert' : 'update';
          const vitalsWithId = {
            ...vitals,
            id: data?.newVitals,
            created_by: { id: null, fullName: 'currentUser' }
          };
          showAlert({ message: 'Vitals saved successfully', color: 'success' });
          if (fromOverview) queryClient.invalidateQueries(['vitals']);
          hideVitalsModal();
          setLastVitals((prevState) => ({ ...prevState, vitals: vitalsWithId }));
          setOverviewVitals((prevState) =>
            userType === 'update'
              ? prevState?.map((vital) => (vital.id === vitals.id ? vitalsWithId : vital))
              : [vitalsWithId, ...(prevState || [])]
          );
        };
        const onError = (error) => {
          showAlert({ title: error, color: 'danger' });
        };

        await requestApi({ url: '/api/clinical_note/vitals/create', params, onSuccess, onError });
      } catch (error) {
        Honeybadger.notify(
          `Clinical note save vitals -  catch block - patientId: ${id}, error: ${error}`
        );
      }
      updateSaveButton();
    };

    const resetVitals = () => {
      setVitals({
        heart_rate: '',
        respirations: '',
        blood_pressure_systolic: '',
        blood_pressure_diastolic: '',
        weight: '',
        temperature: '',
        height: '',
        bmi: '',
        oxygen_level: '',
        notes: '',
        head_circumference: '',
        head_circumference_unit: ''
      });
    };

    useImperativeHandle(ref, () => ({
      saveVitals,
      resetVitals
    }));

    const convertToInches = (feet, inches) => {
      const totalInches = feet * 12 + inches;
      const centimeters = totalInches * 2.54;
      return centimeters;
    };

    const handleFeetAndInch = (event) => {
      let field = event.target.name;
      let value = parseFloat(event.target.value) || 0;

      if (field === 'feet') {
        value = convertToInches(value, inches);
      } else if (field === 'inches') {
        value = convertToInches(feet, value);
      }

      handleHeightValue({ target: { value: value.toString() } });
      return;
    };

    const handleHeightValue = (e) => {
      let { value: centimeters } = e.target;

      if (vitals?.weight > 0) {
        const bmi = calculateBMI({ weight: vitals?.weight, height: centimeters });

        setVitals({ ...vitals, bmi, height: centimeters });
      } else {
        setVitals({ ...vitals, height: centimeters });
      }
    };

    const convertoPounds = (pounds, ounces) => {
      return pounds + ounces / 16;
    };

    const handlePoundsAndOz = (event) => {
      let field = event.target.name;
      let value = parseFloat(event.target.value) || 0;

      if (field === 'pounds') {
        value = convertoPounds(value, ounces);
      } else if (field === 'oz') {
        value = convertoPounds(pounds, value);
      }

      handleBMIChange({ target: { name: 'weight', value: value.toString() } });
      return;
    };

    const handleBMIChange = (event) => {
      let field = event.target.name;
      let value = event.target.value;

      let removeNullText = value?.replace('null', '');

      if (field === 'weight' && vitals?.height > 0) {
        const bmi = calculateBMI({ weight: value, height: vitals?.height });

        setVitals({ ...vitals, bmi, [field]: removeNullText });
      } else {
        setVitals({ ...vitals, [field]: removeNullText });
      }
    };

    const handleOnChange = (event, key) => {
      setVitals({
        ...vitals,
        [key]: event?.target?.value,
        ...(key === 'head_circumference' && { head_circumference_unit: currentUnit })
      });
    };

    const { pounds: lastPounds, ounces: lastOunces } = separatePoundsAndOunces(lastVitals?.weight);
    const { feet: lastFeet, inches: lastInches } = separateFeetAndInches(
      cmToInch(lastVitals?.height)
    );
    const { pounds, ounces } = separatePoundsAndOunces(vitals?.weight);
    const { feet, inches } = separateFeetAndInches(cmToInch(vitals?.height));

    const handleUnitChange = (unit) => {
      setCurrentUnit(unit);

      handleOnChange({ target: { value: unit } }, 'head_circumference_unit');
    };

    return (
      <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-6 overflow-auto !pr-3 sm:gap-4">
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-heart-blue" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Heart rate</span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="heart_rate"
                id="heart_rate"
                placeholder="Add heart rate"
                rightText={<span className="text-sm font-500 text-primary-500">bpm</span>}
                value={vitals?.heart_rate}
                onChange={(event) => handleOnChange(event, 'heart_rate')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured heartbeats per minute:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.heart_rate) ? `${lastVitals?.heart_rate} bpm` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-lungs" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Respiratory rate</span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="respiratory-rate"
                id="respiratory_rate"
                placeholder="Add respiratory rate"
                rightText={<span className="text-sm font-500 text-primary-500">bpm</span>}
                value={vitals?.respirations}
                onChange={(event) => handleOnChange(event, 'respirations')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.respirations) ? `${lastVitals?.respirations} bpm` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-blood-pressure-systolic" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">
                Blood pressure (systolic)
              </span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="blood_pressure_systolic"
                id="blood_pressure_systolic"
                placeholder="Add blood pressure"
                rightText={<span className="text-sm font-500 text-primary-500">mmHg</span>}
                value={vitals?.blood_pressure_systolic}
                onChange={(event) => handleOnChange(event, 'blood_pressure_systolic')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.blood_pressure_systolic)
                ? `${lastVitals?.blood_pressure_systolic} mmHg`
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-blood-pressure-diastolic" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">
                Blood pressure (diastolic)
              </span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="blood_pressure_diastolic"
                id="blood_pressure_diastolic"
                placeholder="Add blood pressure"
                rightText={<span className="text-sm font-500 text-primary-500">mmHg</span>}
                value={vitals?.blood_pressure_diastolic}
                onChange={(event) => handleOnChange(event, 'blood_pressure_diastolic')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.blood_pressure_diastolic)
                ? `${lastVitals?.blood_pressure_diastolic} mmHg`
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-weight" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Weight</span>
              <div className="flex gap-1">
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="pounds"
                  id="pounds"
                  placeholder="Add pounds"
                  rightText={
                    <span data-qa="weight-lb" className="text-sm font-500 text-primary-500">
                      lb
                    </span>
                  }
                  name="pounds"
                  value={pounds}
                  onChange={(event) => handlePoundsAndOz(event)}
                />
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="oz"
                  id="oz"
                  placeholder="Add oz"
                  rightText={
                    <span data-qa="weight-oz" className="text-sm font-500 text-primary-500">
                      oz
                    </span>
                  }
                  name="oz"
                  value={ounces}
                  onChange={(event) => handlePoundsAndOz(event)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.weight) ? `${lastPounds} lb ${lastOunces} oz` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-temperature" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Temperature</span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="temperature"
                id="temperature"
                placeholder="Add temperature"
                rightText={<span className="text-sm font-500 text-primary-500">°F</span>}
                value={vitals?.temperature}
                onChange={(event) => handleOnChange(event, 'temperature')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.temperature) ? `${lastVitals?.temperature} °F` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-height" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Height</span>
              <div className="flex gap-1">
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="feet"
                  id="feet"
                  placeholder="Add feet"
                  rightText={<span className="text-sm font-500 text-primary-500">ft</span>}
                  name="feet"
                  value={feet}
                  onChange={handleFeetAndInch}
                />
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="inches"
                  id="inches"
                  rightText={<span className="text-sm font-500 text-primary-500">in</span>}
                  placeholder="Add inches"
                  name="inches"
                  value={inches}
                  onChange={handleFeetAndInch}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.height) ? `${lastFeet}'${lastInches} ft` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-body" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">BMI</span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="bmi"
                id="bmi"
                placeholder="Add BMI"
                rightText={
                  <span className="text-sm font-500 text-primary-500">
                    kgm<sup>2</sup>
                  </span>
                }
                name="bmi"
                value={vitals?.bmi}
                onChange={(event) => handleBMIChange(event)}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured body mass index:</p>
            <p className="text-sm text-neutral-600">
              {lastVitals?.bmi !== 'null' || lastVitals?.bmi !== '' ? (
                <span className="text-sm font-500">
                  {lastVitals?.bmi} kgm<sup>2</sup>
                </span>
              ) : (
                'N/A'
              )}
            </p>
          </div>
        </div>
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <div className="flex gap-x-4 gap-y-2">
            <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
              <Icon icon="new-oxygen-level" />
            </div>
            <div className="grid w-full gap-y-2">
              <span className="text-sm font-500 leading-3 text-neutral-800">Oxygen level</span>
              <Input
                hideNumberArrows={true}
                type="number"
                data-qa="oxygen_level"
                id="oxygen_level"
                placeholder="Add oxygen level"
                rightText={<span className="text-sm font-500 text-primary-500">%</span>}
                value={vitals?.oxygen_level}
                onChange={(event) => handleOnChange(event, 'oxygen_level')}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-2 !pt-2">
            <p className="text-sm text-neutral-600">Last measured:</p>
            <p className="text-sm text-neutral-600">
              {!isEmpty(lastVitals?.oxygen_level) ? `${lastVitals?.oxygen_level} %` : 'N/A'}
            </p>
          </div>
        </div>
        {isGrowthChartEnabled && (
          <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-ruler" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">
                  Head Circumference
                </span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="head_circumference"
                  id="head_circumference"
                  placeholder="Add head circumference"
                  name="head_circumference"
                  value={vitals?.head_circumference}
                  onChange={(event) => handleOnChange(event, 'head_circumference')}
                  currentUnit={currentUnit}
                  units={unitsOptions}
                  onUnitChange={handleUnitChange}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured head circumference:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.head_circumference)
                  ? `${vitals?.head_circumference} ${vitals?.head_circumference_unit}`
                  : 'N/A'}
              </p>
            </div>
          </div>
        )}
        <div className="!rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
          <Textarea
            data-qa="general_notes"
            label="General notes"
            transcribing
            placeholder="Type general notes here"
            value={vitals?.notes}
            onChange={(event) => {
              handleOnChange(event, 'notes');
            }}
          />
        </div>
        {/* <button
          className="hidden"
          onClick={() =>
            onSaveVitals({
              ...customFormsParams,
              vitals,
              lastVitals,
              setLastVitals,
              setOverviewVitals
            })
          }
        /> */}
      </div>
    );
  }
);

export default Vitals;
