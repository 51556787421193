import React, { useEffect, useState } from 'react';
import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useNavigate } from 'react-router-dom';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';
import { MyScribeAIContextProvider } from 'lib/context/MyScribeAI/MyScribeAIContextProvider';
import OutsideClinicalNoteExport from 'components/practice/charts/ClinicalNote/AllClinicalNotes/components/OutsideClinicalNoteExport';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { ia } from 'lib/helpers/utility';
import SignLockActionComponent from 'components/practice/charts/ClinicalNote/shared/SignLockActionComponent';
import { requestApi } from 'api/Api';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { updateDocumentSign } from 'api/DocumentSign';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';

const Actions = ({ data: clinicalNote }) => {
  const user = useRecoilValue(userState);
  const [userSign, setUserSign] = useState(false);
  const [confirmSignLock, showConfirmSignLock] = useState(false);
  const [showExportModal, setShowExportModal] = useState({ open: false, showOnlyExport: true });
  const [selectedClinicalNote, setSelectedClinicalNote] = useState({});
  const [allowToSign, setAllowToSign] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const hasUserSigned =
      clinicalNote?.document_sign?.find((row) => row.user_id == (user?.user_id ?? user.id))
        ?.status === 'signed';
    setAllowToSign(
      clinicalNote?.document_sign?.find((row) => row.user_id == (user?.user_id ?? user.id))
    );
    setUserSign(!ia(clinicalNote?.document_sign) && clinicalNote?.locked ? true : hasUserSigned);
  }, [clinicalNote?.id]);

  const onExportClick = ({ e, showOnlyExport = true }) => {
    if (!clinicalNote.id) {
      showAlert({
        title: `Clinical Note ${showOnlyExport ? 'Export' : 'Preview'} Unavailable`,
        message: `Clinical note not initiated; ${showOnlyExport ? 'export' : 'preview'} unavailable.`,
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    setSelectedClinicalNote(clinicalNote);
    setShowExportModal({ open: true, showOnlyExport });
    e?.stopPropagation();
  };

  const startClinicalNote = () => {
    window.open(
      `/portal/charts/${clinicalNote.appointment.user_id}/clinical-notes/${clinicalNote.appointment.id}`,
      '_blank'
    );
  };

  const onSuperbillClick = (e) => {
    if (!clinicalNote.id || !clinicalNote?.superbill?.id) {
      showAlert({
        title: 'Superbill Unavailable',
        message: 'Clinical note not initiated; superbill unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    window.open(
      `/portal/charts/${clinicalNote?.appointment?.user_id}/billing/superbill/${clinicalNote?.superbill?.id}`,
      '_blank'
    );
    e?.stopPropagation();
  };

  const onClaimClick = (e) => {
    if (!clinicalNote.id) {
      showAlert({
        title: 'Claim Unavailable',
        message: 'Clinical note not initiated; claim unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    navigate(
      claimType(clinicalNote?.appointment?.claim?.state, clinicalNote?.appointment?.claim?.id),
      { state: { claimID: clinicalNote?.appointment?.claim?.id } }
    );
    e?.stopPropagation();
  };

  const claimType = (claimType, claimId) => {
    let type = '';
    switch (claimType) {
      case 'complete':
        type = 'accepted';
        break;
      default:
        type = claimType;
    }

    return `/portal/beyond-billing/claims/${type}/${claimId}`;
  };

  const showSignAndLockAction = () => {
    showConfirmSignLock(true);
  };

  const options = [
    {
      label: clinicalNote?.id ? 'View Draft' : 'Start Visit',
      onClick: clinicalNote?.id
        ? (e) => onExportClick({ e, showOnlyExport: false })
        : startClinicalNote,
      icon: clinicalNote?.id ? 'new-eye' : 'new-note-add'
    },
    ...(clinicalNote?.id
      ? [
          { label: 'Continue Editing', onClick: startClinicalNote, icon: 'new-edit' },
          {
            label: 'Export',
            onClick: (e) => onExportClick({ e, showOnlyExport: true }),
            icon: 'new-export-v2'
          },
          {
            label: 'View Superbill',
            onClick: onSuperbillClick,
            icon: 'new-superbill'
          },
          { label: 'View Claim', onClick: onClaimClick, icon: 'new-claim' }
        ]
      : []),

    ...(clinicalNote?.id && allowToSign
      ? [
          {
            label: userSign ? 'Unlock' : 'Sign & Lock',
            onClick: showSignAndLockAction,
            icon: userSign ? 'new-unlockv2' : 'new-lockv2'
          }
        ]
      : [])
  ];

  const onLockNote = async ({ lockStatus = false }) => {
    try {
      const { services, ...rest } = clinicalNote;

      const newLockState = lockStatus;
      const newState = newLockState ? 'finalized' : 'draft';
      const lockHistory = rest.lock_history ? [...rest.lock_history] : [];
      lockHistory.push({ lock: newLockState, timestamp: new Date(), user_id: user.id });
      const user_id = rest?.appointment?.user_id;
      const appointment_id = rest?.appointment_id;

      let params = {
        clinicalNote: {
          ...rest,
          locked: newLockState,
          state: newState,
          lock_history: lockHistory,
          user_id,
          appointment_id
        }
      };
      const res = await requestApi({ url: '/api/clinical_note/upsert', navigate, params });

      if (res?.code === 0) {
        queryClient.invalidateQueries(['clinicalNotes']);
        queryClient.resetQueries(['claim']);
        queryClient.resetQueries(['appointment']);
      } else {
        showAlert({
          title: 'Document sign & lock failed',
          message: res?.error ?? 'Something went wrong',
          color: 'danger'
        });
      }
    } catch (error) {
      showAlert({
        title: 'Document sign & lock failed',
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }
    showConfirmSignLock(false);
  };

  const updateDocumentSignMutation = useMutation((data) => updateDocumentSign(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documentSign']);
    }
  });
  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      <ClinicalNoteContextProvider outsideClinicalNoteContext={true}>
        <Chart3dContextProvider>
          <MyScribeAIContextProvider>
            <AmbientListeningContextProvider>
              <OutsideClinicalNoteExport
                showExportModal={showExportModal}
                setShowExportModal={setShowExportModal}
                selectedClinicalNote={selectedClinicalNote}
              />
            </AmbientListeningContextProvider>
          </MyScribeAIContextProvider>
        </Chart3dContextProvider>
      </ClinicalNoteContextProvider>
      {confirmSignLock && (
        <SignLockActionComponent
          documentSignatures={clinicalNote?.document_sign}
          clinicalNoteContext={clinicalNote}
          onLockNote={onLockNote}
          userState={user}
          confirmSignLock={confirmSignLock}
          showConfirmSignLock={showConfirmSignLock}
          hasUserSigned={userSign}
          updateDocumentSignMutation={updateDocumentSignMutation}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Actions);
