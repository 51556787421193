const { io, ia, iaRa, spaceToKebabCase } = require('../../../../../../lib/helpers/utility');

exports.prepareSelection = ({
  savedSelection = {},
  initialSelection = {},
  componentsOrder = null
}) => {
  if (io(savedSelection) && io(initialSelection)) {
    const result = selectMatchingKeys({ savedSelection, initialSelection });

    const completeSelection = verifyAndCompleteObject({ selection: result, componentsOrder });

    return completeSelection;
  }

  if (io(initialSelection)) {
    const completeSelection = verifyAndCompleteObject({
      selection: initialSelection,
      componentsOrder
    });

    return completeSelection;
  }

  return;
};

const selectMatchingKeys = ({ savedSelection = {}, initialSelection = {} }) => {
  const newObj = {};

  Object.keys(savedSelection).forEach((key) => {
    if (key in initialSelection) {
      newObj[key] = savedSelection[key];
    }
  });

  return newObj;
};

const verifyAndCompleteObject = ({ selection, componentsOrder = null }) => {
  const newObject = {};

  Object.keys(selection).forEach((key) => {
    const currentValue = selection[key];

    if (ia(currentValue.formType) && !checkIfIsCustomForm(key)) delete selection[key].formType;

    const isChart3D = key === 'chart3d';

    if (isChart3D) {
      newObject[key] = face3DChartFormTypes({ currentValue });
    } else if (!ia(currentValue.formType) && checkIfIsCustomForm(key)) {
      newObject[key] = {
        ...currentValue,
        formType: createFormTypes()
      };
    } else {
      newObject[key] = currentValue;
    }

    if (!currentValue.id) {
      newObject[key] = {
        ...newObject[key],
        id: self.crypto.randomUUID()
      };
    }
  });

  const finalCheck = this.checkIfAllFormTypesFalse({
    selection: newObject,
    processType: 'CHILDREN',
    checkAll: true
  });

  const finalizeNewObject = sortByPosition(finalCheck, componentsOrder);

  return finalizeNewObject;
};

const face3DChartFormTypes = ({ currentValue = {} }) => {
  const defaultFormTypes = [
    { title: 'Front View', checked: true },
    { title: 'Right View', checked: true },
    { title: 'Left View', checked: true },
    { title: 'Items', checked: true },
    { title: 'Narrative', checked: true }
  ];

  const existingFormTypes = iaRa(currentValue?.formType);

  const processedFormTypes = defaultFormTypes.map((defaultType) => {
    const existingType = existingFormTypes.find((type) => type?.title === defaultType.title);

    if (!existingType) {
      return {
        ...defaultType,
        id: self.crypto.randomUUID()
      };
    }

    return existingType;
  });

  return {
    ...currentValue,
    formType: processedFormTypes
  };
};

const checkIfIsCustomForm = (formName) => {
  const SIMPLE_FORMS = [
    'providerSignature',
    'vitals',
    'exam',
    'test',
    'assessment',
    'plan',
    'cptCodes',
    'notes',
    'diagnosisCodes',
    'objective',
    'subjective',
    'complaints',
    'patientNeeds',
    'problemManifestationDefinition',
    'sessionQuotesAndContents',
    'timeFrames',
    'riskAndCoMorbidities',
    'referralOrders',
    'nonERXPrescriptionOrders',
    'labOrImagingOrders',
    'myscribe'
  ];

  return !SIMPLE_FORMS.includes(formName);
};

const createFormTypes = () => {
  return [
    {
      id: self.crypto.randomUUID(),
      title: 'Template',
      checked: false
    },
    {
      id: self.crypto.randomUUID(),
      title: 'Narrative',
      checked: true
    }
  ];
};

exports.checkIfAllFormTypesFalse = ({ selection, processType, key = null, checkAll = false }) => {
  let copySelection = {};

  Object.keys(selection).forEach((currrentKey) => {
    let currentObject = selection[currrentKey];
    const shouldUpdate = currrentKey === key || checkAll;

    if (shouldUpdate && processType === 'PARENT') {
      return (copySelection[currrentKey] = {
        ...currentObject,
        formType: isSectionChecked(currentObject)
      });
    }

    if (shouldUpdate && processType === 'CHILDREN') {
      return (copySelection[currrentKey] = isOneOfFormTypesChecked(currentObject));
    }

    return (copySelection[currrentKey] = currentObject);
  });

  return copySelection;
};

const isSectionChecked = (currentObject) => {
  if (!ia(currentObject.formType)) return currentObject;

  const isSectionChecked = currentObject?.checked;

  if (isSectionChecked) {
    return currentObject?.formType?.map((f) =>
      f.title !== 'Template' ? { ...f, checked: true } : { ...f, checked: false }
    );
  } else {
    return currentObject?.formType?.map((f) => ({ ...f, checked: false }));
  }
};

const isOneOfFormTypesChecked = (currentObject) => {
  if (!ia(currentObject.formType)) return currentObject;

  const isOneOfFormTypesChecked = currentObject?.formType?.some(({ checked }) => checked);

  if (isOneOfFormTypesChecked) return { ...currentObject, checked: true };
  else return { ...currentObject, checked: false };
};

exports.titleToPosition = (reference) => {
  if (!reference) return null;

  return Object.values(reference || {}).reduce((acc, refItem) => {
    if (refItem?.title) {
      acc[refItem.title] = refItem.position;
    }
    return acc;
  }, {});
};

const sortByPosition = (selection, reference = null) => {
  return Object.keys(selection)
    .sort((a, b) => {
      const posA = reference ? (reference[selection[a]?.title] ?? null) : selection[a]?.position;

      const posB = reference ? (reference[selection[b]?.title] ?? null) : selection[b]?.position;

      if (typeof posA === 'number' && typeof posB === 'number') {
        return posA - posB;
      }
      if (typeof posA === 'number') {
        return -1;
      }
      if (typeof posB === 'number') {
        return 1;
      }
      return 0;
    })
    .reduce((acc, key) => {
      const position = reference
        ? (reference[selection[key]?.title] ?? null)
        : selection[key]?.position;
      acc[key] = { ...selection[key], position };
      return acc;
    }, {});
};

exports.processAdvancedHPForms = (advancedHP, cnDisplaySettings) => {
  const advancedHPForms = advancedHP?.map((item) => {
    if (
      cnDisplaySettings?.hp?.enabled &&
      cnDisplaySettings?.sections?.hp[item.form_id || item.id]?.enabled === false
    ) {
      return null;
    }
    return item;
  });

  const filteredAdvancedHPForms = advancedHPForms?.filter((value) => io(value) && value.form);

  const getAdvancedHP = ia(filteredAdvancedHPForms)
    ? Object.fromEntries(
        filteredAdvancedHPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'hp',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedHP;
};

exports.processAdvancedSOAPForms = (advancedSOAP, cnDisplaySettings) => {
  const advancedSOAPForms = advancedSOAP?.map((item) => {
    if (cnDisplaySettings?.sections?.soap[item.form_id || item.id]?.enabled === false) {
      return null;
    }
    return item;
  });

  const filteredAdvancedSOAPForms = advancedSOAPForms?.filter((value) => io(value) && value.form);

  const getAdvancedSOAP = ia(filteredAdvancedSOAPForms)
    ? Object.fromEntries(
        filteredAdvancedSOAPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'soap',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedSOAP;
};

exports.processAdvancedCustomForms = (advancedSOAP) => {
  const filteredAdvancedSOAPForms = advancedSOAP?.filter((value) => io(value) && value.form);

  const getAdvancedSOAP = ia(filteredAdvancedSOAPForms)
    ? Object.fromEntries(
        filteredAdvancedSOAPForms.map((item) => {
          const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`;

          return [
            title,
            {
              title: advancedFormTitle(item),
              advancedType: 'customForm',
              id: self.crypto.randomUUID(),
              checked: true,
              position: null
            }
          ];
        })
      )
    : {};

  return getAdvancedSOAP;
};

const advancedFormTitle = (item) => {
  return `${item?.form?.name || item?.name} #${item?.form_id || item?.id}`;
};
