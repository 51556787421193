import React from 'react';
import {
  cmToFt,
  ia,
  io,
  isEmpty,
  separatePoundsAndOunces
} from '../../../../../../../lib/helpers/utility';
import Icon from '../../../../../../shared/Icon/Icon';
import VitalsModal from '../../../Vitals/VitalsModal';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import CreationInfo from './CreationInfo';

const VitalsOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = () => {},
  vitals = [],
  setVitals = () => {},
  vitalsModal = false,
  setVitalsModal = () => {},
  isGrowthChartEnabled = false
}) => {
  if (!cnSettings?.overview.vitals) return null;

  return (
    <SimpleAccordion
      title="Vitals"
      open={isOpen.vitals}
      // onCopy={() => handleCopy(note.id)}
      // copied={copiedIndex === note.id ? true : false}
      edit={
        <>
          {io(currentHpOverviewData?.vitals) ? (
            <Icon
              icon="new-edit-gray"
              onClick={() => setVitalsModal(true)}
              data-qa="new-edit-gray-vitals"
            />
          ) : (
            <Icon icon="new-plus" onClick={() => setVitalsModal(true)} data-qa="new-plus-vitals" />
          )}
          <VitalsModal
            lastVitals={currentHpOverviewData?.vitals}
            setLastVitals={setCurrentHpOverviewData}
            setOverviewVitals={setVitals}
            showVitalsModal={vitalsModal}
            hideVitalsModal={() => setVitalsModal(false)}
            isGrowthChartEnabled={isGrowthChartEnabled}
          />
        </>
      }
      onToggle={() => toggleOne('vitals')}>
      {ia(vitals) &&
        vitals?.map((vital, index) => {
          const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);
          return (
            <React.Fragment key={index}>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
              {(!isEmpty(vital?.blood_pressure_systolic) ||
                !isEmpty(vital?.blood_pressure_diastolic)) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Blood pressure:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.blood_pressure_systolic || 0} / ${
                      vital.blood_pressure_diastolic || 0
                    } mmHg`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.heart_rate) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Heart rate:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.heart_rate} bpm`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.respirations) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Respirations:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.respirations} bpm`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.height) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Height:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${cmToFt(vital.height)} ft`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.weight) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Weight:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${pounds} lb ${ounces} oz`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.temperature) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Temperature:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.temperature}  °F`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.bmi) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Bmi:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.bmi} kgm²`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.oxygen_level) && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Oxygen:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.oxygen_level} %`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.head_circumference) && isGrowthChartEnabled && (
                <div className="flex justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Head circumference:
                  </span>
                  <span className="break-words text-right text-sm font-400 text-primary-900">
                    {`${vital.head_circumference} ${vital?.head_circumference_unit}`}
                  </span>
                </div>
              )}

              {!isEmpty(vital?.notes) && (
                <div className="flex flex-wrap justify-between gap-x-1">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Note:
                  </span>
                  <span className="break-all text-sm font-400 text-primary-900">{vital.notes}</span>
                </div>
              )}
              <CreationInfo createdAt={vital?.created_at} createdBy={vital?.created_by?.fullName} />
            </React.Fragment>
          );
        })}
    </SimpleAccordion>
  );
};

export default VitalsOverview;
