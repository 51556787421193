import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { ia, pm } from '../../../lib/helpers/utility';
import state from '../../state';
import Button from '../Buttons/Button';
import ErrorRequirements from '../Error/Requirements';
import Spinner from '../spinner/spinner';
import SSOKindNames from './SSOKindNames';
import SSOKindSelector from './SSOKinds';
import Tippy from '@tippyjs/react';
import Icon from '../Icon/Icon';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';

export default function SSOAction({
  patientId,
  kind,
  enableEnrollPatient: enableEnrollPatientConfig = false,
  isButton = false,
  handleStartVeradigmSessionSocket = null
}) {
  const apiCallCounter = useRef(0);
  const [show, setShow] = useState({ loading: false });
  const [enableEnrollPatient, setEnableEnrollPatient] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const p = useRecoilValue(state.permissions);
  if (!pm(p, 'prescribe.read')) {
    return null;
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (patientId && apiCallCounter.current === 0) {
      // window.onfocus = () => {
      //   // do stuff on focus
      //   // updateMeds();
      // };
      getVeradigmPatient();
    }
    return () => {
      // remove onfocus
      // window.onfocus = undefined;
    };
  }, [patientId]);

  const updateMeds = async () => {
    const res = await requestApi({
      url: '/api/prescribe/medications/sync',
      params: { patientId },
      navigate
    });
    showAlert({ color: 'success', title: 'Information Synced!' });
    switch (res?.code) {
      case 0:
        break;
      default:
        break;
    }
  };

  const getVeradigmPatient = async () => {
    apiCallCounter.current += 1;
    if (apiCallCounter.current > 1) {
      return;
    }
    setShow({ ...show, loading: true });
    try {
      const res = await interimApi('/api/prescribe/getPatient', { patientId }, navigate);
      const { code, redirect, error, requirements: loadedRequirements, enrolled } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
          setEnableEnrollPatient(!enrolled);
          break;
        case 3:
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
          break;
        default:
          toast.error(error || `An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
    setShow({ ...show, loading: false });
  };

  const getSSO = async (e) => {
    handleStartVeradigmSessionSocket && handleStartVeradigmSessionSocket();
    setShow({ ...show, loading: true });
    try {
      const kind = e.target.name;
      const res = await interimApi('/api/prescribe/getSSO', { patientId, kind }, navigate);
      const { code, redirect, error, requirements: loadedRequirements, htmlBody } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          try {
            // do something with the html body
            var newWindow = open(
              '',
              '_blank',
              `height=${screen?.height || 300},width=${
                screen.width || 300
              },fullscreen=yes,popup=1,name=ePrescribe`
            );
            newWindow.document.write(htmlBody);
            newWindow.document.forms.namedItem('samlpost').submit();
            //! after entering veradigm make api calls after 1min
            //! sso kind prescribe => after 15min
            //! whenever they visit too, [clinical note, overview]
          } catch (e) {
            console.error(e);
            showAlert({
              color: 'warning',
              title: 'Veradigm Prescribe Popup Error',
              message: `Please make sure pop-ups are enabled and are not getting blocked by your browser.`
            });
          }
          break;
        case 3:
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
          break;
        default:
          toast.error(error || `An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
    setShow({ ...show, loading: false });
  };

  const enrollPatient = async () => {
    try {
      if (!patientId) {
        toast.error(`There's been an error. Please select a patient if available.`);
        return;
      }
      const res = await interimApi('/api/prescribe/enrollPatient', { patientId }, navigate);
      const { code, redirect, error, veradigm_user_guid } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          toast.success(`Successfully enrolled patient! PID:${veradigm_user_guid}`);
          break;
        default:
          toast.error(error || `An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
  };

  if (show.loading) {
    return (
      <div style={{ margin: '0.2rem' }}>
        <div>ePrescribe Loading</div>
        <Spinner />
      </div>
    );
  }
  if (isButton && ia(requirements) && !ia(requirements)) {
    return (
      <Tippy
        content={`Note: ePrescribe Requirements not met, please fulfill the requirements to enable prescribing for this patient.`}
        className="tippy-dark">
        <div
          className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
          onClick={() => (ia(requirements) ? null : getSSO())}>
          <Icon icon="new-export-bulk" />
          <span className="text-sm font-400 text-primary-900">{SSOKindNames[kind]}</span>
        </div>
      </Tippy>
    );
  }

  if (isButton) {
    return (
      <div style={{ margin: '0.2rem' }}>
        {enableEnrollPatient && enableEnrollPatientConfig && (
          <Button onClick={enrollPatient} color="success" text={'Enroll Patient'} />
        )}
        {!kind &&
          Object.keys(SSOKindSelector).map((v) => (
            <Button onClick={getSSO} name={v} color="primary" text={SSOKindNames[v]} />
          ))}
        {kind && (
          <div
            className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
            onClick={getSSO}>
            <Icon icon="new-export-bulk" />
            <span className="text-sm font-400 text-primary-900">{SSOKindNames[kind]}</span>
          </div>
          // <Button onClick={getSSO} name={'kind'} color="primary" text={SSOKindNames[kind]} />
        )}
      </div>
    );
  }

  if (ia(requirements)) {
    return (
      <ErrorRequirements
        requirements={requirements}
        title={`Note: ePrescribe Requirements not met, please fulfill the requirements to enable prescribing for this patient.`}
      />
    );
  }

  return (
    <div style={{ margin: '0.2rem' }}>
      {enableEnrollPatient && enableEnrollPatientConfig && (
        <Button onClick={enrollPatient} color="success" text={'Enroll Patient'} />
      )}
      {!kind &&
        Object.keys(SSOKindSelector).map((v) => (
          <Button onClick={getSSO} name={v} color="primary" text={SSOKindNames[v]} />
        ))}
      {kind && <Button onClick={getSSO} name={kind} color="primary" text={SSOKindNames[kind]} />}
    </div>
  );
}
