import React, { useEffect, forwardRef } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { useLog } from 'lib/hooks/queries/admin/useLog';
import Spinner from 'components/shared/spinner/spinner';

const JsonTreeView = forwardRef(({ src, onHeightChange }, ref) => {
  const id = src;

  const { data, isLoading } = useLog({
    params: { logId: id },
    dependencies: [id]
  });

  useEffect(() => {
    if (ref.current && !isLoading) {
      const height = ref.current.getBoundingClientRect().height;
      onHeightChange(height);
    }
  }, [isLoading, onHeightChange, ref]);

  return (
    <div ref={ref}>
      {isLoading ? <Spinner /> : <JsonView className="json-tree-view" src={data.log} />}
    </div>
  );
});

export default JsonTreeView;
