import React from 'react';
import { Capitalize } from '../../../lib/helpers/utility';
import cs from 'classnames';

const Header = ({ title, subtitle, children, className, showInfoInRecordings = false }) => (
  <div
    className={cs(
      'relative z-10 flex justify-between gap-4 border-0 !border-b !border-solid !border-neutral-100 bg-white !px-6',
      className
    )}
    data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
    <div
      className={cs(
        'flex items-center gap-1 !py-3 !pr-6',
        (title || subtitle) && 'shadow-[4px_0_16px_0_rgba(0,79,107,0.04)]'
      )}>
      {title && <h3 className="w-max text-lg font-500 leading-7 text-primary-800">{title}</h3>}
      {subtitle && (
        <div className="flex items-center justify-center rounded-full bg-primary-50 !py-1 px-[10px]">
          <span className="w-max text-base font-500 leading-6 text-primary-600">
            {Capitalize(subtitle)}
          </span>
        </div>
      )}
    </div>
    <div className="!py-[10px]">{children}</div>
  </div>
);

export default Header;
