import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Chart3dContextProvider } from '../../../../../lib/context/Chart3dContext/Chart3dContextProvider';
import { ClinicalNoteContextProvider } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { formatDateAndTimeZ, ia } from '../../../../../lib/helpers/utility';
import { useClinicalNote } from '../../../../../lib/hooks/queries/clinicalNotes/useClinicalNotes';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { currentPractice } from '../../../practiceState';
import AllClinicalNotesTable from './components/AllClinicalNotesTable';
import ClinicalNotePopover from './components/ClinicalNotePopover';
import ClinicalNoteStatus from './components/ClinicalNoteStatus';
import OutsideClinicalNoteExport from './components/OutsideClinicalNoteExport';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';
import { MyScribeAIContextProvider } from 'lib/context/MyScribeAI/MyScribeAIContextProvider';

const AllClinicalNotes = () => {
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const { id, appointmentId } = useParams();
  const { patient, practicePatient, setPracticePatient } = useOutletContext();
  const [showExportModal, setShowExportModal] = useState({ open: false, showOnlyExport: true });
  const [selectedClinicalNote, setSelectedClinicalNote] = useState({});

  const [filters, setFilters] = useState({
    searchTerm: '',
    page: 1,
    limit: 15,
    column: 'appointment.starts_at',
    sort: 'DESC',
    startDate: moment().subtract(1, 'year').toDate(),
    endDate: moment().utc().endOf('day').toDate(),
    pagination: true
  });

  useEffect(() => {
    if (patient?.created_at) {
      setFilters((prev) => ({
        ...prev,
        startDate: moment(patient?.created_at).utc().startOf('day').toDate()
      }));
    }
  }, [patient?.created_at]);

  moment.tz.setDefault(practice?.timezone);

  const { data, isLoading } = useClinicalNote({
    params: { ...filters, patientId: id, withDocumentSign: true },
    dependencies: [id, filters]
  });

  const allClinicalNotes = data?.clinicalNote;
  const count = data?.count?.count;

  let hpNoteOverviewRefs = {};
  const makeHpNoteOverviewRefs = () => {
    const stringArray = [
      'allergies',
      'vitals',
      'medicationHistory',
      'vaccines',
      'weightLossAdministration',
      'socialHistory',
      'familyHistory',
      'medicalHistory',
      'surgicalHistory'
    ];

    stringArray.forEach((string) => {
      hpNoteOverviewRefs[string] = useRef(null);
    });
  };

  makeHpNoteOverviewRefs();

  if (appointmentId)
    return (
      <Outlet
        context={{
          patient,
          practicePatient,
          setPracticePatient,
          hpNoteOverviewRefs
        }}
      />
    );

  return (
    <AllClinicalNotesTable
      filters={filters}
      setFilters={setFilters}
      count={count}
      allClinicalNotes={allClinicalNotes}>
      {isLoading ? (
        <tr>
          <td colSpan={3} className="bg-white">
            <Skeleton count={12} height={48} />
          </td>
        </tr>
      ) : ia(allClinicalNotes) ? (
        allClinicalNotes.map((clinicalNote, idx) => {
          return (
            <tr
              data-qa={`clinical-notes-${idx}`}
              key={idx}
              className="cursor-pointer odd:!bg-[#F8FDFF] even:!bg-white"
              onClick={() =>
                navigate(
                  `/portal/charts/${id}/clinical-notes/${
                    clinicalNote.appointment_id ?? clinicalNote.appointment.id
                  }`
                )
              }
              id={clinicalNote.appointment.id}>
              <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                {clinicalNote?.appointment?.id}
              </td>
              <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                {formatDateAndTimeZ(clinicalNote.appointment?.starts_at, practice?.timezone)}
              </td>
              <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                {clinicalNote.practitioner?.fullName}
              </td>
              <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                {ia(clinicalNote.services) ? clinicalNote.services.join(', ') : 'N/A'}
              </td>
              <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
                {ia(clinicalNote?.icd_10_codes)
                  ? clinicalNote.icd_10_codes.map((row) => row).join(', ')
                  : 'N/A'}
              </td>
              <td className="font-300 !border-0 !p-2 text-sm text-primary-900">
                <ClinicalNoteStatus data={clinicalNote} />
              </td>
              <td>
                <ClinicalNotePopover
                  lastIndex={idx === allClinicalNotes.length - 1}
                  index={idx}
                  clinicalNote={clinicalNote}
                  setSelectedClinicalNote={setSelectedClinicalNote}
                  setShowExportModal={setShowExportModal}
                />
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={3} className="!py-4 text-center">
            No clinical notes found!
          </td>
        </tr>
      )}

      {!!selectedClinicalNote && (
        <ClinicalNoteContextProvider>
          <Chart3dContextProvider>
            <MyScribeAIContextProvider>
              <AmbientListeningContextProvider>
                <OutsideClinicalNoteExport
                  showExportModal={showExportModal}
                  setShowExportModal={setShowExportModal}
                  selectedClinicalNote={selectedClinicalNote}
                  setSelectedClinicalNote={setSelectedClinicalNote}
                />
              </AmbientListeningContextProvider>
            </MyScribeAIContextProvider>
          </Chart3dContextProvider>
        </ClinicalNoteContextProvider>
      )}
    </AllClinicalNotesTable>
  );
};

export default withErrorBoundary(AllClinicalNotes);
