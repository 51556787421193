import React, { useEffect, useImperativeHandle, useState } from 'react';
import Button from '../../../../../../../../shared/Buttons/Button';
import CPTCodesRow from './CPTCodesRow';
import { useClinicalNoteContext } from '../../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Axios from '../../../../../../../../../configuredAxios';
import { ia } from '../../../../../../../../../lib/helpers/utility';
import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

export const handleCPTSearch = async (searchTerm, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await Axios.post('/api/codes/cpt/get', params);
      if (res.data.cpt) {
        return res.data.cpt.map((item) => {
          const label = item.name ? `${item.code} - ${item.name}` : `${item.code}`;
          return {
            value: item.code,
            label,
            charge: item.par_amount,
            base_unit: item.base_unit
          };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later. `);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};

export const handleModifierSearch = async (searchTerm, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await Axios.post('/api/codes/modifiers/get', params);
      if (res.data.modifiers) {
        return res.data.modifiers.map((item) => {
          return { value: item.code, label: `${item.code} - ${item.name}` };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};

const CPTCodes = ({ sectionRef = null }) => {
  const initialValue = [
    {
      code: null,
      modifiers: [],
      id: self.crypto.randomUUID()
    }
  ];

  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const [cptCodes, setCptCodes] = useState([]);
  const practice = useRecoilValue(currentPractice);

  useEffect(() => {
    setCptCodes(ia(clinicalNote?.cpt_codes) ? clinicalNote?.cpt_codes : initialValue);
  }, [clinicalNote?.cpt_codes]);

  useImperativeHandle(sectionRef, () => ({
    formData: { cpt_codes: cptCodes }
  }));

  const handleAddRow = (event) => {
    event?.preventDefault();
    setCptCodes([
      {
        code: null,
        modifiers: [],
        id: self.crypto.randomUUID()
      },
      ...cptCodes
    ]);
  };

  const handleDeleteRow = (id) => {
    const filteredCpt = cptCodes.filter((row) => row.id !== id);
    setCptCodes(filteredCpt);
    setClinicalNote({ ...clinicalNote, cpt_codes: filteredCpt });
  };

  const handleOnChange = ({ key, event, id }) => {
    const updatedArray = cptCodes.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [key]:
            event && key === 'code'
              ? {
                  ...event,
                  charge: event?.charge
                    ? parseFloat(
                        (event?.charge / 100) *
                          (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage /
                            100 || 1)
                      ).toFixed(2)
                    : 0
                }
              : event
        };
      } else {
        return row;
      }
    });

    setCptCodes(updatedArray);
    setClinicalNote({
      ...clinicalNote,
      cpt_codes: updatedArray
    });
  };

  const getCPTCodes = async (ids) => {
    if (ids) {
      try {
        const params = {
          ids
        };
        let res = await Axios.post('/api/codes/cpt/get', params);
        if (res.data.cpt) {
          setLoadedCPT(
            res.data.cpt.map((item) => {
              return { value: item.code, label: `${item.code} - ${item.name}` };
            })
          );
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
        }
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
    }
  };
  const getModifierCodes = async (ids) => {
    if (ids) {
      let arr = new Array(ids.length);
      try {
        ids.map(async (codes, index) => {
          const params = {
            ids: codes
          };
          let res = await Axios.post('/api/codes/modifiers/get', params);
          if (res.data.modifiers) {
            arr[index] = res.data.modifiers.map((item) => {
              return { value: item.code, label: `${item.code} - ${item.name}` };
            });
          } else {
            Honeybadger.notify(`There's been an unexpected error, please try again later.`);
          }
        });
        setLoadedModifiers(arr);
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
    }
  };

  const selectCPT = (e, v) => {
    setLoadedCPT(e);
    let newArr = [];
    if (v.action === 'remove-value') {
      newArr = newVisit.cpt_codes.filter((w) => v.removedValue.value !== w.cpt);
    } else if (v.action === 'select-option') {
      if (Array.isArray(newVisit.cpt_codes)) {
        newArr = [...newVisit.cpt_codes, { cpt: v.option.value, modifiers: [] }];
      } else {
        newArr.push({ cpt: v.option.value, modifiers: [] });
      }
    }
    setNewVisit({
      ...newVisit,
      cpt_codes: newArr
    });
  };

  const selectModifier = (e, index) => {
    let arr = loadedModifiers;
    arr[index] = e;
    setLoadedModifiers(arr);
    if (Array.isArray(e)) {
      let newArr = [...newVisit.cpt_codes];
      let mappedEvent = e.map((item) => {
        return item.value;
      });
      newArr[index].modifiers = mappedEvent;
      setNewVisit({ ...newVisit, cpt_codes: newArr });
    } else {
      let newArr = [...newVisit.cpt_codes];
      newArr[index].modifiers = [];
      setNewVisit({ ...newVisit, cpt_codes: newArr });
    }
  };

  return (
    <div>
      <div className="dashed-top flex justify-end !pb-2">
        <Button text="Add CPT® code" iconRight="add-circle" transparent onClick={handleAddRow} />
      </div>
      <div className="grid gap-y-4">
        {cptCodes.map((item, idx) => (
          <CPTCodesRow
            key={idx}
            className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
            onChange={handleOnChange}
            onDelete={handleDeleteRow}
            length={cptCodes.length}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default CPTCodes;
