import React from 'react';
import ProfilePicture from '../../../../Profile/ProfilePicture';
import Icon from '../../../../shared/Icon/Icon';
import { Link } from 'react-router-dom';
import { formatPhoneNumber, formatDate } from '../../../../../lib/helpers/utility';

const Patient = ({ appointment }) => {
  return (
    <div>
      <p className="text-sm font-500">
        Patient <span className="inline text-danger-600">*</span>
      </p>
      <div className="!mt-2 grid grid-cols-2">
        <Link
          data-qa="patient-overview"
          to={`/portal/charts/${appointment?.patient?.id}`}
          target="_blank"
          className="flex  items-center justify-between rounded-md bg-gradient-to-r from-primary-50 to-primary-100 !p-3">
          <div className="flex  items-center">
            <ProfilePicture
              firstName={appointment?.patient?.f_name}
              lastName={appointment?.patient?.l_name}
              id={appointment?.patient?.id}
              size={48}
            />
            <div className="!ml-3 max-w-[124px]">
              <h3 className="!m-0 block break-words text-base font-500	text-primary-900">
                {appointment?.patient?.fullName}
              </h3>
              <p className="block text-sm font-400 text-primary-500">#{appointment?.patient?.id}</p>
            </div>
          </div>
          <Icon icon="new-link" className="cursor-pointer" />
        </Link>
        <div className="flex max-w-full flex-grow flex-col overflow-hidden whitespace-normal px-3 py-1">
          <div className="flex flex-wrap items-center justify-between">
            <p className="text-sm text-neutral-500">Date of birth: </p>
            <p className="break-all text-sm text-neutral-800">
              {formatDate(appointment?.patient?.dob)}
            </p>
          </div>
          {formatPhoneNumber(appointment?.patient?.phone) && (
            <div className="flex flex-wrap items-center justify-between">
              <p className=" text-sm text-neutral-500">Phone: </p>
              <p className="break-all text-sm text-neutral-800">
                {formatPhoneNumber(appointment?.patient?.phone)}
              </p>
            </div>
          )}
          <div className="flex flex-wrap items-center justify-between">
            <p className="text-sm text-neutral-500">Email: </p>
            <p className=" break-all text-sm text-neutral-800">{appointment?.patient?.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patient;
