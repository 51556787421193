import React, { useEffect, useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import {
  TaskValidationSchema,
  changedRequester,
  getChangedTaskFields,
  getChangedValues,
  shapeTaskValues,
  taskInitialValues
} from '../lib/helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { deleteTask, updateTask } from 'api/Tasks';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { userState } from 'components/state';
import TaskForm from './TaskForm';
import { useMember } from 'lib/hooks/queries/useMember';

const PreviewTask = ({ task, previewTaskModal, hidePreviewTaskModal }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedRequester, setSelectedRequester] = useState();
  const currentUser = useRecoilValue(userState);
  const { data: membersList } = useMember({});

  const practice = useRecoilValue(currentPractice);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const members = membersList?.members.map((member) => ({
    value: member.user_id,
    label: `${member.f_name} ${member.l_name}`
  }));

  useEffect(() => {
    if (task) {
      const requesterOptions = task?.users
        ?.filter((user) => user.user_kind === 'requester')
        .map((requester) => ({
          label: `${requester?.user[0]?.f_name} ${requester?.user[0]?.l_name}`,
          value: requester?.user[0]?.id
        }));
      setSelectedRequester({
        label: requesterOptions[0]?.label,
        value: requesterOptions[0]?.value
      });
      formik.setFieldValue('users.requester', requesterOptions[0]?.value);
    }
  }, [task]);

  const { mutateAsync: mutateUpdateTaskForm, isLoading: loadingUpdate } = useMutation({
    mutationFn: (data) => updateTask(navigate, data)
  });

  const { mutateAsync: mutateDeleteTask, isLoading: loadingDelete } = useMutation({
    mutationFn: (data) => deleteTask(navigate, data)
  });

  const formik = useFormik({
    initialValues: taskInitialValues(task, selectedRequester),
    validationSchema: TaskValidationSchema,
    onSubmit: async (values) => {
      const shapedTaskObject = shapeTaskValues(values);
      const requesterChanged = changedRequester(
        formik.initialValues.users.requester,
        formik.values.users.requester
      );
      const changedOwners = getChangedValues(
        formik.initialValues.users.owners,
        formik.values.users.owners
      );
      const changedFollowers = getChangedValues(
        formik.initialValues.users.followers,
        formik.values.users.followers
      );
      let changedUsers = [...changedOwners, ...changedFollowers];

      let changedTaskFields = getChangedTaskFields(
        formik.initialValues.task,
        shapedTaskObject.task
      );
      await mutateUpdateTaskForm(
        {
          taskId: task?.id,
          taskUsers: shapedTaskObject?.users,
          changedTaskFields,
          changedUsers,
          requesterChanged
        },
        {
          onSuccess: ({ code, error }) => {
            if (code === 0) {
              queryClient.invalidateQueries(['tasks']);
              queryClient.refetchQueries(['tasks-widget']);
              showAlert({ title: 'Task updated successfully', color: 'success' });
              hidePreviewTaskModal();
            } else {
              showAlert({
                title: 'Task update failed',
                message: error ?? 'Something went wrong',
                color: 'danger'
              });
            }
          }
        }
      );
    }
  });

  const handleContinue = async () => {
    const variables = { taskId: task.id };
    await mutateDeleteTask(variables, {
      onSuccess: ({ code }) => {
        if (code === 0) {
          queryClient.invalidateQueries(['tasks']);
          queryClient.refetchQueries(['tasks-widget']);
          hidePreviewTaskModal();
          setShowConfirmationModal(false);
          showAlert({ title: 'Task Deleted successfully', color: 'success' });
        } else {
          showAlert({ title: 'Task deletion failed', color: 'danger' });
        }
      }
    });
  };

  const handleUpdate = () => formik.submitForm();

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Modal
        slideFromRight
        handleOpen={previewTaskModal}
        handleClose={hidePreviewTaskModal}
        title={task?.title}
        customStyling={{ maxWidth: '800px', minWidth: '800px' }}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              transparent
              onClick={hidePreviewTaskModal}
              text="Cancel"
              color="neutral"
              outlined
              data-qa="cancel-btn"
            />
            <div className="flex gap-3">
              <Button
                color="danger"
                className="w-[45px]"
                icon="trash"
                iconColor="white"
                iconSize={18}
                loading={loadingDelete}
                onClick={() => setShowConfirmationModal(true)}
                data-qa="delete-btn"
              />
              <Button
                onClick={handleUpdate}
                loading={loadingUpdate}
                text="Update Task"
                data-qa="update-btn"
              />
            </div>
          </div>
        }>
        <TaskForm
          formik={formik}
          members={members}
          user={currentUser}
          selectedRequester={selectedRequester}
          setSelectedRequester={setSelectedRequester}
          practice={practice}
        />

        {showConfirmationModal && (
          <Confirm
            variant="danger"
            primaryBtnTxt="Delete"
            title="Delete Task"
            icon="new-document-remove-red"
            message="Are you sure you want to delete this task?"
            handleOpen={showConfirmationModal}
            handleContinue={handleContinue}
            handleClose={() => setShowConfirmationModal(false)}
          />
        )}
      </Modal>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(PreviewTask);
