import cs from 'classnames';

import Tippy from '@tippyjs/react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import React, { Fragment, useEffect, useState } from 'react';

import { Popover, Transition } from '@headlessui/react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import { useOutletContext } from 'react-router-dom';
import { iaRa, io } from '../../../../../../../../lib/helpers/utility';
import CH3DFormsAI from '../../../components/forms/3D/3DFormsAI';
import CustomFormsAI from '../../../components/forms/CUSTOM/CustomFormsAI';
import HPFormsAI from '../../../components/forms/HP/HPFormsAI';
import INTAKEFormsAI from '../../../components/forms/INTAKE/IntakeFormsAI';
import PROGRESSFormsAI from '../../../components/forms/PROGRESS/PROGRESSFormsAI';
import SOAPFormsAI from '../../../components/forms/SOAP/SOAPFormsAI';
import { TYPE_OF_NOTES } from '../../../constants';

const List = ({ type }) => {
  const { typeOfNotes, setSelectedChatComponents } = useMyTemplateContext();
  const currentComponents = typeOfNotes?.[type]?.components || {};

  const propsForNotFoundNote = {
    icon: 'new-notev1',
    iconSize: 32,
    title: 'No data',
    titleClassName: '!mt-3',
    description: 'No data in display settings'
  };

  const onSelect = (e, item) => {
    const { checked = false } = e.target;

    setSelectedChatComponents((prevSelected) => {
      if (checked) {
        const note_item = TYPE_OF_NOTES?.find((note) => note.type === type);
        return [
          ...iaRa(prevSelected),
          {
            title: item?.path,
            forms: note_item?.forms || CustomFormsAI,
            label: note_item?.label || note_item?.title || '',
            type
          }
        ];
      } else {
        return prevSelected.filter((selectedItem) => selectedItem.title !== item.path);
      }
    });
  };

  return (
    <div className=" py-[6px] !pr-2 pl-[10px]">
      {io(currentComponents) ? (
        Object.values(currentComponents)?.map((item) => (
          <Tippy content={item?.title} placement="right" className="tippy-dark" key={item.title}>
            <div className="flex items-center rounded-md p-2 shadow-md">
              <Checkbox
                onChange={(e) => onSelect(e, item)}
                color="blue"
                label={item?.title}
                // disabled={cnSaving}
                value={item.title}
                checked={item?.checked}
                data-qa={`left-sidebar-checkbox-${item.title}`}
              />
            </div>
          </Tippy>
        ))
      ) : (
        <NotFound {...propsForNotFoundNote} />
      )}
    </div>
  );
};

const NotesPopover = ({ buttonContent, children, position = 'bottom', className = '' }) => {
  const positionClasses = {
    top: 'bottom-0 mb-2',
    bottom: 'top-full mt-2',
    left: 'right-full mr-2',
    right: 'left-full ml-2'
  };

  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button className="flex items-center focus:outline-none">
        {buttonContent}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <Popover.Panel
          className={`absolute z-10 ${positionClasses[position]} w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg`}>
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const getFormByName = (name) => {
  if (!name) return;

  const finalObj = {
    hp: HPFormsAI,
    intake: INTAKEFormsAI,
    soap: SOAPFormsAI,
    progress: PROGRESSFormsAI
    // '3d': ,
    // 'myscribe': ,
  };

  if (finalObj?.[name]) {
    return finalObj[name];
  }
  return CustomFormsAI;
};

const TypeOfNotesSidebar = ({ className }) => {
  const { enabledNotes = [] } = useOutletContext();

  const [typeOfNotes, setTypeOfNotes] = useState([]);

  useEffect(() => {
    setTypeOfNotes(
      enabledNotes?.map((item) => ({
        ...item,
        type: item?.path,
        checked: !!item?.checked,
        forms: getFormByName(item.path)
      }))
    );
  }, []);

  const selectedTypeOfNotes = typeOfNotes?.filter((note) => !!note?.checked);

  const onSelectTypeOfNotes = (e, note) => {
    setTypeOfNotes((prev) =>
      prev.map((item) => ({
        ...note,
        ...item,
        checked: note?.type === item.type ? e : item?.checked
      }))
    );
  };

  const headerButtons = [
    { id: 1, component: <Icon icon="new-reset" />, tooltip: 'Reset selection' },
    { id: 2, component: <Icon icon="save-changes" />, tooltip: 'Reset selection' },
    {
      id: 3,
      component: (
        <NotesPopover
          buttonContent={<Icon icon="new-add-square" />}
          className="p-0"
          position="bottom"
          isFixed
          options={TYPE_OF_NOTES}
          icon="new-add-square">
          {typeOfNotes.map((option) => (
            <div className="flex justify-between" key={option.type}>
              <div>{option.label}</div>
              <Switch
                onChange={(e) => onSelectTypeOfNotes(e, option)}
                checked={option?.checked}
                data-qa="note-choose-switch"
              />
            </div>
          ))}
        </NotesPopover>
      ),
      tooltip: 'Reset selection'
    }
  ];

  return (
    <div
      className={cs(
        'fixed  z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        className,
        'w-[264px]'
      )}>
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        {/* buttons */}
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              <Tippy content={button.tooltip} placement="top" className="tippy-dark">
                {button.component}
              </Tippy>
            </div>
          ))}
        </div>
      </div>
      <div className="h-full overflow-y-auto pb-28">
        {selectedTypeOfNotes?.map((selectedNote) => (
          <div key={selectedNote.type}>
            <div
              className="relative flex items-center gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 font-500 text-primary-600 transition-all
        ">
              {selectedNote?.label}
            </div>
            <List open={true} type={selectedNote.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeOfNotesSidebar;
