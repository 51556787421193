import React from 'react';
import { Capitalize } from '../../../lib/helpers/utility';
import cs from 'classnames';

const Header = ({ title, subtitle, children, className }) => (
  <div
    className={cs(
      'border-0 !border-solid !border-b !border-neutral-100 flex justify-between gap-4 !px-6 bg-white relative z-10',
      className
    )}>
    <div
      className={cs(
        'flex items-center gap-1 !py-3 !pr-6',
        (title || subtitle) && 'shadow-[4px_0_16px_0_rgba(0,79,107,0.04)]'
      )}>
      {title && <h3 className="leading-7 text-lg font-500 text-primary-800 w-max">{title}</h3>}
      {subtitle && (
        <div className="flex items-center justify-center rounded-full bg-primary-50 !py-1 px-[10px]">
          <span className="text-primary-600 font-500 leading-6 text-base w-max">
            {Capitalize(subtitle)}
          </span>
        </div>
      )}
    </div>
    <div className="!py-[10px]">{children}</div>
  </div>
);

export default Header;
