import React from 'react';
import ContactsTable from './components/ContactsTable';

export default function Contacts() {
  return (
    <div className="min-h-[calc(100vh)-40px]" data-dd-privacy="allow">
      <div className="mb-[1px] flex h-[68px] w-full items-center justify-between bg-white !px-6">
        <h3 className="relative text-primary-800">Contacts</h3>
      </div>
      <div className="!p-6">
        <ContactsTable fullTable />
      </div>
    </div>
  );
}
