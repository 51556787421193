import React, { Fragment } from 'react';
import cs from 'classnames';
import { Tab } from '@headlessui/react';

import AddProceduresProducts from './AddProceduresProducts';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../practice/practiceState';

const ProceduresProducts = ({ services, packages, products, formik, isQuote = false }) => {
  const practice = useRecoilValue(currentPractice);

  const { packages: packagesPermission = false, products: productsPermission = false } =
    practice.display_settings;

  let tabsData = [
    {
      label: 'Packages',
      hasPermission: packagesPermission,
      length: formik?.values?.packages?.length,
      content: (
        <AddProceduresProducts type="packages" label="Packages" list={packages} formik={formik} />
      )
    },
    {
      label: 'Products',
      hasPermission: productsPermission,
      length: formik?.values?.products?.length,
      content: (
        <AddProceduresProducts type="products" label="Products" list={products} formik={formik} />
      )
    }
  ];

  if (isQuote) {
    tabsData.unshift({
      label: 'Services',
      hasPermission: true,
      length: formik?.values?.services?.length,
      content: (
        <AddProceduresProducts type="services" label="Services" list={services} formik={formik} />
      )
    });
  }

  return (
    <div className="rounded-lg border border-solid border-neutral-100 !p-4 shadow-[0px_0px_8px_0px__rgba(0,79,107,0.08)]">
      <Tab.Group>
        <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
          {tabsData
            .filter((it) => it.hasPermission)
            .map((tab, i) => {
              return (
                <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                  {({ selected }) => (
                    <button
                      className={cs(
                        'flex border-x-0 border-t-0 border-b-2 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                        selected
                          ? ' !border-primary-500 font-600 text-primary-500'
                          : 'font-500 text-neutral-800'
                      )}>
                      {tab.label}

                      {tab.length > 0 && (
                        <label
                          className={cs(
                            '!ml-2 mb-0 grid !h-6 !w-6 cursor-pointer place-content-center rounded-full text-xs',
                            selected
                              ? 'bg-primary-100 text-primary-500'
                              : 'bg-neutral-100 text-neutral-700'
                          )}>
                          {tab.length}
                        </label>
                      )}
                    </button>
                  )}
                </Tab>
              );
            })}
        </Tab.List>

        <Tab.Panels>
          {tabsData
            .filter((it) => it.hasPermission)
            .map((tab, i) => (
              <Tab.Panel key={i}>{tab.content}</Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ProceduresProducts;
