import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import { Capitalize, ia } from 'lib/helpers/utility';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Axios from '../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import InfoBox from '../../../../shared/InfoBox/InfoBox';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { permissions as userPermissions, userState } from '../../../../state';
import TPAlert from '../TreatmentPlan/components/TPAlert';
import { getOverview } from '../lib/getOverview';
import { processCustomNoteTypes } from '../lib/processCustomNoteTypes';
import Footer from './Footer/Footer';
import PatientOverview from './PatientOverview/PatientOverview';
import Sidebar from './Sidebar/Sidebar';
import Note3D from './TypeNotes/3DNote/3DNote';
import HPNote from './TypeNotes/HPNote/HPNote';
import IntakeNote from './TypeNotes/IntakeNote/IntakeNote';
import MyScribeAI from './TypeNotes/MyScribeAI/MyScribeAI';
import ProgressNote from './TypeNotes/ProgressNote/ProgressNote';
import SOAPNote from './TypeNotes/SOAPNote/SOAPNote';

const CurrentNote = () => {
  const { patient, hpNoteOverviewRefs, open, navbarRef, enabledNotes } = useOutletContext();
  const { clinicalNote, loading, cnError, setCurrentHpOverviewData, customFormTypes } =
    useClinicalNoteContext();
  const { appointmentId, type, formName } = useParams();
  const wrapperRef = useRef();
  const navigate = useNavigate();
  const { user_id } = useRecoilValue(userState);
  const permissions = useRecoilValue(userPermissions);
  const [practitionerId, setPractitionerId] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(
    permissions?.clinical_note?.create ||
      permissions?.clinical_note?.update ||
      user_id === practitionerId
  );
  const [appointment, setAppointment] = useState(null);

  const typeNotes = {
    hp: HPNote,
    soap: SOAPNote,
    intake: IntakeNote,
    progress: ProgressNote,
    '3d': Note3D,
    myscribe: MyScribeAI,
    ...processCustomNoteTypes({ customFormTypes }),
    notFoundNote: NotFound
  };

  const CurrentNote = typeNotes?.[type] ?? typeNotes.notFoundNote;

  const propsForNotFoundNote = {
    icon: '404-not-found',
    title: 'Note not found',
    titleClassName: '!mt-3',
    description: (
      <div>
        The note of type <em className="text-md font-600 text-primary-900">"{Capitalize(type)}"</em>{' '}
        you are looking for does not exist.
      </div>
    ),
    createText: 'Please go back',
    onCreate: () => onNavigateBack()
  };

  const onNavigateBack = () => {
    if (!ia(enabledNotes)) {
      navigate(`/portal/charts/${patient?.id}/clinical-notes`);
      return;
    }

    navigate(
      `/portal/charts/${patient?.id}/clinical-notes/${appointmentId}/${enabledNotes?.[0].path}`
    );
  };
  useEffect(() => {
    getAppointment();
  }, []);

  useEffect(() => {
    setHasPermissions(
      permissions?.clinical_note?.create ||
        permissions?.clinical_note?.update ||
        user_id === practitionerId
    );
  }, [practitionerId]);

  useEffect(() => {
    (async () => {
      setCurrentHpOverviewData(
        await getOverview({
          patientId: patient?.id,
          appointmentId: appointmentId ?? appointment?.id,
          navigate,
          isKebab: false
        })
      );
    })();
  }, [patient, appointment]);

  // TODO: convert this with ReactQuery
  const getAppointment = async () => {
    try {
      let params = { id: appointmentId, createInvoiceIfNotExists: false };

      const { data } = await Axios.post('/api/appointment/get_one', params);

      if (data?.appointment) {
        setAppointment(data?.appointment);
        setPractitionerId(data?.appointment?.practitioner_id);
      }
    } catch (err) {
      Honeybadger.notify(`Appointment get one in current note - ${err}`);
    }
  };

  return (
    <div className="current-note grid h-full w-full" ref={wrapperRef}>
      <Sidebar navbarRef={navbarRef} appointment={appointment} />
      <div
        className={cs(
          'grow duration-200',
          open ? 'pl-[264px]' : 'pl-[128px]',
          (type === '3d' || formName === 'diagnosis-codes') && 'overflow-hidden'
        )}>
        <PatientOverview />
        <Footer open={open} forwardedRef={wrapperRef} />
        <div
          className={cs(
            'flex flex-col gap-4 !p-5',
            (type === '3d' || formName === 'diagnosis-codes') && 'h-[calc(100vh-170px)]'
          )}>
          {(cnError || !hasPermissions) && (
            <div className="flex flex-col gap-2">
              {cnError && <InfoBox color="danger" text={cnError} />}
              {!hasPermissions && !permissions && (
                <InfoBox text="The note will not be saved because you don't have the necessary permissions!" />
              )}
            </div>
          )}
          <TPAlert />
          {clinicalNote?.locked && (
            <InfoBox
              icon="new-lockv2"
              color="primary"
              text="This note is Locked. Must Unlock to make modifications."
            />
          )}
          {type && !loading ? (
            <CurrentNote
              {...propsForNotFoundNote}
              context={{
                appointment,
                patient,
                hpNoteOverviewRefs
              }}
            />
          ) : (
            <Skeleton height="calc(100vh - 214px)" />
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentNote;
