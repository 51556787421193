import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import '../Custom.scss';
import { FamilyHistory as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteCustomFormCheck } from '../lib/deleteCustomFormCheck';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import FamilyHistoryRow from './FamilyHistoryRow';

const FamilyHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative = false,
  onChange,
  fromClinicalNote = false,
  setItemModal = () => {}
}) => {
  const initalObject = {
    medicalCondition: null,
    motherSide: false,
    fatherSide: false,
    sibling: false,
    note: '',
    id: self.crypto.randomUUID()
  };
  const [familyHistory, setFamilyHistory] = useState(initialValue);
  const [prevFamilyHistory, setPrevFamilyHistory] = useState(initialValue);
  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');
  const [familyHistoryToDelete, setFamilyHistoryToDelete] = useState(null);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.familyHistory?.advancedForm
      : true;
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setFamilyHistory(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setFamilyHistory(defaultValue);
      }
    } else {
      setFamilyHistory(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(familyHistory);
  }, [familyHistory]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setFamilyHistory;
    }
  }, []);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'FamilyHistory', type: 'custom' } });
    }
  };

  const syncFamilyHistoryAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ familyHistorybject: updatedObject });
    } else {
      setFamilyHistory(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();

    const shouldStopProcess = doEmptyFormExist({
      newForm: initalObject,
      forms: familyHistory.row
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Family History',
        message: 'Empty family history exists. Please fill in the details.',
        color: 'warning'
      });
      return;
    }

    const updatedObject = {
      noDetails: false,
      row: [initalObject, ...familyHistory.row]
    };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };
  const mutateDeleteFamilyHistory = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Family History',
          message: error,
          color: 'warning'
        });
        return;
      }
      setFamilyHistory(initialValue);
      setShowConfirmModal(false);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'familyHistory'
        });
      showAlert({
        title: `Family History deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!formId && !fromCheckin) {
      showAlert({
        title: 'Family History',
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const familyHistoryRows = familyHistory?.row || [];
    if (familyHistoryRows.length === 1) {
      setShowConfirmModal(true);
      return;
    } else {
      const updatedObject = {
        ...familyHistory,
        row: familyHistory?.row.filter((row) => row.id !== id)
      };
      setShowConfirmModal(false);
      familyHistoryToDelete && setFamilyHistoryToDelete(null);
      syncFamilyHistoryAndClinicalNotes(updatedObject);
    }
  };

  const handleCheckMother = (id) => {
    const updatedArr = familyHistory.row.map((row) => {
      if (row.id === id) {
        return { ...row, motherSide: !row.motherSide };
      } else {
        return row;
      }
    });
    const updatedObject = { ...familyHistory, row: updatedArr };

    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleCheckFather = (id) => {
    const updatedArr = familyHistory.row.map((row) => {
      if (row.id === id) {
        return { ...row, fatherSide: !row.fatherSide };
      } else {
        return row;
      }
    });
    const updatedObject = { ...familyHistory, row: updatedArr };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleCheckSibling = (id) => {
    const updatedArr = familyHistory.row.map((row) => {
      if (row.id === id) {
        return { ...row, sibling: !row.sibling };
      } else {
        return row;
      }
    });
    const updatedObject = { ...familyHistory, row: updatedArr };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleChangeNote = (event, id) => {
    const updatedArr = familyHistory.row.map((row) => {
      if (row.id === id) {
        return { ...row, note: event.target.value };
      } else {
        return row;
      }
    });
    const updatedObject = { ...familyHistory, row: updatedArr };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleMedicalCondition = (event, id) => {
    const updatedArr = familyHistory.row.map((row) => {
      if (row.id === id) {
        return { ...row, medicalCondition: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...familyHistory, row: updatedArr };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleNoDetails = () => {
    const updatedObject = { ...familyHistory, noDetails: !familyHistory.noDetails };
    syncFamilyHistoryAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    familyHistorybject = familyHistory,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...familyHistorybject, narrative: event };

    setFamilyHistory(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon
            icon="new-clinical-narrative"
            className="cursor-pointer"
            data-qa="new-clinical-narrative"
          />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ familyHistorybject = familyHistory, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: familyHistorybject,
      sectionData: 'row',
      sectionName: 'Family History',
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      familyHistorybject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <div className="FamilyHistory CustomForms">
      <input type="hidden" value={JSON.stringify(familyHistory)} ref={forwardedRef} />
      {isAdvancedForm && (
        <div
          className={cs(
            'flex items-center gap-x-4',
            familyHistory?.noDetails && 'justify-between'
          )}>
          <Checkbox
            data-qa="no-notable-fmily-history"
            label="No notable family history"
            name="noDetails"
            isChecked={familyHistory.noDetails}
            onChange={handleNoDetails}
          />
          {familyHistory?.noDetails && !fromCheckin && (
            <Icon
              icon="trash"
              className="cursor-pointer"
              onClick={() => {
                if (!formId) {
                  showAlert({
                    title: 'Family History',
                    message: 'Please create the form before deleting one.',
                    color: 'warning'
                  });
                  return;
                } else {
                  setShowConfirmModal(true);
                }
              }}
            />
          )}
        </div>
      )}
      {!familyHistory.noDetails && isAdvancedForm && (
        <div className="dashed-top !mt-4">
          <div className="CustomForms__head">
            <Button
              data-qa="add-circle"
              onClick={handleAddRow}
              text="Add medical condition"
              transparent
              iconRight="add-circle"
              textClassName="text-primary-500"
            />
          </div>
          <div className="CustomForms__body">
            {familyHistory?.row?.map((item, idx) => (
              <FamilyHistoryRow
                key={item.id}
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                onChangeMC={handleMedicalCondition}
                onCheckMother={handleCheckMother}
                onCheckFather={handleCheckFather}
                fromCheckin={fromCheckin}
                initialObject={initalObject}
                lastFamilyHistories={familyHistory}
                setShowConfirmModal={setShowConfirmModal}
                setFamilyHistoryToDelete={setFamilyHistoryToDelete}
                onCheckSibling={handleCheckSibling}
                onChangeNote={handleChangeNote}
                onDelete={handleDeleteRow}
                length={familyHistory.row.length}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setFamilyHistory}
            restData={{
              className: 'w-full',
              label: '"Family History Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-family-history',
              'data-qa': 'narrative-family-history',
              name: 'Narrative-family-history',
              value: familyHistory?.narrative,
              forwardedRef: textAreaRef,
              formName: 'familyHistory'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          submitForm({
            ...customFormsParams,
            formId,
            currentFormData: familyHistory,
            defaultState: prevFamilyHistory,
            type: 'familyHistory'
          });
          setPrevFamilyHistory(familyHistory);
        }}
      />
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Family History"
          icon="trash"
          message={
            deleteCustomFormCheck(familyHistoryToDelete, familyHistory?.row)
              ? 'Are you sure you want to delete this family history?'
              : 'Are you sure you want to delete this form?'
          }
          handleContinue={() => {
            if (!formId && !fromCheckin) {
              setShowConfirmModal(false);

              const updateFamHistory = familyHistory?.row?.filter(
                (row) => row.id !== familyHistoryToDelete
              );
              const updatedObject = {
                ...familyHistory,
                narrative: '',
                row: deleteCustomFormCheck(familyHistoryToDelete, familyHistory?.row)
                  ? updateFamHistory
                  : initialValue.row
              };

              setFamilyHistory(updatedObject);
              syncFamilyHistoryAndClinicalNotes(updatedObject);
              return;
            }

            if (deleteCustomFormCheck(familyHistoryToDelete, familyHistory?.row)) {
              handleDeleteRow(familyHistoryToDelete);
            } else {
              mutateDeleteFamilyHistory.mutate();
            }
          }}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default FamilyHistory;
