import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { notificationIconType } from './lib/helper';
import { capitalize } from 'lodash';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Type = ({ data }) => {
  return (
    <div className="flex gap-2">
      <Icon color="primary" icon={notificationIconType(data?.type)} />
      <p> {capitalize(data?.type)}</p>
    </div>
  );
};

export default withErrorBoundary(Type);
