import { Honeybadger } from '@honeybadger-io/react';
import { createStickyNote, deleteStickyNote } from 'api/StickyNote';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import StickyNoteButton from 'components/shared/StickyNotesButton/StickyNotesButton';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia, iaRa } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { useStickyNotes } from 'lib/hooks/queries/sticky_note/useStcikyNotes';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import practiceState from '../practiceState';
import History from './History';

export default function StickyNotes({ patientId, className, customStyle }) {
  const { data } = usePatient({ params: { id: patientId }, dependencies: [patientId] });
  const patient = data?.patient;
  const practice = useRecoilValue(practiceState.currentPractice);
  const [note, setNote] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { device } = useUIContext();
  const ref = useRef();
  const navigate = useNavigate();
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useOutsideClick(ref, () => {
    if (!confirmDelete) {
      setShowNotes(false);
    }
  });

  const { isFetching } = useStickyNotes({
    params: { patientId: patient?.id },
    dependencies: [patient?.id],
    options: {
      enabled: !!patient?.id,
      refetchOnMount: true,
      onSuccess: (data) => {
        if (data?.code !== 0) {
          showAlert({
            title: 'Sticky Note',
            message: data?.error ?? 'There was an error fetching sticky notes',
            color: 'danger'
          });
          return;
        }

        const notes = data?.notes;

        if (!ia(notes)) {
          setNotes([]);
          setShowNotes(false);
          return;
        }

        setNotes(notes);
      }
    }
  });

  useEffect(() => {
    if (practice.display_settings?.sticky_notes?.alert && ia(notes) && device === 'desktop') {
      setShowNotes(true);
    }
  }, [notes, practice, device]);

  const handleDeleteStickyNote = async ({ id }) => {
    if (!ia(notes)) {
      showAlert({
        title: 'Sticky Note',
        message: 'No notes available to delete. Please refresh and see if problem persists.',
        color: 'danger'
      });
      return;
    }

    const allNotes = iaRa(notes);
    const leftNotes = allNotes.filter((note, index) => index != id);

    const check = allNotes.length > leftNotes.length;
    if (!check) {
      showAlert({ title: 'Sticky Note', message: 'Failed to delete sticky note', color: 'danger' });
      return;
    }

    setDeleteLoading(true);

    const onSuccess = () => {
      showAlert({
        title: 'Sticky Note',
        message: 'Sticky note deleted successfully',
        color: 'success'
      });
      setNotes(leftNotes);
      if (!leftNotes?.length) {
        setShowNotes(false);
      }
      setConfirmDelete(false);
    };

    const onError = (error) => {
      showAlert({
        title: 'Sticky Note',
        message: error || 'There was an error deleting sticky note',
        color: 'danger'
      });
      setConfirmDelete(false);
    };

    try {
      await deleteStickyNote(
        navigate,
        { patientId: patient?.id, notes: leftNotes },
        onError,
        onSuccess
      );
    } catch (error) {
      Honeybadger.notify(
        `file: /stickyNotes, method: deleteStickyNote - catch, error: ${
          error?.response?.data ?? 'An unexpected error has occurred.'
        }`
      );
    }

    setDeleteLoading(false);
  };

  const handleSaveStickyNote = async () => {
    if (!note) {
      showAlert({ title: 'Sticky Note', message: 'Please enter a note', color: 'danger' });
      return;
    }

    setSaveLoading(true);

    try {
      const onSuccess = ({ newStickyNote }) => {
        showAlert({
          color: 'success',
          title: 'Sticky Note',
          message: 'Sticky note saved successfully!'
        });
        setNotes(ia(notes) ? [...notes, newStickyNote] : [newStickyNote]);
        setNote('');
      };
      const onError = (error) => {
        showAlert({ color: 'danger', title: 'Sticky Note', message: error });
      };
      await createStickyNote(
        navigate,
        {
          patientId: patient?.id,
          note
        },
        onError,
        onSuccess
      );
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: /stickyNotes, method: saveStickyNotes - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }

    setSaveLoading(false);
  };

  const getNotes = () => {
    setShowNotes(!showNotes);
  };

  return (
    <div ref={ref} className={className} id="stickyNotes">
      {patient?.id && <StickyNoteButton onClick={getNotes} customStyle={customStyle} />}
      {showNotes && (
        <History
          notes={notes}
          note={note}
          setNote={setNote}
          setConfirmDelete={setConfirmDelete}
          setNoteToDelete={setNoteToDelete}
          onDelete={handleDeleteStickyNote}
          saveLoading={saveLoading}
          saveNote={() => handleSaveStickyNote()}
          setShowNotes={setShowNotes}
          loading={isFetching}
        />
      )}
      {confirmDelete && (
        <Confirm
          ref={ref}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Sticky Note"
          loading={deleteLoading}
          icon="trash"
          message="Are you sure you want to delete sticky note?"
          handleContinue={() => {
            handleDeleteStickyNote({ id: noteToDelete?.id });
          }}
          handleOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
}
