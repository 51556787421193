import { serviceChargeTypes } from '../../../../constants';
import * as Yup from 'yup';

export const ServiceValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  total_cost_cents: Yup.number().required('Price is required'),
  charge_type: Yup.object().required('Charge type is required'),
  time_length: Yup.number().required('Time length is required')
});

const findServiceChargeType = (value) => {
  return serviceChargeTypes.find((chargeType) => chargeType.value === value);
};

export const initialValues = (serviceToBeUpdated) => ({
  name: serviceToBeUpdated?.name || '',
  description: serviceToBeUpdated?.description || '',
  image: serviceToBeUpdated?.image || '',
  procedure_code: serviceToBeUpdated?.procedure_code || '',
  total_cost_cents: serviceToBeUpdated?.total_cost_cents || 0,
  time_length: serviceToBeUpdated?.time_length || 60,
  self_scheduling: serviceToBeUpdated?.self_scheduling || false,
  charge_type: serviceToBeUpdated?.charge_type
    ? findServiceChargeType(serviceToBeUpdated?.charge_type)
    : {
        value: 'cash',
        label: 'Assume cash amount'
      },
  standard_charge: serviceToBeUpdated?.standard_charge || 0,
  cpt: serviceToBeUpdated?.cpt || null,
  paySchedule: serviceToBeUpdated?.payerConnection || [],
  serviceId: serviceToBeUpdated?.id || null,
  practitioner_ids: serviceToBeUpdated?.practitioner_ids || [],
  color: serviceToBeUpdated?.color || '#000000',
  category_id: serviceToBeUpdated?.category_id || null,
  state_tax_rate: serviceToBeUpdated?.state_tax_rate || 0,
  local_tax_rate: serviceToBeUpdated?.local_tax_rate || 0
});
export const getFinalShapeServiceObj = (values) => ({
  name: values.name,
  description: values.description,
  procedure_code: values.procedure_code,
  total_cost_cents: Number(values.total_cost_cents),
  time_length: values.time_length,
  self_scheduling: values.self_scheduling,
  charge_type: values.charge_type.value,
  standard_charge: Number(values.standard_charge),
  image: values.image,
  practitioner_ids: values.practitioner_ids,
  color: values.color,
  category_id: values.category_id,
  state_tax_rate: values.state_tax_rate,
  local_tax_rate: values.local_tax_rate
});
