import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../api/InterimApi';
import useProcessLogin from '../../../lib/hooks/useProcessLogin';
import { currentPractice } from '../../practice/practiceState';
import Button from '../../shared/Buttons/Button';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import Input from '../../shared/Input/Input';
import { userState } from '../../state';

const LoginValidationSchema = Yup.object().shape({
  password: Yup.string().min(4, 'Too Short!').max(50, 'Too Long!').required('Password required')
});

const ReturnCheck = () => {
  const [practitioner, setPractitioner] = useState('');

  const setUser = useSetRecoilState(userState);
  const setPractice = useSetRecoilState(currentPractice);
  const processLogin = useProcessLogin();

  useEffect(() => {
    getOriginUser();
  }, []);

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    enableReinitialize: true,
    validationSchema: LoginValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await loginAsPractitioner({
        password: values.password
      });
      setSubmitting(false);
    }
  });

  const navigate = useNavigate();

  const getOriginUser = async () => {
    try {
      const result = await interimApi('/api/patient/checkin/return_origin', {}, navigate);
      const { practitioner } = result.data;

      setPractitioner(practitioner);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const loginAsPractitioner = async ({ password }) => {
    try {
      sessionStorage.removeItem('myriad-session-reference-id');
      const result = await interimApi(
        '/api/practice/return-check',
        {
          password
        },
        navigate
      );
      const { code, redirect, user, error } = result.data;

      switch (code) {
        case -1:
          navigate('/login');
          break;
        case 0:
          processLogin({ user, redirect });
          break;
        default:
          if (error) showAlert({ message: error, color: 'danger' });
          Honeybadger.notify(
            `file: checkin/ReturnCheck, method: loginAsPractitioner - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/ReturnCheck, method: loginAsPractitioner - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const logout = async () => {
    try {
      await interimApi('/api/user/logout', {}, navigate);
      sessionStorage.removeItem('myriad-session-reference-id');
      setUser({
        user_id: '',
        f_name: '',
        l_name: '',
        email: '',
        state: '',
        kind: '',
        logged_in: false
      });

      setPractice({ id: null, name: '', email: '', timezone: '', display_settings: null });

      window.localStorage.clear();

      window.Intercom('shutdown');

      navigate('/login');
    } catch (error) {
      window.localStorage.clear();
      Honeybadger.notify(
        `file: checkin/ReturnCheck, method: logout - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
      formik.handleSubmit();
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto px-4 !py-8 lg:flex lg:h-screen lg:items-center">
      <form className="mx-auto max-w-3xl rounded-lg bg-neutral-50 !p-4">
        <h1 className="text-2xl font-bold text-neutral-900 sm:text-3xl">
          Login as <strong className="text-primary-600">{practitioner}</strong>
        </h1>
        <Input
          label="Password"
          id="password"
          data-qa="password"
          type="password"
          name="password"
          className={'!my-3'}
          onKeyDown={handleKeyDown}
          onChange={formik.handleChange}
          error={formik.errors.password}
        />
        <div className="!my-3 flex gap-4">
          <Button
            data-qa="login-btn"
            text="Login"
            color="primary"
            onClick={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
          />
          <Button
            data-qa="logout-btn"
            text="Logout"
            color="secondary"
            onClick={() => logout()}
            loading={formik.isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default withErrorBoundary(ReturnCheck);
