import Honeybadger from '@honeybadger-io/js';
import { interimApi } from 'api/InterimApi';

export default async function handleCPTSearch(searchTerm, codes = [], navigate) {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await interimApi('/api/codes/cpt/get', params, navigate);
      if (res.data.cpt) {
        return res.data.cpt.map((item) => {
          return {
            value: item.code,
            label: `${item.code} - ${item.name}`,
            parAmount: item.par_amount,
            meta: item,
            base_unit: item.base_unit
          };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
}
