import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import usePageTitle from '../../../lib/hooks/usePageTitle';
import { useRecoilValue } from 'recoil';
import { userState as user_state } from 'components/state';
import { generateSettingsList } from './lib/generateSettingsList';
import SecondarySidebar from 'components/Sidebars/SecondarySidebar/SecondarySidebar';

const Settings = () => {
  usePageTitle('Settings');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userState = useRecoilValue(user_state);
  const settingsLinks = generateSettingsList(userState);

  useEffect(() => {
    if (pathname === '/portal/settings') navigate('/portal/settings/services', { replace: true });
  }, [pathname]);

  return (
    <div className="flex h-full bg-neutral-50" data-dd-privacy="allow">
      <SecondarySidebar list={settingsLinks} target="settings" title="Practice Hub" />
      <div className="flex w-full flex-col">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
