import React, { useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);
import Widget from './widgets/Widget';
import { generateUUID } from 'three/src/math/MathUtils';
import { showAlert } from '../Alert/Alert';
import Confirm from '../Modal/Confirm/Confirm';
import cs from 'classnames';
import { objMap } from '../../../lib/helpers/utility';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';

export default function Board({
  rowHeight = 155,
  header,
  wrapperClassName,
  children,
  margin = [16, 16],
  breakpoints = { lg: 1200, md: 520, sm: 0 },
  widgets,
  ...props
}) {
  const {
    layout,
    setLayout,
    data,
    defaultBoard,
    editMode,
    changedSizes,
    selectedFeature,
    board,
    cols,
    addItem,
    addedItem,
    setAddedItem,
    breakpoint,
    setBreakpoint,
    setDeleteWidgetModal,
    deleteWidgetModal
  } = useBoardContext();

  useEffect(() => {
    if (addedItem) {
      var timeout;
      const section = document.getElementById(addedItem);
      if (section) {
        section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        timeout = setTimeout(() => {
          setAddedItem(null);
        }, 3000);
      }
      return () => clearTimeout(timeout);
    }
  }, [addedItem]);

  const onLayoutChange = (newLayout) => {
    setTimeout(() => {
      setLayout({
        ...layout,

        [breakpoint]: layout[breakpoint].map((item) => {
          const newItem = newLayout.find((newItem) => newItem.i === item.i);
          return newItem ? { ...item, ...newItem } : item;
        })
      });
    }, 50);
  };

  const onBreakpointChange = (newBreakpoint) => {
    if (!Object.keys(layout).includes(newBreakpoint)) {
      setLayout({
        ...layout,
        [newBreakpoint]: layout[Object.keys(layout)[0]]
      });
    }
    setBreakpoint(newBreakpoint);
  };

  const filterItem = (item, id) =>
    item
      ? {
          ...item,
          i: item.i || id || generateUUID(),
          x: item.x || 0,
          y: item.y || 0,
          w: item.w || item.minW || 2,
          h: item.h || item.minH || 1,
          visible: true
        }
      : null;

  const removeItem = () => {
    setLayout((prevLayout) => {
      return objMap(prevLayout, (breakpoint, layout) => {
        return layout.filter((layoutItem) => layoutItem.i !== deleteWidgetModal);
      });
    });
    showAlert({
      title: 'Widget Deleted',
      message: 'Widget deleted successfully',
      color: 'success'
    });
    setDeleteWidgetModal(null);
  };

  const onDrop = (dragLayout) => {
    const id = generateUUID();

    const defaultItem = defaultBoard?.find((layoutItem) => layoutItem.name === selectedFeature);
    if (
      !defaultItem.multiple &&
      layout.lg.find((layoutItem) => layoutItem.name === selectedFeature)
    )
      return showAlert({
        message: "Can't add multiple of this widget!",
        color: 'danger'
      });

    setLayout((prevLayout) =>
      objMap(prevLayout, (br, layout) => {
        if (br === breakpoint) {
          return dragLayout.map((item) => {
            const prevItem = layout.find((prevItem) => prevItem.i === item.i);

            return prevItem
              ? { ...prevItem, ...item, isDraggable: null }
              : {
                  ...defaultBoard?.find((defaultItem) => defaultItem.name === selectedFeature),
                  ...item,
                  isDraggable: null,
                  visible: true,
                  i: id
                };
          });
        } else {
          return layout;
        }
      })
    );

    setAddedItem(id);

    Object.keys(layout).forEach((br) => {
      if (br !== breakpoint) {
        addItem(selectedFeature, br, id);
      }
    });
  };

  return (
    <div className={cs('relative flex h-full flex-col', header && 'overflow-hidden')}>
      {children}
      {layout?.[breakpoint]?.length > 0 && (
        <div className={cs('pb-5', wrapperClassName)}>
          <ResponsiveGridLayout
            margin={margin}
            className="layout z-10 px-1 pb-1"
            onDrop={onDrop}
            layouts={layout}
            breakpoints={breakpoints}
            cols={cols}
            isDraggable={editMode}
            isResizable={editMode}
            rowHeight={rowHeight}
            onLayoutChange={onLayoutChange}
            isDroppable={true}
            droppingItem={
              (defaultBoard &&
                filterItem(defaultBoard?.find((item) => item.name == selectedFeature))) ||
              null
            }
            onBreakpointChange={onBreakpointChange}>
            {layout &&
              layout[breakpoint]?.map((item) => (
                <div key={item.i} id={item.i}>
                  <Widget
                    item={item}
                    board={board}
                    customizations={data?.customizations}
                    editMode={editMode}
                    layout={layout?.[breakpoint]}
                    addedItem={addedItem}
                    changedSizes={changedSizes}
                    widgets={widgets}
                    setDeleteWidgetModal={setDeleteWidgetModal}
                    {...props}
                  />
                </div>
              ))}
          </ResponsiveGridLayout>
        </div>
      )}
      {deleteWidgetModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Widget"
          icon="trash"
          message="Are you sure you want to delete Widget?"
          handleContinue={removeItem}
          handleOpen={deleteWidgetModal !== null}
          handleClose={() => setDeleteWidgetModal(null)}
        />
      )}
    </div>
  );
}
