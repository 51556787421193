import React, { useEffect } from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import StepsChart from '../StepsChart';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import { getCurrentStep, getStepsLeft } from '../../lib/helpers';
import GetStarted from './components/GetStarted';
import Input from 'components/shared/Input/Input';
import Button from 'components/shared/Buttons/Button';
import Questionnaire from './components/Questionnaire';
import { useNavigate } from 'react-router-dom';

const NameAndAutomation = ({ currentBreadcrumb, handleFormView }) => {
  const { breadCrumbs, formik, setBreadCrumbs, buildButtonText, setBuildButtonText, isEdit } =
    usePacketBuilderContext();
  const navigate = useNavigate();
  const currentBreadcrumbStep = getCurrentStep(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);
  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  useEffect(() => {
    const currentBreadCrumbIndex = breadCrumbs.findIndex((c) => c.active);
    let nextIndex = currentBreadCrumbIndex + 1;
    while (nextIndex < breadCrumbs.length) {
      const nextBreadCrumb = breadCrumbs[nextIndex];
      if (
        (!nextBreadCrumb.hidden && nextBreadCrumb.view !== 'forms') ||
        formik?.values?.patient_creation
      ) {
        setBuildButtonText('Start Building');
        break;
      } else {
        setBuildButtonText('Skip Automation & Start Building');
      }
      nextIndex++;
    }
  }, [breadCrumbs, formik?.values?.patient_creation]);

  const navigateView = () => {
    setBreadCrumbs((prevBreadCrumbs) => {
      const currentIndex = prevBreadCrumbs.findIndex((c) => c.active);
      const newBreadCrumbs = prevBreadCrumbs.map((crumb, index) => {
        if (index === currentIndex) {
          return { ...crumb, active: false, isFinished: true };
        }
        return crumb;
      });

      let nextIndex = currentIndex + 1;

      while (nextIndex < newBreadCrumbs.length && newBreadCrumbs[nextIndex].hidden) {
        nextIndex++;
      }
      if (nextIndex < newBreadCrumbs.length) {
        newBreadCrumbs[nextIndex] = { ...newBreadCrumbs[nextIndex], active: true, hidden: false };
      }

      return newBreadCrumbs;
    });
  };
  const addNewForm = () => {
    navigate('/portal/settings/forms', {
      state: { openModal: true, packet_id: formik?.values?.id }
    });
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div>
        {!isEdit && (
          <StepsChart
            chartData={chartData}
            title="Packet Configuration"
            description="Tip: For automating Intake forms, use service type or tag. Select a new patient service type or tag for these forms.
For forms needed before each appointment, automate them by provider."
            innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
          />
        )}
        <div className="flex h-full flex-col justify-between px-9 py-[15px]">
          <div>
            {!isEdit && <GetStarted />}
            <Input
              label="Packet Name"
              placeholder="Enter packet name"
              id="name"
              data-qa="packet-name"
              name="name"
              value={formik?.values?.name}
              onChange={formik.handleChange}
              error={formik?.touched?.name && formik?.errors?.name}
            />
            <hr className="my-9 h-[1px] border-0 bg-neutral-100" />
          </div>

          <Questionnaire handleFormView={handleFormView} />

          {!isEdit && (
            <>
              <div className="flex items-center justify-center !pb-16 text-center">
                <Button
                  data-qa={buildButtonText}
                  text={buildButtonText}
                  disabled={!formik.values?.name}
                  onClick={navigateView}
                />
              </div>
              <div className="flex items-center justify-center text-center">
                <p className="pr-4 text-sm font-500 text-primary-900">
                  Create new forms to add in the form packet:
                </p>
                <Button
                  type="secondary"
                  icon="add-square"
                  text="Build New Form"
                  transparent
                  className="!border-none !bg-primary-50"
                  onClick={addNewForm}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(NameAndAutomation);
