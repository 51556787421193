import { Popover } from '@headlessui/react';
import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useRecoilState } from 'recoil';
import { dosesList, vaccinesList } from '../../../../../constants';
import schedulingState from '../../../../Patient/self_scheduling/schedulingState';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Input from '../../../Input/Input';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import CustomFormPopover from '../components/CustomFormPopover';

const VaccinesRow = ({
  vaccine,
  doses,
  lastDose,
  fromCheckin,
  calendarPopover,
  lastDoseDisabled,
  notSure,
  index,
  setCurrentRowIdx,
  forwardedRef,
  note,
  id,
  onDelete,
  onChange,
  onChangePopover,
  onCheckNotSure,
  length,
  className
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);

  moment.tz.setDefault(appointmentData?.practice_timezone);

  useEffect(() => {
    onChangePopover(false, id);
  }, [lastDose]);

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <div className="grid w-full items-center gap-4">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(130px,1fr))] items-end gap-4 md:grid-cols-[repeat(auto-fit,minmax(200px,1fr))]">
          <Select
            inputId="select-vaccine"
            label="Vaccine"
            options={vaccinesList}
            value={vaccine}
            name="vaccine"
            onChange={(event) => onChange('vaccine', event, id)}
            isCreatable
            placeholder="Type and select vaccine"
          />
          <Select
            inputId="doses-taken"
            label="Doses taken"
            options={dosesList}
            value={doses}
            name="doses"
            onChange={(event) => onChange('doses', event, id)}
            placeholder="How many doses?"
          />

          <CustomFormPopover
            button={
              <Input
                inputWrapperClassName="!flex"
                data-qa="date-of-last-dose"
                label="Date of last dose"
                rightIcon="new-calendar-gray"
                value={moment.utc(lastDose).format('MMM Do, YYYY')}
                readOnly
                disabled={lastDoseDisabled}
              />
            }
            panel={
              <Calendar
                data-qa="date-lastdose"
                date={new Date(lastDose)}
                onChange={(event) => {
                  onChange('lastDose', moment.utc(event).add('12', 'h').format('YYYY-MM-DD'), id);
                }}
              />
            }
          />

          <Checkbox
            data-qa="not-sure"
            className="!mb-2"
            label="Not sure"
            name="notSure"
            isChecked={notSure}
            onChange={() => onCheckNotSure(id)}
          />
        </div>
        <Textarea
          label="Notes"
          placeholder="Add note here"
          id="note"
          data-qa="note"
          className="w-full"
          name="note"
          value={note}
          onChange={(event) => onChange('note', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
      </div>
      {(!fromCheckin || length > 1) && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default VaccinesRow;
