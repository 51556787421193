import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import SuperbillTable from './components/SuperbillTable';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { defaultFilters, superbillTableColumns } from './lib';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

export default function Superbills() {
  const { superbill } = useParams();

  const practice = useRecoilValue(currentPractice);

  if (superbill) return <Outlet />;

  const cols = superbillTableColumns(practice.timezone);
  return (
    <TableContextProvider cols={cols} pagination name="superbill" defaultFilters={defaultFilters}>
      <SuperbillTable />
    </TableContextProvider>
  );
}
