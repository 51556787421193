import React from 'react';
import Icon from '../../../../../../../../shared/Icon/Icon';
import Box from '../../../../../shared/Box';
import Select from '../../../../../../../../shared/Select/Select';
import { handleCPTSearch, handleModifierSearch } from './CPTCodes';
import { useClinicalNoteContext } from '../../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import cs from 'classnames';

const CPTCodesRow = ({ code, modifiers, id, onChange, onDelete, length, className }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const disabled = !Boolean(code);

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <Box className="w-full">
        <Select
          label="CPT® code"
          placeholder="Type and select CPT®code"
          name="code"
          id="code"
          disabled={clinicalNote?.locked}
          isAsync
          isClearable
          value={code}
          loadOptions={handleCPTSearch}
          menuPortalTarget={document.body}
          onChange={(event) =>
            onChange({
              event,
              key: 'code',
              id
            })
          }
        />
        <div className={cs(disabled && 'cursor-not-allowed')}>
          <Select
            label={code ? `Modifiers - ${code.value}` : `Modifiers`}
            placeholder="Type and select CPT®code first"
            name="modifiers"
            id="modifiers"
            disabled={disabled || clinicalNote?.locked}
            isAsync
            isMulti
            isClearable
            value={modifiers}
            loadOptions={handleModifierSearch}
            menuPortalTarget={document.body}
            onChange={(event) =>
              onChange({
                event,
                key: 'modifiers',
                id
              })
            }
          />
        </div>
      </Box>
      {length > 1 && <Icon icon="trash" onClick={() => onDelete(id)} />}
    </div>
  );
};

export default CPTCodesRow;
