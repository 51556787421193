import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { permissions } from 'components/state';
import { useRecoilValue } from 'recoil';
import Welcome from './components/Welcome';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import Icon from 'components/shared/Icon/Icon';
import { generateEnabledNoteTypes } from '../lib/generateNoteTypes';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const MyScribeAI = () => {
  const { cnDisplaySettings, customFormTypes, clinicalNote } = useClinicalNoteContext();
  const { is_admin } = useRecoilValue(permissions);
  const navigate = useNavigate();
  const location = useLocation();
  const { breadcrumb, favoriteTemplateId } = useMyScribeAIContext();
  const [pathname, setPathname] = useState();
  const { pushBreadcrumb, setBreadcrumbs } = breadcrumb;
  const { patient } = useOutletContext();

  useEffect(() => {
    if (!clinicalNote?.user_id || !clinicalNote?.appointment_id) return;
    setPathname(
      `/portal/charts/${clinicalNote.user_id}/clinical-notes/${
        clinicalNote.appointment_id
      }/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`
    );
  }, [clinicalNote.appointment_id, clinicalNote.user_id, favoriteTemplateId]);

  useEffect(() => {
    if (!pathname) return;

    pushBreadcrumb({
      label: 'MyScribe AI',
      icon: <Icon icon="stars-ai" />,
      name: 'myscribe-ai',
      path: favoriteTemplateId ? pathname.replace(`/${favoriteTemplateId}`, '') : pathname
    });
  }, [pathname]);

  if (!is_admin) {
    navigate(-1);
  }

  return (
    <div>
      <div>
        <Outlet
          context={{
            patient,
            enabledNotes: generateEnabledNoteTypes({ cnDisplaySettings, customFormTypes })
          }}
        />
      </div>
      <Welcome />;
    </div>
  );
};

export default withErrorBoundary(MyScribeAI);
