import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Box from '../../../../practice/charts/ClinicalNote/shared/Box';
import { userState } from '../../../../state';
import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';
import Input from '../../../Input/Input';
import '../Custom.scss';
import { WeightLossAdministration as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import WeightLossAdministrationRow from './WeightLossAdministrationRow';

const WeightLossAdministration = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative = false,
  fromClinicalNote = false,
  setItemModal = () => {}
}) => {
  const initialObject = {
    medication: null,
    doses: '',
    dosesUnit: 'mg',
    location: '',
    typeOfInjection: '',
    notes: '',
    date: new Date().toISOString().slice(0, 10),
    weight: '',
    calendarPopover: false,
    id: self.crypto.randomUUID()
  };

  const [weightLossAdministration, setWeightLossAdministration] = useState(initialValue);
  const [prevWeightLossAdministration, setPrevWeightLossAdministration] = useState(initialValue);
  const {
    clinicalNote,
    cnDisplaySettings,
    setOverviewData,
    setCurrentHpOverviewData,
    advancedFormNarrativeRef
  } = useClinicalNoteContext() || {};
  const user = useRecoilValue(userState);
  const [disabled, setDisabled] = useState(clinicalNote?.locked);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.weightLossAdministration?.advancedForm
      : true;
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setWeightLossAdministration(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setWeightLossAdministration(defaultValue);
      }
    } else {
      setWeightLossAdministration(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setWeightLossAdministration;
    }
  }, []);

  const syncAllergiesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ weightLossAdministrationObject: updatedObject });
    } else {
      setWeightLossAdministration(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();

    const shouldStopProcess = doEmptyFormExist({
      newForm: initialObject,
      forms: weightLossAdministration?.row
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Weight Loss Administration',
        message: 'Empty weight loss administration exists. Please fill in the details.',
        color: 'warning'
      });

      return;
    }

    const updatedObject = {
      row: weightLossAdministration?.row.unshift(initialObject),
      ...weightLossAdministration
    };
    syncAllergiesAndClinicalNotes(updatedObject);
  };
  const mutateDeleteFamilyHistory = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'WeightLossAdministration',
          message: error,
          color: 'warning'
        });
        return;
      }
      setWeightLossAdministration(initialValue);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'weightLossAdministration'
        });
      showAlert({
        title: `WeightLossAdministration deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!formId) {
      showAlert({
        title: 'Weight Loss ',
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const weightLossAdministrationRows = weightLossAdministration?.row || [];
    if (weightLossAdministrationRows.length === 1) {
      mutateDeleteFamilyHistory.mutate();
    }
    const updatedObject = {
      ...weightLossAdministration,
      row: weightLossAdministration?.row.filter((row) => row.id !== id)
    };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleOnChange = (key, event, id) => {
    const updatedObject = {
      ...weightLossAdministration,
      row: weightLossAdministration?.row.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: event };
        } else {
          return row;
        }
      })
    };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleWeight = (event, key) => {
    const updatedObject = { ...weightLossAdministration, [key]: event.target.value };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    weightLossAdministrationObject = weightLossAdministration,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...weightLossAdministrationObject, narrative: event };

    setWeightLossAdministration(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    weightLossAdministrationObject = weightLossAdministration,
    scrollable = false
  } = {}) => {
    const narrativeOptions = {
      data: weightLossAdministrationObject,
      sectionData: 'row',
      sectionName: 'Weight Loss Administration',
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      weightLossAdministrationObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <div className="WeightLossAdministration CustomForms">
      <input type="hidden" value={JSON.stringify(weightLossAdministration)} ref={forwardedRef} />
      {isAdvancedForm && (
        <React.Fragment>
          <Box className="grid-cols-2">
            <Input
              label="Initial weight"
              placeholder="Add initial weight"
              value={weightLossAdministration?.initialWeight}
              onChange={(event) => handleWeight(event, 'initialWeight')}
              disabled={disabled}
              rightText="lbs"
            />
            <Input
              label="Goal weight"
              placeholder="Add goal weight"
              value={weightLossAdministration?.goalWeight}
              onChange={(event) => handleWeight(event, 'goalWeight')}
              disabled={disabled}
              rightText="lbs"
            />
          </Box>
          <div className="CustomForms__head dashed-top !mt-4 bg-white !pt-1">
            <Button onClick={handleAddRow} text="Add medication" transparent icon="add-circle" />
          </div>

          <div className="CustomForms__body gap-y-6">
            {weightLossAdministration?.row?.map((item, idx) => (
              <WeightLossAdministrationRow
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                key={item.id}
                index={idx}
                onChange={handleOnChange}
                onDelete={handleDeleteRow}
                disabled={disabled}
                length={weightLossAdministration?.row.length}
                {...item}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setWeightLossAdministration}
            restData={{
              className: 'w-full',
              label: 'Weight Loss Administration Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-weight-loss-administration',
              'data-qa': 'narrative-weight-loss-administration',
              name: 'Narrative-weight-loss-administration',
              value: weightLossAdministration?.narrative,
              forwardedRef: textAreaRef,
              formName: 'weightLossAdministration'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          submitForm({
            ...customFormsParams,
            formId,
            currentFormData: weightLossAdministration,
            defaultState: prevWeightLossAdministration,
            type: 'weightLossAdministration'
          });
          setPrevWeightLossAdministration(weightLossAdministration);
        }}
      />
    </div>
  );
};

export default WeightLossAdministration;
