import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Switch from '../Switch/Switch';

import { usePreferences } from 'lib/hooks/queries/usePreferences';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { upsertPreferences } from '../../../api/Preferences';
import { useTableContext } from '../../../lib/context/TableContext/TableContext';
import { camelCaseToReadableFormat } from '../../../lib/helpers/utility';
import Icon from '../Icon/Icon';
import RadioButton from '../RadioButton/RadioButton';
import { useQueryClient } from '@tanstack/react-query';

export default function DisplayButton() {
  const { limit, setLimit, setColDefs, colDefs, name, formatValue, pagination, setPage } =
    useTableContext();
  const limits = [15, 25, 50, 100, 500];
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(colDefs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColDefs(items);
    await upsertPreferences(navigate, { name, value: formatValue(items, limit) });
  };

  const togglePinned = async (direction, colIndex) => {
    const newColDefs = colDefs.map((col, index) => {
      if (index === colIndex) {
        if (col.pinned === direction) {
          col.pinned = false;
        } else {
          col.pinned = direction;
        }
      }
      return col;
    });
    setColDefs(newColDefs);
    await upsertPreferences(navigate, { name, value: formatValue(newColDefs, limit) });
    queryClient.invalidateQueries(['table-limit']);
  };

  const handleChangeLimit = async (item) => {
    await upsertPreferences(navigate, {
      name,
      value: formatValue(colDefs, item)
    });
    setLimit(item);
    setPage(1);
    queryClient.invalidateQueries(['table-limit']);
  };

  const changedColumnsNumber = useCallback(() => {
    return Object.values(colDefs).filter((value) => {
      return value.hide;
    }).length;
  }, [colDefs]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={cs(
              'flex items-center justify-center gap-2 rounded-lg bg-white !px-[11px] !py-[6px]',
              open
                ? '!bg-primary-700'
                : ' border border-solid border-neutral-100 shadow-[0px_0px_8px_0px_#1032540A]'
            )}>
            <Icon size={16} color={open ? 'white' : 'neutral'} icon="list-unordered"></Icon>
            <p className={cs('text-sm !font-medium text-neutral-600', open && '!text-white')}>
              Display{' '}
              {changedColumnsNumber() !== 0 && (
                <div className="inline-block">
                  <span className="flex h-[20px] w-[20px] items-center justify-center rounded-[4px] bg-primary-400 text-xs font-700 text-white">
                    {changedColumnsNumber()}
                  </span>
                </div>
              )}
            </p>
            <Icon
              icon="new-chevron-down"
              color={open ? 'white' : 'neutral'}
              className={cs('transition-all', open && 'rotate-180 ')}
            />
          </Popover.Button>
          <Popover.Panel className="absolute right-0 top-11 z-10 min-w-[300px] rounded-lg  border border-gray-200 bg-white !px-4 !py-3 shadow-lg">
            {pagination && (
              <>
                <p className="!mb-2 text-neutral-600">Rows</p>
                <div className="flex">
                  {limits?.map((item, index) => (
                    <RadioButton
                      key={index}
                      label={item}
                      id={item}
                      name="limit"
                      value={item}
                      className={cs(
                        '!cursor-pointer !pl-[24px] !pr-[16px] !leading-[22px] before:!h-4 before:!w-4 after:!left-[5px] after:!top-[11px] after:!h-2 after:!w-2'
                      )}
                      isSelected={limit === item}
                      onChange={() => handleChangeLimit(item)}
                    />
                  ))}
                </div>
              </>
            )}
            <div
              className={cs(
                '!mb-2 flex justify-between !pt-2 ',
                pagination && '!mt-3  border !border-x-0 !border-b-0 !border-neutral-100'
              )}>
              <p className="text-neutral-600 ">Columns</p>
              <p className="!mr-1 text-neutral-600">Pin</p>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                  <div
                    className="flex flex-col"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {colDefs?.map((col, index) => (
                      <Draggable key={col.field} draggableId={col.field} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex items-center justify-between  gap-2 !py-2">
                            <div className="flex gap-1">
                              <Icon icon="new-sort"></Icon>
                              <p className="text-primary-900">
                                {col.headerName ||
                                  camelCaseToReadableFormat(
                                    col.field !== 'actions' ? col.field : 'Actions'
                                  )}
                              </p>
                            </div>
                            <div className="flex">
                              <Switch
                                checked={!col.hide}
                                onChange={() =>
                                  setColDefs(
                                    colDefs.map((item, i) => {
                                      if (i === index) {
                                        item.hide = !item.hide;
                                      }
                                      return item;
                                    })
                                  )
                                }></Switch>
                              <div
                                onClick={() => togglePinned('left', index)}
                                className={cs(
                                  'ml-2 flex items-center justify-center rounded-l-full border !border-y-0 !border-l-0 bg-gray-100 pl-[6px] pr-[3px] hover:bg-gray-200',
                                  col.pinned === true ||
                                    (col.pinned === 'left' && '!bg-primary-600')
                                )}>
                                <Icon
                                  icon="new-chevron-left"
                                  stroke
                                  color={
                                    col.pinned === true || col.pinned === 'left' ? 'white' : 'gray'
                                  }
                                  size={12}></Icon>
                              </div>
                              <div
                                onClick={() => togglePinned('right', index)}
                                className={cs(
                                  'flex items-center justify-center rounded-r-full bg-gray-100 pl-[3px] pr-[6px] hover:bg-gray-200',
                                  col.pinned === 'right' && '!bg-primary-600'
                                )}>
                                <Icon
                                  icon="new-chevron-left"
                                  className={cs('rotate-180 transform')}
                                  stroke
                                  color={col.pinned === 'right' ? 'white' : 'gray'}
                                  size={12}></Icon>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
