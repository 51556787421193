import React, { useState } from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const FooterButtons = ({ isLastStep, showNavigationButtons, nextTo, backTo }) => {
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');
  const { enableNextTo, formik, setBreadCrumbs, handleClose, enableSubmit, isEdit } =
    usePacketBuilderContext();

  const navigateView = (direction) => {
    setBreadCrumbs((prevBreadCrumbs) => {
      const currentIndex = prevBreadCrumbs.findIndex((c) => c.active);
      let newBreadCrumbs = prevBreadCrumbs.map((crumb, index) => {
        if (index === currentIndex) {
          return { ...crumb, active: false, isFinished: true };
        }
        return crumb;
      });

      let nextIndex = currentIndex;

      if (direction === 'next') {
        nextIndex++;
        while (nextIndex < newBreadCrumbs.length && newBreadCrumbs[nextIndex].hidden) {
          nextIndex++;
        }
      } else if (direction === 'back') {
        nextIndex--;
        while (nextIndex >= 0 && newBreadCrumbs[nextIndex].hidden) {
          nextIndex--;
        }
      }

      if (nextIndex >= 0 && nextIndex < newBreadCrumbs.length) {
        newBreadCrumbs[nextIndex] = { ...newBreadCrumbs[nextIndex], active: true, hidden: false };
      }

      return newBreadCrumbs;
    });
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleEdit = () => {
    if (isEdit) {
      const { automation, provider_ids, service_ids, tag_ids, form_ids } = formik.values;
      if (automation?.providers && provider_ids?.length === 0) {
        setMessage(
          `Provider automation has been enabled, but no providers have been added. Please add at least one provider to enable automation.`
        );
        setOpenModal(true);
        return;
      }

      if (automation?.services && service_ids?.length === 0) {
        setMessage(
          `Service automation has been enabled, but no services have been added. Please add at least one service to enable automation.`
        );
        setOpenModal(true);
        return;
      }

      if (automation?.tags && tag_ids?.length === 0) {
        setMessage(
          `Tag automation has been enabled, but no tags have been added. Please add at least one tag to enable automation.`
        );
        setOpenModal(true);
        return;
      }
      if (automation?.forms && form_ids?.length === 0) {
        setMessage(
          `Forms automation has been enabled, but no forms have been added. Please add at least one form to enable automation.`
        );
        setOpenModal(true);
        return;
      }
    }
    formik.handleSubmit();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex  w-full items-center justify-between">
        {showNavigationButtons && !isEdit ? (
          <Button
            disabled={!backTo}
            outlined
            color="neutral"
            text="Back"
            data-qa="back-btn"
            onClick={() => navigateView('back')}
          />
        ) : (
          <Button color="neutral" text="Close" outlined onClick={handleClose} data-qa="close-btn" />
        )}
        <div className="flex-end flex gap-4">
          {showNavigationButtons && nextTo && !isLastStep && !isEdit && (
            <Button
              disabled={!enableNextTo}
              text="Continue"
              onClick={() => navigateView('next')}
              data-qa="continue-btn"
            />
          )}
          {isLastStep && !isEdit && (
            <Button
              text="Submit"
              disabled={!enableSubmit}
              onClick={() => handleSubmit()}
              data-qa="submit-btn"
            />
          )}
          {isEdit && (
            <Button
              text="Save changes"
              disabled={!enableSubmit}
              onClick={handleEdit}
              data-qa="save-changes-btn"
            />
          )}
        </div>
      </div>
      {openModal && (
        <Confirm
          icon="new-document"
          title="Automation For Packet"
          primaryBtnTxt="Save anyway"
          handleOpen={openModal}
          handleClose={() => setOpenModal(false)}
          handleContinue={() => {
            formik.handleSubmit();
            setOpenModal(false);
          }}
          message={message}
        />
      )}
    </ErrorBoundary>
  );
};

export default withErrorBoundary(FooterButtons);
