import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import state from 'components/state';
import { getLedger } from 'api/Widgets';
import { mString } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Payments from 'components/Payments/Payments';
import Configurations from 'components/Payments/Configurations';
import { useBalance, useLedger } from 'lib/hooks/queries/widgets/useOverview';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';

const LedgerItem = ({ item, setInvoice }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => setInvoice(item)}
      className={'flex !cursor-pointer flex-col gap-3 px-[20px] py-3 hover:bg-primary-25'}>
      <div className="flex !cursor-pointer items-center justify-between gap-2">
        <p className="font-semibold text-neutral-800">Invoice #{item?.id}</p>

        <div className="flex gap-2">
          <p className="!text-xs text-primary-800">
            {formatDate(item?.appointment?.start_at || item?.created_at)}
          </p>

          <Icon
            stroke
            shade={700}
            color="primary"
            icon="new-chevron-right"
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="flex flex-col gap-[4px] ">
        <div className="flex justify-between gap-2 !text-sm">
          <p>Total amount:</p>
          <p className="font-medium">{mString(item?.total_amount)}</p>
        </div>

        <div className="flex justify-between gap-2 !text-sm">
          <p>Amount paid:</p>
          <p className="font-medium">
            {item?.amount_paid > 0 ? '-' : ''}
            {mString(item?.amount_paid)}
          </p>
        </div>

        {item?.discountAmount > 0 && (
          <div className="flex justify-between gap-2 !text-sm">
            <p>Discount:</p>
            <p className="font-medium">-{mString(item?.discountAmount)}</p>
          </div>
        )}

        {item?.adjustment > 0 && (
          <div className="flex justify-between gap-2 !text-sm">
            <p>Adjustment:</p>
            <p className="font-medium">-{mString(item?.adjustment)}</p>
          </div>
        )}

        {item?.tax > 0 && (
          <div className="flex justify-between gap-2 !text-sm">
            <p>Tax:</p>
            <p className="font-medium">{mString(item?.tax)}</p>
          </div>
        )}

        <div className="flex justify-between gap-2 !text-sm">
          <p>Balance:</p>
          <p className={`font-semibold ${item?.balance <= 0 ? 'text-success' : 'text-danger'}`}>
            {item?.balance < 0 ? '-' : ''}
            {mString(item?.balance)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Ledger(props) {
  const { patientId, changedSizes, layout } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const defaultSize = layout.find((l) => l.i === props.item.i)?.h - 1;

  const updateSize = async () => {
    const changedSize = changedSizes.find((size) => size.i === props.item.i);
    const size = changedSize ? changedSize?.h - 1 : defaultSize;

    if (size) {
      const newData = await getLedger(navigate, { limit: size, patientId });
      queryClient.setQueryData(['overview-ledger', patientId], newData);
    }
  };

  const updateData = async () => {
    await updateSize();
    await queryClient.invalidateQueries(['overview-balance-ledger']);
  };

  useEffect(() => {
    updateSize();
  }, [changedSizes]);

  const { data } = useLedger({
    params: { limit: defaultSize, patientId },
    dependencies: [patientId]
  });

  const { data: balance } = useBalance({
    params: { patientId },
    dependencies: [patientId]
  });

  const patientBalance = balance?.patientBalance?.[0]?.amount || 0;
  const totalBalance = (patientBalance / 100).toFixed(2);

  const { practice_id } = useRecoilValue(state.permissions);

  const [terminals, setTerminals] = useState([]);
  const [virtualTerminals, setVirtualTerminals] = useState([]);

  const [showNewPayment, setShowNewPayment] = useState(false);

  const [invoice, setInvoice] = useState();

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader hasPlus={false} icon="wallet" {...props}>
        {totalBalance && (
          <p className="flex flex-wrap gap-1 text-sm">
            Total
            <span
              className={`font-semibold ${totalBalance <= 0 ? 'text-success' : 'text-danger'} `}>
              {totalBalance < 0 ? '-' : ''} ${Math.abs(totalBalance)}
            </span>
          </p>
        )}

        <Payments
          smallButton
          patientId={patientId}
          terminals={terminals}
          onSuccess={updateData}
          practiceId={practice_id}
          updateBalance={updateData}
          buttonClassName="!px-[10px]"
          showNewPayment={showNewPayment}
          virtualTerminals={virtualTerminals}
          hideNewPayment={() => setShowNewPayment(false)}
          amount={patientBalance > 0 ? patientBalance : 0}
        />
      </WidgetHeader>

      <div className="flex flex-1 flex-col overflow-y-auto">
        {data?.ledger?.length > 0 ? (
          <FooterWrapper
            link
            text={`Invoices (${data?.count})`}
            onClick={() => navigate(`./billing/invoices`)}>
            <List
              items={data?.ledger}
              renderItemComponent={(item, index) => (
                <LedgerItem setInvoice={setInvoice} item={item} />
              )}></List>
          </FooterWrapper>
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Ledger</p>
          </div>
        )}
      </div>

      {invoice && (
        <Modal
          slideFromRight
          title="Invoice"
          bodyClassName="p-0"
          className="w-[1170px]"
          handleOpen={invoice != null}
          handleClose={() => setInvoice(false)}>
          <Configurations
            patientId={patientId}
            onSuccess={updateData}
            amount={invoice?.balance}
            onClose={() => setInvoice(false)}
            appointmentId={invoice?.appointment_id}
            newInvoice={[{ ...invoice, selected: true, expanded: true }]}
          />
        </Modal>
      )}
    </div>
  );
}
