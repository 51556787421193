import React, { useMemo, useState } from 'react';

import Button from 'components/shared/Buttons/Button';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Icon from 'components/shared/Icon/Icon';

import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import calculateDateDifference from 'components/shared/CustomizableBoard/widgets/helpers/calculateDateDifference';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import CurrentMedications from 'components/shared/Forms/Custom/CurrentMedications/CurrentMedications';
import Modal from 'components/shared/Modal/Modal';
import moment from 'moment';
import { customFilter } from '../helpers/filterCustomData';
import { createMedication, getMedications } from '/api/Medication';
import { useOverviewMedications } from '/lib/hooks/queries/widgets/useOverview';

const Medication = ({ item, openItemModal }) => {
  const frequency = (listItem) =>
    listItem?.frequencyNumber?.label == 'Other'
      ? listItem?.frequencyNumberOther
      : listItem?.frequencyNumber?.label;

  const medicationDays = (item) => {
    const timeDiff = calculateDateDifference(item?.start_date, item?.end_date);
    const today = moment();
    var left = '';

    if (today.isBefore(item?.start_date)) {
      left = `starts ${formatDate(item?.start_date, true)}`;
    } else if (today.isAfter(item?.end_date)) {
      left = `ended ${formatDate(item?.end_date, true)}`;
    } else {
      left = `${calculateDateDifference(today, item?.end_date)} left`;
    }
    return `${timeDiff}, ${left}`;
  };

  return (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[6px] px-[20px] py-[12px] hover:bg-primary-25">
      <List
        items={item?.list}
        dividerType="none"
        renderItemComponent={(listItem, index) => (
          <>
            <div className="flex items-center justify-between gap-2">
              <p className="font-medium text-neutral-800">{listItem.medication}</p>
              {index == 0 && <Icon icon="new-chevron-right"></Icon>}
            </div>

            {listItem?.start_date && listItem?.end_date && (
              <p className="font-medium text-primary-600">{medicationDays(listItem)}</p>
            )}
            {/* <p className="text-primary-600 font-medium">
              {frequency(listItem) > 1
                ? `${frequency(listItem)} doses`
                : `${frequency(listItem)} dose`}{' '}
              {listItem?.frequencyDay?.label?.toLowerCase()}
            </p> */}

            {listItem.note && <p className="line-clamp-2 text-neutral-600">{listItem.note}</p>}
          </>
        )}
      />

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)}
        {item?.createdBy?.fullName && (
          <>
            <span className="px-1">•</span> {item?.createdBy?.fullName}
          </>
        )}
      </p>
    </div>
  );
};

export default function Medications(props) {
  const { patientId, layout } = props;

  const defaultSize = useMemo(() => layout.find((l) => l.i === props.item.i)?.h, [layout]);

  const [show, setShow] = useState(false);

  const { data } = useOverviewMedications({
    params: { patientId },
    dependencies: [patientId]
  });
  const medications = customFilter(data?.response, 'medications', 'medication');
  const { count, items, hasMore } = useMemo(
    () => limitItems(medications, defaultSize, { 'start_date,end_date': 0.3, note: 0.3 }),
    [medications, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [itemModal, setItemModal] = useState(null);
  const [medicationIds, setMedicationIds] = useState([]);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    setMedicationIds(item?.list);
    setItemModal(item?.original);
    setCurrentFormData(item?.original?.response);
  };

  const submitForm = async () => {
    try {
      const params = {
        appointmentId: itemModal?.appointmentId || null,
        patientId,
        medication: currentFormData
      };
      const data = await createMedication(() => {}, params);
      if (data) {
        showAlert({ title: 'Successfully saved!', color: 'primary' });
        queryClient.invalidateQueries(['medications']);
        setItemModal();
        setCurrentFormData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto" data-dd-privacy="allow">
      <WidgetHeader
        onPlusClick={() => setItemModal({})}
        icon="medication"
        {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <FooterWrapper
          hasMore={hasMore}
          onClick={() => setShow(true)}
          text={`Show all medications (${count})`}>
          {items ? (
            <List
              items={items}
              renderItemComponent={(item, index) => (
                <Medication openItemModal={openItemModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Medications</p>
            </div>
          )}
        </FooterWrapper>
      </div>

      <Modal
        handleOpen={show}
        className="w-[1020px] bg-white"
        title="Medications"
        slideFromRight
        handleClose={() => setShow(false)}>
        {medications ? (
          <List
            items={medications}
            renderItemComponent={(item, index) => (
              <Medication openItemModal={openItemModal} item={item} />
            )}
          />
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Medications</p>
          </div>
        )}
      </Modal>

      <Modal
        className="w-[1020px]"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setItemModal();
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }
        handleOpen={!!itemModal}
        title="Medication"
        slideFromRight
        handleClose={() => setItemModal()}>
        <CurrentMedications
          fromOverview={true}
          defaultValue={itemModal?.response}
          setItemModal={setItemModal}
          customFormsParams={{ id: patientId, appointmentId: itemModal?.appointmentId || null }}
          setCurrentFormData={setCurrentFormData}
          hideNoKnownMedications={true}
        />
      </Modal>
    </div>
  );
}
