import React, { useState, useEffect } from 'react';
import { PacketBuilderContext } from './PacketBuilderContext';
import { useFormik } from 'formik';
import {
  PacketInitialValues,
  PacketValidationSchema,
  removeAutomation,
  removeAutomationAndEmptyValues
} from 'components/practice/settings/forms/FormPackets/lib/helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFormPacket, updateFormPacket } from 'api/FormPacket';
import { useLocation, useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import _, { isEqual } from 'lodash';
import { ia } from 'lib/helpers/utility';

export const PacketBuilderContextProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState('Save Progress');
  const [buildButtonText, setBuildButtonText] = useState('Skip Automation & Start Building');
  const [searchTerm, setSearchTerm] = useState({
    forms: '',
    providers: '',
    services: '',
    tags: ''
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [formId, setFormId] = useState();
  const location = useLocation();

  let initialBreadCrumbs = [
    { title: 'Name&Automation', view: 'name_and_automation', active: true, isFinished: false },
    { title: 'Providers', view: 'providers', active: false, isFinished: false, hidden: true },
    { title: 'Services', view: 'services', active: false, isFinished: false, hidden: true },
    { title: 'Tags', view: 'tags', active: false, isFinished: false, hidden: true },
    { title: 'Forms', view: 'forms', active: false, isFinished: false }
  ];

  let editInitialBreadcrumbs = [
    { title: 'Name&Automation', view: 'name_and_automation', active: true, isFinished: false },
    { title: 'Providers', view: 'providers', active: false, isFinished: false },
    { title: 'Services', view: 'services', active: false, isFinished: false },
    { title: 'Tags', view: 'tags', active: false, isFinished: false },
    { title: 'Forms', view: 'forms', active: false, isFinished: false }
  ];

  const [breadCrumbs, setBreadCrumbs] = useState(initialBreadCrumbs);
  const [enableNextTo, setEnableNextTo] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateCreatePacket, isLoading: isFormSavingLoading } = useMutation({
    mutationFn: (data) => createFormPacket(navigate, data)
  });

  const { mutateAsync: mutateUpdateFormPacket } = useMutation({
    mutationFn: (data) => updateFormPacket(navigate, data)
  });

  const formik = useFormik({
    initialValues: PacketInitialValues(),
    validationSchema: PacketValidationSchema,
    onSubmit: async (values) => {
      let shapedPacket = isEdit ? removeAutomationAndEmptyValues(values) : removeAutomation(values);
      let variables = {
        fields: { ...shapedPacket, status: 'active' },
        formPacketId: shapedPacket.id,
        isSubmit: true
      };
      isEdit
        ? await mutateUpdateFormPacket(variables, {
            onSuccess: ({ code, updatedformPacket }) => {
              if (code === 0) {
                queryClient.invalidateQueries(['form_packets']);
                formik.setValues({
                  ...formik.values,
                  tag_ids: updatedformPacket.tag_ids.map((tag) => tag.value),
                  provider_ids: updatedformPacket.provider_ids.map((provider) => provider.value),
                  service_ids: updatedformPacket.service_ids.map((service) => service.value),
                  form_ids: updatedformPacket.form_ids.map((form) => form.value),
                  automation: {
                    ...formik.values.automation,
                    tags: ia(updatedformPacket.tag_ids),
                    providers: ia(updatedformPacket.provider_ids),
                    services: ia(updatedformPacket.service_ids),
                    forms: ia(updatedformPacket.form_ids),
                    patient_creation: updatedformPacket?.patient_creation
                  }
                });
                setPacket({ ...updatedformPacket });
                showAlert({ title: 'Packet Updated successfully', color: 'success' });
              } else {
                showAlert({ title: 'Packet update failed', color: 'danger' });
              }
              setBreadCrumbs(editInitialBreadcrumbs);
            }
          })
        : await mutateCreatePacket(
            { packet: shapedPacket, isSubmit: true },
            {
              onSuccess: ({ code, packet, error }) => {
                if (code === 0) {
                  queryClient.invalidateQueries(['form_packets']);
                  showAlert({
                    title: isEdit ? 'Packet updated successfully' : 'Packet created successfully',
                    color: 'success'
                  });
                } else {
                  showAlert({
                    title: isEdit ? 'Packet update failed' : 'Packet creation failed',
                    message: error ?? 'Something went wrong',
                    color: 'danger'
                  });
                }
                formik.setValues({ ...packet });
                setPrevPacketValues(packet);
                handleClose();
              }
            }
          );
    }
  });
  const [packet, setPacket] = useState({ ...formik.values });
  const [prevPacketValues, setPrevPacketValues] = useState(formik?.values);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!autoSave || isFormSavingLoading) return;
      if (isEqual(prevPacketValues, formik.values)) return;

      let shapedPacket = removeAutomation(formik?.values);
      setSaveButtonText('Saving');
      await mutateCreatePacket(
        { packet: shapedPacket, isSubmit: true },
        {
          onSuccess: ({ code, packet, error }) => {
            if (code !== 0) {
              showAlert({
                title: isEdit ? 'Packet update failed' : 'Packet creation failed',
                message: error ?? 'Something went wrong',
                color: 'danger'
              });
            }
            formik.setValues({ ...packet });
            setPrevPacketValues(packet);
            setSaveButtonText('Save Progress');
          }
        }
      );
    }, 30000); // 30s

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSave, formik.values]);

  useEffect(() => {
    if (location.state?.openModal) {
      setOpenModal(true);
    }
    if (location.state?.form_id) {
      setFormId(location.state?.form_id);
    }
  }, [location.state, setOpenModal]);

  const handleClose = () => {
    setOpenModal(false);
    setBreadCrumbs(initialBreadCrumbs);
    formik.resetForm();
    setAutoSave(false);
    setEnableNextTo(false);
    setSaveButtonText('Save Progress');
    setBuildButtonText('Skip Automation & Start Building');
    setEnableSubmit(false);
    setIsEdit(false);
  };

  const handleSave = async () => {
    let shapedPacket = removeAutomation(formik?.values);
    setSaveButtonText('Saving');
    await mutateCreatePacket(
      { packet: shapedPacket, isSubmit: false },
      {
        onSuccess: ({ code, packet, error }) => {
          if (code !== 0) {
            showAlert({
              title: isEdit ? 'Packet update failed' : 'Packet creation failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
          formik.setValues({ ...packet });
          setPrevPacketValues(packet);
          setSaveButtonText('Save Progress');
        }
      }
    );
  };

  const handleAutoSave = () => {
    setAutoSave(!autoSave);
  };
  return (
    <PacketBuilderContext.Provider
      value={{
        openModal,
        setOpenModal,
        autoSave,
        setAutoSave,
        saveButtonText,
        setSaveButtonText,
        breadCrumbs,
        setBreadCrumbs,
        enableNextTo,
        setEnableNextTo,
        handleClose,
        handleSave,
        handleAutoSave,
        formik,
        buildButtonText,
        setBuildButtonText,
        searchTerm,
        setSearchTerm,
        debouncedSearchTerm,
        setDebouncedSearchTerm,
        enableSubmit,
        setEnableSubmit,
        isEdit,
        setIsEdit,
        isFormSavingLoading,
        formId,
        setFormId,
        packet,
        setPacket,
        editInitialBreadcrumbs
      }}>
      {children}
    </PacketBuilderContext.Provider>
  );
};
