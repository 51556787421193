import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useAIBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const initialBreadcrumbRef = useRef(null);

  useEffect(() => {
    if (initialBreadcrumbRef.current) {
      const basePath = initialBreadcrumbRef.current.path;
      const relativePath = location.pathname.replace(basePath, '');
      const pathParts = relativePath.split('/').filter(Boolean);

      const newBreadcrumbs = pathParts.map((part, index) => ({
        label: part.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
        path: `${basePath}/${pathParts.slice(0, index + 1).join('/')}`
      }));

      setBreadcrumbs([initialBreadcrumbRef.current, ...newBreadcrumbs]);
    }
  }, [location]);

  const pushBreadcrumb = (breadcrumb) => {
    if (!initialBreadcrumbRef.current) {
      initialBreadcrumbRef.current = breadcrumb;
    }

    const exists = breadcrumbs.some((b) => b.path === breadcrumb.path);
    if (!exists) {
      setBreadcrumbs((prev) => [...prev, breadcrumb]);
    }
  };

  const popBreadcrumb = () => {
    setBreadcrumbs((prev) => prev.slice(0, -1));
  };

  const navigateToBreadcrumb = (index) => {
    const selectedBreadcrumb = breadcrumbs.slice(0, index + 1);
    // setBreadcrumbs(selectedBreadcrumb);
    navigate(selectedBreadcrumb[selectedBreadcrumb.length - 1].path);
  };

  return { breadcrumbs, pushBreadcrumb, popBreadcrumb, navigateToBreadcrumb };
};

export default useAIBreadcrumbs;
