import React from 'react';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import NonERXPrescriptionPreview from '../../../Orders/views/NonERXPrescriptionPreview/NonERXPrescriptionPreview';

import { useParams } from 'react-router-dom';
import { ia } from 'lib/helpers/utility';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useNonERXPrescription } from 'lib/hooks/queries/nonERXPrescription/useNonERXPrescription';

const NonERXPrescription = ({ customClinicalNote = null, checkedFormTypes = {} }) => {
  const { isTemplateChecked } = checkedFormTypes;
  const { clinicalNote } = useClinicalNoteContext() || {};

  const customAppointmentId = customClinicalNote?.clinicalNote?.appointment_id;

  const { appointmentId } = useParams();

  const apptID = customAppointmentId ?? appointmentId ?? clinicalNote?.appointment_id;

  const { data: nonERXPrescription, isFetching: nonERXPrescriptionFetching } =
    useNonERXPrescription({
      dependencies: [apptID, clinicalNote?.id],
      params: {
        appointmentIds: [apptID],
        withRelations: { provider: true }
      },
      options: {
        enabled: isTemplateChecked,
        staleTime: 10000,
        select: ({ data }) => data?.nonERXPrescription
      }
    });

  if (nonERXPrescriptionFetching) return <Skeleton height="300px" />;

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">
        Non ERX Prescription Orders
      </h3>

      {ia(nonERXPrescription) ? (
        nonERXPrescription.map((row, index) => {
          return (
            <div key={index}>
              <NonERXPrescriptionPreview itemProps={row} />
              {nonERXPrescription?.length - 1 > index && <div className="border border-solid" />}
            </div>
          );
        })
      ) : (
        <p className="text-sm">No Non ERX Prescription Orders</p>
      )}
    </div>
  );
};

export default NonERXPrescription;
