import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { camelCaseToReadableFormat, formatDate, io } from 'lib/helpers/utility';
import moment from 'moment-timezone';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const SocialHistory = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(currentHpOverviewData?.socialHistory)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="!pb-4 text-base font-500 text-[#003A4B] print:text-xl">Social history</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.socialHistory]?.map((socialHistory) => {
          if (socialHistory?.response?.noDetails || !io(socialHistory?.response)) return null;

          return (
            <>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
              <div className="grid gap-2">
                {Object.entries(socialHistory?.response)?.map(([key, value], idx) => {
                  const hasTruthySubValue = Object.values(value).some(
                    (childValue) => childValue && key !== 'narrative'
                  );
                  if (hasTruthySubValue) {
                    return (
                      <div className="flex" key={idx}>
                        <span className="w-[120px] min-w-[120px] text-xs font-500 text-neutral-700 print:text-base">
                          {`${camelCaseToReadableFormat(key)}: `}
                        </span>
                        <div className="flex flex-wrap gap-4">
                          {Object.entries(value)?.map(([subKey, subValue], idx) => {
                            if (subValue) {
                              let displayValue = subValue;
                              if (subKey === 'yes' && subValue === true) {
                                displayValue = camelCaseToReadableFormat(subKey);
                              } else if (subKey === 'contracteption' && subValue === true) {
                                displayValue = 'contracteption: Yes';
                              } else if (
                                typeof subValue === 'string' &&
                                moment(subValue, moment.ISO_8601).isValid()
                              ) {
                                displayValue = `${camelCaseToReadableFormat(subKey)}: ${formatDate(
                                  subValue
                                )}`;
                              } else if (subKey !== 'yes' && subValue === true) {
                                displayValue = camelCaseToReadableFormat(subKey);
                              } else {
                                displayValue = `${camelCaseToReadableFormat(subKey)}: ${subValue}`;
                              }

                              return (
                                <span
                                  key={idx}
                                  className="w-[120px] min-w-[120px] text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                                  {displayValue}
                                </span>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          );
        })}

      {isNarrativeChecked && !!io(currentHpOverviewData?.socialHistory) && (
        <div className="!mt-2">
          <ListCustomForms
            forms={[currentHpOverviewData?.socialHistory]}
            noDetailsText="No notable social history"
          />
        </div>
      )}
    </div>
  );
};

export default SocialHistory;
