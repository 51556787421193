import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { centsToDollar } from 'lib/helpers/utility';
import practiceState from '../../practiceState';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Header from 'components/shared/Header/Header';
import TableCounter from 'components/shared/Table/TableCounter';
import { requestApi } from 'api/Api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

let delayed;
export const options = {
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    }
  },
  plugins: {
    datasets: {
      color: '#36A2EB'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false
    },
    y: {
      stacked: false
    }
  }
};

const labels = [
  '0 - 30 Days',
  '31 - 60 Days',
  '61 - 90 Days',
  '91 - 120 Days',
  '121+ Days',
  'Total'
];
export default function DynamicAR() {
  const [currentPage, setCurrentPage] = useState('list');
  const { type } = useParams();
  const [patients, setPatients] = useState([]);
  const [payers, setPayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [patientAmounts, setPatientAmounts] = useState({
    zeroToThirty: 0,
    thirtyOneToSixty: 0,
    sixtyOneToNinety: 0,
    ninetyOneToOneHundredAndTwenty: 0,
    oneHundredAndTwentyPlus: 0,
    total: 0
  });
  const [payerAmounts, setPayerAmounts] = useState({
    zeroToThirty: 0,
    thirtyOneToSixty: 0,
    sixtyOneToNinety: 0,
    ninetyOneToOneHundredAndTwenty: 0,
    oneHundredAndTwentyPlus: 0,
    total: 0
  });
  const filters = useRecoilValue(practiceState.reportingFilters);
  const practice = useRecoilValue(practiceState.currentPractice);
  const [order, setOrder] = useState({
    patient_payer: false,
    date: false,
    notes: false,
    balance_type: false,
    amount_one: false,
    amount_two: false,
    amount_three: false,
    amount_four: false,
    amount_five: false,
    total: false
  });
  const navigate = useNavigate();

  const [data, setData] = useState({
    labels,
    datasets: []
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (patientAmounts && payerAmounts) {
      const datasets = [
        {
          label: 'Patients',
          data: Object.values(patientAmounts).map((amount) => (amount / 100).toFixed(2)),
          backgroundColor: 'rgb(75, 192, 192)',
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 8,
            formatter(value, context) {
              return `$${value}`;
            }
          }
        },
        {
          label: 'Payers',
          data: Object.values(payerAmounts).map((amount) => (amount / 100).toFixed(2)),
          backgroundColor: 'rgb(53, 162, 235)',
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 8,
            formatter(value, context) {
              return `$${value}`;
            }
          }
        }
      ];
      setData({
        ...data,
        datasets
      });
    }
  }, [patientAmounts, payerAmounts]);

  const orderBy = (column) => {
    let orderedList = [];
    switch (column) {
      case 'patient_payer':
        const fullName = list?.map((row) => {
          return {
            ...row,
            fullName: row?.fullName || row?.name
          };
        });
        orderedList = fullName?.sort((a, b) => {
          return order[column]
            ? a?.fullName?.trim().localeCompare(b?.fullName?.trim())
            : b?.fullName?.trim().localeCompare(a?.fullName?.trim());
        });
        setList(orderedList);
        break;
      case 'date':
        const date = list?.map((row) => {
          return { ...row, date: row.dob ? row.dob : row.created_at };
        });
        orderedList = date?.sort((a, b) => {
          if (order[column]) {
            return moment(a?.date).isBefore(moment(b?.date)) ? 1 : -1;
          } else {
            return moment(b?.date).isBefore(moment(a?.date)) ? 1 : -1;
          }
        });
        setList(orderedList);
        break;
      case 'notes':
        break;
      case 'balance_type':
        const balance_type = list?.map((row) => {
          return { ...row, balance_type: row.email ? 'Patient' : 'Insurance' };
        });
        orderedList = balance_type?.sort((a, b) => {
          return order[column]
            ? a?.balance_type?.localeCompare(b?.balance_type)
            : b?.balance_type?.localeCompare(a?.balance_type);
        });
        setList(orderedList);
        break;
      case 'amount_one':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.zeroToThirty) - centsToDollar(b?.zeroToThirty)
            : centsToDollar(b?.zeroToThirty) - centsToDollar(a?.zeroToThirty);
        });
        setList(orderedList);
        break;
      case 'amount_two':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.thirtyOneToSixty) - centsToDollar(b?.thirtyOneToSixty)
            : centsToDollar(b?.thirtyOneToSixty) - centsToDollar(a?.thirtyOneToSixty);
        });
        setList(orderedList);
        break;
      case 'amount_three':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.sixtyOneToNinety) - centsToDollar(b?.sixtyOneToNinety)
            : centsToDollar(b?.sixtyOneToNinety) - centsToDollar(a?.sixtyOneToNinety);
        });
        setList(orderedList);
        break;
      case 'amount_four':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.ninetyOneToOneHundredAndTwenty) -
                centsToDollar(b?.ninetyOneToOneHundredAndTwenty)
            : centsToDollar(b?.ninetyOneToOneHundredAndTwenty) -
                centsToDollar(a?.ninetyOneToOneHundredAndTwenty);
        });
        setList(orderedList);
        break;
      case 'amount_five':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.oneHundredAndTwentyPlus) - centsToDollar(b?.oneHundredAndTwentyPlus)
            : centsToDollar(b?.oneHundredAndTwentyPlus) - centsToDollar(a?.oneHundredAndTwentyPlus);
        });
        setList(orderedList);
        break;
      case 'total':
        orderedList = list?.sort((a, b) => {
          return order[column]
            ? centsToDollar(a?.total) - centsToDollar(b?.total)
            : centsToDollar(b?.total) - centsToDollar(a?.total);
        });
        setList(orderedList);
        break;
      default:
        break;
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      let params = {
        id: filters.id,
        limit: 100,
        offset: 0,
        payer: filters.payer
      };
      let res = await requestApi({
        url: '/api/practice/billing/reporting/dynamic_ar',
        params,
        navigate
      });
      if (res) {
        setPatients(res.patients);
        setPayers(res.payers);
        if (res.patients && res.payers) {
          setList([...res.patients, ...res.payers]);
        }
        setPatientAmounts(res.patientAmounts);
        setPayerAmounts(res.payerAmounts);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  if (loading) return <Skeleton count={14} />;

  return (
    <>
      <Header title="Dynamic AR" subtitle={type ?? 'List'} />
      <div className="grid overflow-hidden !p-4">
        {type === 'graph' ? (
          <Bar options={options} data={data} />
        ) : (
          <>
            <div className="relative overflow-auto !rounded-md">
              <table className="primary-table">
                <thead>
                  <tr>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          patient_payer: !order.patient_payer
                        });
                        orderBy('patient_payer');
                      }}>
                      Patient/Payer
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          balance_type: !order.balance_type
                        });
                        orderBy('balance_type');
                      }}>
                      Balance Type
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          amount_one: !order.amount_one
                        });
                        orderBy('amount_one');
                      }}>
                      0-30 days
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          amount_two: !order.amount_two
                        });
                        orderBy('amount_two');
                      }}>
                      31-60 days
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          amount_three: !order.amount_three
                        });
                        orderBy('amount_three');
                      }}>
                      61-90 days
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          amount_four: !order.amount_four
                        });
                        orderBy('amount_four');
                      }}>
                      91-120 days
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          amount_five: !order.amount_five
                        });
                        orderBy('amount_five');
                      }}>
                      121+ days
                    </th>
                    <th
                      onClick={() => {
                        setOrder({
                          ...order,
                          total: !order.total
                        });
                        orderBy('total');
                      }}>
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      Totals:
                    </td>
                    <td>
                      $
                      {(
                        (patientAmounts?.zeroToThirty + payerAmounts?.zeroToThirty) /
                        100
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      $
                      {(
                        (patientAmounts?.thirtyOneToSixty + payerAmounts?.thirtyOneToSixty) /
                        100
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      $
                      {(
                        (patientAmounts?.sixtyOneToNinety + payerAmounts?.sixtyOneToNinety) /
                        100
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      $
                      {(
                        (patientAmounts?.ninetyOneToOneHundredAndTwenty +
                          payerAmounts?.ninetyOneToOneHundredAndTwenty) /
                        100
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      $
                      {(
                        (patientAmounts?.oneHundredAndTwentyPlus +
                          payerAmounts?.oneHundredAndTwentyPlus) /
                        100
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      $
                      {((patientAmounts?.total + payerAmounts?.total) / 100).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2
                        }
                      )}
                    </td>
                  </tr>
                  {list.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-left">{row.fullName || row.name}</td>
                        <td>{row.email ? 'Patient' : 'Insurance'}</td>
                        <td>
                          $
                          {(row?.zeroToThirty / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          $
                          {(row?.thirtyOneToSixty / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          $
                          {(row?.sixtyOneToNinety / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          $
                          {(row?.ninetyOneToOneHundredAndTwenty / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          $
                          {(row?.oneHundredAndTwentyPlus / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          $
                          {(row?.total / 100).toLocaleString(undefined, {
                            maximumFractionDigits: 2
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableCounter
              page={1}
              limit={patients?.length + payers?.length}
              count={patients?.length + payers?.length}
            />
          </>
        )}
      </div>
    </>
  );
}
