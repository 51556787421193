import React from 'react';

const PayersTable = ({ children }) => {
  return (
    <>
      <table className={'primary-table'}>
        <thead>
          <tr>
            <th className="w-[10%] cursor-pointer">ID</th>
            <th className="w-[50%] cursor-pointer">Name</th>
            <th className="w-[30%] cursor-pointer">Address</th>
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

export default PayersTable;
