import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

const EnhanceNote = () => {
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb } = breadcrumb;
  return (
    <div className="p-2">
      <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      <div className="flex h-screen">EnhanceNote</div>
    </div>
  );
};

export default EnhanceNote;
