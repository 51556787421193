import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { UIContextProvider } from './lib/context/UIContext/UIContextProvider';
import App from 'App';
import { Honeybadger } from '@honeybadger-io/react';
import Button from 'components/shared/Button/Button';

const honeyBadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.ENV,
  revision: process.env.REACT_APP_GIT_SHA
};

Honeybadger.configure(honeyBadgerConfig);

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <RecoilRoot>
      <UIContextProvider>
        <App />
      </UIContextProvider>
    </RecoilRoot>
  </BrowserRouter>
  // </React.StrictMode>
);
