import { currentPractice } from 'components/practice/practiceState';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getInvoiceDetails } from '../lib/internal/configs';
import NewInvoiceDetailsTable from './NewInvoiceDetailsTable';

const NewInvoice = ({ invoice, children, isView = false }) => {
  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;
  const patientInvoiceDetails = getInvoiceDetails(invoice, claims);

  return (
    <>
      <div className="!p-4 w-full">
        <div className="flex gap-3"></div>
        <div>
          <NewInvoiceDetailsTable invoices={patientInvoiceDetails} isView={isView} />
        </div>
        {children}
      </div>
    </>
  );
};

export default NewInvoice;
