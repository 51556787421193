import { formatTimeZ } from 'lib/helpers/utility';
export const handleSelectCPT = ({
  procedures = [],
  index,
  service,
  e,
  superbill = null,
  practice,
  claim = null
}) => {
  let updatedProcedures = [...procedures];

  updatedProcedures[index] = {
    ...updatedProcedures[index],
    code: e.value,
    charge: service?.standard_charge
      ? (service?.standard_charge / 100).toFixed(2)
      : e.parAmount
        ? parseFloat(
            (e.parAmount / 100) *
              (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage / 100 || 1)
          ).toFixed(2)
        : 0
  };

  if (e.base_unit) {
    updatedProcedures[index] = {
      ...updatedProcedures[index],
      base_unit: e.base_unit,
      appointment_start: formatTimeZ(
        superbill
          ? superbill.encounter.appointment.starts_at
          : claim?.superbill?.encounter.appointment.starts_at,
        practice.timezone
      ),
      appointment_end: formatTimeZ(
        superbill
          ? superbill.encounter.appointment.ends_at
          : claim?.superbill?.encounter.appointment.ends_at,
        practice.timezone
      )
    };
  }

  return updatedProcedures;
};
