import React from 'react';
import { useRecoilState } from 'recoil';
import { Capitalize, findRelationName, formatDate } from '../../../../../lib/helpers/utility';
import { currentPractice } from '../../../practiceState';

const InsuranceProfileContent = ({ insuranceProfile }) => {
  const { timezone, id } = useRecoilState(currentPractice);

  return (
    <div className="grid gap-2">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Payer id:</p>
        <p className="text-sm font-500 text-neutral-800">{insuranceProfile.payer_id}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Payer name:</p>
        <p className="text-sm font-500 text-neutral-800">{insuranceProfile?.payer_name}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Type:</p>
        <p className="text-sm font-500 text-neutral-800">{Capitalize(insuranceProfile?.type)}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Member id:</p>
        <p className="text-sm font-500 text-neutral-800">{insuranceProfile.member_id}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Group number:</p>
        <p className="text-sm font-500 text-neutral-800">{insuranceProfile.group_number}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Prior authorization number:</p>
        <p className="text-sm font-500 text-neutral-800">{insuranceProfile.prior_auth}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Policy start date:</p>
        <p className="text-sm font-500 text-neutral-800">
          {insuranceProfile.policy_start_date
            ? formatDate(insuranceProfile.policy_start_date, timezone)
            : 'N/A'}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Policy end date:</p>
        <p className="text-sm font-500 text-neutral-800">
          {insuranceProfile.policy_end_date
            ? formatDate(insuranceProfile.policy_end_date, timezone)
            : 'N/A'}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Relation:</p>
        <p className="text-sm font-500 text-neutral-800">
          {findRelationName(insuranceProfile.relation)}
        </p>
      </div>
      {insuranceProfile.insured_first_name && insuranceProfile.insured_last_name && (
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-sm text-neutral-600">Insured name:</p>
          <p className="text-sm font-500 text-neutral-800">
            {insuranceProfile.insured_first_name} {insuranceProfile?.insured_middle_name || ''}{' '}
            {insuranceProfile.insured_last_name}
          </p>
        </div>
      )}
      {insuranceProfile.insured_date_of_birth && (
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-sm text-neutral-600">Insured date of birth:</p>
          <p className="text-base">
            {insuranceProfile.insured_date_of_birth
              ? formatDate(insuranceProfile.insured_date_of_birth, timezone)
              : 'N/A'}
          </p>
        </div>
      )}
      {insuranceProfile.copay && id ? (
        <div className="flex flex-wrap items-center justify-between gap-2">
          <p className="text-sm text-neutral-600">Copay:</p>
          <p className="text-base">${parseFloat(insuranceProfile.copay / 100).toFixed(2)}</p>
        </div>
      ) : null}
    </div>
  );
};

export default InsuranceProfileContent;
