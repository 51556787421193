import { getAdminReportingCSV } from 'api/Admin/Reporting';
import HealthcareProgramEfficiencySaving from 'components/practice/Dashboard/widgets/HealthcareProgramEfficiencySaving';
import UpcomingAppointments from 'components/practice/Dashboard/widgets/UpcomingAppointments/UpcomingAppointments';
import ExportData from 'components/shared/AGTable/ExportData';
import Board from 'components/shared/CustomizableBoard/Board';
import StickyButton from 'components/shared/CustomizableBoard/StickyButton';
import DateRangePicker from 'components/shared/DateRange/DateRangePicker';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';
import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { useAdminReports } from 'lib/hooks/queries/widgets/useAdminReports';
import usePageTitle from 'lib/hooks/usePageTitle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import ClaimMD from './widgets/AdminDashboardPanel/ClaimMD';
import CommunicationsUsage from './widgets/AdminDashboardPanel/Communications';
import EncounterReports from './widgets/AdminDashboardPanel/EncounterReports/EncounterReports';
import PatientDashboardPanel from './widgets/AdminDashboardPanel/Panels/PatientDashboardPanel';
import PracticeDashboardPanel from './widgets/AdminDashboardPanel/Panels/PracticeDashboardPanel';
import StaffMembers from './widgets/AdminDashboardPanel/StaffMember';
import TransactionsDashboardPanel from './widgets/AdminDashboardPanel/Transaction/Transactions';
import Veradigm from './widgets/Veradigm';
import { colDefs } from './widgets/lib/helpers';
const widgets = {
  practice_dashboard_panel: PracticeDashboardPanel,
  patient_dashboard_panel: PatientDashboardPanel,
  upcoming_appointments: UpcomingAppointments,
  healthcare_program_efficiency_saving: HealthcareProgramEfficiencySaving,
  communications_usage: CommunicationsUsage,
  transaction_dashboard_panel: TransactionsDashboardPanel,
  /*   income: Income, */
  encounter_reports: EncounterReports,
  staff_members: StaffMembers,
  veradigm: Veradigm,
  claim_md: ClaimMD
};

export default function Dashboard() {
  return (
    <DashboardContextProvider>
      <BoardContextProvider
        layouts={{
          lg: [],
          xmd: [],
          md: [],
          sm: []
        }}
        cols={{ lg: 4, xmd: 12, md: 12, sm: 1 }}
        board="admin_reports">
        <DashboardBoard />
      </BoardContextProvider>
    </DashboardContextProvider>
  );
}

function DashboardBoard() {
  const { ranges, setRanges } = useDashboardContext();
  const navigate = useNavigate();
  const params = {
    startAt: ranges.startDate,
    endAt: ranges.endDate
  };
  const { data } = useAdminReports(params);
  const { editMode } = useBoardContext();

  usePageTitle('Dashboard');
  const generateCSV = async () => {
    const csvParams = {
      startAt: ranges.startDate,
      endAt: ranges.endDate,
      colDefs,
      category: 'admin_reports'
    };
    const data = await getAdminReportingCSV(navigate, csvParams);
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'admin_reporting.csv';
    a.click();
  };

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden bg-neutral-50 !pb-8">
      <Header />
      <div className="-mt-14">
        <Board
          dashboardData={data}
          breakpoints={{ lg: 1200, xmd: 800, md: 710, sm: 0 }}
          rowHeight={145}
          widgets={widgets}
          margin={[12, 12]}>
          <div className="absolute -top-[74px] right-[14px] z-20 flex items-center gap-2">
            {!editMode && (
              <DateRangePicker
                iconColor="white"
                buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)] sm:!bg-[#55C6EF] !py-0 !px-2 flex min-w-[30px] min-h-[30px]"
                buttonTextColor="text-white"
                customStyling={window.innerWidth < 677 ? { right: '-54px' } : {}}
                ranges={[
                  {
                    startDate: ranges?.startDate,
                    endDate: ranges?.endDate,
                    key: 'selection'
                  }
                ]}
                onChange={(e) => {
                  setRanges((prevState) => ({
                    ...prevState,
                    startDate: e.selection.startDate,
                    endDate: e.selection.endDate
                  }));
                }}
                startDate={ranges?.startDate}
                endDate={ranges?.endDate}
                minDate={-3650}
                maxDate={3650}
              />
            )}
            <ExportData title="Generate Analytics Report" onGenerateClick={generateCSV} />
            <StickyButton
              iconColor="white"
              className="!relative !top-auto !m-0 !h-auto"
              buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)]"
            />
          </div>
        </Board>
      </div>
    </div>
  );
}
