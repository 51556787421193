import moment from 'moment-timezone';
import React from 'react';
import {
  camelCaseToReadableFormat,
  formatDate,
  ia,
  io
} from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import SocialHistoryModal from '../Modals/SocialHistoryModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const SocialHistoryOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview?.social_history && !cnSettings?.overview?.socialHistory) return null;

  return (
    <SimpleAccordion
      title="Social history"
      onToggle={() => toggleOne('socialHistory')}
      open={isOpen.socialHistory}
      edit={
        <SocialHistoryModal
          formId={currentHpOverviewData?.socialHistory?.id}
          defaultValue={currentHpOverviewData?.socialHistory?.response}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
          setCustomResponses={setOverviewData}
        />
      }>
      {ia(overviewData?.socialHistory) &&
        overviewData?.socialHistory?.map((socialHistory, index) => {
          if (!io(socialHistory?.response)) return null;

          if (socialHistory?.response?.noDetails) {
            return (
              <NoKnown
                key={index}
                form={socialHistory}
                description="No known social history"
                shouldShowDivider={index !== 0}
              />
            );
          }

          return (
            <>
              <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
              {Object.entries(socialHistory?.response)?.map(([key, value], idx) => {
                if (key === 'narrative') return null;

                const hasTruthySubValue = Object.values(value).some((childValue) => childValue);

                if (hasTruthySubValue) {
                  return (
                    <React.Fragment key={idx}>
                      <div className="flex justify-between gap-x-4">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          {`${camelCaseToReadableFormat(key)}: `}
                        </span>
                        <div>
                          {Object.entries(value)?.map(([subKey, subValue], idx) => {
                            if (subValue) {
                              let displayValue = subValue;

                              if (subKey === 'yes' && subValue === true) {
                                displayValue = `${camelCaseToReadableFormat(subKey)}\n`;
                              } else if (subKey === 'contracteption' && subValue === true) {
                                displayValue = 'contracteption: Yes';
                              } else if (
                                typeof subValue === 'string' &&
                                moment(subValue, moment.ISO_8601).isValid()
                              ) {
                                displayValue = `${camelCaseToReadableFormat(subKey)}: ${formatDate(
                                  subValue
                                )}`;
                              } else if (subKey !== 'yes' && subValue === true) {
                                displayValue = camelCaseToReadableFormat(subKey);
                              } else {
                                displayValue = `${camelCaseToReadableFormat(subKey)}: ${subValue}`;
                              }

                              return (
                                <span
                                  key={idx}
                                  className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                                  {displayValue}
                                </span>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              })}
              <CreationInfo
                createdAt={socialHistory?.createdAt}
                createdBy={socialHistory?.createdBy?.fullName}
              />
            </>
          );
        })}
    </SimpleAccordion>
  );
};

export default SocialHistoryOverview;
