import { ia } from '../../../../../../lib/helpers/utility';

export const addAdjustment = (setProcedures, code) => {
  const newAdjustmentObj = { amount: '', group: '', code: '' };

  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure) => {
      if (procedure.code === code) {
        if (procedure.adjustment) {
          return { ...procedure, adjustment: [...procedure.adjustment, newAdjustmentObj] };
        } else {
          return { ...procedure, adjustment: [newAdjustmentObj] };
        }
      }
      return procedure;
    });
  });
};

export const deleteAdjustment = (setProcedures, code, adjustmentIndex) => {
  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure) => {
      if (procedure.code === code && ia(procedure.adjustment)) {
        const newAdjustment = procedure.adjustment.slice();
        newAdjustment.splice(adjustmentIndex, 1);

        return { ...procedure, adjustment: newAdjustment };
      }
      return procedure;
    });
  });
};

export const updateAdjustmentProperty = (
  setProcedures,
  code,
  adjustmentIndex,
  propertyName,
  newValue,
  procKey = false
) => {
  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure, i) => {
      if (procKey && adjustmentIndex === i) {
        return { ...procedure, [propertyName]: newValue };
      } else if (procedure.code === code && ia(procedure.adjustment)) {
        const newAdjustment = procedure.adjustment.slice();

        if (newAdjustment[adjustmentIndex]) {
          newAdjustment[adjustmentIndex] = {
            ...newAdjustment[adjustmentIndex],
            [propertyName]: newValue
          };

          return { ...procedure, adjustment: newAdjustment };
        }
      }

      return procedure;
    });
  });
};
