import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from 'lib/helpers/utility';
import moment from 'moment-timezone';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const Medications = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !ia(currentHpOverviewData?.medicationHistory?.response?.medications)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Medications</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.medicationHistory]?.map((medication) =>
          medication?.response?.medications?.map((item, idx) => (
            <div
              className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
              key={idx}>
              {item?.medication && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Medication</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.medication}
                  </p>
                </div>
              )}

              {item?.sig && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">SIG</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.sig}
                  </p>
                </div>
              )}

              {item?.strength && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Strength</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.strength}
                  </p>
                </div>
              )}

              {item?.quantity && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Quantity</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.quantity}
                  </p>
                </div>
              )}

              {item?.days_supply && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Days supply</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.days_supply}
                  </p>
                </div>
              )}

              {item?.refills && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Refills</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.refills}
                  </p>
                </div>
              )}

              {ia(item?.condition) && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Condition</p>
                  <ul>
                    {item?.condition?.map((condition, index) => (
                      <li
                        key={index}
                        className="list-inside list-disc text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                        {condition?.value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {item?.prescriber && (
                <div className="col-start-1 col-end-4 grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Prescribing physician
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.prescriber?.value}
                  </p>
                </div>
              )}

              {item?.start_date && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Date started</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {moment(item?.dateStarted).format('MMM Do, YYYY')}
                  </p>
                </div>
              )}

              {item?.end_date && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Date ended</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {moment(item?.dateEnded).format('MMM Do, YYYY')}
                  </p>
                </div>
              )}

              {item?.note && (
                <div className="col-start-1 col-end-4 grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.note}
                  </p>
                </div>
              )}
            </div>
          ))
        )}

      {isNarrativeChecked &&
        !!ia(currentHpOverviewData?.medicationHistory?.response?.medications) && (
          <div className={!isTemplateChecked && '!mt-2'}>
            <ListCustomForms
              forms={[currentHpOverviewData?.medicationHistory]?.map((medication) => ({
                ...medication,
                type: 'medicationHistory'
              }))}
              noDetailsText="No known medications"
            />
          </div>
        )}
    </div>
  );
};

export default Medications;
