import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import moment from 'moment';
import { kioskReadRequest } from 'api/Kiosk';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const kinds = {
  idle: 'Idle',
  checkin: 'Check-in',
  form: 'Custom Form',
  payment: 'Payment'
};

export const formatKioskRequest = (request) => {
  return {
    id: request.id,
    title: `${request.patient?.fullName || 'Patient'} is requesting assistance!`,
    time: moment(request.created_at).fromNow(),
    type: `${kinds[request.kind || 'idle']}${request.page ? ` - ${request.page}` : ''}`,
    read: request.read,
    kiosk: {
      color: request.kiosk?.color,
      name: request.kiosk?.name
    }
  };
};

const KioskRequests = ({ requests }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const readRequest = async ({ id, all }) => {
    const req = await kioskReadRequest(navigate, { id, all });
    if (req.code == 0) {
      queryClient.invalidateQueries(['kiosk-requests']);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between px-1">
        <p className="text-base font-medium text-primary-900">Requests</p>
        <p className=" !text-neutral-500 cursor-pointer" onClick={() => readRequest({ all: true })}>
          Mark all as completed
        </p>
      </div>

      <div className="flex flex-col gap-2 mt-3">
        {requests.map((r) => {
          const request = formatKioskRequest(r);
          return (
            <div
              onClick={() => readRequest({ id: r.id })}
              key={request.id}
              className={cs(
                'group bg-white flex gap-6 justify-between rounded-lg w-full items-center !px-4 py-3 border  transition-all',
                !request.read
                  ? '!bg-primary-50 cursor-pointer hover:!bg-neutral-100 hover:!border-neutral-50 !border-primary-100'
                  : '!border-white'
              )}
              data-qa="quick-actions-new-patient">
              <div className="flex w-full">
                <div
                  style={{ backgroundColor: request.kiosk.color + '25' }}
                  className={cs(
                    'mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg p-2',
                    !request.read && '!bg-white'
                  )}>
                  <Icon icon="kiosk" customColor={request.kiosk.color} />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex gap-3 justify-between w-full items-end">
                    <span className="text-neutral-600 !text-sm">{request.kiosk.name}</span>
                    <div className="flex items-center gap-2">
                      <span className="!text-sm text-neutral-600">{request.time}</span>
                      <Icon className={!request.read && 'cursor-pointer'} icon="message-tick" />
                    </div>
                  </div>

                  <p className="font-medium text-primary-900 text-base mt-2">{request.title}</p>
                  <span className="text-sm font-medium text-primary-500">{request.type}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KioskRequests;
