import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';
import {
  Frequency,
  Progress as initialValue,
  ProgressList
} from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from '../../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from '../../../../../../../shared/Icon/Icon';
import Select from '../../../../../../../shared/Select/Select';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { userState } from '../../../../../../../state';

const Progress = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote, cnDisplaySettings } = useClinicalNoteContext();
  const [timer, setTimer] = useState(null);
  const [progress, setProgress] = useState([]);

  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm = cnDisplaySettings?.sections?.progress?.progress?.advancedForm;
  const user = useRecoilValue(userState);
  const textAreaRef = useRef();

  useEffect(() => {
    setProgress(clinicalNote?.epn?.progress || initialValue);
  }, [clinicalNote]);

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { progress } }
  }));

  const syncProgressAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ progressObject: updatedObject });
    } else {
      setProgress(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleChange = (e, key) => {
    const name = e?.target?.name;

    const updatedObject = {
      ...progress,
      [key ?? name]: key ? e : !progress[name]
    };

    syncProgressAndClinicalNotes(updatedObject);
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      epn: {
        ...prevState.epn,
        progress: updatedObject
      }
    }));
  };

  const handleNarrative = ({
    progressObject = progress,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...progressObject, narrative: event };

    setProgress(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ progressObject = progress, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: progressObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'progress'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      progressObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <React.Fragment>
      {isAdvancedForm && (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(284px,1fr))] gap-4 sm:grid-cols-1">
          <div className="grid gap-4">
            <Select
              label="Progress"
              placeholder="Choose progress"
              name="progress"
              value={progress?.progress}
              onChange={(event) => handleChange(event, 'progress')}
              options={ProgressList}
              menuPortalTarget={document.body}
            />
            {progress?.progress?.value === 'Other' && (
              <Textarea
                label="Other progress"
                placeholder="Add other progress note"
                className="min-w-[320px] grow"
                value={progress.progressOther}
                onChange={(event) => handleChange(event?.target.value, 'progressOther')}
              />
            )}
          </div>
          <div className="grid gap-4">
            <Select
              label="Frequency recommendation"
              placeholder="Choose progress"
              name="frequency"
              value={progress?.frequency}
              onChange={(event) => handleChange(event, 'frequency')}
              options={Frequency}
              menuPortalTarget={document.body}
            />
            {progress?.frequency?.value === 'Other' && (
              <Textarea
                label="Other frequency"
                placeholder="Add other frequency note"
                value={progress.frequencyOther}
                onChange={(event) => handleChange(event?.target.value, 'frequencyOther')}
              />
            )}
          </div>
          <div>
            <p className="!pb-3 text-sm font-500 text-neutral-800">Treatment recommendation</p>
            <div className="grid gap-4">
              <Checkbox
                label="Continue current therapeutic focus"
                name="continueCurrentTherapeuticFocus"
                isChecked={progress?.continueCurrentTherapeuticFocus}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              <Checkbox
                label="Change treatment goals or objectives"
                name="changeTreatmentGoalsOrObjectives"
                isChecked={progress?.changeTreatmentGoalsOrObjectives}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
            </div>
          </div>
        </div>
      )}

      <div className="dashed-top !mt-4 !pt-3">
        <NarrativeForm
          syncNarrative={syncNarrative}
          setSyncNarrative={setSyncNarrative}
          onChange={(event) => handleNarrative({ event, sync: false })}
          setCurrentForm={setProgress}
          restData={{
            className: 'w-full',
            label: 'Progress Narrative',
            placeholder: 'Add narrative here',
            id: 'Narrative-progress',
            'data-qa': 'narrative-progress',
            name: 'Narrative-progress',
            value: progress?.narrative,
            forwardedRef: textAreaRef
          }}
          narrativeOptions={isAdvancedForm ? narrativeOptions : []}
        />
      </div>
    </React.Fragment>
  );
};

export default Progress;
