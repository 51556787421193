import React from 'react';

const PermissionsTable = ({ children }) => {
  return (
    <table>
      <thead>
        <tr>
          <td>Name</td>
          <td colSpan={2}>Description</td>
          <td>Value</td>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default PermissionsTable;
