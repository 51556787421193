import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import { ia } from '../../../lib/helpers/utility';
import Button from '../../shared/Button/Button';
import PayFailure from 'components/Patient/checkin/payment/payFailure';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import { AlertContent } from 'components/shared/Alert/Alert';

const DEFAULT_ERROR_MSG = `An unexpected error has occurred. Please try again later.`;

const LightboxWrapper = ({
  f_name,
  l_name,
  billingAddress,
  amount,
  patientId,
  practiceId,
  appointmentId,
  invoiceId,
  tokenOnly,
  saveCard,
  surcharge,
  setReceipt = () => {},
  invoiceToken,
  preAuth,
  preAuthForm,
  setCardId,
  updateAmount = () => {},
  onApproval = () => {
    console.warn('onApproval not handled for LightboxWrapper.jsx');
  },
  onCancel = () => {
    console.warn('onCancel not handled for LightboxWrapper.jsx');
  },
  onFail = () => {
    console.warn('onFail not handled for LightboxWrapper.jsx');
  },
  invoices,
  selectedVtid,
  SuccessScreen,
  payerId
}) => {
  const [token, setToken] = useState(null);
  const [show, setShow] = useState({
    status: '',
    retryTransaction: false,
    approved: false,
    unavailable: false,
    errors: []
  });
  const [txnId, setTxnId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    initiateLightbox();
    // initiateTransaction();
  }, []);

  const initiateLightbox = async () => {
    try {
      const res = await interimApi(
        '/api/transactions/online/getCredentials',
        {
          f_name,
          l_name,
          amount,
          patientId,
          practiceId,
          invoiceId,
          appointmentId,
          saveCard,
          tokenOnly,
          billingAddress,
          invoiceToken,
          preAuth,
          surcharge,
          payer_id: payerId
        },
        navigate
      );
      const { code, error, token: loadedToken, transactionId: loadedTransactionId } = res.data;
      switch (code) {
        // case -1:
        //   navigate(redirect);
        //   break;
        case 0:
          if (loadedToken) {
            setToken(loadedToken);
            openLightbox(loadedToken, loadedTransactionId);
            // initiateTransaction(loadedToken);
          } else {
            // need to check if a selection is needed to be made
            console.error(`Lightbox token not received.`);
            toast.error(`There's been an error.`);
            onFail(
              error ||
                `There's been an error. Please try again later. If the issue persists please contact support.`
            );
          }
          break;
        case 1:
          if (error) {
            if (Array.isArray(error)) {
              setShow({ ...show, unavailable: true, error });
            } else {
              setShow({ ...show, unavailable: true, error: [error] });
            }
          }
          break;
        case 5:
          if (error) toast.error(error);
          break;
        default:
          toast.error(error || DEFAULT_ERROR_MSG);
          onFail(error || DEFAULT_ERROR_MSG);
          break;
      }
    } catch (error) {
      toast.error(`An unexpected error has occurred. Please try again later.`);
      onFail(`An unexpected error has occurred. Please try again later.`);
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const openLightbox = async (token, transactionId) => {
    try {
      const callback = {
        onError(error) {
          toast.error(`There's been an error.`);
          console.error(`Lightbox Error: ${error}`);
          setShow({ ...show, retryTransaction: 'Retry' });
          // showResult('error', error);
          completeTransaction(
            transactionId,
            { txnStatus: 'error', ...error },
            `Failed to finalize transaction. Please try again later.`
          );
        },
        onCancelled() {
          setShow({ ...show, retryTransaction: 'Retry' });
          onCancel();
          // showResult('cancelled', '');
          completeTransaction(transactionId, { txnStatus: 'cancelled' });
        },
        onDeclined(response) {
          setShow({ ...show, retryTransaction: 'Declined. Retry?' });
          // showResult('declined', JSON.stringify(response, null, '\t'));
          completeTransaction(
            transactionId,
            { txnStatus: 'declined', ...response },
            `Payment was declined. Please check card information.`
          );
        },
        async onApproval(response) {
          if (amount === 0 && tokenOnly) {
            addCard(response);
          } else {
            completeTransaction(transactionId, { txnStatus: 'approved', ...response });
          }
        }
      };
      window.PayWithConverge.open({ ssl_txn_auth_token: token }, callback);
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  const completeTransaction = async (transactionId, response, msg) => {
    try {
      const res = await interimApi(
        '/api/transactions/online/sale',
        { token, invoices, invoiceToken, transactionId, transaction: response, payerId },
        navigate
      );

      console.log({ token, invoices, invoiceToken, transactionId, transaction: response, payerId });
      const { code, redirect, error, cardId } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setShow({ ...show, retryTransaction: false, approved: true });
          setCardId && setCardId(cardId);
          if (response.txnStatus === 'approved') {
            if (transactionId) {
              setTxnId(transactionId);
            }
            setReceipt && setReceipt(res.data.receipt.data);
            onApproval();
            updateAmount(amount);
          } else {
            if (msg) {
              onFail(msg);
            } else if (response.errorCode) {
              onFail([
                response.errorName,
                `Error Code: ${response.errorCode}`,
                response.errorMessage
              ]);
            }
          }
          break;
        default:
          toast.error(error || DEFAULT_ERROR_MSG);
          onFail(error || DEFAULT_ERROR_MSG);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  const addCard = async (response) => {
    let newShow = Object.assign({}, show);
    try {
      const res = await interimApi(
        '/api/transactions/token/add',
        {
          targetUserId: patientId,
          preAuthForm,
          onlineCard: { ...response, terminal_id: selectedVtid }
        },
        navigate
      );
      const { code, redirect, error, card } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          onApproval({ card: card || {} });
          break;
        default:
          if (error) toast.error(error);
          else toast.error(`An unexpected error has occurred. Please try again later.`);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later. ${error}`);
    }
    setShow(newShow);
  };

  if (show.unavailable) {
    return (
      <PayFailure
        onCancel={onCancel}
        errorMessage={
          ia(show.errors) && (
            <div>
              <div>Errors:</div>
              <ul>
                {show.errors.map((v, idx) => (
                  <li key={idx}>{v}</li>
                ))}
              </ul>
            </div>
          )
        }
        showTryAgain={false}
      />
    );
  }

  if (show.approved) {
    if (SuccessScreen) {
      return (
        <ErrorBoundary
          additionalText={<AlertContent color="success" title="Transaction Success" />}>
          <SuccessScreen transactionId={txnId} />
        </ErrorBoundary>
      );
    }
    return (
      <div className="Transaction--inprocess flex h-full flex-col items-center justify-center !gap-5">
        Approved
      </div>
    );
  }

  if (show.retryTransaction) {
    return (
      <div className="Transaction--inprocess flex h-full flex-col items-center justify-center !gap-5">
        <Button warning onClick={initiateLightbox}>
          {show.retryTransaction}
        </Button>
      </div>
    );
  }

  return (
    <div className="Transaction--inprocess flex h-full flex-col items-center justify-center !gap-5">
      <p className="fs-16 font-600">Transaction in progress...</p>
      <Button danger onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default withErrorBoundary(LightboxWrapper);
