import React from 'react';

import Icon from '../../../../../../shared/Icon/Icon';
import Select from '../../../../../../shared/Select/Select';
import CurrencyInput from '../../../../../../shared/CurrencyInput/CurrencyInput';
import { ia, optionify } from '../../../../../../../lib/helpers/utility';
import { addAdjustment, deleteAdjustment, updateAdjustmentProperty } from '../EOBHelpers';

const AllocateAdjustment = ({ adjustments, groupList, codeList, setProcedures, procedureCode }) => {
  return (
    <div className="flex !gap-1 flex-col">
      {ia(adjustments) &&
        adjustments?.map(({ amount, group, code }, idx) => {
          return (
            <div className="flex !gap-1" key={idx}>
              <CurrencyInput
                value={amount * 100}
                parentClassName="w-24"
                label={idx === 0 && 'Amount'}
                wrapperClassName="!px-[6px] !py-[6px]"
                onValueChange={(amount) =>
                  updateAdjustmentProperty(
                    setProcedures,
                    procedureCode,
                    idx,
                    'amount',
                    (amount / 100).toFixed(2)
                  )
                }
              />

              <Select
                isClearable={false}
                inputId="selectGroup"
                className="!w-24 font-400"
                label={idx === 0 && 'Group'}
                value={{ value: group, label: group }}
                options={optionify(groupList, 'label', 'value')}
                onChange={({ value }) =>
                  updateAdjustmentProperty(setProcedures, procedureCode, idx, 'group', value)
                }
              />

              <Select
                isClearable={false}
                inputId="selectCode"
                className="!w-24 font-400"
                label={idx === 0 && 'Code'}
                value={{ value: code, label: code }}
                options={optionify(codeList, 'label', 'value')}
                onChange={({ value }) =>
                  updateAdjustmentProperty(setProcedures, procedureCode, idx, 'code', value)
                }
              />

              <Icon
                icon="trash"
                color="neutral"
                className={idx === 0 && `!mt-6`}
                onClick={() => deleteAdjustment(setProcedures, procedureCode, idx)}
              />
            </div>
          );
        })}

      <div className="flex ">
        <div
          className="flex cursor-pointer items-center"
          onClick={() => addAdjustment(setProcedures, procedureCode)}>
          <Icon icon="new-add-circle" className="cursor-pointer" />

          <p className="!m-2 w-full !text-right text-sm text-primary-500" data-qa="add-adjustment">
            Add Adjustment
          </p>
        </div>
      </div>
    </div>
  );
};

export default AllocateAdjustment;
