import { filterFalsyValues } from 'components/practice/charts/ClinicalNote/lib/filterFalsyValues';
import { sortObjectByProperty } from 'lib/helpers/utility';
import React from 'react';

export function getEnabledComponents(
  func = () => {},
  type,
  cnDisplaySettings,
  advancedList,
  isCustomForm = false
) {
  const advancedTitles = generateAdvancedTitles(advancedList, cnDisplaySettings, type);

  if (isCustomForm) {
    return sortObjectByProperty(advancedTitles, 'position');
  }

  const enabledComponents = filterFalsyValues(func(cnDisplaySettings, advancedTitles));

  const sortedComponents = sortObjectByProperty(enabledComponents, 'position');

  return sortedComponents;
}

function generateAdvancedTitles(advancedList, cnDisplaySettings, type) {
  const currentFormSection = cnDisplaySettings?.sections?.[type];
  const advancedForms = advancedList
    ?.map((item) => {
      if (currentFormSection?.[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    })
    .filter(Boolean);

  const advancedTitles =
    Array.isArray(advancedForms) &&
    Object.fromEntries(
      advancedForms.map((item) => {
        const title = item?.custom_title;
        return [
          title,
          {
            title: item?.form?.name || item?.name,
            path: title,
            position: currentFormSection?.[item?.custom_title]?.position
          }
        ];
      })
    );
  return advancedTitles;
}

export function assignAdvTitlesToComponent(advancedList, advancedComponent, cnDisplaySettings) {
  const currAdvForm = (key) =>
    advancedList?.find((item) => {
      const title = item?.custom_title;

      if (key === title) return item;
    });

  const advancedTitles = generateAdvancedTitles(advancedList, cnDisplaySettings);
  return Object.keys(advancedTitles).reduce((acc, key) => {
    const Component = advancedComponent;
    const test = currAdvForm(key);

    acc[key] = () => <Component currAdvForm={test} />;
    return acc;
  }, {});
}
