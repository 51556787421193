const moment = require('moment-timezone');
const {
  insurancePatientRelation,
  genders,
  defaultSettings,
  marital_status,
  employmentType,
  yesAndNoOptions,
  frequencyCode,
  tax_id_types
} = require('../../constants');
const customFormInitialStates = require('../../components/shared/Forms/Custom/CustomFormInitialStates');
const { badPwds } = require('./10kbadPwds');
const _ = require('lodash');

exports.Capitalize = (s) => {
  if (s) {
    if (s.length > 0) {
      let sa = s.split(/ /g);
      let r = '';
      sa.forEach((e, j) => {
        r += e[0]?.toUpperCase();
        for (let i = 1; i < e.length; i++) {
          r += e[i].toLowerCase();
        }
        if (j < sa.length - 1) {
          r += ' ';
        }
      });
      return r;
    } else {
      return s;
    }
  } else {
    return s;
  }
};

exports.optionify = (arr = [], label = 'name', value = 'id', meta) => {
  if (this.ia(arr)) {
    if (['string', 'number'].includes(typeof arr[0])) {
      return arr.map((v) => {
        return { label: v, value: v };
      });
    } else {
      let is_label_string = typeof label === 'string';
      let is_value_string = typeof value === 'string';
      let has_meta = meta !== undefined;
      let l = '';
      let v = '';
      return arr.map((e) => {
        if (is_label_string) {
          l = e[label];
        } else {
          l = label(e);
        }
        if (is_value_string) {
          v = e[value];
        } else {
          v = value(e);
        }
        if (has_meta) {
          return { label: l, value: v, meta: meta(e) };
        } else {
          return { label: l, value: v };
        }
      });
    }
  }
  if (typeof arr === 'object') {
    let keys = Object.keys(arr);
    let r = [];
    for (let i = 0; i < keys.length; i++) {
      r.push({ label: arr[keys[i]], value: keys[i] });
    }
    return r;
  }
  return [];
};

exports.Truncate = (s, len) => {
  if (s) {
    return s.length > len ? s.substring(0, len) + '...' : s;
  } else {
    return s;
  }
};

exports.formatPhoneNumber = (phoneNumberString = '') => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

exports.randomString = (length = 0) => {
  if (length > 0) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } else {
    return '';
  }
};

exports.verifyEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

exports.verifyPassword = (pass) => {
  let r = { strength: 'weak', messages: [], password_ok: true };
  // list of banned characters which cannot be present in the password
  const banned_characters = ['"', "'"];
  // presence of illegal characters in the password
  let illegal_char = false;
  // number of upper case letters in the password
  let upperCount = 0;
  // number of lower case letters in the password
  let lowerCount = 0;
  // number of special characters in the password
  let specialCount = 0;
  // strength count
  let password_strength = 0;
  // check the password
  for (let i = 0; i < pass.length; i++) {
    for (let j = 0; j < banned_characters.length; j++) {
      if (pass[i] === banned_characters[j] && !illegal_char) {
        r.password_ok = false;
        illegal_char = true;
        r.messages.push({
          type: 'weak',
          message: `Contains illegal characters (Password cannot contain ', ")`
        });
      }
    }

    if (pass.charCodeAt(i) > 64 && pass.charCodeAt(i) < 91) {
      upperCount++;
    } else if (pass.charCodeAt(i) > 96 && pass.charCodeAt(i) < 123) {
      lowerCount++;
    } else {
      specialCount++;
    }
  }

  // check if the password is a common one from the most commonly used passwords
  if (pass.length > 3 && badPwds.includes(String(pass).toLowerCase())) {
    // the password is one of the common phrases
    r.messages.push({
      type: 'weak',
      message: `Do not use common phrases or passwords`
    });
    r.password_ok = false;
  }

  if (upperCount === 0) {
    r.messages.push({ type: 'weak', message: 'No upper case characters present' });
    r.password_ok = false;
  } else if (upperCount === 1) {
    r.messages.push({ type: 'medium', message: 'Contains one upper case characters' });
    r.password_ok = r.password_ok && true;
    password_strength += 1;
  } else if (upperCount > 1) {
    r.messages.push({ type: 'strong', message: 'Contains multiple upper case characters' });
    r.password_ok = r.password_ok && true;
    password_strength += upperCount / 2;
  }

  if (lowerCount === 0) {
    r.messages.push({ type: 'weak', message: 'No upper case characters present' });
    r.password_ok = false;
  } else if (lowerCount === 1) {
    r.messages.push({ type: 'medium', message: 'Only one lower case character present' });
    r.password_ok = r.password_ok && true;
    password_strength += 1;
  } else if (lowerCount > 1) {
    r.messages.push({ type: 'strong', message: 'Multiple lower case characters present' });
    r.password_ok = r.password_ok && true;
    password_strength += lowerCount / 4;
  }

  if (specialCount === 0) {
    r.messages.push({ type: 'weak', message: 'No special characters present' });
    r.password_ok = false;
  } else if (specialCount === 1) {
    r.messages.push({ type: 'medium', message: 'Only one special character present' });
    r.password_ok = r.password_ok && true;
    password_strength += 1;
  } else if (specialCount > 1) {
    r.messages.push({ type: 'strong', message: 'Multiple lower case characters present' });
    r.password_ok = r.password_ok && true;
    password_strength += specialCount / 2;
  }

  if (pass.length < 6) {
    r.messages.push({ type: 'weak', message: 'Must be longer than 6 characters' });
    r.password_ok = false;
  } else if (pass.length >= 6 && pass.length < 12) {
    r.messages.push({ type: 'medium', message: 'Password fulfills minimum length' });
    r.password_ok = r.password_ok && true;
    password_strength += 1;
  } else if (pass.length >= 12) {
    r.messages.push({ type: 'strong', message: 'Password is of a good length' });
    r.password_ok = r.password_ok && true;
    password_strength += pass.length / 4;
  }

  if (password_strength >= 0 && password_strength < 4) {
    r.strength = 'weak';
  } else if (password_strength >= 4 && password_strength < 10) {
    r.strength = 'medium';
  } else if (password_strength >= 10) {
    r.strength = 'strong';
  }

  r.strengthValue = password_strength;

  return r;
};

exports.ia = (a, length = 0) => Array.isArray(a) && a?.length > length;

exports.iaRa = (a, length = 0) => (Array.isArray(a) && a.length > length ? a : []);

exports.search = (array, key, prop) => {
  prop = typeof prop === 'undefined' ? 'value' : prop;

  for (var i = 0; i < array.length; i++) {
    if (array[i][prop] === Number(key)) {
      return array[i]?.abbreviation || array[i]?.label;
    }
  }
};

exports.mString = (inN, cents = true) => {
  let n = 0;

  if (!isNaN(parseInt(inN))) {
    n = inN;
  }

  let formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Math.abs(n / (cents ? 100 : 1)));

  if (n < 0) {
    return `-${formattedNumber}`;
  }

  return formattedNumber;
};

exports.formatLargeNumber = (n) => {
  return new Intl.NumberFormat('en-US').format(n);
};

exports.random = (min, max) => parseInt(Math.floor(Math.random() * (max - min + 1)) + min);

exports.pm = (permissions, requiredPermissions) => {
  try {
    if (exports.ia(requiredPermissions)) {
      // iterate over required permissions to decide if the permissions are present
      for (let i = 0; i < requiredPermissions.length; i++) {
        if (!_.get(permissions, requiredPermissions[i])) {
          return false;
        }
      }
    } else {
      return _.get(permissions, requiredPermissions);
    }
    return true;
  } catch (error) {
    console.error('pmError', error);
    return false;
  }
};

exports.pmor = (permissions, requiredPermissions) => {
  try {
    if (exports.ia(requiredPermissions)) {
      // iterate over required permissions to decide if the permissions are present
      for (let i = 0; i < requiredPermissions.length; i++) {
        if (_.get(permissions, requiredPermissions[i])) {
          return true;
        }
      }
      return false;
    } else {
      return _.get(permissions, requiredPermissions);
    }
  } catch (error) {
    console.error('pmError', error);
    return false;
  }
};

exports.centsToDollar = (amount) => {
  return amount / 100;
};

exports.mergeStrings = (string_one, string_two) => {
  return `${string_one} ${string_two}`;
};

exports.keyChanges = (base, object) => {
  // courtesy of https://gist.github.com/Yimiprod/7ee176597fef230d1451
  const changes = {};

  function walk(base, object, path = '') {
    for (const [key, value] of Object.entries(object)) {
      const currentPath = Array.isArray(object)
        ? path + `[${key}]`
        : path === ''
          ? key
          : `${path}.${key}`;

      if (base[key] === undefined) {
        // changes[currentPath] = '+';
      } else if (value !== base[key]) {
        if (typeof value === 'object' && typeof base[key] === 'object') {
          walk(base[key], value, currentPath);
        } else {
          changes[currentPath] = object[key];
        }
      }
    }
  }

  walk(base, object);

  return changes;
};

exports.io = (o, length = 0) => Object.keys(o || {})?.length > length;

exports.formatDateAndTime = (dateAndTime, timezone, onlyNumbers, otherFormat = '') => {
  const format = onlyNumbers ? 'MM/DD/YYYY h:mm A' : 'MMM D, YYYY h:mm A';

  return moment.tz(dateAndTime, timezone ?? undefined).format(otherFormat || format);
};

exports.formatDateAndTimeZ = (dateAndTime, timezone) => {
  return moment.tz(dateAndTime, timezone ?? undefined).format('MMM D, YYYY h:mm A z');
};

exports.formatTimeZ = (dateAndTime, timezone) => {
  return moment.tz(dateAndTime, timezone ?? undefined).format('hh:mm A');
};

exports.formatDate = (date, timezone, onlyNumbers = false) => {
  const format = onlyNumbers ? 'MM/DD/YYYY' : 'MMM D, YYYY';

  return moment.tz(date, timezone ?? undefined).format(format);
};

exports.formatTime = (date, timezone, format = 'h:mm A') => {
  return moment.tz(date, timezone ?? undefined).format(format);
};

exports.splitName = (fullName) => {
  const nameParts = fullName?.split(' ');
  let firstName = '';
  let middleName = '';
  let lastName = '';
  let startIndex, endIndex, subArr, mName;

  switch (nameParts.length) {
    case 1:
      firstName = nameParts[0];
      break;
    case 2:
      firstName = nameParts[0];
      lastName = nameParts[1];
      break;
    case 3:
      firstName = nameParts[0];
      middleName = nameParts[1];
      lastName = nameParts[2];
      break;
    default:
      startIndex = 1;
      endIndex = nameParts.length - 2;
      subArr = nameParts.slice(startIndex, endIndex);
      mName = subArr.join(' ');
      firstName = nameParts[0];
      middleName = mName;
      lastName = nameParts[nameParts.length - 1];
      break;
  }

  return {
    firstName,
    middleName,
    lastName
  };
};

exports.isEmpty = (value) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

exports.replaceNullValues = (obj) => {
  const cleanedObject = { ...obj };
  for (let key in cleanedObject) {
    if (cleanedObject[key] === null) {
      cleanedObject[key] = '';
    }
  }
  return cleanedObject;
};

exports.findRelationName = (relationType) => {
  const relation = insurancePatientRelation?.find((relation) => {
    if (relation?.value == relationType) {
      return relation;
    }
  });

  return relation && relation?.label;
};

exports.findStateName = ({ states, stateId }) => {
  if (!this.ia(states)) return;

  const state = states?.find((state) => {
    if (state.value == stateId) {
      return state;
    }
  });

  return state && state?.label;
};

exports.findGenderName = (genderValue) => {
  if (!this.ia(genders)) return;

  const gender = genders?.find((gender) => {
    if (gender.value === genderValue) return gender;
  });

  return gender;
};

exports.mapGenderValue = (value) => {
  if (!value) return null;

  if (value === 'other') return 'U';
  if (value === 'female' || value === 'male') return this.Capitalize(value)[0];

  return 'U';
};

exports.initials = ({ fullName = '', fName = '', lName = '' }) => {
  let firstName = fName,
    lastName = lName;
  if (fullName) {
    const separated = fullName.split(' ');
    firstName = separated[0];
    lastName = separated[separated.length - 1];
  }
  return firstName ? firstName[0] : '' + lastName ? lastName[0] : '';
};

exports.formatDateRelativeToToday = (date, timezone) => {
  const now = moment();
  const dateToFormat = moment.tz(date, timezone ?? undefined);
  const diffInDays = now.diff(dateToFormat, 'days');

  let formattedDate;
  if (diffInDays === 0) {
    formattedDate = 'TODAY';
  } else if (diffInDays === 1) {
    formattedDate = 'YESTERDAY';
  } else {
    formattedDate = `${diffInDays} days ago`;
  }

  return formattedDate;
};

exports.formatTimeRelativeToToday = (date, timezone) => {
  const now = moment();
  const dateToFormat = moment.tz(date, timezone ?? undefined);

  const diffInMinutes = now.diff(dateToFormat, 'minutes');
  if (diffInMinutes < 60) {
    return diffInMinutes === 0 ? 'now' : `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
  }

  const diffInHours = now.diff(dateToFormat, 'hours');
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  }

  const diffInDays = now.diff(dateToFormat, 'days');
  return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
};

exports.cmToFt = (cm) => {
  if (cm) {
    const feet = Math.floor(cm / 30.48);
    const inches = Math.round((cm / 2.54) % 12);

    return feet + "'" + inches;
  } else {
    return '';
  }
};

exports.orderById = (arrayToSort, arrayBasedOn) => {
  if (arrayToSort && arrayToSort.length > 0) {
    return arrayToSort.sort(
      (a, b) =>
        arrayBasedOn.find((fp) => fp.id === a.id).order -
        arrayBasedOn.find((fp) => fp.id === b.id).order
    );
  }
};

exports.snakeToTitleCase = (s) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());

exports.removeUnderscores = (text) => {
  if (typeof text !== 'string') return text;
  return text.replace(/_/g, ' ');
};

exports.checkValueExists = (value) => {
  if (Array.isArray(value)) return this.ia(value);

  if (typeof value === 'string') return !this.isEmpty(value);

  return false;
};

exports.isEqual = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return (
      value1.length === value2.length &&
      value1.every((elem, index) => this.isEqual(elem, value2[index]))
    );
  }
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);
    return (
      keys1.length === keys2.length && keys1.every((key) => this.isEqual(value1[key], value2[key]))
    );
  }
  return String(value1) === String(value2);
};

exports.compareObjects = (obj1, obj2) => {
  const props1 = Object.keys(obj1);
  const props2 = Object.keys(obj2);

  if (props1.length !== props2.length) return false;

  for (let prop of props1) {
    if (!props2.includes(prop)) return false;

    const value1 = obj1[prop];
    const value2 = obj2[prop];

    if (!this.isEqual(value1, value2)) return false;
  }

  return true;
};

exports.isSameArray = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;

  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (!this.compareObjects(arr1[i], arr2[i])) return false;
  }

  return true;
};

exports.responseUpdated = ({ currentForm, submitResponse }) => {
  if (currentForm?.response) {
    const parsedResponse = JSON.parse(currentForm.response.json.fields);

    return !this.isSameArray(submitResponse, parsedResponse);
  }

  if (!currentForm?.json?.fields) {
    return submitResponse.some((response) => this.checkValueExists(response?.value));
  }

  const fields =
    typeof currentForm?.json?.fields === 'string'
      ? JSON.parse(currentForm?.json?.fields)
      : currentForm?.json?.fields;
  const initialFormState = fields.find((field) => field.key)?.key;

  if (!initialFormState) {
    if (typeof currentForm?.json?.fields === 'string' && JSON.parse(currentForm?.json?.fields)) {
      let parsedCurrentForm = JSON.parse(currentForm?.json?.fields);

      parsedCurrentForm = parsedCurrentForm.map((row) => {
        const rowHasDatePickerProp = row.name.includes('date_picker');
        const rowWithNoValueProp = !Object.prototype.hasOwnProperty.call(row, 'value');

        if (rowHasDatePickerProp && rowWithNoValueProp) {
          return { ...row, value: undefined };
        }

        return row;
      });

      const checkIfObjectsSame = this.compareObjects(parsedCurrentForm, submitResponse);

      return !checkIfObjectsSame;
    } else {
      return submitResponse.some((response) => {
        if (response?.name?.includes('dropdown') && response?.value === '_') return false;

        return this.checkValueExists(response?.value);
      });
    }
  }

  return currentForm.json.fields
    ?.filter((field) => !field.static)
    ?.some((field, index) => {
      const currentResponse = submitResponse[index]?.value;

      if (field?.key) {
        const fieldKey = field.key;

        let initialObj = customFormInitialStates[fieldKey];

        if (fieldKey === 'ReviewSystems') {
          initialObj = {
            practitioner: customFormInitialStates[fieldKey],
            patient: customFormInitialStates[fieldKey]
          };
        }

        return submitResponse[index]?.value !== JSON.stringify(initialObj);
      }

      return this.checkValueExists(currentResponse);
    });
};

exports.showCardExpiration = (cardExpiration) => {
  if (!cardExpiration) return 'N/A';

  const firstPart = cardExpiration.slice(0, 2);
  const secondPart = cardExpiration.slice(2);

  return `${firstPart}/${secondPart}`;
};

exports.getFullAddress = ({ address, states }) => {
  if (!this.io(address) || !this.ia(states)) return;

  let stateName = '';

  const {
    address_ln_1 = '',
    address_ln_2 = '',
    city = '',
    state = '',
    country = '',
    zip = ''
  } = address || {};

  if (typeof state === 'number') stateName = this.search(states, state, 'id');

  const merge = [address_ln_1, address_ln_2, city, stateName, country, zip]
    .filter(Boolean)
    .join(', ')
    .trim();

  return merge;
};

exports.calculateBMI = ({ weight, height }) => {
  if (!weight || !height) return 0;

  const w = Number(weight) / 2.205;
  const h = (Number(height) / 100) ** 2;

  return (w / h).toFixed(1);
};

exports.deleteKeys = ({ object, keys }) => {
  keys?.forEach(function (key) {
    if (Object.prototype.hasOwnProperty.call(object, key)) delete object[key];
  });

  return object;
};

exports.updateAppointmentFilters = ({
  setUserState = false,
  data = {},
  clearData = false
} = {}) => {
  setUserState &&
    setUserState((prevState) => ({
      ...prevState,
      display_settings: {
        ...prevState.display_settings,
        appointmentFilters: clearData
          ? defaultSettings.appointmentFilters
          : {
              ...prevState.display_settings.appointmentFilters,
              ...data
            }
      }
    }));
};

// use: filePath(import.meta.url)
exports.filePath = (metaUrl) => {
  const url = new URL(metaUrl);

  const path = url?.pathname;
  // const filename = path.split('/').pop();

  return path.split('/myriad-frontend')[1];
  // return { path, filename };
};

const keysToKeep = ['id', 'name', 'email', 'error', 'code'];

exports.sanitizeResponse = (obj) => {
  if (!obj) return null;

  let k = Object.keys(obj);
  let r = {};
  for (let i = 0; i < k.length; i++) {
    if (typeof obj[k[i]] === 'object') {
      if (Array.isArray(obj[k[i]])) {
        r[k[i]] = [];
        let keyAllowed = false;
        for (let key in keysToKeep) {
          if (k[i].toLowerCase().includes(keysToKeep[key])) {
            keyAllowed = true;
          }
        }
        for (let j = 0; j < obj[k[i]].length; j++) {
          if (typeof obj[k[i]][j] === 'object') {
            const newKI = exports.sanitizeResponse(obj[k[i]][j]);
            if (newKI) r[k[i]][j] = newKI;
          } else if (
            (typeof obj[k[i]][j] === 'string' || typeof obj[k[i]][j] === 'number') &&
            keyAllowed
          ) {
            r[k[i]][j] = obj[k[i]][j];
          }
        }
      } else {
        let newKI = exports.sanitizeResponse(obj[k[i]]);
        if (newKI) {
          r[k[i]] = newKI;
        }
      }
    } else {
      for (let key in keysToKeep) {
        if (k[i].toLowerCase().includes(keysToKeep[key])) r[k[i]] = obj[k[i]];
      }
    }
  }
  return r;
};

exports.getOrdinalSuffix = ({ number = null }) => {
  if (!number) return null;

  let suffix = null;
  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      suffix = 'st';
      break;
    case 2:
      suffix = 'nd';
      break;
    case 3:
      suffix = 'rd';
      break;
    default:
      suffix = 'th';
      break;
  }

  return `${number}${suffix}`;
};

exports.lbsToKg = (lbs) => parseFloat(lbs * 0.45359237).toFixed(2);
exports.cmToInch = (cm) => parseFloat((cm / 2.54).toFixed(2));
exports.inchToCm = (inch) => parseFloat(inch * 2.54).toFixed(2);

exports.separatePoundsAndOunces = (weightInPounds) => {
  const pounds = Math.floor(weightInPounds);
  const ounces = (weightInPounds - pounds) * 16; // 1 pound = 16 ounces
  return { pounds: parseInt(pounds) || '', ounces: parseInt(ounces) || '' };
};

exports.separateFeetAndInches = (heightInInches) => {
  const feet = Math.floor(heightInInches / 12);
  const inches = heightInInches % 12;
  return { feet: parseInt(feet) || '', inches: parseInt(inches) || '' };
};

exports.spaceToUppercase = (title) => {
  if (title) {
    return title
      .split(' ')
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join('');
  }
  return '';
};

exports.spaceToKebabCase = (title) => {
  if (title) {
    const cleanedTitle = title.toLowerCase().replace(/[^\w\s]/g, '');
    const kebabCaseTitle = cleanedTitle.replace(/\s+/g, ' ').split(' ').join('-');
    return kebabCaseTitle;
  }
  return '';
};

exports.camelToKebabCase = (title) => {
  if (typeof title === 'string') {
    const kebabCaseTitle = title.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return kebabCaseTitle;
  }
  return '';
};

exports.camelToKebabKeys = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      newObj[kebabKey] = obj[key];
    }
  }
  return newObj;
};

exports.camelCaseToReadableFormat = (text) => {
  const words = text?.replace(/([a-z])([A-Z])/g, '$1 $2')?.split(/(?=[A-Z])/);
  const readableText = this.iaRa(words)
    ?.map((word) => word.toLowerCase())
    .join(' ');
  const capitalizeText = readableText?.charAt(0).toUpperCase() + readableText?.slice(1);
  return capitalizeText;
};

exports.camelToSpaceCase = (title) => {
  if (typeof title === 'string') {
    const spaceCaseTitle = title.replace(/([a-z])([A-Z])/g, '$1 $2');
    return spaceCaseTitle.charAt(0).toUpperCase() + spaceCaseTitle.slice(1);
  }
  return '';
};

exports.sortObjectByProperty = (obj, prop) => {
  const entries = Object.entries(obj);
  entries.sort(([, a], [, b]) => a[prop] - b[prop]);
  const sortedObject = {};
  entries.forEach(([key, value]) => {
    sortedObject[key] = value;
  });
  return sortedObject;
};

exports.formatCurrencyValue = (value) => {
  return value ? (value / 100).toFixed(2) : '0.00';
};

exports.reShapePractitioners = (practitioners = []) => {
  return practitioners?.map(({ id, f_name, l_name, profile_photo, virtual_link }) => ({
    value: id,
    label: `${f_name} ${l_name}`,
    virtual_link,
    ...(profile_photo && {
      image: `https://cdn.filestackcontent.com/${JSON.parse(profile_photo || `{}`)?.jpg}`
    })
  }));
};

exports.reShapeProcedures = (procedures = []) => {
  return procedures?.map((service) => ({
    ...service,
    value: service.id,
    label: service.name,
    timeLength: service?.time_length
  }));
};

exports.groupByDate = (data = [], field = 'created_at', format = 'MMM D, YYYY') => {
  return data.reduce((grouped, item) => {
    const date = moment(item[field]).format(format);

    if (!grouped[date]) grouped[date] = [];

    grouped[date].push(item);

    return grouped;
  }, {});
};

exports.sumBy = (items = [], key) => {
  // support nested key
  if (key.includes('.')) {
    const keys = key.split('.');
    const nestedKey = keys.pop();

    return (
      items?.reduce?.((total, item) => {
        const nestedValue = keys.reduce((obj, parentKey) => obj?.[parentKey], item);
        return total + Number(nestedValue?.[nestedKey] || 0) || 0;
      }, 0) || 0
    );
  }

  return items?.reduce?.((total, item) => total + Number(item[key] || 0), 0) || 0;
};

exports.arrayToObject = (items = [], key, value) => {
  if (!key || !value) return items || [];

  return (
    items.reduce((acc, item) => {
      acc[item[key]] = item[value];
      return acc;
    }, {}) || {}
  );
};

exports.convertJSONToCSV = (jsonData) => {
  const csvRows = [];
  const isArray = Array.isArray(jsonData);

  if (isArray && !jsonData.length > 0) {
    return;
  }

  const headers = Object.keys(isArray ? jsonData[0] : jsonData);
  csvRows.push(headers.join(','));

  if (isArray) {
    for (const row of jsonData) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
  } else {
    const values = headers.map((header) => {
      const escaped = ('' + jsonData[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};

exports.downloadCSV = (csvContent, filename = 'data.csv') => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

exports.claimCSV = (claim) => {
  const matchedRelation = insurancePatientRelation.find((item) => item.label === claim?.pat_rel);
  const matchedMaritalStatus = marital_status.find((item) => item.label === claim?.pat_marital);
  const matchedEmplStatus = employmentType.find((item) => item.label === claim?.pat_employment);
  const matchedEmplRelStatus = yesAndNoOptions.find(
    (item) => item.label === claim?.employment_related
  );
  const matchedAccidentStatus = yesAndNoOptions.find((item) => item.label === claim?.auto_accident);
  const matchedOtherAccidentStatus = yesAndNoOptions.find(
    (item) => item.label === claim?.other_accident
  );
  const matchedOtherPtRelStatus = insurancePatientRelation.find(
    (item) => item.label === claim?.other_pat_rel
  );
  const matchedFrequencyStatus = frequencyCode.find((item) => item.label === claim?.frequency_code);
  const matchedTaxTypesStatus = tax_id_types.find((item) => item.label === claim?.bill_taxid_type);
  const matchedASStatus = yesAndNoOptions.find((item) => item.label === claim?.accept_assign);
  if (claim.cond_date) claim['cond_date'] = moment(claim.cond_date).format('MM/DD/YYYY');
  claim?.procedures?.map((procedure, index) => {
    claim[`charge_${index + 1}`] = parseFloat(procedure.charge * parseInt(procedure.units)).toFixed(
      2
    );
    claim[`diag_ref_${index + 1}`] = procedure.diagnosis;
    claim[`from_date_${index + 1}`] = moment(procedure.from_date).format('MM/DD/YYYY');
    if (this.ia(procedure?.modifiers)) {
      procedure.modifiers.map((modifier, i) => {
        claim[`mod${i + 1}_${index + 1}`] = modifier;
      });
    } else if (typeof procedure?.modifier === 'string') {
      procedure?.modifiers?.split(',').map((modifier, i) => {
        claim[`mod${i + 1}_${index + 1}`] = modifier;
      });
    }
    claim[`narrative_${index + 1}`] = procedure.narrative;
    claim[`proc_code_${index + 1}`] = procedure.code;
    claim[`thru_date_${index + 1}`] = moment(procedure.thru_date).format('MM/DD/YYYY');
    claim[`units_${index + 1}`] = procedure.units;
    claim[`place_of_service_${index + 1}`] = procedure.place_of_service;
    claim['remote_claimid'] = claim.id;
    claim['pat_dob'] = moment(claim.pat_dob).format('YYYY-MM-DD');
    claim['ins_dob'] = claim.ins_dob && moment(claim.ins_dob).format('YYYY-MM-DD');
    claim['pat_sex'] = this.mapGenderValue(claim.pat_sex);
    claim['ins_sex'] = this.mapGenderValue(claim.ins_sex);
    claim['other_ins_sex'] = this.mapGenderValue(claim.other_ins_sex);
    claim['pat_rel'] = matchedRelation?.value || claim?.pat_rel || null;
    claim['pat_marital'] = matchedMaritalStatus?.value || claim?.pat_marital || null;
    claim['pat_employment'] = matchedEmplStatus?.value || claim?.pat_employment || null;
    claim['employment_related'] = matchedEmplRelStatus?.value || claim?.employment_related || null;
    claim['auto_accident'] = matchedAccidentStatus?.value || claim?.auto_accident || null;
    claim['other_accident'] = matchedOtherAccidentStatus?.value || claim?.other_accident || null;
    claim['other_pat_rel'] = matchedOtherPtRelStatus?.value || claim?.other_pat_rel || null;
    claim['frequency_code'] = matchedFrequencyStatus?.value || claim?.frequency_code || null;
    claim['bill_taxid_type'] = matchedTaxTypesStatus?.value || claim?.bill_taxid_type || null;
    claim['accept_assign'] = matchedASStatus?.value || claim?.accept_assign || null;
  });

  this.deleteKeys({
    object: claim,
    keys: [
      'state',
      'errors',
      'superbill',
      'custom_id',
      'updated_at',
      'created_at',
      'procedures',
      'practice_id',
      'practitioner',
      'state_history',
      'insurance_payment'
    ]
  });

  claim = this.replaceNullValues(claim);

  const csv = this.convertJSONToCSV(claim);
  return csv;
};

function hexToRgb(hex) {
  // Remove the hash character (#) if it's present
  hex = hex?.replace(/^#/, '');

  // Parse the hexadecimal color into its RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

function calculateLuminance(hex) {
  const { r, g, b } = hexToRgb(hex);

  // Calculate the relative luminance using the formula
  const luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  return luminance;
}

exports.isColorLight = (hex) => {
  const luminance = calculateLuminance(hex);

  // You can adjust the threshold (0.5) to determine what you consider "light" or "dark"
  return luminance > 0.75;
};

exports.getKeysByType = (obj, type) => {
  const numberKeys = [];
  const nonNumberKeys = [];

  for (const key in obj) {
    if (type === 'number' && !isNaN(Number(key))) {
      numberKeys.push(key);
    } else if (type === 'letter' && isNaN(Number(key))) {
      nonNumberKeys.push(key);
    }
  }

  if (type === 'number') {
    return numberKeys;
  } else if (type === 'letter') {
    return nonNumberKeys;
  } else {
    return { numberKeys, nonNumberKeys };
  }
};

exports.getPatientDateRangeInDays = (patientCreatedDate) => {
  const startDate = moment.utc().startOf('day').diff(moment.utc().startOf('day'), 'days');
  const endDate = moment
    .utc()
    .endOf('day')
    .diff(moment.utc(patientCreatedDate).endOf('day'), 'days');

  return { startDate, endDate };
};

exports.getInitials = (fullName) => {
  const names = fullName?.split(' ');
  const initials = names
    ?.map((name, index) => {
      if (index === names.length - 1) {
        return name.charAt(0).toUpperCase();
      }
      return name.charAt(0).toUpperCase() + '.';
    })
    .join('');

  return initials;
};

exports.calcDiscount = (discount, total_amount) => {
  if (!discount) return 0;

  let discountAmount;

  const { value, type } = discount || {};

  if (type === 'percent') {
    discountAmount = (total_amount * value) / 100 / 100;
  } else if (type === 'amount') {
    discountAmount = value;
  } else {
    discountAmount = 0;
  }

  return discountAmount;
};

exports.calculateAge = ({ dateOfBirth = undefined }) => {
  return moment.utc().diff(dateOfBirth, 'years');
};

exports.objMap = (obj, func) => {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, func(k, v)]));
};

exports.findRelationValue = (relationLabel) => {
  const relation = insurancePatientRelation?.find((relation) => {
    if (relation?.label == relationLabel) {
      return relation;
    }
  });

  return relation && relation?.value;
};
exports.mapValues = (values) => {
  return this.objMap(values, (_, f) =>
    typeof f === 'object' && Object.keys(f).includes('values') ? f.values : f
  );
};

exports.removeBracketsInText = (text) => {
  if (this.isEmpty(text)) return '';

  return text?.replace(/\[|\]/g, '');
};

exports.hasObjectTrueValues = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) {
      return true;
    }
  }
  return false;
};

exports.isValidDate = (date) => {
  return moment(date).isValid() ? date : null;
};

exports.hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex?.substring(1, 3), 16);
  const g = parseInt(hex?.substring(3, 5), 16);
  const b = parseInt(hex?.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha}%)`;
};

exports.hexToRGBObject = (hex) => {
  const r = parseInt(hex?.substring(1, 3), 16) / 255;
  const g = parseInt(hex?.substring(3, 5), 16) / 255;
  const b = parseInt(hex?.substring(5, 7), 16) / 255;
  return { r: r || 0, g: g || 0, b: b || 0 };
};
