import { Popover } from '@headlessui/react';
import cs from 'classnames';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../../api/InterimApi';
import { formatDate, ia, mString } from '../../../../../lib/helpers/utility';
import Icon from '../../../../shared/Icon/Icon';
import { currentPractice } from '../../../practiceState';
import { calcTotalAdjustment } from './ERA&EOBHelpers';

export default function EraRowDetails({ era, handleFinalized, type }) {
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  const downloadERA = async (id) => {
    try {
      const { data } = await interimApi(
        '/api/practice/billing/claim/download_era',
        { id },
        navigate
      );

      if (data?.era?.data && ia(data?.era?.data)) {
        const linkSource = `data:application/pdf;base64,${data?.era?.data[0]}`;
        const downloadLink = document.createElement('a');
        const fileName = `era_${data?.era?.eraid}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } catch (error) {
      toast.error('There was a problem downloading the ERA/EOB');
      console.error(error);
    }
  };

  return (
    <>
      <tr
        onClick={() => navigate(`${type}/${era?.id}`)}
        className={cs('cursor-pointer hover:!bg-primary-50')}>
        <td className="whitespace-nowrap">
          {era.paid_date && formatDate(era.paid_date, practice.timezone, true)}
        </td>
        <td>{era.check_number}</td>
        <td>{era.payer_name}</td>
        <td>{era.claim?.length}</td>
        <td>{era.unmatched_claims_length}</td>
        <td>{`$${era?.unallocated_amount_cents / 100 || 0}`}</td>
        <td>{era.paid_amount >= 0 ? mString(era.paid_amount) : mString(era.total_paid)}</td>
        <td>{calcTotalAdjustment(era.claims, era?.unmatched_claims)}</td>
        <td>{era.total_charge}</td>
        <td>{era.payment_method}</td>
        <td>
          {era?.type === 'era' && (
            <Popover className="relative">
              {({ open, close }) => (
                <div>
                  <Popover.Button
                    className={`
                      flex h-[34px] w-[34px] items-center justify-center rounded-full border border-solid border-neutral-300 bg-white shadow-small hover:!bg-primary-700
                      ${open && '!bg-primary-700 transition-all'}
                    `}>
                    <Icon icon="three-dots" className="flex cursor-pointer" />
                  </Popover.Button>

                  <Popover.Panel className="absolute right-0 top-10 z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
                    <div
                      className="flex gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={(e) => {
                        close();
                        e.stopPropagation();
                        downloadERA(era.id);
                      }}>
                      <div className="text-xs text-primary-900">Download</div>
                    </div>

                    <div
                      className="flex gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={(e) => {
                        close();
                        e.stopPropagation();
                        handleFinalized(era.id, era.finalized);
                      }}>
                      <div className="text-xs text-primary-900">
                        {era.finalized ? 'Mark Pending' : 'Finalized'}
                      </div>
                    </div>
                  </Popover.Panel>
                </div>
              )}
            </Popover>
          )}
        </td>
      </tr>
    </>
  );
}
