import React, { forwardRef, useEffect, useState } from 'react';
import Honeybadger from '@honeybadger-io/js';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import { userState } from 'components/state';
import PayerForm from './NewPayerForm';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { PayerInitialValues, PayerValidationSchema } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { createPayer } from 'api/Payer';

const NewPayer = forwardRef(({ setNewPayerModalVisible, newPayerModalVisible }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [states, setStates] = useState([]);
  const user = useRecoilValue(userState);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const formik = useFormik({
    initialValues: PayerInitialValues(),
    validationSchema: PayerValidationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let payerObject = {
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        name: values.name,
        phone: values.phone
      };

      await requestPayer(payerObject, resetForm);
      setSubmitting(false);
    }
  });

  const requestPayer = async (newPayer, resetForm) => {
    try {
      let res = await createPayer(navigate, { payer: newPayer, userId: user?.user_id });
      if (res.code === 0) {
        resetForm();
        queryClient.invalidateQueries(['getPayers']);
        queryClient.invalidateQueries(['payers']);
        setNewPayerModalVisible(false);
        showAlert({
          title: 'Success!',
          message: 'Payer requested successfully!',
          color: 'success'
        });
      } else if (res.code === 2) {
        setNewPayerModalVisible(false);
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create payer!",
          color: 'danger'
        });
      }
    } catch (error) {
      setNewPayerModalVisible(false);
      showAlert({
        title: 'No permission!',
        message: error ?? "You're not authorized to create payer!",
        color: 'danger'
      });
    }
  };

  return (
    <Modal
      customStyling={{ width: '620px' }}
      bodyClassName="!p-4 !max-h-[unset] !h-full"
      handleOpen={newPayerModalVisible}
      handleClose={() => setNewPayerModalVisible(false)}
      title="Request New Payer"
      slideFromRight
      key={newPayerModalVisible}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setNewPayerModalVisible(false)}
            id="cancelPayerModalBtn"
            data-qa="cancel-payer-btn"
          />
          <Button
            onClick={() => formik.handleSubmit()}
            text="Request payer"
            id="savePayerModalBtn"
            data-qa="create-payer-btn"
          />
        </div>
      }>
      <PayerForm states={states} formik={formik} />
    </Modal>
  );
});

export default withErrorBoundary(NewPayer);
