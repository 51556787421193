import React, { useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import Icon from '../../../../../../shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';
import { copyMacroText, loadMacro } from '../Create/lib/loadMacroHelpers';
import { ia, spaceToKebabCase } from '../../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../../shared/Alert/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { shouldNavigateToForm } from '../Create/lib/shouldNavigateToForm';

const defaultConfirmModalData = { type: null, props: null };

const MacrosList = ({ filters, hasMacroType }) => {
  const { macros, isGettingMacros, setDeleteMacroId, setEditMacro } = useMacrosContext();
  const {
    setClinicalNote,
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    setLoading,
    applyForceSave,
    cnDisplaySettings,
    customFormTypes
  } = useClinicalNoteContext();
  const [iconColor, setIconColor] = useState({});
  const [copiedMacro, setCopiedMacro] = useState();
  const [showCofirmModal, setShowConfirmModal] = useState(defaultConfirmModalData);
  const navigate = useNavigate();

  const { id: patientId, appointmentId, type } = useParams();

  const MACRO_ICONS = [
    {
      content: 'Apply Macro',
      icon: 'new-apply',
      method: (props) => setShowConfirmModal({ type: 'apply_macro', props }),
      permittedViews: ['appointment_macro', 'note_macro', 'form_macro']
    },
    {
      content: 'Copy Macro',
      icon: 'new-copy-squares',
      method: (props) => copyMacro(props),
      permittedViews: ['text_macro']
    },
    {
      content: 'Edit Macro',
      icon: 'new-edit-pen',
      method: (props) => editSelectedMacro(props),
      permittedViews: ['text_macro']
    },
    {
      content: 'Delete Macro',
      icon: 'new-trash-v2',
      method: (props) => deleteSelectedMacro(props),
      permittedViews: ['appointment_macro', 'note_macro', 'form_macro', 'text_macro']
    }
  ];

  const loadSelectedMacro = (selectedMacro) => {
    loadMacro({
      selectedMacro,
      setClinicalNote,
      setAdvancedHP,
      setAdvancedSOAP,
      setCustomFormTypes,
      setLoading,
      withPreviousData: filters?.macroType !== 'appointment_macro',
      applyForceSave
    });

    const parsedMacro =
      typeof selectedMacro?.body == 'string'
        ? JSON.parse(selectedMacro?.body)
        : selectedMacro?.body;

    shouldNavigateToForm({
      macro: selectedMacro,
      macroType: filters?.macroType,
      patientId,
      appointmentId,
      type,
      cnDisplaySettings,
      navigate
    });

    showAlert({ title: 'Macro loaded successfully', color: 'success' });
  };

  const editSelectedMacro = (macro) => setEditMacro({ ...macro, body: JSON.parse(macro?.body) });

  const deleteSelectedMacro = (macro) => setDeleteMacroId(macro.id);

  const copyMacro = (macro) => {
    copyMacroText(macro);

    setCopiedMacro(macro?.id);

    setTimeout(() => {
      setCopiedMacro(null);
    }, 1500);
  };

  const onHandleContinue = () => {
    switch (showCofirmModal?.type) {
      case 'apply_macro':
        loadSelectedMacro(showCofirmModal?.props);
        break;
    }

    setShowConfirmModal(defaultConfirmModalData);
  };

  return (
    <div className="relative !mt-2 h-full">
      <div
        className={cs(
          'absolute flex h-full w-full flex-col transition-all duration-500',
          (hasMacroType || filters?.searchTerm) && 'group-hover:right-0',
          hasMacroType || filters?.searchTerm ? 'right-0' : '-right-36'
        )}>
        <div className="flex h-full flex-col overflow-auto">
          {(hasMacroType || filters?.searchTerm) && (
            <>
              {isGettingMacros ? (
                <Skeleton count={5} height="40px" />
              ) : ia(macros) ? (
                macros?.map((macro, index) => (
                  <span
                    data-qa={`macros-list-${index}`}
                    key={index}
                    className=" !mt-1 cursor-pointer rounded-lg !p-2 text-sm text-primary-900 transition-all hover:bg-primary-50">
                    <div className="flex justify-between">
                      {macro.name}
                      <div className="flex items-center gap-1.5">
                        {MACRO_ICONS.map((row, iconIndex) => {
                          if (!row.permittedViews.includes(filters.macroType)) return null;

                          return (
                            <Tippy
                              key={iconIndex}
                              placement="bottom"
                              content={copiedMacro === macro?.id ? 'Copied' : row.content}
                              className="tippy-dark">
                              <div
                                onClick={() => row?.method(macro)}
                                data-qa={`macros-${row.icon}`}>
                                <Icon
                                  onMouseEnter={() => setIconColor({ [index]: iconIndex })}
                                  onMouseLeave={() => setIconColor({})}
                                  stroke={iconIndex === iconColor[index]}
                                  color={iconIndex === iconColor[index] && 'white'}
                                  icon={row.icon}
                                  className="flex h-4 w-4 cursor-pointer justify-center rounded-[4px] bg-primary-50 hover:bg-primary-700"
                                />
                              </div>
                            </Tippy>
                          );
                        })}
                      </div>
                    </div>
                  </span>
                ))
              ) : (
                (hasMacroType || filters?.searchTerm) && (
                  <p className="!mt-3 flex justify-center text-base text-primary-900">
                    No macros available.
                  </p>
                )
              )}
            </>
          )}
        </div>
      </div>

      <Confirm
        handleOpen={!!showCofirmModal?.type}
        handleClose={() => setShowConfirmModal(defaultConfirmModalData)}
        handleContinue={onHandleContinue}
        title="Macro"
        message={
          <div>
            <p>
              <em className="text-danger-800">This action will replace existing data. </em>
              <em className="not-italic">Are you sure you want to continue?</em>
            </p>
            <p className="!mt-3">Please use text macros to avoid overriding any fields.</p>
          </div>
        }
        primaryBtnTxt="Continue"
        secondaryBtnTxt="Cancel"
        icon="new-info"
        variant="warning"
      />
    </div>
  );
};

export default MacrosList;
