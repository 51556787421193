import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const FamilyHistory = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !currentHpOverviewData?.familyHistory?.id) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Family history</h3>
      {isTemplateChecked &&
        [currentHpOverviewData?.familyHistory]?.map((familyHistory) =>
          familyHistory?.response?.row?.map((item, idx) => (
            <div
              className="grid grid-cols-3 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
              key={idx}>
              {item?.medicalCondition?.value && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">
                    Medical condition
                  </p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.medicalCondition?.value}
                  </p>
                </div>
              )}
              {(item?.fatherSide || item?.motherSide) && (
                <div className="grid auto-rows-min">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Inheritance</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item.fatherSide ? 'Father side' : 'Mother side'}
                  </p>
                </div>
              )}
              {item?.note && (
                <div className="grid">
                  <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>
                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                    {item?.note}
                  </p>
                </div>
              )}
            </div>
          ))
        )}

      {isNarrativeChecked && currentHpOverviewData?.familyHistory?.id && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.familyHistory]}
            noDetailsText="No notable family history"
          />
        </div>
      )}
    </div>
  );
};

export default FamilyHistory;
