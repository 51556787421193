import React from 'react';
import Tippy from '@tippyjs/react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const TableTippy = ({ value }) => {
  const maxLength = 1;
  const remainingValues = value.slice(maxLength);
  return (
    <>
      {remainingValues?.length > 0 ? (
        <Tippy
          arrow={true}
          placement="left-start"
          className="tippy-dark"
          content={value.map((item, idx) => (
            <p key={idx} className="relative px-1 text-[12px] font-500 text-white">
              {item?.label}
            </p>
          ))}>
          <p>{value[0]?.label.slice(0, 8)}...</p>
        </Tippy>
      ) : (
        <p>{value[0]?.label}</p>
      )}
    </>
  );
};

export default withErrorBoundary(TableTippy);
