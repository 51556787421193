import { useGetPermissions } from 'lib/hooks/queries/permissions/usePermissions';
import { useRecoilState } from 'recoil';
import { pm, pmor } from '../../../lib/helpers/utility';
import state from '../../state';
import { withErrorBoundary } from '../Error/Boundary';

const PermissionsAllowed = ({ requiredPermissions = '', isAny = false, children }) => {
  const [permissions, setPermissions] = useRecoilState(state.permissions);

  useGetPermissions({
    options: {
      enabled: !permissions.loaded,
      onSuccess: (data) => {
        const newPermissions = data?.permissions;
        setPermissions({ ...newPermissions, loaded: true });
      }
    }
  });

  if (!requiredPermissions) {
    return children;
  }

  if (isAny && pmor(permissions, requiredPermissions)) {
    return children;
  }

  if (pm(permissions, requiredPermissions)) {
    return children;
  }

  return null;
};

export default withErrorBoundary(PermissionsAllowed);
