import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

const FormsAI = ({ inputName, formsList }) => {
  const { components } = useClinicalNoteContext();
  const { patient } = useOutletContext();

  const Component = formsList[inputName];
  return <Component context={{ patient }} components={components} /> || <></>;
};

export default FormsAI;
