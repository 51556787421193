import React from 'react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, hasObjectTrueValues } from 'lib/helpers/utility';
import { isNumber } from 'lodash';

const Claims = ({ data }) => {
  if (!hasObjectTrueValues(data))
    return (
      <NotFound
        icon="new-illustration-8"
        iconClassName="!pb-3"
        title="No Claims found!"
        description="You can start creating new claim on chart details."
      />
    );

  const chartData = [
    { name: 'Ready', value: data?.ready || 0, fill: '#5090DC' },
    { name: 'Acknowledged', value: data?.acknowledged || 0, fill: '#FFA03A' },
    { name: 'Denied', value: data?.denied || 0, fill: '#e13434' },
    { name: 'Paid', value: data?.paid || 0, fill: '#459D55' },
    { name: 'Rejected', value: data?.rejected || 0, fill: '#34BFE1' },
    { name: 'Pending', value: data?.pending || 0, fill: '#E1CC00' },
    { name: 'Archived', value: data?.archived || 0, fill: '#9769CB' }
  ].filter((item) => item.value > 0);

  const options = {
    data: chartData,
    width: 200,
    height: 200,
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'name',
        angleKey: 'value',
        innerRadiusRatio: 0.7,
        calloutLabel: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: false
    }
  };

  return (
    <div className="flex items-center gap-4 !p-6">
      <AGChart options={options} />
      <div className="!grid w-full auto-rows-max grid-cols-[repeat(auto-fill,minmax(110px,1fr))] gap-x-4 gap-y-3">
        {options?.data?.map((item, index) => (
          <div className="flex h-max flex-col gap-1" key={index}>
            <div className="flex items-center gap-2">
              <div className="h-3 w-1 rounded-lg" style={{ backgroundColor: item.fill }}></div>
              <p className="font-medium text-neutral-500">{item.name}</p>
            </div>
            {isNumber(item.value) && (
              <p className="!ml-2 text-xl font-semibold text-primary-900">
                {formatLargeNumber(item.value)}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Claims;
