import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { interimApi } from 'api/InterimApi';
import { getServices } from 'api/Service';
import CPTRow from 'components/practice/charts/CPTRow';
import {
  calculateTotalCharge,
  getPlaceOfService,
  handleCPTSearch
} from 'components/practice/charts/SuperBillPage/lib';
import { handleSelectCPT } from 'components/practice/charts/SuperBillPage/lib/handleSelectCPT';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Input from 'components/shared/Input/Input';
import { ia } from 'lib/helpers/utility';

const ServicesAndDiagnosis = ({
  formik,
  superbill,
  states,
  procedures,
  setProcedures,
  handleSubmitClaim
}) => {
  const [posOptions, setPosOptions] = useState([]);
  const practice = useRecoilValue(currentPractice);
  const { values: claim, setFieldValue, errors, handleBlur, touched } = formik;
  const navigate = useNavigate();

  useEffect(() => {
    if (states) {
      getPOS();
    }
  }, []);

  const getPOS = async () => {
    try {
      let res = await interimApi('/api/codes/place_of_service/get', {});
      if (res.data) {
        setPosOptions(res.data.place_of_service);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateDiagnosis = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const handleProcedureChanges = (e, index) => {
    let newVal = e.target.value;
    let fieldName = e.target.name;

    setProcedures((prev) => {
      prev[index][fieldName] = newVal;
      return [...prev];
    });

    setFieldValue('total_charge', calculateTotalCharge(procedures));
  };

  const convertProcedureToPrimary = (index) => {
    setProcedures((prev) => {
      prev[index] = {
        ...prev[index],
        type: 'primary',
        charge_type: 'copay'
      };

      return [...prev];
    });
  };

  const handleDeletion = (index) => {
    let currentCPT = [...procedures];
    currentCPT.splice(index, 1);
    setFieldValue('total_charge', calculateTotalCharge(procedures));

    setProcedures(currentCPT);
    setFieldValue('procedures', currentCPT);
  };

  const selectCPT = async (e, index) => {
    const data = await getServices(navigate, { cpt: e.value });
    const service = data?.services;
    const updatedProcedures = handleSelectCPT({
      procedures,
      index,
      service,
      e,
      superbill,
      practice
    });

    setProcedures(updatedProcedures);

    setFieldValue('procedures', updatedProcedures);
    setFieldValue('total_charge', calculateTotalCharge(procedures));
  };

  const addCPT = () => {
    const newCPT = {
      procedure_code: null,
      modifiers: null,
      charge: 0,
      diagnosis: null,
      from_date: moment(superbill.encounter.appointment.starts_at).toDate(),
      thru_date: moment(superbill.encounter.appointment.starts_at).toDate(),
      narrative: '',
      units: '1',
      place_of_service: getPlaceOfService(superbill, practice)
    };
    let currentCPT = [...procedures];
    currentCPT.push(newCPT);

    setProcedures(currentCPT);
    setFieldValue('total_charge', calculateTotalCharge(procedures));
  };

  const handleInputChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  return (
    <div className="block rounded-md bg-white p-[1.25rem] shadow">
      <div className="flex items-center justify-between gap-4">
        <label className="text-xl font-400 text-neutral-800">Services & Diagnosis</label>
      </div>

      <div className="flex sm:flex-col md:items-center">
        <div className="block !pt-4">
          <p className="!pb-3">Diagnosis (ICD10 CODES)</p>
          <div className="col-start-1 col-end-3">
            <div className="grid grid-cols-4 gap-4 sm:grid-cols-2 xxs:sm:grid-cols-1">
              <Input
                className="flex gap-2"
                label="A."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_1}
                name="diag_1"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="B."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_2}
                name="diag_2"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="C."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_3}
                name="diag_3"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="D."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_4}
                name="diag_4"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="E."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_5}
                name="diag_5"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="F."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_6}
                name="diag_6"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="G."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_7}
                name="diag_7"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="H."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_8}
                name="diag_8"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="I."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_9}
                name="diag_9"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="J."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_10}
                name="diag_10"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="K."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_11}
                name="diag_11"
                onChange={updateDiagnosis}
              />
              <Input
                className="flex gap-2"
                label="L."
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                value={claim?.diag_12}
                name="diag_12"
                onChange={updateDiagnosis}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="!pb-2 !pt-6">
          <p className="!pb-3">Services (CPT &#174; CODES)</p>
          <div className="grid gap-2">
            {procedures?.map((item, index) => {
              return (
                <CPTRow
                  item={item}
                  key={procedures.length + index}
                  index={index}
                  handleProcedureChanges={handleProcedureChanges}
                  handleDeletion={handleDeletion}
                  posOptions={posOptions?.map((item) => {
                    return { label: `${item.code} - ${item.name}`, value: item.code };
                  })}
                  handleCPTSearch={(a, b) => handleCPTSearch(a, b)}
                  selectCPT={selectCPT}
                  superbill={superbill}
                  convertProcedureToPrimary={convertProcedureToPrimary}
                />
              );
            })}
          </div>
        </div>
        {ia(procedures) && (
          <label className="!mt-4 text-lg">Total Charge: ${claim?.total_charge}</label>
        )}
        <Button
          onClick={() => addCPT()}
          text="Add"
          iconColor="white"
          icon="new-plus"
          color="success"
          outlined
          className="min-w-[150px]"
        />
      </div>
      <div className="flex sm:flex-col md:items-center">
        <div className="p-3 print:hidden">
          <Input
            label="Custom Claim Id"
            placeholder="Custom Claim Id"
            name="custom_id"
            value={claim?.custom_id}
            onChange={handleInputChange}
            id="custom_id"
            onBlur={handleBlur}
            error={touched.custom_id && errors.custom_id}
          />
        </div>
      </div>

      <div className="flex justify-end !gap-5">
        {superbill.state === 'complete' && (
          <Button
            color="neutral"
            text="Go to claim"
            className="print:hidden"
            onClick={() =>
              navigate(`/portal/beyond-billing/claims/ready/${claim?.id}`, {
                state: { claimID: claim?.id }
              })
            }
          />
        )}
        <Button
          color="success"
          text="Ready for claim"
          className="print:hidden"
          onClick={handleSubmitClaim}
        />

        <Button
          text="Update"
          className="print:hidden"
          onClick={handleSubmitClaim}
          data-qa="save-btn"
        />
      </div>
    </div>
  );
};

export default withErrorBoundary(ServicesAndDiagnosis);
