const { default: toast } = require('react-hot-toast');
const _ = require('lodash');
const { Honeybadger } = require('@honeybadger-io/react');
const { io } = require('../../../../../lib/helpers/utility');
const { requestApi } = require('../../../../../api/Api');

exports.submitForm = async ({
  id,
  appointmentId,
  currentFormData,
  defaultState,
  type,
  setOverviewData,
  setCurrentHpOverviewData,
  formId = null,
  navigate,
  setSaveButtonText,
  updateSaveButton = () => null
}) => {
  try {
    if (_.isEqual(currentFormData, defaultState)) return;
    setSaveButtonText('Saving');
    const result = await requestApi({
      url: '/api/custom_form_response/create',
      params: {
        formId,
        patientId: id,
        appointmentId,
        response: currentFormData,
        type
      },
      navigate
    });

    const { code, redirect, error, newResponse } = result;
    switch (code) {
      case -1:
        navigate(redirect);
        break;
      case 0:
        this.syncHpOverviewTemplates({
          formId,
          type,
          setOverviewData,
          setCurrentHpOverviewData,
          customFormResponse: newResponse
        });
        break;
      case 2:
        error && toast.error(error);
        break;
      case 3:
        error && toast.error(error);
        break;
      default:
        Honeybadger.notify(
          `file: customFormHelper, method: submitForm - catch, error: ${
            error ? error : 'unknown error'
          }`
        );
        break;
    }
  } catch (error) {
    Honeybadger.notify(`file: customFormHelper, method: submitForm - catch, error: ${error}`);
    console.error(error);
  }
  await updateSaveButton();
};

// exports.onSaveVitals = async ({
//   id,
//   vitals,
//   lastVitals,
//   appointmentId,
//   setLastVitals,
//   setOverviewVitals,
//   navigate
// }) => {
//   const params = {
//     patientId: id,
//     vitals: vitals,
//     appointmentId
//   };
//   if (!io(vitals)) return;

//   if (_.isEqual(vitals, lastVitals)) return;

//   try {
//     const res = await requestApi({ url: '/api/clinical_note/vitals/create', params, navigate });
//     const { code, redirect, error, newVitals, newPatientVitals } = res;
//     switch (code) {
//       case -1:
//         navigate(redirect);
//         break;
//       case 0:
//         const upsertType = newVitals && newPatientVitals ? 'insert' : 'update';
//         const vitalsWithId = {
//           ...vitals,
//           id: newVitals,
//           created_by: { id: null, fullName: 'currentUser' }
//         };
//         syncVitalDataInClinicalNote({
//           vitals: vitalsWithId,
//           setLastVitals,
//           setOverviewVitals,
//           type: upsertType
//         });
//         break;
//       case 2:
//       case 3:
//         toast.error(error);
//         break;
//       default:
//         Honeybadger.notify(
//           `method: onSaveVitals, Clinical note save vitals - try block - patientId: ${id}, error: ${error}`
//         );
//         break;
//     }
//   } catch (error) {
//     Honeybadger.notify(
//       `method: onSaveVitals, Clinical note save vitals -  catch block - patientId: ${id}, error: ${error}`
//     );
//   }
// };

// const syncVitalDataInClinicalNote = ({ vitals, setLastVitals, setOverviewVitals, type }) => {
//   setLastVitals((prevState) => ({ ...prevState, vitals }));

//   setOverviewVitals((prevState) =>
//     type === 'update'
//       ? prevState?.map((vital) => (vital.id === vitals.id ? vitals : vital))
//       : [vitals, ...(prevState || [])]
//   );
// };

exports.syncHpOverviewTemplates = ({
  formId,
  type,
  setOverviewData,
  setCurrentHpOverviewData,
  customFormResponse
}) => {
  if (!io(customFormResponse) || !setOverviewData) return;

  const customFormRows = customFormResponse?.response;

  const createdBy = { id: null, fullName: 'currentUser' };
  const customFormResponseWithCreatedInfo = { ...customFormResponse, createdBy };

  switch (type) {
    case 'medicationHistory':
      setCurrentHpOverviewData((prevState) => {
        const previousData = prevState || {};

        return {
          ...previousData,
          [type]: customFormResponse
        };
      });

      setOverviewData((prevState) => {
        const previousTypeData = prevState[type] || [];
        let findFormId = previousTypeData?.findIndex((cForm) => cForm.id === formId);

        let allCustomForms = [...previousTypeData];

        if (findFormId >= 0) {
          allCustomForms[findFormId] = {
            ...allCustomForms[findFormId],
            createdBy,
            response: customFormRows
          };
        }

        return {
          ...(prevState || {}),
          [type]:
            findFormId >= 0
              ? allCustomForms
              : [customFormResponseWithCreatedInfo, ...(prevState.medications || [])]
        };
      });
      break;
    default:
      setCurrentHpOverviewData((prevState) => {
        const previousData = prevState || {};

        return {
          ...previousData,
          [type]: customFormResponse
        };
      });

      setOverviewData((prevState) => {
        const previousTypeData = prevState[type] || [];
        let findFormId = previousTypeData?.findIndex((cForm) => cForm.id === formId);

        let allCustomForms = [...previousTypeData];

        if (findFormId >= 0) {
          allCustomForms[findFormId] = {
            ...allCustomForms[findFormId],
            createdBy,
            response: customFormRows
          };
        }

        return {
          ...(prevState || {}),
          [type]:
            findFormId >= 0
              ? allCustomForms
              : [customFormResponseWithCreatedInfo, ...previousTypeData]
        };
      });
      break;
  }
};

exports.scrollIntoNarrativeView = ({ ref = {}, className = '!bg-primary-50' }) => {
  setTimeout(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    ref?.current?.classList.add(className);

    setTimeout(() => {
      ref?.current?.classList.remove(className);
    }, 3000);
  }, 500);
};

exports.convertToNormalText = (key) => {
  if (!key) return key;

  const convertedKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

  if (!convertedKey) return key;

  return convertedKey.charAt(0).toUpperCase() + convertedKey.slice(1).replace(/_/g, ' ');
};
