import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from 'lib/helpers/utility';
import React from 'react';
import ListCustomForms from './ListCustomForms';

const Allergies = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { currentHpOverviewData } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(currentHpOverviewData?.allergies)) {
    return null;
  }

  const renderAllergyValue = (item, type) => {
    if (!item[type]?.value) return null;

    return (
      <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
        {item[type]?.value}
      </p>
    );
  };

  const renderNoteValue = (item) => {
    if (!item?.note) return null;

    return (
      <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
        {item?.note}
      </p>
    );
  };

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Allergies</h3>
      {isTemplateChecked && (
        <div className="grid grid-cols-3 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0">
          <React.Fragment>
            <p className="text-xs font-500 text-neutral-700 print:text-base">Allergy</p>
            <p className="text-xs font-500 text-neutral-700 print:text-base">Medication provided</p>
            <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>

            {[currentHpOverviewData?.allergies]?.map((allergy) =>
              allergy?.response?.allergies?.map((item, idx) => (
                <React.Fragment key={idx}>
                  {renderAllergyValue(item, 'allergy')}
                  {renderAllergyValue(item, 'medication')}
                  {renderNoteValue(item)}
                </React.Fragment>
              ))
            )}
          </React.Fragment>
        </div>
      )}

      {isNarrativeChecked && !!io(currentHpOverviewData?.allergies) && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <ListCustomForms
            forms={[currentHpOverviewData?.allergies]}
            noDetailsText="No known allergies"
          />
        </div>
      )}
    </div>
  );
};

export default Allergies;
