import React, { useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Goals from '../../ProgressNote/components/Goals';

const GoalsSection = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext();
  const sectionRef = useRef();
  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Goals', parentKey: 'intake', childKey: 'goals' }}
      title={components?.goals?.title}
      id={components?.goals?.id}
      disabled={clinicalNote?.locked}>
      <Goals section={'intake'} sectionRef={sectionRef} />
    </Accordion>
  );
};

export default GoalsSection;
