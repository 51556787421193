import React, { useRef, useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import ClinicalNotePDF from './ClinicalNotePDF';
import { convertFormToPdf } from 'components/practice/charts/ClinicalNote/Orders/lib/handleSendReferralAsFax';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import SendFaxModal from 'components/practice/comms_center/fax/components/SendFaxModal/SendFaxModal';

const ExportModal = ({ exportModal, setExportModal, documentIds }) => {
  const [sendFaxModalVisible, setSendFaxModalVisible] = useState(false);
  const [faxData, setFaxData] = useState({});
  const [faxLoading, setFaxLoading] = useState(false);
  const [emailContent, setEmailContent] = useState([]);
  const [emailModal, setEmailModal] = useState(false);

  const exportRef = useRef();
  const renderedRef = useRef([]);
  const navigate = useNavigate();

  const handlePrint = () => {
    exportRef.current.triggerPrint();
  };

  const handleEmail = async () => {
    try {
      const res = await requestApi({
        url: '/api/practice/medical_history/export_multiple',
        navigate,
        params: { clinicalNotes: emailContent }
      });
      if (res.code === 0) {
        showAlert({ title: 'Email', message: 'Email sent successfully', color: 'success' });
      }
    } catch (error) {
      showAlert({ title: 'Email', message: 'Something went wrong!', color: 'danger' });
    }
    setEmailModal(false);
  };
  return (
    <>
      <Modal
        handleOpen={exportModal}
        handleClose={() => setExportModal(false)}
        title="Clinical note export"
        className="w-[1020px]"
        bodyClassName="p-0"
        slideFromRight
        headButton={
          <div className="flex w-full justify-end gap-2 pr-2">
            <Button
              icon="new-printer"
              type="secondary"
              color="neutral"
              size="small"
              onClick={handlePrint}
              text="Export PDF"
            />
            <Button
              icon="new-e-fax"
              size="small"
              type="secondary"
              color="neutral"
              text="Fax"
              onClick={() =>
                convertFormToPdf({
                  setFaxData,
                  setFaxLoading,
                  setSendFaxModalVisible,
                  contentId: 'clinical_note',
                  patientName: documentIds[0]?.patient?.fullName,
                  subject: 'Clinical Notes for'
                })
              }
            />
            <Button
              icon="new-patients"
              type="secondary"
              color="neutral"
              size="small"
              onClick={() => setEmailModal(true)}
              text="Send To Patient"
            />
          </div>
        }>
        <ClinicalNotePDF
          contentId="clinical_note"
          exportRef={exportRef}
          renderedRef={renderedRef}
          itemProps={documentIds}
          patient={documentIds[0]?.patient}
          sendFaxModalVisible={sendFaxModalVisible}
          setSendFaxModalVisible={setSendFaxModalVisible}
          faxData={faxData}
          setEmailContent={setEmailContent}
        />
      </Modal>
      {emailModal && (
        <Confirm
          icon="new-patients"
          title="Are you sure you want to send this clinical note to the patient?"
          message="Patient will receive an emailed HIPAA-Compliant link to log in and access from secure patient portal."
          handleOpen={emailModal}
          primaryBtnTxt="Yes, send"
          secondaryBtnTxt={`No, don't send`}
          handleClose={() => setEmailModal(false)}
          handleContinue={handleEmail}
        />
      )}
      {sendFaxModalVisible && (
        <SendFaxModal
          sendFaxModalVisible={sendFaxModalVisible}
          setSendFaxModalVisible={setSendFaxModalVisible}
          faxData={faxData}
        />
      )}
    </>
  );
};

export default ExportModal;
