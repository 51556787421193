import React from 'react';
import CardProcessor from 'components/shared/CardProcessor';
import {
  Capitalize,
  mString,
  formatDate,
  formatDateAndTime,
  snakeToTitleCase
} from 'lib/helpers/utility';
import { Actions } from './actions';
import numOrDefault from 'lib/helpers/numOrDefault';

export const cols = [
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 150
  },
  {
    field: 'patient.fullName',
    headerName: 'Patient',
    width: 300
  },
  {
    field: 'updated_at',
    headerName: 'Date',
    valueFormatter: ({ value }) => formatDateAndTime(value)
  },
  {
    field: 'status',
    headerName: 'Status',
    valueFormatter: ({ value }) => Capitalize(value)
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: ({ value }) => (value?.length > 0 ? snakeToTitleCase(value.toLowerCase()) : '')
  },
  {
    field: 'cc_type',
    headerName: 'Card Type',
    cellRenderer: ({ value }) => {
      return (
        <div className="flex items-center justify-center pt-[8px]">
          <CardProcessor>{value}</CardProcessor>
        </div>
      );
    }
  },
  { field: 'cc_number', headerName: 'Card Number (Last 4)' },
  {
    field: 'amount_paid_self',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    sortable: false
  },
  {
    field: '',
    headerName: 'Actions',
    maxWidth: 100,
    minWidth: 100,
    resizable: false,
    cellRenderer: (params) => <Actions data={params.data} />
  }
];

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Transaction',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  status: {
    type: 'checkbox',
    options: ['started', 'cancelled', 'completed', 'initiated', 'voided', 'failed'],
    values: null,
    title: 'Status',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  type: {
    type: 'search',
    multiple: true,
    options: [
      {
        label: 'Card Present',
        value: 'CARD_PRESENT'
      },
      {
        label: 'In Progress',
        value: 'IN_PROGRESS'
      },
      {
        label: 'Amex',
        value: 'amex'
      },
      {
        label: 'Cash',
        value: 'cash'
      },
      {
        label: 'Online',
        value: 'ONLINE'
      },
      {
        label: 'Charge',
        value: 'charge'
      },
      {
        label: 'Check',
        value: 'check'
      }
    ],
    values: [],
    title: 'Type',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  amount: {
    min: 0,
    max: 1000,
    values: 0,
    type: 'range',
    title: 'Amount',
    preview: (values) => mString(values)
  },
  cashier: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Cashier',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: true
};
export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'always',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};
