import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import AllergiesForm from 'components/shared/Forms/Custom/Allergies/Allergies';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Axios from 'configuredAxios';
import React, { useMemo, useState } from 'react';

const Allergy = ({ item, openAllergyModal }) => {
  const isNoDetailsTrue = !!item?.original?.response?.noDetails;

  return (
    <div
      onClick={() => openAllergyModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      {isNoDetailsTrue ? (
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No known allergies</p>
          <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>
        </div>
      ) : (
        <List
          items={item?.list}
          dividerType="none"
          renderItemComponent={(listItem, index) => (
            <>
              <div className="flex items-center justify-between gap-2">
                <p className="font-medium text-neutral-800">{listItem?.allergy?.label}</p>
                {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
              </div>

              {listItem.typicalReaction && (
                <div className="flex items-center gap-2">
                  <p className="font-medium text-primary-600">
                    {listItem?.typicalReaction?.label?.replace?.('(severe)', '')}
                  </p>
                  {listItem?.typicalReaction?.label?.includes?.('(severe)') && (
                    <>
                      <div className="h-[10px] w-[1px] bg-neutral-400"></div>
                      <p className="font-medium text-danger-500">Severe</p>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        />
      )}

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function Allergies(props) {
  const { customData, layout, changedSizes, patientId } = props;
  const allergies = customData?.allergies?.length > 0 ? customData?.allergies : null;
  const defaultSize = useMemo(() => layout.find((l) => l.i === props.item.i)?.h, [layout]);

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () => limitItems(allergies, defaultSize, { typicalReaction: 0.16 }),
    [allergies, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [allergyModal, setAllergyModal] = useState(null);

  const queryClient = useQueryClient();

  const openAllergyModal = (item) => {
    setAllergyModal(item?.original);
    setCurrentFormData(item?.original?.response);
  };

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId: allergyModal?.id,
        patientId,
        response: currentFormData,
        type: 'allergies'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ title: 'Successfully saved' });
        setAllergyModal();
        setCurrentFormData();
        queryClient.invalidateQueries(['customFormResponse']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        onPlusClick={() => setAllergyModal({})}
        icon="thermometer"
        {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <FooterWrapper
          hasMore={hasMore}
          onClick={() => setShow(true)}
          text={`Show all allergies (${count})`}>
          {items ? (
            <List
              items={items}
              renderItemComponent={(item, index) => (
                <Allergy openAllergyModal={openAllergyModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Allergies</p>
            </div>
          )}
        </FooterWrapper>
      </div>

      <Modal
        handleOpen={show}
        className="w-[1020px] bg-white"
        title="Allergies"
        slideFromRight
        handleClose={() => setShow(false)}>
        {allergies ? (
          <List
            items={allergies}
            renderItemComponent={(item, index) => (
              <Allergy openAllergyModal={openAllergyModal} item={item} />
            )}
          />
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Allergies</p>
          </div>
        )}
      </Modal>

      <Modal
        className="w-[1020px]"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setAllergyModal();
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }
        handleOpen={!!allergyModal}
        title="Allergy"
        slideFromRight
        handleClose={() => setAllergyModal()}>
        <AllergiesForm
          setAllergyModal={setAllergyModal}
          formId={allergyModal?.id}
          defaultValue={allergyModal?.response}
          setCurrentFormData={setCurrentFormData}
        />
      </Modal>
    </div>
  );
}
