import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import HeaderButton from './components/HeaderButton';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';
import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import Header from 'components/shared/Header/Header';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import usePageTitle from 'lib/hooks/usePageTitle';
import { mapValues } from 'lib/helpers/utility';
import PayerDetails from './components/PayerDetails';
import NewPayer from './components/NewPayer';
import { readPayers } from 'api/Payer';

const Payers = () => {
  return (
    <TableContextProvider
      pagination
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns}
      name="payers">
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Payers');
  const category = 'payers';
  const navigate = useNavigate();
  const [newPayerModalVisible, setNewPayerModalVisible] = useState(false);

  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const { data, isLoading, isFetching } = usePayers({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        let res = await readPayers(navigate, { searchTerm });
        if (res.payers) {
          return res.payers.map((payer) => {
            return { value: payer.id, label: payer.name };
          });
        }
      } catch (error) {
        console.error(error);
        showAlert({
          title: 'Error!',
          message: error ?? 'There was an error getting all payers.',
          color: 'danger'
        });
      }
    }
  };

  const payers = data?.payers || [];
  const count = data?.count || [];

  return (
    <>
      <Header title="Payers">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <HeaderButton
            setNewPayerModalVisible={setNewPayerModalVisible}
            searchPayers={searchPayers}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={payers}
              rowSelection="multiple"
              columnDef={defaultColumns}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
              detailCellRenderer={PayerDetails}
              masterDetail={true}
              detailRowAutoHeight={true}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {newPayerModalVisible && (
        <NewPayer
          newPayerModalVisible={newPayerModalVisible}
          setNewPayerModalVisible={setNewPayerModalVisible}
        />
      )}
    </>
  );
}

export default withErrorBoundary(Payers);
