import React from 'react';

export const CustomStatusBarCount = ({ data = [], count, page, limit }) => {
  return (
    <div>
      {data?.length > 0 ? (
        <div className="flex flex-wrap space-x-4 p-3 text-sm">
          {count > 0 && (
            <p className="font-light text-primary-900">
              Showing {page === 1 ? page : (page - 1) * limit} -{' '}
              {count > page * limit ? page * limit : count} out of {count}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};
