import React from 'react';

import { ia, optionify } from '../../../../../lib/helpers/utility';
import Input from '../../../../shared/Input/Input';
import Select from '../../../../shared/Select/Select';

const PhysicalAddress = ({ formik, onChange, stateList, disabled }) => {
  return (
    <div>
      <label className="text-lg font-600">Physical address</label>

      <div>
        <h4 className="text !my-2 text-primary-700">Primary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Address"
            data-qa="address-ln-one"
            id="address.address_ln_1"
            name="address.address_ln_1"
            onChange={onChange}
            placeholder="Address"
            value={formik?.values?.address?.address_ln_1}
            error={formik?.errors?.address?.address_ln_1}
            disabled={disabled}
          />

          <Input
            label="Address 2"
            data-qa="address-ln-two"
            name="address.address_ln_2"
            id="address.address_ln_2"
            onChange={onChange}
            placeholder="Address 2"
            value={formik?.values?.address?.address_ln_2}
            disabled={disabled}
          />

          <Input
            label="City"
            data-qa="address-city"
            id="address.city"
            name="address.city"
            onChange={onChange}
            placeholder="City"
            value={formik?.values?.address?.city}
            error={formik?.errors?.address?.city}
            disabled={disabled}
          />

          <Select
            label="State"
            id="address.state"
            placeholder="Type and select state"
            options={optionify(stateList)}
            error={formik?.errors?.address?.state}
            onChange={(e) => formik.setFieldValue('address.state', e?.value)}
            value={
              ia(stateList)
                ? stateList.map((l) => {
                    if (l?.id === formik?.values?.address?.state)
                      return { value: l?.id, label: l?.name };
                  })
                : null
            }
            disabled={disabled}
          />

          <Input
            label="Zip code"
            data-qa="address-zipcode"
            id="address.zip"
            name="address.zip"
            onChange={onChange}
            placeholder="Zip code"
            value={formik?.values?.address?.zip}
            disabled={disabled}
          />
        </div>
      </div>

      <div>
        <h4 className="text !my-2 text-primary-700">Secondary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Address"
            onChange={onChange}
            placeholder="Address"
            data-qa="secondary-address-ln-one"
            id="secondary_address.address_ln_1"
            name="secondary_address.address_ln_1"
            value={formik?.values?.secondary_address?.address_ln_1}
            disabled={disabled}
          />

          <Input
            label="Address 2"
            onChange={onChange}
            placeholder="Address 2"
            data-qa="secondary-address-ln-two"
            id="secondary_address.address_ln_2"
            name="secondary_address.address_ln_2"
            value={formik?.values?.secondary_address?.address_ln_2}
            disabled={disabled}
          />

          <Input
            label="City"
            placeholder="City"
            onChange={onChange}
            data-qa="secondary-city-address"
            id="secondary_address.city"
            name="secondary_address.city"
            value={formik?.values?.secondary_address?.city}
            disabled={disabled}
          />

          <Select
            label="State"
            placeholder="Type and select state"
            id="secondary_address.state"
            options={optionify(stateList)}
            onChange={(e) => formik.setFieldValue('secondary_address.state', e?.value)}
            value={
              ia(stateList)
                ? stateList.map((l) => {
                    if (l?.id === formik?.values?.secondary_address?.state)
                      return { value: l?.id, label: l?.name };
                  })
                : null
            }
            disabled={disabled}
          />

          <Input
            label="Zip code"
            onChange={onChange}
            placeholder="Zip code"
            data-qa="secondary-address-zipcode"
            id="secondary_address.zip"
            name="secondary_address.zip"
            value={formik?.values?.secondary_address?.zip}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default PhysicalAddress;
