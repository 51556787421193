import React, { useMemo } from 'react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, ia, io } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import useChartSize from 'lib/hooks/useChartSize';
const Patients = (props) => {
  const { dashboardData, item, layout } = props;

  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const patientCountData = {
    new: dashboardData?.thisPeriodAndTotalCount?.periodPatientCount,
    total: dashboardData?.thisPeriodAndTotalCount?.totalPatientCount
  };

  const formattedData = dashboardData?.patientsGraphData
    ? Object.entries(dashboardData?.patientsGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const localDate = new Date(momentTime.valueOf());
        return {
          timestamp: localDate,
          created: parseFloat(data.created)
        };
      })
    : [];

  function getColorForStatus(status) {
    switch (status) {
      case 'created':
        return '#00ABE8';
      default:
        return '#000000';
    }
  }
  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        bar: {
          series: {
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },
    series:
      dashboardData?.count?.patients?.map?.((item) => ({
        type: 'bar',
        xKey: 'timestamp',
        yKey: item.status,
        yName: `${capitalize(item.status)} (${formatLargeNumber(item.count)})`,
        fill: getColorForStatus(item.status),
        stroke: getColorForStatus(item.status)
      })) || [],
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  if (!io(dashboardData?.patientsGraphData) || !ia(dashboardData?.count.patients)) {
    return (
      <>
        <NotFound
          icon="new-illustration-3"
          iconClassName="!pb-5"
          title="No patients report for this period!"
          description="You can try a different period"
        />
      </>
    );
  }

  return (
    <div className="!px-5 !py-[10px]">
      <div className="flex w-full items-center justify-between">
        <p className="pb-3 text-lg font-500 leading-6 text-primary-900">
          {`This Period:  ${formatLargeNumber(patientCountData.new)}  Patients`}
        </p>
        <p className="pb-3 text-lg font-500 leading-6 text-primary-900">
          {`Total: ${formatLargeNumber(patientCountData.total)} Patients`}
        </p>
      </div>
      <AGChart options={options} key={chartKey} />
    </div>
  );
};

export default Patients;
