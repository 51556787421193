import NotFound from 'components/NotFound';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Icon from 'components/shared/Icon/Icon';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import React, { useState } from 'react';

const groupComponentsByType = (components) => {
  return components.reduce((acc, component) => {
    const { label, title, forms, type } = component;
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push({ title, forms, type });
    return acc;
  }, {});
};

const Chat = () => {
  const { selectedChatComponents } = useMyTemplateContext();
  const [disableGenerate, setDisableGenerate] = useState(true);

  const groupedComponents = groupComponentsByType(selectedChatComponents);

  const components = Object.entries(groupedComponents).map(([key, value]) => {
    return (
      <div key={key}>
        <div className="text-xl text-primary-500">{key}</div>
        {value
          ?.map((component) => {
            const Input = component?.forms || NotFound;

            return (
              <div className="py-2" key={component.title}>
                <Input inputName={component.title} type={component?.type} />
              </div>
            );
          })
          .filter(Boolean)}
      </div>
    );
  });

  const onTextChange = (e) => {
    if (!e.target.value?.length) {
      setDisableGenerate(true);
      return;
    }

    setDisableGenerate(false);
  };
  const onGenerate = (text) => {
    console.log('🚀 ~ onGenerate ~ text:', text);
  };
  const onGoBack = () => {};

  return (
    <div className="overflow-y-none relative flex h-full flex-col pb-24">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 z-10" />
      {components?.length > 0 ? (
        <div className="flex-grow overflow-y-scroll px-4 pb-[100px]">
          <div className="mx-auto flex w-2/3 flex-col px-10">{components}</div>
        </div>
      ) : (
        <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto">
          <Icon icon="choose-notes" />
          <h2 className="mb-4 text-xl text-[#004F6B]">Select type of note and choose forms</h2>
          <h4 className="mb-4 text-base text-[#6D828B]">
            You can start creating new clinical notes on chart details.
          </h4>
        </div>
      )}
      <div className="px-auto absolute bottom-20 z-50 flex w-2/3 self-center bg-transparent">
        <AIChatInput
          onTextChange={onTextChange}
          onSubmit={onGenerate}
          onGoBack={onGoBack}
          disabled={disableGenerate}
        />
      </div>
    </div>
  );
};

export default Chat;
