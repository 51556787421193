import React from 'react';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';
import SignModal from './Sign/SignModal';

import { useRecoilValue } from 'recoil';
import { ia } from '../../../../../lib/helpers/utility';
import { userState as user_state } from '../../../../state';
import { everyoneSigned, updateDocSignatures } from './helpers/documentSignatureHelpers';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { showAlert } from 'components/shared/Alert/Alert';

const SignLockActionComponent = ({
  documentSignatures,
  onLockNote,
  updateDocumentSignMutation,
  confirmSignLock,
  showConfirmSignLock,
  hasUserSigned,
  clinicalNoteContext = null
}) => {
  const cnFromContext = useClinicalNoteContext();
  const clinicalNote = cnFromContext ? cnFromContext.clinicalNote : clinicalNoteContext;
  const userState = useRecoilValue(user_state);

  const isOldCNLocked = !ia(documentSignatures) && clinicalNote?.locked;
  const isOldCNNotLocked = !ia(documentSignatures) && !clinicalNote?.locked;

  const isNewCNLocked = hasUserSigned;
  const isNewCNNotLocked = !hasUserSigned;

  if (isOldCNLocked) {
    return (
      <Confirm
        primaryBtnTxt="Confirm"
        title="Unlock clinical note?"
        icon="new-lock"
        message={`It is the responsibility of the provider and associates to chart each encounter in an accurate and timely manner. Any changes to a locked note using "unlock" functionality are documented electronically using version control, for your protection. When unlocking a note, please consider any material changes be formalized through an addendum to the note for tracking purposes.`}
        handleContinue={async () => {
          await onLockNote({ lockStatus: false });
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }
  if (isOldCNNotLocked) {
    return (
      <Confirm
        primaryBtnTxt="Confirm"
        title="Sign and lock clinical note?"
        icon="new-lock"
        message={`
          Are you sure you want to unlock the clinical note?
        `}
        handleContinue={async () => {
          await onLockNote({ lockStatus: true });
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }

  if (isNewCNNotLocked) {
    return (
      <SignModal
        documentId={clinicalNote?.id}
        documentTypes={['clinical_note']}
        documentSignatures={documentSignatures}
        showConfirmationModal={true}
        setShowConfirmationModal={() => showConfirmSignLock(false)}
        onDocumentSignSuccess={async () => {
          await onLockNote({
            lockStatus: everyoneSigned({ documentSignatures, userState })
          });
        }}
        confirmModalProps={{
          title: 'Sign Clinical Note',
          message: 'Are you sure you want to sign this clinical note',
          primaryBtnTxt: 'Sign clinical note'
        }}
      />
    );
  }

  if (isNewCNLocked) {
    return (
      <Confirm
        primaryBtnTxt="Unlock"
        title="Unlock Clinical Note"
        icon="new-lock"
        message="It is the responsibility of the provider and associates to chart each encounter in an accurate and timely manner. Any changes to a locked note using unlock functionality are documented electronically using version control, for your protection. When unlocking a note, please consider any material changes be formalized through an addendum to the note for tracking purposes. Are you sure you want to unlock the clinical note?"
        handleContinue={async () => {
          if (!ia(documentSignatures)) {
            showAlert({
              title: 'Something went wrong please refresh!',
              color: 'danger'
            });
            return;
          }

          const canProceed = await updateDocSignatures({
            updateDocumentSignMutation,
            documentSignatures,
            userState
          });

          if (canProceed) {
            await onLockNote({ lockStatus: false });
          }
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }

  return null;
};

export default SignLockActionComponent;
