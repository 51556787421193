import { getPayersCheckin } from 'api/Payer';

export const searchPayers = async (searchTerm, navigate) => {
  const payers = await getPayersCheckin(navigate, {
    searchTerm
  });

  return payers.payers.map((p) => ({
    label: `Payer ID: ${p.id} - Payer Name: ${p.name}`,
    value: p.id,
    payer_name: p.name
  }));
};
