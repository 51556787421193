import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Goals from './Goals';

const GoalsSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Goals', parentKey: 'progress', childKey: 'goals' }}
      title={components?.goals?.title}
      id={components?.goals?.id}
      disabled={clinicalNote?.locked}>
      <Goals sectionRef={sectionRef} section="progress" />
    </Accordion>
  );
};

export default GoalsSection;
