import { Popover } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import Button from 'components/shared/Buttons/Button';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { formatDate, iaRa } from 'lib/helpers/utility';
import moment from 'moment';
import React from 'react';
import { Calendar } from 'react-date-range';
import { useRecoilValue } from 'recoil';
import practiceState from '../practiceState';
import { conditionalFormatTimeZ } from './SuperBillPage/lib/conditionTimeFormat';
import { generateBaseUnit } from './SuperBillPage/lib/generateBaseUnit';
import { getHours } from './SuperBillPage/lib/getHours';
import { shouldDisableProcedureProduct } from './SuperBillPage/lib/shouldDisableProcedure';

export default function CPTRow({
  item,
  handleProcedureChanges,
  index,
  posOptions,
  handleDeletion,
  handleCPTSearch,
  selectCPT,
  superbill
}) {
  const disableProcedure = shouldDisableProcedureProduct({
    superbill,
    procedure: item
  });
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const formattedStartTime = conditionalFormatTimeZ(
    item?.appointment_start,
    currPractice?.timezone
  );
  const formattedEndTime = conditionalFormatTimeZ(item?.appointment_end, currPractice?.timezone);
  const hours = getHours(currPractice, formattedStartTime, formattedEndTime);
  let timeOptions = iaRa(hours).map((time) => ({
    value: time,
    label: time
  }));

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-x-4 gap-y-1 !rounded-lg p-2 odd:bg-neutral-50">
      <TippyContainer isDisabled={disableProcedure}>
        <Select
          label="Code"
          isAsync
          loadOptions={handleCPTSearch}
          onChange={(e) => selectCPT(e, index)}
          cacheOptions
          value={{ label: item.procedure_code }}
          noOptionsMessage={() => 'Start typing the code or description.'}
          inputId="cptCodes"
          menuPortalTarget={document.body}
          disabled={disableProcedure}
          isClearable={false}
        />
      </TippyContainer>

      <Input
        label="NDC"
        name="narrative"
        value={item.narrative}
        onChange={(e) => handleProcedureChanges(e, index)}
      />
      <Input
        label="Modifier"
        name="modifiers"
        value={item.modifiers}
        onChange={(e) => handleProcedureChanges(e, index)}
      />
      <Input
        label="Diagnosis pointer"
        name="diagnosis"
        value={item.diagnosis}
        onChange={(e) => handleProcedureChanges(e, index)}
      />
      <CurrencyInput
        label="Charges"
        name="charge"
        value={item.charge}
        onValueChange={(value) =>
          handleProcedureChanges({ target: { value, name: 'charge' } }, index)
        }
      />
      <Input
        label="Days/Units"
        name="units"
        value={item.units}
        onChange={(e) => {
          if (e.target.value < 1) {
            return;
          }
          handleProcedureChanges(e, index);
        }}
        min={1}
        type="number"
      />
      <Select
        label="POS"
        className="print:hidden"
        options={posOptions}
        value={posOptions.find((p) => p.value === String(item.place_of_service))}
        onChange={(e) =>
          handleProcedureChanges({ target: { value: e.value, name: 'place_of_service' } }, index)
        }
        isMulti={false}
      />
      <Input
        label="Place of service"
        className="hidden print:block"
        value={item.place_of_service}
        type="text"
      />
      <Popover className="relative bg-white">
        {({ close }) => (
          <>
            <Popover.Button className="w-full p-0">
              <Input
                rightIcon="new-calendar-gray"
                label="From date"
                value={formatDate(item.from_date)}
              />
            </Popover.Button>
            <Popover.Panel className="absolute top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
              <Calendar
                showDateDisplay
                className="w-full"
                onChange={(date) => {
                  const now = new Date();
                  date.setHours(now.getHours());
                  handleProcedureChanges(
                    { target: { value: moment(date).format('MM/DD/YYYY'), name: 'from_date' } },
                    index
                  );
                  close();
                }}
                date={new Date(formatDate(item.from_date))}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      <Popover className="relative bg-white">
        {({ close }) => (
          <>
            <Popover.Button className="w-full p-0">
              <Input
                rightIcon="new-calendar-gray"
                label="To date"
                value={formatDate(item.thru_date)}
              />
            </Popover.Button>

            <Popover.Panel className="absolute top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
              <Calendar
                showDateDisplay
                className="w-full"
                onChange={(date) => {
                  const now = new Date();
                  date.setHours(now.getHours());
                  handleProcedureChanges(
                    { target: { value: moment(date).format('MM/DD/YYYY'), name: 'thru_date' } },
                    index
                  );
                  close();
                }}
                date={new Date(formatDate(item.thru_date))}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      {item?.base_unit && (
        <>
          <Select
            label="Start Time"
            parentClassName="w-full"
            isClearable={false}
            className="!-mt-[2px]"
            rightIcon="new-clock-gray"
            options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
            onChange={(val) =>
              handleProcedureChanges({ target: { value: val.value, name: 'start_time' } }, index)
            }
            value={
              timeOptions.find((option) => option.value == item.start_time) ||
              timeOptions.find((option) => option.value == formattedStartTime)
            }
          />
          <Select
            label="End Time"
            parentClassName="w-full"
            isClearable={false}
            className="!-mt-[2px]"
            rightIcon="new-clock-gray"
            options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
            onChange={(val) =>
              handleProcedureChanges({ target: { value: val.value, name: 'end_time' } }, index)
            }
            value={
              timeOptions.find((option) => option.value == item.end_time) ||
              timeOptions.find((option) => option.value == formattedEndTime)
            }
          />
          <Button
            onClick={() =>
              generateBaseUnit({
                procedure: item,
                formattedStartTime,
                formattedEndTime,
                index,
                updateProcedures: handleProcedureChanges
              })
            }
            text="Calculate Unit"
            className="mb-1 mt-auto max-h-[40px] w-full"
          />
        </>
      )}

      <TippyContainer className="mb-1 mt-auto max-h-[40px]" isDisabled={disableProcedure}>
        <Button
          disabled={disableProcedure}
          close
          onClick={() => handleDeletion(index)}
          text="Remove"
          iconColor="white"
          icon="trash"
          color="danger"
          outlined
          className="mt-auto max-h-[40px] w-full"
        />
      </TippyContainer>
    </div>
  );
}

const TippyContainer = ({ isDisabled = false, className = null, children }) => {
  return (
    <Tippy
      className="tippy-dark"
      disabled={!isDisabled}
      content="Procedure cannot be removed because the clinical note is locked.">
      <div className={className}>{children}</div>
    </Tippy>
  );
};
