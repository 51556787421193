import React from 'react';
import Icon from '../../../../../shared/Icon/Icon';
import Button from '../../../../../shared/Buttons/Button';

const EligibilityErrorComponent = ({ eligibility, closeModal }) => {
  return (
    <div className="relative flex h-full w-full flex-col items-center !gap-6 bg-white pt-[100px]">
      <div className="absolute right-0 top-0">
        <Icon icon="red-rectangle-shape" />
        <Icon icon="red-rectangle-shape-2" className="absolute right-0 top-0 z-20" />
      </div>

      <Icon icon="request-error" />

      <h3 className="text-[28px] font-500 text-danger-500">Eligibility request - Error</h3>

      <div className="flex flex-col items-center !gap-2">
        <p className="text-base font-500 text-neutral-700">Error code: {eligibility.error_code}</p>
        <p className="text-base font-500 text-neutral-900">{eligibility.error_mesg}</p>
      </div>

      <Button
        outlined
        type="primary"
        color="danger"
        onClick={closeModal}
        text="Back to appointment"
      />
    </div>
  );
};

export default EligibilityErrorComponent;
