import React from 'react';
import { ia } from 'lib/helpers/utility';

const PayerAddress = ({ payer }) => {
  return (
    <>
      {ia(payer?.practicePayers) && payer.practicePayers[0]?.payerAddress?.fullAddress ? (
        <p>{payer.practicePayers[0]?.payerAddress?.fullAddress}</p>
      ) : payer?.payerAddress?.fullAddress ? (
        <p>{payer.payerAddress.fullAddress}</p>
      ) : (
        <p>N/A</p>
      )}
    </>
  );
};

export default PayerAddress;
