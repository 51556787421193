import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { defaultFilters } from '../lib';
import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import { usePatientSuperbill } from 'lib/hooks/queries/superbill/usePatientSuperbill';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import ErrorBoundary from 'components/shared/Error/Boundary';
import cs from 'classnames';

const SuperbillTable = () => {
  const { id } = useParams();
  const { patient } = useOutletContext();
  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const navigate = useNavigate();

  const { data } = usePatientSuperbill({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      patientId: patient?.id
    },
    dependencies: [limit, page, sort, mapValues(filters), patient?.id]
  });

  const superbills = data?.superbill || [];

  const { data: providers = {} } = useProviders({ params: {} });

  useEffect(() => {
    if (ia(providers?.practitioners)) {
      const formattedProviders = providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
      setFilters((prev) => ({
        ...prev,
        providers: { ...prev.providers, options: formattedProviders }
      }));
    }
  }, [providers?.practitioners]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/portal/charts/${id}/billing/superbill/${event.data.id}`, { replace: false });
    }
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    await onRowClicked(e);
  };

  return (
    <ErrorBoundary>
      <div className="!m-2 flex items-center justify-end gap-2">
        <DisplayButton />
        <Filter
          category="superbill"
          defaultFilters={defaultFilters}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <div className={cs('flex h-full flex-col overflow-hidden !pb-0')}>
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={superbills}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            onCellClicked={onCellClicked}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit + 1} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default SuperbillTable;
