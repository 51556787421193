import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import CPTCodes from '../../SOAPNote/components/CPTCodes/CPTCodes';

const CPTCodesSection = ({ components, sectionRef }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.cptCodes?.title}
      id={components?.cptCodes?.id}
      disabled={clinicalNote?.locked}>
      <CPTCodes sectionRef={sectionRef} />
    </Accordion>
  );
};

export default CPTCodesSection;
