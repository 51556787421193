import { useEffect, useRef, useState } from 'react';

const useHasScrollbar = (options = {}) => {
  const { type = 'vertical' } = options;
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const container = ref.current;

    const updateHasScrollbar = () => {
      if (container) {
        let hasScrollbar = false;
        if (type === 'horizontal') {
          hasScrollbar = container.scrollWidth > container.offsetWidth;
        } else {
          hasScrollbar = container.scrollHeight > container.offsetHeight;
        }
        setHasScrollbar(hasScrollbar);
      }
    };

    const observer = new MutationObserver(updateHasScrollbar);

    if (container) {
      observer.observe(container, { childList: true, subtree: true });
    }

    updateHasScrollbar();

    window.addEventListener('resize', updateHasScrollbar);

    return () => {
      window.removeEventListener('resize', updateHasScrollbar);
      if (observer && container) {
        observer.disconnect();
      }
    };
  }, [type]);

  return [ref, hasScrollbar];
};

export default useHasScrollbar;
