import Icon from 'components/shared/Icon/Icon';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const MyScribeAILink = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathnameParts = pathname.split('/');
  const finalPathname = pathnameParts.slice(0, pathnameParts.length - 1).join('/');

  const { favoriteTemplateId } = useMyScribeAIContext();

  useEffect(() => {}, []);
  return (
    <>
      {pathname.includes('myscribe-ai') ? (
        <div className="flex cursor-pointer items-center" onClick={() => navigate(-1)}>
          <Icon icon="chevron-left" color="primary" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#C1EFFF] bg-clip-text pl-1 text-lg font-medium leading-snug text-transparent">
            Go Back Clinical Templates
          </div>
        </div>
      ) : (
        <Link
          className="flex cursor-pointer items-center"
          to={`${finalPathname}/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`}>
          <Icon icon="stars-ai" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
            Go To MyScribe AI
          </div>
        </Link>
      )}
    </>
  );
};

export default MyScribeAILink;
