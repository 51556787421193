import React from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import Select from '../../../Select/Select';
import Input from '../../../Input/Input';
import { howLongAgo } from '../../../../../constants';

const RSPatientBlock = ({ title, noBlock, row, blockIdx, onChange, disabled, locked = false }) => {
  return (
    <div className="ReviewSystems__block">
      <div className="ReviewSystems__block--inner">
        <p className="ReviewSystems__block__title">{title}</p>
        <Checkbox
          label="No known issue"
          id="no-known-issue"
          isChecked={noBlock}
          onChange={(event) => onChange('noBlock', event.target.value, blockIdx, false)}
          disabled={disabled || locked}
        />
        {!noBlock &&
          row?.map((row, rowIdx) => (
            <div className="ReviewSystems__block__row" key={rowIdx}>
              <Checkbox
                id={`rspatient-checkbox-${rowIdx}`}
                label={row.name}
                isChecked={row.checked}
                onChange={(event) =>
                  onChange('checked', !JSON.parse(event.target.value), blockIdx, rowIdx)
                }
                disabled={disabled || locked}
              />
              {row.checked && (
                <div className="mb-[6px] grid gap-1">
                  <Select
                    inputId={`rspatient-select-${rowIdx}`}
                    className="w-full"
                    placeholder="How long ago?"
                    value={row.when}
                    onChange={(value) => onChange('when', value, blockIdx, rowIdx)}
                    options={howLongAgo}
                    disabled={disabled || locked}
                  />
                  {row.hasOwnProperty('where') && (
                    <Input
                      data-qa={`rspatient-input-${rowIdx}`}
                      placeholder="Where"
                      value={row.where}
                      onChange={(event) => onChange('where', event.target.value, blockIdx, rowIdx)}
                      disabled={disabled || locked}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RSPatientBlock;
