export const colDefs = [
  {
    field: 'periodPracticeCount',
    headerName: 'New Practices'
  },
  {
    field: 'totalPracticeCount',
    headerName: 'Total Practices'
  },
  {
    field: 'periodPatientCount',
    headerName: 'New Patients'
  },
  {
    field: 'totalPatientCount',
    headerName: 'Total Patients'
  },
  {
    field: 'periodStaffCount',
    headerName: 'New Staff Members'
  },
  {
    field: 'totalStaffCount',
    headerName: 'Total Staff Members'
  },
  {
    field: 'periodClaimMDCount',
    headerName: 'New ClaimMDs'
  },
  {
    field: 'totalClaimMDCount',
    headerName: 'Total ClaimMDs'
  },
  {
    field: 'periodVeradigmEpcsCount',
    headerName: 'New Veradigm EPCS'
  },
  {
    field: 'totalVeradigmEpcsCount',
    headerName: 'Total Veradigm EPCS'
  },
  {
    field: 'periodVeradigmEPCount',
    headerName: 'New Veradigm ePrescribe'
  },
  {
    field: 'totalVeradigmEPCount',
    headerName: 'Total Veradigm ePrescribe'
  },
  {
    field: 'periodProcessing',
    headerName: 'This Period Processing'
  },
  {
    field: 'totalProcessing',
    headerName: 'Total Processing'
  },
  {
    field: 'periodCARD_PRESENT',
    headerName: 'This Period Card Present Processing'
  },
  {
    field: 'totalCARD_PRESENT',
    headerName: 'Total Card Present Processing'
  },
  {
    field: 'periodCOF',
    headerName: 'This Period COF Processing'
  },
  {
    field: 'totalCOF',
    headerName: 'Total COF Processing'
  },
  {
    field: 'periodEMV_CONTACT',
    headerName: 'This Period EMV Contact Processing'
  },
  {
    field: 'totalEMV_CONTACT',
    headerName: 'Total EMV Contact Processing'
  },
  {
    field: 'periodIN_PROGRESS',
    headerName: 'This Period In Progress Processing'
  },
  {
    field: 'totalIN_PROGRESS',
    headerName: 'Total In Progress Processing'
  },
  {
    field: 'periodcash',
    headerName: 'This Period Cash Processing'
  },
  {
    field: 'totalcash',
    headerName: 'Total Cash Processing'
  },
  {
    field: 'periodSWIPE',
    headerName: 'This Period SWIPE Processing'
  },
  {
    field: 'totalSWIPE',
    headerName: 'Total SWIPE Processing'
  },
  {
    field: 'periodcare_credit',
    headerName: 'This Period Care Credit Processing'
  },
  {
    field: 'totalcare_credit',
    headerName: 'Total Care Credit Processing'
  },
  {
    field: 'periodONLINE',
    headerName: 'This Period Online Processing'
  },
  {
    field: 'totalONLINE',
    headerName: 'Total Online Processing'
  },
  {
    field: 'periodcheck',
    headerName: 'This Period Check Processing'
  },
  {
    field: 'totalcheck',
    headerName: 'Total Check Processing'
  }
];
