import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io, sortObjectByProperty } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { filterFalsyValues } from '../../../lib/filterFalsyValues';
import { generateHPList } from '../../../lib/generateHPList';
import OrdersSection from '../../../Orders/OrdersSection';
import AdvancedHP from './components/AdvancedHP';
import AllergiesSection from './components/AllergiesSection';
import AssessmentSection from './components/AssessmentSection';
import ComplaintsSection from './components/ComplaintsSection';
import CPTCodesSection from './components/CPTCodesSection';
import ExamSection from './components/ExamSection';
import FamilyHistorySection from './components/FamilyHistorySection';
import HPIRevisionsSection from './components/HPIRevisionsSection';
import ICDCodesSection from './components/ICDCodesSection';
import MedicalHistorySection from './components/MedicalHistorySection';
import MedicationsSection from './components/MedicationsSection';
import NotesSection from './components/NotesSection';
import PlanSection from './components/PlanSection';
import ReviewOfSystems from './components/ReviewOfSystems';
import SocialHistorySection from './components/SocialHistorySection';
import SurgicalHistorySection from './components/SurgicalHistorySection';
import TestSection from './components/TestSection';
import VaccinesSection from './components/VaccinesSection';
import VitalsSection from './components/VitalsSection';
import WeightLossAdministrationSection from './components/WeightLossAdministrationSection';
import { useRecoilValue } from 'recoil';
import { permissions } from 'components/state';

const HPNote = ({ context }) => {
  const userPermissions = useRecoilValue(permissions);
  const { hpNoteOverviewRefs } = context;
  const {
    components,
    setComponents,
    advancedHP,
    setOverviewData,
    cnDisplaySettings,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton,
    setEnhanceNarrativeParams,
    isFetchingEnhanceNarrative
  } = useClinicalNoteContext();
  const { id, appointmentId, formName, type } = useParams();
  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  };

  const [formsList, setFormList] = useState({
    complaints: ComplaintsSection,
    hpi: HPIRevisionsSection,
    ros: ReviewOfSystems,
    allergies: AllergiesSection,
    medicationHistory: MedicationsSection,
    vaccines: VaccinesSection,
    vitals: VitalsSection,
    weightLossAdministration: WeightLossAdministrationSection,
    socialHistory: SocialHistorySection,
    familyHistory: FamilyHistorySection,
    medicalHistory: MedicalHistorySection,
    surgicalHistory: SurgicalHistorySection,
    exam: ExamSection,
    test: TestSection,
    assessment: AssessmentSection,
    plan: PlanSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: NotesSection,
    orders: OrdersSection
  });
  const sectionRef = useRef();

  const firstActiveComponent = Object.keys(components)[0];
  const currAdvForm = advancedHP?.find((item) => {
    const title = item?.custom_title;
    if ((!formName && title === firstActiveComponent) || formName === title) return item;
  });

  useEffect(() => {
    const advancedHPForms = advancedHP.map((item) => {
      if (cnDisplaySettings?.sections?.hp[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    });
    const filteredAdvancedHPForms = advancedHPForms.filter((value) => io(value));
    const advancedTitles =
      ia(filteredAdvancedHPForms) &&
      Object.fromEntries(
        filteredAdvancedHPForms.map((item) => {
          const title = item?.custom_title;
          return [
            title,
            {
              title: item?.form?.name || item?.name,
              path: title,
              position: cnDisplaySettings?.sections?.hp?.[item?.custom_title]?.position
            }
          ];
        })
      );

    const assignAdvTitlesToComponent = Object.keys(advancedTitles).reduce((acc, key) => {
      acc[key] = () => <AdvancedHP currAdvForm={currAdvForm} />;
      return acc;
    }, {});

    setFormList((prevState) => ({ ...prevState, ...assignAdvTitlesToComponent }));

    const enabledComponents = filterFalsyValues(generateHPList(cnDisplaySettings, advancedTitles));
    const sortedComponents = sortObjectByProperty(enabledComponents, 'position');
    setComponents(sortedComponents);
  }, [cnDisplaySettings?.sections, advancedHP, formName, currAdvForm]);

  const CurrentForm = formsList && formsList?.[formName || Object.values(components)?.[0]?.path];

  const handleEnhanceNarrative = () => {
    setEnhanceNarrativeParams({
      kind: type,
      name: formName,
      body: sectionRef?.current?.formData?.[formName],
      customFormId: null
    });
  };

  if (!io(components) || !CurrentForm) return <Skeleton height="146px" />;

  return (
    <div className="flex h-full flex-col gap-y-3">
      <CurrentForm
        key={formName}
        components={components}
        sectionRef={sectionRef}
        options={
          userPermissions?.is_admin
            ? [
                {
                  title: 'Enhance with MyScribe AI',
                  icon: 'new-myscribe-stars',
                  onClick: () => handleEnhanceNarrative(),
                  loading: isFetchingEnhanceNarrative
                }
              ]
            : []
        }
        formId={currentHpOverviewData?.[formName]?.id}
        defaultValue={currentHpOverviewData?.[formName]?.response}
        hpNoteOverviewRef={hpNoteOverviewRefs?.[formName]}
        customFormsParams={customFormsParams}
        setCurrentFormData={(newData) =>
          setCurrentHpOverviewData((prevState) => ({
            ...prevState,
            [formName]: { ...(prevState?.[formName] || {}), response: newData }
          }))
        }
      />
    </div>
  );
};

export default HPNote;
