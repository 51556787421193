import Honeybadger from '@honeybadger-io/js';
import { interimApi } from 'api/InterimApi';
import { ia, search, splitName } from 'lib/helpers/utility';
import { isEmpty } from 'lodash';
import moment from 'moment';

export default async function mapInsuranceCompanyList({ superbill, states, patient }) {
  if (ia(superbill.insuranceProfile)) {
    const data = await Promise.all(
      superbill?.insuranceProfile?.map(async (e) => {
        const { address: payerAddress, phone: payer_phone } = await getPayerAddress(e?.payer_id);
        let relationAddress;
        let ins_patient;
        const isRelationSelf = !isEmpty(e?.relation) && e.relation === '18' ? true : false;
        if (!isRelationSelf) {
          if (e.relation_address) {
            relationAddress = await getRelationAddress(e.relation_address);
          }
          if (e.insured_first_name && e.insured_last_name) {
            ins_patient =
              e.insured_first_name + ' ' + e.insured_middle_name || '' + e.insured_last_name;
          }
        }
        return {
          label: `${e.payer_name} (${e.type})`,
          value: e.payer_id,
          pat_rel: e.relation,
          payer: {
            payerid: e.payer_id,
            payer_name: e.payer_name,
            payer_addr_1: payerAddress?.address_ln_1,
            payer_addr_2: payerAddress?.address_ln_2,
            payer_city: payerAddress?.city,
            payer_state: search(states, payerAddress?.state, 'id'),
            payer_zip: payerAddress?.zip,
            payer_office_number: payer_phone ?? '',
            // payer_order: payerAddress?.order,
            payer_state_id: payerAddress?.state
          },
          ins: {
            ins_number: e.member_id,
            ins_name_f: isRelationSelf ? patient.f_name : ins_patient?.firstName,
            ins_name_m: isRelationSelf ? patient.m_name : ins_patient?.middleName || '',
            ins_name_l: isRelationSelf ? patient.l_name : ins_patient?.lastName || '',
            ins_addr_1: isRelationSelf
              ? patient.userAddress?.address_ln_1
              : relationAddress?.address_ln_1,
            ins_addr_2: isRelationSelf
              ? patient.userAddress?.address_ln_2
              : relationAddress?.address_ln_2,
            ins_city: isRelationSelf ? patient.userAddress?.city : relationAddress?.city,
            ins_state: isRelationSelf
              ? search(states, patient.userAddress?.state, 'id')
              : search(states, relationAddress?.state, 'id'),
            ins_zip: isRelationSelf ? patient.userAddress?.zip : relationAddress?.zip,
            ins_phone: isRelationSelf ? patient.phone : e?.other_relation_phone,
            ins_dob: isRelationSelf
              ? moment(e?.dob)?.format('YYYY-MM-DD')
              : moment(e?.insured_date_of_birth)?.format('YYYY-MM-DD'),
            ins_group: e?.group_number,
            prior_auth: e?.prior_auth
          }
        };
      })
    );

    return data;
  }
  return [];
}

async function getRelationAddress(id) {
  try {
    const res = await interimApi('/api/address/get', { id }, () => {});
    const { address } = res.data;
    if (address) {
      return address;
    }
  } catch (err) {
    Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
  }
}

async function getPayerAddress(id) {
  try {
    const res = await interimApi('/api/payers/read', { id }, () => {});
    if (res.data) {
      const phone = res.data?.payers.phone;
      const address = res.data.payers.payerAddress;
      if (address) {
        return { address, phone };
      } else if (phone) {
        return { address: {}, phone };
      } else {
        return { address: {}, phone: '' };
      }
    }
  } catch (error) {
    Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
  }
}
