import React from 'react';
import PrintViewHeader from './PrintViewHeader';

const PrintView = ({ practice, patient, content, createdAt }) => {
  return (
    <div className="flex min-h-[calc(100vh-54px)] grow justify-center overflow-hidden bg-neutral-200 !p-3 print:bg-white print:!p-0">
      <div className="print:!page-break-before flex h-full w-full flex-col bg-white print:w-full ">
        <PrintViewHeader practice={practice} patient={patient} createdAt={createdAt} />
        <div className="flex flex-col gap-[6px] whitespace-pre-line bg-white !outline-none print:!mb-0 print:break-inside-auto">
          {content}
        </div>
      </div>
    </div>
  );
};

export default PrintView;
