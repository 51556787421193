import React, { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/shared/Buttons/Button';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import Icon from 'components/shared/Icon/Icon';
import Axios from 'configuredAxios';

import { showAlert } from 'components/shared/Alert/Alert';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import VaccinesForm from 'components/shared/Forms/Custom/Vaccines/Vaccines';
import Modal from 'components/shared/Modal/Modal';

const Vaccine = ({ item, openItemModal }) => {
  const isNoDetailsTrue = !!item?.original?.response?.noDetails;

  return (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      {isNoDetailsTrue ? (
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No known vaccines</p>
          <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>
        </div>
      ) : (
        <List
          items={item?.list}
          dividerType="none"
          renderItemComponent={(listItem, index) => (
            <>
              <div className="flex items-center justify-between gap-2">
                <p className="font-medium text-neutral-800">{listItem?.vaccine?.label}</p>
                {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
              </div>

              {(listItem.doses || listItem.lastDose) && (
                <p className="font-medium text-primary-600">
                  {[
                    listItem.doses
                      ? listItem?.doses?.label !== 'Not sure'
                        ? listItem?.doses?.label + ' doses'
                        : 'Not Sure'
                      : null,
                    listItem.lastDose ? `Latest ${formatDate(listItem?.lastDose)}` : null
                  ]
                    .filter(Boolean)
                    .join(' - ')}
                </p>
              )}

              {listItem.note && <p className="line-clamp-2 text-neutral-600">{listItem.note}</p>}
            </>
          )}
        />
      )}

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function Vaccines(props) {
  const { customData, layout, changedSizes, patientId } = props;
  const vaccines = customData?.vaccines?.length > 0 ? customData?.vaccines : null;
  const defaultSize = useMemo(() => layout.find((l) => l.i === props.item.i)?.h, [layout]);

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () => limitItems(vaccines, defaultSize, { note: 0.3, doses: 0.3 }),
    [vaccines, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [itemModal, setItemModal] = useState(null);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    setItemModal(item?.original);
    setCurrentFormData(item?.original?.response);
  };

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId: itemModal?.id,
        patientId,
        response: currentFormData,
        type: 'vaccines'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ title: 'Successfully saved' });
        setItemModal();
        setCurrentFormData();
        queryClient.invalidateQueries(['customFormResponse']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader onPlusClick={() => setItemModal({})} icon="vaccine" {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <FooterWrapper
          hasMore={hasMore}
          onClick={() => setShow(true)}
          text={`Show all vaccines (${count})`}>
          {items ? (
            <List
              items={items}
              renderItemComponent={(item, index) => (
                <Vaccine openItemModal={openItemModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Vaccines</p>
            </div>
          )}
        </FooterWrapper>
      </div>

      <Modal
        handleOpen={show}
        className="w-[1020px] bg-white"
        title="Vaccines"
        slideFromRight
        handleClose={() => setShow(false)}>
        {vaccines ? (
          <List
            items={vaccines}
            renderItemComponent={(item, index) => (
              <Vaccine openItemModal={openItemModal} item={item} />
            )}
          />
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Vaccines</p>
          </div>
        )}
      </Modal>

      <Modal
        className="w-[1020px]"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setItemModal();
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }
        handleOpen={!!itemModal}
        title="Vaccine"
        slideFromRight
        handleClose={() => setItemModal()}>
        <VaccinesForm
          setItemModal={setItemModal}
          formId={itemModal?.id}
          defaultValue={itemModal?.response}
          setCurrentFormData={setCurrentFormData}
        />
      </Modal>
    </div>
  );
}
