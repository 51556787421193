import React, { useCallback, useMemo, useState } from 'react';
import { PatientBalanceContextApi, PatientBalanceContextData } from './PatientBalanceContext';

export const PatientBalanceContextProvider = ({ children }) => {
  const [form, setForm] = useState();
  const [allocating, setAllocating] = useState({});
  const [mode, setMode] = useState({ writeoff: false, allocate: false });
  const [tabIndex, setTabIndex] = useState(0);

  const finalData = useMemo(
    () => ({
      formData: form,
      allocatingRow: allocating,
      activeMode: mode,
      activeTab: tabIndex
    }),
    [form, allocating, mode, tabIndex]
  );

  const setActiveMode = useCallback(setMode, [setMode]);
  const setFormData = useCallback(setForm, [setForm]);
  const setAllocatingRow = useCallback(setAllocating, [setAllocating]);
  const setActiveTab = useCallback(setTabIndex, [setTabIndex]);
  const api = useMemo(
    () => ({ setFormData, setActiveMode, setAllocatingRow, setActiveTab }),
    [setActiveTab, setAllocatingRow, setFormData, setActiveMode]
  );

  return (
    <PatientBalanceContextData.Provider value={finalData}>
      <PatientBalanceContextApi.Provider value={api}>{children}</PatientBalanceContextApi.Provider>
    </PatientBalanceContextData.Provider>
  );
};
