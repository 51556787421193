import { capitalize } from 'lodash';
import { formTypes } from './constants';
import * as Yup from 'yup';
import { formatDate } from 'lib/helpers/utility';
import Actions from '../components/Actions';

export const FormInitialValues = (form, packetIds) => ({
  form: {
    id: form?.id || null,
    name: form?.name || '',
    json: form?.json || null
  },
  form_details: {
    create_type: form?.type || null,
    existing: form?.existing || null,
    integrate: form?.integration || null,
    receive: form?.receive || null
  },
  form_packet_ids: packetIds || []
});

export const FormValidationSchema = Yup.object().shape({
  form: Yup.object().shape({
    name: Yup.string().required('Name is required')
  })
});

export const getCurrentStep = (breadcrumbs, currentBreadcrumb) => {
  // const hiddenSteps = breadcrumbs.filter((breadcrumb) => breadcrumb.hidden);
  let index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.view === currentBreadcrumb);
  if (index >= 0) {
    index = index + 1;
  }
  return index;
};

export const getStepsLeft = (breadcrumbs, currentBreadcrumb) => {
  //get breadcrumbs which are not hidden
  const nonHiddenSteps = breadcrumbs.filter((breadcrumb) => !breadcrumb.hidden);
  const currentIndex = nonHiddenSteps.findIndex(
    (breadcrumb) => breadcrumb.view === currentBreadcrumb
  );

  if (currentIndex === -1) {
    return -1;
  }

  const remainingSteps = nonHiddenSteps.slice(currentIndex + 1);

  return remainingSteps.length;
};
export const getAllStepsLeft = (breadcrumbs, currentBreadcrumb) => {
  //get all breadcrumbs with hidden included
  const currentIndex = breadcrumbs.findIndex((breadcrumb) => breadcrumb.view === currentBreadcrumb);

  if (currentIndex === -1) {
    return -1;
  }

  const remainingSteps = breadcrumbs.slice(currentIndex + 1);

  return remainingSteps.length;
};

export const formTypeFormatter = (value) => {
  const type = formTypes.find((form) => form.value === value);
  return type && type.label;
};

export const getAnswerLabel = (questionKey, answerValue) => {
  const answer = questionKey.answers.find((key) => key.value === answerValue);
  return answer && answer.title;
};

export const defaultColumns = [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value
  },
  {
    field: 'formType',
    headerName: 'Form Type',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value?.name
  },
  {
    field: 'packets',
    headerName: 'Packets',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value?.map((v) => v.label)
  },
  {
    field: 'version',
    headerName: 'Version',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'updated_at',
    headerName: 'Updated On',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDate(value)
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of creation',
    preview: (values) =>
      formatDate({ date: values.startDate, format: 'MMM D, YYYY' }) +
      ' - ' +
      formatDate({ date: values.endDate, format: 'MMM D, YYYY' })
  },
  name: {
    type: 'search',
    values: null,
    title: 'Form name',
    placeholder: 'Search name'
  },
  providers: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Providers',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search provider'
  },
  type: {
    type: 'search',
    multiple: true,
    values: [],
    title: 'Form type',
    preview: (values) => values?.map((v) => v.label).join(', '),
    placeholder: 'Search type'
  }
};
