import { Honeybadger } from '@honeybadger-io/react';
import { useEnhanceNarrative } from 'lib/hooks/queries/myscribeAI/useEnhanceNarrative';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { clinicalNote as clinicalNoteState, userState } from '../../../components/state';
import { ClinicalNoteContext } from './ClinicalNoteContext';

const SAVE_BUTTON_TEXT = 'Save Draft';

export const ClinicalNoteContextProvider = ({ children, outsideClinicalNoteContext = false }) => {
  const [tab, setTab] = useState(null);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const [staticClinicalNote, setStaticClinicalNote] = useState({});
  const [components, setComponents] = useState({});
  const [advancedHP, setAdvancedHP] = useState([]);
  const advancedHPRef = useRef();
  const advancedFormNarrativeRef = useRef();
  const advancedSOAPRef = useRef();
  const advancedFormRef = useRef();
  const [advancedHPElRefs, setAdvancedHPElRefs] = useState([]);
  const [advancedSOAP, setAdvancedSOAP] = useState([]);
  const [customFormTypes, setCustomFormTypes] = useState([]);
  const [advancedSOAPElRefs, setAdvancedSOAPElRefs] = useState([]);
  const [serviceCPT, setServiceCPT] = useState();
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [currentHpOverviewData, setCurrentHpOverviewData] = useState({});
  const [vitals, setVitals] = useState([]);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [cnError, setCnError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [cnDisplaySettings, setCNDisplaySettings] = useState(
    currentUser?.display_settings.clinicalNote
  );
  const [cnDisplaySettingsLoading, setCNDisplaySettingsLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showPrevNoteModal, setShowPrevNoteModal] = useState(false);
  const [previewNoteModal, setPreviewNoteModal] = useState(false);
  const [isExport, setIsExport] = useState(true);
  const [patientResponseSidebar, setPatientResponseSidebar] = useState(false);
  const navigate = useNavigate();
  const [confirmSignLock, showConfirmSignLock] = useState(false);
  const saveNoteRef = useRef(null);
  const vitalsRef = useRef();
  const [saveButtonText, setSaveButtonText] = useState(SAVE_BUTTON_TEXT);
  const [forceSave, setForceSave] = useState({
    advancedForm: false
  });
  const [documentSignInfo, setDocumentSignInfo] = useState({});
  const [enhanceNarrativeParams, setEnhanceNarrativeParams] = useState({
    kind: null,
    name: null,
    body: null,
    customFormId: null
  });
  const [appointment, setAppointment] = useState(null);

  const { isFetching: isFetchingEnhanceNarrative, isLoading: isLoadingEnhanceNarrative } =
    useEnhanceNarrative({
      params: {
        kind: enhanceNarrativeParams.kind,
        name: enhanceNarrativeParams.name,
        body: enhanceNarrativeParams.body,
        customFormId: enhanceNarrativeParams.customFormId,
        id: clinicalNote?.id
      },
      options: {
        onSettled: (data) => {
          if (data?.enhancedNarrative) {
            const updateNarrative = (key, value) => {
              setClinicalNote({
                ...clinicalNote,
                [key]:
                  typeof clinicalNote[key] === 'object'
                    ? { ...clinicalNote[key], narrative: value }
                    : value
              });
            };

            if (data?.name === 'goals') {
              setClinicalNote({
                ...clinicalNote,
                goals_narrative: data?.enhancedNarrative
              });
            } else if (data?.kind && data?.customFormId && advancedFormNarrativeRef.current) {
              advancedFormNarrativeRef.current((prev) => ({
                ...prev,
                narrative: data?.enhancedNarrative
              }));
            } else if (data?.kind) {
              switch (data.kind) {
                case 'hp':
                case 'soap':
                  updateNarrative(data?.name, data?.enhancedNarrative);
                  break;
                case 'intake':
                  if (['timeFrames', 'riskAndCoMorbidities'].includes(data?.name)) {
                    setClinicalNote({
                      ...clinicalNote,
                      mha: {
                        ...clinicalNote?.mha,
                        [data?.name]: data?.enhancedNarrative
                      }
                    });
                  } else {
                    updateNarrative(data?.name, data?.enhancedNarrative);
                  }
                  break;
                case 'progress':
                  if (['sessionQuotesAndContents'].includes(data?.name)) {
                    setClinicalNote({
                      ...clinicalNote,
                      epn: {
                        ...clinicalNote?.epn,
                        [data?.name]: data?.enhancedNarrative
                      }
                    });
                  } else if (['notes'].includes(data?.name)) {
                    updateNarrative(data?.name, data?.enhancedNarrative);
                  } else {
                    setClinicalNote({
                      ...clinicalNote,
                      epn: {
                        ...clinicalNote?.epn,
                        [data?.name]: {
                          ...clinicalNote?.epn?.[data?.name],
                          narrative: data?.enhancedNarrative
                        }
                      }
                    });
                  }
                  break;
              }
            }
          }
        }
      },
      dependencies: [
        enhanceNarrativeParams.kind,
        enhanceNarrativeParams.name,
        enhanceNarrativeParams.body,
        clinicalNote?.id
      ]
    });

  useEffect(() => {
    if (outsideClinicalNoteContext) return;
    updateUserSettings();
  }, [cnDisplaySettings]);

  useEffect(() => {
    return () => {
      setClinicalNote({});
    };
  }, []);

  const updateSaveButton = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText('Saved');
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText(SAVE_BUTTON_TEXT);
  };

  const updateUserSettings = async () => {
    await handleSave();
  };

  const handleSave = async () => {
    setCNDisplaySettingsLoading(true);

    try {
      let params = {
        practitioner_id: currentUser.id,
        fields: {
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        }
      };
      let res = await interimApi('/api/practice/settings/practice_user/update', params, navigate);
      if (res.data) {
        setCurrentUser({
          ...currentUser,
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        });
      }
    } catch (error) {
      Honeybadger.notify(`cnDisplaySettings save - catch error - ${error}`);
    }

    setCNDisplaySettingsLoading(false);
  };

  const applyForceSave = ({ type = null, value = false }) => {
    if (!type) return;

    setForceSave((prevState) => ({ ...prevState, [type]: value }));
  };

  return (
    <ClinicalNoteContext.Provider
      value={{
        clinicalNote,
        setClinicalNote,
        staticClinicalNote,
        setStaticClinicalNote,
        components,
        setComponents,
        advancedHP,
        setAdvancedHP,
        advancedSOAP,
        setAdvancedSOAP,
        customFormTypes,
        setCustomFormTypes,
        advancedHPRef,
        advancedFormNarrativeRef,
        advancedSOAPRef,
        advancedFormRef,
        advancedHPElRefs,
        setAdvancedHPElRefs,
        advancedSOAPElRefs,
        setAdvancedSOAPElRefs,
        loading,
        setLoading,
        serviceCPT,
        setServiceCPT,
        overviewData,
        setOverviewData,
        vitals,
        setVitals,
        selectedTreatmentPlan,
        setSelectedTreatmentPlan,
        currentHpOverviewData,
        setCurrentHpOverviewData,
        cnError,
        setCnError,
        tab,
        setTab,
        alert,
        setAlert,
        cnDisplaySettings,
        setCNDisplaySettings,
        cnDisplaySettingsLoading,
        selected,
        setSelected,
        showPrevNoteModal,
        setShowPrevNoteModal,
        previewNoteModal,
        setPreviewNoteModal,
        isExport,
        setIsExport,
        saveNoteRef,
        vitalsRef,
        confirmSignLock,
        showConfirmSignLock,
        patientResponseSidebar,
        setPatientResponseSidebar,
        saveButtonText,
        setSaveButtonText,
        updateSaveButton,
        forceSave,
        setForceSave,
        applyForceSave,
        documentSignInfo,
        setDocumentSignInfo,
        outsideClinicalNoteContext,
        setEnhanceNarrativeParams,
        isLoadingEnhanceNarrative,
        isFetchingEnhanceNarrative,
        appointment,
        setAppointment
      }}>
      {children}
    </ClinicalNoteContext.Provider>
  );
};
