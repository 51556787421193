import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import checkinBackgroundPNG from '../../../assets/Images/checkin-background.png';
import useProcessLogin from '../../../lib/hooks/useProcessLogin';
import Button from '../../shared/Buttons/Button';
import Icon from '../../shared/Icon/Icon';
import Input from '../../shared/Input/Input';
import RadioButton from '../../shared/RadioButton/RadioButton';
import UserActivateAdvertisement from '../../user/Activate/Advertisement';
import { connectToSocket } from '../../Utility';
import { useQueryClient } from '@tanstack/react-query';
import practiceState from '../../practice/practiceState';
import { getOwnerPractices } from '../../../api/Practice';
import cs from 'classnames';
import Modal from 'components/shared/Modal/Modal';
import NotFound from 'components/practice/Dashboard/components/NotFound';

const PatientCheckinLogin = ({ setLoginRequest = () => {}, isCheckin }) => {
  const [show, setShow] = useState({
    loading: false,
    confirmCode: false,
    email: true,
    selection: 'email',
    error: '',
    pwd: false
  });
  const [creds, setCreds] = useState({ email: '', pwd: '', phone: '', code: '' });
  const [ownerPractices, setOwnerPractices] = useRecoilState(practiceState.ownerPractices);
  const [ownerSelectedLocation, setOwnerSelectedLocation] = useRecoilState(
    practiceState.ownerSelectedLocation
  );
  const [showIdleModal, setShowIdleModal] = useState(false);
  const navigate = useNavigate();
  const processLogin = useProcessLogin();
  const queryClient = useQueryClient();
  let { state } = useLocation();

  useEffect(() => {
    setLoginRequest(true);
    if (state?.idle) setShowIdleModal(true);
  }, []);

  const navigateToLast = (redirect) => {
    setTimeout(() => {
      const redirectLink = localStorage.getItem('redirectTo');
      if (redirectLink) {
        localStorage.removeItem('redirectTo');
        redirectLink && redirectLink !== '/login' ? navigate(redirectLink) : navigate(redirect);
      }

      const lastPage = localStorage.getItem('myriad_pl');
      const lastPageExp = localStorage.getItem('myriad_pl_timestamp');
      if (redirect === '/portal' || redirect === '/select-practice') {
        localStorage.removeItem('myriad_pl');
        localStorage.removeItem('myriad_pl_timestamp');
        navigate(redirect);
      } else if (
        lastPage &&
        lastPageExp &&
        moment().isBefore(moment(lastPageExp).add(5, 'minute'))
      ) {
        localStorage.removeItem('myriad_pl');
        localStorage.removeItem('myriad_pl_timestamp');
        navigate(lastPage);
      } else if (redirect) {
        if (lastPage) localStorage.removeItem('myriad_pl');
        if (lastPageExp) localStorage.removeItem('myriad_pl_timestamp');
        navigate(redirect);
      } else {
        if (lastPage) localStorage.removeItem('myriad_pl');
        if (lastPageExp) localStorage.removeItem('myriad_pl_timestamp');
        navigate('/myportal');
      }
    }, 100);
  };

  const getPractices = async (user) => {
    const data = await getOwnerPractices(navigate, {
      practice_ids: user.practice_ids || []
    });

    setOwnerSelectedLocation(user.selected_practice_id);
    setOwnerPractices(data.practices);
  };

  const emailLogin = async () => {
    const onSuccess = async (data, res) => {
      queryClient.refetchQueries();

      if (data?.session_token) connectToSocket(data.session_token);

      if (data.user.practice_ids || data.user.selected_practice_id) getPractices(data.user);

      const { user } = data;
      await processLogin({ user, redirect: res?.redirect, navigateToLast });
    };
    const onError = (error) => {
      if (error) {
        setShow((ps) => ({
          ...ps,
          loading: false,
          error:
            typeof error === 'string'
              ? error
              : error?.message
                ? error?.message
                : JSON.stringify(error, null, 2)
        }));
      } else {
        setShow((ps) => ({ ...ps, loading: false, error: 'Unexpected error has occurred.' }));
      }
    };
    setShow((ps) => ({ ...ps, loading: true }));
    sessionStorage.removeItem('myriad-session-reference-id');
    await requestApi({
      url: '/api/user/login',
      params: { email: creds.email, password: creds.pwd },
      navigate,
      onSuccess,
      onError,
      onCatch: onError
    });
  };

  const phoneLogin = async (altCode) => {
    const onSuccess = async (data, res) => {
      const { user } = data;
      await processLogin({ user, redirect: res?.redirect, navigateToLast });
    };
    const onError = (error, code) => {
      if (code === 5) {
        setShow((ps) => ({ ...ps, confirmCode: false }));
      }
      setShow((ps) => ({ ...ps, loading: false }));
    };
    setShow((ps) => ({ ...ps, loading: true }));
    sessionStorage.removeItem('myriad-session-reference-id');
    await requestApi({
      url: '/api/user/mfa/confirm',
      params: { code: altCode || creds.code },
      navigate,
      onSuccess,
      onError
    });
  };

  const phoneStart = async () => {
    const onSuccess = () => {
      setShow((ps) => ({ ...ps, confirmCode: true }));
    };
    setShow((ps) => ({ ...ps, loading: true }));
    sessionStorage.removeItem('myriad-session-reference-id');
    await requestApi({
      url: '/api/patient/checkin/phone_send',
      params: { phone: creds.phone },
      navigate,
      onSuccess
    });
    setShow((ps) => ({ ...ps, loading: false }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
      emailLogin();
    }
  };

  return (
    <div className="bg-primary-600">
      <img
        src={checkinBackgroundPNG}
        className="absolute left-0 top-0 z-10 h-full w-full object-cover"
      />
      <div className="flex h-full w-full flex-row ">
        <div className="z-20 flex h-[100svh] w-2/5 flex-col justify-between gap-4 overflow-y-scroll bg-neutral-50 !pt-6 sm:w-screen md:w-1/2" data-qa="test">
          <div className="mt-[1rem] px-[3rem] md:px-2 xs:!px-4">
            <div className="flex w-full flex-row justify-start">
              <Icon
                icon="logo-full-main"
                className={cs('left-0 top-0', !isCheckin && 'flex h-auto')}
                size={200}
              />
            </div>
          </div>
          <div className="px-[3rem] md:px-2 xs:!px-4">
            <div className="flex !gap-2">
              {isCheckin && <Icon icon="back-large" onClick={navigateToLast} />}
              <h3 className="text-3xl font-500 text-primary-900">Login</h3>
            </div>
            <div className="!mt-4">
              <p className="text-sm text-neutral-700">
                Access your health records by logging in to your account with:
              </p>
              <div className="flex flex-col !gap-4 pt-2">
                <div className="!mt-2 flex w-full flex-row flex-wrap gap-2">
                  <RadioButton
                    wrapperClassName="w-max"
                    id="email-input"
                    value={show.selection}
                    label="User credentials"
                    isSelected={show.selection === 'email'}
                    onChange={() =>
                      setShow((ps) => ({ ...ps, selection: 'email', email: true, phone: false }))
                    }
                    data-qa="login-email-radio"
                    className="text-neutral-900"
                  />
                  <RadioButton
                    wrapperClassName="w-max"
                    id="phone-input"
                    value={show.selection}
                    label="Mobile phone"
                    isSelected={show.selection === 'phone'}
                    onChange={() =>
                      setShow((ps) => ({ ...ps, selection: 'phone', email: false, phone: true }))
                    }
                    data-qa="login-phone-radio"
                    className="text-neutral-900"
                  />
                </div>
                <div>
                  {show.email && (
                    <div>
                      <Input
                        placeholder="email@email.com"
                        label="Email address"
                        type="email"
                        value={creds.email}
                        onChange={(e) => {
                          e.persist();
                          setCreds((ps) => ({ ...ps, email: e.target.value }));
                        }}
                        error={show.error}
                        className="!mb-4"
                        data-qa="login-email"
                      />
                      <Input
                        placeholder="password"
                        label="Password"
                        value={creds.pwd}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          e.persist();
                          setCreds((ps) => ({ ...ps, pwd: e.target.value }));
                        }}
                        className="!mb-4"
                        data-qa="login-password"
                        inputClassName="!min-w-full"
                        type={show.pwd ? 'text' : 'password'}
                        rightIcon={show.pwd ? 'new-eye' : 'new-eye-slash'}
                        rightIconClick={() =>
                          setShow((prevState) => ({ ...prevState, pwd: !prevState.pwd }))
                        }
                      />
                      <div className="!mb-6 flex !w-full !flex-row !justify-end">
                        <Link
                          className="text-sm"
                          data-qa="login-password-request"
                          to="/password/request">
                          Forgot Password?
                        </Link>
                      </div>
                      <Button
                        className="w-full"
                        text="Login"
                        onClick={emailLogin}
                        loading={show.loading}
                        data-qa="login-btn"
                      />
                    </div>
                  )}
                  {!show.email && (
                    <div className="flex flex-col justify-center align-middle">
                      <Input
                        label="Phone number"
                        placeholder="(***)***-****"
                        value={creds.phone}
                        onChange={(e) => {
                          e.persist();
                          setCreds((ps) => ({ ...ps, phone: e.target.value }));
                        }}
                        className="!mb-8"
                        data-qa="login-phone"
                      />

                      {!show.confirmCode && (
                        <Button
                          className="w-full"
                          text="Send Code"
                          onClick={phoneStart}
                          loading={show.loading}
                          data-qa="login-send-code-btn"
                        />
                      )}
                      {show.confirmCode && (
                        <>
                          <VerificationInput
                            validChars="0-9"
                            onChange={(e) => setCreds((ps) => ({ ...ps, code: e }))}
                            onComplete={phoneLogin}
                            inputProps={{ autoComplete: 'one-time-code' }}
                            placeholder=""
                            classNames={{
                              container: '',
                              // character: '!border-2 !border-solid !border-primary-500 bg-primary-200 text-primary-600',
                              character:
                                '!rounded-lg border-none text-primary-600 aspect-square bg-white shadow',
                              characterSelected:
                                '!rounded-lg border-none text-primary-600 aspect-square bg-primary-100 shadow border-b-2 border-b-solid border-b-primary-500',
                              characterInactive:
                                '!rounded-lg border-none text-primary-600 aspect-square bg-white shadow'
                            }}
                            data-qa="login-code-input"
                          />
                          <Button
                            text="Login"
                            onClick={phoneLogin}
                            loading={show.loading}
                            className="mt-20 w-full"
                            data-qa="login-confirm-code-btn"
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
                {isCheckin && (
                  <div className="!mt-4">
                    <label className="text-center text-neutral-600">
                      <Link to="./payment" data-qa="checkin-login-skip-to-payment">
                        Skip
                      </Link>{' '}
                      registration and go to, Payment screen!
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <label className="!mx-10 !mb-5 text-center text-neutral-500">
              By logging in, you agree to the{' '}
              <Link to="/terms">Terms of Service and Privacy Policy</Link>
            </label>
            {isCheckin && (
              <div className="flex h-[44px] w-full items-center justify-between bg-primary-50 px-40">
                <h4 className="text-sm text-neutral-600">Powered By</h4>
                <Icon icon="logo-extra-small" />
              </div>
            )}
          </div>
        </div>
        <UserActivateAdvertisement wrapperClassName="!bg-transparent z-20 sm:hidden md:w-1/2 w-3/5" />
        {/* </div> */}
      </div>
      <Modal
        isOpen={showIdleModal}
        handleClose={() => setShowIdleModal(false)}
        shouldCloseOnOverlayClick={true}
        footer={<></>}>
        <div className="my-10">
          <NotFound
            icon="new-automatization"
            title="You were logged out due to inactivity."
            description="To resume your session, kindly log back in using your credentials."
            className="pt-0"
          />
        </div>
      </Modal>
    </div>
  );
};

export default PatientCheckinLogin;
