import React, { useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import Modal from '../../../shared/Modal/Modal';
import Spinner from '../../../shared/spinner/spinner';
import Button from '../../../shared/Button/Button';
import {
  Capitalize,
  formatDate,
  formatPhoneNumber,
  isEmpty
} from '../../../../lib/helpers/utility';
import { diagnosisPointers, insurancePatientRelation } from '../../../../constants';
import CPTRow from './CPTRow';

const PatientDocumentModal = ({ showModal, hideModal, superbill, loading }) => {
  const exportRef = useRef();
  const claim = superbill?.claim;

  const findPatientRelation = (value) => {
    const relation = insurancePatientRelation?.find((relation) => {
      if (relation?.value == value) {
        return relation;
      }
    });

    return relation && relation?.label;
  };

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      slideFromRight
      headButton={
        <ReactToPrint
          trigger={() => (
            <Button icon="export" primary>
              Export PDF
            </Button>
          )}
          content={() => exportRef.current}
        />
      }
      customStyling={{ width: '1100px' }}>
      {loading.spinner ? (
        <div className="Spinner-center">
          <Spinner />
        </div>
      ) : (
        <div ref={exportRef}>
          <div className="bg-white">
            <p className="f-black mb-[24px] px-[20px] text-[32px] font-600">
              Superbill - {formatDate(superbill?.encounter?.appointment?.starts_at)}
            </p>
            <div className="box">
              <label className="subtitle">Patient info</label>
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center">
                <div className="block p-3">
                  <label className="font-500">First Name</label>
                  <div>{superbill?.patient?.f_name}</div>
                </div>
                {superbill?.patient?.m_name && (
                  <div className="block p-3">
                    <label className="font-500">Middle Name</label>
                    <div>{superbill?.patient?.m_name}</div>
                  </div>
                )}
                <div className="block p-3">
                  <label className="font-500">Last Name</label>
                  <div>{superbill?.patient?.l_name}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Date of birth</label>
                  <div>{formatDate(superbill?.patient?.dob)}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Gender</label>
                  <div>{Capitalize(superbill?.patient?.gender)}</div>
                </div>
              </div>
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center ">
                <div className="block p-3">
                  <label className="font-500">Address</label>
                  <div>{superbill?.patient?.userAddress?.fullAddress}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Phone</label>
                  <div>{formatPhoneNumber(superbill?.patient?.phone)}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Email</label>
                  <div>{superbill?.patient?.email}</div>
                </div>
              </div>
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center ">
                <div className="block p-3">
                  <label className="font-500">Insurance name</label>
                  <div>{claim?.payer_name}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Insurance ID</label>
                  <div>{claim?.ins_number}</div>
                </div>
                <div className="block p-3">
                  <label className="font-500">Relation to patient</label>
                  {isEmpty(claim?.pat_rel) ? 'N/A' : findPatientRelation(claim.pat_rel)}
                </div>
              </div>
              {!isEmpty(claim?.pat_rel) && claim.pat_rel !== '18' && (
                <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center">
                  <div className="block p-3">
                    <div>
                      <label className="font-500">Insured Name: </label>
                      <div>{`${claim.ins_name_f} ${claim.ins_name_m ?? ''} ${
                        claim.ins_name_l ?? ''
                      }`}</div>
                    </div>
                  </div>
                  <div className="block p-3">
                    <div>
                      <label className="font-500">Insured Date of Birth: </label>
                      <div>{claim?.ins_dob ? formatDate(claim.ins_dob) : 'N/A'}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="box">
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center">
                <div className="block p-3">
                  <label className="subtitle">Facility information</label>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Facility: </label>
                    <div>{superbill?.claim?.facility_name}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">NPI: </label>
                    <div>{superbill?.claim?.facility_npi}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Tax ID: </label>
                    <div>{superbill?.claim?.facility_id}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Address: </label>
                    <div>{`${superbill?.claim?.facility_addr_1} ${superbill?.claim?.facility_addr_2} ${superbill?.claim?.facility_city} ${superbill?.claim?.facility_state} ${superbill?.claim?.facility_zip}`}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Email:</label>
                    <div>{superbill?.practice?.email}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Phone:</label>
                    <div>{formatPhoneNumber(superbill?.practice?.phone)}</div>
                  </div>
                </div>
                <div className="block p-3">
                  <label className="subtitle">Rendering provider</label>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Name:</label>
                    <div>{`${superbill?.claim?.prov_name_f} ${superbill?.claim?.prov_name_m} ${superbill?.claim?.prov_name_l}`}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">NPI: </label>
                    <div>{superbill?.claim?.prov_npi}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Tax ID: </label>
                    <div>{superbill?.claim?.prov_taxid}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">ID: </label>
                    <div>{superbill?.claim?.prov_id}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Taxonomy: </label>
                    <div>{superbill?.claim?.prov_taxonomy}</div>
                  </div>
                </div>
                <div className="block p-3">
                  <label className="subtitle">Billing provider</label>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Name:</label>
                    <div>{superbill?.claim?.bill_name}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">NPI: </label>
                    <div>{superbill?.claim?.bill_npi}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Tax ID: </label>
                    <div>{superbill?.claim?.bill_taxid}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">ID: </label>
                    <div>{superbill?.claim?.bill_id}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Taxonomy: </label>
                    <div>{superbill?.claim?.bill_taxonomy}</div>
                  </div>
                  <div className="flex items-stretch pb-1">
                    <label className="font-500">Address:</label>
                    <div>{`${superbill?.claim?.bill_addr_1} ${superbill?.claim?.bill_addr_2} ${superbill?.claim?.bill_city} ${superbill?.claim?.bill_state} ${superbill?.claim?.bill_zip}`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <label className="subtitle">Services & Diagnosis</label>
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
                <div className="block p-3">
                  <label className="font-500">Diagnosis (ICD10 CODES)</label>
                  <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
                    <div className="block p-3">
                      {Array.isArray(superbill?.encounter?.icd_10_codes) &&
                        superbill?.encounter?.icd_10_codes.map((code, index) => {
                          return (
                            <div key={index} className="m-[10px] flex items-center">
                              <label className="mr-1 inline-block border border-solid border-neutral-300">
                                {diagnosisPointers[index]}:{' '}
                              </label>
                              <label className="border border-solid border-neutral-300">
                                {code}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="-ml-3 -mr-3 -mt-3 flex-wrap justify-center">
                <div className="block p-3">
                  <label className="font-500">Procedure (CPT &#174; CODES)</label>
                  <div className="block p-3">
                    <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
                      <div className="block w-full items-center border border-solid border-neutral-300 p-3">
                        <div className="-ml-3 -mr-3 -mt-3 flex-wrap">
                          <div className="block p-3">
                            <label className="font-500">Code</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">NDC</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">Modifier</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">Diagnosis pointer</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">Charges</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">Days/Units</label>
                          </div>
                          <div className="block p-3">
                            <label className="font-500">POS</label>
                          </div>
                          <div className="block min-w-[125px] p-3">
                            <label className="font-500">From date</label>
                          </div>
                          <div className="block min-w-[125px] p-3">
                            <label className="font-500">To date</label>
                          </div>
                        </div>
                      </div>
                      {Array.isArray(superbill?.encounter?.cpt_codes) &&
                        superbill?.claim?.procedures.map((item, index) => {
                          return <CPTRow key={index} item={item} />;
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PatientDocumentModal;
