import { Honeybadger } from '@honeybadger-io/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { updateDocumentSign } from '../../../../../api/DocumentSign';
import { interimApi } from '../../../../../api/InterimApi';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from '../../../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';
import { userState as user_state } from '../../../../state';
import TPModal from '../TreatmentPlan/TPModal';
import SignLockActionComponent from './SignLockActionComponent';

const ConfirmSignLock = ({ documentSignatures = [], hasUserSigned = false }) => {
  const {
    clinicalNote,
    setClinicalNote,
    confirmSignLock,
    showConfirmSignLock,
    advancedHPRef,
    advancedSOAPRef,
    advancedFormRef,
    vitalsRef,
    setSelectedTreatmentPlan
  } = useClinicalNoteContext();
  const { hpNoteOverviewRefs } = useOutletContext();
  const queryClient = useQueryClient();
  const userState = useRecoilValue(user_state);
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const { id, appointmentId, type } = useParams();
  const navigate = useNavigate();
  const [availableDates, setAvailableDates] = useState([]);
  const [loadView, setLoadView] = useState(null);
  const [TPOrigin, setTPOrigin] = useState(null);
  const [showTPModal, setShowTPModal] = useState(false);
  const [confirmCreateTPlan, setConfirmCreateTPlan] = useState(false);
  const [confirmUpdateTPlan, setConfirmUpdateTPlan] = useState(false);

  useEffect(() => {
    if (type === 'intake') {
      handleMHA();
    } else if (type === 'progress') {
      handleEPN();
    } else {
      resetLoadView();
    }
  }, [type]);

  const handleMHA = () => {
    setLoadView('create');
    setTPOrigin('intake');
  };

  const handleEPN = () => {
    if (!treatmentPlan) return;
    if (treatmentPlan.length === 0) {
      resetLoadView();
    } else if (treatmentPlan.length === 1) {
      setLoadView('edit');
      setTPOrigin('progress');
    }
    const newAvailableDates = treatmentPlan?.filter((item) => filterAvailableDates(item));
    setAvailableDates(newAvailableDates);
    if (newAvailableDates.length > 1) {
      setLoadView('list');
    } else {
      setLoadView('edit');
    }
    setTPOrigin('progress');
  };

  const filterAvailableDates = (item) => {
    if (item.treatment_frequency_date?.endDate) {
      const endDateMoment = moment(item.treatment_frequency_date.endDate, 'MM/DD/YYYY');
      const currentDate = moment();
      return (
        !endDateMoment.isBefore(currentDate, 'day') && !endDateMoment.isSame(currentDate, 'day')
      );
    }
    return false;
  };

  const resetLoadView = () => {
    setLoadView(null);
    setTPOrigin(null);
  };

  const closeTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(false);
  };

  const onLockNote = async ({ lockStatus = false } = {}) => {
    try {
      showConfirmSignLock(false);
      Object.values(hpNoteOverviewRefs).forEach((el) => el?.current?.click());
      advancedHPRef?.current?.click();
      advancedSOAPRef?.current?.click();
      advancedFormRef?.current?.click();
      vitalsRef?.current?.saveVitals();

      const newLockState = lockStatus;
      const newState = newLockState ? 'finalized' : 'draft';
      const lockHistory = clinicalNote.lock_history ? [...clinicalNote.lock_history] : [];
      lockHistory.push({ lock: newLockState, timestamp: new Date(), user_id: userState.id });
      const user_id = clinicalNote?.appointment?.user_id || id;
      const appointment_id = clinicalNote?.appointment_id || appointmentId;

      let params = {
        clinicalNote: {
          ...clinicalNote,
          locked: newLockState,
          state: newState,
          lock_history: lockHistory,
          user_id,
          appointment_id
        }
      };

      const res = await interimApi('/api/clinical_note/upsert', params, navigate);
      const { clinicalNoteId, error } = res.data;

      setClinicalNote({
        ...clinicalNote,
        locked: newLockState,
        state: newState,
        lock_history: lockHistory,
        user_id,
        appointment_id
      });

      if (!clinicalNoteId) {
        Honeybadger.notify(`Clinical note lock error, error: ${error}`);
        return;
      }

      queryClient.invalidateQueries(['clinicalNotes']);
      queryClient.resetQueries(['claim']);
      queryClient.resetQueries(['appointment']);

      const clinicalNoteAuthor =
        clinicalNote?.practitioner_id == (userState.id || userState.user_id);

      if (newLockState && TPOrigin !== 'intake' && TPOrigin !== 'progress') {
        navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
      }

      clinicalNoteAuthor && !hasUserSigned && TPOrigin === 'intake' && setConfirmCreateTPlan(true);

      clinicalNoteAuthor &&
        !hasUserSigned &&
        TPOrigin === 'progress' &&
        ia(treatmentPlan) &&
        setConfirmUpdateTPlan(true);
    } catch (error) {
      console.error(error);
    }
  };

  const updateDocumentSignMutation = useMutation((data) => updateDocumentSign(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documentSign']);
    }
  });

  if (confirmSignLock) {
    return (
      <SignLockActionComponent
        documentSignatures={documentSignatures}
        clinicalNote={clinicalNote}
        onLockNote={onLockNote}
        userState={userState}
        confirmSignLock={confirmSignLock}
        showConfirmSignLock={showConfirmSignLock}
        hasUserSigned={hasUserSigned}
        updateDocumentSignMutation={updateDocumentSignMutation}
      />
    );
  }

  if (confirmCreateTPlan)
    return (
      <Confirm
        primaryBtnTxt="Create treatment plan"
        secondaryBtnTxt="No, I don't"
        title="Do you want to create a treatment plan?"
        message="Are you sure you want to create treatment plan from clinical note?"
        icon="new-task-square"
        iconSize="24px"
        handleContinue={() => {
          setConfirmCreateTPlan(false);
          setShowTPModal(true);
        }}
        handleOpen={confirmCreateTPlan}
        handleClose={() => {
          setConfirmCreateTPlan(false);
          navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
        }}
      />
    );

  if (confirmUpdateTPlan)
    return (
      <Confirm
        primaryBtnTxt="Update treatment plan"
        secondaryBtnTxt="No, I don't"
        title="Do you want to update the treatment plan?"
        message="Are you sure you want to update the treatment plan?"
        icon="new-task-square"
        iconSize="24px"
        handleContinue={() => {
          setConfirmUpdateTPlan(false);
          setShowTPModal(true);
        }}
        handleOpen={confirmUpdateTPlan}
        handleClose={() => {
          setConfirmUpdateTPlan(false);
          navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
        }}
      />
    );

  if (showTPModal)
    return (
      <TPModal
        isOpen={showTPModal}
        handleClose={closeTPModal}
        loadView={loadView}
        origin={TPOrigin}
        availableDates={availableDates}
      />
    );
};

export default ConfirmSignLock;
