import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Icon from 'components/shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import useChartSize from 'lib/hooks/useChartSize';
import { generateChartData } from './lib/helper';
import ListView from './components/ListView';
import ChartView from './components/ChartView';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Notifications = (props) => {
  const [listView, setListView] = useState(false);
  const { overview, item, layout } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const { height, width, proportion } = useChartSize(container);
  const navigate = useNavigate();

  const handleListView = () => setListView(!listView);
  const chartData = generateChartData(overview?.notifications?.type);

  const options = {
    data: chartData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    series: [
      {
        data: chartData,
        type: 'donut',
        angleKey: 'value',
        fills: chartData.map((item) => item.fill),
        innerRadiusRatio: 0.7,
        innerLabels: [
          {
            text: `${overview?.notifications?.count?.count}`,
            color: '#004F6B',
            fontSize: 19
          },
          { text: ' ', fontSize: 10 },
          { text: 'Notifications', color: '#6D828B', fontSize: 12 }
        ],
        innerCircle: {
          fill: 'transparent'
        }
      }
    ]
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader icon="new-notifications" iconColor="primary" hasPlus={false} {...props}>
        <div
          onClick={handleListView}
          className="cursor-pointer rounded-lg bg-primary-50 !px-1 !py-1">
          <Icon
            className="cursor-pointer"
            color="primary"
            icon={listView ? 'new-graph' : 'list-unordered'}
          />
        </div>
        <div
          onClick={() => {
            navigate(`../notifications`);
          }}
          className="cursor-pointer rounded-lg bg-neutral-100 !px-2 !py-2">
          <Icon className="cursor-pointer" icon="new-link"></Icon>
        </div>
      </WidgetHeader>
      {listView ? (
        <ListView
          options={options}
          data={overview?.notifications?.type}
          total={overview?.notifications?.count?.count}
        />
      ) : (
        <div className="flex w-full flex-1 items-center justify-between">
          <ChartView
            options={options}
            data={overview?.notifications?.type}
            total={overview?.notifications?.count?.count}
          />
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(Notifications);
