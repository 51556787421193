import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilState } from 'recoil';
import { getCodeStates } from '../../../../state';
import { handleCPTSearch, handleICD10Search, handleModifierSearch } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';
import Textarea from 'components/shared/Textarea/Textarea';
import '../Custom.scss';

const OrderAndResult = ({ forwardedRef, defaultValue, onChange }) => {
  const [codeStates, setCodeStates] = useRecoilState(getCodeStates);

  const noteRef = useRef(null);

  const initialValue = {
    cptCodes: codeStates.cptCodes,
    modifiers: codeStates.modifiers,
    icdCodes: codeStates.icdCodes,
    note: ''
  };
  const [orderAndResult, setOrderAndResult] = useState(initialValue);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setOrderAndResult(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setOrderAndResult(defaultValue);
      }
    } else {
      setOrderAndResult(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(orderAndResult);
  }, [orderAndResult]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'OrderAndResult', type: 'custom' } });
    }
  };

  const handleChange = (key, event) => {
    setOrderAndResult({
      ...orderAndResult,
      [key]: event.target.value
    });
  };

  return (
    <div className="OrderAndResult CustomForms">
      <input type="hidden" value={JSON.stringify(orderAndResult)} ref={forwardedRef} />
      <div className="CustomForms__row">
        <div className="grow-1 flex flex-col basis-[40%] justify-end">
          <label label="label" htmlFor="cptCodesInForm">
            CPT ® Codes
          </label>
          <AsyncSelect
            loadOptions={handleCPTSearch}
            onChange={(event) => setCodeStates({ ...codeStates, cptCodes: event })}
            isMulti
            cacheOptions
            value={codeStates.cptCodes}
            noOptionsMessage={() => 'Start typing the code or description.'}
            className="Select"
            classNamePrefix="Select"
            inputId="cptCodesInForm"
          />
        </div>
        {codeStates.cptCodes.map((cpt, index) => (
          <div
            className="Modifiers grow-1 flex flex-col basis-[40%] justify-end"
            key={index}>
            <label label="label">{`Modifiers - ${cpt.value}`}</label>
            <AsyncSelect
              loadOptions={handleModifierSearch}
              onChange={(event) => setCodeStates({ ...codeStates, modifiers: event })}
              isMulti
              cacheOptions
              value={codeStates?.modifiers[index]}
              noOptionsMessage={() => 'Start typing the code or description.'}
              className="Select"
              classNamePrefix="Select"
            />
          </div>
        ))}
        <div className="grow-1 flex flex-col basis-[40%] justify-end">
          <label label="label" htmlFor="icd10CodesInForm">
            ICD-10 Codes
          </label>
          <AsyncSelect
            loadOptions={handleICD10Search}
            onChange={(event) => setCodeStates({ ...codeStates, icdCodes: event })}
            isMulti
            cacheOptions
            value={codeStates.icdCodes}
            noOptionsMessage={() => 'Start typing the code or description.'}
            className="Select"
            classNamePrefix="Select"
            inputId="icd10CodesInForm"
          />
        </div>
        <div className="basis-full grow-1 flex flex-col justify-end">
          <Textarea
            label="Note"
            placeholder="Add note here"
            id="note"
            name="note"
            value={orderAndResult.note}
            onChange={(event) => handleChange('note', event)}
            disabled={disabled}
            transcribing
            forwardedRef={noteRef}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderAndResult;
