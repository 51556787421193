import { iaRa } from 'lib/helpers/utility';

export const shouldDisableProcedureProduct = ({ superbill = null, procedure = null }) => {
  if (!superbill) return;

  const isClinicalNoteLocked =
    superbill?.encounter?.appointment?.medicalHistory?.state === 'finalized' &&
    superbill?.encounter?.appointment?.medicalHistory?.locked;

  const originalProcedures = superbill?.encounter?.cpt_codes;

  return (
    isClinicalNoteLocked &&
    iaRa(originalProcedures)?.some((row) => {
      return row?.code?.value === procedure?.code;
    })
  );
};
