import Honeybadger from '@honeybadger-io/js';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Popup from 'components/shared/Filters/Popup';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Actions = ({ data }) => {
  const { id, status } = data || {};

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [showReceipt, setShowReceipt] = useState(false);
  const [refundTransactionModal, setRefundTransactionModal] = useState(false);

  const onRefund = async () => {
    try {
      const res = await requestApi({
        url: '/api/transactions/online/refund',
        params: {
          transactionId: id
        },
        navigate
      });

      const { code, error } = res;

      switch (code) {
        case 0:
          showAlert({ title: 'Successfully refunded transaction!', color: 'success' });
          queryClient.invalidateQueries(['transactions']);
          break;
        case 7:
          showAlert({ title: 'Successfully voided transaction!', color: 'success' });
          break;
        default:
          if (error) {
            showAlert({ title: error, color: 'danger' });
            Honeybadger.notify(`handleTransactionRefund: ${error}`);
          } else {
            Honeybadger.notify('handleTransactionRefund: unexpected error');
          }
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`handleTransactionRefund: unexpected error ${error}`);
    }
  };

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={180}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        {(close) => (
          <div className=" !py-[6px]">
            <div
              data-qa="view-receipt"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => {
                close();
                setShowReceipt(true);
              }}>
              <div className="ml-1 text-sm text-primary-900">View Receipt</div>
            </div>
            {status?.toLowerCase() !== 'refunded' && (
              <div
                data-qa="void-transaction"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => setRefundTransactionModal(true)}>
                <div className="ml-1 text-sm text-primary-900">Refund</div>
              </div>
            )}
          </div>
        )}
      </Popup>
      {showReceipt && (
        <ReceiptModal
          transactionId={id}
          isOpen={showReceipt}
          onClose={() => setShowReceipt(false)}
        />
      )}
      {!!refundTransactionModal && (
        <Confirm
          icon="new-info"
          iconSize={42}
          variant="warning"
          primaryBtnTxt="Refund"
          title="Refund Transaction"
          message="Are you sure you want to refund this Transaction?"
          handleContinue={() => {
            onRefund();
            setRefundTransactionModal(false);
          }}
          handleOpen={!!refundTransactionModal}
          handleClose={() => setRefundTransactionModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
